import { Injectable } from "@angular/core";

import { ChargetypesService } from "./chargetypes.service";

@Injectable()
export class ChargeTypesIdResolver {
  constructor(private chargeTypesService: ChargetypesService) {}

  resolve() {
    return this.chargeTypesService.listDropDownJustId();
  }
}
