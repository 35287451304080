import { Component, OnInit } from "@angular/core";
import { BaseRate } from "@app/shared/models/rates/BaseRate.model";
import { TripOptions } from "@app/shared/models/trips/TripOptions.model";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-duplicate-rates-modal",
  templateUrl: "./duplicate-rates-modal.component.html",
  styleUrls: ["./duplicate-rates-modal.component.css"],
})
export class DuplicateRatesModalComponent implements OnInit {
  rates: Array<BaseRate>;
  rateType: string;
  tripOptions: TripOptions;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.rates = this.config.data.rates;
    this.tripOptions = this.config.data.tripOptions;

    this.rates = this.rates.map((rate) => ({
      ...rate,
      rateLink: this.buildRateLink(rate),
    }));
  }

  selectRate(rate: BaseRate) {
    this.ref.close(rate);
  }

  buildRateLink(rate: BaseRate) {
    switch (this.rateType) {
      case "CLIENT":
        return `/rates/client/${rate.id}`;
      case "DRIVER":
        return `/rates/driver/${rate.id}`;
      case "CUSTOM":
        return `/rates/custom/${rate.id}`;
      case "GUARANTEE":
        return `/rates/minimums/${rate.id}`;
      case "LONGEVITY":
        return `/rates/longevity/${rate.id}`;
      case "FUEL":
        return `/rates/fuel/${rate.id}`;
      case "INSURANCE":
        return `/rates/insurance/${rate.id}`;
    }
  }
}
