<div class="container">
  <div class="row">
    <div class="row">
      <div class="col-md-5">
        <table class="table tableTripInfo">
          <thead>
            <tr></tr>
          </thead>
          <tbody class="style-text text-table">
            <tr>
              <td class="boldTh">Driver</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.driverName }}</td>
              <td class="pInfo" *ngIf="selectedTrip">
                <div>{{ selectedTrip.tractorNo }}</div>
              </td>
            </tr>
            <tr>
              <td class="boldTh">Trailer</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.trailerNo }}</td>
              <td class="boldTh">Start Trip</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.originLocName }}</td>
            </tr>
            <tr>
              <td class="boldTh">product</td>
              <td *ngIf="selectedTrip">
                <div>{{ selectedTrip.product }}</div>
              </td>
              <td class="boldTh">End Trip</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.endLocName }}</td>
            </tr>
            <tr>
              <td class="boldTh">Stops</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.stopsNo }}</td>
              <td class="boldTh">Total Miles</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.totalMiles }}</td>
            </tr>
            <tr>
              <td class="boldTh">Start Date</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.startDate | date : "MM/dd/yyyy" }}</td>
              <td class="boldTh">End Date</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.endDate | date : "MM/dd/yyyy" }}</td>
            </tr>
            <tr>
              <td class="boldTh">Client</td>
              <td *ngIf="selectedTrip">
                <div></div>
              </td>
              <td class="boldTh">Bill To</td>
              <td *ngIf="selectedTrip">
                <div>{{ selectedTrip.billTo }}</div>
              </td>
            </tr>
            <tr>
              <td class="boldTh">Type</td>
              <td *ngIf="selectedTrip">
                <div>{{ selectedTrip.trailerType }}</div>
              </td>
              <td class="boldTh">UN #</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.unNo }}</td>
            </tr>
            <tr>
              <td class="boldTh">Trip Status</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.status }}</td>
              <td class="boldTh">Quantity</td>
              <td *ngIf="selectedTrip">{{ selectedTrip.quantity }}</td>
            </tr>
          </tbody>
          <div *ngIf="selectedTrip" class="viewDetails" align="center">
            <a (click)="viewDetail(selectedTrip)">
              <button type="button" class="btn btn-secondary btnDetails">Trip Details</button>
            </a>
          </div>
        </table>
        <!-- search select-->
        <div class="row rowFilters">
          <div class="col-md-3 inputSpace">
            <select
              class="form-control style-text text-select"
              type="text"
              id="driverType"
              name="driverType"
              [(ngModel)]="dispatchFilters.clientId"
              (change)="(filterDispatch)"
            >
              <option [ngValue]="null">Select Client</option>
              <option *ngFor="let client of clients" [ngValue]="client.id">
                {{ client.companyName }}
              </option>
            </select>
          </div>
          <!-- search filter-->
          <div>
            <div class="col-md-3 inputSpace">
              <select
                class="form-control style-text text-select"
                type="text"
                id="driverType"
                name="driverType"
                [(ngModel)]="dispatchFilters.tractorId"
              >
                <option [ngValue]="null">Select Tractor</option>
                <option *ngFor="let tractor of tractors" [ngValue]="tractor.id">
                  {{ tractor.unitNumber }}
                </option>
              </select>
            </div>
          </div>
          <div class="createTripDiv">
            <div class="col-md-3 inputSpace">
              <a routerLink="/new/newtrip" class="btnCreateTrip btn">
                <img src="assets/img/plus.svg" class="btn-secondary" width="15" />
                New Trip
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <!-- tabss -->
        <div class="row">
          <div class="tab">
            <button
              class="tablinks active style-text text-select"
              id="two"
              (click)="OpenTab('dispatch')"
            >
              Dispatch
            </button>
            <button class="tablinks style-text" id="one" (click)="OpenTab('schedule')">
              Weekly Schedule
            </button>
          </div>
        </div>
        <div id="dispatch" class="tabcontent" style="display: block">
          <div>
            <div style="display: inline" class="column-filter" *ngFor="let col of allColumns">
              <input
                type="checkbox"
                [id]="col.name"
                (click)="toggle(col)"
                [checked]="isChecked(col)"
              />
              <label [attr.for]="col.name">{{ col.name }}</label>
            </div>
          </div>
          <p-table
            #homeTable
            [columns]="columns"
            [value]="filteredRows"
            [scrollable]="true"
            scrollHeight="400px"
            [virtualScroll]="true"
            scrollDirection="both"
          >
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [style.width.px]="col.width || 100" />
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">
                  {{ col.name }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dispatch>
              <tr>
                <td *ngFor="let col of columns">
                  {{ dispatch[col.prop] }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <!-- end dispatch -->

        <div id="schedule" class="tabcontent style-text">
          <label>Select Client</label>
          <select
            class="selectClient style-text"
            [ngModel]="selectedClient"
            (ngModelChange)="clientSelected($event)"
            name="sel2"
          >
            <option [value]="client.id" *ngFor="let client of clients">
              {{ client.companyName }}
            </option>
          </select>
          <!--display List of trips section-->
          <br />
          <div>
            <h4>Schedule report by client</h4>
            <div
              class="tableScheduleRresult"
              *ngIf="currentClientReportPage && currentClientReportPage.length"
            >
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Schedule Date</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of currentClientReportPage">
                    <td>{{ report.createdAt | date : "MM/dd/yyyy" }}</td>
                    <td>
                      <button
                        (click)="viewReportBelow(report)"
                        type="button"
                        class="btn btn-default"
                      >
                        <span class="glyphicon glyphicon-eye-open"></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p-paginator
                rows="10"
                totalRecords="{{ totalRows }}"
                (onPageChange)="paginateCurrentClientReportPage($event)"
              ></p-paginator>
            </div>
          </div>
        </div>
        <!--second row that display the info coming from the client slection-->
        <div class="row" style="margin-left: 11px">
          <table class="table table-striped displayInfoSection">
            <thead>
              <div></div>
            </thead>
            <tbody *ngIf="reportRows.length">
              <tr class="titleTable">
                <td class="topTable">Driver</td>
                <td class="topTable">Phone</td>
                <td class="topTable">Product</td>
                <td class="topTable">Board</td>
                <td class="topTable">Tractor</td>
                <td class="topTable">Trailer</td>
                <td class="topTable">Time off</td>
                <td class="topTable">Comments</td>
              </tr>
              <tr class="titleTable" *ngFor="let reportRow of reportRows; let indexRow = index">
                <td>
                  <div *ngIf="reportRow.Driver">
                    {{ reportRow.Driver.itemName }}
                  </div>
                  <div *ngIf="reportRow.Team">
                    {{ reportRow.Team.TeamName }}
                  </div>
                </td>
                <td>
                  <div *ngIf="reportRow.Driver">
                    {{ reportRow.Driver.cellphone }}
                  </div>
                  <div *ngIf="reportRow.Team">
                    <div *ngFor="let driver of reportRow.Team.Drivers">
                      {{ driver.lastName }}, {{ driver.name }}:
                      {{ driver.cellphone || driver.phone }}
                    </div>
                  </div>
                </td>
                <td>
                  <div style="display: block">
                    <div *ngFor="let product of reportRow.Products">
                      {{ product.itemName }}
                    </div>
                  </div>
                </td>
                <td>
                  {{ reportRow.board }}
                </td>
                <td>
                  <div *ngIf="reportRow.Tractor">
                    {{ reportRow.Tractor.unitNumber }}
                  </div>
                </td>
                <td>
                  <div
                    class="row"
                    *ngFor="let range of reportRow.DriverEvents; let i = index"
                  ></div>
                  <div *ngIf="!reportRow.DriverEvents || !reportRow.DriverEvents.length">
                    <h3>No time off</h3>
                  </div>
                </td>
                <td>
                  <div class="row" *ngFor="let range of reportRow.DriverEvents; let i = index">
                    {{ range.comments }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ng-template #dateTemplate let-row="row" let-value="value" let-i="index">
    {{ value | date : "MM/dd/yy HH:mm" }}
  </ng-template>
</div>
