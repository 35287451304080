import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DeductionService } from "../deduction.service";
import { Deduction } from "@app/shared/models/settlements/Deduction.model";
import { SelectItem } from "primeng/api";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { CanComponentDeactivate } from "@app/shared/guards/confirmation/confirmation.guard";
import { Observable } from "rxjs";
import { SettlementsService } from "../shared/settlements.service";

@Component({
  selector: "app-deduction",
  templateUrl: "./deduction.component.html",
  styleUrls: ["./deduction.component.css"],
})
export class DeductionComponent implements OnInit, CanComponentDeactivate {
  isCreating: boolean;
  isEditing: boolean;
  loading: boolean;

  deduction: Deduction = new Deduction();
  batches: any[] = [];
  drivers: SelectItem[] = [];
  chargeTypes: SelectItem[] = [];

  historyType: string = "batch";

  selectedBatch: any;
  selectedDriver: any;

  batchHistory: any = [];
  driverHistory: any = [];

  batchColumns = [
    { field: "driverName", header: "Driver" },
    { field: "customValue", header: "Deduction" },
  ];

  driverColumns = [
    { field: "batchNo", header: "Batch" },
    { field: "customValue", header: "Deduction" },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private _AuthService: authenticationService,
    private DeductionService: DeductionService,
    private SettlementsService: SettlementsService
  ) {}

  confirm(): boolean | Observable<boolean> {
    if (this.isCreating) return true;
    if (this.isEditing) return window.confirm("Are you sure you want to leave this page?");
    return true;
  }

  ngOnInit(): void {
    this.isCreating = false;
    this.isEditing = false;
    this.loading = false;

    this.route.params.subscribe((params) => {
      if (params.deductionId == "newdeduction") {
        this.isCreating = true;
        this.deduction = new Deduction();
      } else {
        this.DeductionService.getDeduction(params.deductionId).subscribe((data) => {
          this.deduction = data;

          this.SettlementsService.getGlobalBatchListDropDown("All").subscribe(
            (batchList: any) => {
              this.batches = batchList;

              this.selectedBatch = batchList[0];
              this.loadBatchHistory();
            },
            (resError) => {
              this.toastr.error(resError.message);
            }
          );
        });
      }
    });

    this.chargeTypes = this.route.snapshot.data["chargeTypes"].map((chargeType) => ({
      label: chargeType.label,
      value: chargeType.value.id,
    }));

    this.drivers = this.route.snapshot.data["drivers"].map((driver) => ({
      label: `${driver.lastName} ${driver.name}`,
      value: driver.id,
    }));
    this.selectedDriver = this.drivers[0].value;
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  loadBatchHistory() {
    if (!this.selectedBatch) return;
    this.DeductionService.getBatchHistory(this.deduction.id, this.selectedBatch.id).subscribe(
      (data) => {
        this.batchHistory = data;
      }
    );
  }

  loadDriverHistory() {
    this.DeductionService.getDriverHistory(this.deduction.id, this.selectedDriver).subscribe(
      (data) => {
        this.driverHistory = data;
      }
    );
  }

  validate() {
    if (this.deduction.chargeTypeId == null) {
      this.toastr.error("Charge Type is required");
      return false;
    }
    if (!this.deduction.description) {
      this.toastr.error("Description is required");
      return false;
    }
    if (!this.deduction.reference) {
      this.toastr.error("Reference is required");
      return false;
    }
    return true;
  }

  createDeduction() {
    if (!this.validate()) return;

    this.loading = true;
    this.DeductionService.createDeduction(this.deduction).subscribe(
      () => {
        this.loading = false;
        this.toastr.success("Deduction Created Successfully", "Success");
        this.router.navigate(["settlements/deductions"]);
      },
      (error) => {
        this.toastr.error(error);
        this.loading = false;
      }
    );
  }

  updateDeduction() {
    if (!this.validate()) return;

    this.loading = true;

    this.DeductionService.updateDeduction(this.deduction).subscribe(
      () => {
        this.isEditing = false;
        this.loading = false;
        this.router.navigate(["settlements/deductions"]);
        this.toastr.success("Deduction Updated Successfully", "Success");
      },
      (error) => {
        this.toastr.error(error);
        this.loading = false;
      }
    );
  }
}
