<div class="rDivTable">
  <button
    type="button"
    class="btn btn-primary"
    (click)="save()"
    style="margin-bottom: 1rem"
    *ngIf="edit"
  >
    <i class="fa fa-save mr-2"></i>
    SAVE TABLE CHANGES
  </button>
  <p-table
    #dt
    [columns]="cols"
    [value]="records"
    [scrollable]="true"
    scrollHeight="flex"
    [virtualScroll]="true"
    [virtualScrollItemSize]="42"
    [autoLayout]="true"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width.px]="col.width" />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
        </th>
      </tr>

      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <input
            *ngIf="col.type !== 'driverLink'"
            type="text"
            style="width: 100%"
            (input)="$any(dt).filter($event.target.value, col.field, 'contains')"
            [value]="$any(dt).filters[col.field]?.value"
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record let-y="rowIndex">
      <tr>
        <ng-template ngFor let-col [ngForOf]="cols">
          <td *ngIf="col.type === 'driverLink'" style="text-overflow: clip">
            <img
              *ngIf="record.DriverId && record.batchNo"
              style="cursor: pointer"
              src="assets/img/details.svg"
              (click)="openDriverSettlement(record.DriverId, record.batchNo)"
            />
          </td>
          <td *ngIf="col.type === 'type'">
            {{ record.type }}
          </td>
          <td *ngIf="containsWarnings && col.type === 'warning'" class="text-warning">
            {{ record.warningMsg
            }}<a style="cursor: pointer" (click)="openFuelCard(record.warningInfo.drivers)"
              ><u>{{ record.warningInfo.card }}</u></a
            >
          </td>
          <td *ngIf="containsErrors && col.type === 'error'" [title]="record.errorMsg" class="text-danger">
            {{ record.errorMsg
            }}<a
              *ngIf="record.errorInfo"
              style="cursor: pointer"
              (click)="openFuelCard(record.errorInfo.drivers)"
              ><u>{{ record.errorInfo?.card }}</u></a
            >
            <span *ngIf="record.errorInfo?.authCode">&nbsp;{{ record.errorInfo.authCode }}</span>
          </td>
          <td *ngIf="col.type === 'code'">
            {{ record[col.field] }}
          </td>
          <td *ngIf="col.type === 'date'">
            {{ record[col.field] | date : "MM/dd/yyyy" }}
          </td>
          <td
            *ngIf="
              (col.type === 'string' || col.type === 'currency' || col.type === 'number') && edit
            "
            [ngClass]="{ 'align-right': col.type === 'currency' || col.type === 'number' }"
            class="editable-cell"
            [pEditableColumn]="record"
            [pEditableColumnField]="record[col.field]"
          >
            <p-cellEditor class="elements-table">
              <ng-template pTemplate="input">
                <input
                  *ngIf="col.type === 'string'"
                  style="width: 100%"
                  type="text"
                  [ngModel]="record[col.field]"
                  (change)="onRecordChanged($event.target.value, record, col.field)"
                />
                <input
                  *ngIf="col.type === 'number' || col.type === 'currency'"
                  style="width: 100%; text-align: right"
                  type="number"
                  min="0"
                  [ngModel]="record[col.field]"
                  (change)="onRecordChanged($event.target.value, record, col.field)"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <span
                  *ngIf="col.type === 'string'"
                  [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
                >
                  {{ record[col.field] }}
                </span>
                <span
                  *ngIf="col.type === 'number'"
                  [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
                >
                  {{ record[col.field] | number }}
                </span>
                <span
                  *ngIf="col.type === 'currency'"
                  [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
                >
                  {{ record[col.field] | currency }}
                </span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            *ngIf="
              (col.type === 'string' || col.type === 'currency' || col.type === 'number') && !edit
            "
            [ngClass]="{ 'align-right': col.type === 'currency' || col.type === 'number' }"
          >
            <span
              *ngIf="col.type === 'string'"
              [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
            >
              {{ record[col.field] }}
            </span>
            <span
              *ngIf="col.type === 'number'"
              [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
            >
              {{ record[col.field] | number }}
            </span>
            <span
              *ngIf="col.type === 'currency'"
              [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
            >
              {{ record[col.field] | currency }}
            </span>
          </td>
        </ng-template>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer" let-columns>
      <tr>
        <td *ngIf="containsWarnings"></td>
        <td *ngIf="containsErrors"></td>
        <td *ngIf="type === 'HISTORICAL'"></td>
        <td *ngIf="type === 'HISTORICAL'"></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td style="text-align: right">{{ fuelAmount1Sum | currency }}</td>
        <td style="text-align: right">{{ fuelAmount2Sum | currency }}</td>
        <td style="text-align: right">{{ nonHighwayAmountSum | currency }}</td>
        <td style="text-align: right">{{ oilAmountSum | currency }}</td>
        <td style="text-align: right">{{ otherAmount1Sum | currency }}</td>
        <td style="text-align: right">{{ otherProductAmount2Sum | currency }}</td>
        <td style="text-align: right">{{ cashSum | currency }}</td>
        <td style="text-align: right">{{ dashCashSum | currency }}</td>
        <td style="text-align: right">{{ discountAmountSum | currency }}</td>
        <td style="text-align: right">{{ customerFeeSum | currency }}</td>
        <td style="text-align: right">{{ expressSum | currency }}</td>
        <td style="text-align: right">{{ totalAmountSum | currency }}</td>
        <td style="text-align: right">{{ iNVOICETOTALSum | currency }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- Modal -->
<div id="modalEdit" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg modalWidth">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title"><strong>EDIT TCHEK</strong></h4>
        <br />
      </div>
      <div class="modal-body">
        <div class="col-md-3">
          <div class="form-group">
            <label>Authorization Code:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.authorizationCode" />
          </div>
          <div class="form-group">
            <label>timeCT:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.timeCT" />
          </div>
          <div class="form-group">
            <label>site:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.site" />
          </div>
          <div class="form-group">
            <label>state:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.state" />
          </div>
          <div class="form-group">
            <label>siteInvoice:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.siteInvoice" />
          </div>
          <div class="form-group">
            <label>transactionDate:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.transactionDate" />
          </div>
          <div class="form-group">
            <label>driverID:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.driverNo" />
          </div>
          <div class="form-group">
            <label>driverCDL:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.driverCDL" />
          </div>
          <div class="form-group">
            <label>otherID:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.otherID" />
          </div>
          <div class="form-group">
            <label>fuelQuantity1:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.fuelQuantity1" />
          </div>
          <div class="form-group">
            <label>fuelPPU1:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.fuelPPU1" />
          </div>
          <div class="form-group">
            <label>fuelCode2:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.fuelCode2" />
          </div>
          <div class="form-group">
            <label>fuelQuantity2:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.fuelQuantity2" />
          </div>
          <div class="form-group">
            <label>otherAmount1:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.otherAmount1" />
          </div>
          <div class="form-group">
            <label>otherAmount1PlusPilot:</label>
            <input
              class="form-control"
              type="text"
              [(ngModel)]="recordModal.otherAmount1PlusPilot"
            />
          </div>
          <div class="form-group">
            <label>otherProductCode2:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.otherProductCode2" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Express:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.express" />
          </div>
          <div class="form-group">
            <label>recordType:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.recordType" />
          </div>
          <div class="form-group">
            <label>name:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.name" />
          </div>
          <div class="form-group">
            <label>transactionTime:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.transactionTime" />
          </div>
          <div class="form-group">
            <label>company:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.company" />
          </div>
          <div class="form-group">
            <label>account:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.account" />
          </div>
          <div class="form-group">
            <label>tractorUnitNumber:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.tractorUnitNumber" />
          </div>
          <div class="form-group">
            <label>trailer:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.trailer" />
          </div>
          <div class="form-group">
            <label>odometer:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.odometer" />
          </div>
          <div class="form-group">
            <label>fuelAmount2:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.fuelAmount2" />
          </div>
          <div class="form-group">
            <label>fuelPPU2:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.fuelPPU2" />
          </div>
          <div class="form-group">
            <label>nonHighwayCode:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.nonHighwayCode" />
          </div>
          <div class="form-group">
            <label>otherProductAmount2:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.otherProductAmount2" />
          </div>
          <div class="form-group">
            <label>cash:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.cash" />
          </div>
          <div class="form-group">
            <label>dashCash:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.dashCash" />
          </div>
          <div class="form-group">
            <label>date:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.date" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>siteGroup:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.siteGroup" />
          </div>
          <div class="form-group">
            <label>city:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.city" />
          </div>
          <div class="form-group">
            <label>cardGroup:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.cardGroup" />
          </div>
          <div class="form-group">
            <label>card:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.card" />
          </div>
          <div class="form-group">
            <label>driverName:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.driverName" />
          </div>
          <div class="form-group">
            <label>previousOdometer:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.previousOdometer" />
          </div>
          <div class="form-group">
            <label>trip:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.trip" />
          </div>
          <div class="form-group">
            <label>fuelCode1:</label>
            <input type="text" class="form-control" [(ngModel)]="recordModal.fuelCode1" />
          </div>
          <div class="form-group">
            <label>nonHighwayPPU:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.nonHighwayPPU" />
          </div>
          <div class="form-group">
            <label>oilQuantity:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.oilQuantity" />
          </div>
          <div class="form-group">
            <label>oilPPU:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.oilPPU" />
          </div>
          <div class="form-group">
            <label>otherProductCode1:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.otherProductCode1" />
          </div>
          <div class="form-group">
            <label>payChekAmount:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.payChekAmount" />
          </div>
          <div class="form-group">
            <label>discountAmount:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.discountAmount" />
          </div>
          <div class="form-group">
            <label>discountType:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.discountType" />
          </div>
          <div class="form-group">
            <label>discountValue:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.discountValue" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>customerFee:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.customerFee" />
          </div>
          <div class="form-group">
            <label>payChekFee:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.payChekFee" />
          </div>
          <div class="form-group">
            <label>payChekFeePaidBy:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.payChekFeePaidBy" />
          </div>
          <div class="form-group">
            <label>totalAmount:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.totalAmount" />
          </div>
          <div class="form-group">
            <label>estimatedGSTHST:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.estimatedGSTHST" />
          </div>
          <div class="form-group">
            <label>estimatedQST:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.estimatedQST" />
          </div>
          <div class="form-group">
            <label>siteCurrency:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.siteCurrency" />
          </div>
          <div class="form-group">
            <label>billCurrency:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.billCurrency" />
          </div>
          <div class="form-group">
            <label>iNVOICETOTAL:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.iNVOICETOTAL" />
          </div>
          <div class="form-group">
            <label>pilotAdded:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.pilotAdded" />
          </div>
          <div class="form-group">
            <label>calculatedTotalAmount:</label>
            <input
              class="form-control"
              type="text"
              [(ngModel)]="recordModal.calculatedTotalAmount"
            />
          </div>
          <div class="form-group">
            <label>fuelAmount1:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.fuelAmount1" />
          </div>
          <div class="form-group">
            <label>fuel1AmountImported:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.fuel1AmountImported" />
          </div>
          <div class="form-group">
            <label>nonHighwayAmount:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.nonHighwayAmount" />
          </div>
          <div class="form-group">
            <label>fuel2AmountImported:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.fuel2AmountImported" />
          </div>
          <div class="form-group">
            <label>oilAmount:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.oilAmount" />
          </div>
          <div class="form-group">
            <label>oilAmountImported:</label>
            <input class="form-control" type="text" [(ngModel)]="recordModal.oilAmountImported" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          (click)="saveRowSelectedToEdit(recordModal)"
          data-dismiss="modal"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-default"
          (click)="cancelRowEdition()"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
