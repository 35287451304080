<div class="container">
  <div class="row firstRowContent">
    <legend>All Loads for Batch {{ batchInfo?.number }}</legend>
    <div class="col-md-6 divTableZero">
      <div *ngIf="trips.length > 0">
        Total: {{ totalEarnings | currency }}
        <button
          class="btn"
          style="background: lightgrey; margin-bottom: 6px"
          (click)="selectAllEarnings()"
        >
          Select All Earnings
        </button>
      </div>
      <div *ngFor="let trip of trips; let i = index">
        <div class="toggleClassTrip">
          <span>
            <span class="titleTable"
              >TRIP
              <a [routerLink]="'/new/edittrip/' + trip.id + '/T'" target="_blank">{{
                trip.tripNo
              }}</a>
            </span>
            <span class="titleTable">{{ trip.driverName }}</span>
            <span class="titleTable"
              >{{ trip.startDate | date : "MM/dd/yyyy" }} -
              {{ trip.endDate | date : "MM/dd/yyyy" }}</span
            >
            <span class="titleTable">TOTAL {{ trip.netNonTripExpense | currency }}</span>
            <span class="titleTable">
              INCLUDE:&nbsp;
              <div class="titleTable statusSwitch">
                <p-checkbox
                  [(ngModel)]="trip.include"
                  binary="true"
                  name="switchActive"
                  (onChange)="calculateTotal()"
                  [disabled]="!batchInfo.isOpen"
                >
                </p-checkbox>
              </div>
            </span>
            <span
              class="imageCollapse"
              data-toggle="collapse"
              [attr.data-target]="'#' + trip.tripNo"
              style="cursor: pointer"
              ><img src="assets\img\arrow-down.svg" width="15"
            /></span>
          </span>
        </div>
        <div id="{{ trip.tripNo }}" class="collapse borderDivIntTrip">
          <p-table #dt1 [columns]="colsCharge" [value]="trip.driverCharges" [autoLayout]="true">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">
                  {{ col.header }}
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-charge let-y="rowIndex">
              <tr *ngIf="charge.delete == false">
                <!-- Charge Type -->
                <td
                  id="celda0{{ y }}scrollable"
                  [pEditableColumn]="charge"
                  [pEditableColumnField]="charge.chargeType"
                >
                  <div>
                    <p-cellEditor class="elements-table">
                      <ng-template pTemplate="input">
                        <p-dropdown
                          [options]="driverChargeTypes"
                          [(ngModel)]="charge.chargeType"
                          (onChange)="editCharge(charge)"
                          placeholder="Select a Charge Type"
                          filter="true"
                          [disabled]="!batchInfo.isOpen"
                          appendTo="body"
                          tabbableDropdown
                        ></p-dropdown>
                      </ng-template>
                      <ng-template pTemplate="output" style="text-align: center">
                        {{ charge.chargeType?.description }}
                      </ng-template>
                    </p-cellEditor>
                  </div>
                </td>
                <!-- Description -->
                <td
                  id="celda1{{ y }}scrollable"
                  [pEditableColumn]="charge"
                  [pEditableColumnField]="charge.description"
                >
                  <div>
                    <p-cellEditor class="elements-table">
                      <ng-template pTemplate="input">
                        <input
                          pInputText
                          type="text"
                          [(ngModel)]="charge.description"
                          (change)="editCharge(charge)"
                          [disabled]="!batchInfo.isOpen"
                        />
                      </ng-template>
                      <ng-template pTemplate="output" style="text-align: center">
                        {{ charge.description }}
                      </ng-template>
                    </p-cellEditor>
                  </div>
                </td>
                <!-- Units -->
                <td
                  id="celda2{{ y }}scrollable"
                  [pEditableColumn]="charge"
                  [pEditableColumnField]="charge.units"
                  style="text-align: right"
                >
                  <div>
                    <p-cellEditor class="elements-table">
                      <ng-template pTemplate="input">
                        <input
                          pInputText
                          type="number"
                          [(ngModel)]="charge.units"
                          (change)="editCharge(charge)"
                          [disabled]="!batchInfo.isOpen"
                        />
                      </ng-template>
                      <ng-template pTemplate="output" style="text-align: center">
                        {{ charge.units | number : "1.2-2" }}
                      </ng-template>
                    </p-cellEditor>
                  </div>
                </td>
                <!-- Miles -->
                <td
                  id="celda3{{ y }}scrollable"
                  [pEditableColumn]="charge"
                  [pEditableColumnField]="charge.miles"
                  style="text-align: right"
                >
                  <div>
                    <p-cellEditor class="elements-table">
                      <ng-template pTemplate="input">
                        <input
                          pInputText
                          type="number"
                          [(ngModel)]="charge.miles"
                          (change)="editCharge(charge)"
                          [disabled]="!batchInfo.isOpen"
                        />
                      </ng-template>
                      <ng-template pTemplate="output" style="text-align: center">
                        {{ charge.miles | number }}
                      </ng-template>
                    </p-cellEditor>
                  </div>
                </td>
                <!-- Rate -->
                <td
                  id="celda4{{ y }}scrollable"
                  [pEditableColumn]="charge"
                  [pEditableColumnField]="charge.chargeRate"
                  style="text-align: right"
                >
                  <div>
                    <p-cellEditor class="elements-table">
                      <ng-template pTemplate="input">
                        <input
                          pInputText
                          type="number"
                          [(ngModel)]="charge.chargeRate"
                          (change)="editCharge(charge)"
                          [disabled]="!batchInfo.isOpen"
                        />
                      </ng-template>
                      <ng-template pTemplate="output" style="text-align: center">
                        {{ charge.chargeRate | currency }}
                      </ng-template>
                    </p-cellEditor>
                  </div>
                </td>
                <!-- Total -->
                <td style="text-align: right">{{ charge.price | currency }}</td>
                <!-- Delete -->
                <td>
                  <button
                    (click)="removeCharge(trip.driverCharges, charge)"
                    [disabled]="!batchInfo.isOpen"
                  >
                    -
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <div align="center" style="margin-top: 20px">
        <button
          class="btn buttonBottom minWidthbutton"
          (click)="saveChanges()"
          [disabled]="!batchInfo?.isOpen"
        >
          SAVE
        </button>
      </div>
    </div>
  </div>
</div>
