<div class="modal fade" id="modalImportDeductions" role="dialog">
  <div class="modal-dialog" style="width: 1400px">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="cancel()" #closebutton>
          &times;
        </button>
        <h4 class="modal-title">Import Deductions File</h4>
      </div>
      <div class="modal-body">
        <div *ngIf="!parsedResults" draggable="true" ngClass="{{ dragAreaClass }} dragcc">
          <div class="col-md-12 text-center">
            <a>
              <br />
              <br />
              <span style="font-size: 16px; color: #777">Drag & Drop your Deductions .csv</span>
              <br />
              <br />
            </a>
            <br />
            <a href="javascript:void(0)" (click)="file.click()">
              <a class="textBrows btn"> -Browse Files+- </a>
              <a class="btnBrows btn">Browse</a>
            </a>
            <br />
            <br />

            <input
              type="file"
              #file
              [multiple]="false"
              (change)="onFileChange($event)"
              style="display: none"
            />
          </div>
          <br />
        </div>
        <div *ngIf="parsedResults">
          <div *ngIf="errors.length > 0">
            <div class="alert alert-danger">
              <strong>Errors:</strong>
              <ul>
                <li *ngFor="let error of errors">{{ error }}</li>
              </ul>
            </div>
          </div>
          <div *ngIf="warnings?.length > 0">
            <div class="alert alert-warning">
              <strong>Warnings:</strong>
              <ul>
                <li *ngFor="let warning of warnings">{{ warning }}</li>
              </ul>
            </div>
          </div>
          <div *ngIf="errors?.length == 0">
            <p-table
              *ngIf="errorRows.length > 0"
              [columns]="errCols"
              [value]="errorRows"
              [scrollable]="true"
              scrollHeight="600px"
              [virtualScroll]="true"
            >
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [style.width.px]="col.width || 150" />
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.header">
                    {{ col.header }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-record let-y="rowIndex">
                <tr>
                  <ng-template ngFor let-col [ngForOf]="errCols">
                    <td *ngIf="col.field === 'errors'">
                      <li
                        style="white-space: normal; text-align: left"
                        *ngFor="let error of record[col.field]"
                        class="text-danger"
                      >
                        {{ error }}
                      </li>
                    </td>
                    <td *ngIf="col.field === 'warnings'">
                      <li
                        style="white-space: normal; text-align: left"
                        *ngFor="let warning of record[col.field]"
                      >
                        {{ warning }}
                      </li>
                    </td>
                    <td *ngIf="col.field !== 'warnings' && col.field !== 'errors'">
                      {{ record[col.field] }}
                    </td>
                  </ng-template>
                </tr>
              </ng-template>
            </p-table>
            <p-table
              *ngIf="rows.length > 0"
              [columns]="cols"
              [value]="rows"
              [scrollable]="true"
              scrollHeight="600px"
              [virtualScroll]="true"
            >
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [style.width.px]="col.width || 150" />
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.header">
                    {{ col.header }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-record let-y="rowIndex">
                <tr>
                  <ng-template ngFor let-col [ngForOf]="cols">
                    <td>
                      {{ record[col.field] }}
                    </td>
                  </ng-template>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="modal-footer" style="margin-top: 1rem">
          <button
            *ngIf="!parsedResults"
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
            (click)="cancel()"
          >
            Close
          </button>
          <button *ngIf="parsedResults" type="button" class="btn btn-default" (click)="cancel()">
            Cancel
          </button>
          <button
            *ngIf="parsedResults"
            type="button"
            class="btn btn-primary"
            (click)="import()"
            [disabled]="errors.length > 0"
          >
            Import Deductions
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
