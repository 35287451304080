import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-editable-table",
  templateUrl: "./editable-table.component.html",
  styleUrls: ["./editable-table.component.css"],
})
export class EditableTableComponent implements OnInit {
  @Input() rows: any[];
  @Input() columns: { field: string; name: string; type: string }[];

  // Optional inputs
  @Input() defaultPrice: number = 0;
  @Input() isEditing: boolean = true;
  @Input() lastInputColumnField: string;

  @Output() rowsChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  addRow() {
    // Insert a new row
    let stopCount;
    if (this.rows.length > 0) {
      const lastRow = this.rows[this.rows.length - 1];
      stopCount = lastRow["values"]["stopCount"];
    }

    let newRow = {};
    newRow["values"] = {};
    for (let col of this.columns) {
      if (col.field === "stopCount" && stopCount != undefined) {
        newRow["values"][col.field] = stopCount + 1;
      } else {
        newRow["values"][col.field] = this.defaultPrice;
      }
    }

    this.rows.push(newRow);

    this.updatedRows();
  }

  deleteRow(rowIndex: number) {
    this.rows.splice(rowIndex, 1);
    this.updatedRows();
  }

  updatedRows() {
    let i = 1;
    for (let row of this.rows) {
      row.values.stopCount = i;
      i++;
    }
    this.rowsChange.emit(this.rows);
  }

  showPlusIcon(rowIndex: number, col: any) {
    return rowIndex === this.rows.length - 1 && col.field === "stopCount";
  }

  onTab(field: any, rowIndex: any) {
    if (
      field === this.lastInputColumnField &&
      this.rows.length - 1 === rowIndex
    ) {
      this.addRow();
    } else if (
      this.lastInputColumnField === undefined &&
      field === this.columns[this.columns.length - 1].field &&
      this.rows.length - 1 === rowIndex
    ) {
      this.addRow();
    }
  }
}
