<div>
  <a class="btn btnUpload" (click)="displayImportModal = true">
    <img src="assets/img/upload.svg" class="imgUpload" width="12" />
    <span>Upload Files</span>
  </a>
</div>

<p-dialog
  header="Import/Merge Tchek Files"
  [(visible)]="displayImportModal"
  [modal]="true"
  [style]="{ width: '500px', minWidth: '300px' }"
>
  <div draggable="true" ngClass="{{ dragAreaClass }}  dragcc">
    <div class="row rowT">
      <div class="col-md-12 text-center">
        <a>
          <br />
          <br />
          <span style="font-size: 16px; color: #777">Drag & Drop your Files</span>
          <br />
          <br />
        </a>
        <br />
        <a href="javascript:void(0)" (click)="file.click()">
          <a class="textBrows btn"> -Browse Files+- </a>
          <a class="btnBrows btn">Browse</a>
        </a>
        <div>
          <br />
          <ul class="listResumen">
            <li *ngFor="let err of globalFiles; let i = index">
              <img src="assets/img/file.svg" width="15" /> {{ err.name }}
              {{ err.size / 1000 | number : "1.1-1" }}KB
              <button type="button" class="close" (click)="deleteFile(i)">&times;</button>
            </li>
          </ul>
        </div>
        <br />
        <br />

        <input
          type="file"
          #file
          [multiple]="maxFiles > 1"
          (change)="onFileChange($event)"
          style="display: none"
        />
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary mr-3" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="uploadFiles()">
      <img src="assets/img/upload.svg" class="imgUpload" width="12" />
      Merge
    </button>
  </p-footer>
</p-dialog>
