import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SelectItem } from "primeng/api";
import { MaskService } from "../../../shared/services/mask/mask.service";
import { DriverMinimumRateViewModel } from "@app/shared/models/rates/MinimumRateViewModel.model";

@Component({
  selector: "app-add-minimum-driver",
  templateUrl: "./add-minimum-driver.component.html",
  styleUrls: ["./add-minimum-driver.component.css"],
})
export class AddMinimumDriverComponent {
  @Input() allDrivers!: SelectItem[];
  @Input() isEditing!: boolean;
  @Input() drivers!: DriverMinimumRateViewModel[];
  @Output() driversChange = new EventEmitter();
  dateInputMask: any;
  constructor(private maskService: MaskService) {}

  ngOnInit(): void {
    this.dateInputMask = this.maskService.getInputDateOnlyMask();
  }

  addDriver() {
    this.drivers.push({
      validFromUtc: new Date(),
    });
  }

  removeDriver(driver) {
    this.drivers = this.drivers.filter((d) => d !== driver);
    this.driversChange.emit(this.drivers);
  }
}
