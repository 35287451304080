import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng/api";
import { SettlementsService } from "../../shared/settlements.service";

@Component({
  selector: "app-send-batch-emails",
  templateUrl: "./send-batch-emails.component.html",
  styleUrls: ["./send-batch-emails.component.css"],
})
export class SendBatchEmailsComponent implements OnInit {
  @Input() model: any;
  @Output("refreshGrid") refreshGrid: EventEmitter<any> = new EventEmitter();
  constructor(
    private _SettlementsService: SettlementsService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {}

  onSubmit() {

    this._SettlementsService.sendDriverSettlementEmails(this.model).subscribe(() => {
      this.closeModal();
      this.refreshGrid.emit();
    });
  }


  closeModal() {
    const closeModal = document.getElementsByClassName(
      "p-ripple"
    )[0] as HTMLElement;
    closeModal.click();
  }
}
