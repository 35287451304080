export enum Permissions {
  ClientsRead = "Clients Read",
  ClientsChange = "Clients Add/Edit",
  LessorRead = "Lessor Read",
  LessorChange = "Lessor Add/Edit",
  ProductsRead = "Products Read",
  ProductsChange = "Products Add/Edit",
  ClientProductsRead = "Client Products Read",
  ClientProductsChange = "Client Products Add/Edit",
  EquipmentCodesRead = "Equipment Codes Read",
  EquipmentCodesChange = "Equipment Codes Add/Edit",
  BusinessRulesRead = "Business Rules Read",
  BusinessRulesChange = "Business Rules Add/Edit",
  PeopleNetChange = "PeopleNet Testing Tool Add/Edit",
  AccessorialsRead = "Accessorials Read",
  AccessorialsChange = "Accessorials Add/Edit",
  LocationsRead = "Locations Read",
  LocationsChange = "Locations Add/Edit",
  TChekImportChange = "T-Chek Import Add/Edit",
  TChekExpensesChange = "T-Chek Fuel Expenses Add/Edit",
  TChekExpressCodesChange = "T-Chek Express Codes Add/Edit",
  TChekHistoricalChange = "T-Chek Historical Add/Edit",
  DashboardRead = "Dashboard Read",
  DashboardChange = "Dashboard Add/Edit",
  BillingQueueRead = "Billing Queue Read",
  BillingQueueChange = "Billing Queue Add/Edit",
  BillingReviewRead = "Billing Review Read",
  BillingReviewChange = "Billing Review Add/Edit",
  CancelledTripsChange = "Cancelled Trips Edit",
  ReadyForBillRead = "Ready For Bill Read",
  BilledRead = "Billed Read",
  BilledChange = "Billed Add/Edit (Export and print)",
  CancelledRead = "Cancelled Read",
  DriversRead = "Drivers Read",
  DriversChange = "Drivers Add/Edit/Delete",
  TractorsRead = "Tractors Read",
  TractorsChange = "Tractors Add/Edit/Delete",
  TrailersRead = "Trailers Read",
  TrailersChange = "Trailers Add/Edit/Delete",
  FuelReportRead = "Fuel Report Read",
  SettlementsRead = "Settlements Read",
  SettlementsChange = "Settlements Add/Edit",
  WeeklyReportChange = "Create Weekly Report Add/Edit",
  WeeklyReportRead = "Previous Weekly Reports Read",
  PCMilerChange = "PC Miler Add/Edit",
  UsersChange = "Users Add/Edit",
  RolesChange = "Roles Add/Edit",
  BatchChange = "Batch Add/Edit",
  ViewsChange = "Views Add/Edit",
  ConfigChange = "Config Add/Edit",
  Admin = "Admin",
}
