<div class="container">
  <div class="col-md-12">
    <legend>Client Product List</legend>
    <div style="display: inline-block">
      <p-table #dt [columns]="colsClients" [value]="clientList" [autoLayout]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" pResizableColumn>
              {{ col.header }}
            </th>
          </tr>
          <tr>
            <th>
              <input
                type="text"
                style="width: 100%"
                (input)="$any(dt).filter($event.target.value, 'customerNo', 'contains')"
                [value]="$any(dt).filters['customerNo']?.value"
              />
            </th>
            <th>
              <input
                type="text"
                style="width: 100%"
                (input)="$any(dt).filter($event.target.value, 'companyName', 'contains')"
                [value]="$any(dt).filters['companyName']?.value"
              />
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-client>
          <tr>
            <td>{{ client.customerNo }}</td>
            <td>{{ client.companyName }}</td>
            <td>
              <div
                *ngIf="client.ClientProducts.length"
                style="display: table-cell; vertical-align: middle"
              >
                <div *ngFor="let clientProduct of client.ClientProducts">
                  <div *ngIf="clientProduct && clientProduct.Product">
                    {{ clientProduct.Product.name }}:
                    {{ clientProduct.Product.un }}
                    <a
                      *ngIf="clientProduct.hazardDocument"
                      target="_blank"
                      [appGetFile]="getFilePath(clientProduct.hazardDocument)"
                      [appOpenFile]="true"
                    >
                      <i
                        class="glyphicon glyphicon-zoom-in"
                        styleClass="btn"
                        style="cursor: pointer"
                      ></i>
                    </a>
                    <a
                      (click)="deleteClientProduct(client, clientProduct)"
                      *ngIf="hasPermission('ClientProductsChange')"
                    >
                      <i class="pi pi-trash" styleClass="btn" style="cursor: pointer"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div style="display: table-cell; vertical-align: middle">
                <span style="display: block">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    (click)="viewDetail(client)"
                    class="btn btnDetails"
                    *ngIf="hasPermission('ClientProductsChange')"
                  >
                    Associate Product
                  </button>
                </span>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Associate Product</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="error.length" class="alert alert-block alert-danger">
          {{ error }}
        </div>
        <h5 *ngIf="selClient">{{ selClient.companyName }}: {{ selClient.customerNo }}</h5>
        <h4>New Client Product</h4>
        <p-dropdown
          #productDropDown
          [showTransitionOptions]="'0ms'"
          [hideTransitionOptions]="'0ms'"
          [style]="{ width: '83%' }"
          [options]="availableProducts"
          [(ngModel)]="newProductId"
          id="productSelect"
          placeholder="Select a Product"
          (onChange)="onChange($event)"
          tabbableDropdown
        ></p-dropdown>
        <br />
        <input
          #inputFile
          [disabled]="!newProductId"
          class="form-control-file inpFile"
          type="file"
          (change)="getTemporalImage($event)"
          placeholder="Upload file..."
        />
        <br />
        <button
          [disabled]="!newProductId"
          type="button"
          class="btn btn-primary"
          (click)="addClientProduct()"
        >
          Add client product
        </button>
        <br />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<script-hack
  >$('.modal-content').draggable({{ "{" }}'handle':'.modal-header'{{ "}" }});</script-hack
>
