import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { CanComponentDeactivate } from "@app/shared/guards/confirmation/confirmation.guard";
import { ToastrService } from "ngx-toastr";
import { TeamsService } from "../shared/teams.service";
import { Permissions } from "@models/permissions.enum";
import { DriverViewModel } from "@app/shared/models/drivers/DriverViewModel.model";
import { DriverService } from "@app/driver/shared/driver.service";
import { Team } from "@app/shared/models/Team";
import { SelectItem } from "primeng/api";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-team",
  templateUrl: "./team.component.html",
  styleUrls: ["./team.component.css"],
})
export class TeamComponent implements OnInit, CanComponentDeactivate {
  @ViewChild("closebutton") closebutton: {
    nativeElement: { click: () => void };
  };

  team: Team = {
    name: "",
    drivers: [],
  };
  totalPercent: number;

  id: string;
  isGuardActive: boolean = true;
  editing: boolean = false;
  creating: boolean = false;
  notFound: boolean = false;

  teamTypes: SelectItem[] = [
    { label: "COMPANY DRIVER", value: "COMPANY DRIVER" },
    { label: "OWNER OPERATOR", value: "OWNER OPERATOR" },
    { label: "LESSOR", value: "LESSOR" },
  ];

  // add driver modal
  drivers: DriverViewModel[] = [];
  allDrivers: DriverViewModel[] = [];
  selectedDriver: any;

  constructor(
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: authenticationService,
    private driverService: DriverService,
    private teamsService: TeamsService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params["id"] === "new" ? null : params["id"];
      this.creating = params["id"] === "new";
    });

    if (this.id && this.id.length) {
      this.teamsService.getTeam(this.id).subscribe(
        (team) => {
          this.team = team;
          this.calculateTotalPercent();
          this.loadDrivers();
        },
        (err) => {
          this.notFound = true;
        }
      );
    } else {
      this.loadDrivers();
      this.editing = true;
    }
  }

  loadDrivers() {
    this.driverService.getTeamlessDrivers().subscribe((drivers: any) => {
      this.drivers = drivers
        .map((driver: DriverViewModel) => ({
          ...driver,
          itemName: `${driver.lastName}, ${driver.name}`,
          id: driver.id,
        }))
        .filter((driver: DriverViewModel) => driver.lastName !== null);
      this.allDrivers = this.drivers;

      this.filterDrivers();
    });
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  confirm() {
    if (this.editing && this.isGuardActive) {
      return window.confirm("Are you sure you want to leave this page?");
    } else {
      return true;
    }
  }

  calculateTotalPercent() {
    this.totalPercent = this.team.drivers.reduce(
      (acc: number, curr: DriverViewModel) => acc + (curr.earningPercentage || 0),
      0
    );
  }

  filterDrivers() {
    if (this.team.drivers.length > 0) {
      this.drivers = this.allDrivers.filter(
        (driver) => !this.team.drivers.find((teamDriver) => teamDriver.id === driver.id)
      );
    }
    if (this.team.type) {
      this.drivers = this.drivers.filter((driver) => driver.type === this.team.type);
    }
  }

  deleteTeam() {
    this.confirmationService.confirm({
      key: "deleteDialog",
      message: "Do you really want to delete this Team?",
      accept: () => {
        this.teamsService.deleteTeam(this.team.id).subscribe(
          () => {
            this.isGuardActive = false;
            this.router.navigate(["/teams"]);
            this.toastr.success("Team Deleted");
          },
          (error) => {
            this.toastr.warning("Error deleting team");
          }
        );
        this.closeModal();
      },
      reject: () => {},
    });
  }

  createTeam() {
    if (!this.validateTeam()) return;
    this.teamsService.createTeam(this.team).subscribe(
      () => {
        this.isGuardActive = false;
        this.router.navigate(["/teams"]);
        this.toastr.success("Team Created");
      },
      (error) => {
        this.toastr.warning("Error creating team");
      }
    );
  }

  saveTeam() {
    if (!this.validateTeam()) return;
    this.teamsService.updateTeam(this.team).subscribe(
      () => {
        this.isGuardActive = false;
        this.router.navigate(["/teams"]);
        this.toastr.success("Team Updated");
      },
      (error) => {
        this.toastr.warning("Error updating team");
      }
    );
  }

  validateTeam() {
    if (this.totalPercent !== 100) {
      this.toastr.warning("Total percentage must equal 100%");
      return false;
    }
    const numPayTos = this.team.drivers.filter((driver) => driver.isPayTo).length;
    if (numPayTos !== 1) {
      this.toastr.warning("There must be exactly one pay to driver");
      return false;
    }
    return true;
  }

  addDriver() {
    this.selectedDriver = this.drivers.find((driver) => driver.id === this.selectedDriver.id);
    this.selectedDriver.earningPercentage = 0;
    this.team.drivers.push(this.selectedDriver);
    // remove this driver from the dropdown list
    this.drivers = this.drivers.filter((driver) => driver.id !== this.selectedDriver.id);
    this.team.type = this.selectedDriver.type;
    this.filterDrivers();
    this.closeModal();
  }

  removeDriver(driver: any) {
    this.team.drivers = this.team.drivers.filter(
      (teamDriver: DriverViewModel) => teamDriver.id !== driver.id
    );
    // add this driver to the dropdownlist of drivers
    driver.itemName = `${driver.name} ${driver.lastName}`;
    this.drivers.push(driver);
    this.drivers = [...this.drivers];
    this.drivers.sort((a, b) => {
      return a.lastName.localeCompare(b.lastName);
    });
    this.calculateTotalPercent();
    if (this.team.drivers.length === 0) {
      this.team.type = null;
      this.filterDrivers();
    }
  }

  clearDriver() {
    this.selectedDriver = null;
  }

  closeModal() {
    this.closebutton.nativeElement.click();
  }
}
