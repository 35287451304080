import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clientprofile',
  templateUrl: './clientprofile.component.html',
  styleUrls: ['./clientprofile.component.css']
})
export class ClientprofileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
