<div class="container">
  <div class="col-md-12">
    <div>
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item active" *ngIf="hasPermission('PCMilerChange')">
          <a class="nav-link" href="#PcMiler" role="tab" data-toggle="tab">PC MILER</a>
        </li>
        <li class="nav-item" *ngIf="hasPermission('UsersChange')">
          <a class="nav-link" href="#users" role="tab" data-toggle="tab">USERS</a>
        </li>
        <li class="nav-item" *ngIf="hasPermission('RolesChange')">
          <a class="nav-link" href="#roles" role="tab" data-toggle="tab">ROLES</a>
        </li>
        <li class="nav-item" *ngIf="hasPermission('ConfigChange')">
          <a class="nav-link" href="#config" role="tab" data-toggle="tab">CONFIG</a>
        </li>
      </ul>
    </div>
    <!-- Tab panes -->
    <div class="tab-content white-bg-tabs">
      <div class="tab-pane active" id="PcMiler">
        <app-pc-miller (isGuardActive)="isGuardActive = $event"></app-pc-miller>
      </div>
      <div class="tab-pane" id="users">
        <app-listusers (isGuardActive)="isGuardActive = $event"></app-listusers>
      </div>
      <div class="tab-pane" id="roles">
        <app-listroles></app-listroles>
      </div>
      <div class="tab-pane" id="config">
        <app-config (isGuardActive)="isGuardActive = $event"></app-config>
      </div>
    </div>

    <p-confirmDialog
      header="Confirmation"
      icon="pi pi-exclamation-triangle"
      [style]="{ width: '50vw' }"
    ></p-confirmDialog>
  </div>
</div>
