import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TcheckService } from "../../../tcheck/tcheck.service";
import { DriverService } from "../../../driver/shared/driver.service";
import { TchekTablesService } from "@app/tcheck/tchek-tables.service";
import { SelectItem } from "primeng/api";
import { ChargetypesService } from "@app/chargeType/shared/chargetypes.service";

@Component({
  selector: "app-table-company-expenses",
  templateUrl: "./table-company-expenses.component.html",
  styleUrls: ["./table-company-expenses.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCompanyExpensesComponent implements OnInit, OnChanges {
  @Input() records: any[];
  @Input() batchNo: number;
  @Output() onRecordsSaved = new EventEmitter<any>();

  cols: any[];
  drivers = [];
  totals: any[];
  chargeTypes: SelectItem[] = [];
  doingBulkUpdate: boolean = false;
  bulkUpdateChargeTypeId: number = null;
  numMissingChargeTypes: number = 0;

  constructor(
    private toastr: ToastrService,
    private tcheckService: TcheckService,
    private tchekTables: TchekTablesService,
    private chargeTypesService: ChargetypesService,
    private driverService: DriverService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.cols = this.tchekTables.companyExpensesCols;

    this.chargeTypesService.listDropDownJustId().subscribe(
      (chargeTypes: any[]) => {
        this.chargeTypes = chargeTypes;

        this.updateChargeTypeFilters();
        this.cdr.markForCheck();
      },
      () => this.toastr.error("Error loading charge types")
    );
  }

  ngOnChanges(changes: any) {
    this.cols = this.tchekTables.companyExpensesCols;
    this.updateTotals();
    this.checkMissingChargeTypes();

    // check if batchNo has changed
    const batchNoChange = changes.batchNo;
    if (batchNoChange && batchNoChange.currentValue !== batchNoChange.previousValue) {
      this.updateBatchDriversDropdown();
    }
  }

  updateBatchDriversDropdown() {
    this.driverService.getBatchDriversDropdown(this.batchNo).subscribe(
      (drivers: any) => {
        this.drivers = drivers;
        // add an empty option to the beginning of the list
        this.drivers.unshift({ value: null, label: "Select a Driver" });

        this.updateDriverFilters();
        this.cdr.markForCheck();
      },
      (error) => {
        this.toastr.error("Error getting drivers");
      }
    );
  }

  updateDriverFilters() {
    for (const record of this.records) {
      const driver = this.drivers.find((d) => d.value === record.batchDriverId);
      record.filterDriverName = driver ? driver.label : "";
    }
  }

  updateChargeTypeFilters() {
    for (const record of this.records) {
      const chargeType = this.chargeTypes.find((d) => d.value === record.chargeTypeId);
      record.filterChargeType = chargeType ? chargeType.label : "";
    }
  }

  updateTotals() {
    this.totals = this.tchekTables.calculateTotals(this.cols, this.records);
  }

  checkMissingChargeTypes() {
    this.numMissingChargeTypes = this.records.filter(
      (record) => record.invoicetotal != 0 && record.chargeTypeId == null
    ).length;
  }

  bulkUpdateChargeTypes() {
    for (const record of this.records) {
      if (record.shouldBulkUpdate) {
        record.chargeTypeId = this.bulkUpdateChargeTypeId;
        record.softUpdate = true;
        record.shouldBulkUpdate = false;
      }
    }
  }

  onRecordChanged(newValue: any, record: any, field: string) {
    const oldValue = record[field];
    // if this is the first time the record has been changed, log its original value
    const key = `${field}OriginalValue`;
    if (record[key] === undefined) record[key] = oldValue;
    // mark if the record has been changed
    record[`${field}Changed`] = record[key] !== newValue;
    // mark the record as one we want to update when we save
    record.softUpdate = true;

    // set the new value
    record[field] = newValue;

    this.updateDriverFilters();
    this.updateChargeTypeFilters();
  }

  updateRecords() {
    const updatedRecords = this.records.filter((record) => record.softUpdate);

    for (const record of updatedRecords) {
      if (!record.authorizationCode.startsWith("A")) record.card = "0";
    }

    this.tcheckService.updateExpenses(updatedRecords).subscribe(
      () => {
        this.toastr.success("Expenses updated", "Success");
        this.records = this.records.filter((record) => !record.isDriverTransaction);
        this.onRecordsSaved.emit();
        this.cdr.markForCheck();
      },
      () => this.toastr.error("Error updating expenses")
    );
  }

  deleteRecord(record: any) {
    if (confirm("Are you sure you want to delete this record?")) {
      this.tcheckService.deleteRecord(record.id).subscribe(
        () => {
          this.toastr.success("Expense deleted", "Success");
          this.records = this.records.filter((r) => r.id != record.id);
          this.cdr.markForCheck();
        },
        () => this.toastr.error("Error deleting expense")
      );
    }
  }

  openDriverSettlement(driverID: string, batchNo: number) {
    const url = `/settlements/loads/${driverID}?batchNo=${batchNo}&tab=Fuel`;
    window.open(url, "_blank");
  }
}
