<div class="container">
  <div class="form-horizontal">
    <div class="row">
      <h4 *ngIf="notFound">Lessor not found</h4>
      <div class="col-md-6">
        <h4>Lessor Profile</h4>
        <fieldset>
          <!-- Text input-->
          <div class="form-group">
            <label class="col-md-3 control-label">Lessor ID *</label>
            <div class="col-md-9">
              <input
                id="lessorId"
                name="lessorId"
                type="text"
                [disabled]="is_edit"
                [(ngModel)]="lessorInfo.lessorId"
                (focusout)="checkIdExists()"
                placeholder="Insert Lessor ID"
                class="form-control input-md inp p-inputtext"
                [ngClass]="{ 'p-invalid': warnings.lessorId }"
                maxlength="7"
              />
              <small *ngIf="warnings.lessorId" class="p-invalid">{{ warnings.lessorId }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">Lessor Name *</label>
            <div class="col-md-9">
              <input
                id="name"
                name="name"
                type="text"
                [disabled]="is_edit"
                [(ngModel)]="lessorInfo.name"
                (focusout)="checkNameExists()"
                placeholder="Insert Lessor Name"
                class="form-control input-md inp p-inputtext"
                [ngClass]="{ 'p-invalid': warnings.name }"
              />
              <small *ngIf="warnings.name" class="p-invalid">{{ warnings.name }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">Address *</label>
            <div class="col-md-9">
              <input
                id="address"
                name="address"
                type="text"
                [disabled]="is_edit"
                [(ngModel)]="lessorInfo.address"
                placeholder="Insert Address"
                class="form-control input-md inp p-inputtext"
                [ngClass]="{
                  'p-invalid': attemptedCreate && !lessorInfo.address
                }"
              />
              <small *ngIf="attemptedCreate && !lessorInfo.address" class="p-invalid"
                >Required</small
              >
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">Address 2</label>
            <div class="col-md-9">
              <input
                id="address2"
                name="address2"
                type="text"
                [disabled]="is_edit"
                [(ngModel)]="lessorInfo.address2"
                placeholder="Insert Address 2"
                class="form-control input-md inp p-inputtext"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">City *</label>
            <div class="col-md-9">
              <input
                id="city"
                name="city"
                type="text"
                [disabled]="is_edit"
                [(ngModel)]="lessorInfo.city"
                placeholder="Insert City"
                class="form-control input-md inp p-inputtext"
                [ngClass]="{ 'p-invalid': attemptedCreate && !lessorInfo.city }"
              />
              <small *ngIf="attemptedCreate && !lessorInfo.city" class="p-invalid">Required</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">State *</label>
            <div class="col-md-9">
              <p-dropdown
                placeholder="Select State"
                [options]="states"
                [(ngModel)]="selectedState"
                optionLabel="fullName"
                [disabled]="is_edit"
                [style]="{ minWidth: '100%' }"
                tabbableDropdown
              ></p-dropdown>
              <small *ngIf="attemptedCreate && !selectedState" class="p-invalid">Required</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">Zip Code *</label>
            <div class="col-md-9">
              <p-inputMask
                mask="99999"
                placeholder="99999"
                [disabled]="is_edit"
                [(ngModel)]="lessorInfo.zip"
                styleClass="form-control input-md inp"
                [style]="{ opacity: 1, color: '#505050' }"
                inputId="lessorZip"
              ></p-inputMask>
              <small *ngIf="attemptedCreate && !lessorInfo.zip" class="p-invalid">Required</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">Phone Number *</label>
            <div class="col-md-9">
              <p-inputMask
                mask="(999) 999-9999"
                [(ngModel)]="lessorInfo.phone"
                placeholder="(999) 999-9999"
                [disabled]="is_edit"
                styleClass="form-control input-md inp"
                [style]="{ opacity: 1, color: '#505050' }"
                inputId="lessorPhone"
              ></p-inputMask>
              <small *ngIf="attemptedCreate && !lessorInfo.phone" class="p-invalid">Required</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">Email *</label>
            <div class="col-md-9">
              <input
                id="email"
                name="email"
                type="text"
                [disabled]="is_edit"
                [(ngModel)]="lessorInfo.email"
                placeholder="Insert Email"
                class="form-control input-md inp p-inputtext"
                [ngClass]="{ 'p-invalid': warnings.email }"
              />
              <small *ngIf="attemptedCreate && !lessorInfo.email" class="p-invalid">Required</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">EIN *</label>
            <div class="col-md-9">
              <p-inputMask
                mask="99-9999999"
                [(ngModel)]="lessorInfo.ein"
                placeholder="99-9999999"
                [disabled]="is_edit"
                styleClass="form-control input-md inp"
                [style]="{ opacity: 1, color: '#505050' }"
                inputId="lessorEIN"
              ></p-inputMask>
              <small *ngIf="attemptedCreate && !lessorInfo.ein" class="p-invalid">Required</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">Active</label>
            <div class="col-md-9">
              <p-checkbox
                [(ngModel)]="lessorInfo.active"
                name="active"
                binary="true"
                [disabled]="is_edit"
                inputId="lessorActive"
              >
              </p-checkbox>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-3 control-label">Comments</label>
            <div class="col-md-9">
              <textarea
                style="width: 100%; min-height: 100px"
                area
                [disabled]="is_edit"
                [(ngModel)]="lessorInfo.comments"
                inputId="lessorComments"
              ></textarea>
            </div>
          </div>
        </fieldset>
      </div>
      <!--End edit table -->
    </div>
    <!-- Button (Double) -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" align="right">
          <button
            id="cancelEdit"
            name="cancelEdit"
            class="btn"
            (click)="cancelEdit()"
            *ngIf="uuid && uuid.length && !is_edit"
          >
            Cancel
          </button>
          <button
            id="edit"
            name="edit"
            class="btn btn-edit"
            (click)="editOn()"
            *ngIf="hasPermission('LessorChange') && !isCreating"
          >
            {{ btnEdit }}
          </button>
          <button
            id="create"
            name="create"
            class="btn btn-edit"
            (click)="createLessor()"
            *ngIf="isCreating"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '50vw' }"
></p-confirmDialog>
