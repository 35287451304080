<div class="container">
  <div class="col-md-12">
    <legend>Clients</legend>
    <div class="btnRig">
      <button
        id="edit"
        name="edit"
        class="btn btn-primary"
        routerLink="/newclient"
        *ngIf="hasPermission('ClientsChange')"
      >
        Add New Client
      </button>
    </div>
    <div class="toggle-box">
      <p-checkbox
        [(ngModel)]="showDeleted"
        binary="true"
        inputId="binary"
        class="mr-2"
        (onChange)="filterClients()"
      ></p-checkbox>
      <label for="binary">Show Deleted Clients</label>
    </div>
    <div style="display: inline-block">
      <p-table
        #dt
        [columns]="colsClients"
        [value]="filteredClients"
        stateStorage="session"
        stateKey="clientlist-table"
        [autoLayout]="true"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
              <input
                type="text"
                style="width: 100%"
                (input)="$any(dt).filter($event.target.value, col.field, 'contains')"
                [value]="$any(dt).filters[col.field]?.value"
              />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-client>
          <tr
            class="ui-resizable-column"
            [ngClass]="{
              'clickable-row': !client.dateDeletedUtc != null,
              'deleted-row': client.dateDeletedUtc != null
            }"
            (dblclick)="viewDetail(client)"
          >
            <td>{{ client.customerNo }}</td>
            <td>{{ client.companyName }}</td>
            <td>{{ client.phone }}</td>
            <td>{{ client.email }}</td>
            <td>{{ client.city }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
