import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { ToastrService } from "ngx-toastr";
import { SelectItem } from "primeng/api";
import { Permissions } from "@models/permissions.enum";
import {
  CustomChargeCondition,
  CustomChargeRate,
  CustomChargeViewModel,
} from "@app/shared/models/rates/CustomChargeViewModel.model";

@Component({
  selector: "app-edit-custom-charges",
  templateUrl: "./edit-custom-charges.component.html",
  styleUrls: ["./edit-custom-charges.component.css"],
})
export class EditCustomChargesComponent implements OnInit {
  @Input() rate: CustomChargeViewModel;
  @Input() rateId: number;
  @Input() rateType: string;
  @Input() isEditing: boolean;
  @Input() isCreating: boolean;
  @Input() hideBillTos: boolean;
  @Output() hideBillTosChange = new EventEmitter();

  drivers = [];
  selectedDrivers = [];
  conditions: CustomChargeCondition[] = [];

  client: CustomChargeRate;
  driver: CustomChargeRate;

  applyToOptions: SelectItem[];
  chargePerOptions: SelectItem[];
  typeOptions: SelectItem[];
  clientComparisonOptions: SelectItem[];
  driverComparisonOptions: SelectItem[];

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authService: authenticationService
  ) {
    this.client = {
      type: "CLIENT",
      chargePer: "TRIP",
      flatOrVariable: "FLAT",
      units: "totalMiles",
      free: 0,
      interval: 1,
      rate: 0,
    };
    this.driver = {
      type: "DRIVER",
      chargePer: "TRIP",
      flatOrVariable: "FLAT",
      units: "totalMiles",
      free: 0,
      interval: 1,
      rate: 0,
    };

    this.chargePerOptions = [
      { label: "Trip", value: "TRIP" },
      { label: "Stop", value: "STOP" },
      { label: "Day", value: "DAY" },
      { label: "Mile", value: "MILE" },
      { label: "Hour", value: "HOUR" },
      { label: "Quarter", value: "QUARTER" },
    ];
    this.typeOptions = [
      { label: "Flat", value: "FLAT" },
      { label: "Variable", value: "VARIABLE" },
    ];
    this.applyToOptions = [
      { label: "Both", value: "BOTH" },
      { label: "Bill To", value: "BILL TO" },
      { label: "Driver", value: "DRIVER" },
    ];
  }

  ngOnInit(): void {
    this.drivers = this.route.snapshot.data["drivers"];
    this.drivers = this.drivers.filter((driver) => driver.isActive);
    if (this.rate) {
      this.loadRates(this.rate);
    } else {
      this.rate.applyTo = "BOTH";
    }

    // set comparison field to be the correct dropdown for the chargeper
    // then restore the units
    const clientUnits = this.client.units;
    const driverUnits = this.driver.units;
    this.updateComparisonOptions(this.client);
    this.updateComparisonOptions(this.driver);
    this.client.units = clientUnits;
    this.driver.units = driverUnits;
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  onApplyToChanged() {
    if (this.rate.applyTo === "DRIVER") {
      this.hideBillTos = true;
    } else {
      this.hideBillTos = false;
    }
    this.hideBillTosChange.emit(this.hideBillTos);
  }

  updateComparisonOptions(rate: CustomChargeRate) {
    if (rate.type === "CLIENT") {
      this.clientComparisonOptions = this.getComparisonOptions(this.client.chargePer);
      rate.units = this.clientComparisonOptions[0].value;
    } else if (rate.type === "DRIVER") {
      this.driverComparisonOptions = this.getComparisonOptions(this.driver.chargePer);
      rate.units = this.driverComparisonOptions[0].value;
    }
  }

  getComparisonOptions(chargePer: string) {
    switch (chargePer) {
      case "TRIP":
        return [
          { label: "Calculated Miles", value: "totalMiles" },
          { label: "Stops No", value: "stopsNo" },
          { label: "Trip Time", value: "totalTime" },
        ];
      case "STOP":
        return [
          { label: "Sequence", value: "sequence" },
          { label: "Stop Time", value: "stopTimeMinutes" },
          { label: "Gross", value: "gross" },
          { label: "Tare", value: "tare" },
          { label: "Net", value: "net" },
          { label: "Amount", value: "amount" },
          { label: "Miles", value: "miles" },
        ];
    }
  }

  validate = () => {
    if (!this.rate.name) {
      this.toastr.warning("Rate Name is required");
      return false;
    }
    if (!this.rate.chargeTypeId) {
      this.toastr.warning("Charge type is required");
      return false;
    }
    if (!this.rate.applyTo) {
      this.toastr.warning("Apply to is required");
      return false;
    }
    if (this.rate.priority === null || this.rate.priority === undefined) {
      this.toastr.warning("Priority is required");
      return false;
    }
    if (this.rate.version === null || this.rate.version === undefined) {
      this.toastr.warning("Version is required");
      return false;
    }

    return true;
  };

  getDropdownValue(options: any[], items: any[], optionsLabel: string, itemLabel: string) {
    return items.map((item) => {
      return options.find((option) => option[optionsLabel] == item[itemLabel]);
    });
  }

  loadRates(customCharge: CustomChargeViewModel) {
    const client = customCharge.customChargeRates.find((rate) => rate.type === "CLIENT");
    const driver = customCharge.customChargeRates.find((rate) => rate.type === "DRIVER");
    if (client) this.client = client;
    if (driver) this.driver = driver;

    this.conditions = customCharge.customChargeConditions;

    // map the drivers into dropdown form
    this.selectedDrivers = this.getDropdownValue(this.drivers, this.rate.drivers, "id", "id");
  }

  // Build the object to send to the server
  buildRatesObject = () => {
    let customChargeRates = [];
    if (this.rate.applyTo === "DRIVER" || this.rate.applyTo === "BOTH") {
      customChargeRates.push({
        ...this.driver,
        type: "DRIVER",
        id: undefined,
      });
    }
    if (this.rate.applyTo === "BILL TO" || this.rate.applyTo === "BOTH") {
      customChargeRates.push({
        ...this.client,
        type: "CLIENT",
        id: undefined,
      });
    }

    const rateObject = {
      ...this.rate,
      clients: this.rate.clients.map((billTo) => ({ id: billTo.id })),
      drivers: this.selectedDrivers
        .filter((driver) => driver?.id)
        .map((driver) => ({ id: driver.id })),

      customChargeConditions: this.conditions,
      customChargeRates,
    };

    return rateObject;
  };
}
