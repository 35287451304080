import { of as observableOf, Observable, Observer } from "rxjs";
import { Component, ViewChild, OnInit } from "@angular/core";
import { TrailerService } from "../shared/trailer.service";
import { Router } from "@angular/router";
import { FileUploadService } from "../../../app/shared/services/files/fileUpload.service";
import { GetPictureService } from "../../shared/services/files/getPictures.service";
import { MaskService } from "../../shared/services/mask/mask.service";
import { StatesServices } from "../../shared/services/states/states.service";
import { TerminalLocationsServices } from "../../shared/services/terminals/terminals.service";
import { ToastrService } from "ngx-toastr";
import { CanComponentDeactivate } from "../../shared/guards/confirmation/confirmation.guard";


@Component({
  selector: "app-addtrailer",
  templateUrl: "./addtrailer.component.html",
  styleUrls: ["./addtrailer.component.css"],
})
export class AddtrailerComponent implements OnInit, CanComponentDeactivate {
  equipmentCodes: any = [];
  equipmentCodeSelected: any = {};
  dateMask: any;
  docType: string;
  errorMsg: any;
  newVariable: any = {};
  uuid: any;
  states: { abbreviation: string; fullName: string }[];
  trailer: any = { active: true };
  previewTrailerPicture: any;
  terminals: Array<string>;
  isProcessing = false;
  letters = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
  numbers = /^\d*\.?\d+$/;
  isGuardActive = true;
  @ViewChild("trailerDocuments", { static: true }) trailerDocuments;
  @ViewChild("trailerPicture", { static: true }) trailerPicture;
  dateInputMask: any;

  constructor(
    private _addTrailerService: TrailerService,
    private router: Router,
    private fileUploadService: FileUploadService,
    private getPictureService: GetPictureService,
    private maskService: MaskService,
    private statesServices: StatesServices,
    private toastr: ToastrService,
    private terminalLocationsServices: TerminalLocationsServices
  ) {
    this.docType = "trailer";
  }

  ngOnInit() {
    this.dateMask = this.maskService.getDate();
    this.states = this.statesServices.getTwoLettersStates("US");
    this.terminals = this.terminalLocationsServices.getTerminalLocations();
    this.trailer.trailerType = "CHASIS";
    this.trailer.mobileDeviceType = "DSN";
    this._addTrailerService.listTypes().subscribe(
      (equipmentCodes) => {
        this.equipmentCodes = equipmentCodes;
      },
      (err) => {
        this.errorMsg = err;
      }
    );

    this.dateInputMask = this.maskService.getInputDateOnlyMask();
    //  this.startForm();
  }

  confirm() {
    if (this.isGuardActive) {
      return window.confirm("Leave this page without creating the trailer?");
    } else {
      return true;
    }
  }

  equipmentCodeSelectedChanged() {
    this.trailer.equipmentCode = this.equipmentCodeSelected.equipmentCode;
  }

  startForm() {
    this.trailer.stateOfRegistration = "NE";

    this.trailer.licenseNumber = "12354";
    this.trailer.terminalLocation = "OMAHA";
    this.trailer.currentLocationAddress = "OMAHA";
    this.trailer.currentLocationState = "NE";
    this.trailer.currentLocationCity = "OMAHA";
    this.trailer.currentLocationZip = "68066";
    this.trailer.serial = "123456";

    this.trailer.owner = "LGT";
    this.trailer.year = "2015";
    this.trailer.weight = "25000";
    this.trailer.height = "12.3";
    this.trailer.engineHours = "63";
    this.trailer.length = "35.6";
    this.trailer.purchasedDate = new Date();
    // this.trailer.equipmentCode = "STEEL";
    this.trailer.equipmentCodeSteelType = "LIN";

    this.trailer.model = "2014";

    this.trailer.price = "250010";
    this.trailer.mobileDeviceNumber = "402919933";
    this.trailer.axles = "8";
    this.trailer.currentLocation = "OMAHA";
  }

  getTemporalImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.previewTrailerPicture = this.getPictureService.getPreviewImage(event.target.files[0]);
    }
  }
  getFilePath(path) {
    return this.getPictureService.getPicturePath(path, "trailer");
  }

  uploadProfilePicture(files) {
    const fname: string = this.trailerPicture.nativeElement.files[0].name;
    return this.fileUploadService.uploadSingleFileRename(
      this.trailerPicture.nativeElement.files,
      this.docType,
      this.trailer.trailerNo + "_" + fname
    );
  }
  uploadDocuments(trailerId) {
    return this.trailerDocuments.uploadDocuments(
      { TrailerId: trailerId },
      this.docType + "_files",
      trailerId
    );
  }

  sendNewTrailer(newTrailer) {
    return this._addTrailerService.createNewTrailer(newTrailer);
  }
  finishTrailerCreation() {
    this.isGuardActive = false;
    this.toastr.success("Trailer created.", "Success");
    this.isProcessing = false;
    this.trailerDocuments.files = [];
    this.router.navigate(["/trailerlist/"]);
  }
  dateChanged(event, param, justDate, yearDigits) {
    if (event.code === "Delete" || event.code === "Backspace") {
      return;
    }
    this.maskService.maskDate(event, param, justDate, yearDigits);
  }
  createNewTrailer(newTrailer) {
    //  if (this.validateMandatoryField()) {
    //   if (this.validateTrailerFields()) {
    this.isProcessing = true;

    let trailerPictureObservable;

    if (this.trailerPicture.nativeElement.files && this.trailerPicture.nativeElement.files[0]) {
      trailerPictureObservable = this.uploadProfilePicture(this.trailerPicture.nativeElement.files);
    } else {
      trailerPictureObservable = observableOf(undefined);
    }

    trailerPictureObservable.subscribe((result) => {
      if (result) {
        newTrailer.picture = this.trailerPicture.nativeElement.files[0];
      }

      this.sendNewTrailer(newTrailer).subscribe(
        (trailerCreated: any) => {
          if (this.trailerDocuments.documents.length) {
            this.uploadDocuments(trailerCreated.id).subscribe((documentsCreated) => {
              this.finishTrailerCreation();
              return;
            });
          } else {
            this.finishTrailerCreation();
            return;
          }
        },
        (error) => {
          this.toastr.warning(error, "Alert!");
          this.isProcessing = false;
        }
      );
    });
    //   }
    //  }
  }

  /*
    validateMandatoryField(): boolean {
      try {
        this.isValidManufactureSerialNo();
        this.isValidTerminalLocation();
        this.isValidTrailerType()
        this.isValidYear()
        this.isValidPurchasedDate()
        this.isValidMake()
        this.isValidPrice()
        this.isValidModel()
        this.isValidLength()
        this.isValidHeight()
        this.isValidStateOfRegistration()
        this.isValidWeight()
        this.isValidLicenseNumber()
        this.isValidAxles()
        this.isValidLicenseExpirationDate()
        this.isValidTireSize()
        this.isValidEquipmentCodeSelected()
        this.isValidEngineHours()
        this.isValidInsurancePolicyNumber()
        this.isValidInsuranceName()
        this.isValidCurrentLocationState()
        this.isValidCurrentLocationCity()
        this.isValidSerial()
        this.isValidOwner()
        this.isValidMobileDeviceType()
        this.isValidMobileDeviceNumber()
        return true;
      } catch (error) {
        switch (error.type) {
          case 'warning':
            this.toastr.warning(error.msg, 'Alert!', {
              closeButton: true,
              enableHtml: true,
            });
            console.log("Validation error")
            console.dir(error)
            break;
          default:
            this.toastr.info(error.msg, 'Info!', {
              closeButton: true,
              enableHtml: true,
            });
            console.log("Validation error")
            console.dir(error)
            break;
        }
      }
    }

    isValidManufactureSerialNo(): boolean {
      if (typeof this.trailer.manufactureSerialNo === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Manufacture Serial No <b>Required</b>' };
      }
      return true;
    }

    isValidTerminalLocation(): boolean {
      if (typeof this.trailer.terminalLocation === 'undefined') {
        throw { type: 'warning', msg: 'Trailer terminal Location <b>Required</b>' };
      }
      return true;
    }


    isValidTrailerType(): boolean {
      if (typeof this.trailer.trailerType === 'undefined') {
        throw { type: 'warning', msg: 'Trailer type <b>Required</b>' };
      }
      return true;
    }

    isValidYear(): boolean {
      if (typeof this.trailer.year === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Year <b>Required</b>' };
      }
      return true;
    }

    isValidPurchasedDate(): boolean {
      if (typeof this.trailer.purchasedDate === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Purchased Date <b>Required</b>' };
      }
      return true;
    }

    isValidMake(): boolean {
      if (typeof this.trailer.make === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Make <b>Required</b>' };
      }
      return true;
    }

    isValidPrice(): boolean {
      if (typeof this.trailer.price === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Price <b>Required</b>' };
      }
      return true;
    }

    isValidModel(): boolean {
      if (typeof this.trailer.model === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Model <b>Required</b>' };
      }
      return true;
    }

    isValidLength(): boolean {
      if (typeof this.trailer.length === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Length <b>Required</b>' };
      }
      return true;
    }

    isValidHeight(): boolean {
      if (typeof this.trailer.height === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Height <b>Required</b>' };
      }
      return true;
    }

    isValidStateOfRegistration(): boolean {
      if (typeof this.trailer.stateOfRegistration === 'undefined') {
        throw { type: 'warning', msg: 'Trailer State Of Registration <b>Required</b>' };
      }
      return true;
    }

    isValidWeight(): boolean {
      if (typeof this.trailer.weight === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Weight <b>Required</b>' };
      }
      return true;
    }

    isValidLicenseNumber(): boolean {
      if (typeof this.trailer.licenseNumber === 'undefined') {
        throw { type: 'warning', msg: 'Trailer License Number <b>Required</b>' };
      }
      return true;
    }

    isValidAxles(): boolean {
      if (typeof this.trailer.axles === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Axles <b>Required</b>' };
      }
      return true;
    }

    isValidLicenseExpirationDate(): boolean {
      if (typeof this.trailer.licenseExpirationDate === 'undefined') {
        throw { type: 'warning', msg: 'Trailer License Expiration Date <b>Required</b>' };
      }
      return true;
    }

    isValidTireSize(): boolean {
      if (typeof this.trailer.tireSize === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Tire Size <b>Required</b>' };
      }
      return true;
    }

    isValidEquipmentCodeSelected(): boolean {
      if (typeof this.trailer.equipmentCode === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Equipment Code Selected <b>Required</b>' };
      }
      return true;
    }

    isValidEngineHours(): boolean {
      if (typeof this.trailer.engineHours === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Engine Hours <b>Required</b>' };
      }
      return true;
    }

    isValidInsurancePolicyNumber(): boolean {
      if (typeof this.trailer.insurancePolicyNumber === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Insurance Policy Number <b>Required</b>' };
      }
      return true;
    }

    isValidInsuranceName(): boolean {
      if (typeof this.trailer.insuranceName === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Insurance Name <b>Required</b>' };
      }
      return true;
    }

    isValidCurrentLocationState(): boolean {
      if (typeof this.trailer.currentLocationState === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Current Location State <b>Required</b>' };
      }
      return true;
    }

    isValidCurrentLocationCity(): boolean {
      if (typeof this.trailer.currentLocationCity === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Current Location City <b>Required</b>' };
      }
      return true;
    }

    isValidSerial(): boolean {
      if (typeof this.trailer.serial === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Serial <b>Required</b>' };
      }
      return true;
    }

    isValidOwner(): boolean {
      if (typeof this.trailer.owner === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Owner <b>Required</b>' };
      }
      return true;
    }

    isValidMobileDeviceType(): boolean {
      if (typeof this.trailer.mobileDeviceType === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Mobile Device Type <b>Required</b>' };
      }
      return true;
    }

    isValidMobileDeviceNumber(): boolean {
      if (typeof this.trailer.mobileDeviceNumber === 'undefined') {
        throw { type: 'warning', msg: 'Trailer Mobile Device Number <b>Required</b>' };
      }
      return true;
    }

    // I am here

    validateTrailerFields(): boolean {
      try {
        this.isContentManufactureSerialNo();
        this.isContentYear();
        this.isContentPrice();
        this.isContentModel();
        this.isContentLength();
        this.isContentLicenseNumber();
        this.isContentHeight();
        this.isContentWeight();
        this.isContenAxles();
        this.isContenTireSize();
        this.isContentEngineHours();
        this.isContentInsuranceNumber();
        this.isContentInsuranceName();
        this.isContentCurrentLocationCity();
        this.isContentCurrentLocationState();
        this.isContentSerial();
        this.isContentOwner();
        this.isContentMobileDeviceNumber();
        return true;
      } catch (error) {
        switch (error.type) {
          case 'warning':
            this.toastr.warning(error.msg, 'Alert!', {
              closeButton: true,
              enableHtml: true,
            });
            throw error;
          default:
            this.toastr.info(error.msg, 'Info!', {
              closeButton: true,
              enableHtml: true,
            });
            throw error;
        }
      }
    }

    isContentManufactureSerialNo(): boolean {
      if (this.trailer.manufactureSerialNo && !this.trailer.manufactureSerialNo.match(this.letters)) {
        throw { type: 'warning', msg: 'Manufacture Serial can not contain special characters' };
      }
      return true;
    }



    isContentYear(): boolean {
      if (this.trailer.year && isNaN(Number(this.trailer.year))) {
        throw { type: 'warning', msg: 'Year can only be numbers.' };
      } else if (this.trailer.year && this.trailer.year.length > 4) {
        throw { type: 'warning', msg: 'YEAR format is not correct (YYYY)' };
      }
      return true;
    }

    isContentPrice(): boolean {
      if (this.trailer.price  && isNaN(Number(this.trailer.price))) {
        throw { type: 'warning', msg: 'Trailer Price can only be numbers.' };
      }
      return true;
    }

    isContentModel(): boolean {
      if (this.trailer.model && !this.trailer.model.match(this.letters)) {
        throw { type: 'warning', msg: 'Trailer Model can only be letters.' };
      }
      return true;
    }

    isContentLength(): boolean {
      if (this.trailer.length  && isNaN(Number(this.trailer.length))) {
        throw { type: 'warning', msg: 'Trailer Length can only be numbers.' };
      }
      return true;
    }

    isContentLicenseNumber(): boolean {
      if (this.trailer.licenseNumber  && !this.trailer.licenseNumber.match(this.letters)) {
        throw { type: 'warning', msg: 'Trailer Vin can not contain special characters' };
      }
      return true;
    }

    isContentHeight(): boolean {
      if (this.trailer.height  && isNaN(Number(this.trailer.height))) {
        throw { type: 'warning', msg: 'Trailer Height can only be numbers.' };
      }
      return true;
    }

    isContentWeight(): boolean {
      if (this.trailer.weight && isNaN(Number(this.trailer.weight))) {
        throw { type: 'warning', msg: 'Trailer Weight can only be numbers.' };
      }
      return true;
    }

    isContenAxles(): boolean {
      if (this.trailer.axles && isNaN(Number(this.trailer.axles))) {
        throw { type: 'warning', msg: 'Trailer Axles can only be numbers.' };
      }
      return true;
    }


    isContenTireSize(): boolean {
      if (this.trailer.tireSize && isNaN(Number(this.trailer.tireSize))) {
        throw { type: 'warning', msg: 'Trailer Tire Size can only be numbers.' };
      }
      return true;
    }


    isContentEngineHours(): boolean {
      if (this.trailer.engineHours && isNaN(Number(this.trailer.engineHours))) {
        throw { type: 'warning', msg: 'Trailer Tire Size can only be numbers.' };
      }
      return true;
    }

    isContentInsuranceNumber(): boolean {
      if (this.trailer.insurancePolicyNumber && !this.trailer.insurancePolicyNumber.match(this.letters)) {
        throw { type: 'warning', msg: 'Trailer insurance Policy Number can not contain special characters' };
      }
      return true;
    }

    isContentInsuranceName(): boolean {
      if (this.trailer.insuranceName && !this.trailer.insuranceName.match(this.letters)) {
        throw { type: 'warning', msg: 'Trailer insurance Policy Name can only be letters.' };
      }
      return true;
    }

    isContentCurrentLocationCity(): boolean {
      if (this.trailer.currentLocationCity && !this.trailer.currentLocationCity.match(this.letters)) {
        throw { type: 'warning', msg: 'Trailer current Location City can only be letters.' };
      }
      return true;
    }

      isContentCurrentLocationState(): boolean {
      if (this.trailer.currentLocationState && !this.trailer.currentLocationState.match(this.letters)) {
        throw { type: 'warning', msg: 'Trailer current Location State can only be letters.' };
      }
      return true;
    }

    isContentSerial(): boolean {
      if (this.trailer.serial && isNaN(Number(this.trailer.serial))) {
        throw { type: 'warning', msg: 'Trailer serial can only be numbers.' };
      }
      return true;
    }

    isContentOwner(): boolean {
      if (this.trailer.owner && !this.trailer.owner.match(this.letters)) {
        throw { type: 'warning', msg: 'Trailer Owner can only be letters.' };
      }
      return true;
    }

    isContentMobileDeviceNumber(): boolean {
      if (this.trailer.mobileDeviceNumber && isNaN(Number(this.trailer.mobileDeviceNumber))) {
        throw { type: 'warning', msg: 'Trailer Mobile Device Number can only be numbers.' };
      }
      return true;
    }
  */
}
