<div class="container">
  <div class="page-title">Deduction</div>
  <div class="row">
    <div class="col-md-4">
      <div class="row">
        <label class="col-md-6 control-label" for="description">Description</label>
        <div class="col-md-6">
          <input
            type="text"
            class="form-control"
            id="description"
            [readonly]="!isEditing && !isCreating"
            [(ngModel)]="deduction.description"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-6 control-label" for="reference"
          >Reference
          <i
            class="fa fa-question-circle"
            pTooltip="Label that shows on Invoice for quickbooks, which is formatted into, for example, DriverID-Reference-01."
          ></i>
        </label>
        <div class="col-md-6">
          <input
            type="text"
            class="form-control"
            id="reference"
            [readonly]="!isEditing && !isCreating"
            [(ngModel)]="deduction.reference"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-md-6 control-label">Charge Type</label>
        <div class="col-md-6">
          <p-dropdown
            [options]="chargeTypes"
            [(ngModel)]="deduction.chargeTypeId"
            placeholder="Charge Type"
            filter="true"
            filterBy="label"
            optionLabel="label"
            optionValue="value"
            [disabled]="!isEditing && !isCreating"
            [style]="{ width: '100%' }"
            tabbableDropdown
          ></p-dropdown>
        </div>
      </div>
      <div class="row">
        <label class="col-md-6 control-label">Active</label>
        <div class="col-md-6">
          <p-checkbox
            [(ngModel)]="deduction.isActive"
            [disabled]="!isEditing && !isCreating"
            binary="true"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div class="col-md-8" *ngIf="!isCreating">
      <div>
        <label class="radio-inline">
          <input
            type="radio"
            name="batch"
            [(ngModel)]="historyType"
            (change)="loadBatchHistory()"
            value="batch"
          />By Batch
        </label>
        <label class="radio-inline">
          <input
            type="radio"
            name="driver"
            [(ngModel)]="historyType"
            (change)="loadDriverHistory()"
            value="driver"
          />By Driver
        </label>
      </div>

      <!-- batch dropdown -->
      <div style="margin: 1rem 0">
        <div *ngIf="historyType === 'batch'">
          <p-dropdown
            [options]="batches"
            [(ngModel)]="selectedBatch"
            placeholder="Select Batch"
            (onChange)="loadBatchHistory()"
            optionLabel="itemName"
            filterBy="itemName"
            tabbableDropdown
          ></p-dropdown>
        </div>

        <!-- driver dropdown -->
        <div *ngIf="historyType === 'driver'">
          <p-dropdown
            [options]="drivers"
            [(ngModel)]="selectedDriver"
            placeholder="Select Driver"
            (onChange)="loadDriverHistory()"
            filter="true"
            tabbableDropdown
          ></p-dropdown>
        </div>
      </div>

      <!-- Batch History Table -->
      <div *ngIf="historyType === 'batch'" style="max-width: 600px">
        <p-table
          [columns]="batchColumns"
          [value]="batchHistory"
          [autoLayout]="true"
          sortMode="multiple"
          [scrollable]="true"
          scrollHeight="600px"
          [virtualScroll]="true"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr>
              <td>{{ row.driverName }}</td>
              <td>{{ row.customValue | currency }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <!-- Driver History Table -->
      <div *ngIf="historyType === 'driver'" style="max-width: 800px">
        <p-table
          [columns]="driverColumns"
          [value]="driverHistory"
          [autoLayout]="true"
          sortMode="multiple"
          [scrollable]="true"
          scrollHeight="600px"
          [virtualScroll]="true"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr>
              <td>
                Batch {{ row.batchNo }}
                <span class="text-muted">{{ row.startDate | date : "MM/dd/yyyy" }}</span>
              </td>
              <td>{{ row.customValue | currency }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <div class="d-flex" *ngIf="hasPermission('SettlementsChange')">
    <button *ngIf="isCreating" type="button" class="btn btn-primary" (click)="createDeduction()">
      Create
    </button>
    <button
      *ngIf="isEditing"
      type="button"
      class="btn btn-secondary mr-3"
      (click)="isEditing = false"
    >
      Cancel
    </button>
    <button
      *ngIf="!isCreating && !isEditing"
      type="button"
      class="btn btn-primary"
      (click)="isEditing = true"
    >
      Edit
    </button>
    <button
      *ngIf="!isCreating && isEditing"
      type="button"
      class="btn btn-primary"
      [disabled]="loading"
      (click)="updateDeduction()"
    >
      Save
    </button>
  </div>
</div>
