import { BaseRate } from "./BaseRate.model";

export type FuelSurchargeViewModel = {
  isDefaultBillTo: boolean;

  rateBy: string;
  ratingType: string;
  clientPriceIncrement: number;
  clientPriceInterval: number;
  driverPriceIncrement: number;
  driverPriceInterval: number;
  decimals: number;
  isStraightPassThrough: boolean;

  clients: any[];
  gasRegions: any[];
  fuelSurchargeRates: FuelSurchargeRateViewModel[];
} & BaseRate;

export type FuelSurchargeRateViewModel = {
  id?: string;
  price: number;
  floor: number;
  roof: number;
  kind: string;
  fuelSurchargeId?: string;
};
