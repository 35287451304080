import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { HttpClient } from "../shared/services/http/http-client.service";

@Injectable()
export class scheduleinfoService {
  constructor(private router: Router, private _http: HttpClient) {}

  getInfo(uuid) {
    return this._http.get("api/schedule/find/" + uuid).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server schedule info error")
      )
    );
  }
}
