import { Injectable } from '@angular/core';


@Injectable()
export class TerminalLocationsServices {

    constructor () {
    }

    getTerminalLocations() {
        return [
          'BAYTOWN, TEXAS',
          'JACKSON, TENNESSEE',
          'OMAHA, NEBRASKA',
          'RIO VISTA, CALIFORNIA',
          'TOLEDO, OHIO'
        ];
    }

    getSelectFormat(){
        let terminals = this.getTerminalLocations()
        let terminalsFormat = []
        for (let i = 0; i < terminals.length; i++) {
            let state = {
                name: terminals[i],
                itemName: terminals[i],
                id: i + 1
            }
            terminalsFormat.push(terminals);               
        }            
        return terminalsFormat;
    }

}
