import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-adjust-by-percent-button",
  templateUrl: "./adjust-by-percent-button.component.html",
  styleUrls: ["./adjust-by-percent-button.component.css"],
})
export class AdjustByPercentButtonComponent implements OnInit {
  @ViewChild("closebutton") closebutton: any;

  @Input() rows: any[];
  @Output() rowsChange = new EventEmitter();

  roundingOption: string = "Round Up";
  percentage: number = 0;

  constructor() {}

  ngOnInit(): void {}

  roundValue(value: number) {
    switch (this.roundingOption) {
      case "Round Up":
        return Math.ceil(value);
      case "Round":
        return Math.round(value);
      case "Round To Penny":
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }
  }

  adjustByPercent() {
    for (let row of this.rows) {
      const { values } = row;
      for (let [key, value] of Object.entries(values)) {
        values[key] = this.roundValue(Number(value) * (this.percentage / 100));
      }
    }
    this.rowsChange.emit(this.rows);
    this.closebutton.nativeElement.click();
  }
}
