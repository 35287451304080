<div>
  <div class="col-md-12 no-padding">
    <legend>Roles</legend>
    <!-- <div class="btnRig">
      <button type="submit" class="btn btn-primary" (click)="showAddForm()">
        Add New Role
      </button>
    </div> -->
    <div style="display: inline-block">
      <p-table
        #dtrolesList
        [columns]="rolesCols"
        [value]="allRoles"
        [autoLayout]="true"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-role>
          <tr class="clickable-row" (dblclick)="showEditForm(role)">
            <td>{{ role.rolName }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  header="Edit Role"
  [(visible)]="displayEdit"
  [modal]="true"
  [style]="{ minWidth: '400px' }"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <app-editrole [selectedRole]="rolSelected"></app-editrole>
</p-dialog>

<p-dialog
  header="Add New Role"
  [(visible)]="displayAdd"
  [modal]="true"
  [style]="{ width: '300px', minWidth: '200px' }"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <app-addrole></app-addrole>
</p-dialog>

<p-confirmDialog
  key="deleteRoleDialog"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
