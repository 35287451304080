import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { User } from "@models/user.model";

@Component({
  selector: "app-listusers",
  templateUrl: "./listusers.component.html",
  styleUrls: ["./listusers.component.css"],
})
export class ListusersComponent implements OnInit {
  user: any = {};
  allUsers: User[];
  filteredUsers: User[];
  allRoles;
  errorMsg;
  userCols;
  displayEdit = false;
  displayAdd = false;
  showDeleted = false;
  selectedUser: any;
  originalInfo;
  @Output("isGuardActive") isGuardActive = new EventEmitter();

  constructor(private _ActivatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.allUsers = [];
    this.userCols = [
      { field: "username", header: "Username" },
      { field: "email", header: "Email" },
      { field: "name", header: "Name" },
      { field: "lastName", header: "Last Name" },
      { field: "roleId", header: "Role" },
    ];

    this.allRoles = this._ActivatedRoute.snapshot.data.roles;
    this.allUsers = this._ActivatedRoute.snapshot.data.users;
    this.allUsers = this.allUsers.map((user: any) => {
      return this.addRoleName(user);
    });

    this.filterUsers();
  }

  filterUsers() {
    if (this.showDeleted) this.filteredUsers = this.allUsers;
    else this.filteredUsers = this.allUsers.filter((user) => !user.isDeleted);
  }

  showAddForm() {
    this.displayAdd = true;
  }

  showEditForm(user: User) {
    this.displayEdit = true;
    this.selectedUser = JSON.parse(JSON.stringify(user));
  }

  updateUser(updatedUser: User) {
    updatedUser = this.addRoleName(updatedUser);
    this.allUsers = this.allUsers.map((user: User) => {
      return user.id == updatedUser.id ? updatedUser : user;
    });
    this.filterUsers();
  }
  
  removeUser(user: User) {
    this.allUsers.find(u => u.id === user.id).isDeleted = true;
    this.filterUsers();
  }

  restoreUser(user: User) {
    this.allUsers.find(u => u.id === user.id).isDeleted = false;
    this.filterUsers();
  }

  addUser(user: User) {
    user = this.addRoleName(user);
    this.allUsers.push(user);
    this.filterUsers();
  }

  getRoleName(RoleId: number) {
    if (!RoleId) return "No Role ID";
    const rol = this.allRoles.filter((role) => role.id == RoleId);
    if (!rol) return "Invalid Role ID";
    return rol[0].rolName.toUpperCase();
  }

  addRoleName(user: User) {
    return {
      ...user,
      roleName: this.getRoleName(user.RoleId),
    };
  }
}
