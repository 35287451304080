import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewChildren,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";

import { ScrollDirective } from "../../../directive/scroll.directive";
import { DatePipe, DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { TcheckService } from "../../../../tcheck/tcheck.service";
import { ToastrService } from "ngx-toastr";
import { Table } from "primeng/table";
import { TchekTablesService } from "@app/tcheck/tchek-tables.service";

@Component({
  selector: "app-table-report",
  templateUrl: "./table-report.component.html",
  styleUrls: ["./table-report.component.css"],
})
export class TableReportComponent implements OnInit {
  @ViewChild("dt", { static: true }) dt: Table;
  @Output() onEdit = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Input() type: string;

  cellSelected: string;
  changedRecords: any[] = [];

  // prettier-ignore
  cols = this.tchekTables.reportCols;

  records: any = {};
  generalRecods: any = [];
  recordModal: any = [];
  edit: boolean = true;
  otherAmount1Sum: string;
  otherAmount1PlusPilotSum: string;
  otherProductAmount2Sum: string;
  cashSum: string;
  dashCashSum: string;
  payChekAmountSum: string;
  discountAmountSum: string;
  discountValueSum: string;
  customerFeeSum: string;
  payChekFeeSum: string;
  payChekFeePaidBySum: string;
  totalAmountSum: string;
  iNVOICETOTALSum: string;
  oilAmountImportedSum: string;
  oilAmountSum: string;
  nonHighwayAmountSum: string;
  fuel1AmountImportedSum: string;
  fuelAmount1Sum: string;
  calculatedTotalAmountSum: string;
  pilotAddedSum: string;
  fuelAmount2Sum: string;
  fuel2AmountImportedSum: string;
  expressSum: string;
  filterElements: any = [];
  filters: any = [];

  originalRow: any;

  containsErrors: boolean = false;
  containsWarnings: boolean = false;

  @Input()
  set record(pRecord) {
    pRecord = pRecord.map((record) => {
      //if (record["date"]) record["date"] = this.datePipe.transform(record["date"], "MM/dd/yyyy");
      return record;
    });
    this.records = JSON.parse(JSON.stringify(pRecord));
    this.recalculateTotals();

    // If the error column exists but we dont have errors, remove the column. Otherwise, add it.
    let errorCol = this.cols.find((col) => col.field == "errorMsg") != null;
    this.containsErrors = this.records.some((record) => record.errorMsg != null);

    if (!errorCol && this.containsErrors) {
      this.cols.splice(0, 0, {
        field: "errorMsg",
        header: "Error Message",
        type: "error",
        width: 550,
      });
    } else if (errorCol && !this.containsErrors) {
      this.cols = this.cols.filter((col) => col.field != "errorMsg");
    }

    // If the warning column exists but dont have warnings, remove the column. Otherwise, add it.
    let warningCol = this.cols.find((col) => col.field == "warningMsg");
    this.containsWarnings = this.records.some((record) => record.warningMsg != null);
    if (!warningCol && this.containsWarnings) {
      this.cols.splice(0, 0, {
        field: "warningMsg",
        header: "Warning Message",
        type: "warning",
        width: 550,
      });
    } else if (warningCol && !this.containsWarnings) {
      this.cols = this.cols.filter((col) => col.field != "warningMsg");
    }

    setTimeout(() => {
      if (this.dt && this.dt.filteredValue && this.dt.filteredValue.length == 0) {
        this.cols.forEach((col) => {
          this.dt.filter("", col.field, "contains");
          this.filters[col.field] = [];
        });
        this.recalculateTotals();
      }
    }, 5);
  }

  @Input()
  set isEdit(edit) {
    this.edit = edit;
  }

  @ViewChildren(ScrollDirective) directiveScroll;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private tcheckService: TcheckService,
    private tchekTables: TchekTablesService
  ) {}

  ngOnInit() {
    if (this.type === "HISTORICAL") {
      // add the historical cols to the start of the cols array
      this.cols = this.tchekTables.historicalCols.concat(this.cols);
    }
  }

  recalculateTotals(dict = null) {
    this.fuelAmount2Sum = this.getSum("fuelAmount2", dict);
    this.otherAmount1Sum = this.getSum("otherAmount1", dict);
    this.otherAmount1PlusPilotSum = this.getSum("otherAmount1PlusPilot", dict);
    this.otherProductAmount2Sum = this.getSum("otherProductAmount2", dict);
    this.cashSum = this.getSum("cash", dict);    
    this.expressSum = this.getSum("express", dict);
    this.dashCashSum = this.getSum("dashCash", dict);
    this.payChekAmountSum = this.getSum("payChekAmount", dict);
    this.discountAmountSum = this.getSum("discountAmount", dict);
    this.discountValueSum = this.getSum("discountValue", dict);
    this.customerFeeSum = this.getSum("customerFee", dict);
    this.payChekFeeSum = this.getSum("payChekFee", dict);
    this.payChekFeePaidBySum = this.getSum("payChekFeePaidBy", dict);
    this.totalAmountSum = this.getSum("totalAmount", dict);
    this.iNVOICETOTALSum = this.getSum("iNVOICETOTAL", dict);
    this.pilotAddedSum = this.getSum("pilotAdded", dict);
    this.calculatedTotalAmountSum = this.getSum("calculatedTotalAmount", dict);
    this.fuelAmount1Sum = this.getSum("fuelAmount1", dict);
    this.fuel1AmountImportedSum = this.getSum("fuel1AmountImported", dict);
    this.nonHighwayAmountSum = this.getSum("nonHighwayAmount", dict);
    this.oilAmountSum = this.getSum("oilAmount", dict);
    this.oilAmountImportedSum = this.getSum("oilAmountImported", dict);
    this.fuel2AmountImportedSum = this.getSum("fuel2AmountImported", dict);
  }

  getSum(attribute, dict = null) {
    if (!dict) dict = this.records;
    var value = 0;
    dict.forEach((record) => {
      value += Number(record[attribute] || 0);
    });
    return value.toFixed(2);
  }

  openModal(record) {
    this.originalRow = record;
    this.recordModal = JSON.parse(JSON.stringify(record));
  }

  cancelRowEdition() {
    this.originalRow = {};
  }

  saveRowSelectedToEdit(recordModal) {
    this.records[recordModal.index] = JSON.parse(JSON.stringify(recordModal));
    this.recalculateTotals();
  }

  openFuelCard(drivers) {
    if (drivers.length == 0) {
      const url = this.router.serializeUrl(this.router.createUrlTree(["/drivers-list/"]));
      window.open(url, "_blank");
    } else {
      let urls = [];
      drivers.forEach((driver) => {
        const url = this.router.serializeUrl(this.router.createUrlTree(["/driverinfo/", driver]));
        urls.push(url);
      });
      this.openLinks(urls);
    }
  }

  openLinks(urls, i = 0) {
    if (i == urls.length) return;
    window.open(urls[i], "_blank");
    setTimeout(() => {
      this.openLinks(urls, i + 1);
    }, 500);
  }

  onRecordChanged(newValue: any, record: any, field: string) {
   
    const oldValue = record[field];
    // if this is the first time the record has been changed, log its original value
    const key = `${field}OriginalValue`;
    if (record[key] === undefined) record[key] = oldValue;
    // mark if the record has been changed
    record[`${field}Changed`] = record[key] !== newValue;

    // mark the record as one we want to update when we save
    record.softUpdate = true;

    // set the new value
    record[field] = newValue;
  
    this.onEdit.emit("edited");
  }

  save() {
    this.tcheckService.saveScreenRecords(this.records, true).subscribe(
      () => {
        this.toastr.success("Saved");
        this.onSave.emit("success");
      },
      (err) => this.toastr.error("Error updating records")
    );
  }

  openDriverSettlement(driverID: string, batchNo: number) {
    const url = `/settlements/loads/${driverID}?batchNo=${batchNo}&tab=Fuel`;
    window.open(url, "_blank");
  }
}
