import { Injectable } from "@angular/core";

class StateDropdownItem {
  name: string;
  itemName: string;
  fullName: string;
  id: number;
}

@Injectable()
export class StatesServices {
  private USAStates = [
    { abbreviation: "AL", fullName: "ALABAMA" },
    { abbreviation: "AK", fullName: "ALASKA" },
    { abbreviation: "AR", fullName: "ARKANSAS" },
    { abbreviation: "AZ", fullName: "ARIZONA" },
    { abbreviation: "CA", fullName: "CALIFORNIA" },
    { abbreviation: "CO", fullName: "COLORADO" },
    { abbreviation: "CT", fullName: "CONNECTICUT" },
    { abbreviation: "DC", fullName: "DISTRICT OF COLUMBIA" },
    { abbreviation: "DE", fullName: "DELAWARE" },
    { abbreviation: "FL", fullName: "FLORIDA" },
    { abbreviation: "GA", fullName: "GEORGIA" },
    { abbreviation: "IA", fullName: "IOWA" },
    { abbreviation: "ID", fullName: "IDAHO" },
    { abbreviation: "IL", fullName: "ILLINOIS" },
    { abbreviation: "IN", fullName: "INDIANA" },
    { abbreviation: "KS", fullName: "KANSAS" },
    { abbreviation: "KY", fullName: "KENTUCKY" },
    { abbreviation: "LA", fullName: "LOUISIANA" },
    { abbreviation: "MA", fullName: "MASSACHUSETTS" },
    { abbreviation: "MD", fullName: "MARYLAND" },
    { abbreviation: "ME", fullName: "MAINE" },
    { abbreviation: "MI", fullName: "MICHIGAN" },
    { abbreviation: "MN", fullName: "MINNESOTA" },
    { abbreviation: "MO", fullName: "MISSOURI" },
    { abbreviation: "MS", fullName: "MISSISSIPPI" },
    { abbreviation: "MT", fullName: "MONTANA" },
    { abbreviation: "NC", fullName: "NORTH CAROLINA" },
    { abbreviation: "NE", fullName: "NEBRASKA" },
    { abbreviation: "NH", fullName: "NEW HAMPSHIRE" },
    { abbreviation: "NJ", fullName: "NEW JERSEY" },
    { abbreviation: "NM", fullName: "NEW MEXICO" },
    { abbreviation: "NV", fullName: "NEVADA" },
    { abbreviation: "NY", fullName: "NEW YORK" },
    { abbreviation: "ND", fullName: "NORTH DAKOTA" },
    { abbreviation: "OH", fullName: "OHIO" },
    { abbreviation: "OK", fullName: "OKLAHOMA" },
    { abbreviation: "OR", fullName: "OREGON" },
    { abbreviation: "PA", fullName: "PENNSYLVANIA" },
    { abbreviation: "RI", fullName: "RHODE ISLAND " },
    { abbreviation: "SC", fullName: "SOUTH CAROLINA" },
    { abbreviation: "SD", fullName: "SOUTH DAKOTA" },
    { abbreviation: "TN", fullName: "TENNESSEE" },
    { abbreviation: "TX", fullName: "TEXAS" },
    { abbreviation: "UT", fullName: "UTAH" },
    { abbreviation: "VT", fullName: "VERMONT" },
    { abbreviation: "VA", fullName: "VIRGINIA" },
    { abbreviation: "WA", fullName: "WASHINGTON" },
    { abbreviation: "WI", fullName: "WISCONSIN" },
    { abbreviation: "WV", fullName: "WEST VIRGINIA" },
    { abbreviation: "WY", fullName: "WYOMING" },
  ];

  private MEXICOStates = [
    { abbreviation: "AG", fullName: "AGUASCALIENTES" },
    { abbreviation: "BJ", fullName: "BAJA CALIFORNIA" },
    { abbreviation: "BS", fullName: "BAJA CALIFORNIA SUR" },
    { abbreviation: "CP", fullName: "CAMPECHE" },
    { abbreviation: "CH", fullName: "CHIAPAS" },
    { abbreviation: "CI", fullName: "CHIHUAHUA" },
    { abbreviation: "CU", fullName: "COAHUILA DE ZARAGOZA" },
    { abbreviation: "CL", fullName: "COLIMA" },
    { abbreviation: "DF", fullName: "CIUDAD DE MEXICO" },
    { abbreviation: "DG", fullName: "DURANGO" },
    { abbreviation: "GJ", fullName: "GUANAJUATO" },
    { abbreviation: "GR", fullName: "GUERRERO" },
    { abbreviation: "HG", fullName: "HIDALGO" },
    { abbreviation: "JA", fullName: "JALISCO" },
    { abbreviation: "EM", fullName: "MEXICO (State)" },
    { abbreviation: "MH", fullName: "MICHOACAN DE OCAMPO" },
    { abbreviation: "MR", fullName: "MORELOS" },
    { abbreviation: "NA", fullName: "NAYARIT" },
    { abbreviation: "NX", fullName: "NUEVO LEON" },
    { abbreviation: "OA", fullName: "OAXACA" },
    { abbreviation: "PU", fullName: "PUEBLA" },
    { abbreviation: "QA", fullName: "QUERETARO ARTEAGA" },
    { abbreviation: "QR", fullName: "QUINTANA ROO" },
    { abbreviation: "SL", fullName: "SAN LUIS POTOSI" },
    { abbreviation: "SI", fullName: "SINALOA" },
    { abbreviation: "SO", fullName: "SONORA" },
    { abbreviation: "TA", fullName: "TABASCO" },
    { abbreviation: "TM", fullName: "TAMAULIPAS" },
    { abbreviation: "TL", fullName: "TLAXCALA" },
    { abbreviation: "VZ", fullName: "VERACRUZ" },
    { abbreviation: "YC", fullName: "YUCATAN" },
    { abbreviation: "ZT", fullName: "ZACATECAS" },
  ];

  private CANADAStates = [
    { abbreviation: "AB", fullName: "ALBERTA" },
    { abbreviation: "BC", fullName: "BRITISH COLUMBIA" },
    { abbreviation: "MB", fullName: "MANITOBA" },
    { abbreviation: "NB", fullName: "NEW BRUNSWICK" },
    { abbreviation: "NL", fullName: "NEWFOUNDLAND & LABRADOR" },
    { abbreviation: "NT", fullName: "NORTHWEST TERRITORY" },
    { abbreviation: "NU", fullName: "NUNAVUT" },
    { abbreviation: "NS", fullName: "NOVA SCOTIA" },
    { abbreviation: "ON", fullName: "ONTARIO" },
    { abbreviation: "PE", fullName: "PRINCE EDWARD ISLAND" },
    { abbreviation: "QC", fullName: "QUEBEC" },
    { abbreviation: "SK", fullName: "SASKATCHEWAN" },
    { abbreviation: "YT", fullName: "YUKON TERRITORY" },
  ];

  constructor() {}

  getTwoLettersStates(country) {
    country = country ? country : "US";
    const countryUppercase = country.toUpperCase();
    switch (countryUppercase) {
      case "US":
        return this.USAStates;
      case "MX":
        return this.MEXICOStates;
      case "CA":
        return this.CANADAStates;
      default:
        return this.USAStates;
    }
  }

  getSelectFormat(country, category = -1) {
    let states = this.getTwoLettersStates(country);
    let statesFormat = [];
    for (let i = 0; i < states.length; i++) {
      let state = {
        name: states[i].abbreviation,
        itemName: states[i].abbreviation,
        fullName: states[i].fullName,
        id: (category >= 0 ? category : 0) + i + 1,
      };
      if (category >= 0) state["category"] = country;
      statesFormat.push(state);
    }
    return statesFormat;
  }

  getAllStates(): StateDropdownItem[] {
    let allStates = this.getSelectFormat("US", 0);
    allStates = allStates.concat(this.getSelectFormat("MX", allStates.length));
    allStates = allStates.concat(this.getSelectFormat("CA", allStates.length));

    return allStates;
  }

  getStateCountry(state: string) {
    const isUS = this.USAStates.find((stateItem) => stateItem.abbreviation == state);
    if (isUS) return "USA";
    const isCanada = this.CANADAStates.find((stateItem) => stateItem.abbreviation == state);
    if (isCanada) return "Canada";
    const isMexico = this.MEXICOStates.find((stateItem) => stateItem.abbreviation == state);
    if (isMexico) return "Mexico";
    return null;
  }

  findStateId(name: string) {
    const first = this.getAllStates().find((state) => state.name == name);
    if (first === undefined) return 0;
    return first.id;
  }

  findStateFullName(name: string) {
    const first = this.getAllStates().find((state) => name == state.name);
    if (first === undefined) return "";
    return first.fullName;
  }

  findStateData(name: string) {
    const first = this.getAllStates().find((state) => name == state.name);
    return first;
  }

  mapLocationsToDropdown(locations: any[]) {
    return locations.map((location) => location.location);
  }

  mapDropdownToLocations(states: string[]): any[] {
    return states.map((state) => ({ location: state }));
  }
}
