import { Injectable } from "@angular/core";

import { TrailerService } from "./trailer.service";

@Injectable()
export class TrailersSelectFormatResolver {
  constructor(private trailerService: TrailerService) {}

  resolve() {
    return [];
    return this.trailerService.listSelectFormat();
  }
}
