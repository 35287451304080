<div class="container">
  <div class="col-md-12">
    <legend>Deductions</legend>
    <div class="btnRig">
      <button
        class="btn btn-primary"
        style="margin-right: 1rem"
        routerLink="/settlements/deductions/newdeduction"
      >
        Add New Deduction
      </button>
    </div>
    <div class="toggle-box">
      <p-checkbox
        [(ngModel)]="showActive"
        binary="true"
        inputId="binary"
        class="mr-2"
        (onChange)="filterDeductions()"
      ></p-checkbox>
      <label for="binary">Show Inactive</label>
    </div>
    <div>
      <p-table [value]="filteredDeductions" [autoLayout]="true" sortMode="multiple">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Name</th>
            <th>Reference</th>
            <th>Charge Type</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-deduction>
          <tr
            [ngClass]="{
              'clickable-row': true,
              'deleted-row': !deduction.isActive
            }"
            (dblclick)="viewDeduction(deduction.id)"
          >
            <td>{{ deduction.description }}</td>
            <td>{{ deduction.reference }}</td>
            <td>{{ deduction.chargeType?.name }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
