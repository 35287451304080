import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { HttpClient } from "../../shared/services/http/http-client.service";

@Injectable()
export class TractorService {
  constructor(private router: Router, private _http: HttpClient) {

  }

  getTractorList(offset, limit, filter, order) {
    return this._http.post("api/tractor/list", { offset, limit, filter, order }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  findAndFilter() {
    return this._http.get("api/tractor/findAndFilter").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateTractor(tractor) {
    return this._http.patch("api/tractor/update", { tractor }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getTractorAsync(id: number | string): Promise<any> {
    return this._http.get( "api/tractor/findById/" + id).toPromise();
  }

  listSelectFormat() {
    return this._http.post("api/tractor/list", {}).pipe(
      map((res: any) => {
        let tractors = res;
        tractors.map((tractor) => (tractor.itemName = tractor.unitNumber));
        return tractors;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server tractor creation error")
      )
    );
  }

  createNewTractor(tractor) {
    return this._http.post("api/tractor/create", { tractor: tractor }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error cant create tractor")
      )
    );
  }

  delete(driver) {
    return this._http.delete("api/tractor/delete/" + driver.id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Error deleting tractor"))
    );
  }
}
