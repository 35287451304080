import { Directive, HostListener, EventEmitter, Output, ElementRef } from "@angular/core";

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutside {

  @Output()
  appClickOutside: EventEmitter<void> = new EventEmitter();

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: PointerEvent) {
    const nativeElement: any = this._elementRef.nativeElement;
    const clickedInside: boolean = nativeElement.contains(event.target);
    if (!clickedInside) {
      this.appClickOutside.emit();
    }
  }

  constructor(private _elementRef: ElementRef) { }

}
