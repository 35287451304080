import { Component, OnInit } from "@angular/core";
import { ChargeType } from "@app/shared/models/ChargeType.model";
import { ChargetypesService } from "../shared/chargetypes.service";

@Component({
  selector: "app-charge-types",
  templateUrl: "./charge-types.component.html",
  styleUrls: ["./charge-types.component.css"],
})
export class ChargeTypesComponent implements OnInit {
  cols: any[];
  chargeTypes: ChargeType[];
  filteredChargeTypes: ChargeType[];
  selectedChargeType: ChargeType;
  showDeleted = false;
  displayAdd = false;
  displayEdit = false;

  constructor(private chargeTypesService: ChargetypesService) {}

  ngOnInit(): void {
    this.cols = [
      { field: "name", header: "Name" },
      { field: "description", header: "Description" },
      { field: "revenueGL", header: "Revenue GL" },
      { field: "expenseGL", header: "Expense GL" },
      { field: "otherGL", header: "Other GL" },
      { field: "isAccessorial", header: "Accessorial" },
    ];
    this.chargeTypes = [];
    this.getChargeTypes();
  }

  filterChargeTypes() {
    if (this.showDeleted) this.filteredChargeTypes = this.chargeTypes;
    else this.filteredChargeTypes = this.chargeTypes.filter((user) => !user.isDeleted);
  }

  showAddForm() {
    this.selectedChargeType = {
      id: "",
      name: "",
      description: "",
      revenueGL: "",
      expenseGL: "",
      otherGL: "",
      isAccessorial: false,
    };
    this.displayAdd = true;
  }

  showEditForm(chargeType: ChargeType) {
    this.displayEdit = true;
    this.selectedChargeType = chargeType;
  }

  addChargeType(chargeType: ChargeType) {
    this.chargeTypes.push(chargeType);
    this.filterChargeTypes();
  }

  getChargeTypes() {
    this.chargeTypesService.getChargeTypes().subscribe((chargeTypes) => {
      this.chargeTypes = chargeTypes;
      this.filterChargeTypes();
    });
  }
}
