import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { clientService } from "./client.service";

@Injectable()
export class ClientsResolver {
  constructor(private clientService: clientService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     return this.clientService.getClientList();
  }
}
