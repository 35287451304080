
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FileUploadService } from '../../../services/files/fileUpload.service';
import { Documentervice } from '../shared/document.service';
import { GetPictureService } from '../../../services/files/getPictures.service';

@Component({
  selector: 'viewDocument',
  templateUrl: './viewDocument.component.html',
  styleUrls: ['./viewDocument.component.css']
})
export class ViewDocumentComponent implements OnInit {

  @Input('documents') documents: Array<any>;
  @Input('is_edit') is_edit: boolean;


  constructor(
    private fileUploadService: FileUploadService,
    private documentervice: Documentervice,
    private getPictureService: GetPictureService
  ) { }

  ngOnInit() {
  }
  deleteFile(document, index) {
    if (document.AttachedFiles[index].id) {
      document.AttachedFiles[index].deleteFile = true;
    } else {
      document.AttachedFiles.splice(index, 1);
    }

  }

  getPicturePath(name, docType: string) {
    return this.getPictureService.getPicturePath(name, docType);
  }

  setFilesToDocument(event, document) {
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      document.AttachedFiles.push(file);
    }
  }

  updateDocument(document) {
    const updatingFiles = [];
    const uploadingFiles = [];
    // this for choose the new files
    for (let i = 0; i < document.AttachedFiles.length; i++) {
      if (document.AttachedFiles[i].size) {
        updatingFiles.push({ index: i, file: document.AttachedFiles[i] });
      }
    }
    // this for upload the new files
    for (let j = 0; j < updatingFiles.length; j++) {
      const file = updatingFiles[j].file;
      uploadingFiles.push(this.fileUploadService.uploadFiles(file, 'others'));
    }
    // once the files are uploaded we match the new file with the current position
    if (uploadingFiles.length) {
      return observableForkJoin(uploadingFiles).pipe(map(filesProcesed => {
        for (let k = 0; k < filesProcesed.length; k++) {
          document.AttachedFiles[updatingFiles[k].index] = filesProcesed[k];
        }
        this.documentervice.updateDocument(document).subscribe(result => {
          return result;
        });

      }));
    } else {
      return this.documentervice.updateDocument(document);
    }

  }

}
