<div class="login-wrapper">
  <div class="container">
    <div class="row vertical-offset-100">
      <div class="col-md-4 col-md-offset-4">
        <div class="panel panel-default">
          <div class="panel-heading">
            <div class="row-fluid user-row">
              <img
                src="assets/img/lgt.png"
                class="img-responsive"
                alt="LGT Logo"
              />
            </div>
          </div>
          <div class="panel-body">
            <form accept-charset="UTF-8" role="form" class="form-signin">
              <fieldset>
                <label class="panel-login">
                  <div class="login_result"></div>
                </label>
                <label for="password" style="padding-top: 10px; color: white;">
                  New Password
                </label>
                <input
                  [(ngModel)]="form.password"
                  style="text-transform: none !important"
                  name="password"
                  id="password"
                  class="form-control"
                  type="password"
                />
                <label for="password-confirm" style="padding-top: 10px; color: white;">
                  Confirm Password
                </label>
                <input
                  [(ngModel)]="form.passwordConfirm"
                  style="text-transform: none !important"
                  name="password-confirm"
                  id="password-confirm"
                  class="form-control"
                  type="password"
                />
                <input
                  type="submit"
                  name="login-submit"
                  id="login-submit"
                  (click)="submit()"
                  tabindex="4"
                  class="btn btn-lg btn-login btn-block"
                  style="margin-top: 30px;"
                  value="Reset »"
                />
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>