import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng/api";
import { TcheckService } from "../tcheck/tcheck.service";

@Component({
  selector: "app-tchek-import-manager",
  templateUrl: "./tchek-import-manager.component.html",
  styleUrls: ["./tchek-import-manager.component.css"],
})
export class TchekImportManagerComponent implements OnInit {
  selectRecords: any = [];
  selectYears: any = [];
  records: any = [];
  batchSelected: any = 0;
  yearSelected: any = 0;
  editOff: boolean = false;

  constructor(
    private tcheckService: TcheckService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.tcheckService.getYearsTcheckBatch().subscribe((res) => {
      this.selectYears = res;
    });
  }

  dataChangedYear() {
    if (this.yearSelected != 0) {
      this.tcheckService.getSelectTcheckBatch(this.yearSelected).subscribe((res) => {
        this.selectRecords = res;
      });
    }
  }

  dataChangedSelectedBatch() {
    if (this.batchSelected.uuid != 0) {
      this.tcheckService.getTcheckByUUID(this.batchSelected.uuid).subscribe((res) => {
        this.records = res;
      });
    }
  }

  resetPage() {
    this.batchSelected = 0;
    this.yearSelected = 0;
    this.selectRecords = [];
    this.selectYears = [];
    this.records = [];
    this.ngOnInit();
  }

  deleteBatch() {
    this.confirmationService.confirm({
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      message: "Are you sure you want to delete this imported Tchek?",
      accept: () => {
        this.tcheckService.deleteBatch(this.batchSelected.id).subscribe(
          () => {
            this.toastr.success("Batch deleted successfully", "Success");
            this.resetPage();
          },
          (error) => this.toastr.error(error)
        );
      },
    });
  }
}
