import { Component, Input } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { SettlementsService } from "../../shared/settlements.service";

@Component({
  selector: "app-settlement-invoice",
  templateUrl: "./settlement-invoice.component.html",
  styleUrls: ["./settlement-invoice.component.css"]
})
export class SettlementInvoiceComponent {
  @Input() settlement: any;
  url: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private _SettlementsService: SettlementsService
  ) {}

  ngOnChanges() {
    if (Object.keys(this.settlement).length > 0) {
      this._SettlementsService.printInvoice(this.settlement.DriverId, this.settlement.id).subscribe(
        (response) => {
          const blob = new Blob([response], { type: "application/pdf" });
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
        }
      );
    }
  }
}
