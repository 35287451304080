import { Component, OnInit, NgZone, Input } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-settlements-menu",
  templateUrl: "./settlements-menu.component.html",
  styleUrls: ["./settlements-menu.component.css"],
})
export class SettlementsMenuComponent implements OnInit {
  @Input("selectedTab") selectedTab;
  @Input("driverId") driverId;
  constructor(private router: Router) {}

  ngOnInit() {}
  changeTab(destination) {
    this.router.navigate(["/settlements/" + destination + "/", this.driverId]);
  }
}
