<div class="container">
    <div class="col-md-8">
        <form class="form-horizontal">
            <fieldset>

                <!-- Form Name -->
                <legend>Client Profile</legend>

                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">Company Name</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>
                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">Email Address</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>

                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">Phone Number</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>
                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">Alternative Phone</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>
                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">Address</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>
                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">State</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>
                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">City</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>
                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">Zip Code</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>
                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">Billing Address</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>
                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">Billing E mail</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>
                <!-- Text input-->
                <div class="form-group">
                    <label class="col-md-3 control-label" for="company">Contact Name</label>
                    <div class="col-md-6">
                        <input id="company" name="company" type="text" placeholder="placeholder" class="form-control input-md">
                    </div>
                </div>
            </fieldset>
        </form>
    </div>

    <div class="col-md-4">
        <img src="https://i.pinimg.com/originals/5c/8d/ff/5c8dfff09e10ebf02cb8fbd8afeaa09d.jpg" class="imgStyle">
    </div>
</div>
<div class="row col-md-12">
    <div class="form-group">
        <div class="col-md-12" align="center">
            <button id="edit" name="edit" class="btn btn-default">Edit Profile</button>
            <button id="delete" name="delete" class="btn btn-default">Delete</button>
        </div>
    </div>
</div>