import { Directive, HostListener, Input, ElementRef, Output } from "@angular/core";
import { HttpClient } from "../services/http/http-client.service";
import { HttpHeaders } from "@angular/common/http";
import * as Jquery from "jquery";
import { DomSanitizer } from "@angular/platform-browser";

@Directive({
  selector: "[appGetFile]",
})
export class DownloadFileDirective {
  constructor(
    private readonly httpClient: HttpClient,
    private el: ElementRef,
    private sanitizer: DomSanitizer
  ) {}

  private downloadUrl: any;
  private downloadedUrl: any;

  private preview: boolean;
  private download: boolean;
  private open: boolean;

  @Input("appGetFile")
  public set url(url: any) {
    this.downloadUrl = url;
    if (this.preview) this.setSrc();
  }

  @Input("appPreviewFile")
  public set previewFile(previewFile: boolean) {
    this.preview = previewFile;
    this.setSrc();
  }

  @Input("appDownloadFile")
  public set downloadFile(downloadFile: boolean) {
    this.download = downloadFile;
  }

  @Input("appOpenFile")
  public set openFile(openFile: boolean) {
    this.open = openFile;
  }

  public setSrc() {
    if (typeof this.downloadUrl === "string") {
      this.el.nativeElement.src = this.downloadUrl;
    }
    this.getDownload();
  }

  public async getDownload(perform = false) {
    if (this.downloadUrl === this.downloadedUrl) return;

    const response = await this.httpClient.download(this.downloadUrl);

    this.downloadUrl = URL.createObjectURL(response.body);
    this.downloadedUrl = this.downloadUrl;

    if (this.preview) {
      this.el.nativeElement.src = this.downloadUrl;
    }
    if (perform && (this.download || this.open)) {
      // Create an anchor element to "point" to it
      const anchor = document.createElement("a");
      anchor.href = this.downloadUrl;
      anchor.target = "_blank";

      if (this.download) anchor.download = this.getFilenameFromHeaders(response.headers) || "file";

      // Simulate a click on our anchor element
      anchor.click();
    }
  }

  @HostListener("click")
  public async onClick(): Promise<void> {
    this.getDownload(true);
  }

  private getFilenameFromHeaders(headers: HttpHeaders) {
    // The content-disposition header should include a suggested filename for the file
    const contentDisposition = headers.get("Content-Disposition");
    if (!contentDisposition) {
      return null;
    }

    /* StackOverflow is full of RegEx-es for parsing the content-disposition header,
     * but that's overkill for my purposes, since I have a known back-end with
     * predictable behaviour. I can afford to assume that the content-disposition
     * header looks like the example in the docs
     * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
     *
     * In other words, it'll be something like this:
     *    Content-Disposition: attachment; filename="filename.ext"
     *
     * I probably should allow for single and double quotes (or no quotes) around
     * the filename. I don't need to worry about character-encoding since all of
     * the filenames I generate on the server side should be vanilla ASCII.
     */

    const leadIn = "filename=";
    const start = contentDisposition.search(leadIn);
    if (start < 0) {
      return null;
    }

    // Get the 'value' after the filename= part (which may be enclosed in quotes)
    const value = contentDisposition.substring(start + leadIn.length).trim();
    if (value.length === 0) {
      return null;
    }

    // If it's not quoted, we can return the whole thing
    const firstCharacter = value[0];
    if (firstCharacter !== '"' && firstCharacter !== "'") {
      return value;
    }

    // If it's quoted, it must have a matching end-quote
    if (value.length < 2) {
      return null;
    }

    // The end-quote must match the opening quote
    const lastCharacter = value[value.length - 1];
    if (lastCharacter !== firstCharacter) {
      return null;
    }

    // Return the content of the quotes
    return value.substring(1, value.length - 1);
  }
}
