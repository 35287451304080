import { Component, OnInit } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-header-component",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  isLogin: boolean;
  username: string;
  usernameSubscription: Subscription;

  constructor(private router: Router, private authService: authenticationService) {
    this.username = this.authService.getUsername();
  }

  ngOnInit() {
    const loginUrls = ["/login", "/reset"];
    if (loginUrls.some((url) => this.router.url.startsWith(url))) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.isLogin = loginUrls.some((url) => event.url.startsWith(url));
      });

    this.usernameSubscription = this.authService.getUsernameObservable().subscribe((username) => {
      this.username = username;
    });
  }

  ngOnDestroy() {
    if (this.usernameSubscription) {
      this.usernameSubscription.unsubscribe();
    }
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  sliderFunctionality() {
    document.getElementById("menuRol").className = "dropdown";
  }

  logout() {
    this.authService.logout();
  }
}
