<div class="container">
  <div class="form-horizontal">
    <div class="row">
      <div class="col-md-6">
        <h5>Client Profile</h5>
        <div class="form-group">
          <label class="col-md-4 control-label" for="companyName">Company Name *</label>
          <div class="col-md-8">
            <input
              id="companyName"
              name="companyName"
              type="text"
              placeholder="Insert Company Name"
              class="form-control input-md"
              [(ngModel)]="newClient.companyName"
              aria-label="clientCompanyName"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="clientValidFrom">Valid From *</label>
          <div class="col-md-8">
            <div class="row">
              <div class="col-sm-5">
                <input id="clientValidFrom"
                       name="clientValidFrom"
                       type="text"
                       aria-label="clientValidFrom"
                       [style]="{ opacity: 1, color: '#505050' }"
                       [inputMask]="dateInputMask"
                       autocomplete="off"
                       placeholder="mm/dd/yyyy"
                       class="form-control input-md"
                       [(ngModel)]="newClient.validFrom"
                       aria-label="clientValidFrom"/>

              </div>
              <div class="col-sm-2" style="text-align: center">
                <label class="control-label" for="clientValidTo">To</label>
              </div>
              <div class="col-sm-5">
                <input id="clientValidTo"
                       name="clientValidTo"
                       type="text"
                       aria-label="clientValidTo"
                       [style]="{ opacity: 1, color: '#505050' }"
                       [inputMask]="dateInputMask"
                       autocomplete="off"
                       placeholder="mm/dd/yyyy"
                       class="form-control input-md"
                       [(ngModel)]="newClient.validTo"
                       aria-label="clientValidTo"/>   
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label"> Active </label>
          <div class="col-md-8">
            <p-checkbox [(ngModel)]="newClient.isActive"
              binary="true"
              name="isActive"
              typeof="checkbox"
              aria-label="clientIsActive"
            >
            </p-checkbox>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label"> Has plant number? </label>
          <div class="col-md-8">
            <p-checkbox
              [(ngModel)]="newClient.hasPlantNumber"
              binary="true"
              name="hasPlantNumber"
            ></p-checkbox>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="companyEmail">Email</label>
          <div class="col-md-8">
            <input
              id="companyEmail"
              name="companyEmail"
              type="email"
              placeholder="Insert Email Address"
              class="form-control input-md"
              [(ngModel)]="newClient.email"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="companyPhone">Phone Number</label>
          <div class="col-md-8">
            <input
              id="companyPhone"
              name="companyPhone"
              type="tel"
              class="form-control input-md"
              autocomplete="off"
              [inputMask]="usPhoneMask"
              placeholder="(XXX) XXX-XXXX"
              [(ngModel)]="newClient.phone"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="companyAltPhone">Alt Phone</label>
          <div class="col-md-8">
            <input
              id="companyAltPhone"
              name="companyAltPhone"
              type="tel"
              autocomplete="off"
              [inputMask]="usPhoneMask"
              placeholder="(XXX) XXX-XXXX"
              class="form-control input-md"
              [(ngModel)]="newClient.altPhone"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="address">Address *</label>
          <div class="col-md-8">
            <input
              id="address"
              name="address"
              type="text"
              placeholder="Insert Address"
              class="form-control input-md"
              [(ngModel)]="newClient.address"
              aria-label="clientAddress"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="address2">Address Line 2</label>
          <div class="col-md-8">
            <input
              id="address2"
              name="address2"
              type="text"
              placeholder="Insert Address Line 2"
              class="form-control input-md"
              [(ngModel)]="newClient.address2"
              aria-label="clientAddress2"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="city">City *</label>
          <div class="col-md-8">
            <input
              id="city"
              name="city"
              type="text"
              placeholder="Insert City"
              class="form-control input-md"
              [(ngModel)]="newClient.city"
              aria-label="clientCity"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="state">State *</label>
          <div class="col-md-8">
            <select class="selectField" id="state" name="state" [(ngModel)]="newClient.state" aria-label="clientState"> 
              <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                {{ state.fullName }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="zip">ZIP *</label>
          <div class="col-md-8">
            <input
              id="zip"
              name="zip"
              type="text"
              placeholder="Insert ZIP Code"
              class="form-control input-md"
              [(ngModel)]="newClient.zip"
              aria-label="clientZip"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="contactName">Contact name</label>
          <div class="col-md-8">
            <input
              id="contactName"
              name="contactName"
              type="text"
              placeholder="Insert Contact name"
              class="form-control input-md"
              [(ngModel)]="newClient.contactName"
              aria-label="clientContactName"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="clientId">Client ID *</label>
          <div class="col-md-8">
            <input
              id="clientId"
              name="clientId"
              type="text"
              placeholder="Insert Client ID"
              class="form-control input-md"
              maxlength="20"
              [(ngModel)]="newClient.customerNo"
              (keydown.space)="$event.preventDefault()"
              (ngModelChange)="removeClientSpaces()"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-4 control-label" for="paymentTerms">Payment Terms *</label>
          <div class="col-md-8">
            <input
              id="paymentTerms"
              name="paymentTerms"
              type="number"
              placeholder="Payment Terms"
              class="form-control input-md"
              [(ngModel)]="newClient.billingRange"
              min="0"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h5>Bill to</h5>
        <div class="row" *ngFor="let billToElement of billToList; let i = index">
          <div class="col-md-11">
            <p-accordion>
              <p-accordionTab>
                <p-header>
                  {{ billToElement.companyName }}
                </p-header>
                <app-bill-to
                  [billTo]="billToElement"
                  [companyName]="newClient.companyName"
                  [states]="states"
                ></app-bill-to>
              </p-accordionTab>
            </p-accordion>
          </div>
          <div class="col-md-1">
            <span
              role="button"
              class="glyphicon glyphicon-trash btn-lg"
              (click)="removeBillToElement(i)"
            ></span>
          </div>
        </div>
        <div>
          <button type="button" class="btn btn-Information" (click)="useTheClientAsContact()">
            Auto Complete Bill To Fields
          </button>
          <button type="button" class="btn btn-Information" (click)="addBillTo()">
            Add new bill to
          </button>
          <app-bill-to
            #billTo
            [companyName]="newClient.companyName"
            [states]="states"
          ></app-bill-to>
        </div>
      </div>
    </div>
  </div>
  <div class="row" class="bottom-btns">
    <div class="col-md-12" align="center">
      <button
        type="button"
        class="btn btn-secondary createClientButton"
        (click)="createAddClient(newClient)"
      >
        Create Client
      </button>
      <button
        type="button"
        class="btn btn-secondary createClientButton"
        [routerLink]="'/clientlist'"
      >
        Cancel
      </button>
    </div>
    <br />
  </div>

  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
    [style]="{ width: '50vw' }"
  ></p-confirmDialog>
</div>
