<div class="container">
  <div class="col-md-12">
    <legend>Create New Tractor</legend>
    <div class="row">
      <div class="col-sm-9">
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="unitNumber">Unit Number</label>
            <div class="col-md-6">
              <input id="unitnumberid"
                     name="unitNumber"
                     tabindex="1"
                     type="text"
                     placeholder="Insert unit number"
                     class="form-control input-md"
                     [(ngModel)]="tractor.unitNumber" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="notes">Notes</label>
            <div class="col-md-6">
              <textarea id="notes"
                        name="notes"
                        tabindex="1"
                        type="text"
                        placeholder="Insert notes"
                        [(ngModel)]="tractor.notes"
                        maxlength="255"
                        rows="3"
                        style="text-transform: uppercase"></textarea>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="active">Active</label>
            <div class="col-md-6">
              <p-checkbox [(ngModel)]="tractor.isActive" tabindex="3" binary="true"></p-checkbox>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="terminalLocation">Terminal Location</label>
            <div class="col-md-6">
              <select class="selectState"
                      id="terminalLocation"
                      name="terminalLocation"
                      tabindex="2"
                      [(ngModel)]="tractor.terminalLocation">
                <option *ngFor="let terminal of terminals" [ngValue]="terminal">
                  {{ terminal }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="dateSold">Date Sold</label>
            <div class="col-md-6">
              <input id="dateSoldInput"
                     name="dateSoldInput"
                     type="text"
                     [inputMask]="dateInputMask"
                     autocomplete="off"
                     placeholder="mm/dd/yyyy"
                     appendTo="body"
                     class="form-control input-md"
                     [tabIndex]="4"
                     [(ngModel)]="tractor.dateSold" />
            </div>
          </div>
        </div>
        <br />
        <hr />
        <!--impares left section start here-->
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">Year</label>
            <div class="col-md-6">
              <input id="year"
                     name="year"
                     type="text"
                     tabindex="5"
                     placeholder="Insert tractor year"
                     class="form-control input-md"
                     [(ngModel)]="tractor.year" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="make">Make</label>
            <div class="col-md-6">
              <input id="make"
                     name="make"
                     type="text"
                     tabindex="7"
                     placeholder="Insert tractor manufacturer"
                     class="form-control input-md"
                     [(ngModel)]="tractor.make" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="tractorModel">Tractor Model</label>
            <div class="col-md-6">
              <input id="tractorModel"
                     name="tractorModel"
                     tabindex="9"
                     type="text"
                     placeholder="Insert tractor model"
                     class="form-control input-md"
                     [(ngModel)]="tractor.model" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="fuelType">Fuel Type</label>
            <div class="col-md-6">
              <select class="selectFuel"
                      id="fuelType"
                      tabindex="13"
                      name="fuelType"
                      [(ngModel)]="tractor.fuelType">
                <option value="cng" selected>CNG</option>
                <option value="diesel">Diesel</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">Owner </label>
            <div class="col-md-6">
              <input id="owner"
                     name="owner"
                     type="text"
                     tabindex="41"
                     placeholder="Insert tractor owner"
                     class="form-control input-md"
                     [(ngModel)]="tractor.owner" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="stateOfRegistration">State of Registration</label>
            <div class="col-md-6">
              <select class="selectState"
                      id="stateOfRegistration"
                      tabindex="17"
                      name="stateOfRegistration"
                      [(ngModel)]="tractor.stateOfRegistration">
                <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                  {{ state.fullName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="licenseNumber">License Plate</label>
            <div class="col-md-6">
              <input id="licenseNumber"
                     name="licenseNumber"
                     tabindex="19"
                     type="text"
                     placeholder="Insert license number"
                     class="form-control input-md"
                     [(ngModel)]="tractor.licenseNumber" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="licenseExpirationDate">License Expiration Date</label>
            <div class="col-md-6">
              <input id="licenseExpirationDate"
                     name="licenseExpirationDate"
                     type="text"
                     [inputMask]="dateInputMask"
                     autocomplete="off"
                     placeholder="mm/dd/yyyy"
                     appendTo="body"
                     class="form-control input-md"
                     [tabIndex]="21"
                     [(ngModel)]="tractor.licenseExpirationDate" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="engineManufacturer">Engine Manufacturer</label>
            <div class="col-md-6">
              <input id="engineManufacturer"
                     name="engineManufacturer"
                     type="text"
                     tabindex="23"
                     placeholder="Insert engine manufacture"
                     class="form-control input-md"
                     [(ngModel)]="tractor.engineManufacturer" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="engineModel">Engine Model</label>
            <div class="col-md-6">
              <input id="engineModel"
                     name="engineModel"
                     type="text"
                     tabindex="25"
                     placeholder="Insert engine model"
                     class="form-control input-md"
                     [(ngModel)]="tractor.engineModel" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="engineSerialNumber">Engine Serial #</label>
            <div class="col-md-6">
              <input class="form-control input-md"
                     [(ngModel)]="tractor.engineSerialNumber"
                     tabindex="27"
                     id="engineSerialNumber"
                     name="engineSerialNumber"
                     type="text"
                     placeholder="Insert engine serial #" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="DriverId">Tractor Driver</label>
            <div class="col-md-6">
              <select id="DriverId"
                      id="DriverId"
                      name="DriverId"
                      tabindex="29"
                      (change)="driverSelected(tractor.driverId)"
                      [(ngModel)]="tractor.driverId"
                      class="form-control">
                <option [ngValue]="null" disabled>--Select one--</option>
                <option *ngFor="let driver of drivers" [ngValue]="driver.id">
                  {{ driver.lastName + " " + driver.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="insuranceName">Insurance Name</label>
            <div class="col-md-6">
              <input id="insuranceName"
                     name="insuranceName"
                     type="text"
                     tabindex="31"
                     placeholder="Insert insurance name"
                     class="form-control input-md"
                     [(ngModel)]="tractor.insuranceName" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="tractorModel">Base Manufacture Warranty</label>
            <div class="col-md-6">
              <input id="baseManuFactureWarranty"
                     tabindex="33"
                     name="baseManuFactureWarranty"
                     type="text"
                     placeholder="Insert Base Manufacture Warranty"
                     class="form-control input-md"
                     [(ngModel)]="tractor.baseManuFactureWarranty" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="baseWarrantyYear">Base Warranty Year</label>
            <div class="col-md-6">
              <input id="baseWarrantyYear"
                     name="baseWarrantyYear"
                     tabindex="35"
                     type="text"
                     placeholder="Insert Base Warranty Year"
                     class="form-control input-md"
                     [(ngModel)]="tractor.baseWarrantyYear" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="engineMilesWarranty">Engine Miles Warranty</label>
            <div class="col-md-6">
              <input id="engineMilesWarranty"
                     name="engineMilesWarranty"
                     tabindex="37"
                     type="text"
                     placeholder="Insert warranty miles"
                     class="form-control input-md"
                     [(ngModel)]="tractor.engineMilesWarranty" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="afterTreatmentManufactureWarranty">After Treatment Manufacture</label>
            <div class="col-md-6">
              <input id="afterTreatmentManufactureWarranty"
                     tabindex="39"
                     name="afterTreatmentManufactureWarranty"
                     type="text"
                     placeholder="Insert AfterTreatment Manufacture Warranty"
                     class="form-control input-md"
                     [(ngModel)]="tractor.afterTreatmentManufactureWarranty" />
            </div>
          </div>
        </div>
        <!--End impares left section-->
        <!--Pares left start here section-->
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="datePurchased">Date Purchased </label>
            <div class="col-md-6">
              <input id="datePurchasedInput"
                     name="datePurchasedInput"
                     type="text"
                     [inputMask]="dateInputMask"
                     autocomplete="off"
                     placeholder="mm/dd/yyyy"
                     appendTo="body"
                     class="form-control input-md"
                     [tabIndex]="6"
                     [(ngModel)]="tractor.datePurchased" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="purchasePrice">Purchase Price</label>
            <div class="col-md-6">
              <input id="purchasePrice"
                     name="purchasePrice"
                     tabindex="8"
                     type="text"
                     placeholder="Insert tractor purchase price"
                     class="form-control input-md"
                     [(ngModel)]="tractor.purchasePrice" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="length">Tractor Length (ft)</label>
            <div class="col-md-6">
              <input id="length"
                     name="length"
                     type="text"
                     tabindex="10"
                     placeholder="Insert tractor length"
                     [(ngModel)]="tractor.length"
                     class="form-control input-md inp" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Height (ft)</label>
            <div class="col-md-6">
              <input id="height"
                     name="height"
                     type="text"
                     tabindex="12"
                     placeholder="Insert tractor height"
                     class="form-control input-md"
                     [(ngModel)]="tractor.height" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="ladwidthn">Tractor Width (ft)</label>
            <div class="col-md-6">
              <input id="width"
                     name="width"
                     type="text"
                     tabindex="14"
                     placeholder="Insert tractor width"
                     [(ngModel)]="tractor.width"
                     class="form-control input-md inp" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="ladenWeight">Laden Weight (Lb) </label>
            <div class="col-md-6">
              <input id="ladenWeight"
                     name="ladenWeight"
                     tabindex="16"
                     type="text"
                     placeholder="Insert tractor laden weight"
                     class="form-control input-md"
                     [(ngModel)]="tractor.ladenWeight" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="unladenWeight">Unladen Weight (Lb)</label>
            <div class="col-md-6">
              <input id="unladenWeight"
                     name="unladenWeight"
                     tabindex="18"
                     type="text"
                     placeholder="insert unladen weight"
                     class="form-control input-md"
                     [(ngModel)]="tractor.unladenWeight" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Axles</label>
            <div class="col-md-6">
              <input id="axles"
                     name="axles"
                     type="text"
                     tabindex="20"
                     placeholder="Insert tractor axles"
                     class="form-control input-md"
                     [(ngModel)]="tractor.axles" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Steer Tire Size (in)</label>
            <div class="col-md-6">
              <input id="steerTireSize"
                     name="steerTireSize"
                     tabindex="22"
                     type="text"
                     placeholder="Insert steer tire size"
                     class="form-control input-md"
                     [(ngModel)]="tractor.steerTireSize" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="driveTireSize">Drive Tire Size (in)</label>
            <div class="col-md-6">
              <input id="driveTireSize"
                     name="driveTireSize"
                     tabindex="24"
                     type="text"
                     placeholder="Insert tire size"
                     class="form-control input-md"
                     [(ngModel)]="tractor.driveTireSize" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="accumulatedMiles">Accumulated Miles </label>
            <div class="col-md-6">
              <input id="accumulatedMiles"
                     name="accumulatedMiles"
                     tabindex="26"
                     type="text"
                     placeholder="Insert accumulated miles"
                     class="form-control input-md"
                     [(ngModel)]="tractor.accumulatedMiles" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="insurancePolicyNumber">Insurance Policy Number</label>
            <div class="col-md-6">
              <input id="insurancePolicyNumber"
                     tabindex="28"
                     name="insurancePolicyNumber"
                     type="text"
                     placeholder="Insert insurance policy number"
                     class="form-control input-md"
                     [(ngModel)]="tractor.insurancePolicyNumber" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="afterTreatmentYearWarranty">After Treatment Year Warranty</label>
            <div class="col-md-6">
              <input id="afterTreatmentYearWarranty"
                     tabindex="30"
                     name="afterTreatmentYearWarranty"
                     type="text"
                     placeholder="Insert warranty miles"
                     class="form-control input-md"
                     [(ngModel)]="tractor.afterTreatmentYearWarranty" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="baseWarrantyMiles">Base Warranty Miles</label>
            <div class="col-md-6">
              <input id="baseWarrantyMiles"
                     tabindex="32"
                     name="baseWarrantyMiles"
                     type="text"
                     placeholder="Insert Base Warranty Miles"
                     class="form-control input-md"
                     [(ngModel)]="tractor.baseWarrantyMiles" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" form="engineManufactureWarranty">
              Engine manufacture Warranty
            </label>
            <div class="col-md-6">
              <input id="engineManufactureWarranty"
                     tabindex="34"
                     name="engineManufactureWarranty"
                     type="text"
                     placeholder="Insert Engine Manufacture Warranty"
                     class="form-control input-md"
                     [(ngModel)]="tractor.engineManufactureWarranty" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label">Engine Year Warranty</label>
            <div class="col-md-6">
              <input id="engineYearWarranty"
                     tabindex="36"
                     name="engineYearWarranty"
                     type="text"
                     placeholder="Insert Wngine miles Warranty"
                     class="form-control input-md"
                     [(ngModel)]="tractor.engineYearWarranty" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="afterTreatmentMilesWarranty">After Treatment Miles</label>
            <div class="col-md-6">
              <input id="afterTreatmentMilesWarranty"
                     tabindex="38"
                     name="afterTreatmentMilesWarranty"
                     type="text"
                     placeholder="Insert After Treatment Miles Warranty"
                     class="form-control input-md"
                     [(ngModel)]="tractor.afterTreatmentMilesWarranty" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="afterTreatmentMilesWarranty">Equipment Code</label>
            <div class="col-md-6">
              <input id="equipmentCode"
                     name="equipmentCode"
                     tabindex="40"
                     type="text"
                     placeholder="Insert equipment code"
                     class="form-control input-md"
                     [(ngModel)]="tractor.equipmentCode" />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="vinNo">Vin Number</label>
            <div class="col-md-6">
              <input id="vinNo"
                     name="vinNo"
                     tabindex="40"
                     type="text"
                     placeholder="Insert vin number"
                     class="form-control input-md"
                     [(ngModel)]="tractor.vinNo" />
            </div>
          </div>
        </div>
        <!--Pares left ends here section-->
        <!-- Text input
        <div class="form-group">
          <label class="col-md-6 control-label" for="currentLocationState">Current Location State</label>
          <div class="col-md-6">
            <select class="selectState" id="currentLocationState" name="currentLocationState" [(ngModel)]="tractor.currentLocationState">
              <option *ngFor="let state of states" [ngValue]="state.abbreviation">{{state.fullName}}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="currentLocationCity">Current Location City</label>
            <div class="col-md-6">
              <input id="currentLocationCity" name="currentLocationCity" type="text" placeholder="Insert current location city" class="form-control input-md"
                [(ngModel)]="tractor.currentLocationCity">
            </div>
          </div>
        </div>-->
        <br />
        <hr />
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label">Mobile device type</label>
            <div class="col-md-6">
              <select class="selectEquipment"
                      tabindex="42"
                      type="text"
                      id="type"
                      name="type"
                      [(ngModel)]="tractor.mobileDeviceType">
                <option value="NONE">NONE</option>
                <option value="PHONE">PHONE</option>
                <option value="TABLET">TABLET</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="mobileDeviceNumber">Mobile Device Number</label>
            <div class="col-md-6">
              <input id="mobileDeviceNumber"
                     name="mobileDeviceNumber"
                     tabindex="43"
                     type="text"
                     [inputMask]="usPhoneMask"
                     autocomplete="off"
                     placeholder="mobile device number"
                     class="form-control input-md"
                     [(ngModel)]="tractor.mobileDeviceNumber" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-3">
        <div>
          <img class="img-responsive"
               style="margin-top: 89px"
               [src]="previewProfilePicture || 'assets/img/truck.jpg'" />
        </div>
        <br />
        <div class="form-group btnUploadPicture col-md-12">
          <label class="col-md-4 control-label">Upload Picture</label>
          <div class="col-md-8">
            <input type="file"
                   #profilePicture
                   class="form-control-file inpFile"
                   (change)="getTemporalImage($event)" />
          </div>
        </div>
        <br />
        <div class="form-group">
          <label class="col-md-6 control-label" for="company">Tractor Documents</label>
          <div class="col-md-4">
            <createDocument #tractorDocument></createDocument>
          </div>
        </div>
      </div>
    </div>
    <!--second row info-->
    <div class="row" class="bottom-btns">
      <div class="col-md-12" align="center">
        <button type="button"
                class="btn btnCreateTrip"
                (click)="confirmationModal()"
                [disabled]="isProcessing">
          Create New Tractor
        </button>
      </div>
      <br />
    </div>
  </div>
</div>
<button type="button"
        style="display: none"
        class="btn btn-primary"
        id="openModal"
        data-toggle="modal"
        data-target="#modalConfirm"></button>
<!-- Modal -->
<div class="modal fade"
     id="modalConfirm"
     tabindex="-1"
     role="dialog"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 class="modal-title" id="exampleModalLabel">
          Are you sure you want to create this tractor?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="createNewTractor(tractor)">
          Save
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog header="Confirmation"
                 icon="pi pi-exclamation-triangle"
                 [style]="{ width: '50vw' }"></p-confirmDialog>
