<div class="row form-group" *ngIf="isEditing">
  <label class="col-md-2">Add Conditions</label>
  <div class="col-md-10">
    <div class="row">
      <label class="col-md-2">Depending Field</label>
      <div class="col-md-4">
        <select
          [(ngModel)]="selectedField"
          class="form-control"
          (change)="onDependingFieldChanged()"
          aria-label="dependingField"
        >
          <option selected hidden></option>
          <ng-container *ngFor="let field of fields">
            <option [ngValue]="field">
              {{ field.table }} - {{ field.displayName }}
            </option>
          </ng-container>
        </select>
      </div>
      <label *ngIf="selectedField.type" class="col-md-2">Values</label>
      <div *ngIf="selectedField.type" class="col-md-4">
        <!-- String -->
        <div *ngIf="selectedField.type == 'string'">
          <!-- Multi select dropdown -->
          <div class="form-group" *ngIf="selectedField.options">
            <p-multiSelect
              [filter]="true"
              [options]="selectedField.options"
              [(ngModel)]="selectedOptions"
              [virtualScroll]="true"
              itemSize="30"
              optionLabel="itemName"
              filterBy="itemName"
              [style]="{ minWidth: '100%' }"
              tabbableMultiselect
            ></p-multiSelect>
          </div>
          <!-- Manually entered text values -->
          <div
            class="form-group"
            *ngIf="!selectedField.options && !selectedField.requestedOptions"
          >
            <div *ngFor="let textValue of selectedOptions; let i = index">
              <div style="display: flex">
                <input
                  type="text"
                  class="form-control input-md"
                  [(ngModel)]="textValue.itemName"
                  style="flex: 1"
                  aria-label="conditionsValue"
                />
                <button
                  *ngIf="selectedOptions.length > 1"
                  type="button"
                  (click)="removeTextValue(i)"
                >
                  -
                </button>
              </div>
            </div>
            <button
              type="button"
              (click)="addTextValue()"
              style="margin-top: 8px"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedField.type" class="row">
      <div style="text-align: center">
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          class="p-button-success"
          label="Add"
          (click)="addCondition()"
          [disabled]="selectedOptions.length === 0"
          *ngIf="!editingCondition"
        ></button>
        <button
          type="button"
          class="btn"
          (click)="saveCondition()"
          *ngIf="editingCondition"
        >
          Save
        </button>
        <button
          type="button"
          class="btn"
          (click)="cancelEdit()"
          *ngIf="editingCondition"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row form-group">
  <label class="col-md-2">Conditions</label>
  <div class="col-md-10">
    <table *ngIf="conditions.length > 0" class="table">
      <thead>
        <tr>
          <th>Field</th>
          <th>Values</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let condition of conditions; let i = index">
          <td>{{ condition.field | conditionDisplayName }}</td>
          <td>
            <ng-container *ngIf="condition.textMultiple?.length > 0">
              {{ condition.textMultiple }}
            </ng-container>
          </td>
          <td *ngIf="isEditing">
            <div class="action-buttons tableBoxIcons">
              <a (click)="editCondition(i)">
                <span class="glyphicon glyphicon-pencil"></span>
              </a>
              <a (click)="deleteCondition(i)">
                <span class="glyphicon glyphicon-trash"></span>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
