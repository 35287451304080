import { Component, OnInit, ViewChild } from "@angular/core";
import { TrailerService } from "../shared/trailer.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { GetPictureService } from "../../shared/services/files/getPictures.service";
import { MaskService } from "../../shared/services/mask/mask.service";
import { ToastrService } from "ngx-toastr";
import { StatesServices } from "../../shared/services/states/states.service";
import { TerminalLocationsServices } from "../../shared/services/terminals/terminals.service";
import { FileUploadService } from "../../../app/shared/services/files/fileUpload.service";
import { CanComponentDeactivate } from "../../shared/guards/confirmation/confirmation.guard";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "app-trailerinfo",
  templateUrl: "./trailerinfo.component.html",
  styleUrls: ["./trailerinfo.component.css"],
})
export class TrailerinfoComponent implements OnInit, CanComponentDeactivate {
  @ViewChild("trailerDocuments", { static: true }) trailerDocuments;

  dateMask = [];
  uuid: number;
  errorMsg: any;
  trailerInfo: any = {};
  trailer: any = { active: true };
  is_edit: boolean;
  btnProfile: string;
  equipmentCodes: any = [];
  equipmentCodeSelected: any = {};
  states: { abbreviation: string; fullName: string }[];
  terminals: Array<string>;
  originalInfo: any;
  trailerPicture: any;
  newPictureFile: any;
  isProcessing: boolean;
  letters = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
  numbers = /^\d*\.?\d+$/;
  docType: string;
  dateInputMask: any;

  constructor(
    private _trailerinfo: ActivatedRoute,
    private trailerDataService: TrailerService,
    private getPictureService: GetPictureService,
    private maskService: MaskService,
    private statesServices: StatesServices,
    private toastr: ToastrService,
    private router: Router,
    private terminalLocationsServices: TerminalLocationsServices,
    private fileUploadService: FileUploadService,
    private _AuthService: authenticationService
  ) {
    this.is_edit = true;
    this.btnProfile = "Edit Trailer";
    this.docType = "trailer";
  }

  ngOnInit() {
    this.dateInputMask = this.maskService.getInputDateTimeMask();

    this.isProcessing = false;
    this.trailerPicture = "assets/img/trailer.jpg";
    this.terminals = this.terminalLocationsServices.getTerminalLocations();

    this.states = this.statesServices.getTwoLettersStates("US");
    this.trailerDataService.listTypes().subscribe(
      (equipmentCodes: any) => {
        this.equipmentCodes = equipmentCodes;
        this.dateMask = this.maskService.getDate();
        this._trailerinfo.params.subscribe((params) => {
          this.uuid = params["id"];
        });
        this.trailerDataService.gettrailerinfo(this.uuid).subscribe((result) => {
          this.trailerInfo = result;
          if (this.trailerInfo.picture) {
            this.trailerPicture = this.getFilePath(this.trailerInfo.picture);
          }
          this.equipmentCodeSelected =
            equipmentCodes.filter(
              (equipmentCodesValue) =>
                equipmentCodesValue.equipmentCode === this.trailerInfo.equipmentCode
            )[0] || {};
          this.formatDates();
          this.trailerDocuments.getDocuments(
            ("web" + this.docType + "_files").toUpperCase(),
            this.trailerInfo.id
          );
        });
      },
      (err) => {
        this.errorMsg = err;
      }
    );
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  confirm() {
    if (!this.is_edit) {
      return window.confirm("Are you sure you want to leave this page without saving any changes?");
    } else {
      return true;
    }
  }

  getTemporalImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.newPictureFile = event.target.files[0];
      this.trailerPicture = this.getPictureService.getPreviewImage(event.target.files[0]);
    }
  }
  equipmentCodeSelectedChanged() {
    this.trailerInfo.equipmentCode = this.equipmentCodeSelected.equipmentCode;
  }
  getFilePath(path) {
    return this.getPictureService.getPicturePath(path, this.docType);
  }
  dateChanged(event, param, justDate, yearDigits) {
    if (event.code === "Delete" || event.code === "Backspace") {
      return;
    }
    this.maskService.maskDate(event, param, justDate, yearDigits);
  }
  editOn(trailer) {
    if (this.btnProfile === "Edit Trailer") {
      this.originalInfo = JSON.stringify(this.trailerInfo);
      this.btnProfile = "Save Changes";
      this.is_edit = false;
    } else {
      if (this.validateMandatoryField()) {
        if (this.validateTrailerFields()) {
          this.isProcessing = true;
          if (this.newPictureFile) {
            this.uploadNewImage().subscribe((url: any) => {
              trailer.picture = url.name;
              this.updateTrailer(trailer);
            });
          } else {
            this.updateTrailer(trailer);
          }
        }
      }
    }
  }
  uploadNewImage() {
    return this.fileUploadService.uploadSingleFileRename(
      [this.newPictureFile],
      this.docType,
      this.trailerInfo.trailerNo + "_" + this.newPictureFile.name
    );
  }
  uploadDocuments(trailerId) {
    return this.trailerDocuments.uploadDocuments(
      { TrailerId: trailerId },
      this.docType + "_files",
      trailerId
    );
  }

  updateTrailer(trailer) {
    this.trailerDataService.updateTrailer(trailer).subscribe(
      () => {
        this.originalInfo = JSON.stringify(trailer);
        this.toastr.success("Trailer updated", "Success!");
        this.isProcessing = false;
        this.is_edit = true;
        this.btnProfile = "Edit Trailer";
        this.router.navigate(["/trailerlist"]);
      },
      (error) => {
        this.toastr.warning(error, "Alert!");
        console.dir(error);
        this.isProcessing = false;
      }
    );
  }

  getPicturePath(name) {
    return this.getPictureService.getPicturePath(name, this.docType);
  }

  cancelEdit() {
    this.is_edit = true;
    this.btnProfile = "Edit Trailer";
    this.trailerInfo = JSON.parse(this.originalInfo);
    this.formatDates();
  }
  formatDates() {
    this.trailerInfo.purchasedDate = this.trailerInfo.purchasedDate
      ? new Date(this.trailerInfo.purchasedDate)
      : null;
    this.trailerInfo.licenseExpirationDate = this.trailerInfo.licenseExpirationDate
      ? new Date(this.trailerInfo.licenseExpirationDate)
      : null;
  }

  // validations start here

  validateMandatoryField(): boolean {
    try {
      this.isValidManufactureSerialNo();
      this.isValidTerminalLocation();
      this.isValidTrailerType();
      this.isValidYear();
      this.isValidPurchasedDate();
      this.isValidMake();
      this.isValidPrice();
      this.isValidModel();
      this.isValidLength();
      this.isValidHeight();
      this.isValidStateOfRegistration();
      this.isValidWeight();
      this.isValidLicenseNumber();
      this.isValidAxles();
      this.isValidLicenseExpirationDate();
      this.isValidTireSize();
      this.isValidEquipmentCodeSelected();
      this.isValidEngineHours();
      this.isValidInsurancePolicyNumber();
      this.isValidInsuranceName();
      this.isValidCurrentLocationState();
      this.isValidCurrentLocationCity();
      this.isValidSerial();
      this.isValidOwner();
      this.isValidMobileDeviceType();
      this.isValidMobileDeviceNumber();
      return true;
    } catch (error) {
      switch (error.type) {
        case "warning":
          this.toastr.warning(error.msg, "Alert!", {
            closeButton: true,
            enableHtml: true,
          });
          console.log("Validation error");
          console.dir(error);
          break;
        default:
          this.toastr.info(error.msg, "Info!", {
            closeButton: true,
            enableHtml: true,
          });
          console.log("Validation error");
          console.dir(error);
          break;
      }
    }
  }

  isValidManufactureSerialNo(): boolean {
    if (typeof this.trailerInfo.trailerNo === "undefined") {
      throw {
        type: "warning",
        msg: "Trailer Manufacture Serial No <b>Required</b>",
      };
    }
    return true;
  }

  isValidTerminalLocation(): boolean {
    if (typeof this.trailerInfo.terminalLocation === "undefined") {
      throw {
        type: "warning",
        msg: "Trailer terminal Location <b>Required</b>",
      };
    }
    return true;
  }

  isValidTrailerType(): boolean {
    if (typeof this.trailerInfo.trailerType === "undefined") {
      throw { type: "warning", msg: "Trailer type <b>Required</b>" };
    }
    return true;
  }

  isValidYear(): boolean {
    if (typeof this.trailerInfo.year === "undefined") {
      throw { type: "warning", msg: "Trailer Year <b>Required</b>" };
    }
    return true;
  }

  isValidPurchasedDate(): boolean {
    if (typeof this.trailerInfo.purchasedDate === "undefined") {
      throw { type: "warning", msg: "Trailer Purchased Date <b>Required</b>" };
    }
    return true;
  }

  isValidMake(): boolean {
    if (typeof this.trailerInfo.make === "undefined") {
      throw { type: "warning", msg: "Trailer Make <b>Required</b>" };
    }
    return true;
  }

  isValidPrice(): boolean {
    if (typeof this.trailerInfo.price === "undefined") {
      throw { type: "warning", msg: "Trailer Price <b>Required</b>" };
    }
    return true;
  }

  isValidModel(): boolean {
    if (typeof this.trailerInfo.model === "undefined") {
      throw { type: "warning", msg: "Trailer Model <b>Required</b>" };
    }
    return true;
  }

  isValidLength(): boolean {
    if (typeof this.trailerInfo.length === "undefined") {
      throw { type: "warning", msg: "Trailer Length <b>Required</b>" };
    }
    return true;
  }

  isValidHeight(): boolean {
    if (typeof this.trailerInfo.height === "undefined") {
      throw { type: "warning", msg: "Trailer Height <b>Required</b>" };
    }
    return true;
  }

  isValidStateOfRegistration(): boolean {
    if (typeof this.trailerInfo.stateOfRegistration === "undefined") {
      throw {
        type: "warning",
        msg: "Trailer State Of Registration <b>Required</b>",
      };
    }
    return true;
  }

  isValidWeight(): boolean {
    if (typeof this.trailerInfo.weight === "undefined") {
      throw { type: "warning", msg: "Trailer Weight <b>Required</b>" };
    }
    return true;
  }

  isValidLicenseNumber(): boolean {
    if (typeof this.trailerInfo.licenseNumber === "undefined") {
      throw { type: "warning", msg: "Trailer License Number <b>Required</b>" };
    }
    return true;
  }

  isValidAxles(): boolean {
    if (typeof this.trailerInfo.axles === "undefined") {
      throw { type: "warning", msg: "Trailer Axles <b>Required</b>" };
    }
    return true;
  }

  isValidLicenseExpirationDate(): boolean {
    if (typeof this.trailerInfo.licenseExpirationDate === "undefined") {
      throw {
        type: "warning",
        msg: "Trailer License Expiration Date <b>Required</b>",
      };
    }
    return true;
  }

  isValidTireSize(): boolean {
    if (typeof this.trailerInfo.tireSize === "undefined") {
      throw { type: "warning", msg: "Trailer Tire Size <b>Required</b>" };
    }
    return true;
  }

  isValidEquipmentCodeSelected(): boolean {
    if (typeof this.trailerInfo.equipmentCode === "undefined") {
      throw {
        type: "warning",
        msg: "Trailer Equipment Code Selected <b>Required</b>",
      };
    }
    return true;
  }

  isValidEngineHours(): boolean {
    if (typeof this.trailerInfo.engineHours === "undefined") {
      throw { type: "warning", msg: "Trailer Engine Hours <b>Required</b>" };
    }
    return true;
  }

  isValidInsurancePolicyNumber(): boolean {
    if (typeof this.trailerInfo.insurancePolicyNumber === "undefined") {
      throw {
        type: "warning",
        msg: "Trailer Insurance Policy Number <b>Required</b>",
      };
    }
    return true;
  }

  isValidInsuranceName(): boolean {
    if (typeof this.trailerInfo.insuranceName === "undefined") {
      throw { type: "warning", msg: "Trailer Insurance Name <b>Required</b>" };
    }
    return true;
  }

  isValidCurrentLocationState(): boolean {
    if (typeof this.trailerInfo.currentLocationState === "undefined") {
      throw {
        type: "warning",
        msg: "Trailer Current Location State <b>Required</b>",
      };
    }
    return true;
  }

  isValidCurrentLocationCity(): boolean {
    if (typeof this.trailerInfo.currentLocationCity === "undefined") {
      throw {
        type: "warning",
        msg: "Trailer Current Location City <b>Required</b>",
      };
    }
    return true;
  }

  isValidSerial(): boolean {
    if (typeof this.trailerInfo.serial === "undefined") {
      throw { type: "warning", msg: "Trailer Serial <b>Required</b>" };
    }
    return true;
  }

  isValidOwner(): boolean {
    if (typeof this.trailerInfo.owner === "undefined") {
      throw { type: "warning", msg: "Trailer Owner <b>Required</b>" };
    }
    return true;
  }

  isValidMobileDeviceType(): boolean {
    if (typeof this.trailerInfo.mobileDeviceType === "undefined") {
      throw {
        type: "warning",
        msg: "Trailer Mobile Device Type <b>Required</b>",
      };
    }
    return true;
  }

  isValidMobileDeviceNumber(): boolean {
    if (typeof this.trailerInfo.mobileDeviceNumber === "undefined") {
      throw {
        type: "warning",
        msg: "Trailer Mobile Device Number <b>Required</b>",
      };
    }
    return true;
  }

  // I am here

  validateTrailerFields(): boolean {
    try {
      this.isContentManufactureSerialNo();
      this.isContentYear();
      this.isContentPrice();
      this.isContentModel();
      this.isContentLength();
      this.isContentLicenseNumber();
      this.isContentHeight();
      this.isContentWeight();
      this.isContenAxles();
      this.isContenTireSize();
      this.isContentEngineHours();
      this.isContentInsuranceNumber();
      this.isContentInsuranceName();
      this.isContentCurrentLocationCity();
      this.isContentCurrentLocationState();
      this.isContentSerial();
      this.isContentOwner();
      this.isContentMobileDeviceNumber();
      return true;
    } catch (error) {
      switch (error.type) {
        case "warning":
          this.toastr.warning(error.msg, "Alert!", {
            closeButton: true,
            enableHtml: true,
          });
          throw error;
        default:
          this.toastr.info(error.msg, "Info!", {
            closeButton: true,
            enableHtml: true,
          });
          throw error;
      }
    }
  }

  isContentManufactureSerialNo(): boolean {
    if (this.trailerInfo.trailerNo && !this.trailerInfo.trailerNo.match(this.letters)) {
      throw {
        type: "warning",
        msg: "Manufacture Serial can not contain special characters",
      };
    }
    return true;
  }

  isContentYear(): boolean {
    if (this.trailerInfo.year && isNaN(Number(this.trailerInfo.year))) {
      throw { type: "warning", msg: "Year can only be numbers." };
    } else if (this.trailerInfo.year && this.trailerInfo.year.length > 4) {
      throw { type: "warning", msg: "YEAR format is not correct (YYYY)" };
    }
    return true;
  }

  isContentPrice(): boolean {
    if (this.trailerInfo.price && isNaN(Number(this.trailerInfo.price))) {
      throw { type: "warning", msg: "Trailer Price can only be numbers." };
    }
    return true;
  }

  isContentModel(): boolean {
    if (this.trailerInfo.model && !this.trailerInfo.model.match(this.letters)) {
      throw { type: "warning", msg: "Trailer Model can only be letters." };
    }
    return true;
  }

  isContentLength(): boolean {
    if (this.trailerInfo.length && isNaN(Number(this.trailerInfo.length))) {
      throw { type: "warning", msg: "Trailer Length can only be numbers." };
    }
    return true;
  }

  isContentLicenseNumber(): boolean {
    if (this.trailerInfo.licenseNumber && !this.trailerInfo.licenseNumber.match(this.letters)) {
      throw {
        type: "warning",
        msg: "Trailer Vin can not contain special characters",
      };
    }
    return true;
  }

  isContentHeight(): boolean {
    if (this.trailerInfo.height && isNaN(Number(this.trailerInfo.height))) {
      throw { type: "warning", msg: "Trailer Height can only be numbers." };
    }
    return true;
  }

  isContentWeight(): boolean {
    if (this.trailerInfo.weight && isNaN(Number(this.trailerInfo.weight))) {
      throw { type: "warning", msg: "Trailer Weight can only be numbers." };
    }
    return true;
  }

  isContenAxles(): boolean {
    if (this.trailerInfo.axles && isNaN(Number(this.trailerInfo.axles))) {
      throw { type: "warning", msg: "Trailer Axles can only be numbers." };
    }
    return true;
  }

  isContenTireSize(): boolean {
    if (this.trailerInfo.tireSize && isNaN(Number(this.trailerInfo.tireSize))) {
      throw { type: "warning", msg: "Trailer Tire Size can only be numbers." };
    }
    return true;
  }

  isContentEngineHours(): boolean {
    if (this.trailerInfo.engineHours && isNaN(Number(this.trailerInfo.engineHours))) {
      throw { type: "warning", msg: "Trailer Tire Size can only be numbers." };
    }
    return true;
  }

  isContentInsuranceNumber(): boolean {
    if (
      this.trailerInfo.insurancePolicyNumber &&
      !this.trailerInfo.insurancePolicyNumber.match(this.letters)
    ) {
      throw {
        type: "warning",
        msg: "Trailer insurance Policy Number can not contain special characters",
      };
    }
    return true;
  }

  isContentInsuranceName(): boolean {
    if (this.trailerInfo.insuranceName && !this.trailerInfo.insuranceName.match(this.letters)) {
      throw {
        type: "warning",
        msg: "Trailer insurance Policy Name can only be letters.",
      };
    }
    return true;
  }

  isContentCurrentLocationCity(): boolean {
    if (
      this.trailerInfo.currentLocationCity &&
      !this.trailerInfo.currentLocationCity.match(this.letters)
    ) {
      throw {
        type: "warning",
        msg: "Trailer current Location City can only be letters.",
      };
    }
    return true;
  }

  isContentCurrentLocationState(): boolean {
    if (
      this.trailerInfo.currentLocationState &&
      !this.trailerInfo.currentLocationState.match(this.letters)
    ) {
      throw {
        type: "warning",
        msg: "Trailer current Location State can only be letters.",
      };
    }
    return true;
  }

  isContentSerial(): boolean {
    if (this.trailerInfo.serial && isNaN(Number(this.trailerInfo.serial))) {
      throw { type: "warning", msg: "Trailer serial can only be numbers." };
    }
    return true;
  }

  isContentOwner(): boolean {
    if (this.trailerInfo.owner && !this.trailerInfo.owner.match(this.letters)) {
      throw { type: "warning", msg: "trailer Owner can only be letters." };
    }
    return true;
  }

  isContentMobileDeviceNumber(): boolean {
    if (this.trailerInfo.mobileDeviceNumber && isNaN(Number(this.trailerInfo.mobileDeviceNumber))) {
      throw {
        type: "warning",
        msg: "Trailer Mobile Device Number can only be numbers.",
      };
    }
    return true;
  }
}
