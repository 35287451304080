
<div *ngFor="let document of documents;let i = index">
  <div class="row" *ngIf="!document.deleted">
    <div class="col-md-12">
      <button (click)="document.deleted = true" *ngIf="!is_edit">X</button>
      <input  type="text"  style="width: 200px"  [(ngModel)]="document.name" [disabled]="is_edit" class="form-control input-md inp">   
      <input type="file" style=" color: transparent; padding-left: 5em;" (change)='setFilesToDocument($event,document)'  multiple placeholder="Add files" /> 
  </div>
  </div>
  
  <div *ngFor="let file of document.AttachedFiles; let j = index">
  <div class="row"*ngIf="!file.deleted">
    <div class="col-md-12">
  <button (click)="deleteFile(document ,j )" *ngIf="!is_edit">X</button>          
      <a  target="_blank" href="{{getPicturePath(file.path, 'others')}}">
      <button type="button" class="btn btn-secondary">Download  {{file.name}}</button>
      <br>
      </a>
  </div>
  </div>
    </div>
  </div>
