import { Injectable } from "@angular/core";

import { ChargetypesService } from "./chargetypes.service";

@Injectable()
export class GlobalChargeTypesResolver {
  constructor(private chargeTypesService: ChargetypesService) {}

  resolve() {
    return this.chargeTypesService.getGlobalChargeTypes();
  }
}
