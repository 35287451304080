import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-drivers-selector",
  templateUrl: "./drivers-selector.component.html",
  styleUrls: ["./drivers-selector.component.css"],
})
export class DriversSelectorComponent implements OnInit {
  @Input() isEditing: boolean;
  @Input() drivers = [];
  @Input() isDefaultDriver: boolean = false;
  @Output() isDefaultDriverChange = new EventEmitter();
  @Input() selectedDrivers = [];
  @Output() selectedDriversChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  // sort so that the selected drivers are at the top, ties broken by name
  sortSelected() {
    const selected = this.selectedDrivers.filter((d) => d != undefined);
    const notSelected = this.drivers.filter((d) => !selected.some((s) => s?.id === d?.id));
    selected.sort((a, b) => a.lastName - b.lastName);
    notSelected.sort((a, b) => a.lastName - b.lastName);
    this.drivers = [...selected, ...notSelected];
  }
}
