<div class="container">
  <div class="row firstRowContent">
    <div align="center">
      <span class="titleLoad" id="arrowLeft" (click)="previousDriver()">
        <img
          src="assets\img\arrow-left.svg"
          alt="Previous"
          srcset="assets\img\arrow-left.svg 200w, assets\img\arrow-left.svg 400w"
          sizes="(min-width: 600px) 300px, 100vw"
        />
        Previous
      </span>
      <span class="titleLoad">
        {{ batchSelected.status || "NO BATCH" }} - {{ driverInfo.driverNo }} -
        {{ driverInfo.name }} {{ driverInfo.lastName }}
      </span>
      <span class="titleLoad" id="arrowRight" (click)="nextDriver()">
        Next
        <img
          src="assets\img\arrow-right.svg"
          alt="Next"
          srcset="assets\img\arrow-right.svg 200w, assets\img\arrow-right.svg 400w"
          sizes="(min-width: 600px) 300px, 100vw"
        />
      </span>
    </div>
    <br />
    <div class="topActionBar col-md-12 row rowHou">
      <div class="divRightTitle displayBox col-md-3">
        <select
          id="batches-list"
          [(ngModel)]="batchSelected"
          (change)="getAllBatchInfo()"
          class="form-control selectMenuList"
        >
          <option *ngFor="let batch of batchsList" [ngValue]="batch">
            BATCH {{ batch.batchNo }} {{ batch.startDate | date : "MM/dd/yyyy" : "GMT-00:00" }} -
            {{ batch.endDate | date : "MM/dd/yyyy" : "GMT-00:00" }}
          </option>
        </select>
      </div>
      <div hidden="true" class="form-group col-md-2 colReveneu">
        <label class="col-md-9 control-label">NO REVENUE BATCH</label>
        <div class="col-md-3 statusSwitch">
          <p-checkbox
            [(ngModel)]="batchSelected.noRevenue"
            binary="true"
            name="noRevenue"
            [disabled]="!batchInfo.isOpen"
            hidden="true"
          >
          </p-checkbox>
        </div>
      </div>
    </div>

    <!-- Tab Headers -->
    <div class="col-md-12 divTableZero">
      <div class="tab">
        <button class="tablinks active" id="one" (click)="tabDestination('Loads')">LOADS</button>
        <button class="tablinks" id="two" (click)="tabDestination('Fuel')">FUEL</button>
        <button class="tablinks" id="three" (click)="tabDestination('Reimbursements')">
          REIMBURSEMENTS
        </button>
        <button class="tablinks" id="four" (click)="tabDestination('Deduction')">DEDUCTIONS</button>
        <button class="tablinks" id="five" (click)="tabDestination('Escrow')">ESCROW</button>
        <button class="tablinks" id="six" (click)="tabDestination('Deficit')">DEFICIT</button>
        <button class="tablinks" id="eight" (click)="tabDestination('Teams')">TEAMS</button>
        <button class="tablinks" id="nine" (click)="tabDestination('Invoice')">SETTLEMENT</button>
      </div>

      <!-- Loads tab -->
      <div id="Loads" class="tabcontent">
        <br />
        <div class="row">
          <div class="col-md-6">
            <div *ngIf="batchTrips">
              Total: {{ totalEarnings | currency }}
              <button
                class="btn"
                style="background: lightgrey; margin-bottom: 6px"
                (click)="selectAllEarnings()"
              >
                Select All Earnings
              </button>
              <button
                class="btn"
                style="margin-left: 6px; background: lightgrey; margin-bottom: 6px"
                (click)="createTrip()"
              >
                Create Trip
              </button>
              <button
                class="btn"
                data-toggle="collapse"
                data-target=".multi-collapse"
                style="margin-left: 6px; background: lightgrey; margin-bottom: 6px"
              >
                Expand All
              </button>
            </div>
            <div class="col-md-12" *ngFor="let tripPayment of batchTrips; let i = index">
              <div class="toggleClassTrip">
                <span>
                  <span class="titleTable">
                    TRIP
                    <a [routerLink]="'/new/edittrip/' + tripPayment.id + '/T'" target="_blank">
                      {{ tripPayment.tripNo }}
                    </a>
                  </span>
                  <span class="titleTable">{{ tripPayment.startDate | date : "MM/dd/yyyy" }}</span>
                  <span class="titleTable">{{ tripPayment.endDate | date : "MM/dd/yyyy" }}</span>
                  <span class="titleTable"
                    >TOTAL {{ tripPayment.netNonTripExpense | currency }}</span
                  >
                  <span class="titleTable">
                    INCLUDE:&nbsp;
                    <div class="titleTable statusSwitch">
                      <p-checkbox
                        [(ngModel)]="tripPayment.include"
                        binary="true"
                        name="switchActive"
                        (onChange)="LoadIncludeChange(tripPayment)"
                        [disabled]="tripPayment.status == 'PAYOUT' || !batchInfo.isOpen"
                      >
                      </p-checkbox>
                    </div>
                  </span>
                  <span
                    class="imageCollapse"
                    data-toggle="collapse"
                    [attr.data-target]="'#' + tripPayment.tripNo"
                    style="cursor: pointer"
                  >
                    <img src="assets\img\arrow-down.svg" width="15" />
                  </span>
                </span>
              </div>
              <div id="{{ tripPayment.tripNo }}" class="collapse borderDivIntTrip multi-collapse">
                <p-table
                  #dt1
                  [columns]="colsCharge"
                  [value]="tripPayment.driverCharges"
                  [autoLayout]="true"
                >
                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns">
                        {{ col.header }}
                      </th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-charge let-y="rowIndex">
                    <tr *ngIf="charge.delete == false">
                      <!-- Charge Type -->
                      <td
                        id="celda0{{ y }}scrollable"
                        [pEditableColumn]="charge"
                        [pEditableColumnField]="charge.chargeType"
                      >
                        <div>
                          <p-cellEditor class="elements-table">
                            <ng-template pTemplate="input">
                              <p-dropdown
                                [options]="driverChargeTypes"
                                [(ngModel)]="charge.chargeType.id"
                                (onChange)="editCharge(charge)"
                                optionLabel="label"
                                optionValue="value.id"
                                placeholder="Select a Charge Type"
                                filter="true"
                                [disabled]="!batchInfo.isOpen"
                                appendTo="body"
                                tabbableDropdown
                              ></p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" style="text-align: center">
                              {{ charge.chargeType?.name }}
                            </ng-template>
                          </p-cellEditor>
                        </div>
                      </td>
                      <!-- Description -->
                      <td id="celda1{{ y }}scrollable">
                        <div>
                          <span style="text-align: center">
                            {{ charge.description }}
                          </span>
                        </div>
                      </td>
                      <!-- Flat or Variable -->
                      <td
                        id="celda0{{ y }}scrollable"
                        [pEditableColumn]="charge"
                        [pEditableColumnField]="charge.rateType"
                      >
                        <div>
                          <p-cellEditor class="elements-table">
                            <ng-template pTemplate="input">
                              <p-dropdown
                                [options]="rateTypes"
                                [(ngModel)]="charge.rateType"
                                (onChange)="editCharge(charge)"
                                optionLabel="value"
                                optionValue="value"
                                placeholder="Select a Rate Type"
                                filter="true"
                                [disabled]="!batchInfo.isOpen"
                                appendTo="body"
                                tabbableDropdown
                              ></p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" style="text-align: center">
                              {{ charge.rateType }}
                            </ng-template>
                          </p-cellEditor>
                        </div>
                      </td>
                      <!-- Uses Units (Units or Miles) -->
                      <td
                        id="celda0{{ y }}scrollable"
                        [pEditableColumn]="charge"
                        [pEditableColumnField]="charge.usesUnits"
                      >
                        <div>
                          <p-cellEditor class="elements-table">
                            <ng-template pTemplate="input">
                              <p-dropdown
                                [options]="unitsOrMiles"
                                [(ngModel)]="charge.usesUnits"
                                (onChange)="editCharge(charge)"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Select Units/Miles"
                                filter="true"
                                [disabled]="!batchInfo.isOpen"
                                appendTo="body"
                                tabbableDropdown
                              ></p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" style="text-align: center">
                              <span *ngIf="charge.usesUnits">Units</span>
                              <span *ngIf="!charge.usesUnits">Miles</span>
                            </ng-template>
                          </p-cellEditor>
                        </div>
                      </td>
                      <!-- Units -->
                      <td
                        id="celda2{{ y }}scrollable"
                        [pEditableColumn]="charge"
                        [pEditableColumnField]="charge.units"
                        style="text-align: right"
                      >
                        <div>
                          <p-cellEditor class="elements-table">
                            <ng-template pTemplate="input">
                              <input
                                pInputText
                                type="number"
                                [(ngModel)]="charge.units"
                                (change)="editCharge(charge)"
                                (keydown)="onKeyBoard($event, 2, y)"
                                (keyup)="editCharge(charge)"
                                [disabled]="!batchInfo.isOpen"
                                onfocus="this.select()"
                              />
                            </ng-template>
                            <ng-template pTemplate="output" style="text-align: center">
                              {{ charge.units | number : "1.2-2" }}
                            </ng-template>
                          </p-cellEditor>
                        </div>
                      </td>
                      <!-- Miles -->
                      <td
                        id="celda3{{ y }}scrollable"
                        [pEditableColumn]="charge"
                        [pEditableColumnField]="charge.miles"
                        style="text-align: right"
                      >
                        <div>
                          <p-cellEditor class="elements-table">
                            <ng-template pTemplate="input">
                              <input
                                pInputText
                                type="number"
                                [(ngModel)]="charge.miles"
                                (change)="editCharge(charge)"
                                (keydown)="onKeyBoard($event, 3, y)"
                                (keyup)="editCharge(charge)"
                                [disabled]="!batchInfo.isOpen"
                                onfocus="this.select()"
                              />
                            </ng-template>
                            <ng-template pTemplate="output" style="text-align: center">
                              {{ charge.miles | number : "1.1-1" }}
                            </ng-template>
                          </p-cellEditor>
                        </div>
                      </td>
                      <!-- Rate -->
                      <td
                        id="celda4{{ y }}scrollable"
                        [pEditableColumn]="charge"
                        [pEditableColumnField]="charge.chargeRate"
                        style="text-align: right"
                      >
                        <div>
                          <p-cellEditor class="elements-table">
                            <ng-template pTemplate="input">
                              <input
                                pInputText
                                type="number"
                                [(ngModel)]="charge.chargeRate"
                                (change)="editCharge(charge)"
                                (keydown)="onKeyBoard($event, 4, y)"
                                (keyup)="editCharge(charge)"
                                [disabled]="!batchInfo.isOpen"
                                onfocus="this.select()"
                              />
                            </ng-template>
                            <ng-template pTemplate="output" style="text-align: center">
                              {{ charge.chargeRate | number : "1.4-4" }}
                            </ng-template>
                          </p-cellEditor>
                        </div>
                      </td>
                      <!-- Total -->
                      <td style="text-align: right">{{ charge.price | currency }}</td>
                      <!-- Delete -->
                      <td>
                        <button
                          (click)="removeCharge(tripPayment.driverCharges, charge)"
                          title="Remove Charge"
                          [disabled]="!batchInfo.isOpen"
                        >
                          -
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td>
                        <button
                          (click)="addCharge(tripPayment)"
                          title="Add Charge"
                          [disabled]="!batchInfo.isOpen"
                        >
                          +
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="batchTrips.length > 0 && guaranteedDailyRate > 0">
            <div><strong>Driver Guarantee</strong> Pay</div>
            <div><strong>Driver Guarantee Amount</strong> {{ guaranteedDailyRate | currency }}</div>
            <p-table
              [columns]="colsGuarantees"
              [value]="batchTrips"
              [style]="{ width: '1000px' }"
              class="guaranteeTable"
              [autoLayout]="true"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns">
                    {{ col.header }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-trip>
                <tr>
                  <td>{{ trip.tripNo }}</td>
                  <td [pEditableColumn]="trip">
                    <p-cellEditor class="elements-table">
                      <ng-template pTemplate="input">
                        <input
                          name="startDate"
                          type="text"
                          [inputMask]="dateInputMask"
                          autocomplete="off"
                          placeholder="MM/dd/yyyy HH:mm"
                          class="form-control input-md"
                          [(ngModel)]="trip.startDate"
                        />
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span [class.editedField]="trip.startDate != trip.startDate">
                          {{ trip.startDate | date : "MM/dd/yyyy HH:mm" }}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td [pEditableColumn]="trip">
                    <p-cellEditor class="elements-table">
                      <ng-template pTemplate="input">
                        <input
                          name="endDate"
                          type="text"
                          [inputMask]="dateInputMask"
                          autocomplete="off"
                          placeholder="MM/dd/yyyy HH:mm"
                          class="form-control input-md"
                          [(ngModel)]="trip.endDate"
                        />
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span [class.editedField]="trip.endDate != trip.endDate">
                          {{ trip.endDate | date : "MM/dd/yyyy HH:mm" }}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>{{ trip.tripTimeMinutes / 60 | number }}</td>
                  <td>{{ trip.payableMiles }}</td>
                  <td>{{ trip.netNonTripExpense | currency }}</td>
                  <td>({{ trip.excludedPayCharges | currency }})</td>
                  <td>{{ trip.netNonTripExpense - trip.excludedPayCharges | currency }}</td>
                  <td>{{ trip.guaranteedAmount | currency }}</td>
                  <td
                    [pEditableColumn]="trip"
                    [ngClass]="{
                      editedField: trip.guaranteedExtraPay != trip.calculatedGuaranteedExtraPay
                    }"
                  >
                    <p-cellEditor class="elements-table">
                      <ng-template pTemplate="input">
                        <input
                          pInputText
                          type="number"
                          [(ngModel)]="trip.guaranteedExtraPay"
                          [disabled]="!batchInfo.isOpen"
                          onfocus="this.select()"
                        />
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ trip.guaranteedExtraPay | currency }}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <!-- Fuel tab -->
      <div id="Fuel" class="tabcontent">
        <app-settlement-fuel-tab
          [(advances)]="advances"
          [advanceTotals]="advanceTotals"
          [batchSelected]="batchSelected"
        ></app-settlement-fuel-tab>
      </div>

      <!-- Reimbursements Tab -->
      <div id="Reimbursements" class="tabcontent">
        <div class="borderCollapse">
          <p-table #dt3 [columns]="colsReimbursements" [value]="reimbursements">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">
                  {{ col.header }}
                </th>
                <th>
                  <button
                    type="button"
                    (click)="addReinbursement()"
                    align="center"
                    *ngIf="batchSelected.status != 'CLOSED'"
                  >
                    Add
                  </button>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-reimbursement>
              <tr style="height: 45px">
                <td pEditableColumn style="width: 190px">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <select
                        [(ngModel)]="reimbursement.rateType"
                        class="inputForm inputTable"
                        (change)="changesOnReinbursement(reimbursement)"
                        [disabled]="!batchInfo.isOpen"
                      >
                        <option value="VARIABLE">VARIABLE</option>
                        <option value="FLAT">FLAT</option>
                      </select>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ reimbursement?.rateType }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width: 230px">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <select
                        [(ngModel)]="reimbursement.chargeTypeId"
                        class="inputForm inputTable"
                        (change)="changesOnReinbursement(reimbursement)"
                        [disabled]="!batchInfo.isOpen"
                      >
                        <option [ngValue]="''" selected="selected">--Select Accessorial--</option>
                        <option
                          *ngFor="let accessory of accessorials"
                          [ngValue]="accessory.value.id"
                        >
                          {{ accessory.value.description }}
                        </option>
                      </select>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ reimbursement?.chargeType?.description }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width: 195px">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        pInputText
                        type="number"
                        [(ngModel)]="reimbursement.units"
                        [disabled]="!batchInfo.isOpen"
                        (change)="changesOnReinbursement(reimbursement)"
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ reimbursement.units }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width: 210px">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        pInputText
                        type="number"
                        [(ngModel)]="reimbursement.chargeRate"
                        [disabled]="!batchInfo.isOpen"
                        (change)="changesOnReinbursement(reimbursement)"
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ reimbursement.chargeRate | currency }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td>{{ reimbursement.price | currency }}</td>
                <td pEditableColumn style="width: 160px">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <select
                        [(ngModel)]="reimbursement.tripId"
                        class="inputForm inputTable"
                        (change)="changesOnReinbursement(reimbursement)"
                        [disabled]="!batchInfo.isOpen"
                      >
                        <option [ngValue]="null" selected="selected"></option>
                        <ng-container *ngFor="let tripPayment of batchTrips">
                          <option *ngIf="tripPayment.include" [ngValue]="tripPayment.id">
                            {{ tripPayment.tripNo }}
                          </option>
                        </ng-container>
                      </select>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ reimbursement.tripNo }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td style="width: 70px">
                  <a
                    (click)="deleteReinbursement(reimbursement)"
                    class="trash"
                    style="cursor: pointer"
                    *ngIf="batchSelected.status != 'CLOSED' && !reimbursement.delete"
                    title="Delete Reimbursement"
                  >
                    <span class="glyphicon glyphicon-trash"></span>
                  </a>
                  <a
                    (click)="deleteReinbursement(reimbursement)"
                    class="trash"
                    style="cursor: pointer"
                    *ngIf="batchSelected.status != 'CLOSED' && reimbursement.delete"
                    title="Undelete Reimbursement"
                  >
                    <span class="glyphicon glyphicon-trash" style="color: red"></span>
                  </a>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <!-- Deduction Tab -->
      <div id="Deduction" class="tabcontent">
        <div class="d-flex" style="max-width: 800px; flex-direction: column">
          <div class="d-flex" style="max-width: 350px; margin-bottom: 10px">
            <a routerLink="/settlements/deductions" target="_blank">
              <button class="btn buttonBottom minWidthbutton">DEDUCTION LIST</button>
            </a>
          </div>
          <p-table #dt4 [columns]="colsDeduction" [value]="deductions" [paginator]="false">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width: 20px"></th>
                <th style="text-align: left">Deduction</th>
                <th style="text-align: right; width: 170px">Amount</th>
                <th>
                  <button
                    type="button"
                    (click)="addDeduction()"
                    class="btn btn-success"
                    *ngIf="batchSelected.status != 'CLOSED'"
                    align="center"
                  >
                    Add
                  </button>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-deduction>
              <tr>
                <td style="text-align: center; vertical-align: middle">
                  <img
                    src="assets/img/details.svg"
                    width="20"
                    style="margin: 0px; cursor: pointer"
                    title="Go To Deduction"
                    [routerLink]="'/settlements/deductions/' + deduction.deductionId"
                  />
                </td>
                <td>
                  <p-dropdown
                    #deductionDropDown
                    [style]="{ width: '100%' }"
                    [options]="allDeductions"
                    [(ngModel)]="deduction.deductionId"
                    optionValue="deductionId"
                    optionLabel="description"
                    filterBy="description"
                    placeholder="Select a Deduction"
                    filter="true"
                    id="deductionDropDown"
                    [disabled]="deduction.id"
                    (onChange)="chargeChange(deduction)"
                    appendTo="body"
                    tabbableDropdown
                  ></p-dropdown>
                </td>
                <td style="text-align: right; width: 170px">
                  <p-inputNumber
                    [inputStyle]="{ 'text-align': 'right' }"
                    (onInput)="chargeChange(deduction)"
                    class="col-md-12"
                    [(ngModel)]="deduction.customValue"
                    mode="decimal"
                    prefix="$"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    (onFocus)="$event.target.select()"
                  ></p-inputNumber>
                </td>
                <td style="width: 70px">
                  <a
                    (click)="deleteDeduction(deduction)"
                    class="trash"
                    style="cursor: pointer"
                    *ngIf="batchSelected.status != 'CLOSED' && !deduction.delete"
                    title="Delete Deduction"
                  >
                    <span class="glyphicon glyphicon-trash"></span>
                  </a>
                  <a
                    (click)="deleteDeduction(deduction)"
                    class="trash"
                    style="cursor: pointer"
                    *ngIf="batchSelected.status != 'CLOSED' && deduction.delete"
                    title="Undelete Deduction"
                  >
                    <span class="glyphicon glyphicon-trash" style="color: red"></span>
                  </a>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer" let-columns>
              <tr>
                <td></td>
                <td style="text-align: left">TOTALS</td>
                <td style="text-align: right">
                  <b>{{ summaryDeductions.customValue | currency }}</b>
                </td>
                <td></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

      <!-- Escrow tab -->
      <div id="Escrow" class="tabcontent">
        <app-escrow #escrowComponent [batchInfo]="batchInfo"></app-escrow>
      </div>

      <!-- Deficit tab -->
      <div id="Deficit" class="tabcontent">
        <app-deficit #deficitComponent [batchInfo]="batchInfo"></app-deficit>
      </div>

      <!-- Teams tab -->
      <div id="Teams" class="tabcontent">
        <app-settlement-teams-tab
          #teamSplitComponent
          [batchId]="this.batchSelected.id"
          [batchStatus]="this.batchSelected.status"
        >
        </app-settlement-teams-tab>
      </div>

      <!-- Invoice tab -->
      <div id="Invoice" class="tabcontent tab-invoice" style="padding: 0px !important">
        <app-settlement-invoice *ngIf="loadPDF" [settlement]="settlement"></app-settlement-invoice>
      </div>
    </div>
    <div align="center">
      <button
        class="btn buttonBottom minWidthbutton"
        (click)="saveBatch()"
        [disabled]="!batchInfo.isOpen"
      >
        SAVE
      </button>
      <button
        class="btn buttonBottom minWidthbutton"
        (click)="printInvoice()"
        *ngIf="tabSelected === 'Invoice'"
      >
        PRINT
      </button>
    </div>
  </div>
</div>
