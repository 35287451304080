<form (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label for="name">Email Text</label>
    <textarea
      id="emailText"
      rows="8"
      name="emailText"
      type="text"
      [(ngModel)]="model.emailText"
      placeholder="Type here"
      style="text-transform: none !important"
      class="form-control input-md"
    >
    </textarea>
  </div>

  <p-footer>
    <div class="footer">
      <button type="button" class="btn btn-secondary mr-2" (click)="closeModal()">Cancel</button>

      <button type="submit" class="btn btn-primary createBtn">Send Emails</button>
    </div>
  </p-footer>
</form>
