import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { HttpClient } from "../shared/services/http/http-client.service";

@Injectable()
export class homeService {
  private socket;
  // this HttpClient class was build by milo creating our own httpclient instead using the angular one

  constructor(private router: Router, private _http: HttpClient) {}

  getTripList() {
    return this._http.post("api/trip/list", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server home error"))
    );
  }

  listHome() {
    return this._http.get("api/dispatch/listHome/").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  dispatchRecord(tractorId, driverId) {
    return this._http.post("api/dispatch/dispatchRecord/", { tractorId, driverId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
