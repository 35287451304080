<div class="container">
  <div class="col-md-12">
    <div>
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item active">
          <a class="nav-link" href="#list" role="tab" data-toggle="tab">LOCATIONS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#yesterday" role="tab" data-toggle="tab">YESTERDAY</a>
        </li>
      </ul>
    </div>
    <!-- Tab panes -->
    <div class="tab-content white-bg-tabs">
      <div class="tab-pane active" id="list">
        <app-location-list #locationListComponent></app-location-list>
      </div>
      <div class="tab-pane" id="yesterday">
        <app-yesterday-locations #yesterdayLocationsComponent></app-yesterday-locations>
      </div>
    </div>
  </div>
</div>
