import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { RatesService } from "@app/rates/shared/rates.service";
import { RateViewModel } from "@app/shared/models/rates/RateViewModel.model";

@Component({
  selector: "app-edit-rates-table",
  templateUrl: "./edit-rates-table.component.html",
  styleUrls: ["./edit-rates-table.component.css"],
})
export class EditRatesTableComponent implements OnInit {
  @ViewChild("closebutton") closebutton;

  @Input() isEditing: boolean;
  @Input() rates: RateViewModel[];
  @Input() clientId: number;
  @Input() index: string;
  @Output() ratesChange = new EventEmitter();

  types: any[];
  ratesList: any[];

  ratesForm = new UntypedFormGroup({
    rateType: new UntypedFormControl("", Validators.required),
    selectedRate: new UntypedFormControl({ value: "", disabled: true }, Validators.required),
  });

  constructor(private ratesService: RatesService) {
    this.types = [
      { name: "CLIENT" },
      { name: "DRIVER" },
      { name: "CUSTOM" },
      // { name: "GUARANTEE" },
      // { name: "LONGEVITY" },
      { name: "FUEL" },
      { name: "INSURANCE" },
    ];
  }

  ngOnInit(): void {}

  get rateType() {
    return this.ratesForm.get("rateType");
  }
  get selectedRate() {
    return this.ratesForm.get("selectedRate");
  }

  onRateTypeSelected() {
    this.ratesService.getRatesByType(this.rateType.value.name).subscribe((rates: any[]) => {
      this.ratesList = rates;
      this.selectedRate.enable();
    });
  }

  addRate(rateToAdd: RateViewModel) {
    rateToAdd.rateType = this.rateType.value.name;
    if (!this.rates) this.rates = [];
    if (!this.rates.some((rate) => rate.id === rateToAdd.id)) this.rates.push(rateToAdd);
    this.ratesChange.emit(this.rates);
  }

  removeRate(rateToDelete: RateViewModel) {
    this.rates = this.rates.filter((rate) => rate.id != rateToDelete.id);
    this.ratesChange.emit(this.rates);
  }

  close() {
    this.closebutton.nativeElement.click();
  }

  onSubmit() {
    if (this.ratesForm.invalid) return;
    for (const rate of this.selectedRate.value) {
      this.addRate(rate);
    }
    this.close();
  }
}
