<p-table #dt2 [columns]="cols" [value]="advances" [autoLayout]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th></th>
      <th></th>
      <th
        *ngFor="let col of columns"
        [ngClass]="{ 'align-right': col.type === 'currency' || col.type === 'number' }"
      >
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-advance>
    <tr *ngIf="advance">
      <td>
        <a
          (click)="advance.softDelete = !advance.softDelete"
           style="cursor:pointer"
          *ngIf="batchSelected.status != 'CLOSED'"
        >
          <span
            title="Delete Fuel Expense"
            [style.color]="advance.softDelete ? 'red' : ''"
            class="glyphicon glyphicon-trash"
          ></span>
        </a>
      </td>
      <td style="text-overflow: clip">
        <img
          ng-if="advance.batchId"
          style="cursor: pointer; width: 21px"
          src="assets/img/details.svg"
          title="Go To Fuel Expense Grid"
          (click)="openFuelExpensesGrid(advance)"
        />
      </td>
      <ng-template ngFor let-col [ngForOf]="cols">
        <td *ngIf="col.type === 'code'">{{ advance.authorizationCode }}</td>
        <td *ngIf="col.type === 'date'">{{ advance[col.field] | date : "MM/dd/yyyy" }}</td>
        <td
          *ngIf="col.type === 'string' || col.type === 'currency' || col.type === 'number'"
          [ngClass]="{ 'align-right': col.type === 'currency' || col.type === 'number' }"
          class="editable-cell"
          [pEditableColumn]="advance"
          [pEditableColumnField]="advance[col.field]"
        >
          <p-cellEditor class="elements-table">
            <ng-template pTemplate="input">
              <input
                *ngIf="col.type === 'string'"
                style="width: 100px"
                type="text"
                [ngModel]="advance[col.field]"
                (ngModelChange)="onTchekChanged($event, advance, col.field)"
              />
              <input
                *ngIf="col.type === 'number' || col.type === 'currency'"
                style="width: 100px; text-align: right"
                type="number"
                min="0"
                [ngModel]="advance[col.field]"
                (ngModelChange)="onTchekChanged($event, advance, col.field)"
              />
            </ng-template>
            <ng-template pTemplate="output">
              <span
                *ngIf="col.type === 'string'"
                [style.color]="advance[col.field + 'Changed'] ? 'red' : ''"
              >
                {{ advance[col.field] }}
              </span>
              <span
                *ngIf="col.type === 'number'"
                [style.color]="advance[col.field + 'Changed'] ? 'red' : ''"
              >
                {{ advance[col.field] | number }}
              </span>
              <span
                *ngIf="col.type === 'currency'"
                [style.color]="advance[col.field + 'Changed'] ? 'red' : ''"
              >
                {{ advance[col.field] | currency }}
              </span>
            </ng-template>
          </p-cellEditor>
        </td>
      </ng-template>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer" let-columns>
    <tr>
      <td></td>
      <td></td>
      <td>TOTALS</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td class="align-right">{{ advanceTotals.gallons | number }}</td>
      <td class="align-right"></td>
      <td class="align-right">{{ advanceTotals.cost | currency }}</td>
      <td class="align-right">{{ advanceTotals.others | currency }}</td>
      <td class="align-right">{{ advanceTotals.dashCash | currency }}</td>
      <td class="align-right">{{ advanceTotals.discount | currency }}</td>
      <td class="align-right">{{ advanceTotals.fee | currency }}</td>
      <td class="align-right">{{ advanceTotals.oil | currency }}</td>
      <td class="align-right">{{ advanceTotals.express | currency }}</td>
      <td class="align-right">{{ advanceTotals.driverTotal | currency }}</td>
      <td class="align-right">{{ advanceTotals.lgtTotal | currency }}</td>
      <td class="align-right">{{ advanceTotals.net | currency }}</td>
    </tr>
  </ng-template>
</p-table>
