import { throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { HttpClient } from "../shared/services/http/http-client.service";

@Injectable()
export class TcheckService {
  constructor(private _http: HttpClient) {}

  uploadBatch(aspxPath: string, datPaths: string[], xlsPaths: string[]) {
    return this._http.post("/api/tcheck", { aspxPath, datPaths, xlsPaths }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  refreshRecords(records) {
    return this._http.post("/api/tcheck/refresh", { records }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getSelectTcheckBatch(year: number) {
    return this._http.post("/api/tcheck/getTcheckBatch", { year }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getYearsTcheckBatch() {
    return this._http.get("/api/tcheck/getYearTcheckBatch").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getTcheckByUUID(uuid: string) {
    return this._http.post("/api/tcheck/getBatchAllByUUID", { uuid }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getRecordsByBatchId(globalBatchId: number) {
    return this._http.post("/api/tcheck/getRecordsByBatchId", { globalBatchId }).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  saveRecords(registers: Array<any>) {
    return this._http.post("/api/tcheck/saveRegisters", { registers }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        if (error.error) error.message = error.error;
        return observableThrowError(error.message || "Server error");
      })
    );
  }

  saveScreenRecords(registers: Array<any>, update = false) {
    return this._http.post("/api/tcheck/saveScreenRegisters", { registers, update }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        error.message = error.error?.message;
        return observableThrowError(error.message || "Server error");
      })
    );
  }

  clearScreenRecords() {
    return this._http.get("/api/tcheck/clearScreenRegisters").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        error.message = error.error?.message;
        return observableThrowError(error.message || "Server error");
      })
    );
  }

  getScreenRecords() {
    return this._http.post("/api/tcheck/getScreenRegisters", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        error.message = error.error.message;
        return observableThrowError(error.message || "Server error");
      })
    );
  }

  getDriverExpenses(globalBatchId: number) {
    return this._http.get(`/api/tcheck/getDriverExpenses?batchId=${globalBatchId}`).pipe(
      map((res: any) => {
        return res.map((record) => ({
          ...record,
          date: new Date(record.date).toLocaleDateString(),
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getCompanyExpenses(globalBatchId: number) {
    return this._http.get(`/api/tcheck/getCompanyExpenses?batchId=${globalBatchId}`).pipe(
      map((res: any) => {
        return res.map((record) => ({
          ...record,
          date: new Date(record.date).toLocaleDateString(),
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateExpenses(tcheks: any[]) {
    return this._http.post("/api/tcheck/update", tcheks).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteRecord(tchekId: number) {
    return this._http.delete(`/api/tcheck/${tchekId}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteBatch(tcheckBatchId: number) {
    return this._http.post("/api/tcheck/deleteBatch", { tcheckBatchId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  listTchekBatches() {
    return this._http.get("/api/tcheck/listTchekBatches/").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
