import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "../../shared/services/http/http-client.service";

@Injectable()
export class BillAddressService {
  constructor(private router: Router, private _http: HttpClient) {}

  create(billAddress) {
    return this._http.post("api/billAddress/create", { billAddress }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  getListByClient(clientId) {
    return this._http
      .post("api/billAddress/listDropDown", {
        billAddress: {
          clientId: clientId,
        },
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.message || "Server error"))
      );
  }
  update(billAddress) {
    return this._http.patch("api/billAddress/update", { billAddress }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  delete(billAddress) {
    return this._http.delete("api/billAddress/delete/" + billAddress.uuid).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
