import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TchekTablesService {
  // prettier-ignore
  reportCols = [
    { field: "driverName", header: "Driver Name / Vendor", width: 250, type: "string" },
    { field: "tractorUnitNumber", header: "Tractor Unit Number", width: 150, type: "string" },
    { field: "trailer", header: "Trailer", width: 150, type: "string" },
    { field: "dateStr", header: "Date", width: 130, type: "date" },
    { field: "totalAmount", header: "total Amount", width: 150, type: "currency" },
    { field: "name", header: "Name", width: 250, type: "string" },
    { field: "city", header: "City", width: 200, type: "string" },
    { field: "state", header: "State", width: 80, type: "string" },
    { field: "card", header: "Fuel Card", width: 100, type: "string" },
    { field: "fuelAmount1", header: "fuel Amount 1", width: 150, type: "currency" },
    { field: "fuelAmount2", header: "fuel Amount 2", width: 150, type: "currency" },
    { field: "nonHighwayAmount", header: "non Highway Amount", width: 150, type: "currency" },
    { field: "oilAmount", header: "oilAmount", width: 140, type: "currency" },
    { field: "otherAmount1", header: "other Amount 1", width: 140, type: "currency" },
    { field: "otherProductAmount2", header: "other Product Amount 2", width: 150, type: "currency" },
    { field: "cash", header: "Cash", width: 140, type: "currency" },
    { field: "dashCash", header: "dashCash", width: 150, type: "currency" },
    { field: "discountAmount", header: "discount Amount", width: 150, type: "currency" },
    { field: "customerFee", header: "customer Fee", width: 150, type: "currency" },
    { field: "express", header: "Express", width: 150, type: "currency" },
    { field: "authorizationCode", header: "Auth Code", width: 130, type: "code" },
    { field: "reference", header: "Reference", width: 150, type: "string" },
  ];

  // prettier-ignore
  historicalCols = [
    { field: "link", header: "", width: 50, type: "driverLink" },
    { field: "type", header: "type", width: 150, type: "type" },
  ]

  // prettier-ignore
  driverExpensesCols = [
    { field: "link", header: "", width: 50, type: "driverLink", filter: "hide" },
    { field: "isNonHighway", header: "NH", width: 51, type: 'isNonHighway', filter: "hide" },
    { field: "driverName", header: "Driver Name", width: 250, type: 'string' },
    { field: "tractorUnitNumber", header: "Tractor / Unit Number", width: 150, type: 'string' },
    { field: "trailer", header: "Trailer #", width: 150, type: 'string' },
    { field: "dateStr", header: "Date", width: 130, type: 'date' },
    { field: "calculatedTotalAmount", header: "Total Amount", width: 140, type: 'currency' },
    { field: "name", header: "Name", width: 150, type: 'string' },
    { field: "city", header: "City", width: 150, type: 'string' },
    { field: "state", header: "State", width: 80, type: 'string' },
    { field: "card", header: "Fuel Card", width: 100, type: 'string' },
    { field: "fuelAmount1", header: "Fuel Amount 1", width: 150, type: 'currency' },
    { field: "fuelAmount2", header: "Fuel Amount 2", width: 150, type: 'currency' },
    { field: "nonHighwayAmount", header: "Non-Highway Amount", width: 150, type: 'currency' },
    { field: "oilAmount", header: "Oil Amount", width: 140, type: 'currency' },
    { field: "otherAmount1", header: "Other Amount 1", width: 140, type: 'currency' },
    { field: "otherProductAmount2", header: "Other Product Amount 2", width: 140, type: 'currency' },
    { field: "cash", header: "Cash", width: 140, type: 'currency' },
    { field: "dashCash", header: "Dash Cash", width: 140, type: 'currency' },
    { field: "payChekAmount", header: "Pay-Chek Amount", width: 140, type: 'currency' },
    { field: "discountAmount", header: "Discount Amount", width: 140, type: 'currency' },
    { field: "customerFee", header: "Client Fee", width: 130, type: 'currency' },
    { field: "payChekFee", header: "Pay-Chek Fee", width: 140, type: 'currency' },
    { field: "payChekFeePaidBy", header: "Pay-Chek Fee Paid By", width: 140, type: 'currency' },
    { field: "express", header: "Express", width: 140, type: 'currency' },
    { field: "authorizationCode", header: "Auth Code", width: 130, type: 'code' },
    { field: "", header: "", width: 70, type: 'delete', filter: "hide" },
  ];

  // prettier-ignore
  companyExpensesCols = [
    { field: "link", header: "", width: 50, type: "driverLink", filter: "hide" },
    { field: "ChargeTypeId", header: "Charge Type", width: 250, type: 'chargeType', filter: "hide" },
    { field: "batchDriverId", header: "Driver", width: 260, type: 'driver', filter: "hide" },
    { field: "driverName", header: "Name", width: 250, type: 'string' },
    { field: "dateStr", header: "Date", width: 130, type: 'date' },
    { field: "calculatedTotalAmount", header: "Total Amount", width: 140, type: 'currency' },
    { field: "name", header: "Name", width: 200, type: 'string' },
    { field: "city", header: "City", width: 200, type: 'string' },
    { field: "state", header: "State", width: 80, type: 'string' },
    { field: "reference", header: "Reference", width: 115, type: 'string' },
    { field: "fuelAmount1", header: "Fuel Amount 1", width: 115, type: 'currency' },
    { field: "fuelAmount2", header: "Fuel Amount 2", width: 115, type: 'currency' },
    { field: "nonHighwayAmount", header: "Non-Highway Amount", width: 115, type: 'currency' },
    { field: "oilAmount", header: "Oil Amount", width: 100, type: 'currency' },
    { field: "otherAmount1", header: "Other Amount 1", width: 115, type: 'currency' },
    { field: "otherProductAmount2", header: "Other Product Amount 2", width: 115, type: 'currency' },
    { field: "cash", header: "Cash", width: 140, type: 'currency' },
    { field: "dashCash", header: "Dash Cash", width: 140, type: 'currency' },
    { field: "payChekAmount", header: "Pay-Chek Amount", width: 140, type: 'currency' },
    { field: "discountAmount", header: "Discount Amount", width: 140, type: 'currency' },
    { field: "customerFee", header: "Client Fee", width: 140, type: 'currency' },
    { field: "payChekFee", header: "Pay-Chek Fee", width: 140, type: 'currency' },
    { field: "payChekFeePaidBy", header: "Pay-Chek Fee Paid By", width: 140, type: 'currency' },
    { field: "express", header: "Express", width: 140, type: 'currency' },
    { field: "authorizationCode", header: "Auth Code", width: 130, type: 'code' },
    { field: "", header: "", width: 70, type: 'delete' },
  ]

  constructor() {}

  calculateTotals(cols: any[], records: any[]) {
    const totals = [];
    for (const col of cols) {
      if (col.type === "currency") {
        const total = records.reduce((acc, curr) => {
          return acc + curr[col.field];
        }, 0);
        totals.push(total);
      } else {
        totals.push(null);
      }
    }
    return totals;
  }

  exportToCSV(records: any[], cols: any[], filename: string) {
    let plainRecords = [];

    cols = cols.filter((col) => col.field !== "");
    const tableKeys = cols.map((col) => col.field);
    const columnNames = cols.map((col) => col.header);

    for (const expense of records) {
      const driverRecord = [];
      for (const key of tableKeys) {
        const value = String(expense[key] || "");
        // if value contains a comma, wrap it in quotes
        driverRecord.push(value.includes(",") ? `"${value}"` : value);
      }
      plainRecords.push(driverRecord);
    }

    plainRecords.unshift(columnNames);

    // add a row containing the totals
    const totals = this.calculateTotals(cols, records);
    // replace nulls with empty strings
    for (let i = 0; i < totals.length; i++) {
      if (totals[i] === null) {
        totals[i] = "";
      }
    }
    totals[0] = "Totals";
    plainRecords.push(totals);

    let CsvString = "";
    for (const row of plainRecords) {
      for (const cell of row) {
        CsvString += cell + ",";
      }
      // remove the last comma
      CsvString = CsvString.substring(0, CsvString.length - 1);
      CsvString += "\r\n";
    }

    CsvString = "data:application/csv," + encodeURIComponent(CsvString);
    const x = document.createElement("A");
    x.setAttribute("href", CsvString);
    x.setAttribute("download", `${filename}.csv`);
    document.body.appendChild(x);
    x.click();
    document.body.removeChild(x);
  }
}
