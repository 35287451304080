import { Directive, Optional, Self, Host } from "@angular/core";
import { MultiSelect } from "primeng/multiselect";

@Directive({
  selector: "[tabbableMultiselect]",
})
export class TabbableMultiselectDirective {
  constructor(@Host() @Self() @Optional() public multiselect: MultiSelect) {
    window.addEventListener("keyup", (event: KeyboardEvent) => {
      if (event.key === "Tab" && multiselect.focused) {
        multiselect.show();
      }
    });
  }
}