import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface CanComponentDeactivate {
  confirm(): Observable<boolean> | boolean;
}

@Injectable()
export class ConfirmationGuard {
  skipConfirmation = false;
  constructor() {}

  canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.skipConfirmation) return component.confirm();
    this.skipConfirmation = false;
    return true;
  }
}
