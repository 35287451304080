import { Component, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { ConfigService } from "./shared/config.service";
import { ChargetypesService } from "@app/chargeType/shared/chargetypes.service";
import { ChargeType } from "@app/shared/models/ChargeType.model";
import { SelectItem } from "primeng/api";

@Component({
  selector: "app-config",
  templateUrl: "./config.component.html",
  styleUrls: ["./config.component.css"],
})
export class ConfigComponent implements OnInit {
  selectedVersion: any;
  versions: Array<any>;
  lastVersion: any;
  isDataChanged: boolean;
  newValues: any = {};
  attributesChanged = [];
  nonHighwayFuelChargeTypeId: string;
  originalHighwayChargeTypeId: string;
  escrowChargeTypeId: string;
  originalEscrowChargeTypeId: string;
  deficitChargeTypeId: string;
  originalDeficitChargeTypeId: string;
  tchekChargeTypeId: string;
  originalTchekChargeTypeId: string;
  chargeTypes: SelectItem;

  @Output("isGuardActive") isGuardActive = new EventEmitter();

  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private _ChargeTypeService: ChargetypesService,
    private _ConfigService: ConfigService
  ) {}

  ngOnInit() {
    this.attributesChanged = [];
    this.isDataChanged = false;
    this.isGuardActive.emit(this.isDataChanged);

    this.selectedVersion = this._ActivatedRoute.snapshot.data.configSettings.currentSettings ?? {};
    this.newValues = JSON.parse(JSON.stringify(this.selectedVersion));

    const resolvers = this._ActivatedRoute.snapshot.data;
    this.chargeTypes = resolvers.chargeTypes;
    const globalChargeTypes = resolvers.globalChargeTypes;
    const nonHighwayChargeType = globalChargeTypes.find((gct) => gct.type === "NON-HIGHWAYFUEL");
    const escrowChargeType = globalChargeTypes.find((gct) => gct.type === "ESCROW");
    const deficitChargeType = globalChargeTypes.find((gct) => gct.type === "DEFICIT");
    const tchekChargeType = globalChargeTypes.find((gct) => gct.type === "TCHEK");
    if (nonHighwayChargeType) {
      this.nonHighwayFuelChargeTypeId = nonHighwayChargeType.chargeType?.id;
      this.originalHighwayChargeTypeId = this.nonHighwayFuelChargeTypeId;
    }
    if (escrowChargeType) {
      this.escrowChargeTypeId = escrowChargeType.chargeType?.id;
      this.originalEscrowChargeTypeId = this.escrowChargeTypeId;
    }
    if (deficitChargeType) {
      this.deficitChargeTypeId = deficitChargeType.chargeType?.id;
      this.originalDeficitChargeTypeId = this.deficitChargeTypeId;
    }
    if (tchekChargeType) {
      this.tchekChargeTypeId = tchekChargeType.chargeType?.id;
      this.originalTchekChargeTypeId = this.tchekChargeTypeId;
    }
  }

  dataChanged() {
    this.isDataChanged = true;
    this.isGuardActive.emit(this.isDataChanged);
  }

  saveChargeTypes() {
    if (this.nonHighwayFuelChargeTypeId !== this.originalHighwayChargeTypeId) {
      this._ChargeTypeService
        .updateGlobalChargeType("NON-HIGHWAYFUEL", this.nonHighwayFuelChargeTypeId)
        .subscribe(
          () => {
            this.toastr.success("Non-Highway Fuel Charge Type Succesfully Updated.", "Success");
          },
          (error) => {
            console.dir(error);
            this.toastr.error(error, "Error");
          }
        );
    }
    if (this.escrowChargeTypeId !== this.originalEscrowChargeTypeId) {
      this._ChargeTypeService.updateGlobalChargeType("ESCROW", this.escrowChargeTypeId).subscribe(
        () => {
          this.toastr.success("Escrow Charge Type Succesfully Updated.", "Success");
        },
        (error) => {
          console.dir(error);
          this.toastr.error(error, "Error");
        }
      );
    }
    if (this.deficitChargeTypeId !== this.originalDeficitChargeTypeId) {
      this._ChargeTypeService.updateGlobalChargeType("DEFICIT", this.deficitChargeTypeId).subscribe(
        () => {
          this.toastr.success("Deficit Charge Type Succesfully Updated.", "Success");
        },
        (error) => {
          console.dir(error);
          this.toastr.error(error, "Error");
        }
      );
    }
    if (this.tchekChargeTypeId !== this.originalTchekChargeTypeId) {
      this._ChargeTypeService.updateGlobalChargeType("TCHEK", this.tchekChargeTypeId).subscribe(
        () => {
          this.toastr.success("tchek Charge Type Succesfully Updated.", "Success");
        },
        (error) => {
          console.dir(error);
          this.toastr.error(error, "Error");
        }
      );
    }
  }

  save() {
    this._ConfigService.update(this.newValues).subscribe(
      (result) => {
        this.isDataChanged = false;
        this.isGuardActive.emit(this.isDataChanged);
        this.toastr.success("Config updated", "Success");
        this._ActivatedRoute.snapshot.data.configSettings.currentSettings = JSON.parse(
          JSON.stringify(this.newValues)
        );
        this.saveChargeTypes();
      },
      (error) => {
        this.toastr.error(error, "Error");
      }
    );
  }

  cancel() {
    this.newValues = JSON.parse(JSON.stringify(this.selectedVersion));
    this.isDataChanged = false;
    this.isGuardActive.emit(this.isDataChanged);
  }
}
