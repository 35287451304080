<!--<ngx-loading-bar></ngx-loading-bar>-->
<!--The content below is only a placeholder and can be replaced.-->
<app-sidebar-component> </app-sidebar-component>
<!-- START PAGE-CONTAINER -->
<div class="page-container ">
  <app-header-component></app-header-component>
  <!-- START PAGE CONTENT WRAPPER -->
  <router-outlet>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"></ngx-spinner>
  </router-outlet>
</div>
