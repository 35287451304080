<div class="container">
  <div class="row">
    <div class="col-sm-9">
      <div class="tripHeaderTitle">
        <p class="TripHeader">Trailer/ Container/ Chassis</p>
        <hr />
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="company">Trailer Number</label>
        <div class="col-md-3">
          <input
            id="manufactureSerialNo"
            name="manufactureSerialNo"
            type="text"
            placeholder="Insert trailer No"
            class="form-control input-md"
            [(ngModel)]="trailer.trailerNo"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="homeLocation">Terminal Location</label>
          <div class="col-md-3">
            <select
              class="selectState"
              id="terminalLocation"
              name="terminalLocation"
              [(ngModel)]="trailer.terminalLocation"
            >
              <option *ngFor="let terminal of terminals" [ngValue]="terminal">
                {{ terminal }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="trailerType">Trailer Type</label>
        <div class="col-md-3 inputSpace">
          <select
            class="selectEquipment"
            type="text"
            id="trailerType"
            name="trailerType"
            [(ngModel)]="trailer.trailerType"
          >
            <option value="CHASIS">Chasis</option>
            <option value="CONTAINER">Container</option>
            <option value="TRAILER">Trailer</option>
          </select>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="active">Active</label>
          <div class="col-md-3 inputSpace">
            <p-checkbox [(ngModel)]="trailer.active" binary="true"></p-checkbox>
          </div>
        </div>
      </div>
      <hr />
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="company">Trailer Year</label>
        <div class="col-md-3 inputSpace">
          <input
            id="year"
            name="year"
            type="text"
            placeholder="Insert trailer year"
            class="form-control input-md"
            [(ngModel)]="trailer.year"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="company">Date Purchased </label>
          <div class="col-md-3 inputSpace">
            <input id="puchaseDate"
                   name="puchaseDate"
                   type="text"
                   [inputMask]="dateInputMask"
                   autocomplete="off"
                   placeholder="mm/dd/yyyy"
                   class="form-control input-md"
                   [(ngModel)]="trailer.purchasedDate" />
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="make">Trailer Make</label>
        <div class="col-md-3 inputSpace">
          <input
            id="make"
            name="make"
            type="text"
            placeholder="Insert trailer manufacturer"
            class="form-control input-md"
            [(ngModel)]="trailer.make"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="company">Trailer Price</label>
          <div class="col-md-3 inputSpace">
            <input
              id="price"
              name="price"
              type="text"
              placeholder="Insert trailer price"
              class="form-control input-md"
              [(ngModel)]="trailer.price"
            />
          </div>
        </div>
      </div>

      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="company">Model</label>
        <div class="col-md-3 inputSpace">
          <input
            id="model"
            name="model"
            type="text"
            placeholder="Insert trailer model"
            class="form-control input-md"
            [(ngModel)]="trailer.model"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Length</label>
          <div class="col-md-3 inputSpace">
            <input
              id="length"
              name="length"
              type="text"
              placeholder="Insert trailer length"
              class="form-control input-md"
              [(ngModel)]="trailer.length"
            />
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Height</label>
          <div class="col-md-3 inputSpace">
            <input
              id="height"
              name="height"
              type="text"
              placeholder="Insert trailer height"
              class="form-control input-md"
              [(ngModel)]="trailer.height"
            />
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="radios">State of registration</label>
        <div class="col-md-3">
          <select
            class="selectState"
            id="stateOfRegistration"
            name="stateOfRegistration"
            [(ngModel)]="trailer.stateOfRegistration"
          >
            <option *ngFor="let state of states" [ngValue]="state.abbreviation">
              {{ state.fullName }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Weight</label>
          <div class="col-md-3 inputSpace">
            <input
              id="weight"
              name="weight"
              type="text"
              placeholder="Insert trailer weight"
              class="form-control input-md"
              [(ngModel)]="trailer.weight"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-3 control-label" for="radios">License Number</label>
        <div class="col-md-3">
          <input
            id="licenseNumber"
            name="licenseNumber"
            type="text"
            placeholder="Insert License #"
            class="form-control input-md"
            [(ngModel)]="trailer.licenseNumber"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="axles">Axles</label>
          <div class="col-md-3 inputSpace">
            <input
              id="axles"
              name="axles"
              type="text"
              placeholder="Insert Trailer Axles"
              class="form-control input-md"
              [(ngModel)]="trailer.axles"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-3 control-label" for="radios">License Expiration</label>
        <div class="col-md-3">
          <input id="licenseExpirationDate"
                 name="licenseExpirationDate"
                 aria-label="licenseExpirationDate"
                 type="text"
                 [inputMask]="dateInputMask"
                 autocomplete="off"
                 placeholder="mm/dd/yyyy"
                 class="form-control input-md"
                 [(ngModel)]="trailer.licenseExpirationDate" />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Tire Size</label>
          <div class="col-md-3 inputSpace">
            <input
              id="tireSize"
              name="tireSize"
              type="text"
              placeholder="Insert tire size"
              class="form-control input-md"
              [(ngModel)]="trailer.tireSize"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-3 control-label" for="company">Equipment Code</label>
        <div class="col-md-3 inputSpace">
          <select
            class="selectEquipment"
            type="text"
            id="equipmentCode"
            name="equipmentCode"
            [(ngModel)]="equipmentCodeSelected"
            (change)="equipmentCodeSelectedChanged()"
          >
            <option [ngValue]="null" disabled>--Select one--</option>
            <option *ngFor="let equ of equipmentCodes" [ngValue]="equ">
              {{ equ.equipmentCode }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Engine Hours</label>
          <div class="col-md-3 inputSpace">
            <input
              id="engineHours"
              name="engineHours"
              type="text"
              placeholder="Insert engine hours"
              class="form-control input-md"
              [(ngModel)]="trailer.engineHours"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group">
          <label class="col-md-3 control-label" for="vinNo">Vin Number</label>
          <div class="col-md-3 inputSpace">
            <input
              id="vinNo"
              name="vinNo"
              type="text"
              placeholder="Insert vin number"
              class="form-control input-md"
              [(ngModel)]="trailer.vinNo"
            />
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <div class="form-group" class="insuranceInput">
          <label class="col-md-3 control-label" for="radios">Insurance Policy Number</label>
          <div class="col-md-3 inputSpace">
            <input
              id="insurancePolicyNumber"
              name="insurancePolicyNumber"
              type="text"
              placeholder="Insert insurance policy number"
              class="form-control input-md"
              [(ngModel)]="trailer.insurancePolicyNumber"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Insurance Name</label>
          <div class="col-md-3 inputSpace">
            <input
              id="insuranceName"
              name="insuranceName"
              type="text"
              placeholder="Insert insurance name"
              class="form-control input-md"
              [(ngModel)]="trailer.insuranceName"
            />
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="currentLocationState"
          >Current Location State</label
        >
        <div class="col-md-3">
          <select
            class="selectState"
            id="currentLocationState"
            name="currentLocationState"
            [(ngModel)]="trailer.currentLocationState"
          >
            <option *ngFor="let state of states" [ngValue]="state.abbreviation">
              {{ state.fullName }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="currentLocationCity"
            >Current Location City</label
          >
          <div class="col-md-3">
            <input
              id="currentLocationCity"
              name="currentLocationCity"
              type="text"
              placeholder="Insert current location city"
              class="form-control input-md"
              [(ngModel)]="trailer.currentLocationCity"
            />
          </div>
        </div>
      </div>
      <!-- Text input
  <div class="form-group">
    <label class="col-md-3 control-label" for="currentLocationAddress">current Location Address</label>
    <div class="col-md-3 inputSpace">
      <input id="currentLocationAddress" name="currentLocationAddress" type="text" placeholder="Insert current location address"
        class="form-control input-md" [(ngModel)]="trailer.currentLocationAddress">
    </div>
    <div class="form-group">
      <label class="col-md-3 control-label" for="currentLocationZip">Current Location Zip</label>
      <div class="col-md-3 inputSpace">
        <input id="currentLocationZip" name="currentLocationZip" type="text" placeholder="Insert current location zip" class="form-control input-md"
          [(ngModel)]="trailer.currentLocationZip">
      </div>
    </div>
  </div>
  <Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="serial">Trailer Serial</label>
        <div class="col-md-3 inputSpace">
          <input
            id="serial"
            name="serial"
            type="text"
            placeholder="Insert serial"
            class="form-control input-md"
            [(ngModel)]="trailer.serial"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Trailer Owner</label>
          <div class="col-md-3 inputSpace">
            <input
              id="owner"
              name="owner"
              type="text"
              placeholder="Insert "
              class="form-control input-md"
              [(ngModel)]="trailer.owner"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div *ngIf="trailer.equipmentCode == 'STEEL'" class="selectType">
          <label class="col-md-3 control-label" for="equipmentCodeSteelType">Steel type</label>
          <div class="col-md-3 inputSpace">
            <select
              class="selectEquipment"
              type="text"
              id="equipmentCodeSteelType"
              name="equipmentCodeSteelType"
              [(ngModel)]="trailer.equipmentCodeSteelType"
            >
              <option value="LOX" selected>LOX</option>
              <option value="LIN">LIN</option>
              <option value="LAR">LAR</option>
            </select>
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label">UN numbers:</label>
        <ul class="col-md-3">
          <li *ngFor="let unNumber of equipmentCodeSelected.un; let i = index">
            {{ equipmentCodeSelected.name[i] }}: {{ unNumber }}
          </li>
        </ul>
      </div>
      <br />
      <hr />
      <div class="form-group">
        <label class="col-md-3 control-label">Mobile device type</label>
        <div class="col-md-3 inputSpace">
          <select
            class="selectEquipment"
            type="text"
            id="type"
            name="type"
            [(ngModel)]="trailer.mobileDeviceType"
          >
            <option value="DSN">DSN</option>
            <option value="PHONE">Phone</option>
            <option value="TABLET">Tablet</option>
          </select>
        </div>
      </div>
      <br />
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="radios">Mobile Device Number</label>
        <div class="col-md-3 inputSpace">
          <input
            id="mobileDeviceNumber"
            name="mobileDeviceNumber"
            type="text"
            placeholder="mobile device number"
            class="form-control input-md"
            [(ngModel)]="trailer.mobileDeviceNumber"
          />
        </div>
      </div>
    </div>
    <div class="col-sm-3" align="center">
      <div>
        <img
          class="img-responsive"
          style="margin-top: 89px"
          [src]="previewTrailerPicture || 'assets/img/trailer.jpg'"
        />
        <div class="form-group">
          <input
            type="file"
            #trailerPicture
            (change)="getTemporalImage($event)"
            placeholder="Upload file..."
          />
          <br />
          <label class="col-md-3 control-label" for="company">upload Docs</label>
          <div class="col-md-3">
            <createDocument #trailerDocuments></createDocument>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
  <div class="row" class="bottom-btns">
    <div class="col-sm-6" align="right">
      <button
        type="button"
        class="btn btn-warning"
        (click)="createNewTrailer(trailer)"
        [disabled]="isProcessing"
      >
        Save Trailer Profile
      </button>
    </div>
    <br />
  </div>
</div>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '50vw' }"
></p-confirmDialog>
