import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { LessorService } from "./lessor.service";

@Injectable()
export class LessorsResolver {
  constructor(private lessorService: LessorService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.lessorService.getLessorList();
  }
}
