<div class="container">
  <div class="col-md-12">
    <div class="page-title">
      <span class="clickable" (click)="previousDriver()" style="margin-right: 3rem">
        <img
          src="assets\img\arrow-left.svg"
          alt="Previous"
          srcset="assets\img\arrow-left.svg 200w, assets\img\arrow-left.svg 400w"
          sizes="(min-width: 600px) 300px, 100vw"
        />
        Previous
      </span>
      <span class="driver-info">Driver info: {{ driverInfo.driverNo }}</span>
      <span class="clickable" (click)="nextDriver()" style="margin-left: 3rem">
        Next
        <img
          src="assets\img\arrow-right.svg"
          alt="Next"
          srcset="assets\img\arrow-right.svg 200w, assets\img\arrow-right.svg 400w"
          sizes="(min-width: 600px) 300px, 100vw"
        />
      </span>
    </div>
    <div class="row">
      <div class="col-sm-9">
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label">Driver ID</label>
            <div class="col-md-6 inputSpace">
              <input
                id="driverNo"
                required
                name="driverNo"
                [tabIndex]="1"
                type="text"
                placeholder="Insert Company Driver"
                class="form-control"
                [(ngModel)]="driverInfo.driverNo"
                [disabled]="is_edit"
                maxlength="16"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">PeopleNet Id</label>
            <div class="col-md-6 inputSpace">
              <input
                id="peopleNetId"
                name="peopleNetId"
                tabindex="3"
                type="text"
                placeholder="Insert People Net Id"
                [disabled]="is_edit"
                [(ngModel)]="driverInfo.peopleNetId"
                class="form-control input-md"
                maxlength="7"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Termination Date</label>
            <div class="col-md-6 inputSpace">
              <input
                id="terminationDate"
                name="terminationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="5"
                [(ngModel)]="driverInfo.terminationDate"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">Type</label>
            <div class="col-md-6 inputSpace">
              <select
                class="form-control"
                [disabled]="is_edit"
                tabindex="7"
                type="text"
                id="driverType"
                name="driverType"
                [(ngModel)]="driverInfo.type"
                (change)="driverTypeChanged()"
              >
                <option value="COMPANY DRIVER">Company Driver</option>
                <option value="OWNER OPERATOR">Owner Operator</option>
                <option value="LEASE OPERATOR">Lessor</option>
              </select>
            </div>
            <br />
            <div *ngIf="driverInfo.type == 'LEASE OPERATOR'">
              <label class="col-md-6 control-label" for="company">Select lessor</label>
              <div class="col-md-6 inputSpace">
                <select
                  class="form-control"
                  [disabled]="is_edit"
                  tabindex="9"
                  type="text"
                  id="driverType"
                  name="driverType"
                  [(ngModel)]="driverInfo.lessorId"
                >
                  <option value="null">--Select lessor --</option>
                  <option
                    *ngFor="let lessorOwner of lessorOwners; let i = index"
                    [ngValue]="lessorOwner.id"
                  >
                    {{ lessorOwner.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <p-confirmDialog
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            [style]="{ width: '50vw' }"
          ></p-confirmDialog>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="isActive">Active</label>
            <div class="col-md-6 inputSpace">
              <p-checkbox
                id="isActive"
                name="isActive"
                [tabIndex]="2"
                [(ngModel)]="driverInfo.isActive"
                binary="true"
                [disabled]="is_edit"
              ></p-checkbox>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Termination Type</label>
            <div class="col-md-6 inputSpace">
              <input
                id="terminationType"
                tabindex="4"
                name="terminationType"
                [disabled]="is_edit"
                type="text"
                placeholder="Insert termination Type"
                [(ngModel)]="driverInfo.terminationType"
                class="form-control input-md"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="rehire">Eligible Rehire</label>
            <div class="col-md-6 inputSpace">
              <p-checkbox
                id="rehire"
                name="rehire"
                [tabIndex]="6"
                checked
                [(ngModel)]="driverInfo.rehire"
                binary="true"
                [disabled]="is_edit"
              ></p-checkbox>
            </div>
            <div class="form-group">
              <label class="col-md-6 control-label" for="tchekCardNo">Fuel Card Number</label>
              <div class="col-md-6 inputSpace">
                <input
                  id="name"
                  name="name"
                  type="text"
                  [tabIndex]="8"
                  placeholder="Insert Driver Fuel Card"
                  [disabled]="is_edit"
                  [(ngModel)]="driverInfo.tchekCardNo"
                  class="form-control input-md"
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="Name">First Name</label>
            <div class="col-md-6 inputSpace">
              <input
                id="name"
                name="name"
                type="text"
                tabindex="10"
                [disabled]="is_edit"
                placeholder="Insert new driver name"
                class="form-control input-md"
                [(ngModel)]="driverInfo.name"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Middle Name </label>
            <div class="col-md-6 inputSpace">
              <input
                id="StartDate"
                name="StartDate"
                tabindex="12"
                [disabled]="is_edit"
                type="text"
                placeholder="Insert Middle Name"
                class="form-control"
                [(ngModel)]="driverInfo.middleName"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">Cellphone</label>
            <div class="col-md-6 inputSpace">
              <input
                id="cellphone"
                name="cellphone"
                tabindex="14"
                [disabled]="is_edit"
                type="text"
                [inputMask]="usPhoneMask"
                autocomplete="off"
                placeholder="(XXX) XXX-XXXX"
                class="form-control input-md"
                [(ngModel)]="driverInfo.cellphone"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="emergencyContName"
              >Emergency Cont Name</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="emergencyContName"
                name="emergencyContName"
                tabindex="16"
                [disabled]="is_edit"
                type="text"
                placeholder="Insert emergency contact name"
                class="form-control input-md"
                [(ngModel)]="driverInfo.emergencyContName"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="homeAddress">Home Address</label>
            <div class="col-md-6 inputSpace">
              <input
                id="homeAddress"
                name="homeAddress"
                tabindex="18"
                [disabled]="is_edit"
                type="text"
                placeholder="Insert home address"
                class="form-control input-md"
                [(ngModel)]="driverInfo.homeAddress"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="state">State</label>
            <div class="col-md-6 inputSpace">
              <select
                class="selectState"
                type="text"
                tabindex="20"
                [disabled]="is_edit"
                id="state"
                name="state"
                [(ngModel)]="driverInfo.state"
              >
                <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                  {{ state.fullName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="zipCode">Zip Code</label>
            <div class="col-md-6 inputSpace">
              <input
                id="zipCode"
                name="zipCode"
                [disabled]="is_edit"
                tabindex="22"
                type="text"
                placeholder="Insert zip code"
                class="form-control input-md"
                [(ngModel)]="driverInfo.zipCode"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">Last Name</label>
            <div class="col-md-6 inputSpace">
              <input
                id="LastName"
                name="LastName"
                tabindex="11"
                [disabled]="is_edit"
                type="text"
                placeholder="Insert Last Name"
                [(ngModel)]="driverInfo.lastName"
                class="form-control input-md"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="email">Email</label>
            <div class="col-md-6 inputSpace">
              <input
                id="email"
                name="email"
                tabindex="13"
                [disabled]="is_edit"
                type="email"
                placeholder="email"
                class="form-control input-md"
                [(ngModel)]="driverInfo.email"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="settlementEmail">Settlement Email</label>
            <div class="col-md-6 inputSpace">
              <input
                id="settlementEmail"
                name="settlementEmail"
                tabindex="15"
                [disabled]="is_edit"
                type="email"
                placeholder="Settlement Email"
                class="form-control input-md"
                [(ngModel)]="driverInfo.settlementEmail"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">Phone</label>
            <div class="col-md-6 inputSpace">
              <input
                id="phone"
                name="phone"
                [disabled]="is_edit"
                tabindex="17"
                type="text"
                [inputMask]="usPhoneMask"
                autocomplete="off"
                placeholder="(XXX) XXX-XXXX"
                class="form-control input-md"
                [(ngModel)]="driverInfo.phone"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="emergencyContPhone"
              >Emergency Cont Phone</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="emergencyContPhone"
                [disabled]="is_edit"
                tabindex="19"
                name="emergencyContPhone"
                type="text"
                placeholder="(XXX) XXX-XXXX"
                class="form-control input-md"
                [(ngModel)]="driverInfo.emergencyContPhone"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="city">City</label>
            <div class="col-md-6 inputSpace">
              <input
                id="city"
                name="city"
                [disabled]="is_edit"
                tabindex="21"
                type="text"
                placeholder="Insert city"
                class="form-control input-md"
                [(ngModel)]="driverInfo.city"
              />
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">Birth Date</label>
            <div class="col-md-6 inputSpace">
              <input
                id="birthDate"
                name="birthDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="23"
                [(ngModel)]="driverInfo.birthDate"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="taxId">Tax ID</label>
            <div class="col-md-6 inputSpace">
              <input
                id="taxId"
                name="taxId"
                type="text"
                [disabled]="is_edit"
                tabindex="25"
                placeholder="Insert tax Id"
                class="form-control input-md"
                [(ngModel)]="driverInfo.taxId"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">License Number </label>
            <div class="col-md-6 inputSpace">
              <input
                id="licenseNumber"
                name="licenseNumber"
                [disabled]="is_edit"
                tabindex="27"
                type="text"
                placeholder="Insert license number"
                class="form-control input-md"
                [(ngModel)]="driverInfo.licenseNumber"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">License State</label>
            <div class="col-md-6 inputSpace">
              <select
                class="selectState"
                id="licenseState"
                [disabled]="is_edit"
                tabindex="29"
                name="licenseState"
                [(ngModel)]="driverInfo.licenseState"
              >
                <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                  {{ state.fullName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="radios">License Expiration</label>
            <div class="col-md-6 inputSpace">
              <input
                id="licenseExpirationDate"
                name="licenseExpirationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="31"
                [(ngModel)]="driverInfo.licenseExpirationDate"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="socialS">SSN</label>
            <div class="col-md-6 inputSpace">
              <p-inputMask
                id="socialSecurityNumber"
                [disabled]="is_edit"
                name="socialSecurityNumber"
                tabindex="24"
                type="text"
                mask="999-99-9999"
                placeholder="XXX-XX-XXXX"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [(ngModel)]="driverInfo.socialSecurityNumber"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="physicalExamDate">Physical Exam Date </label>
            <div class="col-md-6 inputSpace">
              <input
                id="physicalExamDate"
                name="physicalExamDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="26"
                [(ngModel)]="driverInfo.physicalExamDate"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="physicalExamExpirationDate"
              >Physical Exam Expiration</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="physicalExamExpirationDate"
                name="physicalExamExpirationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="28"
                [(ngModel)]="driverInfo.physicalExamExpirationDate"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="hmeExpirationDate">HME Expiration</label>
            <div class="col-md-6 inputSpace">
              <input
                id="hmeExpirationDate"
                name="hmeExpirationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="30"
                [(ngModel)]="driverInfo.hmeExpirationDate"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="passportExpirationDate"
              >Passport Expiration</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="passportExpirationDate"
                name="passportExpirationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="32"
                [(ngModel)]="driverInfo.passportExpirationDate"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="hiredDate">Hire Dates</label>
            <div class="col-md-6">
              <div *ngFor="let item of driverHireDateCounter(); let i = index">
                <div style="display: flex" [style.margin-top]="i > 0 ? '5px' : '0'">
                  <div style="flex: 1; min-width: 43%; width: 43%">
                    <input
                      *ngIf="i == 0"
                      id="hiredDate0"
                      name="hiredDate0"
                      type="text"
                      styleClass="driver-mask-class"
                      inputStyleClass="driver-mask-class"
                      [inputMask]="dateInputMask"
                      autocomplete="off"
                      placeholder="mm/dd/yyyy"
                      [disabled]="is_edit"
                      class="form-control input-md"
                      [tabIndex]="33"
                      [(ngModel)]="driverInfo.hiredDate"
                    />
                    <input
                      *ngIf="i > 0"
                      id="hiredDate"
                      name="hiredDate"
                      type="text"
                      styleClass="driver-mask-class"
                      inputStyleClass="driver-mask-class"
                      [inputMask]="dateInputMask"
                      autocomplete="off"
                      placeholder="mm/dd/yyyy"
                      [disabled]="is_edit"
                      class="form-control input-md"
                      [tabIndex]="34"
                      [(ngModel)]="driverInfo.driverHireDates[i - 1].hiredDate"
                    />
                  </div>
                  <div style="display: flex">
                    <label style="flex: 1">-</label>
                    <div style="display: flex">
                      <div style="flex: 1">
                        <input
                          *ngIf="i == 0"
                          id="hiredDateTo0"
                          name="hiredDateTo0"
                          type="text"
                          styleClass="driver-mask-class"
                          inputStyleClass="driver-mask-class"
                          [inputMask]="dateInputMask"
                          autocomplete="off"
                          placeholder="mm/dd/yyyy"
                          [disabled]="is_edit"
                          class="form-control input-md"
                          [tabIndex]="35"
                          [(ngModel)]="driverInfo.hiredDateTo"
                        />
                        <input
                          *ngIf="i > 0"
                          id="hiredDateTo"
                          name="hiredDateTo"
                          type="text"
                          styleClass="driver-mask-class"
                          inputStyleClass="driver-mask-class"
                          [inputMask]="dateInputMask"
                          autocomplete="off"
                          placeholder="mm/dd/yyyy"
                          [disabled]="is_edit"
                          class="form-control input-md"
                          [tabIndex]="36"
                          [(ngModel)]="driverInfo.driverHireDates[i - 1].hiredDateTo"
                        />
                      </div>
                      <button
                        *ngIf="i == 0"
                        [disabled]="is_edit"
                        class="button-form-control"
                        (click)="addDriverHireDate()"
                      >
                        +
                      </button>
                      <button
                        *ngIf="i > 0"
                        [disabled]="is_edit"
                        class="button-form-control"
                        (click)="removeDriverHireDate(i)"
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="annualReviewDate"
              >Last Annual Review Date</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="annualReviewDate"
                name="annualReviewDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="37"
                [(ngModel)]="driverInfo.annualReviewDate"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="trainingDateCGMP">CGMP Training Date</label>
            <div class="col-md-6 inputSpace">
              <input
                id="trainingDateCGMP"
                name="trainingDateCGMP"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="39"
                (ngModelChange)="
                  calculateTrainingDateCGMPExpirationDate(driverInfo.trainingDateCGMP)
                "
                [(ngModel)]="driverInfo.trainingDateCGMP"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="expirationDateCGMP"
              >CGMP Expiration Date</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="expirationDateCGMP"
                name="expirationDateCGMP"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="41"
                [(ngModel)]="driverInfo.expirationDateCGMP"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="col-md-6 control-label" for="hazmatTrainingDate"
              >Hazmat Training Date</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="hazmatTrainingDate"
                name="hazmatTrainingDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="38"
                (ngModelChange)="
                  calculatehazmatTrainingExpirationDate(driverInfo.hazmatTrainingDate)
                "
                [(ngModel)]="driverInfo.hazmatTrainingDate"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="hazmatTrainingExpirationDate"
              >Hazmat Training Expire Date</label
            >
            <div class="col-md-6 inputSpace">
              <input
                id="hazmatTrainingExpirationDate"
                name="hazmatTrainingExpirationDate"
                type="text"
                styleClass="driver-mask-class"
                inputStyleClass="driver-mask-class"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                [disabled]="is_edit"
                class="form-control input-md"
                [tabIndex]="40"
                [(ngModel)]="driverInfo.hazmatTrainingExpirationDate"
              />
            </div>
          </div>
          <div class="form-group" *ngIf="!driverInfo.team">
            <label for="client-list" class="col-md-6">Select new team</label>
            <div class="col-md-6 inputSpace">
              <select
                id="client-list"
                id="team"
                name="team"
                [(ngModel)]="driverSelected"
                tabindex="42"
                (change)="teamSelected(driverInfo)"
                class="selectState"
                type="text"
                [disabled]="is_edit"
              >
                <option [ngValue]="null" selected>--No Team--</option>
                <option *ngFor="let item of drivers" [ngValue]="item">
                  {{ item.lastName }}, {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group col-md-12" *ngIf="driverInfo.team?.drivers">
            <table style="width: 100%">
              <tr>
                <th><label>Team Members</label></th>
                <th><label>Pay To</label></th>
                <th><label>Percent</label></th>
              </tr>
              <tr *ngFor="let teamDriver of driverInfo.team.drivers">
                <td>{{ teamDriver.name }} {{ teamDriver.lastName }}</td>
                <td>
                  <p-checkbox
                    *ngIf="teamDriver.id === driverInfo.id"
                    [(ngModel)]="driverInfo.isPayTo"
                    binary="true"
                    (onChange)="isPayToChanged(driverInfo)"
                    [disabled]="is_edit || teamDriver.isPayTo"
                  ></p-checkbox>
                  <p-checkbox
                    *ngIf="teamDriver.id !== driverInfo.id"
                    [(ngModel)]="teamDriver.isPayTo"
                    binary="true"
                    (onChange)="isPayToChanged(teamDriver)"
                    [disabled]="is_edit || teamDriver.isPayTo"
                  ></p-checkbox>
                </td>
                <td style="display: flex; align-items: center; width: 100px">
                  <input
                    [(ngModel)]="teamDriver.earningPercentage"
                    (change)="earningPercentageChanged(teamDriver)"
                    type="number"
                    min="0"
                    class="form-control"
                    [disabled]="is_edit"
                  />
                  <span style="margin-left: 0.25rem">%</span>
                </td>
              </tr>
            </table>
            <div *ngIf="!is_edit">
              <button (click)="removeFromTeam()">Leave The Team</button>
            </div>
          </div>
          <div class="form-group" *ngIf="!driverInfo.team || !driverInfo.team.drivers">
            <label for="payTo" class="col-md-6">Is Pay To</label>
            <div class="col-md-6 inputSpace">
              <p-checkbox
                [(ngModel)]="driverInfo.isPayTo"
                binary="true"
                [disabled]="true"
              ></p-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3" align="center" style="display: block">
        <div>
          <img
            class="img-responsive"
            [src]="profilePicture"
            [appGetFile]="profilePicture"
            [appPreviewFile]="true"
          />
          <div class="form-group">
            <input
              [disabled]="is_edit"
              class="form-control-file inpFile"
              type="file"
              (change)="getTemporalImage($event)"
              placeholder="Upload file..."
            />
            <br />
            <label class="col-md-3 control-label" for="company">upload Docs</label>
            <div class="col-md-3">
              <createDocument #driverDocuments [disableInputs]="is_edit"></createDocument>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" class="bottom-btns">
  <div class="col-sm-6" align="right">
    <button
      *ngIf="hasPermission('DriversChange')"
      type="button"
      class="btn btn-warning createBtn"
      [disabled]="isProcessing"
      (click)="editOn(driverInfo)"
    >
      {{ btnProfile }}
    </button>
  </div>
  <div class="col-sm-6" align="left">
    <button *ngIf="!is_edit" type="button" class="btn btn-secondary" (click)="cancelEdit()">
      Cancel
    </button>
  </div>
  <br />
</div>
<!-- end modal to add new client-->
