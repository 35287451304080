import { Role } from "@models/role.model";
import { Permission } from "@models/permission.model";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { RoleService } from "../shared/role.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-editrole",
  templateUrl: "./editrole.component.html",
  styleUrls: ["./editrole.component.css"],
})
export class EditroleComponent implements OnInit, OnChanges {
  @Input() selectedRole: Role;

  permissions: Array<Permission>;
  selectedCategories: string[] = [];

  constructor(private _roleService: RoleService, private toastr: ToastrService) {}

  ngOnInit() {
    this._roleService.getAllRoleRules().subscribe((permissions: Array<Permission>) => {
      this.permissions = permissions;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRole && !changes.selectedRole.firstChange) {
      // Initialize the permissions to the newly selected role
      const rolePermissions = changes.selectedRole.currentValue.Permissions;
      this.selectedCategories = rolePermissions.map((perm) => perm.name);
    }
  }

  updateRole() {
    const roleName = this.selectedRole.rolName;
    const permissions = this.selectedCategories;
    const rolePermissions = { roleName, permissions };
    this._roleService.updateRoleRules(rolePermissions).subscribe(
      (result) => {
        this.toastr.success("Role updated", "Success!");
        this.close();
      },
      (error) => {
        this.toastr.warning("error", "Alert!", {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
      }
    );
  }

  close() {
    const closeModal = document.getElementsByClassName("p-dialog-header-close")[0] as HTMLElement;
    closeModal.click();
  }
}
