import { Component, OnInit } from "@angular/core";
import { Deduction } from "@app/shared/models/settlements/Deduction.model";
import { DeductionService } from "../deduction.service";
import { Router } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "app-deductions-list",
  templateUrl: "./deductions-list.component.html",
  styleUrls: ["./deductions-list.component.css"],
})
export class DeductionsListComponent implements OnInit {
  deductions: Deduction[] = [];
  filteredDeductions: Deduction[] = [];
  showActive: boolean = false;

  constructor(
    private router: Router,
    private _AuthService: authenticationService,
    private deductionService: DeductionService
  ) {}

  ngOnInit(): void {
    this.deductionService.getDeductions().subscribe((data) => {
      this.deductions = data;
      this.filterDeductions();
    });
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  viewDeduction(id: number) {
    this.router.navigate(["settlements/deductions/" + id]);
  }

  filterDeductions() {
    if (this.showActive) this.filteredDeductions = this.deductions;
    else this.filteredDeductions = this.deductions.filter((deduction) => deduction.isActive);
  }
}
