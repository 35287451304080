import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { authenticationService } from "./auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "reset-component",
  templateUrl: "./reset.component.html",
  styleUrls: ["./login.component.css"],
})
export class ResetComponent {
  form: any = {};

  constructor(
    private route: ActivatedRoute,
    private authService: authenticationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (!this.route.snapshot.queryParamMap.has("token")) {
      this.toastr.error("No token provided", "Error");
    } else {
      this.form.token = this.route.snapshot.queryParamMap.get("token");
      this.form.username = this.route.snapshot.queryParamMap.get("username");
    }
  }

  submit() {
    if (this.form.password !== this.form.passwordConfirm) {
      this.toastr.error("Passwords don't match", "Error");
      return;
    }
    this.authService.reset(this.form);
  }
}
