<div class="container">
  <div class="col-md-12">
    <legend>Fuel Report</legend>
  </div>

  <select
    [ngModel]="selectedZone"
    (ngModelChange)="zoneSelected($event)"
    class="tableDistatchSelects selectFuel"
  >
    <option value="all">All</option>
    <option value="US - NATIONAL">US - NATIONAL</option>
    <option value="CALIFORNIA">CALIFORNIA</option>
    <option value="CENTRAL ATLANTIC">CENTRAL ATLANTIC</option>
    <option value="GULF COAST">GULF COAST</option>
    <option value="LOWER ATLANTIC">LOWER ATLANTIC</option>
    <option value="MIDWEST">MIDWEST</option>
    <option value="NEW ENGLAND">NEW ENGLAND</option>
    <option value="ROCKY MOUNTAIN">ROCKY MOUNTAIN</option>
    <option value="WEST COAST LESS CALIFORNIA">WEST COAST LESS CALI</option>
    <option value="EAST COAST">EAST COAST</option>
    <option value="WEST COAST">WEST COAST</option>
    <option value="CA - NATIONAL">CA - NATIONAL</option>
    <option value="ST. JOHN'S">ST. JOHN'S</option>
    <option value="WINNIPEG">WINNIPEG</option>
    <option value="FREDERICTON">FREDERICTON</option>
    <option value="LETHBRIDGE">LETHBRIDGE</option>
    <option value="HALIFAX">HALIFAX</option>
    <option value="CHARLOTTETOWN">CHARLOTTETOWN</option>
    <option value="MONTREAL">MONTREAL</option>
    <option value="REGINA">REGINA</option>
    <option value="VANCOUVER">VANCOUVER</option>
    <option value="TORONTO">TORONTO</option>
  </select>
  <table class="table tableCard">
    <tbody>
      <tr>
        <td class="topTable">Zone</td>
        <td class="topTable">Price</td>
        <td class="topTable">Date</td>
      </tr>
      <tr class="titleTable" *ngFor="let zone of zones">
        <td style="font-weight: bold">
          <a (click)="selectZone(zone.zone)" style="cursor: pointer">{{ zone.zone }}</a>
        </td>
        <td style="font-weight: bold">{{ zone.price | number }}</td>
        <td style="font-weight: bold">{{ zone.startDate | date : "yyyy-MM-dd" }}</td>
      </tr>
    </tbody>
  </table>
</div>
