<div>
  <div class="col-md-12 no-padding">
    <legend>Client Rates</legend>
    <div class="btnRig">
      <button class="btn btn-primary rate-btn" (click)="createRate()">Add Rate</button>
    </div>
    <p-table
      #clientRatesTable
      [columns]="columns"
      [value]="rates"
      sortMode="multiple"
      stateStorage="session"
      stateKey="client-rates-list"
      [scrollable]="true"
      scrollHeight="700px"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [style.width.px]="col.width" />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{ col.header }}
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns">
            <input
              *ngIf="col.type !== 'boolean'"
              type="text"
              style="width: 100%"
              (input)="$any(clientRatesTable).filter($event.target.value, col.field, 'contains')"
              [value]="$any(clientRatesTable).filters[col.field]?.value"
            />
            <p-dropdown
              aria-label="status"
              *ngIf="col.type === 'boolean'"
              [options]="statusOptions"
              (onChange)="$any(clientRatesTable).filter($event.value, col.field, 'equals')"
              [ngModel]="$any(clientRatesTable).filters[col.field]?.value"
              appendTo="body"
              tabbableDropdown
            >
            </p-dropdown>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rate>
        <tr class="clickable-row" (dblclick)="editRate(rate)">
          <td>{{ rate.name }}</td>
          <td>{{ rate.description }}</td>
          <td>{{ rate.rateBy }}</td>
          <td>{{ rate.priority }}</td>
          <td>{{ rate.validFromUtc }}</td>
          <td>{{ rate.validToUtc }}</td>
          <td>{{ rate.isValid ? "Active" : "Inactive" }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
