import { Component, OnInit } from "@angular/core";
import { FuelService } from "../fuel/fuel.service";

@Component({
  selector: "app-fuel",
  templateUrl: "./fuel.component.html",
  styleUrls: ["./fuel.component.css"],
})
export class FuelComponent implements OnInit {
  zones: Array<any>;
  errorMsg: any;
  selectedZone: string;

  constructor(private fuelService: FuelService) {}

  ngOnInit() {
    this.selectedZone = "all";
    this.fuelService.getFuelPrice().subscribe(
      (result: any) => {
        this.zones = result;
      },
      (error) => (this.errorMsg = error)
    );
  }

  zoneSelected(selectedZone: string) {
    if (selectedZone && selectedZone === "all") {
      this.fuelService.getFuelPrice().subscribe(
        (result: any) => {
          this.zones = result;
        },
        (error) => (this.errorMsg = error)
      );
    } else {
      this.fuelService.getFuelPriceByZone(selectedZone).subscribe(
        (result: any) => {
          this.zones = result;
        },
        (error) => (this.errorMsg = error)
      );
    }
  }

  selectZone(zone: string) {
    this.selectedZone = zone;
    this.zoneSelected(zone);
  }
}
