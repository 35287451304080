<div class="container">
  <div class="team-header">
    <h4>Team Info: {{ team?.name }}</h4>
    <div class="form-group" align="right">
      <button class="btn" routerLink="/teams" style="margin-right: 1rem">Cancel</button>
      <button
        class="btn btn-primary"
        (click)="editing = true"
        *ngIf="hasPermission('DriversChange') && !creating && !editing"
      >
        Edit Team
      </button>
      <button
        class="btn btn-primary"
        (click)="saveTeam()"
        *ngIf="editing && !creating"
        style="margin-right: 1rem"
      >
        Save Changes
      </button>
      <button class="btn btn-danger" (click)="deleteTeam()" *ngIf="editing && !creating">
        Delete Team
      </button>
      <button
        class="btn btn-primary"
        (click)="createTeam()"
        [disabled]="team.drivers?.length < 2"
        *ngIf="creating"
      >
        Create
      </button>
    </div>
  </div>
  <hr />
  <h4 *ngIf="notFound">Team not found</h4>
  <div *ngIf="!notFound">
    <div class="row form-group">
      <label class="col-md-2">Team Name</label>
      <div class="col-md-4">
        <input
          required
          type="text"
          class="form-control"
          [(ngModel)]="team.name"
          [disabled]="!editing"
          Id="teamName"
        />
      </div>
    </div>
    <div class="row form-group">
      <label class="col-md-2">Team Type</label>
      <div class="col-md-4">
        <!-- <p-dropdown
          [options]="teamTypes"
          [(ngModel)]="team.type"
          [autoDisplayFirst]="false"
          [disabled]="!editing"
          tabbableDropdown
        ></p-dropdown> -->
        <input type="text" class="form-control" [(ngModel)]="team.type" [disabled]="true" />
      </div>
    </div>
    <div class="row form-group" style="margin-top: 2rem">
      <label class="col-md-2">Team Members</label>
      <div class="col-md-10">
        <table class="table">
          <thead>
            <tr>
              <th>Pay To</th>
              <th>Percent</th>
              <th>Driver</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let driver of team.drivers">
              <td>
                <div style="width: 40px; text-align: center">
                  <p-checkbox
                    [(ngModel)]="driver.isPayTo"
                    binary="true"
                    [disabled]="!editing"
                    id="isPayTo"
                  ></p-checkbox>
                </div>
              </td>
              <td style="width: 120px; display: flex; align-items: center">
                <input
                  [(ngModel)]="driver.earningPercentage"
                  type="number"
                  min="0"
                  class="form-control"
                  [disabled]="!editing"
                  (blur)="calculateTotalPercent()"
                />
                <span style="margin-left: 0.25rem">%</span>
              </td>
              <td style="width: 100%">{{ driver.name }} {{ driver.lastName }}</td>
              <td>
                <button
                  pButton
                  pRipple
                  type="button"
                  class="p-button-danger"
                  icon="pi pi-trash"
                  (click)="removeDriver(driver)"
                  *ngIf="editing"
                ></button>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <b style="margin-left: 1rem" *ngIf="totalPercent">{{ totalPercent }}%</b>
              </td>
              <td></td>
              <td>
                <button
                  pButton
                  pRipple
                  type="button"
                  class="p-button-success"
                  icon="pi pi-plus"
                  data-toggle="modal"
                  data-target="#addDriverModal"
                  *ngIf="editing"
                  (click)="clearDriver()"
                  Id="plusDriver"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div style="width: 1200px; margin-bottom: 15px">
    <app-team-history *ngIf="!editing" [teamId]="team.id"> </app-team-history>
  </div>
</div>

<div
  class="modal fade"
  id="addDriverModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addDriverModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" style="min-width: 600px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Driver</h5>
      </div>
      <hr />
      <div class="modal-body">
        <div class="form-group row">
          <label class="col-sm-4 control-label">Select Driver</label>
          <div class="col-sm-8">
            <p-dropdown
              placeholder="Select a Driver"
              [options]="drivers"
              [(ngModel)]="selectedDriver"
              optionLabel="itemName"
              filterBy="itemName"
              optionDisabled="disabled"
              [filter]="true"
              [style]="{ minWidth: '100%' }"
              tabbableDropdown></p-dropdown>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" #closebutton>
          Cancel
        </button>
        <button
          type="submit"
          [disabled]="selectedDriver == null"
          class="btn btn-primary"
          (click)="addDriver()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog key="deleteDialog"
                 header="Confirmation"
                 icon="pi pi-exclamation-triangle">
</p-confirmDialog>
