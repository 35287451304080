import { Component, ViewChild, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-preview-files",
  templateUrl: "./preview-file.component.html",
  styleUrls: ["./preview-file.component.css"],
})
export class PreviewFileComponent implements OnInit {
  @Input("files") files: Array<any>;
  src: string;
  currentFile: any;
  index = 0;

  constructor() {}

  ngOnInit() {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.src = reader.result as any;
      this.currentFile = this.files[0];
    };
    reader.readAsDataURL(this.files[0]);
  }

  prev() {
    if (this.index - 1 >= 0) {
      this.index--;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.src = reader.result as any;
        this.currentFile = this.files[this.index];
      };
      reader.readAsDataURL(this.files[this.index]);
    }
  }

  next() {
    if (this.index + 1 < this.files.length) {
      this.index++;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.src = reader.result as any;
        this.currentFile = this.files[this.index];
      };
      reader.readAsDataURL(this.files[this.index]);
    }
  }
}
