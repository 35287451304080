<div class="container">
  <div class="col-md-12">
    <form class="form-horizontal tableCard">
      <fieldset>

        <!-- Form Name -->
        <legend>Shipper Info</legend>

        <!-- Text input-->
        <div class="form-group">
          <label class="col-md-3 control-label" for="nameClient">Shipper Name</label>
          <div class="col-md-4">
            <input id="nameClient" name="nameClient" type="text" placeholder="Insert Shipper Name" class="form-control input-md" [(ngModel)]="newShipper.name">
          </div>
        </div>
        <!-- Text input-->
        <div class="form-group">
          <label class="col-md-3 control-label" for="numberClient">Number</label>
          <div class="col-md-4">
            <input id="numberClient" name="numberClient" type="text" placeholder="Insert Number" class="form-control input-md" [(ngModel)]="newShipper.number">
          </div>
        </div>
        <!-- Text input-->
        <div class="form-group">
            <label class="col-md-3 control-label" for="Adrress">Address</label>
            <div class="col-md-4">
              <input id="Adrress" name="Adrress" type="text" placeholder="Insert Address" class="form-control input-md" [(ngModel)]="newShipper.address">
            </div>
          </div>
        <!-- Text input-->
        <div class="form-group">
          <label class="col-md-3 control-label" for="selectedClient">Client</label>
          <div class="col-md-4">
            <select [(ngModel)]="newShipper.selectedClient" class="form-control" id="selectedClient" name="selectedClient">
                <option [ngValue]="null" disabled>--Select one--</option>
                <option *ngFor="let client of clients" [value]="client.id">{{client.companyName}}</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label class="col-md-4 control-label" for="edit"></label>
          <div class="col-md-8">
            <button id="edit" name="edit" class="btn btn-primary" (click)="create(newShipper)">Create new Shipper</button>
          </div>
        </div>
      </fieldset>
    </form>

    <hr>
    <div class="col-md-12">
      <div class="form-group">
        <label class="col-md-3 control-label">Select Client</label>
        <div class="col-md-4">
          <select [ngModel]="clientSelectedId" (ngModelChange)="clientSelected($event)" class="form-control">
            <option [ngValue]="null" disabled>--Select one--</option>
            <option *ngFor="let client of clients" [value]="client.id">{{client.companyName}}</option>
          </select>
        </div>
      </div>
      <br>
    </div> 
 <br>
    <table class="table tableCard">

      <tbody>
        <tr>
          <td class="topTable">Action</td>
          <td></td>
          <td class="topTable">Shipper Name</td>
          <td class="topTable">Shipper Number</td>
          <td class="topTable"></td>
        </tr>
        <tr class="titleTable" *ngFor="let shipper of shippers;let i = index">
          <td>
            <button type="button" class="btn btn-default" (click)="shipper.isEditable=true" *ngIf="!shipper.isEditable">
              <span class="glyphicon glyphicon-pencil"></span>
            </button>
            <button type="button" class="btn btn-default" (click)="shipper.isEditable=false" *ngIf="shipper.isEditable">
              Cancel
            </button>
          </td>
          <td>
            <button type="button" class="btn btn-default" (click)="delete(shipper,i)" *ngIf="!shipper.isEditable">
              <span class="glyphicon glyphicon-trash"></span>
            </button>
          </td>
          <td>
            <input [disabled]="!shipper.isEditable" id="company" name="company" type="text" class="form-control input-md" [(ngModel)]="shipper.name">
          </td>
          <td>
            <input [disabled]="!shipper.isEditable" id="other" name="other" type="text" class="form-control input-md" [(ngModel)]="shipper.number">
          </td>
          <td>
            <button type="button" class="btn btn-default" (click)="update(shipper)" *ngIf="shipper.isEditable">
              <span class="glyphicon glyphicon-ok"></span>
            </button>
          </td>
        </tr>
      </tbody>

    </table>
  </div>

</div>