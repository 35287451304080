<div>
  <button
    pButton
    type="button"
    icon="pi pi-plus"
    class="p-button-success"
    label="Add Bill To"
    *ngIf="isEditing"
    (click)="addClient()"
    style="margin-bottom: 0.5rem"
  ></button>
</div>
<div *ngIf="isEditing">
  <div *ngFor="let client of clients" class="flex">
    <p-dropdown
      [style]="{ width: '350px' }"
      [options]="groupedBillTos"
      [(ngModel)]="client.id"
      [autoDisplayFirst]="false"
      [group]="true"
      scrollHeight="300px"
      class="mr-2"
      tabbableDropdown
    ></p-dropdown>
    <label class="mr-2">Valid From</label>
    <input
      id="validFrom"
      name="validFrom"
      type="text"
      [inputMask]="dateInputMask"
      autocomplete="off"
      placeholder="mm/dd/yyyy"
      class="mr-2"
      [(ngModel)]="client.validFromUtc"
    />
    <label class="mr-2">To</label>
    <input
      id="validTo"
      name="validTo"
      type="text"
      [inputMask]="dateInputMask"
      autocomplete="off"
      placeholder="mm/dd/yyyy"
      class="mr-2"
      [(ngModel)]="client.validToUtc"
    />

    <button
      pButton
      type="button"
      icon="pi pi-trash"
      class="p-button-danger"
      (click)="removeClient(client)"
    ></button>
  </div>
</div>

<div *ngIf="!isEditing">
  <div *ngFor="let client of clients" class="mr-2">
    <label>{{ client.companyName }} </label>
    &nbsp;-
    <label>Valid From {{ client.validFromUtc | date : "MM/dd/yyyy" }}</label>
    <label *ngIf="client.validTo"> - {{ client.validToUtc | date : "MM/dd/yyyy" }}</label>
  </div>
</div>
