<div *ngIf="src">
	<pdf-viewer 
	[src]="src" 
	[autoresize]="true" 
	[original-size]="false"
	class="documentWireframe" 
	style="
	display: block;
    /* max-width: 424px; */
    width: 466px;
    max-height: 278px;
    margin-left: -23px;"
	*ngIf="currentFile.type==='application/pdf'">
	</pdf-viewer>
	<img [src]="src"
	*ngIf="currentFile.type==='image/jpeg'"
	style="display: block; max-width: 454px; max-height: 280px;">
	<div class="row buttonsRow">
			<div class="col-md-4"  align="left"><button class="btn btn-next" (click)='prev()'><i class="fa fa-arrow-left"></i></button></div>
				<div class="col-md-4"  align="center"></div>
				<div class="col-md-4" align="right" style="padding-right: 40px;"><button class="btn btn-next" (click)='next()'><i class="fa fa-arrow-right"></i></button></div>
	</div>
</div>