<div class="container">
  <div class="col-md-12">
    <legend>T-Chek Historical</legend>
    <div class="d-flex" style="margin-bottom: 1rem" *ngIf="batchesList.length > 0">
      <div class="mr-3">
        <div>
          <p-dropdown
            [options]="batchesList"
            [(ngModel)]="selectedBatch"
            [filter]="true"
            optionLabel="itemName"
            filterBy="itemName"
            (onChange)="loadBatchData()"
            tabbableDropdown
          ></p-dropdown>
        </div>
      </div>
      <div>
        <button
          pButton
          type="button"
          icon="fa fa-file-excel-o"
          title="Export to Excel"
          label="Export to Excel"
          (click)="excelGeneration()"
          class="p-button-secondary"
          style="margin-left: 1rem"
        ></button>
      </div>
    </div>
    <app-table-report [record]="records" [isEdit]="false" type="HISTORICAL"></app-table-report>
  </div>
</div>
