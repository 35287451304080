import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TeamsService } from "@app/teams/shared/teams.service";
import { MaskService } from "../../../app/shared/services/mask/mask.service";

@Component({
  selector: "app-team-history",
  templateUrl: "./team-history.component.html",
  styleUrls: ["./team-history.component.css"],
})
export class TeamHistoryComponent implements OnInit {
  @Input() teamId: string;

  histories: {
    createdAt: Date;
    teamId: string;
    field: string;
    value: string;
    Driver: any;
  }[];

  filteredHistories: any[] = [];
  startDate: Date;
  endDate: Date;

  columns: any = [
    { field: "createdAt", header: "Date Changed", width: 350 },
    { field: "Team.name", header: "Team Name", width: 200 },
    { field: "field", header: "Field", width: 220 },
    { field: "value", header: "Value", width: 220 },
  ];
  dateInputMask: any;


  constructor(private route: ActivatedRoute, private teamService: TeamsService, private maskService: MaskService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.teamId = params["id"];
    });
    this.teamService.getTeamHistory(this.teamId).subscribe((res) => {
      this.histories = res;
      this.filteredHistories = res;
    });
    this.dateInputMask = this.maskService.getInputDateOnlyMask();
  }

  filterByDate() {
    this.filteredHistories = this.histories.filter((history) => {
      const createdDate = new Date(history.createdAt);
      return createdDate > this.startDate && createdDate < this.endDate;
    });
  }
}
