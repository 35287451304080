import { Directive, Optional, Self, Host } from "@angular/core";
import { Dropdown } from "primeng/dropdown";

@Directive({
  selector: "[tabbableDropdown]",
})
export class TabbableDropdownDirective {
  constructor(@Host() @Self() @Optional() public dropdown: Dropdown) {
    window.addEventListener("keyup", (event: KeyboardEvent) => {
      if (event.key === "Tab" && dropdown.focused) {
        const visibleOptions = dropdown.visibleOptions();
        // If the dropdown isn't open, open it
        if (!dropdown.overlayVisible) dropdown.show();
        // // if shift is being held down, don't do any of this stuff
        // else if (event.shiftKey) return;
        // // if the dropdown is open, and there is only one item in the dropdown list
        // // select it and close the dropdown
        // else if (dropdown.overlayVisible && visibleOptions.length === 1) {
        //   this.dropdown.value = visibleOptions[0];
        //   if (this.dropdown.optionValue)
        //     this.dropdown.value = visibleOptions[0][this.dropdown.optionValue];
        //   this.dropdown.updateModel(this.dropdown.value);
        //   this.dropdown.onChange.emit(this.dropdown.value);
        //   dropdown.hide();
        //   focusNextElement();
        // }
        // // If the dropdown is open, and there is an element selected
        // // go to the next tabbable element
        // else if (dropdown.overlayVisible && dropdown.selectedOption) {
        //   dropdown.hide();
        //   focusNextElement();
        // }
      }
    });
  }
}

function focusNextElement() {
  // Collect all elements that could potentially receive focus
  const focusableElements = Array.from(
    document.querySelectorAll(
      'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
    )
  ).filter((el) => !el.hasAttribute("disabled"));

  // Get the currently focused element
  const currentElement = document.activeElement;

  // Find the index of the currently focused element within the focusable elements array
  const currentIndex = focusableElements.indexOf(currentElement);

  // Calculate the index of the next element to focus
  const nextIndex = (currentIndex + 1) % focusableElements.length;

  // Set focus to the next element
  const nextElement = focusableElements[nextIndex] as HTMLElement;
  nextElement.focus();
}
