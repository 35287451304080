<div class="container">
  <div class="col-md-12">
    <legend>PeopleNet Testing Tool</legend>
    <div id="result" class="trips-tab-body" style="display: block">
      <p-table
        #dtPeopleNet
        [columns]="cols"
        [value]="truckList"
        sortMode="multiple"
        [scrollable]="true"
        scrollHeight="flex"
        [virtualScroll]="true"
        [virtualScrollItemSize]="42"
        stateStorage="session"
        stateKey="peoplenet-trucks-table"
      >
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="columns.length">No records found</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width.px]="col.width" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
              <input
                type="text"
                style="width: 100%"
                (input)="$any(dtPeopleNet).filter($event.target.value, col.field, 'contains')"
                [value]="$any(dtPeopleNet).filters[col.field]?.value"
              />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-i="rowIndex" let-truck>
          <tr style="height: 42px">
            <td [title]="truck.tractorNumber?.toUpperCase()">{{ truck.tractorNumber }}</td>
            <td>{{ truck.dateTime | date : "MM/dd/yyyy HH:mm" }}</td>
            <td style="text-align: right">{{ truck.speed }}</td>
            <td [title]="truck.heading?.toUpperCase()">{{ truck.heading }}</td>
            <td style="text-align: right">{{ truck.latitude }}</td>
            <td style="text-align: right">{{ truck.longitude }}</td>
            <td [title]="truck.location?.toUpperCase()">{{ truck.location }}</td>
            <td [title]="truck.driver1Name?.toUpperCase()">{{ truck.driver1Name }}</td>
            <td [title]="truck.dutyStatus1?.toUpperCase()">{{ truck.dutyStatus1 }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
