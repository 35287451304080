import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ChargeType } from "@app/shared/models/ChargeType.model";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng/api";
import { ChargetypesService } from "../shared/chargetypes.service";

@Component({
  selector: "app-edit-charge-type",
  templateUrl: "./edit-charge-type.component.html",
  styleUrls: ["./edit-charge-type.component.css"],
})
export class EditChargeTypeComponent implements OnInit {
  @Input() chargeType: ChargeType;
  @Output() chargeTypeChange = new EventEmitter();
  @Input() isEditing: boolean;
  @Output() addedEvent = new EventEmitter();

  constructor(
    private chargeTypesService: ChargetypesService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.isEditing) {
      this.editChargeType();
    } else {
      this.createNewChargeType();
    }
  }

  createNewChargeType() {
    this.chargeTypesService.addChargeType(this.chargeType).subscribe(
      (created: any) => {
        this.addedEvent.emit(created);
        this.closeModal();
      },
      (error) => {
        this.toastr.warning(error, "Alert!");
        console.dir(error);
      }
    );
  }

  editChargeType() {
    this.chargeTypesService
      .editChargeType(this.chargeType.id, this.chargeType)
      .subscribe(
        (edited: any) => {
          this.chargeTypeChange.emit(edited);
          this.closeModal();
        },
        (error) => {
          this.toastr.warning(error, "Alert!");
          console.dir(error);
        }
      );
  }

  confirmationDelete() {
    this.confirmationService.confirm({
      key: "deleteDialog",
      message: "Do you really want to delete this Charge Type?",
      accept: () => {
        this.deleteChargeType(this.chargeType.id);
        this.closeModal();
      },
      reject: () => {},
    });
  }

  deleteChargeType(id: string | number) {
    this.chargeTypesService.deleteChargeType(id).subscribe(
      (result) => {
        this.chargeType.isDeleted = true;
        this.chargeTypeChange.emit(this.chargeType);
      },
      (error) => {
        this.toastr.warning(error, "Alert!");
        console.dir(error);
      }
    );
  }

  restore() {
    this.chargeTypesService
      .restoreChargeType(this.chargeType.id, this.chargeType)
      .subscribe(() => {
        this.chargeType.isDeleted = false;
        this.chargeTypeChange.emit(this.chargeType);
        this.closeModal();
      });
  }

  closeModal() {
    const closeModal = document.getElementsByClassName(
      "p-ripple"
    )[0] as HTMLElement;
    closeModal.click();
  }
}
