<div class="container">
  <div class="col-md-12">
    <legend>Schedule Tractor</legend>
    <div class="col-md-6">
      <div class="form-group">
        <label class="col-md-4 control-label" for="company">Client</label>
        <div class="col-md-8">
          <select id="selectbasic" name="selectbasic" class="form-control">
            <option value="1">Option one</option>
            <option value="2">Option two</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-4 control-label" for="company">Product</label>
        <div class="col-md-8">
          <select id="selectbasic" name="selectbasic" class="form-control">
            <option value="1">Option one</option>
            <option value="2">Option two</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-4 control-label" for="company">Details</label>
        <div class="col-md-8">
          <textarea id="company" rows="8" name="company" type="text" placeholder="placeholder" class="form-control input-md">
            </textarea>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label class="col-md-4 control-label" for="radios">Need Trailer?</label>
        <div class="col-md-8">
          <label class="radio-inline" for="radios-0">
            <input type="radio" name="radios" id="radios-0" value="Yes" checked="checked"> Yes
          </label>
          <label class="radio-inline" for="radios-1">
            <input type="radio" name="radios" id="radios-1" value="No"> No
          </label>
        </div>
      </div>
      <div class="col-md-12">
        <table class="table table-fixed">
          <thead>
            <tr>
              <th class="col-xs-3">#</th>
              <th class="col-xs-3">Trailer No</th>
              <th class="col-xs-3">Product</th>
              <th class="col-xs-3">State</th>
              <th class="col-xs-3">City</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="col-xs-3">
                <input type="checkbox" id="checkbox" />
              </td>
              <td class="col-xs-3"> </td>
              <td class="col-xs-3"> </td>
              <td class="col-xs-3"> </td>
              <td class="col-xs-3"> </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="form-group">
        <div class="col-md-12">
          <button id="edit" name="edit" class="btn btn-default">Calendar</button>
        </div>
      </div>
    </div> 
    <hr>
    <br>
    <div class="form-group"> 
      <div class="col-md-12" align="center">
        <button id="edit" name="edit" class="btn btn-default scheduleTruckBtn">Schedule Tractor</button>
      </div>
    </div>
  </div>

</div>