<div class="container">
  <div class="col-md-12">
    <legend>Charge Types</legend>
    <div class="btnRig">
      <button type="submit" class="btn btn-primary" (click)="showAddForm()">Create</button>
    </div>
    <div class="toggle-box">
      <p-checkbox
        [(ngModel)]="showDeleted"
        binary="true"
        inputId="binary"
        class="mr-2"
        (onChange)="filterChargeTypes()"
        id="showDeleted"
      ></p-checkbox>
      <label for="binary">Show Deleted</label>
    </div>
    <div style="display: inline-block">
      <p-table #chargeTypesTable [columns]="cols" [value]="filteredChargeTypes" [autoLayout]="true">
        <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="pi pi-search" style="margin: 4px 4px 0 0"></i>
            <input
              type="text"
              pInputText
              size="50"
              placeholder="Search..."
              (input)="chargeTypesTable.filterGlobal($event.target.value, 'contains')"
              style="width: auto"
            />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-chargeType>
          <tr
            [ngClass]="{
              'clickable-row': !chargeType.isDeleted,
              'deleted-row': chargeType.isDeleted
            }"
            (dblclick)="showEditForm(chargeType)"
          >
            <td>{{ chargeType.name }}</td>
            <td>{{ chargeType.description }}</td>
            <td>{{ chargeType.revenueGL }}</td>
            <td>{{ chargeType.expenseGL }}</td>
            <td>{{ chargeType.otherGL }}</td>
            <td>{{ chargeType.isAccessorial | yesNo }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  header="Edit Charge Type"
  *ngIf="displayEdit"
  [(visible)]="displayEdit"
  [modal]="true"
  [style]="{ width: '350px' }"
  [minY]="100"
>
  <app-edit-charge-type
    [(chargeType)]="selectedChargeType"
    [isEditing]="true"
  ></app-edit-charge-type>
</p-dialog>

<p-dialog
  header="Add Charge Type"
  *ngIf="displayAdd"
  [(visible)]="displayAdd"
  [modal]="true"
  [style]="{ width: '300px' }"
  [minY]="70"
>
  <app-edit-charge-type
    [(chargeType)]="selectedChargeType"
    (addedEvent)="getChargeTypes()"
    [isEditing]="false"
  ></app-edit-charge-type>
</p-dialog>

<p-confirmDialog
  key="deleteDialog"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
