import { of as observableOf, Observable, Observer } from "rxjs";
import { Component, ViewChild, OnInit } from "@angular/core";
import { DriverService } from "../shared/driver.service";
import { LessorService } from "../../lessor/shared/lessor.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FileUploadService } from "../../shared/services/files/fileUpload.service";
import { DriverHireDateService } from "../shared/driverHireDate.service";
import { GetPictureService } from "../../shared/services/files/getPictures.service";
import { StatesServices } from "../../shared/services/states/states.service";
import { ToastrService } from "ngx-toastr";
import { CanComponentDeactivate } from "../../shared/guards/confirmation/confirmation.guard";
import { DriverValidationService } from "@app/driver/shared/driverValidation.service";
import { DriverViewModel } from "@app/shared/models/drivers/DriverViewModel.model";
import { MaskService } from "../../shared/services/mask/mask.service";

@Component({
  selector: "app-add-driver",
  templateUrl: "./add-driver.component.html",
  styleUrls: ["./add-driver.component.css"],
})
export class AddDriverComponent implements OnInit, CanComponentDeactivate {
  dateMask: (string | RegExp)[];
  previewProfilePicture: any;
  driver: DriverViewModel = new DriverViewModel();
  drivers: DriverViewModel[] = [];
  allDrivers: DriverViewModel[] = [];
  isProcessing = false;
  useGuard: boolean = true;

  driverSelected;
  letters = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
  @ViewChild("driverDocuments", { static: true }) driverdocuments;
  @ViewChild("profilePicture", { static: true }) profilePicture;
  lessorOwners: any = [];
  states: { abbreviation: string; fullName: string }[];
  docType: string;
  dateInputMask: any;

  constructor(
    private _addDriverService: DriverService,
    private lessorService: LessorService,
    private router: Router,
    private fileUploadService: FileUploadService,
    private getPictureService: GetPictureService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private statesServices: StatesServices,
    private driverHireDateService: DriverHireDateService,
    private driverValidationService: DriverValidationService,
    private maskService: MaskService
  ) {
    this.docType = "driver";
  }

  ngOnInit() {
    this.driver.driverHireDates = [];
    this.dateInputMask = this.maskService.getInputDateOnlyMask();

    this.driverSelected = "-1";
    this.driver.type = "COMPANY DRIVER";
    this.driver.isActive = true;
    this.driver.isPayTo = true;
    this.allDrivers = this.route.snapshot.data["drivers"];
    this.drivers = this.route.snapshot.data["drivers"];
    this.states = this.statesServices.getTwoLettersStates("US");
    // this.startForm();
  }

  filterTeamDrivers() {
    this.drivers = this.allDrivers.filter((driver) => driver.type === this.driver.type);
  }

  confirm() {
    if (this.useGuard) return window.confirm("Leave this page without creating the driver?");
    return true;
  }

  calculateTotalTeamPercent() {
    return this.driver.team.drivers.reduce((acc, curr) => acc + (curr.earningPercentage || 0), 0);
  }

  calculatehazmatTrainingExpirationDate(hazmatTrainingDate) {
    if (hazmatTrainingDate) {
      const year = hazmatTrainingDate.getFullYear();
      const month = hazmatTrainingDate.getMonth();
      const day = hazmatTrainingDate.getDate();

      this.driver.hazmatTrainingExpirationDate = new Date(year + 3, month, day);
    } else {
      delete this.driver.hazmatTrainingExpirationDate;
    }
  }
  calculateTrainingDateCGMPExpirationDate(CGMPExpirationDate) {
    if (CGMPExpirationDate) {
      const year = CGMPExpirationDate.getFullYear();
      const month = CGMPExpirationDate.getMonth();
      const day = CGMPExpirationDate.getDate();

      this.driver.expirationDateCGMP = new Date(year + 1, month, day);
    } else {
      delete this.driver.expirationDateCGMP;
    }
  }

  removeDriverHireDate(index) {
    this.driver.driverHireDates.splice(index - 1, 1);
  }
  addDriverHireDate() {
    this.driver.driverHireDates.push({
      hiredDate: this.driver.hiredDate,
      hiredDateTo: this.driver.hiredDateTo,
    });
    this.driver.driverHireDates.sort((a, b) => {
      var aDate = new Date(a.hiredDate).getTime();
      var aDateTo = new Date(a.hiredDateTo).getTime();
      var bDate = new Date(b.hiredDate).getTime();
      var bDateTo = new Date(b.hiredDateTo).getTime();
      return aDate == bDate ? bDateTo - aDateTo : bDate - aDate;
    });
    this.driver.hiredDate = null;
    this.driver.hiredDateTo = null;
  }
  driverHireDateCounter() {
    return new Array(this.driver.driverHireDates.length + 1);
  }
  addHireDates(driverId: string) {
    return this.driverHireDateService.createHireDates(driverId, this.driver.driverHireDates);
  }

  teamSelected(driver) {
    if (this.driverSelected === "-1") {
      return this.removeFromTeam();
    }
    if (!this.driverSelected) {
      driver.TeamId = null;
      driver.driverTeamId = null;
      return;
    }
    if (this.driverSelected.Team) {
      driver.TeamId = this.driverSelected.Team.id;
      driver.driverTeamId = null;
    } else {
      driver.driverTeamId = this.driverSelected.id;
      driver.TeamId = null;
    }
    if (this.driver.team == null) this.driver.team = {};
    if (this.driverSelected.Team) {
      // If the selected driver is already part of a team, Add us to the team
      this.driver.isPayTo = false;
      this.driver.earningPercentage = 0;
      let teamDrivers = this.driverSelected.team.drivers.filter(
        (driver) => driver.id !== this.driver.id
      );
      this.driver.team.drivers = [...teamDrivers, JSON.parse(JSON.stringify(this.driver))];
    } else {
      this.driver.isPayTo = true;
      this.driver.earningPercentage = 100;
      this.driverSelected.isPayTo = false;
      this.driverSelected.earningPercentage = 0;
      this.driver.team.drivers = [this.driverSelected, JSON.parse(JSON.stringify(this.driver))];
    }
  }

  removeFromTeam() {
    delete this.driver.teamId;
    delete this.driver.team;
    this.driverSelected = null;
    this.driver.isPayTo = true;
  }
  updatedDriverNo() {
    if (this.driver.name && this.driver.lastName) {
      this.driver.driverNo = (
        this.driver.lastName.slice(0, 4) + this.driver.name.slice(0, 4)
      ).toUpperCase();
    }
  }
  driverTypeChanged(driverType: string) {
    if (driverType === "LEASE OPERATOR") {
      this.lessorService.getActiveLessorList(true).subscribe((response) => {
        this.lessorOwners = response || [];
      });
    }
    if (!this.driver.team) this.driver.isPayTo = true;
    this.filterTeamDrivers();
  }

  earningPercentageChanged(driver: DriverViewModel) {
    if (driver.id === this.driver.id) {
      this.driver.earningPercentage = driver.earningPercentage;
    }
  }

  getTemporalImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.previewProfilePicture = this.getPictureService.getPreviewImage(event.target.files[0]);
    }
  }

  uploadProfilePicture() {
    const fname: string = this.profilePicture.nativeElement.files[0].name;
    return this.fileUploadService.uploadSingleFileRename(
      this.profilePicture.nativeElement.files,
      this.docType,
      this.driver.driverNo + "_" + fname
    );
  }

  uploadDocuments(driverId: string) {
    return this.driverdocuments.uploadDocuments(
      { DriverId: driverId },
      this.docType + "_files",
      driverId
    );
  }

  sendNewDriver(newDriver: DriverViewModel) {
    return this._addDriverService.createNewDriver(newDriver);
  }

  finishDriverCreation() {
    document.getElementById("closeModal").click();
    this.toastr.success("Driver created", "Success");
    this.isProcessing = false;
    this.driverdocuments.files = [];
    this.useGuard = false;
    this.router.navigate(["/drivers-list"]);
  }

  confirmationModal() {
    // validations go here
    document.getElementById("openModal").click();
  }

  createNewDriver() {
    const newDriver = this.driver;
    if (!this.validateDriver(newDriver)) return;
    this.isProcessing = true;
    let profilePictureOservable;
    if (this.profilePicture.nativeElement.files && this.profilePicture.nativeElement.files[0]) {
      profilePictureOservable = this.uploadProfilePicture();
    } else {
      profilePictureOservable = observableOf(undefined);
    }
    profilePictureOservable.subscribe((result: any) => {
      if (result) {
        newDriver.picture = result.name;
      }
      this.sendNewDriver(newDriver).subscribe(
        (driverCreated: any) => {
          if (this.driverdocuments.documents.length) {
            this.uploadDocuments(driverCreated.id).subscribe((documentsCreated) => {
              if (this.driver.driverHireDates.length) {
                this.addHireDates(driverCreated.id).subscribe((res) => {
                  this.finishDriverCreation();
                  return;
                });
              } else {
                this.finishDriverCreation();
                return;
              }
            });
          } else {
            if (this.driver.driverHireDates.length) {
              this.addHireDates(driverCreated.id).subscribe((res) => {
                this.finishDriverCreation();
                return;
              });
            } else {
              this.finishDriverCreation();
              return;
            }
          }
        },
        (errors) => {
          for (const error of errors) {
            this.toastr.warning(error, "Alert!", {
              closeButton: true,
              enableHtml: true,
            });
            console.dir(error);
          }
          this.isProcessing = false;
        }
      );
    });
  }

  setDriverPayTo(driver: any) {
    driver.isPayTo = true;

    if (this.driver.team?.drivers) {
      for (const driver of this.driver.team.drivers) {
        if (!driver.id) continue;
        let warned = false;
        this._addDriverService
          .getCurrentBatchStatus(driver.id)
          .subscribe((batchStatusResponse: any) => {
            if (batchStatusResponse.status !== "CLOSED") {
              this.toastr.warning(
                "This team has a batch ongoing, saving your changes will remove this driver from the batch."
              );
              warned = true;
            }
          });
        if (warned) break;
      }
    }

    // remove isPayTo from all other drivers
    for (const teamDriver of this.driver.team?.drivers) {
      if (teamDriver.id != driver.id) {
        teamDriver.isPayTo = false;
      }
    }

    // also make sure isPayTo is updated for the driver we're creating
    const isNewDriver = !driver.id;
    this.driver.isPayTo = isNewDriver;
  }

  validateDriver(newDriver: DriverViewModel) {
    let errors = this.driverValidationService.validate(newDriver);
    if (errors && errors.length > 0) {
      for (const error of errors) {
        this.toastr.warning(error, "Alert!");
      }
      return false;
    }

    if (newDriver.team?.drivers) {
      const totalPercent = this.calculateTotalTeamPercent();
      if (totalPercent !== 100) {
        this.toastr.warning("Total percent must equal 100%", "Alert!");
        return false;
      }
    }

    return true;
  }
}
