<div class="row" *ngIf="_files && _files.length">
  <div class="col-md-4" align="left">
    <button [disabled]="index === 0" class="btn btn-next" (click)="prev()">
      <i class="fa fa-arrow-left"></i>
    </button>
  </div>
  <div class="col-md-4" align="center"></div>
  <div class="col-md-4" align="right" style="padding-right: 40px">
    <button [disabled]="index === _files.length - 1" class="btn btn-next" (click)="next()">
      <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>

<div class="row" style="display: block; max-width: 500px; overflow-y: auto">
  <div *ngIf="currentFile && !currentFile.id">
    <div *ngIf="src">
      <pdf-viewer
        [src]="src"
        [autoresize]="true"
        [original-size]="false"
        class="documentWireframe"
        *ngIf="currentFile.type === 'application/pdf'"
      >
      </pdf-viewer>
      <img
        #img
        [appGetFile]="src"
        [appPreviewFile]="true"
        *ngIf="currentFile.type === 'image/jpeg'"
        class="imagePreviews"
      />
    </div>
  </div>

  <div *ngIf="currentFile && currentFile.id">
    <div class="col-md" style="margin-top: -1%; margin-bottom: 1%; background-color: #fbfbfb">
      <button class="btn btn-open" (click)="open()">Open Document</button>
    </div>
    <div>
      <pdf-viewer
        [src]="src"
        [autoresize]="true"
        [original-size]="false"
        class="documentWireframe"
        *ngIf="fileType('pdf')"
      >
      </pdf-viewer>
      <img
        #img
        [appGetFile]="src"
        [appPreviewFile]="true"
        *ngIf="fileType('jpeg')"
        class="imagePreviews"
      />
    </div>
  </div>
</div>
