import { Component, OnInit, ViewChild } from "@angular/core";
import { clientService } from "../shared/client.service";
import { Router, ActivatedRoute } from "@angular/router";
import { StatesServices } from "../../shared/services/states/states.service";
import { FileUploadService } from "../../shared/services/files/fileUpload.service";
import { GetPictureService } from "../../shared/services/files/getPictures.service";
import { MaskService } from "../../shared/services/mask/mask.service";
import { ToastrService } from "ngx-toastr";
import { CanComponentDeactivate } from "../../shared/guards/confirmation/confirmation.guard";
import { BillToComponent } from "../bill-to/bill-to.component";

@Component({
  selector: "app-addclient",
  templateUrl: "./addclient.component.html",
  styleUrls: ["./addclient.component.css"],
})
export class AddclientComponent implements OnInit, CanComponentDeactivate {
  billToList: Array<any>;
  errorMsg: any;
  newClient: any = {
    BillToLocationId: null,
    ClientId: null,
    isActive: true,
    emails: [],
  };
  uuid: any;
  previewProfilePicture: any;
  usPhoneMask: any;
  zipCodeMask = [];
  clients: any[] = [];
  showNewClientComponent = false;
  disabledAddBillToButton: boolean;
  letters = /[^a-zA-Z\s]+/;
  numbers = /^\d*\.?\d+$/;
  zipCode = /^\d{5}(?:[-\s]\d{4})?$/;

  @ViewChild("profilePicture") profilePicture;
  @ViewChild("billTo", { static: true }) billToComponent: BillToComponent;
  states: { abbreviation: string; fullName: string }[];
  isGuardActive: boolean = true;
  dateInputMask: any;

  constructor(
    private _addClientService: clientService,
    private router: Router,
    private statesServices: StatesServices,
    private fileUploadService: FileUploadService,
    private maskService: MaskService,
    private getPictureService: GetPictureService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.disabledAddBillToButton = true;
    this.states = this.statesServices.getTwoLettersStates("US");
    this.usPhoneMask = this.maskService.getUsPhoneMask();
    this.clients = this.route.snapshot.data["clients"];
    this.billToList = [];
    this.zipCodeMask = this.maskService.getZipCode();
    this.dateInputMask = this.maskService.getInputDateOnlyMask();
  }

  confirm() {
    if (this.isGuardActive) {
      return window.confirm("Do you want to leave without creating a client?");
    } else {
      return true;
    }
  }

  useTheClientAsContact() {
    this.billToComponent.useTheClientAsContact(this.newClient);
  }

  getTemporalImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.previewProfilePicture = this.getPictureService.getPreviewImage(event.target.files[0]);
    }
  }

  uploadProfilePicture() {
    return this.fileUploadService.uploadFiles(this.profilePicture.nativeElement.files, "client");
  }

  finishClientCreation() {
    this.toastr.success("Client created", "Success!");
    this.isGuardActive = false;
    this.router.navigate(["/clientlist/"]);
  }

  createAddClient(client) {
    if (!this.validateClient()) return;
    const newBillTo = this.billToComponent.getBillTo();
    const newBillToProperties = Object.getOwnPropertyNames(newBillTo);
    if (newBillToProperties.length > 1) {
      if (!this.addBillTo()) return;
    }
    if (this.billToList.length == 0) {
      this.toastr.info("A complete Bill To is required");
      return;
    }

    // parse dates
    client.validFrom = new Date(this.newClient.validFrom);
    client.validTo = client.validTo ? new Date(this.newClient.validTo) : null;
    for (const billTo of this.billToList) {
      billTo.validFrom = new Date(billTo.validFrom);
      billTo.validTo = billTo.validTo ? new Date(billTo.validTo) : null;
      billTo.isActive = true;
    }

    if (this.checkBillToUnique()) {
      this._addClientService.createAddClient(client, this.billToList).subscribe(
        () => {
          this.finishClientCreation();
        },
        (error) => {
          this.toastr.warning(error, "warning");
        }
      );
    }
  }

  validateClient() {
    const requiredFields = [
      "companyName",
      "validFrom",
      "address",
      "city",
      "state",
      "zip",
      "customerNo",
      "billingRange",
    ];
    const displayNames = {
      companyName: "Company Name",
      validFrom: "Valid From",
      address: "Address",
      city: "City",
      state: "State",
      zip: "ZIP Code",
      customerNo: "Client ID",
      billingRange: "Payment Terms",
    };

    const warnings = [];

    for (const field of requiredFields) {
      if (!this.newClient[field]) {
        warnings.push(`Client: ${displayNames[field]} required`);
      }
    }

    if (this.newClient.validFrom && this.newClient.validTo) {
      if (this.newClient.validFrom >= this.newClient.validTo) {
        warnings.push("Valid To date must be after Valid From");
      }
    }

    if (this.newClient.customerNo) {
      if (this.newClient.customerNo.length > 20 || this.newClient.customerNo.length < 2) {
        warnings.push("Client ID must be 2-20 characters");
      }
      if (!this.checkClientUnique()) {
        warnings.push("Client ID already exists");
      }
    }

    if (
      this.newClient.city &&
      this.newClient.city != this.newClient.city.replace(this.letters, "")
    ) {
      warnings.push("Client City can only be letters");
    }

    if (this.newClient.zip && !this.zipCode.test(this.newClient.zip)) {
      warnings.push("Invalid ZIP Code");
    }

    if (warnings.length > 0) {
      const orderedWarnings = warnings.reverse();
      for (const warning of orderedWarnings) {
        this.toastr.warning(warning);
      }
      return false;
    }
    return true;
  }

  addBillTo() {
    const newBillTo = this.billToComponent.getBillTo();
    const isValid = this.billToComponent.validateBillTo();
    const isUnique = this.checkBillToUnique(newBillTo.customerNo);
    if (!isValid || !isUnique) return false;
    this.billToList.push(JSON.parse(JSON.stringify(this.billToComponent.getBillTo())));
    this.billToComponent.resetBillTo();
    return true;
  }

  checkBillToUnique(newId = null) {
    const existingBillTo = [];
    if (newId) {
      existingBillTo.push(newId.toUpperCase());
    }
    let isUnique = true;
    this.billToList.forEach((billTo) => {
      if (!existingBillTo.includes(billTo.customerNo.toUpperCase())) {
        existingBillTo.push(billTo.customerNo.toUpperCase());
      } else {
        this.toastr.warning(
          `${billTo.companyName} Bill To ID ${billTo.customerNo} already exists`,
          "warning"
        );
        existingBillTo.push(billTo.customerNo.toUpperCase());
        isUnique = false;
      }
    });
    return isUnique;
  }

  checkClientUnique() {
    if (
      this.clients.some(
        (x) => x.customerNo.toUpperCase() == this.newClient.customerNo.toUpperCase()
      )
    ) {
      return false;
    }
    return true;
  }

  nullORUndefinedValidation(obj) {
    if (typeof obj === "undefined" || obj == null) {
      return false;
    }
    return true;
  }

  generateBillingId() {
    if (this.billToComponent) {
      this.billToComponent.generateBillingId();
    }
  }

  dateChanged(event, param, justDate, yearDigits) {
    if (event.code === "Delete" || event.code === "Backspace") {
      return;
    }
    this.maskService.maskDate(event, param, justDate, yearDigits);
  }

  removeClientSpaces() {
    this.newClient.customerNo = this.newClient.customerNo.replace(/\s/g, "");
  }

  removeBillToElement(index) {
    this.billToList.splice(index, 1);
  }

  locIdGenerated(idWasGenerated) {
    this.disabledAddBillToButton = !idWasGenerated;
  }
}
