import { Component, OnInit, ViewChild } from "@angular/core";
import { LessorService } from "../shared/lessor.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CanComponentDeactivate } from "../../shared/guards/confirmation/confirmation.guard";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { Lessor } from "@app/shared/models/Lessor.model";
import { StatesServices } from "@app/shared/services/states/states.service";

@Component({
  selector: "app-lessorinfo",
  templateUrl: "./lessorinfo.component.html",
  styleUrls: ["./lessorinfo.component.css"],
})
export class LessorinfoComponent implements OnInit, CanComponentDeactivate {
  uuid: string;
  lessorInfo: Lessor;
  originalInfo: any;
  selectedState: any;

  states: any[] = [];

  notFound: boolean = false;
  isGuardActive: boolean = true;
  warnings: any = {};
  btnEdit: string;
  is_edit: boolean;
  isCreating: boolean;
  attemptedCreate: boolean = false;

  constructor(
    private _lessorinfo: ActivatedRoute,
    private lessorDataService: LessorService,
    private toastr: ToastrService,
    private router: Router,
    private statesService: StatesServices,
    private _AuthService: authenticationService
  ) {
    this.lessorInfo = {
      id: -1,
      uuid: "",
      name: "",
      lessorId: "",
      active: true,
      phone: "",
      email: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      ein: "",
      comments: "",
    };
  }

  ngOnInit() {
    this.btnEdit = "Edit Profile";
    this.is_edit = true;
    this.states = this.statesService.getTwoLettersStates("US");

    this._lessorinfo.params.subscribe((params) => {
      this.uuid = params["uuid"] === "new" ? null : params["uuid"];
      this.isCreating = params["uuid"] === "new";

      if (this.uuid && this.uuid.length) {
        this.lessorDataService.getLessorInfo(this.uuid).subscribe(
          (result) => {
            this.lessorInfo = result;
            this.selectedState = this.states.find((state) => state.fullName === result.state);
          },
          (err) => {
            this.notFound = true;
          }
        );
      } else {
        this.is_edit = false;
      }
    });
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  confirm() {
    if (!this.is_edit && this.isGuardActive) {
      return window.confirm("Are you sure you want to leave this page?");
    } else {
      return true;
    }
  }

  finishLessorEdition() {
    this.toastr.success("Lessor updated", "Success!");
  }

  createLessor() {
    if (this.checkRequiredFields()) {
      this.lessorInfo.state = this.selectedState.fullName;
      this.lessorDataService.createAddLessor(this.lessorInfo).subscribe(
        (res) => {
          this.toastr.success("Lessor created", "Success!");
          this.isGuardActive = false;
          this.router.navigate(["/lessorlist/"]);
        },
        (err) => {
          this.toastr.warning("Error creating Lessor!", "Alert!");
        }
      );
    } else {
      this.toastr.warning("You are missing some required fields!", "Alert!");
    }
  }

  editOn() {
    if (this.btnEdit === "Edit Profile") {
      this.btnEdit = "Save Changes";
      this.is_edit = false;
      this.originalInfo = JSON.stringify(this.lessorInfo);
    } else {
      if (this.checkRequiredFields()) {
        this.lessorDataService.updateLessor(this.lessorInfo).subscribe((result) => {
          this.btnEdit = "Edit Profile";
          this.is_edit = true;
          this.finishLessorEdition();
          this.isGuardActive = false;
          this.router.navigate(["/lessorlist/"]);
        });
      } else {
        this.toastr.warning("You are missing some required fields!", "Alert!");
      }
    }
  }

  cancelEdit() {
    this.is_edit = true;
    this.btnEdit = "Edit Profile";
    this.lessorInfo = JSON.parse(this.originalInfo);
  }

  checkNameExists() {
    if (!this.lessorInfo.name) {
      this.warnings.name = "Required";
      return;
    }
    if (!this.isCreating) return;
    this.lessorDataService.getLessorByName(this.lessorInfo.name).subscribe(
      (result) => {
        if (result) {
          this.warnings.name = "A lessor with this name already exists";
        } else {
          this.warnings.name = false;
        }
      },
      (err) => {
        this.toastr.warning("Error creating Lessor!", "Alert!");
      }
    );
  }

  checkIdExists() {
    if (!this.lessorInfo.lessorId) {
      this.warnings.lessorId = "Required";
      return;
    }
    if (!this.isCreating) return;
    this.lessorDataService.getLessorByLessorId(this.lessorInfo.lessorId).subscribe(
      (result) => {
        if (result) {
          this.warnings.lessorId = "A lessor with this id already exists";
        } else {
          this.warnings.lessorId = false;
        }
      },
      (err) => {
        this.toastr.warning("Error creating Lessor!", "Alert!");
      }
    );
  }

  checkRequiredFields() {
    this.attemptedCreate = true;

    // fill in the state from the selected state
    if (!this.selectedState) return false;
    this.lessorInfo.state = this.selectedState.fullName;

    const required = [
      "lessorId",
      "name",
      "lessorId",
      "phone",
      "email",
      "address",
      "city",
      "state",
      "zip",
      "ein",
    ];

    for (const field of required) {
      if (!this.lessorInfo[field]) {
        console.error("missing", field);
        return false;
      }
    }
    return true;
  }
}
