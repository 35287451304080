<div class="row form-group">
  <label class="col-md-2">Apply To</label>
  <div class="col-md-4">
    <p-dropdown
      *ngIf="isEditing; else applyToPreview"
      [options]="applyToOptions"
      [(ngModel)]="rate.applyTo"
      (onChange)="onApplyToChanged()"
      [style]="{ minWidth: '100%' }"
      aria-label="applyTo"
      tabbableDropdown
    ></p-dropdown>
    <ng-template #applyToPreview>
      <div>
        {{ rate.applyTo }}
      </div>
    </ng-template>
  </div>
</div>
<div class="row form-group" *ngIf="rate.applyTo !== 'BILL TO'">
  <label class="col-md-2">Drivers</label>
  <div class="col-md-4">
    <app-drivers-selector
      [isEditing]="isEditing"
      [drivers]="drivers"
      [(isDefaultDriver)]="rate.isDefaultDriver"
      [(selectedDrivers)]="selectedDrivers"
      aria-label="drivers"
    ></app-drivers-selector>
  </div>
</div>
<hr />

<app-condition-editor [(conditions)]="conditions" [isEditing]="isEditing"></app-condition-editor>

<hr />

<div class="row form-group" *ngIf="rate.applyTo !== 'DRIVER'">
  <label class="col-md-2">Client</label>
  <div class="col-md-10">
    <div class="row form-group">
      <label class="col-md-2">Charge Per</label>
      <div class="col-md-4">
        <p-dropdown
          [options]="chargePerOptions"
          [(ngModel)]="client.chargePer"
          (onChange)="updateComparisonOptions(client)"
          [style]="{ minWidth: '100%' }"
          [disabled]="!isEditing"
          tabbableDropdown
          aria-label="clientChargePer"
        ></p-dropdown>
      </div>
      <label class="col-md-2">Type</label>
      <div class="col-md-4">
        <p-dropdown
          [options]="typeOptions"
          [(ngModel)]="client.flatOrVariable"
          [style]="{ minWidth: '100%' }"
          [disabled]="!isEditing"
          tabbableDropdown
          aria-label="clientType"
        ></p-dropdown>
      </div>
    </div>
    <div *ngIf="client.flatOrVariable === 'VARIABLE'" class="row form-group">
      <label *ngIf="clientComparisonOptions" class="col-md-2">Comparison Field</label>
      <div *ngIf="clientComparisonOptions" class="col-md-4">
        <p-dropdown
          [options]="clientComparisonOptions"
          [(ngModel)]="client.units"
          [style]="{ minWidth: '100%' }"
          [disabled]="!isEditing"
          tabbableDropdown
        ></p-dropdown>
      </div>
      <label class="col-md-2">Free</label>
      <div class="col-md-4">
        <input
          *ngIf="isEditing; else clientFree"
          type="number"
          min="0"
          class="form-control input-md"
          [(ngModel)]="client.free"
          aria-label="clientFree"
        />
        <ng-template #clientFree>
          <div>{{ client.free }}</div>
        </ng-template>
      </div>
    </div>
    <div class="row form-group">
      <label *ngIf="client.flatOrVariable === 'VARIABLE'" class="col-md-2">Interval</label>
      <div *ngIf="client.flatOrVariable === 'VARIABLE'" class="col-md-4">
        <input
          *ngIf="isEditing; else clientInterval"
          type="number"
          min="0"
          class="form-control input-md"
          [(ngModel)]="client.interval"
         aria-label="clientInterval"
        />
        <ng-template #clientInterval>
          <div>{{ client.interval }}</div>
        </ng-template>
      </div>
      <label class="col-md-2">Rate</label>
      <div class="col-md-4">
        <input
          *ngIf="isEditing; else clientRate"
          type="text"
          class="form-control input-md"
          [(ngModel)]="client.rate"
          aria-label="clientRate"
        />
        <ng-template #clientRate>
          <div>${{ client.rate }}</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<hr *ngIf="rate.applyTo !== 'DRIVER'" />

<div class="row form-group" *ngIf="rate.applyTo !== 'BILL TO'">
  <label class="col-md-2">Driver</label>
  <div class="col-md-10">
    <div class="row form-group">
      <label class="col-md-2">Charge Per</label>
      <div class="col-md-4">
        <p-dropdown
          [options]="chargePerOptions"
          [(ngModel)]="driver.chargePer"
          (onChange)="updateComparisonOptions(driver)"
          [style]="{ minWidth: '100%' }"
          [disabled]="!isEditing"
          tabbableDropdown
          aria-label="driverChargePer"
        ></p-dropdown>
      </div>
      <label class="col-md-2">Type</label>
      <div class="col-md-4">
        <p-dropdown
          [options]="typeOptions"
          [(ngModel)]="driver.flatOrVariable"
          [style]="{ minWidth: '100%' }"
          [disabled]="!isEditing"
          tabbableDropdown
         aria-label="driverType"
        ></p-dropdown>
      </div>
    </div>
    <div *ngIf="driver.flatOrVariable === 'VARIABLE'" class="row form-group">
      <label *ngIf="driverComparisonOptions" class="col-md-2">Comparison Field</label>
      <div *ngIf="driverComparisonOptions" class="col-md-4">
        <p-dropdown
          [options]="driverComparisonOptions"
          [(ngModel)]="driver.units"
          [style]="{ minWidth: '100%' }"
          [disabled]="!isEditing"
          tabbableDropdown
        ></p-dropdown>
      </div>
      <label class="col-md-2">Free</label>
      <div class="col-md-4">
        <input
          *ngIf="isEditing; else driverFree"
          type="number"
          min="0"
          class="form-control input-md"
          [(ngModel)]="driver.free"
          aria-label="driverFree"
        />
        <ng-template #driverFree>
          <div>{{ driver.free }}</div>
        </ng-template>
      </div>
    </div>
    <div class="row form-group">
      <label *ngIf="driver.flatOrVariable === 'VARIABLE'" class="col-md-2">Interval</label>
      <div *ngIf="driver.flatOrVariable === 'VARIABLE'" class="col-md-4">
        <input
          *ngIf="isEditing; else driverInterval"
          type="number"
          min="0"
          class="form-control input-md"
          [(ngModel)]="driver.interval"
          aria-label="driverInterval"
        />
        <ng-template #driverInterval>
          <div>{{ driver.interval }}</div>
        </ng-template>
      </div>
      <label class="col-md-2">Rate</label>
      <div class="col-md-4">
        <input
          *ngIf="isEditing; else driverRate"
          type="text"
          class="form-control input-md"
          [(ngModel)]="driver.rate"
          aria-label="driverRate"
        />
        <ng-template #driverRate>
          <div>${{ driver.rate }}</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
