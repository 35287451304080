import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChargetypesService } from "@app/chargeType/shared/chargetypes.service";
import { ChargeType } from "@app/shared/models/ChargeType.model";
import { ToastrService } from "ngx-toastr";
import { SelectItem } from "primeng/api";

@Component({
  selector: "app-settlement-fuel-tab",
  templateUrl: "./settlement-fuel-tab.component.html",
  styleUrls: ["../loads.component.css"],
})
export class SettlementFuelTabComponent implements OnInit {
  @Input() advances!: any[];
  @Output() advanceChanged = new EventEmitter<any>();
  @Input() advanceTotals!: any;
  @Input() batchSelected!: any;

  tchekChargeType: ChargeType;
  chargeTypes: SelectItem;

  cols = [
    { field: "authorizationCode", header: "Code", type: "code" },
    { field: "date", header: "Date", type: "date" },
    { field: "name", header: "Location", type: "string" },
    { field: "city", header: "City", type: "string" },
    { field: "state", header: "State", type: "string" },
    { field: "recordType", header: "Type", type: "string" },
    { field: "tractorUnitNumber", header: "Unit", type: "string" },
    { field: "fuelQuantity1", header: "Gallons", type: "number" },
    { field: "fuelPPU1", header: "PPG", type: "currency" },
    { field: "fuelAmount1", header: "Cost", type: "currency" },
    { field: "consolidatedOthers", header: "Other", type: "currency" },
    { field: "consolidatedCash", header: "Cash", type: "currency" },
    { field: "discountAmount", header: "Discount", type: "currency" },
    { field: "customerFee", header: "Fee", type: "currency" },
    { field: "oilAmount", header: "Oil", type: "currency" },
    { field: "express", header: "Express", type: "currency" },
    { field: "calculatedDriverAmount", header: "Driver Total", type: "currency" },
    { field: "calculatedLGTAmount", header: "LGT Total", type: "currency" },
    { field: "calculatedTotalAmount", header: "Net", type: "currency" },
  ];

  constructor(
    private _ChargeTypeService: ChargetypesService,
    private _ActivatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    const resolvers = this._ActivatedRoute.snapshot.data;
    this.chargeTypes = resolvers.chargeTypes;
    const globalChargeTypes = resolvers.globalChargeTypes;
    const tchekChargeType = globalChargeTypes.find((gct) => gct.type === "TCHEK");
    if (tchekChargeType) {
      this.tchekChargeType = tchekChargeType.ChargeType;
    }
  }

  onTchekChanged(newValue: string | number, advance: any, field: string) {
    const oldValue = advance[field];
    const key = `${field}OriginalValue`;
    if (advance[key] === undefined) {
      advance[key] = oldValue;
    }
    advance[`${field}Changed`] = advance[key] !== newValue;
    // flag this tchek to be updated when we save
    advance.softUpdate = true;
    advance[field] = newValue;
  }

  saveTchekChargeType() {
    this._ChargeTypeService.updateGlobalChargeType("TCHEK", this.tchekChargeType.id).subscribe(
      () => {
        this.toastr.success("Tchek Charge Type Succesfully Updated.", "Success");
      },
      (error) => {
        console.dir(error);
        this.toastr.error(error, "Error");
      }
    );
  }

  openFuelExpensesGrid(tcheck: any) {
    const { batchId, authorizationCode } = tcheck;
    // if the batch is not active, go to the historical screen
    let url = "";
    if (this.batchSelected.status !== "ACTIVE") {
      url = `/tchekHistorical?batchId=${batchId}`;
    } else {
      // go to either fuel expense or express codes depending on auth code
      const authCodeFirstChar = authorizationCode.charAt(0);
      if (authCodeFirstChar === "A") {
        url = `/settlements/driverExpenses?batchId=${batchId}`;
      } else {
        url = `/settlements/companyExpenses?batchId=${batchId}`;
      }
    }
    window.open(url, "_blank");
  }
}
