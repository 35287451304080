import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ClientProductService } from "./clientProduct.service";

@Injectable()
export class ClientProductsResolver {
  constructor(private ClientProductService: ClientProductService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.ClientProductService.getClientProductList();
  }
}
