<div class="container">
  <div class="col-md-12">
    <legend>Product Information</legend>
    <div class="btnRig">
      <a (click)="viewDetail(null)">
        <button
          id="edit"
          name="edit"
          class="btn btn-primary"
          *ngIf="hasPermission('ProductsChange')"
        >
          Add New Product
        </button>
      </a>
    </div>
    <div style="display: inline-block">
      <p-table
        class="table-dark"
        #dtProductInformationTable
        [columns]="colsProductInformation"
        [value]="products"
        [autoLayout]="true"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr class="clickable-row" (dblclick)="viewDetail(product)">
            <td>{{ product.name }}</td>
            <td>{{ product.un }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="addProductModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addProductLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addProductLabel">
          {{ selProduct && selProduct.id ? "Product Details" : "Add New Product" }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="selProduct">
        <div *ngIf="modalError.length" class="alert alert-block alert-danger">
          {{ modalError }}
        </div>
        <div>
          <div class="form-group row">
            <label class="col-sm-4 control-label">Product Name</label>
            <div class="col-sm-8">
              <input
                id="name"
                name="name"
                type="text"
                [disabled]="is_edit"
                [(ngModel)]="selProduct.name"
                placeholder="Insert Product Name"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 control-label" for="un">UN Number</label>
            <div class="col-sm-8">
              <input
                id="un"
                name="un"
                type="text"
                [disabled]="is_edit"
                [(ngModel)]="selProduct.un"
                placeholder="Insert UN #"
                class="form-control"
              />
            </div>
          </div>
          <br />
          <h5 class="text-center">
            Per
            <select [(ngModel)]="conversionUnit" (change)="updateConversion()">
              <option>Pound</option>
              <option>Ton</option>
            </select>
          </h5>
          <ng-container *ngIf="conversionUnit == 'Pound'">
            <div class="form-group row">
              <label class="col-sm-4 control-label">Standard Cubic Feet</label>
              <div class="col-sm-8">
                <input
                  id="scf"
                  name="scf"
                  type="number"
                  [disabled]="is_edit"
                  [(ngModel)]="selProduct.scfPerPound"
                  (change)="updateUnits('Pound')"
                  placeholder="0"
                  step="0.001"
                  min="0"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 control-label">Gallons</label>
              <div class="col-sm-8">
                <input
                  id="gallons"
                  name="gallons"
                  type="number"
                  [disabled]="is_edit"
                  [(ngModel)]="selProduct.gallonsPerPound"
                  (change)="updateUnits('Gallon')"
                  placeholder="0"
                  step="0.001"
                  min="0"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 control-label">Liters</label>
              <div class="col-sm-8">
                <input
                  id="liters"
                  name="liters"
                  type="number"
                  [disabled]="is_edit"
                  [(ngModel)]="selProduct.litersPerPound"
                  (change)="updateUnits('Liter')"
                  placeholder="0"
                  step="0.001"
                  min="0"
                  class="form-control"
                />
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="conversionUnit == 'Ton'">
            <div class="form-group row">
              <label class="col-sm-4 control-label">Standard Cubic Feet</label>
              <div class="col-sm-8">
                <input
                  id="scf"
                  name="scf"
                  type="number"
                  [disabled]="is_edit"
                  [(ngModel)]="selProduct.scfPerTon"
                  (change)="updateUnits('Ton')"
                  placeholder="0"
                  step="0.001"
                  min="0"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 control-label">Gallons</label>
              <div class="col-sm-8">
                <input
                  id="gallons"
                  name="gallons"
                  type="number"
                  [disabled]="is_edit"
                  [(ngModel)]="selProduct.gallonsPerTon"
                  (change)="updateUnits('Gallon', 'Ton')"
                  placeholder="0"
                  step="0.001"
                  min="0"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 control-label">Liters</label>
              <div class="col-sm-8">
                <input
                  id="liters"
                  name="liters"
                  type="number"
                  [disabled]="is_edit"
                  [(ngModel)]="selProduct.litersPerTon"
                  (change)="updateUnits('Liter', 'Ton')"
                  placeholder="0"
                  step="0.001"
                  min="0"
                  class="form-control"
                />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="modalElem btn btn-secondary" data-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          class="modalElem btn btn-danger"
          (click)="delete()"
          [attr.data-dismiss]="!modalError.length ? 'modal' : ''"
          *ngIf="hasPermission('ProductsChange') && selProduct && selProduct.id"
        >
          Delete
        </button>
        <button
          type="button"
          class="modalElem btn btn-primary"
          (click)="editOn()"
          [attr.data-dismiss]="!modalError.length ? 'modal' : ''"
          *ngIf="hasPermission('ProductsChange')"
        >
          {{ btn }}
        </button>
      </div>
    </div>
  </div>
</div>
<script-hack
  >$('.modal-content').draggable({{ "{" }}'handle':'.modal-header'{{ "}" }});</script-hack
>
