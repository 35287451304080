<div class="col-md-12 no-padding">
  <legend>PC Miler</legend>
  <div class="row">
    <div class="col-md-2">API KEY</div>
    <div class="col-md-3">
      <input
        [(ngModel)]="newValues.apiKey"
        (ngModelChange)="dataChanged('apiKey')"
        name="PcMilerAPIKey"
        class="form-control"
        id="PcMilerAPIKey"
        type="text"
      />
    </div>
    <div class="col-md-3">API provided by PcMiler (ALKmaps)</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Vehicle Type</div>
    <div class="col-md-3">
      <p-dropdown
        [style]="{ width: '100%' }"
        (onChange)="dataChanged('vehicleType')"
        [options]="vehicleTypeOptions"
        [(ngModel)]="newValues.vehicleType"
        name="PcMilerVehicleType"
        id="PcMilerVehicleType"
        tabbableDropdown
      >
      </p-dropdown>
    </div>
    <div class="col-md-4">
      Restricts the route to roads that are accessible by the selected vehicle type.
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Route Type</div>
    <div class="col-md-3">
      <p-dropdown
        [style]="{ width: '100%' }"
        (onChange)="dataChanged('routingType')"
        [options]="routingTypeOptions"
        [(ngModel)]="newValues.routingType"
        name="PcMilerroutingType"
        id="PcMilerroutingType"
        tabbableDropdown
      >
      </p-dropdown>
    </div>
    <div class="col-md-4">Generate the route to match the selected type.</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Route Optimization</div>
    <div class="col-md-3">
      <p-dropdown
        [style]="{ width: '100%' }"
        (onChange)="dataChanged('routeOptimization')"
        [options]="routeOptimizationOptions"
        [(ngModel)]="newValues.routeOptimization"
        name="PcMilerrouteOptimization"
        id="PcMilerrouteOptimization"
        tabbableDropdown
      >
      </p-dropdown>
    </div>
    <div class="col-md-4">Generate the route to match the selected optimization.</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Toll Roads</div>
    <div class="col-md-3">
      <p-dropdown
        [style]="{ width: '100%' }"
        (onChange)="dataChanged('tollRoads')"
        [options]="tollRoadsOptions"
        [(ngModel)]="newValues.tollRoads"
        name="TollRoads"
        id="TollRoads"
        tabbableDropdown
      >
      </p-dropdown>
    </div>
    <div class="col-md-4">Whether or not to avoid toll roads when generating a route</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Borders Open</div>
    <div class="col-md-3 text-PcMiler">
      <div class="margin-input-switch">
        <p-checkbox
          (onChange)="dataChanged('bordersOpen')"
          [(ngModel)]="newValues.bordersOpen"
          name="PcMilerBordersOpen"
          id="PcMilerBordersOpen"
          binary="true"
        >
        </p-checkbox>
      </div>
    </div>
    <div class="col-md-4">Indicates whether borders are open for travel.</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Restrictions</div>
    <div class="col-md-3 text-PcMiler">
      <p-toggleButton
        (onChange)="dataChanged('overrideRestrict')"
        [(ngModel)]="newValues.overrideRestrict"
        name="PcMilerOverrideRestrict"
        id="PcMilerOverrideRestrict"
        onLabel="Override"
        offLabel="Use"
      >
      </p-toggleButton>
    </div>
    <div class="col-md-4">Indicates whether or not to override truck restrictions.</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Roads</div>
    <div class="col-md-3 text-PcMiler">
      <p-toggleButton
        (onChange)="dataChanged('highwayOnly')"
        [(ngModel)]="newValues.highwayOnly"
        name="PcMilerHighwayOnly"
        id="PcMilerHighwayOnly"
        onLabel="HighwayOnly"
        offLabel="Highways & Streets"
      >
      </p-toggleButton>
    </div>
    <div class="col-md-4">Restricts the route to only highway roads.</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">HazMat Type</div>
    <div class="col-md-3">
      <p-dropdown
        [style]="{ width: '100%' }"
        (onChange)="dataChanged('hazMatType')"
        [options]="hazMatTypeOptions"
        [(ngModel)]="newValues.hazMatType"
        name="PcMilerHazMatType"
        id="PcMilerHazMatType"
        type="text"
        tabbableDropdown
      >
      </p-dropdown>
    </div>
    <div class="col-md-4">
      Restricts the route to roads that allow transportation of the selected hazardous material.
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Side Of Street Adherence</div>
    <div class="col-md-3">
      <p-dropdown
        [style]="{ width: '100%' }"
        (onChange)="dataChanged('sideOfStreetAdherence')"
        [options]="sideOfStreetAdherenceOptions"
        [(ngModel)]="newValues.sideOfStreetAdherence"
        name="PcMilerSideOfStreetAdherence"
        id="PcMilerSideOfStreetAdherence"
        tabbableDropdown
      >
      </p-dropdown>
    </div>
    <div class="col-md-4">
      How strict to be in order to avoid the destination being on the opposite side of the street.
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Include Ferry Distance</div>
    <div class="col-md-3 text-PcMiler">
      <div class="margin-input-switch">
        <p-checkbox
          (onChange)="dataChanged('includeFerryDistance')"
          [(ngModel)]="newValues.includeFerryDistance"
          name="PcMilerInclFerryDist"
          id="PcMilerInclFerryDist"
          binary="true"
        >
        </p-checkbox>
      </div>
    </div>
    <div class="col-md-4">
      Indicates whether or not to include ferry distances in mileage calculations.
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Ferry Discourage</div>
    <div class="col-md-3 text-PcMiler">
      <div class="margin-input-switch">
        <p-checkbox
          (onChange)="dataChanged('ferryDiscourage')"
          [(ngModel)]="newValues.ferryDiscourage"
          name="PcMilerFerryDiscourage"
          id="PcMilerFerryDiscourage"
          binary="true"
        >
        </p-checkbox>
      </div>
    </div>
    <div class="col-md-4">
      Indicates whether or not to discourage the use of ferries when creating the route.
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Vehicle Height</div>
    <div class="col-md-3">
      <input
        [(ngModel)]="newValues.trkHeight"
        (ngModelChange)="dataChanged('trkHeight')"
        name="PcMilerTruckHeight"
        class="form-control"
        id="PcMilerTruckHeight"
        type="number"
      />
    </div>
    <div class="col-md-4">The height of the vehicle in inches.</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Vehicle Length</div>
    <div class="col-md-3">
      <input
        [(ngModel)]="newValues.trkLength"
        (ngModelChange)="dataChanged('trkLength')"
        name="PcMilerTruckLength"
        class="form-control"
        id="PcMilerTruckLength"
        type="number"
      />
    </div>
    <div class="col-md-4">The length of the vehicle in inches.</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Vehicle Width</div>
    <div class="col-md-3">
      <input
        [(ngModel)]="newValues.trkWidth"
        (ngModelChange)="dataChanged('trkWidth')"
        name="PcMilerTruckWidth"
        class="form-control"
        id="PcMilerTruckWidth"
        type="number"
      />
    </div>
    <div class="col-md-4">The width of the vehicle in inches.</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Vehicle Weight</div>
    <div class="col-md-3">
      <input
        [(ngModel)]="newValues.trkWeight"
        (ngModelChange)="dataChanged('trkWeight')"
        name="PcMilerTruckWeight"
        class="form-control"
        id="PcMilerTruckWeight"
        type="number"
      />
    </div>
    <div class="col-md-4">The weight of the vehicle in pounds.</div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-2">Vehicle Axles</div>
    <div class="col-md-3">
      <input
        [(ngModel)]="newValues.trkAxles"
        (ngModelChange)="dataChanged('trkAxles')"
        name="PcMilerTruckAxles"
        class="form-control"
        id="PcMilerTruckAxles"
        type="number"
        min="2"
        max="14"
      />
    </div>
    <div class="col-md-4">
      The number of axles on the vehicle; acceptable values are 2 through 14.
    </div>
  </div>
  <div class="row btn-PcMiler">
    <button
      type="button"
      class="btn btn-warning btn-PcMiler active btn-width"
      (click)="save()"
      [disabled]="!isDataChanged"
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-secondary btn-PcMiler active btn-width"
      (click)="cancel()"
      [disabled]="!isDataChanged"
    >
      Cancel
    </button>
  </div>
</div>
