import { Component, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-sidebar-component",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  isLogin: boolean;
  constructor(private router: Router, private _AuthService: authenticationService) {}
  isDis: boolean = false;

  ngOnInit() {
    const loginUrls = ["/login", "/reset"];
    if (loginUrls.some(url => this.router.url.startsWith(url))) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.isLogin = loginUrls.some(url => event.url.startsWith(url));
      });
  }

  hasPermission(permissionName: string) {
    
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  sliderFunctionality() {
    if (this.isDis == false) {
      this.isDis = true;
      document.getElementById("navSidebar").style.transform = "translate(210px, 0px)";
    } else {
      this.isDis = false;
      document.getElementById("navSidebar").style.transform = "translate3d(0px, 0px, 0px)";
    }
  }
}
