import { Injectable } from '@angular/core';

const expirationTimeInMinutes = 5;
@Injectable()
export class TemporalFormsService {

    constructor () {
    }

    isTemporalFormExpired(date1, date2) {
        var utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getHours(), date1.getMinutes());
        var utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getHours(), date2.getMinutes());

       if (Math.floor((utc2 - utc1) / (1000 * 60 * 60)) >= expirationTimeInMinutes) {
         return true;
       }else {
        return false;
       }
    }

    saveChange(formName, model) {
        const temporalForms = (JSON.parse(localStorage.getItem('temporalForms')) || {});
        if (!temporalForms[formName]) {
            temporalForms[formName] = {};
        }
        temporalForms[formName][Object.keys(model)[0]] = model[Object.keys(model)[0]]; // this line can be improved with an Object.assing
        temporalForms[formName].lastFormModificationEvent = new Date();
        localStorage.setItem('temporalForms', JSON.stringify(temporalForms));
    }

    retrieveFormModel(formName, modelName) {
        const temporalForms = (JSON.parse(localStorage.getItem('temporalForms')));
        if (!temporalForms || !temporalForms[formName] || !temporalForms[formName][modelName]) {
            return undefined;
        }
        if (this.isTemporalFormExpired(new Date(temporalForms[formName].lastFormModificationEvent), new Date())) {
             delete temporalForms[formName];
             localStorage.setItem('temporalForms', JSON.stringify(temporalForms));
             return undefined;
        }
        const model = temporalForms[formName][modelName];
        return model;
    }
    clearForm(formName) {
        const temporalForms = (JSON.parse(localStorage.getItem('temporalForms')));
        if (!temporalForms || !temporalForms[formName]) {
            return undefined;
        }
        delete temporalForms[formName];
        localStorage.setItem('temporalForms', JSON.stringify(temporalForms));
    }


}
