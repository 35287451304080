import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { BillToViewModel } from "@app/shared/models/clients/BillToViewModel.model";

@Component({
  selector: "app-add-bill-tos",
  templateUrl: "./add-bill-tos.component.html",
  styleUrls: ["./add-bill-tos.component.css"],
})
export class AddBillTosComponent implements OnInit {
  @ViewChild("closebutton") closebutton;

  @Input() isEditing: boolean;
  @Input() isDefaultBillTo: boolean = false;
  @Output() isDefaultBillToChange = new EventEmitter();
  @Input() billTos: any[];
  @Output() billTosChange = new EventEmitter();

  clients = [];
  allBillTos: BillToViewModel[] = [];
  clientBillTos: BillToViewModel[] = [];

  billToForm = new UntypedFormGroup({
    billTo: new UntypedFormControl({ value: "", disabled: true }, Validators.required),
    selectedClient: new UntypedFormControl("", Validators.required),
  });

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.clients = this.route.snapshot.data["clients"];
    this.allBillTos = this.route.snapshot.data["billToFullList"];
  }

  get billTo() {
    return this.billToForm.get("billTo");
  }

  get selectedClient() {
    return this.billToForm.get("selectedClient");
  }

  onClientSelected() {
    if (this.selectedClient == null) {
      this.clientBillTos = [];
    } else {
      this.clientBillTos = this.allBillTos.filter(
        (billTo) => billTo.clientId === this.selectedClient.value.id
      );
    }
    this.billTo.setValue("");
    this.billTo.enable();
  }

  addBillTo(newBillTo: any) {
    if (this.billTos.some((billTo) => billTo.id === newBillTo.id)) return;
    this.billTos.push(newBillTo);
    this.billTosChange.emit(this.billTos);
  }

  removeBillTo(billToDelete: any) {
    this.billTos = this.billTos.filter((billTo) => billTo.id != billToDelete.id);
    this.billTosChange.emit(this.billTos);
  }

  close() {
    this.closebutton.nativeElement.click();
  }

  onSubmit() {
    if (this.billToForm.invalid) return;
    for (const billTo of this.billTo.value) {
      this.addBillTo(billTo);
    }
    this.close();
  }
}
