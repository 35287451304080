import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { SelectItem } from "primeng/api";
import { PcMilerService } from "./shared/pcMiler.service";

@Component({
  selector: "app-pc-miller",
  templateUrl: "./pcMiler.component.html",
  styleUrls: ["./pcMiler.component.css"],
})
export class PcMilerComponent implements OnInit {
  selectedVersion: any;
  versions: Array<any>;
  sideOfStreetAdherenceOptions: SelectItem[];
  hazMatTypeOptions: SelectItem[];
  routeOptimizationOptions: SelectItem[];
  routingTypeOptions: SelectItem[];
  vehicleTypeOptions: SelectItem[];
  tollRoadsOptions: SelectItem[];
  newValues: any = {};
  isDataChanged: boolean;
  attributesChanged: any;
  lastVersion: any;
  @Output("isGuardActive") isGuardActive = new EventEmitter();

  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _ToastrService: ToastrService,
    private _PcMilerService: PcMilerService
  ) {}

  ngOnInit() {
    this.attributesChanged = [];
    this.isDataChanged = false;
    this.isGuardActive.emit(this.isDataChanged);
    this.selectedVersion = this._ActivatedRoute.snapshot.data.PcMilerSettings.currentSettings;
    this.lastVersion = Number(this._ActivatedRoute.snapshot.data.version);
    this.newValues = JSON.parse(JSON.stringify(this.selectedVersion));
    this.versions = this._ActivatedRoute.snapshot.data.PcMilerSettings.versions;

    this.sideOfStreetAdherenceOptions = [
      { label: "Adhere", value: "5" },
      { label: "Average", value: "3" },
      { label: "Minimal", value: "1" },
      { label: "Moderate", value: "2" },
      { label: "Off", value: "0" },
      { label: "Strict", value: "4" },
      { label: "Strongly Adhere", value: "6" },
    ];
    this.hazMatTypeOptions = [
      { label: "Caustic", value: "2" },
      { label: "Explosives", value: "3" },
      { label: "Flammable", value: "4" },
      { label: "General", value: "1" },
      { label: "Inhalants", value: "5" },
      { label: "None", value: "0" },
      { label: "Radioactive", value: "6" },
      { label: "Harmful To Water", value: "7" },
      { label: "Tunnel", value: "8" },
    ];

    this.routeOptimizationOptions = [
      { label: "None", value: "0" },
      { label: "Optimize All Stops ", value: "1" },
      { label: "Optimize Intermediate Stops", value: "2" },
    ];
    this.routingTypeOptions = [
      { label: "Fastest", value: "2" },
      { label: "Practical", value: "0" },
      { label: "Shortest", value: "1" },
    ];
    this.vehicleTypeOptions = [
      { label: "Automobile", value: "2" },
      { label: "Light Truck", value: "1" },
      { label: "Truck", value: "0" },
    ];
    this.tollRoadsOptions = [
      { label: "Use", value: "3" },
      { label: "Avoid if Possible", value: "2" },
      { label: "Always Avoid", value: "1" },
    ];
  }

  dataChanged(attribute) {
    if (String(this.selectedVersion[attribute]) !== String(this.newValues[attribute])) {
      if (!this.attributesChanged.includes(attribute)) {
        this.attributesChanged.push(attribute);
      }
    } else {
      if (this.attributesChanged.includes(attribute)) {
        this.attributesChanged = this.attributesChanged.filter(
          (attributeChangedAleady) => attributeChangedAleady !== attribute
        );
      }
    }
    if (this.attributesChanged.length > 0) {
      this.isDataChanged = true;
    } else {
      this.isDataChanged = false;
    }
    this.isGuardActive.emit(this.isDataChanged);
  }

  cancel() {
    this.newValues = JSON.parse(JSON.stringify(this.selectedVersion));
    this.attributesChanged = [];
    this.isDataChanged = false;
    this.isGuardActive.emit(this.isDataChanged);
  }

  save() {
    this._PcMilerService.update(this.newValues).subscribe(
      () => {
        this.selectedVersion = JSON.parse(JSON.stringify(this.newValues));
        this.attributesChanged = [];
        this.isDataChanged = false;
        this.isGuardActive.emit(this.isDataChanged);
        this.lastVersion++;
        this.selectedVersion++;
        this.versions.push(this.lastVersion);
        this._ToastrService.success("PcMiler config updated", "Success");
        this._ActivatedRoute.snapshot.data.PcMilerSettings.currentSettings = JSON.parse(
          JSON.stringify(this.newValues)
        );
      },
      (error) => {
        this._ToastrService.error(error, "Error");
      }
    );
  }
}
