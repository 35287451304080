export class ChargeType {
  id: string;
  name: string;
  description: string;
  revenueGL: string;
  expenseGL: string;
  otherGL?: string;
  isAccessorial: boolean;
  isDeleted?: boolean;
}
