<div class="container">
  <div class="col-md-12">
    <legend>
      T-Chek Express Codes
      <span *ngIf="startDate">
        ({{ startDate | date : "MM/dd/yyyy" }} - {{ endDate | date : "MM/dd/yyyy" }})
      </span>
    </legend>

    <div class="d-flex" style="margin-bottom: 1rem" *ngIf="batchesList.length > 0">
      <div class="mr-3">
        <div>
          <p-dropdown
            [options]="batchesList"
            [(ngModel)]="selectedBatch"
            [filter]="true"
            optionLabel="itemName"
            filterBy="itemName"
            (onChange)="refreshRecords()"
            tabbableDropdown
          ></p-dropdown>
        </div>
      </div>
      <div>
        <button
          pButton
          type="button"
          icon="fa fa-file-excel-o"
          title="Export to Excel"
          label="Export to Excel"
          (click)="excelGeneration()"
          class="p-button-secondary"
          style="margin-left: 1rem"
        ></button>
      </div>
    </div>

    <div *ngIf="!loading && batchesList.length === 0">
      <p>No active batch found.</p>
    </div>
    <div *ngIf="batchesList.length > 0 && (records.length > 0 || loadingRecords)">
      <app-table-company-expenses
        #tableReport
        [records]="records"
        [batchNo]="selectedBatch?.batchNo"
        (onRecordsSaved)="refreshRecords()"
      ></app-table-company-expenses>
    </div>
  </div>
</div>
