<button type="button" class="btn btn-default" (click)="updateRecords()" style="margin-bottom: 1rem">
  SAVE
</button>
<div>
  <p-table
    #dt
    [columns]="cols"
    [value]="records"
    [scrollable]="true"
    scrollHeight="775px"
    [virtualScroll]="true"
    [virtualScrollItemSize]="55"
    stateStorage="session"
    stateKey="driverExpenses"
    [loading]="!records || records.length === 0"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width.px]="col.width" />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
        </th>
      </tr>
      <tr>
        <th *ngFor="let col of columns">
          <input
            *ngIf="col.filter != 'hide'"
            type="text"
            style="width: 100%"
            (input)="$any(dt).filter($event.target.value, col.field, 'contains')"
            [value]="$any(dt).filters[col.field]?.value"
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-i="rowIndex" let-record>
      <tr>
        <ng-template ngFor let-col [ngForOf]="cols">
          <td *ngIf="col.type === 'driverLink'" style="text-overflow: clip">
            <img
              *ngIf="record.driverID && record.batchNo"
              style="cursor: pointer"
              src="assets/img/details.svg"
              (click)="openDriverSettlement(record.driverID, record.batchNo)"
            />
          </td>
          <td *ngIf="col.type === 'code'">
            {{ record[col.field] }}
          </td>
          <td *ngIf="col.type === 'isNonHighway'">
            {{ record.isNonHighwayTransaction ? "✔" : "" }}
          </td>
          <td *ngIf="col.type === 'date'">
            {{ record[col.field] | date : "MM/dd/yyyy" }}
          </td>
          <td *ngIf="col.type === 'delete'">
            <button
              pButton
              type="button"
              icon="pi pi-trash"
              class="p-button-danger"
              (click)="deleteRecord(record)"
              title="Delete Record"
            ></button>
          </td>
          <td
            *ngIf="col.type === 'string' || col.type === 'currency' || col.type === 'number'"
            [ngClass]="{ 'align-right': col.type === 'currency' || col.type === 'number' }"
            class="editable-cell"
            [pEditableColumn]="record"
            [pEditableColumnField]="record[col.field]"
          >
            <p-cellEditor class="elements-table">
              <ng-template pTemplate="input">
                <input
                  *ngIf="col.type === 'string'"
                  style="width: 100%"
                  type="text"
                  [ngModel]="record[col.field]"
                  (ngModelChange)="onRecordChanged($event, record, col.field)"
                  [title]="record[col.field]"
                />
                <input
                  *ngIf="col.type === 'number' || col.type === 'currency'"
                  style="width: 100%; text-align: right"
                  type="number"
                  min="0"
                  [ngModel]="record[col.field]"
                  (ngModelChange)="onRecordChanged($event, record, col.field)"
                  [title]="record[col.field]"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <span
                  *ngIf="col.type === 'string'"
                  [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
                  [title]="record[col.field]"
                >
                  {{ record[col.field] }}
                </span>
                <span
                  *ngIf="col.type === 'number'"
                  [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
                  [title]="record[col.field]"
                >
                  {{ record[col.field] | number }}
                </span>
                <span
                  *ngIf="col.type === 'currency'"
                  [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
                  [title]="record[col.field]"
                >
                  {{ record[col.field] | currency }}
                </span>
              </ng-template>
            </p-cellEditor>
          </td>
        </ng-template>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer" let-columns>
      <tr>
        <ng-template ngFor let-total [ngForOf]="totals">
          <td *ngIf="total != null">
            {{ total | currency }}
          </td>
          <td *ngIf="total == null"></td>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
</div>
