import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { PdfViewerModule } from "ng2-pdf-viewer";

import { PasswordModule } from "primeng/password";
import { CalendarModule } from "primeng/calendar";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputMaskModule } from "primeng/inputmask";
import { RadioButtonModule } from "primeng/radiobutton";
import { CheckboxModule } from "primeng/checkbox";
import { AccordionModule } from "primeng/accordion";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ToggleButtonModule } from "primeng/togglebutton";
import { PaginatorModule } from "primeng/paginator";
import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { DatePipe } from "@angular/common";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService } from "primeng/api";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ToolbarModule } from "primeng/toolbar";
import { InputNumberModule } from "primeng/inputnumber";
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog";
import { CurrencyPipe } from "@angular/common";

import { CreateDocumentComponent } from "./components/document/createDocument/createDocument.component";
import { ViewDocumentComponent } from "./components/document/viewDocuments/viewDocument.component";
import { HttpClient } from "./services/http/http-client.service";
import { FileUploadService } from "./services/files/fileUpload.service";
import { GetPictureService } from "./services/files/getPictures.service";
import { Documentervice } from "./components/document/shared/document.service";
import { MaskService } from "./services/mask/mask.service";
import { TemporalFormsService } from "./services/temporalForms/temporalForms.service";
import { StatesServices } from "./services/states/states.service";
import { TerminalLocationsServices } from "./services/terminals/terminals.service";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { NewUploadService } from "./services/files/new-upload.service";
import { PreviewFileComponent } from "./components/preview-file/preview-file.component";
import { MixedPreviewFileComponent } from "./components/mixed-preview-file/mixed-preview-file.component";
import { FilterComponent, FilterPipe } from "./components/filter/filter.component";
import { YesNoPipe } from "./pipes/yesNo.pipe";
import { NegativeToParens } from "./pipes/negativeToParens.pipe";
import { CompletedPipe } from "./pipes/completedPipe";
import { SortArrayService } from "./services/sortArray/sort-array.service";
import { LazyForDirective } from "./directive/lazy-for.directive";
import { TableReportComponent } from "./components/table-report/table-report/table-report.component";
import { ScrollDirective } from "./directive/scroll.directive";
import { DownloadFileDirective } from "./directive/download-file.directive";
import { TableDriverExpensesComponent } from "./components/table-driver-expenses/table-driver-expenses.component";
import { TableCompanyExpensesComponent } from "./components/table-company-expenses/table-company-expenses.component";
import { CapitalLatterService } from "./services/capital-latter/capital-latter.service";
import { ResizeService } from "./services/resize/resize.service";
import { ConfirmationGuard } from "./guards/confirmation/confirmation.guard";
import { DataSharingService } from "./services/data/dataSharing.service";
import { SelectsService } from "./services/selects/select.service";
import { SpinnerService } from "./services/spinner/spinner.service";
import { ClickOutside } from "./directive/click-outside.directive";
import { ScriptHackComponent } from "../scripthack/scripthack.component";

import { PcMilerNearLocationsComponent } from "../pcMiler/shared/components/nearLocations/pcMiler-near-locations.component";
import { PcMilerResolver } from "../pcMiler/shared/pcMiler.resolver";
import { PcMilerService } from "../pcMiler/shared/pcMiler.service";
import { PcMilerComponent } from "../pcMiler/pcMiler.component";
import { NewMixedPreviewFileComponent } from "@app/shared/components/newmixed-preview-file/newmixed-preview-file.component";
import { NewBillService } from "@app/billing-new/shared/bill.service";
import { TabbableDropdownDirective } from "./directive/tabbable-dropdown.directive";
import { TabbableMultiselectDirective } from "./directive/tabbable-multiselect.directive";
import { DuplicateRatesModalComponent } from "@app/trip-new/components/duplicate-rates-modal/duplicate-rates-modal.component";

@NgModule({
  declarations: [
    CreateDocumentComponent,
    ViewDocumentComponent,
    FileUploadComponent,
    PreviewFileComponent,
    MixedPreviewFileComponent,
    NewMixedPreviewFileComponent,
    FilterComponent,
    FilterPipe,
    YesNoPipe,
    NegativeToParens,
    CompletedPipe,
    LazyForDirective,
    TableReportComponent,
    ScrollDirective,
    DownloadFileDirective,
    TableDriverExpensesComponent,
    TableCompanyExpensesComponent,
    ClickOutside,
    ScriptHackComponent,
    PcMilerComponent,
    PcMilerNearLocationsComponent,
    TabbableDropdownDirective,
    TabbableMultiselectDirective,
    DuplicateRatesModalComponent,
  ],
  exports: [
    CreateDocumentComponent,
    ViewDocumentComponent,
    FileUploadComponent,
    PreviewFileComponent,
    MixedPreviewFileComponent,
    NewMixedPreviewFileComponent,
    FilterComponent,
    FilterPipe,
    YesNoPipe,
    NegativeToParens,
    CompletedPipe,
    LazyForDirective,
    TableReportComponent,
    ScrollDirective,
    DownloadFileDirective,
    TableDriverExpensesComponent,
    TableCompanyExpensesComponent,
    ClickOutside,
    ScriptHackComponent,
    PcMilerComponent,
    PcMilerNearLocationsComponent,
    TabbableDropdownDirective,
    TabbableMultiselectDirective,
    TableModule,
    DuplicateRatesModalComponent,
  ],
  providers: [
    HttpClient,
    FileUploadService,
    GetPictureService,
    Documentervice,
    MaskService,
    TemporalFormsService,
    StatesServices,
    TerminalLocationsServices,
    NewUploadService,
    CapitalLatterService,
    ResizeService,
    ConfirmationGuard,
    DataSharingService,
    SelectsService,
    SortArrayService,
    SpinnerService,
    ConfirmationService,
    DatePipe,
    PcMilerResolver,
    PcMilerService,
    NewBillService,
    DialogService,
    CurrencyPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    TableModule,
    PasswordModule,
    CalendarModule,
    InputSwitchModule,
    InputMaskModule,
    RadioButtonModule,
    PaginatorModule,
    CheckboxModule,
    AccordionModule,
    InputTextareaModule,
    MultiSelectModule,
    DialogModule,
    DropdownModule,
    ConfirmDialogModule,
    DialogModule,
    OverlayPanelModule,
    ScrollPanelModule,
    ToolbarModule,
    InputNumberModule,
    DynamicDialogModule,
    DialogModule,
    ToggleButtonModule,
    RouterModule,
  ],
})
export class SharedModule {}
