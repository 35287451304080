import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { RoleService } from "./role.service";

@Injectable()
export class RoleResolver {
  constructor(private roleService: RoleService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.roleService.findAndFilter();
  }
}
