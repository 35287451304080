import { Injectable } from "@angular/core";

import { TrailerService } from "./trailer.service";

@Injectable()
export class TrailersTypesResolver {
  constructor(private trailerService: TrailerService) {}

  resolve() {
    return this.trailerService.listTypes();
  }
}
