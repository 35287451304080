<div class="row form-group">
  <div class="col-md-4">
    <label>FUEL REGIONS</label>
    <i
      class="pi pi-info-circle"
      pTooltip="Regions use the most specific rate selected"
      style="vertical-align: text-bottom"
    ></i>
  </div>
  <div class="col-md-8">
    <ul *ngFor="let region of topLevelRegions" style="padding-left: 0">
      <li>
        <p-checkbox
          name="regions"
          [value]="region"
          [(ngModel)]="selectedRegions"
          [inputId]="region.name"
          (onChange)="onRegionChanged(region)"
          [disabled]="!isEditing || disabledRegions[region.id]"
        ></p-checkbox>
        <label [for]="region.name">{{ region.name }}</label>
        <i class="pi pi-info-circle" [pTooltip]="region.statesString"></i>
      </li>
      <ul *ngIf="region.children">
        <div *ngFor="let childRegion of region.children">
          <li>
            <p-checkbox
              name="regions"
              [value]="childRegion"
              [(ngModel)]="selectedRegions"
              [inputId]="childRegion.name"
              (onChange)="onRegionChanged(childRegion)"
              [disabled]="!isEditing || disabledRegions[childRegion.id]"
            ></p-checkbox>
            <label [for]="childRegion.name">{{ childRegion.name }}</label>
            <i
              class="pi pi-info-circle"
              [pTooltip]="childRegion.statesString"
            ></i>
          </li>
          <ul *ngIf="childRegion.children">
            <div *ngFor="let grandChildRegion of childRegion.children">
              <li>
                <p-checkbox
                  name="regions"
                  [value]="grandChildRegion"
                  [(ngModel)]="selectedRegions"
                  [inputId]="grandChildRegion.name"
                  (onChange)="onRegionChanged(grandChildRegion)"
                  [disabled]="
                    !isEditing || disabledRegions[grandChildRegion.id]
                  "
                ></p-checkbox>
                <label [for]="grandChildRegion.name">{{
                  grandChildRegion.name
                }}</label>
                <i
                  class="pi pi-info-circle"
                  [pTooltip]="grandChildRegion.statesString"
                ></i>
              </li>
            </div>
          </ul>
        </div>
      </ul>
    </ul>
  </div>
</div>
