import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { BatchGlobal } from "@app/shared/models/BatchGlobal.model";
import { DeductionService } from "../deduction.service";
import { withLatestFrom } from "rxjs/operators";

@Component({
  selector: "app-escrow",
  templateUrl: "./escrow.component.html",
  styleUrls: ["./escrow.component.css"],
})
export class EscrowComponent implements OnInit {
  @Output() escrowChanged = new EventEmitter();

  _escrowData: any;
  cols: { field: string; header: string }[];
  driverId: any;
  escrowHistory: any[];
  depositToOptions: any;

  @Input("escrowData")
  set escrowData(escrowData) {
    if (Array.isArray(escrowData)) return;
    const numKeys = Object.keys(escrowData).length;
    if (numKeys === 0) return;
    if (escrowData.id) {
      this._escrowData = escrowData;
    }
  }
  @Input("batchInfo") batchInfo: any = {};
  escrow: any = {};

  constructor(
    private DeductionService: DeductionService,
    private _ActivatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this._ActivatedRoute.url
      .pipe(withLatestFrom(this._ActivatedRoute.paramMap, this._ActivatedRoute.queryParamMap))
      .subscribe(([url, params, queryParams]) => {
        this.driverId = params.get("driverId");

        this.DeductionService.getEscrowHistory(this.driverId).subscribe((data) => {
          this.escrowHistory = data;
        });
      });
  }

  ngOnInit() {}

  depositChanged() {
    this.batchInfo.escrowAdd = Math.abs(this.batchInfo.escrowAdd);
    this.escrowChanged.emit();
  }
  interestChanged() {
    this.batchInfo.escrowInterest = Math.abs(this.batchInfo.escrowInterest);
    this.escrowChanged.emit();
  }

  withdrawChanged() {
    this.batchInfo.escrowSubtract = -Math.abs(this.batchInfo.escrowSubtract);
    this.escrowChanged.emit();
  }
}
