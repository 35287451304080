import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { LocationsService } from "./locations.service";
import { flatMap, first } from "rxjs/operators";

@Injectable()
export class LocationsConsigneesResolver {
  constructor(private locationsService: LocationsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return [];
    return this.locationsService.listConsignee().pipe(
      flatMap((data) => data),
      first() //TODO: JRH check if this works
    );
  }
}
