import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataSharingService {

    private batchNo = new BehaviorSubject(0);
    currentBatchNo = this.batchNo.asObservable();

    constructor() { }

    changeBatchNo(batchNo) {
        this.batchNo.next(batchNo)
    }

}