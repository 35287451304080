import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../shared/user.service";
import { RoleService } from "../../roles/shared/role.service";
import { RoleResolver } from "../../roles/shared/role.resolver";
import { ActivatedRoute } from "@angular/router";
import { Md5 } from "ts-md5/dist/md5";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormControl,
} from "@angular/forms";
import { User } from "@app/shared/models/user.model";
import { authenticationService } from "@app/login/auth.service";

@Component({
  selector: "app-adduser",
  templateUrl: "./adduser.component.html",
  styleUrls: ["./adduser.component.css"],
})
export class AddUserComponent implements OnInit {
  @Input() visible: boolean = false;
  @Output() userAddedEvent = new EventEmitter<User>();

  isProcessing = false;
  hasSubmitted = false;
  allRoles;
  errorMsg: string;

  userForm = new UntypedFormGroup(
    {
      username: new UntypedFormControl("", Validators.required),
      email: new UntypedFormControl("", [Validators.required, Validators.email]),
      name: new UntypedFormControl(""),
      lastName: new UntypedFormControl(""),
      emailSignature: new UntypedFormControl(""),
      roleId: new UntypedFormControl("", Validators.required),
    }
  );

  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private roleResolve: RoleResolver,
    private _addUserService: UserService,
    private roleService: RoleService,
    private authService: authenticationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.allRoles = this._ActivatedRoute.snapshot.data.roles;
  }

  get username() {
    return this.userForm.get("username");
  }
  get email() {
    return this.userForm.get("email");
  }
  get name() {
    return this.userForm.get("name");
  }
  get lastName() {
    return this.userForm.get("lastName");
  }
  get emailSignature() {
    return this.userForm.get("emailSignature");
  }
  get roleId() {
    return this.userForm.get("roleId");
  }

  onSubmit() {
    this.hasSubmitted = true;

    if (this.userForm.invalid) return;

    const user = {
      username: this.username.value,
      email: this.email.value,
      name: this.name.value,
      lastName: this.lastName.value,
      emailSignature: this.emailSignature.value,
      RoleId: this.roleId.value,
    };

    this.createNewUser(user);
  }

  createNewUser(newUser: any) {
    this.sendNewUser(newUser).subscribe(
      (createdUser: any) => {
        this.userAddedEvent.emit(createdUser);
        this.authService.startReset(createdUser.username).subscribe(
          () => {
            this.closeModalNewUser();
          },
          (error) => {
            this.toastr.warning(error.message || "Server Error", "Alert!", {
              closeButton: true,
              enableHtml: true
            });
          });
      },
      (error) => {
        this.toastr.warning(error, "Alert!", {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
        this.isProcessing = false;
      }
    );
  }

  sendNewUser(user) {
    return this._addUserService.createNewUser(user);
  }

  closeModalNewUser() {
    const closeModal = document.getElementsByClassName(
      "p-dialog-header-close"
    )[0] as HTMLElement;
    closeModal.click();
  }

  ngOnChanges() {
    if (this.visible) {
      this.userForm.reset();
      this.hasSubmitted = false;
      this.isProcessing = false;
      this.errorMsg = null;
    }
  }
}
