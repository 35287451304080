<div id="yesterday-locations">
  <div class="col-md-12 no-padding">
    <legend>Locations Created in the Last 24 Hours</legend>

    <div class="d-flex btnRig">
      @if (isAllowed('LocationsChange')) {
        <button
          (click)="toggleLocationEditing()"
          class="btn"
        >
          @if (!canEditLocations) {
            Enable Editing
          } @else {
            Disable Editing
          }
        </button>
      }
    </div>

    <div class="table-wrapper">
      <p-table #table
        [columns]="columns"
        [value]="locations"
        [rows]="rows"
        [loading]="isLoading"
        [scrollable]="true"
        scrollHeight="600px"
        [virtualScroll]="true"
        [virtualScrollItemSize]="28"
        [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)"
      >

        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              pFrozenColumn
              style="width: 170px;"
              [hidden]="!canEditLocations"
            ></th>
            <th
              pFrozenColumn
              style="width: 150px; text-align: left;"
            >ID</th>
            @for (column of columns; track column.field) {
              <th
                pFrozenColumn [frozen]="column.frozen"
                [style.width]="column.width"
              >{{ column.header }}</th>
            }
          </tr>
          <tr>
            <th
              pFrozenColumn
              [hidden]="!canEditLocations"
            ></th>
            <th pFrozenColumn>
              <input
                type="text"
                [(ngModel)]="filters.locId"
                (input)="filtersSubject.next($event)"
              />
            </th>
            @for (column of columns; track column.field) {
              <th>
                @if (isFilterable(column.field)) {
                  <input
                    type="text"
                    [(ngModel)]="filters[column.field]"
                    (input)="filtersSubject.next($event)"
                  />
                }
              </th>
            }
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-location>
          <tr>
            <td
              pFrozenColumn
              [hidden]="!canEditLocations"
            >
              @if (!isEditingLocation(location)) {
                <button
                (click)="editLocation(location)"
                title="Edit"
                type="button"
                class="btn"
                ><i
                class="fa fa-pencil"
                aria-hidden="true"
                ></i></button>
              }
              @if (isEditingLocation(location)) {
                <button
                (click)="cancelEditLocation(location)"
                title="Cancel"
                type="button"
                class="btn"
                ><i
                class="fa fa-times"
                aria-hidden="true"
                ></i></button>
              }
              @if (isEditingLocation(location)) {
                <button
                (click)="deleteLocation(location)"
                title="Delete"
                type="button"
                class="btn btn-danger"
                ><i
                class="fa fa-trash"
                aria-hidden="true"
                ></i></button>
              }
              @if (isEditingLocation(location)) {
                <button
                  (click)="updateLocation(location)"
                  title="Save"
                  type="button"
                  class="btn btn-primary"
                ><i
                  class="fa fa-save"
                  aria-hidden="true"
                ></i></button>
              }
            </td>
            <td pFrozenColumn>
              @if (!isEditingLocation(location)) {
                {{ location.locId }}
              } @else {
                <input
                  name="locId"
                  type="text"
                  [(ngModel)]="location.locId"
                />
              }
            </td>
            <td style="text-align: center;">
              @if (!isEditingLocation(location)) {
                {{ location.countryAbbreviation }}
              } @else {
                <select
                  name="countryAbbreviation"
                  [(ngModel)]="location.countryAbbreviation"
                  (ngModelChange)="setLocationCountry(location)"
                  required
                >
                  @for (country of countries; track country.abbreviation) {
                    <option [ngValue]="country.abbreviation">
                      {{ country.name }}
                    </option>
                  }
                </select>
              }
            </td>
            <td>
              @if (!isEditingLocation(location)) {
                {{ location.name }}
              } @else {
                <input
                  name="name"
                  type="text"
                  [(ngModel)]="location.name"
                />
              }
            </td>
            <td>
              @if (!isEditingLocation(location)) {
                {{ location.address }}
              } @else {
                <input
                  name="address"
                  type="text"
                  [(ngModel)]="location.address"
                />
              }
            </td>
            <td>
              @if (!isEditingLocation(location)) {
                {{ location.city }}
              } @else {
                <input
                  name="city"
                  type="text"
                  [(ngModel)]="location.city"
                />
              }
            </td>
            <td style="text-align: center;">
              @if (!isEditingLocation(location)) {
                {{ location.stateAbbreviation }}
              } @else {
                <select
                  name="state"
                  [(ngModel)]="location.stateAbbreviation"
                  (ngModelChange)="setLocationState(location)"
                  required
                >
                  @for (state of getStatesByCountry(location.countryAbbreviation); track state.abbreviation) {
                    <option [ngValue]="state.abbreviation">
                      {{ state.abbreviation }} - {{ state.fullName }}
                    </option>
                  }
                </select>
              }
            </td>
            <td style="text-align: center;">
              @if (!isEditingLocation(location)) {
                {{ location.zipcode }}
              } @else {
                <input
                  name="zipcode"
                  type="text"
                  [(ngModel)]="location.zipcode"
                />
              }
            </td>
            <td>
              @if (!isEditingLocation(location)) {
                {{ location.latitude }}
              } @else {
                <input
                  name="latitude"
                  type="number"
                  step="0.000001"
                  [(ngModel)]="location.latitude"
                  (change)="location.confidenceLevel = 'MANUAL'"
                />
              }
            </td>
            <td>
              @if (!isEditingLocation(location)) {
                {{ location.longitude }}
              } @else {
                <input
                  name="longitude"
                  type="number"
                  step="0.000001"
                  [(ngModel)]="location.longitude"
                  (change)="location.confidenceLevel = 'MANUAL'"
                />
              }
            </td>
            <td style="text-align: center;">
              <p-checkbox
                binary="true"
                [(ngModel)]="location.isShipper"
                disabled="{{ !isEditingLocation(location) }}"
              ></p-checkbox>
            </td>
            <td style="text-align: center;">
              <p-checkbox
                binary="true"
                [(ngModel)]="location.isConsignee"
                disabled="{{ !isEditingLocation(location) }}"
              ></p-checkbox>
            </td>
            <td style="text-align: center;">
              <p-checkbox
                binary="true"
                [(ngModel)]="location.isBillTo"
                disabled="{{ !isEditingLocation(location) }}"
              ></p-checkbox>
            </td>
            <td style="text-align: center;">
              {{ location.confidenceLevel }}
            </td>
          </tr>
        </ng-template>
  
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  header="Select Near Location"
  [(visible)]="exactLocationsData.length"
  [style]="{ 'max-width': '1600px' }"
>
  <h4>Please select one of the available options.</h4>
  <app-pc-miller-near-locations
    [locations]="exactLocationsData"
    (locationSelected)="finishLocationUpdate($event)"
  ></app-pc-miller-near-locations>
</p-dialog>
