import { Component, OnInit } from "@angular/core";
import { SettlementsService } from "@app/settlements/shared/settlements.service";
import { TcheckService } from "@app/tcheck/tcheck.service";
import { TchekTablesService } from "@app/tcheck/tchek-tables.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-tchek-historical",
  templateUrl: "./tchek-historical.component.html",
  styleUrls: ["./tchek-historical.component.css"],
})
export class TchekHistoricalComponent implements OnInit {
  records: any = [];
  batchesList: any[] = [];
  selectedBatch: any;

  constructor(
    private tcheckService: TcheckService,
    private toastr: ToastrService,
    private tchekTables: TchekTablesService,
    private settlementsService: SettlementsService
  ) {}

  ngOnInit() {
    this.settlementsService.getGlobalBatchListDropDown("Inactive").subscribe(
      (batchList: any) => {
        this.batchesList = batchList;
        this.selectedBatch = this.batchesList[0];
        this.loadBatchData();
      },
      (resError) => {
        this.toastr.error(resError.message, "Error");
      }
    );
  }

  loadBatchData() {
    if (!this.selectedBatch) return;
    this.tcheckService.getRecordsByBatchId(this.selectedBatch.id).subscribe((res: any) => {
      this.records = res.map((record) => ({
        ...record,
        type: record.isDriverTransaction ? "FUEL EXPENSE" : "Express Code",
      }));
    });
  }

  excelGeneration() {
    const cols = this.tchekTables.historicalCols.concat(this.tchekTables.reportCols);
    this.tchekTables.exportToCSV(this.records, cols, "tcheckHistoricalExpenses");
  }
}
