import { Injectable } from "@angular/core";
import { DriverViewModel } from "@app/shared/models/drivers/DriverViewModel.model";

@Injectable()
export class DriverValidationService {
  constructor() {}

  validate(driver: DriverViewModel) {
    let errors = [];
    if (!driver) {
      errors.push("Driver missing");
      return errors;
    }
    if (driver.isActive && !driver.settlementEmail) errors.push("Settlement email is required.");
    if (!driver.name) errors.push("Driver Name is required.");
    if (!driver.lastName) errors.push("Driver Last Name is required.");
    if (!driver.peopleNetId) errors.push("PeopleNet ID is required.");
    errors = errors.concat(this.validateDriverDates(driver));
    return errors;
  }

  validateDriverDates(driver: DriverViewModel) {
    const currentHireDate = driver.hiredDate;
    if (!currentHireDate) return ["Hired date is required."];

    const parsedCurrentHireDate = new Date(currentHireDate);

    for (const hireDates of driver.driverHireDates) {
      const { hiredDate, hiredDateTo } = hireDates;
      const parsedHiredDate = new Date(hiredDate);
      const parsedHiredDateTo = new Date(hiredDateTo);
      // compare dates
      if (hiredDate && hiredDateTo && parsedHiredDate > parsedHiredDateTo) {
        return ["Hired date second value must be after first."];
      }
      if (parsedCurrentHireDate < parsedHiredDate || parsedCurrentHireDate < parsedHiredDateTo) {
        return ["Current hired date must be after all previous hire dates"];
      }
    }
    return [];
  }
}
