<div class="sticky-header container">
  <legend style="display: flex; margin-bottom: 0">
    Guarantees
    <div style="margin-left: auto; margin-bottom: 10px">
      <button type="button" class="btn" style="margin-right: 1rem" routerLink="/rates">
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="canChange && !isCreating && !isEditing"
        (click)="toggleEditing()"
      >
        Edit
      </button>
      <button
        type="button"
        class="btn btn-danger"
        style="margin-right: 1rem"
        *ngIf="isEditing && !isCreating"
        (click)="confirmDelete()"
      >
        Delete
      </button>
      <button
        type="button"
        class="btn btn-primary"
        style="margin-right: 1rem"
        (click)="copyRate()"
        *ngIf="canChange && isEditing && !isCreating"
      >
        <i class="fa fa-copy"></i>
        Copy Rate
      </button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="isEditing && !isCreating"
        (click)="updateRate()"
      >
        Update
      </button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="isCreating && isEditing && canChange"
        (click)="createNewRate()"
      >
        Create
      </button>
    </div>
  </legend>
</div>
<div class="container" style="margin-top: 10px">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <div class="row form-group">
          <label class="col-md-4">Rate Name</label>
          <div class="col-md-8">
            <input
              *ngIf="isEditing; else namePreview"
              type="text"
              class="form-control input-md"
              [(ngModel)]="rate.name"
              required
            />
            <ng-template #namePreview>
              <div>
                {{ rate.name }}
              </div>
            </ng-template>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-md-4">Charge Type</label>
          <p-dropdown
            *ngIf="isEditing"
            class="col-md-8"
            [style]="{ minWidth: '100%' }"
            [options]="chargeTypes"
            [(ngModel)]="rate.chargeTypeId"
            placeholder="Select a Charge Type"
            tabbableDropdown
          ></p-dropdown>
          <div *ngIf="!isEditing" class="col-md-4">
            {{ rate.chargeType?.name }}
          </div>
        </div>
        <div class="row form-group">
          <label class="col-md-4">Description</label>
          <div class="col-md-8">
            <textarea
              *ngIf="isEditing; else descriptionPreview"
              rows="5"
              maxlength="1024"
              [(ngModel)]="rate.description"
              class="form-control inputForm notes"
            ></textarea>
            <ng-template #descriptionPreview>
              <div>
                {{ rate.description }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="row form-group" *ngIf="isEditing">
      <label class="col-md-2">Apply To</label>
      <p-dropdown
        class="col-md-4"
        [style]="{ minWidth: '100%' }"
        [options]="applyToOptions"
        [(ngModel)]="rate.applyTo"
        (onChange)="applyToChanged()"
        tabbableDropdown
      ></p-dropdown>
    </div>
    <div class="row form-group" *ngIf="rate.applyTo === 'DRIVERS'">
      <label class="col-md-2">Drivers</label>
      <div class="col-md-10">
        <app-add-minimum-driver
          [allDrivers]="drivers"
          [(drivers)]="rate.drivers"
          [isEditing]="isEditing"
        >
        </app-add-minimum-driver>
      </div>
    </div>
    <div class="row form-group" *ngIf="rate.applyTo === 'CLIENTS'">
      <label class="col-md-2">Bill Tos</label>
      <div class="col-md-10">
        <app-add-minimum-client [(clients)]="rate.clients" [isEditing]="isEditing">
        </app-add-minimum-client>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-md-2">Guarantee Period</label>
      <p-dropdown
        *ngIf="isEditing"
        class="col-md-4"
        [style]="{ minWidth: '100%' }"
        [options]="guaranteePeriods"
        [(ngModel)]="rate.guaranteePeriod"
        placeholder="Select a Period"
        tabbableDropdown
      ></p-dropdown>
      <div *ngIf="!isEditing" class="col-md-4">
        {{ rate.guaranteePeriod }}
      </div>
      <label class="col-md-2">Pay or Miles</label>
      <p-dropdown
        *ngIf="isEditing"
        class="col-md-4"
        [style]="{ minWidth: '100%' }"
        [options]="guaranteeTypes"
        [(ngModel)]="rate.guaranteeType"
        placeholder="Select Pay or Miles"
        tabbableDropdown
      ></p-dropdown>
      <div *ngIf="!isEditing" class="col-md-4">
        {{ rate.guaranteeType }}
      </div>
    </div>
    <div class="row form-group" *ngIf="rate.guaranteeType">
      <label class="col-md-2" *ngIf="rate.guaranteeType === 'PAY'">Rate ($)</label>
      <label class="col-md-2" *ngIf="rate.guaranteeType === 'MILES'">Guaranteed Miles</label>
      <div class="col-md-4">
        <input
          type="number"
          *ngIf="isEditing"
          class="form-control input-md"
          [(ngModel)]="rate.rate"
          required
        />
        <div *ngIf="!isEditing">
          <span *ngIf="rate.guaranteeType === 'PAY'">{{ rate.rate | currency }}</span>
          <span *ngIf="rate.guaranteeType === 'MILES'">{{ rate.rate }} Miles</span>
        </div>
      </div>
    </div>
    <div class="row form-group" *ngIf="rate.guaranteeType == 'PAY'">
      <label class="col-md-2">Excluded Charges</label>
      <div class="col-md-4">
        <p-multiSelect
          *ngIf="isEditing; else excludedChargesPreview"
          [filter]="false"
          [options]="excludedChargeTypes"
          [(ngModel)]="rate.excludedChargeTypes"
          [style]="{ minWidth: '100%' }"
          tabbableMultiselect
        ></p-multiSelect>
        <ng-template #excludedChargesPreview>
          <div>
            <div *ngFor="let excludedCharge of excludedChargeTypesPreview">
              {{ excludedCharge.name }}
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div>
    <app-daily-minimum-history *ngIf="!isCreating" [rateId]="rate.id"> </app-daily-minimum-history>
  </div>
</div>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '50vw' }"
></p-confirmDialog>
