import { firstValueFrom, throwError as observableThrowError } from "rxjs";

import { catchError, first, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import { HttpClient } from "../../shared/services/http/http-client.service";
import { TripCharge } from "@app/shared/models/trips/TripCharge.model";
import { TripOptions } from "@app/shared/models/trips/TripOptions.model";
import { InsuranceSurchargeViewModel } from "@app/shared/models/rates/InsuranceSurchargeViewModel.model";
import { LongevityViewModel } from "@app/shared/models/rates/LongevityViewModel.model";
import {
  FuelSurchargeRateViewModel,
  FuelSurchargeViewModel,
} from "@app/shared/models/rates/FuelSurchargeViewModel.model";
import { CustomChargeViewModel } from "@app/shared/models/rates/CustomChargeViewModel.model";
import { DriverRateViewModel } from "@app/shared/models/rates/DriverRateViewModel.model";
import { MinimumRateViewModel } from "@app/shared/models/rates/MinimumRateViewModel.model";
import { ClientRateViewModel } from "@app/shared/models/rates/ClientRateViewModel.model";

@Injectable()
export class NewTripService {
  filteredTrips: any[] = [];
  selectedfiles: any[] = [];
  constructor(private _http: HttpClient) {}

  getCharges(tripId: string | number) {
    return this._http.get(`api/trip/getCharges/${tripId}`).pipe(
      map((res: TripCharge[]) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  updateChargesAsPromise(
    tripId: string | number,
    tripCharges: TripCharge[],
    driverCharges: TripCharge[]
  ) {
    if (!tripCharges && !driverCharges) return;
    return this._http
      .patch(`api/trip/updateCharges/${tripId}`, {
        tripCharges,
        driverCharges,
      })
      .toPromise();
  }

  getNextTripInFilteredList(currentID: string) {
    const trips = JSON.parse(localStorage.getItem("tripListStatus")).tripsIdList;
    if (!trips) return false;
    for (let i = 0; i < trips.length; i++) {
      if (trips[i] === currentID) {
        // if is the last one??
        if (i === trips.length - 1) {
          return false;
        } else {
          return trips[++i];
        }
      }
    }
    return false;
  }

  getTripList(offset, limit, filter, order) {
    return this._http.post("api/trip/list", { offset, limit, filter, order }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getCancelledTripList(offset, limit, filters, order) {
    return this._http
      .post("api/trip/listCancelledTrips", {
        offset,
        limit,
        filter: filters,
        order,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) =>
          observableThrowError(error.message || "Server Trip Cancelled List error")
        )
      );
  }

  getQueueTripList(filter = {}, order = {}) {
    return this._http.post("api/trip/listqueue", { filter, order }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getNewListQueueTrips() {
    return this._http.post("api/trip/listQueueTrip", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getListFilterQueue() {
    return this._http.post("api/trip/listFilterQueue", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getFilterBillingTrips() {
    return this._http.post("api/trip/listFilterBilling", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  getNewListBillingTrips() {
    return this._http.post("api/trip/listBillingTrip", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getClientList() {
    return this._http.post("api/client/list", { validOnly: true, includeDeleted: true }).pipe(
      map((res: any) => {
        let client = res;
        client.map((client) => (client.itemName = client.companyName));
        return client;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getListFilterBilling() {
    return this._http.post("api/trip/listFilterBilling", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getNewListBilledTrips() {
    return this._http.post("api/trip/listBilledTrip", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getListFilterBilled() {
    return this._http.post("api/trip/listFilterBilled", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getNewListCancelledTrips() {
    //VARGAS WTF UNA FUNCION POST QUE NO RECIVE PARAMETROS?
    return this._http.post("api/trip/listCancelledTrip", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getListFilterCancelled() {
    //VARGAS WTF UNA FUNCION POST QUE NO RECIVE PARAMETROS?
    return this._http.post("api/trip/listFilterCancelled", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  newListBilled() {
    return this._http.get("api/trip/newListBilledView").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  getListReadyForBillView() {
    return this._http.post("api/trip/listReadyForBillView", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  newGetListReadyForBillView() {
    return this._http.get("api/trip/newListReadyForBillView").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  getBillingTripList(offset, limit, filter, order) {
    return this._http.post("api/trip/listbilling", { offset, limit, filter, order }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getBilledTripList(offset, limit, filter, order) {
    return this._http.post("api/trip/listbilled", { offset, limit, filter, order }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getInvoiceTripList(offset, limit, filter, order) {
    return this._http.post("api/trip/listinvoice", { offset, limit, filter, order }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getTripInfo(id: string) {
    return this._http.get("api/trip/find/" + id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server home Trip info List error")
      )
    );
  }

  findOverlappingTripsAsync(
    startDate: Date,
    endDate: Date,
    driverId: string,
    trailerId: string,
    tractorId: string
  ): Promise<any> {
    return firstValueFrom(
      this._http.post("api/trip/findOverlapping", {
        startDate,
        endDate,
        driverId,
        trailerId,
        tractorId,
      })
    );
  }

  async isTripBackupValid(id): Promise<any> {
    return firstValueFrom(this._http.get("api/trip/isBackupValid/" + id));
  }

  createNewTrip(newTrip, tripBilling, payments) {
    return this._http
      .post("api/trip/createWeb", {
        trip: newTrip,
        billing: tripBilling,
        payments,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) =>
          observableThrowError(error.message || "Server trip creation error")
        )
      );
  }

  createNewTripUpload(files) {
    return this._http.post("api/trip/createFromPicturesWeb", { Files: files }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  getClientsDropDown() {
    return this._http.get("api/client/listDropDown").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }
  //Goodbye schedules
  /*  getSchedulebyClientDate(clientId, startDate, endDate) {
      const client = {
        ClientId: clientId,
        startDate: startDate,
        endDate: endDate,
      };
      return this._http
        .post("api/schedule/listByClientDate", { schedule: client }, null, false)
        .pipe(
          map((res) => {
            return res;
          }),
          catchError((error: any) =>
            observableThrowError(error.message || "Server trip creation error")
          )
        );
    }*/

  getDriversDropDown() {
    return this._http.get("api/driver/listDropDown").pipe(
      map((res: any) => {
        let drivers = res;
        drivers.map((driver) => (driver.itemName = driver.lastName + " " + driver.name));
        return drivers;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  listDriversTripDropDown() {
    return this._http.get("api/driver/listTripDropDown").pipe(
      map((res: any) => {
        let drivers = res;
        drivers.map((driver) => (driver.itemName = driver.lastName + " " + driver.name));
        return drivers;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  getTractorsDropDown() {
    return this._http.get("api/tractor/listDropDown").pipe(
      map((res: any) => {
        let tractors = res;
        tractors.map((tractor) => (tractor.itemName = tractor.unitNumber));
        return tractors;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  getTeamsDropDown() {
    return this._http.get("api/team/listDropDown").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  getProductsDropDown() {
    return this._http.get("api/product/listDropDown").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  getTrailersDropDown() {
    return this._http.get("api/trailer/listDropDown").pipe(
      map((res: any) => {
        let trailers = res;
        trailers = trailers.filter((trailer) => trailer.trailerNo !== null);
        return trailers;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  listShippers() {
    return this._http.get("api/shipper/listAll").pipe(
      map((res: any) => {
        let shippers = res;
        shippers.map((shippers) => (shippers.itemName = shippers.locId));
        return shippers;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getLocationsDropDown(clientId) {
    return this._http.post("api/location/listByClientDropDown", { clientId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  getBillAdressesDropDown(clientId) {
    const client = {
      clientId: clientId,
    };
    return this._http.post("api/billAddress/listDropDown", { billAddress: client }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  getFuelPriceByStateAndDate(state, fuelDate) {
    const filter = {
      state: state,
      fuelDate: fuelDate,
    };
    return this._http.post("api/gas/getFuelPriceByState", { filter }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  async getFuelPriceByStateAndDateAsync(state: string, fuelDate: Date): Promise<any> {
    const filter = {
      state: state,
      fuelDate: fuelDate,
    };
    return firstValueFrom(this._http.post("api/gas/getFuelPriceByState", { filter }));
  }

  async getFuelPriceByRegionAndDateAsync(region: string, fuelDate: Date): Promise<number> {
    return firstValueFrom(this._http.post("api/gas/getFuelPriceByRegion", { region, fuelDate }));
  }

  async getClientRatesFromOptions(tripOptions: TripOptions): Promise<ClientRateViewModel[]> {
    const { tripData, ...options } = tripOptions;
    return firstValueFrom(this._http.post("api/rates/getClientRatesFromOptions", { options }));
  }

  async getDriverGuaranteedRate(
    driverId: string | number,
    date: Date
  ): Promise<MinimumRateViewModel> {
    return firstValueFrom(
      this._http.post("api/rates/minimums/getDriverGuarantee", { driverId, date })
    );
  }

  async getClientGuaranteedRate(
    clientId: string | number,
    date: Date
  ): Promise<MinimumRateViewModel> {
    return firstValueFrom(
      this._http.post("api/rates/minimums/getClientGuarantee", { clientId, date })
    );
  }

  async getDriverRatesFromOptions(tripOptions: TripOptions): Promise<DriverRateViewModel[]> {
    const { tripData, ...options } = tripOptions;
    return firstValueFrom(this._http.post("api/rates/getDriverRatesFromOptions", { options }));
  }

  async getInsuranceRatesFromOptions(
    tripOptions: TripOptions
  ): Promise<InsuranceSurchargeViewModel[]> {
    const { tripData, ...options } = tripOptions;
    return firstValueFrom(this._http.post("api/rates/getInsuranceRatesFromOptions", { options }));
  }

  async getLongevityFromOptions(tripOptions: any): Promise<LongevityViewModel[]> {
    const { tripData, ...options } = tripOptions;
    return firstValueFrom(this._http.post("api/rates/getLongevityFromOptions", { options }));
  }

  async getFuelRatesFromOptions(
    tripOptions: any,
    ratingType: string
  ): Promise<FuelSurchargeViewModel[]> {
    const { tripData, ...options } = tripOptions;
    options.ratingType = ratingType;
    return firstValueFrom(this._http.post("api/rates/getFuelRatesFromOptions", { options }));
  }

  async getFuelSurchargeRegionForState(
    fuelSurchargeId: string | number,
    state: string
  ): Promise<any> {
    return firstValueFrom(
      this._http.get(`api/rates/fuelSurcharges/${fuelSurchargeId}/getRegionForState?state=${state}`)
    );
  }

  async getCustomChargesFromOptions(
    tripOptions: TripOptions,
    type: string,
    driverId?: string
  ): Promise<CustomChargeViewModel[]> {
    const { tripData, ...options } = tripOptions;
    return firstValueFrom(
      this._http.post("api/rates/getCustomChargesFromOptions", {
        options,
        type,
        driverId,
      })
    );
  }

  getTripInfoByTripNo(tripNo: string) {
    return this._http.get("api/trip/findByTripNo/" + tripNo).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server home Trip info List error")
      )
    );
  }

  updateTrip(trip, billing, payments) {
    return this._http.patch("api/trip/update", { trip, billing, payments }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateTripAsPromise(trip, billing, payments) {
    return firstValueFrom(this._http.patch("api/trip/update", { trip, billing, payments }));
  }

  updateTripAttachedFilesAsPromise(tripId, attachedFiles) {
    return this._http
      .patch("api/trip/updateAttachedFiles", { tripId, attachedFiles })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.message || "Server error"))
      )
      .toPromise();
  }

  deleteTrip(id: string) {
    return this._http.delete("api/trip/delete/" + id);
  }

  restoreTrip(id: string) {
    return this._http.post("api/trip/restore", { id });
  }

  sendToBilling(id: string) {
    return this._http.get("api/trip/sendToBillingReview/" + id);
  }

  sendBatchToBillingReview(tripsIDs: string[]) {
    return this._http.post("api/trip/sendBatchToBillingReview/", { tripsIDs }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  sendToBillingQueue(id: string) {
    return this._http.get("api/trip/sendToBillingQueue/" + id);
  }

  sendBatchToBillingQueue(tripsIDs) {
    return this._http.post("api/trip/sendBatchToBillingQueue/", { tripsIDs }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  sendBatchToReadyForBill(tripsIDs) {
    return this._http.post("api/trip/sendBatchToReadyForBill/", { tripsIDs }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  sendToReadyForBill(id: string) {
    return this._http.get("api/trip/sentToReadyForBill/" + id);
  }

  sendBackToBillingReview(id: string) {
    return this._http.get("api/trip/sendBackToBillingReview/" + id);
  }

  setTripListStatusData(view: any) {
    const { currentView, tripsIdList } = view;
    const currentStatus = JSON.parse(localStorage.getItem("tripListStatus")) || {};
    if (currentView) currentStatus.currentView = currentView;
    if (tripsIdList) currentStatus.tripsIdList = tripsIdList || [];
    localStorage.setItem("tripListStatus", JSON.stringify(currentStatus));
  }

  getTripListStatusData() {
    return JSON.parse(localStorage.getItem("tripListStatus"));
  }

  getListBillingView() {
    return this._http.post("api/trip/listbillingView", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  printInvoices(tripsIDs: string[], includeBackup = true) {
    return this._http.post("api/trip/printInvoices", { tripsIDs, includeBackup }, "blob").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  printBackup(tripIDs: string) {
    return this._http.post("api/trip/printBackup", { tripIDs }, "blob").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  moveInvoicesToBilled(tripsIDs: string[]) {
    return this._http.post("api/trip/moveInvoicesToBilled", { tripsIDs }, "blob").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  runBillingCycle(tripsIDs: string[]) {
    return this._http.post("api/trip/runBillingCycle", { tripsIDs }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  approveTrips(tripIds: string[]) {
    return this._http.post("api/trip/approveTrips", { tripIds }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  getRuleName(ruleId: string) {
    return this._http.post("api/trip/getRuleName", { ruleId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }
}
