export class TractorViewModel {
  id: string | number;
  dateCreatedUtc: string;
  dateDeletedUtc: string;
  isActive: boolean;
  unitNumber: string;
  model: string;
  terminalLocation: string;
  make: string;
  owner: string;
  fuelType: string;
  licenseNumber: string;
  stateOfRegistration: string;
  height: string;
  length: string;
  width: string;
  axles: string;
  insuranceName: string;
  insurancePolicyNumber: string;
  equipmentCode: string;
  picture: string;
  mobileDeviceType: string;
  mobileDeviceNumber: string;
  engineManufacturer: string;
  engineSerialNumber: string;
  engineModel: string;
  afterTreatmentManufactureWarranty: string;
  engineManufactureWarranty: string;
  baseManuFactureWarranty: string;
  TcheckCardNo: string;
  vinNo: string;
  notes: string;
  year: number;
  afterTreatmentYearWarranty: number;
  afterTreatmentMilesWarranty: number;
  engineYearWarranty: number;
  engineMilesWarranty: number;
  baseWarrantyYear: number;
  baseWarrantyMiles: number;
  accumulatedMiles: number;
  purchasePrice: number;
  ladenWeight: number;
  unladenWeight: number;
  steerTireSize: number;
  driveTireSize: number;
  licenseExpirationDate: Date;
  datePurchased: Date;
  dateSold: Date;
  driverId: string;

  driver?: any;
}
