<nav class="sidebar" id="navSidebar" style="transform: translate3d(0px, 0px, 0px)" *ngIf="!isLogin">
  <div class="sidebar-header">
    <img
      src="assets/img/lgt.png"
      alt="logo"
      class="brand"
      data-src="assets/img/lgt.png"
      width="50"
      height="50"
    />
  </div>

  <div class="sidebar-menu">
    <ul class="menu-items">
      <li class="m-t-30">
        <a routerLink="home" pTooltip="Dashboard" *ngIf="hasPermission('DashboardRead')" aria-label="Dashboard">
          <span routerLinkActive="bg-success" class="icon-thumbnail" data-placement="right">
            <i  class="pg-home"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('BillingQueueRead') || hasPermission('BilledRead')">
        <a routerLink="new/triplist" pTooltip="Trips" aria-label="Trips">
          <span routerLinkActive="bg-success" class="icon-thumbnail" data-placement="right">
            <i class="fa fa-road"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('DriversRead')">
        <a routerLink="drivers-list" pTooltip="Drivers" aria-label="Drivers">
          <span routerLinkActive="bg-success" class="icon-thumbnail" data-placement="right">
            <i class="fa fa-user-circle"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('LessorRead')">
        <a routerLink="lessorlist" pTooltip="Lessors" aria-label="Lessors">
          <span routerLinkActive="bg-success" class="icon-thumbnail" data-placement="right">
            <i class="fa fa-building-o"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('DriversRead')">
        <a routerLink="teams" pTooltip="Teams" aria-label="Teams">
          <span routerLinkActive="bg-success" class="icon-thumbnail" data-placement="right">
            <i class="fa fa-users"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('TractorsRead')">
        <a routerLink="tractorlist" pTooltip="Tractors" aria-label="Tractors">
          <span routerLinkActive="bg-success" class="icon-thumbnail" data-placement="right">
            <i class="fa fa-truck"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('TrailersRead')">
        <a routerLink="trailerlist" pTooltip="Trailers" aria-label="Trailers">
          <span routerLinkActive="bg-success" class="icon-thumbnail" data-placement="right">
            <i class="fa fa-subway"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('FuelReportRead')">
        <a routerLink="fuel" pTooltip="Fuel Report" aria-label="Fuel Report">
          <span routerLinkActive="bg-success" class="icon-thumbnail" data-placement="right">
            <i class="fa fa-bar-chart"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('SettlementsRead')">
        <a routerLink="settlements" pTooltip="Settlements" aria-label="Settlements">
          <span routerLinkActive="bg-success" class="icon-thumbnail" data-placement="right">
            <i class="fa fa-magic" aria-hidden="true"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('WeeklyReportChange')">
        <a routerLink="reports/create" pTooltip="New Report" aria-label="New Report">
          <span
            routerLinkActive="bg-success"
            [routerLinkActiveOptions]="{ exact: true }"
            class="icon-thumbnail"
            data-placement="right"
          >
            <i class="fa fa-calendar-plus-o"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('WeeklyReportRead')">
        <a routerLink="reports" pTooltip="Reports" aria-label="Reports">
          <span
            routerLinkActive="bg-success"
            [routerLinkActiveOptions]="{ exact: true }"
            class="icon-thumbnail"
            data-placement="right"
          >
            <i class="fa fa-calendar"></i>
          </span>
        </a>
      </li>
      <li class="" *ngIf="hasPermission('PCMilerChange')">
        <a routerLink="utilities" pTooltip="Utilities" aria-label="Utilities">
          <span routerLinkActive="bg-success" class="icon-thumbnail" data-placement="right">
            <i class="fa fa-window-restore"></i>
          </span>
        </a>
      </li>
    </ul>
    <div class="clearfix"></div>
  </div>
</nav>
