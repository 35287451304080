export class Deduction {
  id: number;
  driverId: number;

  description: string;
  reference: string;
  isActive: boolean;

  chargeTypeId: number;

  delete?: boolean;
}
