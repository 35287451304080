import { Component, OnInit, ViewChild } from "@angular/core";
import { TractorService } from "../shared/tractor.service";
import { Router } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { SelectItem } from "primeng/api";

@Component({
  selector: "app-tractorlist",
  templateUrl: "./tractorlist.component.html",
  styleUrls: ["./tractorlist.component.css"],
})
export class TractorlistComponent implements OnInit {
  tractorsCols: any = [];
  errorMsg: any;
  allRecord: any;

  statusOptions: SelectItem[] = [
    { label: "ALL", value: null },
    { label: "ACTIVE", value: "Active" },
    { label: "INACTIVE", value: "Inactive" },
  ];

  constructor(
    private _tractorList: TractorService,
    private router: Router,
    private _AuthService: authenticationService
  ) {}

  ngOnInit() {
    this.tractorsCols = [
      { field: "unitNumber", header: "Unit number", width: 120 },
      { field: "driverName", header: "Driver name" },
      { field: "year", header: "Year", width: 100 },
      { field: "make", header: "Manufacturer", width: 130 },
      { field: "licenseNumber", header: "License Plate", width: 120 },
      { field: "active", header: "Status", width: 100, type: "boolean" },
    ];

    this._tractorList.findAndFilter().subscribe(
      (resTractorListData) => {
        this.allRecord = JSON.parse(JSON.stringify(resTractorListData));
      },
      (resTractorListError) => (this.errorMsg = resTractorListError)
    );
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  viewDetail(tractor) {
    this.router.navigate(["/tractorinfo/", tractor.id]);
  }
}
