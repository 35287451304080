import rg4js from "raygun4js";
import { ErrorHandler } from "@angular/core";
import { environment } from "./environments/environment";

rg4js("apiKey", environment.raygunApiKey);
rg4js("enableCrashReporting", true);

// Create a new ErrorHandler and report an issue straight to Raygun
export class RaygunErrorHandler implements ErrorHandler {
  handleError(e: any) {
    console.error(e);
    rg4js("send", {
      error: e,
    });
  }
}
