import { forkJoin as observableForkJoin } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "../http/http-client.service";
import { throwError as observableThrowError } from "rxjs";

@Injectable()
export class FileUploadService {
  constructor(private _http: HttpClient) {}

  uploadFiles(files: any[], docType: string, uniqPrefix?: string) {
    const observableArray = [];
    const responses = [];
    for (const file of files) {
      if (uniqPrefix) {
        Object.defineProperty(file, "name", {
          writable: true,
          value: uniqPrefix + file.name,
        });
      }
      const formData: FormData = new FormData();
      formData.append(docType, file, file.name);
      observableArray.push(this._http.upload("/api/upload", formData));
    }
    return observableForkJoin(observableArray).pipe(
      map((results: Array<Response>) => {
        results.map((response) => {
          responses.push(response);
        });
        return responses;
      })
    );
  }

  uploadSingleFileRename(files: any[], docType: string, newName: string) {
    const file = files[0];
    Object.defineProperty(file, "name", {
      writable: true,
      value: newName.toUpperCase(),
    });
    const formData: FormData = new FormData();
    formData.append(docType, file, file.name);
    return this._http.upload("/api/upload", formData).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadFilesToAzure(files: any[], docType: string) {
    const observableArray = [];
    const responses = [];
    for (const file of files) {
      const formData: FormData = new FormData();
      formData.append(docType, file, file.name);
      observableArray.push(this._http.upload("api/azureBlobStorage/uploadfile", formData));
    }
    return observableForkJoin(observableArray).pipe(
      map((results: Array<Response>) => {
        results.map((response) => {
          responses.push(response);
        });
        return responses;
      })
    );
  }

  getAzureFileUrl(folder: string, fileName: string) {
    return `/api/download/${folder}/${fileName}`;
  }

  getPictureUrl(path: string, docType: string) {
    return this._http.get("/api/download/" + docType.toLowerCase() + "/" + path).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.message || "Error retrieving azure url");
      })
    );
  }
}
