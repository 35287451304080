import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-create-rates",
  templateUrl: "./create-rates.component.html",
  styleUrls: ["./create-rates.component.css"],
})
export class CreateRatesComponent implements OnInit {
  rateType: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.rateType = params["rateType"].toUpperCase();

      if (!this.isValidRateType(this.rateType)) {
        // throw them back to the rates page
        this.router.navigate(["/rates"]);
      }
    });
  }

  isValidRateType(rateType: string) {
    return (
      rateType === "CLIENT" ||
      rateType === "DRIVER" ||
      rateType === "FUEL" ||
      rateType === "INSURANCE" ||
      rateType === "CUSTOM" ||
      rateType === "LONGEVITY"
    );
  }
}
