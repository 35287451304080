import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { StatesServices } from "@app/shared/services/states/states.service";

@Component({
  selector: "app-states-picker",
  templateUrl: "./states-picker.component.html",
  styleUrls: ["./states-picker.component.css"],
})
export class StatesPickerComponent implements OnInit, OnChanges {
  @ViewChild("closebutton") closebutton: any;

  @Input() id: string;
  @Input() isEditing: boolean;
  @Input() states: any[];
  @Output() statesChange = new EventEmitter();

  allStates: any[];
  statesToDisplay: string[] = [];

  USSelected: boolean = false;
  MXSelected: boolean = false;
  CASelected: boolean = false;

  constructor(private statesService: StatesServices) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.states && !changes.states.firstChange) {
      this.onStatesChange();
    }
  }

  ngOnInit(): void {
    this.allStates = this.statesService.getAllStates();

    // the
    this.allStates.map((state) => ({
      ...state,
      dropdownModel: {
        name: state.name,
        itemName: state.name,
        id: state.id,
      },
    }));
  }

  onStatesChange() {
    this.USSelected = this.isCategoryFull("US");
    this.MXSelected = this.isCategoryFull("MX");
    this.CASelected = this.isCategoryFull("CA");
    this.updateStatesList();

    this.statesChange.emit(this.states);
  }

  updateStatesList() {
    this.statesToDisplay = [];
    if (this.USSelected) this.statesToDisplay.push("All US");
    else if (this.isCategoryNotEmpty("US"))
      this.statesToDisplay.push("Some US");
    if (this.CASelected) this.statesToDisplay.push("All Canada");
    else if (this.isCategoryNotEmpty("CA"))
      this.statesToDisplay.push("Some Canada");
    if (this.MXSelected) this.statesToDisplay.push("All Mexico");
    else if (this.isCategoryNotEmpty("MX"))
      this.statesToDisplay.push("Some Mexico");

    // this.statesToDisplay = this.statesToDisplay.concat(this.states);
  }

  isCategoryFull(category: string) {
    const filteredStates = this.statesService.getTwoLettersStates(category);
    for (let state of filteredStates) {
      const isStateSelected = this.states.some(
        (selectedState) => selectedState === state.abbreviation
      );
      if (!isStateSelected) {
        return false;
      }
    }
    return true;
  }

  isCategoryNotEmpty(category: string) {
    const filteredStates = this.statesService.getTwoLettersStates(category);
    for (let state of filteredStates) {
      const isStateSelected = this.states.some(
        (selectedState) => selectedState === state.abbreviation
      );
      if (isStateSelected) return true;
    }
    return false;
  }

  // Add or delete all states in a category based on toggleOn
  filterStates(category: string, toggleOn: boolean) {
    // this function isn't optimized, but the size is small enough it's fine
    const groupedStates = this.statesService
      .getTwoLettersStates(category)
      .map((state) => state.abbreviation);

    // filter out the states that match
    this.states = this.states.filter(
      (state) => !groupedStates.some((groupedState) => groupedState == state)
    );

    // the push them back in, if that's what we need (basically a union)
    if (toggleOn) {
      this.states = this.states.concat(groupedStates);
    }

    this.onStatesChange();
  }

  toggleGroup(group: string) {
    switch (group) {
      case "US":
        this.filterStates(group, this.USSelected);
        break;
      case "MX":
        this.filterStates(group, this.MXSelected);
        break;
      case "CA":
        this.filterStates(group, this.CASelected);
        break;
    }
  }
}
