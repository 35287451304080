import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { GasRegion } from "@app/shared/models/fuel/GasRegion.model";

@Component({
  selector: "app-fuel-regions-selector",
  templateUrl: "./fuel-regions-selector.component.html",
  styleUrls: ["./fuel-regions-selector.component.css"],
})
export class FuelRegionsSelectorComponent implements OnChanges {
  @Input() regions!: GasRegion[];
  @Input() isEditing!: boolean;
  @Input() selectedRegions!: GasRegion[];
  @Output() selectedRegionsChange = new EventEmitter();

  _regions: GasRegion[];
  topLevelRegions: GasRegion[] = [];
  disabledRegions: any = {};

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    // Run after the regions are loaded in
    if (changes.regions && !changes.regions.firstChange) {
      this.formatFuelRegions();
    }
  }

  formatFuelRegions() {
    this._regions = this.regions.map((region) => ({
      ...region,
      children: [],
    }));

    for (const region of this._regions) {
      if (region.gasRegionId) {
        const parent = this._regions.find((r) => r.id === region.gasRegionId);
        parent.children.push(region);
      }
      region.statesString = region.states.sort().join(", ");
      this.disabledRegions[region.id] = false;
    }

    this.topLevelRegions = this._regions.filter((region) => region.level === 1);
    // Initialize the selected regions values to be based on our parsed regions data
    this.selectedRegions = this._regions.filter((region) =>
      this.selectedRegions.some((selectedRegion) => region.id === selectedRegion.id)
    );
    for (const region of this.selectedRegions) {
      this.onRegionChanged(region);
    }
    this.selectedRegionsChange.emit(this.selectedRegions);
  }

  deselectRegion(region: GasRegion) {
    this.selectedRegions = this.selectedRegions.filter(
      (selectedRegion) => selectedRegion.id !== region.id
    );
  }

  setChildrenDisabled(region: GasRegion, disabled: boolean) {
    if (region.children) {
      for (const child of region.children) {
        this.disabledRegions[child.id] = disabled;
        if (disabled) this.deselectRegion(child);
        this.setChildrenDisabled(child, disabled);
      }
    }
  }

  setParentsDisabled(region: GasRegion, disabled: boolean) {
    if (region.gasRegionId) {
      let parent = this._regions.find((r) => r.id === region.gasRegionId);
      this.disabledRegions[parent.id] = disabled;
      if (disabled) this.deselectRegion(parent);
      this.setParentsDisabled(parent, disabled);
    }
  }

  onRegionChanged(region: GasRegion) {
    const isSelected = this.selectedRegions.some(
      (selectedRegion) => selectedRegion.id === region.id
    );
    let someSiblingSelected = isSelected;
    if (!isSelected && region.gasRegionId) {
      // check to see if some siblings are selected
      const parent = this._regions.find((r) => r.id === region.gasRegionId);
      const siblings = parent.children;
      someSiblingSelected = siblings.some((sibling) =>
        this.selectedRegions.some((selectedRegion) => selectedRegion.id === sibling.id)
      );
    }

    // clear and disable all child, grandchildren, parents and grandparents
    if (isSelected) {
      this.setChildrenDisabled(region, true);
    } else {
      this.setChildrenDisabled(region, false);
    }

    if (someSiblingSelected) {
      this.setParentsDisabled(region, true);
    } else {
      this.setParentsDisabled(region, false);
    }

    this.selectedRegionsChange.emit(this.selectedRegions);
  }
}
