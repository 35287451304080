import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@app/shared/services/http/http-client.service";
import { throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Team } from "@app/shared/models/Team";

@Injectable({
  providedIn: "root",
})
export class TeamsService {
  constructor(private router: Router, private _http: HttpClient) {}

  getTeamsList() {
    return this._http.get("/api/team/list").pipe(
      map((res: Team[]) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getTeam(id: string | number) {
    return this._http.get("/api/team/find/" + id).pipe(
      map((res: Team) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getTeamHistory(uuid: string) {
    return this._http.get("/api/team/findTeamHistory/" + uuid).pipe(
      map((res: any) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  createTeam(team: Team) {
    return this._http.post("/api/team/create", team).pipe(
      map((res: any) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateTeam(team: Team) {
    return this._http.patch("/api/team/update", team).pipe(
      map((res: any) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteTeam(teamId: string | number) {
    return this._http.delete("/api/team/delete/" + teamId).pipe(
      map((res: any) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
