<div>
  <div class="form-group">
    <label for="rolName">Role Name:</label>
    <input
      type="text"
      class="form-control"
      id="editNameRole"
      readonly
      [(ngModel)]="selectedRole.rolName"
    />
  </div>
  <div *ngFor="let permission of permissions" style="margin-bottom: 5px">
    <p-checkbox
      [value]="permission.name"
      [(ngModel)]="selectedCategories"
      [inputId]="permission.id"
    ></p-checkbox>
    <label [for]="permission.key" class="permission-name" style="margin-bottom: 0">{{
      permission.name
    }}</label>
  </div>
</div>
<div class="modal-footer">
  <button
    (click)="updateRole()"
    type="button"
    title="Save"
    class="btn btn-primary"
  >
    Save
  </button>
  <button
    (click)="close()"
    type="button"
    title="Close"
    class="btn"
  >
    Close
  </button>
</div>
