import { Injectable } from "@angular/core";

@Injectable()
export class SortArrayService {
  constructor() {}

  sortArray(array, property, direction = null) {
    direction = direction || 1;
    array.sort(function compare(a, b) {
      let comparison = 0;
      const valueA = isNaN(Number(a[property]))
        ? a[property]
        : Number(a[property]);
      const valueB = isNaN(Number(b[property]))
        ? b[property]
        : Number(b[property]);

      if (valueA > valueB) {
        comparison = 1 * direction;
      } else if (valueA < valueB) {
        comparison = -1 * direction;
      }
      return comparison;
    });
    return array; // Chainable
  }

  // get nested children of an object
  // https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
  resolvePath(object: any, path: string, defaultValue = null) {
    return path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);
  }

  fillOutFilters(columns, records) {
    const initInsertedItemsPerColumn = function (columns) {
      let insertedItemsPerColumn = {};
      columns.map((column) => {
        insertedItemsPerColumn[column] = [];
      });
      return insertedItemsPerColumn;
    };

    const initFilterElements = function (columns) {
      let filterElements = {};
      columns.map((column) => {
        filterElements[column] = [];
      });
      return filterElements;
    };

    let filterElements = initFilterElements(columns);
    let insertedItemsPerColumn = initInsertedItemsPerColumn(columns);

    columns.map((column) => {
      records.map((record) => {
        const value = this.resolvePath(record, column);
        if (value != null) {
          let isValueAlreadyAdded =
            insertedItemsPerColumn[column].includes(value);
          if (!isValueAlreadyAdded) {
            insertedItemsPerColumn[column].push(value);
            filterElements[column].push({ label: value, value: value });
          }
        }
      });
    });

    filterElements = this.sortFilters(filterElements, "label");

    return filterElements;
  }

  sortFilters(filterData, sortKey) {
    for (var key in filterData) {
      if (filterData.hasOwnProperty(key)) {
        filterData[key] = this.sortArray(filterData[key], sortKey, 1);
      }
    }
    return filterData;
  }
}
