<div class="panel filterable tripTable container">
  <div align="left">
    <h2>Schedule details</h2>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="col-md-2 control-label" for="textarea">Schedule No</label>
            <div class="col-md-4">
              <div class="input-md">{{ schedule.scheduleNo }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-2 control-label" for="textarea">Client</label>
            <div class="col-md-4">
              <div class="input-md">{{ schedule.Client.name }}</div>
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-2 control-label" for="textarea">Team / Driver </label>
            <div class="col-md-4">
              <div class="input-md">{{ schedule.Driver.name }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-2 control-label" for="textarea">Start Date</label>
            <div class="col-md-4">
              <div class="input-md">{{ schedule.startDate | date : "MM/dd/yyyy" }}</div>
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-2 control-label" for="textarea">End Date</label>
            <div class="col-md-4">
              <div class="input-md">{{ schedule.endDate | date : "MM/dd/yyyy" }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-2 control-label" for="textarea" aria-label="status">Status</label>
            <div class="col-md-4">
              <div class="input-md">{{ schedule.status }}</div>
            </div>
          </div>
          <br />
          <div class="form-group">
            <label class="col-md-2 control-label" for="textarea">Trailer No</label>
            <div class="col-md-4">
              <div class="input-md">{{ schedule.Trailer.trailerNo }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-2 control-label" for="textarea">Product</label>
            <div class="col-md-4">
              <div class="input-md">{{ schedule.Product.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <!-- Textarea -->
      <div class="form-group">
        <label class="col-md-2 control-label" for="textarea">Description</label><br />
        <div class="col-md-4">
          <textarea
            rows="5"
            cols="60"
            id="textarea"
            name="textarea"
            style="background-color: white"
            [(ngModel)]="schedule.desc"
            disabled
          ></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <h2>Trips</h2>
    <table class="table">
      <thead>
        <tr class="filters filtersBar">
          <th><input type="text" class="form-control" placeholder="ID" /></th>
          <th><input type="text" class="form-control" placeholder="Trip No" /></th>
          <th><input type="text" class="form-control" placeholder="Start Date" /></th>
          <th><input type="text" class="form-control" placeholder="End Date" /></th>
          <th><input type="text" class="form-control" placeholder="Driver" /></th>
          <th><input type="text" class="form-control" placeholder="Miles" /></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="titleTable">
          <td class="topTable">ID</td>
          <td class="topTable">Trip No</td>
          <td class="topTable">Start Date</td>
          <td class="topTable">End Date</td>
          <td class="topTable">Driver</td>
          <td class="topTable">Miles</td>
        </tr>
        <tr *ngFor="let trip of schedule.Trips">
          <td>{{ trip.id }}</td>
          <td>{{ trip.tripNo }}</td>
          <td>{{ trip.startDate | date : "MM/dd/yyyy" }}</td>
          <td>{{ trip.endDate | date : "MM/dd/yyyy" }}</td>
          <td>{{ schedule.Driver.name }}</td>
          <td>{{ trip.totalMiles }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
