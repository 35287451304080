import { first } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { LocationsService } from "./locations.service";

@Injectable()
export class LocationsBillToResolver {
  constructor(private locationsService: LocationsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.locationsService.listBillTo().pipe(first());
  }
}
