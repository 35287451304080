import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TchekTablesService } from "@app/tcheck/tchek-tables.service";
import { TcheckService } from "../../tcheck/tcheck.service";
import { ToastrService } from "ngx-toastr";
import { SettlementsService } from "../shared/settlements.service";

@Component({
  selector: "app-driver-expenses",
  templateUrl: "./driverExpenses.component.html",
  styleUrls: ["./driverExpenses.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverExpensesComponent implements OnInit {
  errorMsg: any;
  records = [];
  startDate: Date;
  endDate: Date;
  loading = false;
  loadingRecords = false;
  batchesList = [];
  selectedBatch = null;

  constructor(
    private tcheckService: TcheckService,
    private tchekTables: TchekTablesService,
    private toastr: ToastrService,
    private settlementsService: SettlementsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.settlementsService.getGlobalBatchListDropDown("Active").subscribe(
      (batchList: any) => {
        this.loading = false;
        this.batchesList = batchList;

        if (this.batchesList.length > 0) {
          this.selectedBatch = this.batchesList[0];
          this.refreshRecords();
        }

        this.cdr.markForCheck();
      },
      (resError) => {
        this.toastr.error(resError.message, "Error");
      }
    );
  }

  excelGeneration() {
    const cols = this.tchekTables.driverExpensesCols;
    this.tchekTables.exportToCSV(this.records, cols, "driverExpenses");
  }

  refreshRecords() {
    this.loadingRecords = true;
    this.tcheckService.getDriverExpenses(this.selectedBatch.id).subscribe(
      (res: any) => {
        this.records = res;
        if (this.records.length > 0) {
          this.startDate = new Date(
            Math.min.apply(
              null,
              this.records.map((r) => new Date(r.date))
            )
          );
          this.endDate = new Date(
            Math.max.apply(
              null,
              this.records.map((r) => new Date(r.date))
            )
          );
        }

        this.cdr.markForCheck();
      },
      (error) => (this.errorMsg = error),
      () => (this.loadingRecords = false)
    );
  }
}
