import { Component, OnInit, ViewChild } from "@angular/core";
import { ProductService } from "./shared/products.service";
import { FileUploadService } from "../shared/services/files/fileUpload.service";
import { GetPictureService } from "../shared/services/files/getPictures.service";
import { ToastrService } from "ngx-toastr";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.css"],
})
export class ProductsComponent implements OnInit {
  docType: string;
  newProduct: any = {};
  products: Array<any>;
  selProduct: any;
  originalProduct: any;
  numbers = /^(UN)?\d*\.?\d+$/;
  btn: String;
  modalError: String;
  colsProductInformation;
  is_edit: boolean;
  conversionUnit: string = "Pound";
  litersPerGallon = 3.78541;

  constructor(
    private productService: ProductService,
    private fileUploadService: FileUploadService,
    private getPictureService: GetPictureService,
    private _AuthService: authenticationService,
    private toastr: ToastrService
  ) {
    this.docType = "hazmat";
  }

  ngOnInit() {
    this.modalError = "";
    this.is_edit = false;
    this.productService.list().subscribe((result: any) => {
      this.products = result;
    });

    this.colsProductInformation = [
      { field: "name", header: "Product Name" },
      { field: "un", header: "UN #" },
    ];

    this.productService.getProductTypes();

    $("#addProductModal").on("hide.bs.modal", () => {
      this.modalError = "";
    });
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  sort() {
    this.products.sort((prod1, prod2) => {
      if (prod1.name > prod2.name) return 1;
      if (prod1.name < prod2.name) return -1;
    });
  }

  createNewProduct(newProduct) {
    this.productService.create(newProduct).subscribe((productCreated) => {
      this.products.push(productCreated);
      this.toastr.success("", "Success");
      this.newProduct = {};
      this.sort();
    });
  }

  deleteProduct(product) {
    this.productService.delete(product).subscribe((result) => {
      this.products = this.products.filter((dri) => dri.uuid != product.uuid);
      this.toastr.success("", "Success");
    });
  }

  setDocumentToProduct(event, product) {
    product.updatedDocument = event.target.files;
  }

  updateProduct(product) {
    if (product.updatedDocument) {
      this.fileUploadService
        .uploadFiles(product.updatedDocument, this.docType)
        .subscribe((result) => {
          product.hazardDocument = result[0].path;
          this.productService.update(product).subscribe((status) => {
            product.isEditable = false;
            this.toastr.success("", "Success");
          });
        });
    } else {
      this.productService.update(product).subscribe((result) => {
        product.isEditable = false;
        this.toastr.success("", "Success");
      });
    }
  }

  delete() {
    this.deleteProduct(this.selProduct);
  }

  editOn() {
    if (!this.selProduct.name.length || !this.selProduct.un.length) {
      this.modalError = "Product name and UN # cannot be empty.";
      return;
    }
    if (!this.selProduct.un.toUpperCase().match(this.numbers)) {
      this.modalError = "UN # must be either only numbers or UN followed by numbers.";
      return;
    }
    if (!this.selProduct.un.toUpperCase().startsWith("UN"))
      this.selProduct.un = "UN" + this.selProduct.un;

    this.modalError = this.isUnique();
    if (this.modalError.length) return;
    if (this.btn == "Save changes") {
      this.originalProduct = Object.assign(this.originalProduct, this.selProduct);
      this.updateProduct(this.originalProduct);
    } else {
      this.createNewProduct(this.selProduct);
    }
    this.modalError = "";
  }

  isUnique() {
    for (let i = 0; i < this.products.length; i++) {
      let product: any = this.products[i];
      if (this.selProduct.id != product.id) {
        if (this.selProduct.name.toUpperCase() == product.name.toUpperCase()) {
          return "Product name is already taken.";
        }
        if (this.selProduct.un == product.un) {
          return "Product UN # is already taken.";
        }
      }
    }
    return "";
  }

  getFilePath(path) {
    return this.getPictureService.getPicturePath(path, this.docType);
  }

  viewDetail(product) {
    if (product) {
      this.btn = "Save changes";
      this.selProduct = JSON.parse(JSON.stringify(product));
      this.originalProduct = product;
      // this.router.navigate(['/productinfo/',product.uuid]);
    } else {
      this.btn = "Create product";
      this.selProduct = JSON.parse(JSON.stringify({ name: "", un: "" }));
    }

    this.selProduct.litersPerPound = (
      this.selProduct.gallonsPerPound * this.litersPerGallon
    ).toFixed(3);

    this.selProduct.scfPerTon = this.selProduct.scfPerPound * 2000;
    this.selProduct.gallonsPerTon = this.selProduct.gallonsPerPound * 2000;
    this.selProduct.litersPerTon = (
      this.selProduct.gallonsPerPound *
      this.litersPerGallon *
      2000
    ).toFixed(3);

    ($("#addProductModal") as any).modal("show");
  }

  convertUnits(unit: string | undefined) {
    switch (unit) {
      case "Ton":
        this.selProduct.scfPerPound = this.selProduct.scfPerTon / 2000;
        this.selProduct.gallonsPerPound = this.selProduct.gallonsPerTon / 2000;
        this.selProduct.litersPerPound = (
          this.selProduct.gallonsPerPound * this.litersPerGallon
        ).toFixed(3);
        break;
      case "Pound":
        this.selProduct.scfPerTon = this.selProduct.scfPerPound * 2000;
        this.selProduct.gallonsPerTon = this.selProduct.gallonsPerPound * 2000;
        this.selProduct.litersPerTon = (
          this.selProduct.gallonsPerTon * this.litersPerGallon
        ).toFixed(3);
        break;
      case "Gallon":
        this.selProduct.litersPerPound = (
          this.selProduct.gallonsPerPound * this.litersPerGallon
        ).toFixed(3);
        this.selProduct.litersPerTon = (
          this.selProduct.gallonsPerTon * this.litersPerGallon
        ).toFixed(3);
        break;
      case "Liter":
        this.selProduct.gallonsPerPound = (
          this.selProduct.litersPerPound / this.litersPerGallon
        ).toFixed(3);
        this.selProduct.gallonsPerTon = (
          this.selProduct.litersPerTon / this.litersPerGallon
        ).toFixed(3);
        break;
    }
  }

  updateUnits(unit: string, unit2?: string) {
    this.convertUnits(unit);
    this.convertUnits(unit2);
  }

  updateConversion() {
    switch (this.conversionUnit) {
      case "Pound":
        this.convertUnits("Ton");
        break;
      case "Ton":
        this.convertUnits("Pound");
        break;
    }
  }
}
