<div class="container">
  <div class="form-horizontal">
    <div class="row">
      <div class="col-md-6">
        <h4>Client Profile</h4>
        <fieldset>
          <!-- Text input-->
          <div class="form-group">
            <label class="col-md-6 control-label">Company Name *</label>
            <div class="col-md-6">
              <input
                id="companyName"
                name="companyName"
                type="text"
                [disabled]="!isEditing"
                [(ngModel)]="clientInfo.companyName"
                placeholder="Insert Company Name"
                class="form-control input-md inp"
                aria-label="clientCompanyName"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="company">Valid From *</label>
            <div class="col-md-6">
              <div class="row">
                <div class="col-sm-5">
                  <input
                    id="clientInfovalidFrom"
                    name="clientInfovalidFrom"
                    type="text"
                    [style]="{ opacity: 1, color: '#505050' }"
                    [inputMask]="dateInputMask"
                    autocomplete="off"
                    placeholder="mm/dd/yyyy"
                    class="form-control input-md"
                    [disabled]="!isEditing"
                    [(ngModel)]="clientInfo.validFrom"
                    aria-label="clientValidFrom"
                  />
                </div>
                <div class="col-sm-2" style="text-align: center">
                  <label class="control-label" for="company">To</label>
                </div>
                <div class="col-sm-5">
                  <input
                    id="clientInfovalidTo"
                    name="clientInfovalidTo"
                    type="text"
                    [style]="{ opacity: 1, color: '#505050' }"
                    [inputMask]="dateInputMask"
                    autocomplete="off"
                    placeholder="mm/dd/yyyy"
                    class="form-control input-md"
                    [disabled]="!isEditing"
                    [(ngModel)]="clientInfo.validTo"
                    aria-label="clientValidTo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="companyEmail">Email</label>
            <div class="col-md-6">
              <input
                id="companyEmail"
                name="companyEmail"
                type="email"
                placeholder="Insert Email Address"
                class="form-control input-md"
                [(ngModel)]="clientInfo.email"
                [disabled]="!isEditing"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="companyPhone">Phone Number</label>
            <div class="col-md-6">
              <input
                id="companyPhone"
                name="companyPhone"
                type="text"
                class="form-control input-md"
                [inputMask]="usPhoneMask"
                autocomplete="off"
                placeholder="(XXX) XXX-XXXX"
                [(ngModel)]="clientInfo.phone"
                [disabled]="!isEditing"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="companyAltPhone">Alt Phone</label>
            <div class="col-md-6">
              <input
                id="companyAltPhone"
                name="companyAltPhone"
                type="text"
                [inputMask]="usPhoneMask"
                autocomplete="off"
                placeholder="(XXX) XXX-XXXX"
                class="form-control input-md"
                [(ngModel)]="clientInfo.altPhone"
                [disabled]="!isEditing"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="address">Address *</label>
            <div class="col-md-6">
              <input
                id="address"
                name="address"
                type="text"
                [disabled]="!isEditing"
                [(ngModel)]="clientInfo.address"
                placeholder="Insert Address"
                class="form-control input-md inp"
                aria-label="clientAddress"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-6 control-label" for="address2">Address Line 2</label>
            <div class="col-md-6">
              <input
                id="address2"
                name="address2"
                type="text"
                [disabled]="!isEditing"
                [(ngModel)]="clientInfo.address2"
                placeholder="Insert Address Line 2"
                class="form-control input-md inp"
                aria-label="clientAddress2"
              />
            </div>
          </div>
          <!-- Text input-->
          <div class="form-group">
            <label class="col-md-6 control-label" for="city">City *</label>
            <div class="col-md-6">
              <input
                id="city"
                name="city"
                type="text"
                [disabled]="!isEditing"
                [(ngModel)]="clientInfo.city"
                placeholder="Insert City"
                class="form-control input-md inp"
                aria-label="clientCity"
              />
            </div>
          </div>
          <!-- Text input-->
          <div class="form-group">
            <label class="col-md-6 control-label" for="state">State *</label>
            <div class="col-md-6">
              <select
                class="selectField"
                id="state"
                name="state"
                [disabled]="!isEditing"
                [(ngModel)]="clientInfo.state"
                aria-label="clientState"
              >
                <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                  {{ state.fullName }}
                </option>
              </select>
            </div>
          </div>
          <!-- Text input-->
          <div class="form-group">
            <label class="col-md-6 control-label" for="zip">Zip Code *</label>
            <div class="col-md-6">
              <input
                id="zip"
                name="zip"
                type="text"
                [disabled]="!isEditing"
                [(ngModel)]="clientInfo.zip"
                placeholder="Insert Zip Code"
                class="form-control input-md inp"
                aria-label="clientZip"
              />
            </div>
          </div>

          <!-- Text input-->
          <div class="form-group">
            <label class="col-md-6 control-label" for="clientId">Client Id *</label>
            <div class="col-md-6">
              <input
                id="clientId"
                name="clientId"
                readonly
                type="text"
                maxlength="20"
                [(ngModel)]="clientInfo.customerNo"
                placeholder="Insert Client Id"
                class="form-control input-md inp"
                (keydown.space)="$event.preventDefault()"
                (ngModelChange)="removeClientSpaces()"
              />
            </div>
          </div>
          <!-- Text input-->
          <div class="form-group">
            <label class="col-md-6 control-label" for="contact">Contact Name *</label>
            <div class="col-md-6">
              <input
                id="contactName"
                name="contactName"
                [disabled]="!isEditing"
                [(ngModel)]="clientInfo.contactName"
                type="text"
                placeholder="Contact name"
                class="form-control input-md inp"
                aria-label="clientContactName"
              />
            </div>
          </div>
          <!-- Text input-->
          <div class="form-group">
            <label class="col-md-6 control-label" for="paymentTerms">Payment Terms *</label>
            <div class="col-md-6">
              <input
                id="paymentTerms"
                name="paymentTerms"
                [disabled]="!isEditing"
                [(ngModel)]="clientInfo.billingRange"
                type="text"
                min="0"
                placeholder="Billing range"
                class="form-control input-md inp"
              />
            </div>
          </div>

          <div class="form-group">
            <label class="col-md-6 control-label">Active </label>
            <div class="col-md-6" style="padding-top: 7px">
              <p-checkbox
                [(ngModel)]="clientInfo.isActive"
                [disabled]="!isEditing"
                binary="true"
                name="status"
                role="checkbox"
                aria-label="clientIsActive"
              ></p-checkbox>
            </div>
          </div>

          <div class="form-group">
            <label class="col-md-6 control-label">Has plant number?</label>
            <div class="col-md-6">
              <p-checkbox
                [(ngModel)]="clientInfo.hasPlantNumber"
                name="hasPlantNumber"
                binary="true"
                [disabled]="!isEditing"
              ></p-checkbox>
            </div>
          </div>
          <hr />
        </fieldset>
      </div>
      <div class="col-md-6">
        <div class="col-md-11" style="padding-left: 0">
          <h4 style="display: inline-block">Bill to list</h4>
          <div class="toggle-box pull-right" style="margin-top: 15px">
            <p-checkbox [(ngModel)]="showInactive" binary="true" inputId="binary" class="mr-2">
            </p-checkbox>
            <label for="binary">Show Inactive</label>
          </div>
        </div>

        <div
          class="row"
          *ngFor="let billToClient of clientInfo.children; let i = index; trackBy: billToTrackBy"
        >
          <div class="col-md-11" *ngIf="billToClient.isActive || showInactive">
            <p-accordion>
              <p-accordionTab>
                <p-header>
                  {{ billToClient.companyName }}
                </p-header>
                <app-bill-to
                  [index]="i"
                  [billTo]="billToClient"
                  [companyName]="clientInfo.companyName"
                  [states]="states"
                  (billToEdited)="billToEdited($event)"
                  [disableInputs]="!isEditing"
                ></app-bill-to>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
        <div class="row" *ngFor="let billToClient of newBillToList; let i = index">
          <div class="col-md-11">
            <p-accordion>
              <p-accordionTab>
                <p-header> * {{ billToClient.companyName }} </p-header>
                <app-bill-to
                  [index]="i + 100"
                  [billTo]="billToClient"
                  [companyName]="clientInfo.companyName"
                  [states]="states"
                ></app-bill-to>
              </p-accordionTab>
            </p-accordion>
            <button
              type="button"
              class="btn btn-default"
              (click)="deleteJustAddedBillTo(i)"
              *ngIf="isEditing"
            >
              <span class="glyphicon glyphicon-trash"></span>
            </button>
          </div>
        </div>
        <div>
          <div *ngIf="showNewBillToForm">
            <h4>New Bill to</h4>
            <button type="button" class="btn btn-Information" (click)="useTheClientAsContact()">
              Auto Complete Bill To Fields
            </button>
            <app-bill-to
              [index]="99999"
              #billTo
              [companyName]="clientInfo.companyName"
              [states]="states"
            ></app-bill-to>
            <button type="button" class="btn btn-Information mr-2" (click)="addBillTo()">
              Add bill to
            </button>
            <button
              type="button"
              class="btn btn-danger"
              [disabled]="!isEditing"
              *ngIf="showNewBillToForm"
              (click)="showNewBillToForm = false"
            >
              Cancel
            </button>
          </div>
          <button
            pButton
            type="button"
            icon="pi pi-plus"
            class="p-button-success"
            *ngIf="!showNewBillToForm && isEditing"
            (click)="showNewBillToForm = true"
          ></button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group" align="center">
          <button
            id="cancelEdit"
            name="cancelEdit"
            class="btn btn-secondary mr-2"
            (click)="cancelEdit()"
            *ngIf="isEditing"
          >
            Cancel
          </button>
          <button
            id="delete"
            name="delete"
            class="btn btn-danger mr-2"
            (click)="confirmationDelete()"
            *ngIf="isEditing && !clientInfo.dateDeletedUtc"
          >
            Delete
          </button>
          <button
            id="restore"
            name="restore"
            class="btn btn-primary mr-2"
            (click)="restoreClient()"
            *ngIf="clientInfo.dateDeletedUtc"
          >
            Restore
          </button>
          <button
            *ngIf="hasPermission('ClientsChange') && !clientInfo.dateDeletedUtc"
            id="edit"
            name="edit"
            class="btn btn-primary mr-2"
            (click)="editOn()"
          >
            {{ btnEdit }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '50vw' }"
></p-confirmDialog>
<p-confirmDialog
  key="deleteClientDialog"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
