<p-accordion>
  <p-accordionTab header="History">
    <div style="display: inline-block">
      <p-table
        #historyTable
        [columns]="columns"
        [value]="filteredEntries"
        sortMode="multiple"
        [scrollable]="true"
        scrollHeight="700px"
      >
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width.px]="col.width" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" style="text-align: center" [ngSwitch]="col.field">
              <input
                *ngSwitchDefault
                pInputText
                type="text"
                (input)="historyTable.filter($event.target.value, col.field, 'contains')"
                [style.width]="col.width"
              />
              <div class="d-flex" *ngSwitchCase="'dateCreatedUtc'">
                <input
                  id="startDate"
                  name="startDate"
                  type="text"
                  [inputMask]="dateInputMask"
                  autocomplete="off"
                  placeholder="mm/dd/yyyy"
                  appendTo="body"
                  [(ngModel)]="startDate"
                />
                -
                <input
                  id="endDate"
                  name="endDate"
                  type="text"
                  [inputMask]="dateInputMask"
                  autocomplete="off"
                  placeholder="mm/dd/yyyy"
                  appendTo="body"
                  [(ngModel)]="endDate"
                />
                <p-button
                  label="Filter"
                  (onClick)="filterByDate()"
                  [disabled]="!startDate || !endDate"
                  [style]="{ 'margin-left': '1rem' }"
                ></p-button>
              </div>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-entry>
          <tr>
            <td>{{ entry.dateCreatedUtc | date : "short" }}</td>
            <td>{{ entry.name }}</td>
            <td>{{ entry.field }}</td>
            <td>{{ entry.value }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-accordionTab>
</p-accordion>
