import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShipperService } from './shipper.service'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-shipper',
  templateUrl: './shipper.component.html',
  styleUrls: ['./shipper.component.css']
})
export class ShipperComponent implements OnInit {

  clients:Array<any>;
  shippers:Array<any>;
  clientSelectedId:number = null;
  newShipper:any= {selectedClient:null};
  constructor(private route: ActivatedRoute,private shipperService: ShipperService, private toastr: ToastrService) { }

  ngOnInit() {
    this.clients = this.route.snapshot.data['clients']; 
    this.shipperService.list().subscribe(result => {
      this.shippers = result;
    });
  }

  clientSelected(clientId) {
    this.clientSelectedId = clientId;
    this.shipperService.listByClient(clientId).subscribe((shippers:any) => {
      this.shippers = shippers;
    })
  }

  update(shipper) {
    this.shipperService.update(shipper).subscribe((shippers:any) => {
      this.toastr.success('shipper updated','Success')
    })
  }

  create(shipper) {
    this.shipperService.create(shipper).subscribe((shipper:any) => {
      this.toastr.success('shipper created','Success')
      if(shipper.ClientId === Number(this.clientSelectedId)) {
        this.shippers.push(shipper);
      }      
    })
  }

  delete(shipper,index) {

    let conf = confirm("Are you sure you want to continue?")
        if(conf == true){         
          this.shipperService.delete(shipper.uuid).subscribe((shipper:any) => {
            this.toastr.success('Consignee deleted','Success')
            this.shippers.splice(index,1);   
          })
        }

  }

}
