import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { TeamsService } from "../shared/teams.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "app-teamslist",
  templateUrl: "./teamslist.component.html",
  styleUrls: ["./teamslist.component.css"],
})
export class TeamslistComponent implements OnInit {
  teams: any[] = [];
  cols: any = [];
  filterElements: any = [];

  constructor(
    private router: Router,
    private authService: authenticationService,
    private teamsService: TeamsService
  ) {}

  ngOnInit(): void {
    this.cols = [
      { field: "name", header: "Name" },
      { field: "driversList", header: "Drivers" },
    ];

    this.teamsService.getTeamsList().subscribe({
      next: (teams) => {
        this.teams = teams.map((team) => ({
          ...team,
          driversList: team.drivers
            .map((driver: any) => `${driver.name} ${driver.lastName}`)
            .join(", "),
        }));
      },
      error: (error) => console.error(error),
    });
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  viewDetail(team: any) {
    this.router.navigate(["/teams/", team.id]);
  }
}
