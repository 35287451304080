<hr />
<div class="row">
  <div class="col-md-6">
    <div class="row form-group">
      <label class="col-md-4">Insurance Surcharge (0.02 = 2%)</label>
      <div class="col-md-8">
        <input
          *ngIf="isEditing; else amountPreview"
          type="number"
          [(ngModel)]="amount"
          min="0"
          step="any"
          class="form-control p-inputtext"
        />
        <ng-template #amountPreview>
          <div>
            {{ amount }}
          </div>
        </ng-template>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-md-4">Included Charges</label>
      <div class="col-md-8">
        <p-multiSelect
          *ngIf="isEditing; else includedChargesPreview"
          [filter]="false"
          [options]="includedChargesList"
          [(ngModel)]="includedCharges"
          [style]="{ minWidth: '100%' }"
          tabbableMultiselect
        ></p-multiSelect>
        <ng-template #includedChargesPreview>
          <div>
            <div *ngFor="let charge of includedChargeNames">
              {{ charge }}
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
