import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TractorService } from "../shared/tractor.service";
import { GetPictureService } from "../../../app/shared/services/files/getPictures.service";
import { Observable, Observer } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { StatesServices } from "../../shared/services/states/states.service";
import { TerminalLocationsServices } from "../../shared/services/terminals/terminals.service";
import { MaskService } from "../../../app/shared/services/mask/mask.service";
import { FileUploadService } from "../../../app/shared/services/files/fileUpload.service";
import { ConfirmationService } from "primeng/api";
import { CanComponentDeactivate } from "../../shared/guards/confirmation/confirmation.guard";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { TractorViewModel } from "@app/shared/models/tractors/TractorViewModel.model";
import { CreateDocumentComponent } from "@app/shared/components/document/createDocument/createDocument.component";

@Component({
  selector: "app-truckinfo",
  templateUrl: "./truckinfo.component.html",
  styleUrls: ["./truckinfo.component.css"],
})
export class TruckinfoComponent implements OnInit, CanComponentDeactivate {
  @ViewChild("tractorDocument", { static: true }) tractorDocument: CreateDocumentComponent;
  states: { abbreviation: string; fullName: string }[];
  id: string;
  errorMsg: any;
  tractorInfo: TractorViewModel = new TractorViewModel();
  is_edit: boolean;
  btnProfile: string;
  isProcessing = false;
  terminals: Array<string>;
  originalInfo: any;
  newProfileFile: any;
  profilePicture: any;
  driversWithNoTrailerAssigned: Array<any>;
  letters = /^[A-Za-z]+$/;
  numbers = /^\d*\.?\d+$/;
  documentsColapsed: boolean;
  docType: string = "tractor";
  dateInputMask: any;

  constructor(
    private statesServices: StatesServices,
    private tractorDataService: TractorService,
    private getPictureService: GetPictureService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private maskService: MaskService,
    private terminalLocationsServices: TerminalLocationsServices,
    private fileUploadService: FileUploadService,
    private _AuthService: authenticationService,
    private _ConfirmationService: ConfirmationService
  ) {
    this.is_edit = true;
    this.btnProfile = "Edit Profile";
  }

  ngOnInit() {
    this.documentsColapsed = false;
    this.profilePicture = "assets/img/truck.jpg";
    this.dateInputMask = this.maskService.getInputDateOnlyMask();

    this.terminals = this.terminalLocationsServices.getTerminalLocations();
    this.states = this.statesServices.getTwoLettersStates("US");
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.driversWithNoTrailerAssigned = this.route.snapshot.data.driversWithNoTrailerAssigned;
    });

    this.tractorDataService.getTractorAsync(this.id).then((result: TractorViewModel) => {
      this.tractorInfo = result;
      if (result.driver) {
        this.driversWithNoTrailerAssigned.push(result.driver);
      }
      if (this.tractorInfo.picture) {
        this.profilePicture = this.getFilePath(this.tractorInfo.picture);
      }
      this.formatDates();
      this.tractorDocument.getDocuments(
        ("web" + this.docType + "_files").toUpperCase(),
        this.tractorInfo.id
      );
    });

    // we are inserting the css using JS because the compoenent doesnt allow us to change the css DO NOT DELETE/EDIT/MOVE this script
    const styleDatatable = document.createElement("style");
    styleDatatable.type = "text/css";
    styleDatatable.innerHTML = ".owl-datetime-main-input{ font-size:13px !important; }";
    document.getElementsByTagName("head")[0].appendChild(styleDatatable);
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  confirm() {
    if (!this.is_edit) {
      return window.confirm("Do you want to leave without saving any changes?");
    } else {
      return true;
    }
  }

  editOn(tractor) {
    if (this.btnProfile === "Edit Profile") {
      this.originalInfo = JSON.stringify(this.tractorInfo);
      this.btnProfile = "Save Changes";
      this.is_edit = false;
    } else {
      this.isProcessing = true;

      if (this.newProfileFile) {
        this.uploadNewImage().subscribe((url) => {
          this.tractorInfo.picture = this.newProfileFile.name;
          this.uploadDocuments(tractor.id).subscribe((documentsCreated) => {
            this.updateTractor(tractor);
          });
        });
      } else {
        this.uploadDocuments(tractor.id).subscribe((documentsCreated) => {
          this.updateTractor(tractor);
        });
      }
    }
  }
  getPicturePath(name) {
    if (typeof name !== "undefined") {
      return this.getPictureService.getPicturePath(name, this.docType);
    }
  }

  dateChanged(event, param, justDate, yearDigits) {
    if (event.code === "Delete" || event.code === "Backspace") {
      return;
    }
    this.maskService.maskDate(event, param, justDate, yearDigits);
  }
  cancelEdit() {
    this.is_edit = true;
    this.btnProfile = "Edit Profile";
    this.tractorInfo = JSON.parse(this.originalInfo);
    this.formatDates();
  }
  formatDates() {
    this.tractorInfo.dateSold = this.tractorInfo.dateSold
      ? new Date(this.tractorInfo.dateSold)
      : null;
    this.tractorInfo.datePurchased = this.tractorInfo.datePurchased
      ? new Date(this.tractorInfo.datePurchased)
      : null;
    this.tractorInfo.licenseExpirationDate = this.tractorInfo.licenseExpirationDate
      ? new Date(this.tractorInfo.licenseExpirationDate)
      : null;
  }
  getFilePath(path) {
    console.log(this.docType);
    return this.getPictureService.getPicturePath(path, this.docType);
  }
  getTemporalImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.newProfileFile = event.target.files[0];
      this.profilePicture = this.getPictureService.getPreviewImage(event.target.files[0]);
    }
  }

  uploadNewImage() {
    return this.fileUploadService.uploadSingleFileRename(
      [this.newProfileFile],
      this.docType,
      this.tractorInfo.unitNumber + "_" + this.newProfileFile.name
    );
  }

  uploadDocuments(tractorId) {
    return this.tractorDocument.uploadDocuments(
      { TractorId: tractorId },
      this.docType + "_files",
      tractorId
    );
  }

  updateTractor(tractor) {
    this.tractorDataService.updateTractor(tractor).subscribe(
      () => {
        this.originalInfo = JSON.stringify(tractor);
        this.isProcessing = false;
        this.toastr.success("Tractor updated", "Success!");
        this.is_edit = true;
        this.btnProfile = "Edit Profile";
        this.router.navigate(["/tractorlist"]);
      },
      (errors) => {
        for (const error of errors) {
          this.toastr.warning(error, "Alert!", {
            closeButton: true,
            enableHtml: true,
          });
          console.dir(error);
        }
        this.isProcessing = false;
      }
    );
  }

  validateMandatoryFields(tractor) {
    if (!tractor.unitNumber || tractor.unitNumber.trim() === "") {
      this.toastr.warning("UNIT NUMBER - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.terminalLocation || tractor.terminalLocation.trim() === "") {
      this.toastr.warning("TERMINAL LOCATION - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.dateSold) {
      this.toastr.warning("DATE SOLD - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.year || tractor.year.trim() === "") {
      this.toastr.warning("YEAR - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.datePurchased) {
      this.toastr.warning("DATE PURCHASED - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.make || tractor.make.trim() === "") {
      this.toastr.warning("MAKE - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.purchasePrice || tractor.purchasePrice.trim() === "") {
      this.toastr.warning("PURCHASE PRICE - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.model || tractor.model.trim() === "") {
      this.toastr.warning("MODEL - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.length || tractor.length.trim() === "") {
      this.toastr.warning("TRACTOR LENGTH (FT) - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.height || tractor.height.trim() === "") {
      this.toastr.warning("HEIGHT (FT) - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.fuelType || tractor.fuelType.trim() === "") {
      this.toastr.warning("FUEL TYPE - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.width || tractor.width.trim() === "") {
      this.toastr.warning("FUEL TYPE - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.TcheckCardNo || tractor.TcheckCardNo.trim() === "") {
      this.toastr.warning("FUEL CARD NUMBER - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.ladenWeight || tractor.ladenWeight.trim() === "") {
      this.toastr.warning("LADEN WEIGHT (LB) - <b>Required<b/>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.stateOfRegistration || tractor.stateOfRegistration.trim() === "") {
      this.toastr.warning("STATE OF REGISTRATION - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.unladenWeight || tractor.unladenWeight.trim() === "") {
      this.toastr.warning("UNLADEN WEIGHT (LB) - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.licenseNumber || tractor.licenseNumber.trim() === "") {
      this.toastr.warning("LICENSE PLATE - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.axles || tractor.axles.trim() === "") {
      this.toastr.warning("AXLES - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.licenseExpirationDate) {
      this.toastr.warning("LICENSE EXPIRATION DATE - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.steerTireSize || tractor.steerTireSize.trim() === "") {
      this.toastr.warning("STEER TIRE SIZE (IN) - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.engineManufacturer || tractor.engineManufacturer.trim() === "") {
      this.toastr.warning("ENGINE MANUFACTURER - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.driveTireSize || tractor.driveTireSize.trim() === "") {
      this.toastr.warning("DRIVE TIRE SIZE (IN) - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.engineModel || tractor.engineModel.trim() === "") {
      this.toastr.warning("ENGINE MODEL - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.accumulatedMiles || tractor.accumulatedMiles.trim() === "") {
      this.toastr.warning("ACCUMULATED MILES - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.engineSerialNumber || tractor.engineSerialNumber.trim() === "") {
      this.toastr.warning("ENGINE SERIAL # - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.insurancePolicyNumber || tractor.insurancePolicyNumber.trim() === "") {
      this.toastr.warning("INSURANCE POLICY NUMBER - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.DriverId || tractor.DriverId.trim() === "") {
      this.toastr.warning("TRACTOR DRIVER - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.afterTreatmentYearWarranty || tractor.afterTreatmentYearWarranty.trim() === "") {
      this.toastr.warning("AFTER TREATMENT YEAR WARRANTY - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.insuranceName || tractor.insuranceName.trim() === "") {
      this.toastr.warning("INSURANCE NAME - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.baseWarrantyMiles || tractor.baseWarrantyMiles.trim() === "") {
      this.toastr.warning("BASE WARRANTY MILES - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.baseManuFactureWarranty || tractor.baseManuFactureWarranty.trim() === "") {
      this.toastr.warning("BASE MANUFACTURE WARRANTY - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.engineManufactureWarranty || tractor.engineManufactureWarranty.trim() === "") {
      this.toastr.warning("ENGINE MANUFACTURE WARRANTY - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.baseWarrantyYear || tractor.baseWarrantyYear.trim() === "") {
      this.toastr.warning("BASE WARRANTY YEAR - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.engineYearWarranty || tractor.engineYearWarranty.trim() === "") {
      this.toastr.warning("ENGINE YEAR WARRANTY - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.engineMilesWarranty || tractor.engineMilesWarranty.trim() === "") {
      this.toastr.warning("ENGINE MILES WARRANTY - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.afterTreatmentMilesWarranty || tractor.afterTreatmentMilesWarranty.trim() === "") {
      this.toastr.warning("AFTER TREATMENT MILES - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (
      !tractor.afterTreatmentManufactureWarranty ||
      tractor.afterTreatmentManufactureWarranty.trim() === ""
    ) {
      this.toastr.warning("AFTER TREATMENT MANUFACTURE - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.equipmentCode || tractor.equipmentCode.trim() === "") {
      this.toastr.warning("EQUIPMENT CODE - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.owner || tractor.owner.trim() === "") {
      this.toastr.warning("OWNER - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.mobileDeviceType || tractor.mobileDeviceType.trim() === "") {
      this.toastr.warning("MOBILE DEVICE TYPE - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.mobileDeviceNumber || tractor.mobileDeviceNumber.trim() === "") {
      this.toastr.warning("MOBILE DEVICE NUMBER - <b>Required</b>", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    return true;
  }

  validateTractorFields(tractor) {
    if (!tractor.unitNumber.match(this.numbers)) {
      this.toastr.warning("UNIT NUMBER can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.year.match(this.numbers)) {
      this.toastr.warning("YEAR can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    } else if (tractor.year.length > 4) {
      this.toastr.warning("YEAR format is not correct (YYYY)", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.make.match(this.letters)) {
      this.toastr.warning("MAKE can only be letters.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.purchasePrice.match(this.numbers)) {
      this.toastr.warning("PURCHASE PRICE can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.length.match(this.numbers)) {
      this.toastr.warning("TRACTOR LENGTH (FT) can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.height.match(this.numbers)) {
      this.toastr.warning("HEIGHT (FT) can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.width.match(this.numbers)) {
      this.toastr.warning("FUEL TYPE can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.TcheckCardNo.match(this.numbers)) {
      this.toastr.warning("FUEL CARD NUMBERE can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.ladenWeight.match(this.numbers)) {
      this.toastr.warning("LADEN WEIGHT (LB) can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.unladenWeight.match(this.numbers)) {
      this.toastr.warning("UNLADEN WEIGHT (LB) can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.licenseNumber.match(this.numbers)) {
      this.toastr.warning("LICENSE PLATE can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.axles.match(this.numbers)) {
      this.toastr.warning("AXLES can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.steerTireSize.match(this.numbers)) {
      this.toastr.warning("STEER TIRE SIZE (IN) can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.engineManufacturer.match(this.letters)) {
      this.toastr.warning("ENGINE MANUFACTURER can only be letters.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.driveTireSize.match(this.numbers)) {
      this.toastr.warning("DRIVE TIRE SIZE (IN) can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.engineModel.match(this.numbers)) {
      this.toastr.warning("ENGINE MODEL can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.accumulatedMiles.match(this.numbers)) {
      this.toastr.warning("ACCUMULATED MILES can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.engineSerialNumber.match(this.numbers)) {
      this.toastr.warning("ENGINE SERIAL # can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.insurancePolicyNumber.match(this.numbers)) {
      this.toastr.warning("INSURANCE POLICY NUMBER can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }
    if (!tractor.afterTreatmentYearWarranty.match(this.numbers)) {
      this.toastr.warning("AFTER TREATMENT YEAR WARRANTY can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    } else if (tractor.afterTreatmentYearWarranty.length > 4) {
      this.toastr.warning("AFTER TREATMENT YEAR WARRANTY format is not correct (YYYY)", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.insuranceName.match(this.letters)) {
      this.toastr.warning("INSURANCE NAME can only be letters.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.baseWarrantyMiles.match(this.numbers)) {
      this.toastr.warning("BASE WARRANTY MILES can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.baseWarrantyYear.match(this.numbers)) {
      this.toastr.warning("BASE WARRANTY YEAR can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    } else if (tractor.baseWarrantyYear.length > 4) {
      this.toastr.warning("BASE WARRANTY YEAR format is not correct (YYYY)", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }

    if (!tractor.mobileDeviceNumber.match(this.numbers)) {
      this.toastr.warning("MOBILE DEVICE NUMBER can only be numbers.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      return false;
    }
    return true;
  }
}
