<div>
  <div class="form-group">
    <label class="col-md-2 control-label" for="billToName">Name *</label>
    <div class="col-md-9">
      <input
        id="billToName"
        name="billToName"
        type="text"
        placeholder=""
        class="form-control input-md"
        [(ngModel)]="newBillTo.companyName"
        (ngModelChange)="infoEdited(newBillTo); generateBillingId()"
        [disabled]="disableInputs"
        aria-label="billToName"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToValidFrom">Valid From *</label>
    <div class="col-md-9">
      <div class="row">
        <div class="col-sm-5">
          <input
            id="billToValidFrom"
            name="billToValidFrom"
            type="text"
            aria-label="billToValidFrom"
            [style]="{ opacity: 1, color: '#505050' }"
            [inputMask]="dateInputMask"
            autocomplete="off"
            placeholder="mm/dd/yyyy"
            class="form-control input-md"
            [disabled]="disableInputs"
            (focusout)="infoEdited(newBillTo)"
            [(ngModel)]="newBillTo.validFrom"
            aria-label="billToValidFrom"
          />
        </div>
        <div class="col-sm-2" style="text-align: center">
          <label class="control-label" for="billToValidTo">To</label>
        </div>
        <div class="col-sm-5">
          <input
            id="billToValidTo"
            name="billToValidTo"
            type="text"
            aria-label="billToValidTo"
            [style]="{ opacity: 1, color: '#505050' }"
            [inputMask]="dateInputMask"
            autocomplete="off"
            placeholder="mm/dd/yyyy"
            class="form-control input-md"
            [disabled]="disableInputs"
            (focusout)="infoEdited(newBillTo)"
            [(ngModel)]="newBillTo.validTo"
            aria-label="billToValidTo"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToAddress">Address *</label>
    <div class="col-md-9">
      <input
        id="billToAddress"
        name="billToAddress"
        type="text"
        placeholder=""
        class="form-control input-md"
        [(ngModel)]="newBillTo.address"
        (ngModelChange)="infoEdited(newBillTo); generateBillingId()"
        [disabled]="disableInputs"
        aria-label="billToAddress"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToAddress2">Address Line 2</label>
    <div class="col-md-9">
      <input
        id="billToAddress2"
        name="billToAddress2"
        type="text"
        placeholder=""
        class="form-control input-md"
        [(ngModel)]="newBillTo.address2"
        (ngModelChange)="infoEdited(newBillTo); generateBillingId()"
        [disabled]="disableInputs"
        aria-label="billToAddress2"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToCity">City *</label>
    <div class="col-md-9">
      <input
        id="billToCity"
        name="billToCity"
        type="text"
        placeholder=""
        class="form-control input-md"
        [(ngModel)]="newBillTo.city"
        [disabled]="disableInputs"
        (ngModelChange)="infoEdited(newBillTo); generateBillingId()"
        aria-label="billToCity"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToState">State *</label>
    <div class="col-md-9">
      <select
        class="selectField"
        id="billToState"
        name="billToState"
        [(ngModel)]="newBillTo.state"
        (ngModelChange)="infoEdited(newBillTo); generateBillingId()"
        [disabled]="disableInputs"
        aria-label="billToState"
      >
        <option *ngFor="let state of states" [ngValue]="state.abbreviation">
          {{ state.fullName }}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToZip">Zip *</label>
    <div class="col-md-9">
      <input
        id="billToZip"
        name="billToZip"
        type="text"
        placeholder=""
        class="form-control input-md"
        [(ngModel)]="newBillTo.zip"
        (ngModelChange)="infoEdited(newBillTo); generateBillingId()"
        [disabled]="disableInputs"
        aria-label="billToZip"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToContactName">Contact Name</label>
    <div class="col-md-9">
      <input
        id="billToContactName"
        name="billToContactName"
        type="text"
        placeholder=""
        class="form-control input-md"
        [(ngModel)]="newBillTo.contactName"
        [disabled]="disableInputs"
        (ngModelChange)="infoEdited(newBillTo); generateBillingId()"
        aria-label="billToContactName"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToContactPhone">Contact Phone</label>
    <div class="col-md-9">
      <input
        id="billToContactPhone"
        name="billToContactPhone"
        type="text"
        autocomplete="off"
        [inputMask]="usPhoneMask"
        placeholder="(XXX) XXX-XXXX"
        class="form-control input-md"
        [disabled]="disableInputs"
        [(ngModel)]="newBillTo.phone"
        (ngModelChange)="infoEdited(newBillTo)"
        aria-label="billToContactPhone"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToEmail">Contact Email</label>
    <div class="col-md-9">
      <input
        id="billToEmail"
        name="billToEmail"
        type="text"
        placeholder=""
        class="form-control input-md"
        [(ngModel)]="newBillTo.email"
        (ngModelChange)="infoEdited(newBillTo)"
        [disabled]="disableInputs"
        aria-label="billToContactEmail"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToInvoiceContent">Invoice Content *</label>
    <div class="col-md-9">
      <select
        [(ngModel)]="newBillTo.invoiceContent"
        (ngModelChange)="infoEdited(newBillTo)"
        class="form-control input-md"
        [disabled]="disableInputs"
        aria-label="invoiceContent"
      >
        <option>INVOICE ONLY</option>
        <option>INVOICE AND BACKUP</option>
      </select>
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToInvoiceEmail">Invoice Email *</label>
    <div class="col-md-9">
      <input
        id="billToInvoiceEmail"
        name="billToInvoiceEmail"
        type="text"
        placeholder=""
        class="form-control input-md"
        [(ngModel)]="newBillTo.invoiceEmail"
        (ngModelChange)="infoEdited(newBillTo)"
        [disabled]="disableInputs"
        aria-label="billToInvoiceEmail"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToId">Bill To ID *</label>
    <div class="col-md-9">
      <input
        id="billToId"
        name="billToId"
        type="text"
        placeholder=""
        maxlength="20"
        class="form-control input-md"
        [(ngModel)]="newBillTo.customerNo"
        (ngModelChange)="infoEdited(newBillTo)"
        [readonly]="newBillTo.id"
        (keydown.space)="$event.preventDefault()"
        (ngModelChange)="removeBillToSpaces()"
        aria-label="billToId"
      />
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToRates">Rates</label>
    <div class="col-md-9">
      <app-edit-rates-table
        [index]="index"
        [clientId]="newBillTo.id"
        [isEditing]="!disableInputs"
        [(rates)]="newBillTo.rates"
        (ratesChange)="infoEdited(newBillTo)"
      ></app-edit-rates-table>
    </div>
  </div>

  <div class="form-group">
    <label class="col-md-2 control-label" for="billToComments">Comments</label>
    <div class="col-md-9">
      <textarea
        [rows]="5"
        [cols]="30"
        pInputTextarea
        autoResize="autoResize"
        [(ngModel)]="newBillTo.comments"
        (ngModelChange)="infoEdited(newBillTo)"
        [disabled]="disableInputs"
        aria-label="comments"
      ></textarea>
    </div>
  </div>
</div>
