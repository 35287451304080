<div class="container">
  <div class="col-md-12">
    <div>
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" [class.active]="currentTab == 'Client'">
          <a class="nav-link" role="tab" (click)="setTab('Client')"
            >Client Rates</a
          >
        </li>
        <li class="nav-item" [class.active]="currentTab == 'Driver'">
          <a class="nav-link" role="tab" (click)="setTab('Driver')"
            >Driver Rates</a
          >
        </li>
        <li class="nav-item" [class.active]="currentTab == 'Custom'">
          <a class="nav-link" role="tab" (click)="setTab('Custom')"
            >Custom Charges</a
          >
        </li>
        <li class="nav-item" [class.active]="currentTab == 'Longevity'">
          <a class="nav-link" role="tab" (click)="setTab('Longevity')"
            >Longevity</a
          >
        </li>
        <li class="nav-item" [class.active]="currentTab == 'Fuel'">
          <a class="nav-link" role="tab" (click)="setTab('Fuel')"
            >Fuel Surcharges</a
          >
        </li>
        <li class="nav-item" [class.active]="currentTab == 'Insurance'">
          <a class="nav-link" role="tab" (click)="setTab('Insurance')"
            >Insurance Surcharges</a
          >
        </li>
      </ul>
    </div>

    <div class="tab-content white-bg-tabs">
      <div *ngIf="currentTab == 'Client'">
        <app-client-rates [rates]="clientRates"></app-client-rates>
      </div>
      <div *ngIf="currentTab == 'Driver'">
        <app-driver-rates [rates]="driverRates"></app-driver-rates>
      </div>
      <div *ngIf="currentTab == 'Custom'">
        <app-generic-rates
          [rates]="customRates"
          name="Custom"
          fullName="Custom Charges"
        ></app-generic-rates>
      </div>
      <div *ngIf="currentTab == 'Longevity'">
        <app-generic-rates
          [rates]="longevityRates"
          name="Longevity"
          fullName="Longevity"
        ></app-generic-rates>
      </div>
      <div *ngIf="currentTab == 'Fuel'">
        <app-generic-rates
          [rates]="fuelRates"
          name="Fuel"
          fullName="Fuel Surcharges"
        ></app-generic-rates>
      </div>
      <div *ngIf="currentTab == 'Insurance'">
        <app-generic-rates
          [rates]="insuranceRates"
          name="Insurance"
          fullName="Insurance Surcharges"
        ></app-generic-rates>
      </div>
    </div>
  </div>
</div>
