import { throwError as observableThrowError, Observable } from "rxjs";
import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { HttpClient } from "../shared/services/http/http-client.service";

// Unused
@Injectable()
export class ShipperService {
  constructor(private router: Router, private _http: HttpClient) {}

  getInfo(shipperId) {
    return (this._http.post("api/shipper/getById", { shipperId }) as any)
      .map((res) => {
        return res;
      })
      .catch((error: any) => observableThrowError(error.message || "Server error"));
  }
  list() {
    return (this._http.get("api/shipper/listAll") as any)
      .map((res) => {
        return res;
      })
      .catch((error: any) => observableThrowError(error.message || "Server error"));
  }

  listByClient(clientId) {
    return (this._http.post("api/shipper/listByClient", { clientId }) as any)
      .map((res) => {
        return res;
      })
      .catch((error: any) => observableThrowError(error.message || "Server error"));
  }
  update(shipper) {
    return (
      this._http.patch("api/shipper/update", {
        ClientId: shipper.ClientId,
        name: shipper.name,
        number: shipper.number,
        id: shipper.id,
      }) as any
    )
      .map((res) => {
        return res;
      })
      .catch((error: any) => observableThrowError(error.message || "Server error"));
  }

  create(shipper) {
    return (
      this._http.post("api/shipper/create/", {
        ClientId: shipper.selectedClient,
        name: shipper.name,
        number: shipper.number,
      }) as any
    )
      .map((res) => {
        return res;
      })
      .catch((error: any) => observableThrowError(error.message || "Server error"));
  }

  delete(uuid) {
    return (this._http.delete("api/shipper/delete/" + uuid) as any)
      .map((res) => {
        return res;
      })
      .catch((error: any) => observableThrowError(error.message || "Server driverInfo error"));
  }
}
