<form (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label for="name">Name</label>
    <input
      type="text"
      class="form-control"
      name="name"
      [ngModel]="chargeType.name"
      (ngModelChange)="chargeType.name = $event.toUpperCase()"
      autofocus
    />
  </div>
  <div class="form-group">
    <label for="description">Description</label>
    <input
      type="text"
      class="form-control"
      name="description"
      [(ngModel)]="chargeType.description"
      (ngModelChange)="chargeType.description = $event.toUpperCase()"
    />
  </div>
  <div class="form-group">
    <label for="revenueGL">Revenue GL Account</label>
    <input type="text" class="form-control" name="revenueGL" [(ngModel)]="chargeType.revenueGL" />
  </div>
  <div class="form-group">
    <label for="expenseGL">Expense GL Account</label>
    <input type="text" class="form-control" name="expenseGL" [(ngModel)]="chargeType.expenseGL" />
  </div>
  <div class="form-group">
    <label for="otherGL">Other GL</label>
    <input type="text" class="form-control" name="otherGL" [(ngModel)]="chargeType.otherGL" />
  </div>
  <div class="form-group">
    <label for="isAccessorial">Is Accessorial</label>
    <div>
      <p-checkbox
        [(ngModel)]="chargeType.isAccessorial"
        binary="true"
        name="isAccessorial"
        inputId="isAccessorial"
      ></p-checkbox>
    </div>
  </div>
  <p-footer>
    <div class="footer">
      <button type="button" class="btn btn-secondary mr-2" (click)="closeModal()">Cancel</button>
      <button *ngIf="!isEditing" type="submit" class="btn btn-primary createBtn mr-2">
        Create
      </button>
      <button
        *ngIf="isEditing && !chargeType.isDeleted"
        type="button"
        class="btn btn-danger mr-2"
        (click)="confirmationDelete()"
      >
        Delete
      </button>
      <button
        *ngIf="chargeType && chargeType.isDeleted"
        type="button"
        class="btn btn-primary createBtn"
        (click)="restore()"
      >
        Restore
      </button>
      <button
        *ngIf="isEditing && !chargeType.isDeleted"
        type="submit"
        class="btn btn-primary createBtn"
      >
        Save
      </button>
    </div>
  </p-footer>
</form>
