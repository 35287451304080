import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Deduction } from "@app/shared/models/settlements/Deduction.model";
import { HttpClient } from "@app/shared/services/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class DeductionService {
  constructor(private _http: HttpClient) {}

  private handleError(error: any) {
    return observableThrowError(error.message || "Server Error");
  }

  getDeductions(): Observable<Deduction[]> {
    return this._http.get<Deduction[]>("api/deduction").pipe(catchError(this.handleError));
  }

  getDeduction(id: number): Observable<Deduction> {
    return this._http.get<Deduction>("api/deduction/" + id).pipe(catchError(this.handleError));
  }

  createDeduction(deduction: Deduction): Observable<Deduction> {
    return this._http
      .post<Deduction>("api/deduction", deduction)
      .pipe(catchError(this.handleError));
  }

  updateDeduction(deduction: Deduction): Observable<Deduction> {
    return this._http
      .patch<Deduction>("api/deduction/" + deduction.id, deduction)
      .pipe(catchError(this.handleError));
  }

  deleteDeduction(id: number): Observable<Deduction> {
    return this._http.delete<Deduction>("api/deduction/" + id).pipe(catchError(this.handleError));
  }

  getBatchHistory(deductionId: number, batchId: number): Observable<any> {
    return this._http
      .get<any>("api/deduction/" + deductionId + "/batchHistory/" + batchId)
      .pipe(catchError(this.handleError));
  }

  getDriverHistory(deductionId: number, driverId: number): Observable<any> {
    return this._http
      .get<any>("api/deduction/" + deductionId + "/driverHistory/" + driverId)
      .pipe(catchError(this.handleError));
  }

  getDeficitHistory(driverId: number): Observable<any> {
    return this._http
      .get<any>("api/deduction/deficitDriverHistory/" + driverId)
      .pipe(catchError(this.handleError));
  }

  getEscrowHistory(driverId: number): Observable<any> {
    return this._http
      .get<any>("api/deduction/escrowDriverHistory/" + driverId)
      .pipe(catchError(this.handleError));
  }
  getDeductionDropdown(): Observable<Deduction[]> {
    return this._http.get<Deduction[]>("api/deduction/getDeductionDropdown").pipe(catchError(this.handleError));
  }
}
