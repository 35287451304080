<div class="container">
  <div class="col-md-12">
    <legend>Drivers</legend>
    <div class="btnRig">
      <button
        id="edit"
        name="edit"
        class="btn btn-primary"
        style="margin-right: 1rem"
        routerLink="/newdriver"
        *ngIf="hasPermission('DriversChange')"
      >
        Add New Driver
      </button>
      <button
        type="button"
        class="btn"
        *ngIf="hasPermission('DriversChange') && !isEditingIds"
        (click)="isEditingIds = true"
      >
        Edit
      </button>
      <button
        type="button"
        *ngIf="isEditingIds"
        class="btn btn-primary"
        (click)="saveDriverChanges()"
      >
        Save
      </button>
    </div>
    <div>
      <p-table
        #dt
        [columns]="colsDrivers"
        [value]="allDrivers"
        sortMode="multiple"
        stateStorage="session"
        stateKey="driverslist"
        [scrollable]="true"
        scrollHeight="700px"
        [virtualScroll]="true"
        [virtualScrollItemSize]="46"
      >
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width.px]="col.width || 150" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" style="width:20%;">
              {{ col.header }}
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns">
              <input
                *ngIf="col.type !== 'boolean'"
                type="text"
                (input)="$any(dt).filter($event.target.value, col.field, 'contains')"
                [value]="$any(dt).filters[col.field]?.value"
              />
              <p-dropdown aria-label="status"
                *ngIf="col.type === 'boolean'"
                [options]="statusOptions"
                (onChange)="$any(dt).filter($event.value, col.field, 'equals')"
                [ngModel]="$any(dt).filters[col.field]?.value"
                appendTo="body"
                tabbableDropdown
              >
              </p-dropdown>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-driver>
          <tr
            class="clickable-row"
            style="height:42px;"
            [ngClass]="{ 'deleted-row': driver.isActive === 'Inactive' }"
            (dblclick)="viewDetail(driver)"
          >
            <td>
              <span *ngIf="!isEditingIds">{{ driver.driverNo }}</span>
              <input
                *ngIf="isEditingIds"
                type="text"
                [(ngModel)]="driver.driverNo"
                class="form-control input-md"
                (change)="onDriverChanged(driver)"
                maxlength="16"
                (click)="$event.stopPropagation()"
              />
            </td>
            <td>{{ driver.lastName }}</td>
            <td>{{ driver.name }}</td>
            <td>{{ driver.cellphone }}</td>
            <td>{{driver.peopleNetId}}</td>
            <td>{{ driver.unitNumber}}</td>
            <td>{{ driver.email }}</td>
            <td>{{ driver.isActive }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
