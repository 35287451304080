import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";
@Component({
  selector: "app-generic-rates",
  templateUrl: "./generic-rates.component.html",
  styleUrls: ["./generic-rates.component.css"],
})
export class GenericRatesComponent implements OnInit {
  @ViewChild("ratesTable", { static: true }) table: Table;
  @Input() rates: any[];
  @Input() name: string;
  @Input() fullName: string;

  statusOptions: SelectItem[] = [
    { label: "ALL", value: null },
    { label: "ACTIVE", value: true },
    { label: "INACTIVE", value: false },
  ];

  // prettier-ignore
  columns: any = [
    { field: "name", header: "Rate Name", width: 300 },
    { field: "description", header: "Description", width: 300 },
    { field: "priority", header: "Priority", width: 100 },
    { field: "validFromUtc", header: "Start Date", width: 130 },
    { field: "validToUtc", header: "End Date", width: 130 },
    { field: "isValid", header: "Status", width: 100, type: "boolean" },
  ];
  filterElements: any = [];

  constructor(private router: Router) {}
  ngOnInit(): void {
    if (this.fullName === "Custom Charges") return;
    const filter = sessionStorage.getItem(`${this.fullName}-rates-list`);
    if (!filter) {
      this.table.filter(true, "isValid", "equals");
    }
  }

  editRate(rate: any) {
    if (rate.isMinimum) return this.router.navigate([`/minimums/${rate.id}`]);
    this.router.navigate([`/rates/${this.name}/${rate.id}`]);
  }

  createRate() {
    this.router.navigate([`/createrate/${this.name}`]);
  }
}
