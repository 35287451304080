import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "../../shared/services/http/http-client.service";

@Injectable()
export class EquipmentService {
  constructor(private router: Router, private _http: HttpClient) {}

  create(equipment) {
    return this._http.post("/api/equipment/create", { equipment }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server equipment creation error")
      )
    );
  }

  update(equipment) {
    return this._http.post("/api/equipment/update", { equipment }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server equipment update error")
      )
    );
  }

  addProduct(equipmentCodeId, productId) {
    return this._http.post("/api/equipment/addProduct", { equipmentCodeId, productId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server equipment update error")
      )
    );
  }

  delete(equipment) {
    return this._http.post("/api/equipment/delete", { equipment }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server equipment delete error")
      )
    );
  }

  deleteProduct(equipmentId) {
    return this._http.post("/api/equipment/deleteProduct", { equipmentId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server equipment update error")
      )
    );
  }

  getEquipmentList() {
    return this._http.get("/api/equipment/list").pipe(
      map((res) => {
        let equipment = res;
        return equipment;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
