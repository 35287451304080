import { trigger } from "@angular/animations";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";

@Component({
  selector: "app-pc-miller-near-locations",
  templateUrl: "./pcMiler-near-locations.component.html",
  styleUrls: ["./pcMiler-near-locations.component.css"],
})
export class PcMilerNearLocationsComponent implements OnInit {
  selectedLocation: any;
  locationList: any;
  @Output("locationSelected") locationSelected = new EventEmitter<any>();
  cols: { field: string; header: string }[];
  @ViewChild("loadTrigger", { static: false })
  set trigger(val: ElementRef<HTMLDivElement>) {
    if (this.locationList.length > 0) {
      const elementToFocus = document.getElementById("1");
      setTimeout(() => elementToFocus.focus(), 0);
    }
  }
  @Input()
  set locations(locations) {
    var i = 1;
    for (var location of locations) {
      location.number = i;
      i++;
    }
    this.locationList = locations;
  }

  constructor() {}

  ngOnInit() {
    this.selectedLocation = null;
    this.locationList = [];
    this.cols = [
      { field: "CountryAbbreviation", header: "Country" },
      { field: "StateName", header: "State Name" },
      { field: "City", header: "City" },
      { field: "County", header: "County" },
      { field: "StreetAddress", header: "Street Address" },
      { field: "Zip", header: "Zip" },
    ];
  }
  checkRow(number: any) {
    this.selectedLocation = this.locationList.find((x) => x.number === number);
    const elementToFocus = document.getElementById("save");
    setTimeout(() => elementToFocus.focus(), 0);
  }
  goToFirstRow() {
    const elementToFocus = document.getElementById("1");
    setTimeout(() => elementToFocus.focus(), 0);
  }
  save() {
    const locationSelected = JSON.parse(JSON.stringify(this.selectedLocation));
    locationSelected.numMatches = this.locationList.length;
    locationSelected.isUserChosen = true;
    this.locationSelected.emit(locationSelected);
    this.selectedLocation = null;
  }
}
