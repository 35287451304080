<div class="col-md-12 row rowbtn" align="center">
  <div class="col-md-2 col-mds-2">
    <button
      type="button"
      [ngClass]="
        selectedTab == 'batch'
          ? 'active btn btnSettlementNav btn-lg'
          : 'btn btnSettlementNav btn-lg'
      "
      (click)="changeTab('loads')"
    >
      BACK TO SETTLEMENTS
    </button>
  </div>
  <div class="hidden">
    <div class="col-md-2 col-mds-2">
      <button
        type="button"
        [ngClass]="
          selectedTab == 'fuel'
            ? 'active btn btnSettlementNav btn-lg'
            : 'btn btnSettlementNav btn-lg'
        "
        id="two"
      >
        FUEL
      </button>
    </div>
    <div class="col-md-2 col-mds-2">
      <button
        type="button"
        [ngClass]="
          selectedTab == 'loanlist'
            ? 'active btn btnSettlementNav btn-lg'
            : 'btn btnSettlementNav btn-lg'
        "
        id="three"
        (click)="changeTab('loanlist')"
      >
        REIMBURSEMENTS
      </button>
    </div>
    <div class="col-md-2 col-mds-2">
      <button
        type="button"
        [ngClass]="
          selectedTab == 'deductibles'
            ? 'active btn btnSettlementNav btn-lg'
            : 'btn btnSettlementNav btn-lg'
        "
        id="four"
        (click)="changeTab('deductibles')"
      >
        DEDUCTIONS
      </button>
    </div>
    <div class="dropdown col-md-2 col-mds-2">
      <button
        class="btn btn-default dropdown-toggle"
        type="button"
        id="dropdownMenu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <div class="btnDropDown">
          <button
            type="button"
            [ngClass]="
              selectedTab == 'escrow'
                ? 'active btn btnSettlementNav btn-lg'
                : 'btn btnSettlementNav btn-lg'
            "
            id="one"
            (click)="changeTab('escrow')"
          >
            ESCROW
          </button>
        </div>
        <span class=""><img src="/assets/img/arrow-down.svg" width="25" /></span>
      </button>
      <ul class="dropdown-menu" id="dropdownMenuLoad" aria-labelledby="dropdownMenu1">
        <li>
          <div class="col-md-2 col-mds-2">
            <button
              type="button"
              [ngClass]="
                selectedTab == 'deficit'
                  ? 'active btn btnSettlementNav btn-lg'
                  : 'btn btnSettlementNav btn-lg'
              "
              id="two"
              (click)="changeTab('deficit')"
            >
              DEFICIT
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
