import { of as observableOf, Observable, Subject } from "rxjs";

import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PeoplenetTestService } from "./peoplenet-test.service";

@Injectable()
export class PeoplenetResolver {
  constructor(private peoplenetTestService: PeoplenetTestService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return [];
    return this.peoplenetTestService.listTractorsLocations().pipe(
      catchError(() => {
        return observableOf([]);
      })
    );
  }
}
