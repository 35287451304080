import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { DriverService } from "./driver.service";

@Injectable()
export class DriversNoTractorAssignedResolver {
  constructor(private driverService: DriverService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.driverService.getDriversNoTractorAssigned();
  }
}
