import { from as observableFrom } from "rxjs";

import { filter } from "rxjs/operators";
import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ClientWeeklyReportService } from "../clientPanel/weeklyReport/shared/clientWeeklyReport.service";
import { homeService } from "./home.service";
import { MaskService } from "../shared/services/mask/mask.service";
import { intersectionBy } from "lodash";

@Component({
  selector: "app-home-component",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  @ViewChild("dateTemplate", { static: true }) dateTemplate: TemplateRef<any>;

  temporalRow: any = {};
  dispatchId: any;
  dispatchDate: Date;
  ischeckTruck = true;
  ischeckTrailer = true;
  ischeckDriver = true;
  ischeckTds = true;
  ischeckTdsDateTimeLoad = true;
  ischeckLoadPoint = true;
  ischeckProduct = true;
  ischeckWorkingFor = true;
  ischeckShipperName = true;
  ischeckConsigneeName = true;
  ischeckConsigneeCity = true;
  ischeckDateDeliveryTime = true;
  ischeckHomeTerminal = true;
  ischeckLastHomeTime = true;
  ischeckLasthirtyFour = true;
  ischeckComments = true;
  connection;

  rows: Array<any> = [
    {
      tractor: -1,
      trailer: -1,
      driver: -1,
      product: -1,
      client: -1,
      loadDateInputId: this.guid(),
      deliveryDateTimeInputId: this.guid(),
      last34HRInputId: this.guid(),
      lastHomeTimeInputId: this.guid(),
    },
  ];
  tractors = [];
  teams = [];
  trailers = [];
  products = [];
  drivers = [];
  totalRows: Number;
  clientSelectedId: any;
  errorMsg: any;
  homeTripInfo: any = [];
  homeTripList: any = [];
  uuid: number;
  clients: Array<any>;
  clientWeeklyReports: any;
  currentReports: Array<any>;
  dropDownClientsSettings: any;
  selectedClient: Array<any>;
  reportRows: Array<any>;
  currentClientReportPage: Array<any>;
  clientsHistoricReports: any = {};
  rangePickerOptions: any;
  i: any;
  columnTable: any;
  desapairCol: any;
  dispatchReady = false;
  selectedTrip;

  rowss = [];
  filteredRows = [];
  allColumns = [
    { name: "Tractor", prop: "unitNumber", width: "150" },
    { name: "Trailer", prop: "trailerNo", width: "100" },
    { name: "Driver", prop: "driverName", width: "100" },
    { name: "TDS #", prop: "tripNo", width: "100" },
    { name: "Date time load", prop: "loadDate", width: "150" },
    { name: "Load Point", prop: "loadName", width: "100" },
    { name: "Product", prop: "product", width: "100" },
    { name: "Working For", prop: "cCompanyName", width: "100" },
    { name: "Shipper Name", prop: "shipper", width: "100" },
    { name: "Consignee Name", prop: "consignee", width: "100" },
    { name: "Consignee City/State", prop: "consigneeState", width: "150" },
    { name: "Date/time Delivery", prop: "unloadDate", width: "150" },
    { name: "Home terminal", prop: "terminalLocation", width: "200" },
    { name: "Last HomeTime", width: "100" },
    { name: "Last 34 HR", width: "100" },
    { name: "Comments", width: "200" },
  ];
  columns = this.allColumns;

  dispatchFilters: any = {
    clientId: null,
    tractorId: null,
  };
  @ViewChild("gmapHome", { static: true }) gmapElement: any;

  constructor(
    private maskService: MaskService,
    private toastr: ToastrService,
    private _homeService: homeService,
    private _ActivatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private clientWeeklyReportService: ClientWeeklyReportService
  ) {}

  // this method allow us to list all the trips over the table above the map
  ngOnInit() {
    this.rangePickerOptions = {
      componentDisabled: true,
      dateFormat: "MM/dd/yyyy",
    };
    this.reportRows = [];
    this.currentReports = [];
    this.clients = this.route.snapshot.data.clients;
    this.dropDownClientsSettings = {
      singleSelection: true,
      text: "Select a client",
      enableSearchFilter: true,
    };
    this.clientWeeklyReports = {};
    this._homeService.getTripList().subscribe(
      (resTripListData) => {
        this.homeTripList = resTripListData;
      },
      (responseTripListError) => {
        this.errorMsg = responseTripListError;
      }
    );

    this.tractors = this.route.snapshot.data["tractors"];
    this.clients = this.route.snapshot.data["clients"];
    this.teams = this.route.snapshot.data["teams"];
    this.trailers = this.route.snapshot.data["trailers"];
    this.products = this.route.snapshot.data["products"];
    this.drivers = this.route.snapshot.data["drivers"];
    this.size();

    this._homeService.listHome().subscribe(
      (resDispatchListData: any) => {
        // this.rowss = resDispatchListData;
        this.rowss = resDispatchListData.map((d) => {
          d.updated = Date.now().toString();
          return d;
        });
        this.filteredRows = this.rowss;
      },
      (responseDispatchListError) => {
        this.errorMsg = responseDispatchListError;
      }
    );
  }

  getModalContent(location) {
    return `<div id="content">
    Vehicle Number: ${location.truckNumber}
    <br>
    Driver: ${location.driverName}
    <br>
    Status: ${location.dutyStatus1}
    <br>
    Heading / Speed: ${location.heading} / ${location.speed}
    <br>
    Lat / Long:	${location.latitude} / ${location.longitude}
    <br>
    Location: ${location.location}
    <br>
    Last update: ${location.datetime}
    </div>
    `;
  }

  filterDispatch() {
    if (typeof this.rowss !== "undefined" && this.rowss != null) {
      this.filteredRows = this.rowss.filter(
        (row) =>
          (this.dispatchFilters.clientId == null ||
            row.ClientId === this.dispatchFilters.clientId) &&
          (this.dispatchFilters.tractorId == null ||
            row.TractorsId === this.dispatchFilters.tractorId)
      );
    }
  }

  showhide(column, table, elem: HTMLInputElement) {
    if (elem) {
      this.columnTable = "table-cell";
    } else {
      this.columnTable = "none";
      this.desapairCol = document.getElementsByTagName("tr");
    }
    if (table === 2) {
      for (this.i = 2; this.i < this.desapairCol.length; this.i++) {
        this.desapairCol[this.i].childNodes[column].style.display = this.columnTable;
      }
    }
    if (table === 1) {
      for (this.i = 0; this.i < 2; this.i++) {
        this.desapairCol[this.i].childNodes[column].style.display = this.columnTable;
      }
    }
  }

  formatRowsDispatch(rows) {
    rows.forEach((dispatch) => {
      this.formatDispatchRow(dispatch);
    });
  }

  formatDispatchRow(dispatch) {
    dispatch.loadDatetime = new Date(dispatch.loadDatetime);
    dispatch.deliveryDateTime = new Date(dispatch.deliveryDateTime);
    dispatch.lastHomeTime = new Date(dispatch.lastHomeTime);
    dispatch.last34HR = new Date(dispatch.last34HR);
    this.findDriverModel(dispatch);
    this.findClientModel(dispatch);
    this.findTractorModel(dispatch);
    this.findTrailerModel(dispatch);
    this.findProductModel(dispatch);
    return dispatch;
  }

  viewReportBelow(reportSelected) {
    //  this.clientWeeklyReports[selectedClientId] = this.formatReport(report);
    this.clientWeeklyReportService
      .listReportDetails(reportSelected.id)
      .subscribe((reportDetails) => {
        this.reportRows = new Array();
        this.reportRows = this.formatReport(reportDetails);
      });
  }

  formatReport(report) {
    this.reportRows = [];
    report.forEach((reportRow) => {
      if (reportRow.DriverEvents && reportRow.DriverEvents.length) {
        reportRow.DriverEvents.forEach((event) => {
          event.beginDate = {
            year: new Date(event.startDate).getFullYear(),
            month: new Date(event.startDate).getMonth(),
            day: new Date(event.startDate).getDay(),
          };
          event.endDate = {
            year: new Date(event.endDate).getFullYear(),
            month: new Date(event.endDate).getMonth(),
            day: new Date(event.endDate).getDay(),
          };
        });
      }

      if (reportRow.Drivers && reportRow.Drivers.length) {
        reportRow.Drivers.forEach((driver) => {
          driver.itemName = driver.name + "," + driver.lastName;
        });
      }

      if (reportRow.Products && reportRow.Products.length) {
        reportRow.Products.forEach((product) => {
          product.itemName = product.name;
        });
      }
    });
    // splitting the rows per driver
    report.forEach((reportRow) => {
      const tempRow = JSON.parse(JSON.stringify(reportRow));
      const tempDriverEvents = JSON.parse(JSON.stringify(reportRow.DriverEvents));
      delete tempRow.Drivers;
      reportRow.Drivers.forEach((driver) => {
        tempRow.Driver = driver;
        const driverEvents = [];
        observableFrom(tempDriverEvents)
          .pipe(filter((event: any) => event.DriverId === driver.id))
          .subscribe((driverEvent) => {
            driverEvents.push(driverEvent);
          });
        tempRow.DriverEvents = driverEvents;
        this.reportRows.push(tempRow);
      });
      reportRow.Teams.forEach((team) => {
        tempRow.Team = team;
        this.reportRows.push(tempRow);
      });
    });
    return this.reportRows.splice(0);
  }

  paginateCurrentClientReportPage(event) {
    const page_number = event.page--;
    this.currentClientReportPage = JSON.parse(
      JSON.stringify(
        this.clientsHistoricReports[this.clientSelectedId].slice(
          page_number * 10,
          (page_number + 1) * 10
        )
      )
    );
  }

  clientSelected(selectedClientId) {
    this.clientSelectedId = selectedClientId;
    if (!this.clientsHistoricReports[selectedClientId]) {
      this.clientWeeklyReportService.listByClient(selectedClientId).subscribe((reports: any) => {
        if (reports.length) {
          this.totalRows = reports.length;
          this.clientsHistoricReports[selectedClientId] = JSON.parse(JSON.stringify(reports));
          this.currentClientReportPage = reports.splice(0, 10);
        } else {
          this.totalRows = 0;
          this.currentClientReportPage = [];
        }
      });
    } else {
      this.totalRows = this.clientsHistoricReports[selectedClientId].length;
      this.currentClientReportPage = JSON.parse(
        JSON.stringify(this.clientsHistoricReports[selectedClientId])
      ).splice(0, 10);
    }

    if (this.clientWeeklyReports[selectedClientId]) {
      this.reportRows = this.clientWeeklyReports[selectedClientId];
    } else {
      this.clientWeeklyReportService.getLastByClient(selectedClientId).subscribe((report: any) => {
        if (report && report.length) {
          this.clientWeeklyReports[selectedClientId] = this.formatReport(report);
          this.reportRows = this.clientWeeklyReports[selectedClientId];
        } else {
          this.reportRows = [];
        }
      });
    }
  }

  findDriverModel(dispatch) {
    dispatch.trailer = -1;
    return new Promise((resolve, reject) => {
      this.drivers.forEach((driver) => {
        if (dispatch.DriverId === driver.id) {
          dispatch.driver = driver;
          resolve(dispatch);
        }
      });
    });
  }

  findClientModel(dispatch) {
    dispatch.client = -1;
    return new Promise((resolve, reject) => {
      this.clients.forEach((client) => {
        if (dispatch.ClientId === client.id) {
          dispatch.client = client;
          resolve(dispatch);
        }
      });
    });
  }

  findTractorModel(dispatch) {
    dispatch.tractor = -1;
    return new Promise((resolve, reject) => {
      this.tractors.forEach((tractor) => {
        if (dispatch.TractorId === tractor.id) {
          dispatch.tractor = tractor;
          resolve(dispatch);
        }
      });
    });
  }

  findTrailerModel(dispatch) {
    dispatch.trailer = -1;
    return new Promise((resolve, reject) => {
      this.trailers.forEach((trailer) => {
        if (dispatch.TrailerId === trailer.id) {
          dispatch.trailer = trailer;
          resolve(dispatch);
        }
      });
    });
  }

  findProductModel(dispatch) {
    return new Promise((resolve, reject) => {
      this.products.forEach((product) => {
        if (dispatch.ProductId === product.id) {
          dispatch.product = product;
          resolve(dispatch);
        }
      });
      resolve(undefined);
    });
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  }

  dateChanged(event, param, justDate, yearDigits) {
    if (event.code === "Delete" || event.code === "Backspace") {
      return;
    }
    this.maskService.maskDate(event, param, justDate, yearDigits);
  }

  OpenTab(tab) {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    if (tab === "schedule") {
      document.getElementById("one").classList.add("active");
    }
    if (tab === "dispatch") {
      document.getElementById("two").classList.add("active");
    }

    document.getElementById(tab).style.display = "block";
  }

  size() {
    let thElm;
    let startOffset;

    Array.prototype.forEach.call(document.querySelectorAll("table th"), function (th) {
      th.style.position = "relative";
      const grip: any = document.createElement("div");
      grip.innerHTML = "&nbsp;";
      grip.style.top = 0;
      grip.style.right = 0;
      grip.style.bottom = 0;
      grip.style.width = "5px";
      grip.style.position = "absolute";
      grip.style.cursor = "col-resize";
      grip.addEventListener("mousedown", function (e) {
        thElm = th;
        startOffset = th.offsetWidth - e.pageX;
      });

      th.appendChild(grip);
    });

    document.addEventListener("mousemove", function (e) {
      if (thElm) {
        thElm.style.width = startOffset + e.pageX + "px";
      }
    });

    document.addEventListener("mouseup", function () {
      thElm = undefined;
    });
  }

  toggle(col) {
    const isChecked = this.isChecked(col);
    if (isChecked) {
      this.columns = this.columns.filter((c) => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
      // retain original sequence of columns by comparing to allColumns array;
      // this means allColumns manages state of desired sequence
      this.columns = intersectionBy(this.allColumns, this.columns, "name");
    }
  }

  isChecked(col) {
    return this.columns.find((c) => {
      return c.name === col.name;
    });
  }

  // here is the method that allow us to go to the  trip view ones it is clicked by user
  viewDetail(selectedTrip) {
    this.router.navigate(["/tripinfo/", selectedTrip.id]);
  }
}
