import { Component } from "@angular/core";
import { authenticationService } from "./auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "login-component",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent {
  user: any = {};
  emailName: any;
  constructor(
    private authService: authenticationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.emailName = this.randomString(64);
  }

  async login(user) {
      user.password = user.noHashedpassword;
    try {
      await this.authService.login(user);
    } catch (error) {
      switch (error.status) {
        case 404:
          this.toastr.error("User not found", "Error");
          break;
        case 401:
          this.toastr.error("Password doesn't match", "Error");
          break;
        default:
          this.toastr.error("Authentication error", "Error");
          break;
      }
    }
  }

  randomString(length) {
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
}
