<div>
  <div class="col-md-12 no-padding">
    <legend>Users</legend>
    <div class="btnRig">
      <button type="submit" class="btn btn-primary" (click)="showAddForm()">Add New User</button>
    </div>
    <div class="toggle-box">
      <p-checkbox
        [(ngModel)]="showDeleted"
        binary="true"
        inputId="binary"
        class="mr-2"
        (onChange)="filterUsers()"
        id="showDeleted"
      ></p-checkbox>
      <label for="binary">Show Deleted Users</label>
    </div>
    <div style="height: 500px">
      <p-table
        #dtUsersList
        [columns]="userCols"
        [value]="filteredUsers"
        [scrollable]="true"
        scrollHeight="flex"
      >
        <ng-template pTemplate="caption">
          <div>
            <i class="pi pi-search" style="margin: 4px 4px 0 0"></i>
            <input
              type="text"
              pInputText
              size="50"
              placeholder="Search..."
              (input)="dtUsersList.filterGlobal($event.target.value, 'contains')"
              style="width: auto; margin-left: 0.5rem"
            />
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col style="width: 200px" />
            <col style="width: 300px" />
            <col style="width: 100px" />
            <col style="width: 100px" />
            <col style="width: 150px" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr
            [ngClass]="{
              'clickable-row': !user.isDeleted,
              'deleted-row': user.isDeleted
            }"
            (dblclick)="showEditForm(user)"
          >
            <td style="text-transform: none !important">{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.lastName }}</td>
            <td>{{ user.roleName }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  header="Edit User"
  [(visible)]="displayEdit"
  [modal]="true"
  [style]="{ width: '500px', minWidth: '300px' }"
  [minY]="100"
  [maximizable]="true"
>
  <app-updateuser
    [selectedUser]="selectedUser"
    (userDeletedEvent)="removeUser($event)"
    (userChangedEvent)="updateUser($event)"
    (userRestoredEvent)="restoreUser($event)"
  ></app-updateuser>
</p-dialog>

<p-dialog
  header="Add New User"
  [(visible)]="displayAdd"
  [modal]="true"
  [style]="{ width: '300px', minWidth: '200px' }"
  [minY]="70"
  [baseZIndex]="10000"
>
  <app-adduser [visible]="displayAdd" (userAddedEvent)="addUser($event)"></app-adduser>
</p-dialog>

<p-confirmDialog
  key="deleteUserDialog"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
