import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { PcMilerResolver } from "./pcMiler/shared/pcMiler.resolver";
import { ConfigResolver } from "./utilities/config/shared/config.resolver";
import { DriversResolver } from "./driver/shared/driver.resolver";
import { DriversWithTeamsResolver } from "./driver/shared/driver-with-teams.resolver";
import { ProductsResolver } from "./product/shared/products.resolver";
import { DriversSelectFormatResolver } from "./driver/shared/driverSelectFormat.resolver";
import { TractorsResolver } from "./tractor/shared/tractor.resolver";
import { TrailersResolver } from "./trailer/shared/trailer.resolver";
import { ClientsResolver } from "./client/shared/client.resolver";
import { LessorsResolver } from "./lessor/shared/lessor.resolver";
import { ColumnResolver } from "../app/settlements/shared/columns.resolver";
import { PeoplenetResolver } from "./peoplenet-test/shared/peoplenet.resolver";
import { DriversNoTractorAssignedResolver } from "./driver/shared/driver-no-tractor-assigned.resolver";
import { BillToResolver } from "./client/shared/billTo.resolver";
import { ParentsResolver } from "./client/shared/parent.resolver";
import { TrailersTypesResolver } from "./trailer/shared/trailerTypes.resolver";
import { ChargeTypesResolver } from "./chargeType/shared/chargetypes.resolver";
import { ChargeTypesIdResolver } from "./chargeType/shared/chargeTypesId.resolver";
import { GlobalChargeTypesResolver } from "./chargeType/shared/globalchargetypes.resolver";
import { RoleResolver } from "./utilities/roles/shared/role.resolver";
import { UserResolver } from "./utilities/users/shared/user.resolver";

import { authenticationService } from "./login/auth.service";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { DriversListComponent } from "./driver/drivers-list/drivers-list.component";
import { TractorlistComponent } from "./tractor/tractorlist/tractorlist.component";
import { DriverinfoComponent } from "./driver/driverinfo/driverinfo.component";
import { TruckinfoComponent } from "./tractor/tractorinfo/truckinfo.component";
import { TrailerlistComponent } from "./trailer/trailerlist/trailerlist.component";
import { TrailerinfoComponent } from "./trailer/trailerinfo/trailerinfo.component";
import { ClientlistComponent } from "./client/clientlist/clientlist.component";
import { ClientinfoComponent } from "./client/clientinfo/clientinfo.component";
import { ClientprofileComponent } from "./client/clientprofile/clientprofile.component";
import { ClientProductListComponent } from "./client/clientproductlist/clientproductlist.component";
import { LessorlistComponent } from "./lessor/lessorlist/lessorlist.component";
import { LessorinfoComponent } from "./lessor/lessorinfo/lessorinfo.component";
import { EquipmentComponent } from "./equipment/equipment-list/equipment.component";
import { ProductsComponent } from "./product/products.component";
import { AddscheduleComponent } from "./addschedule/addschedule.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { ScheduleinfoComponent } from "./scheduleinfo/scheduleinfo.component";
import { AddDriverComponent } from "./driver/add-driver/add-driver.component";
import { AddtractorComponent } from "./tractor/addtractor/addtractor.component";
import { AddtrailerComponent } from "./trailer/addtrailer/addtrailer.component";
import { AddclientComponent } from "./client/addclient/addclient.component";
import { PeoplenetTestComponent } from "./peoplenet-test/peoplenet-test.component";
import { FuelComponent } from "./fuel/fuel.component";
import { RatesListComponent } from "./rates/rateslist.component";
import { LocationsComponent } from "./locations/locations.component";
import { SettlementsHomeComponent } from "../app/settlements/settlementsHome/settlementsHome.component";
import { TChekComponent } from "./tcheck/tcheck.component";
import { LoadsComponent } from "./settlements/loads/loads.component";
import { AllLoadsComponent } from "./settlements/allloads/all-loads.component";
import { DriverExpensesComponent } from "./settlements/driverExpenses/driverExpenses.component";
import { CompanyExpensesComponent } from "./settlements/companyExpenses/companyExpenses.component";
import { TchekImportManagerComponent } from "./tchek-import-manager/tchek-import-manager.component";
import { TchekHistoricalComponent } from "./tchek-historical/tchek-historical.component";
import { ConfirmationGuard } from "./shared/guards/confirmation/confirmation.guard";
import { PcMilerComponent } from "./pcMiler/pcMiler.component";
import { ConfigComponent } from "./utilities/config/config.component";
import { UtilitiesComponent } from "./utilities/utilities.component";
import { EditRatesComponent } from "./rates/edit-rates/edit-rates.component";
import { CreateRatesComponent } from "./rates/create-rates/create-rates.component";
import { ChargeTypesComponent } from "./chargeType/charge-types/charge-types.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AddRoleComponent } from "./utilities/roles/addrole/addrole.component";
import { Permissions } from "@models/permissions.enum";
import { TeamslistComponent } from "./teams/teamslist/teamslist.component";
import { TeamComponent } from "./teams/team/team.component";
import { EditDailyMinimumComponent } from "./rates/daily-minimum-rates/edit-daily-minimum/edit-daily-minimum.component";
import { DeductionsListComponent } from "./settlements/deductions-list/deductions-list.component";
import { DeductionComponent } from "./settlements/deduction/deduction.component";
import { ResetComponent } from "./login/reset.component";

// Route Configuration
export const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "home" },
  { path: "login", component: LoginComponent },
  { path: "reset", component: ResetComponent },
  {
    path: "home",
    component: HomeComponent,
    resolve: {
      products: ProductsResolver,
      drivers: DriversResolver,
      trailers: TrailersResolver,
      tractors: TractorsResolver,
      clients: ClientsResolver,
      peoplenetTractors: PeoplenetResolver,
      PcMilerSettings: PcMilerResolver,
    },
    canActivate: [authenticationService],
    data: {
      permission: Permissions.DashboardRead,
    },
  },
  {
    path: "new",
    loadChildren: () => import("./newtrip/trip.module").then((m) => m.NewtripModule),
    data: {
      permission: Permissions.BillingQueueRead,
    },
  },
  {
    path: "reports",
    loadChildren: () => import("./reports/reports.module").then((m) => m.ReportsModule),
    data: {
      permission: Permissions.WeeklyReportRead,
    },
  },
  {
    path: "drivers-list",
    component: DriversListComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.DriversRead,
    },
  },
  {
    path: "tractorlist",
    component: TractorlistComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.TractorsRead,
    },
  },
  {
    path: "trailerlist",
    component: TrailerlistComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.TrailersRead,
    },
  },
  {
    path: "driverinfo/:id",
    component: DriverinfoComponent,
    resolve: { drivers: DriversWithTeamsResolver },
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.DriversRead,
    },
  },
  {
    path: "trailerinfo/:id",
    component: TrailerinfoComponent,
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.TrailersRead,
    },
  },
  {
    path: "tractorinfo/:id",
    component: TruckinfoComponent,
    resolve: { driversWithNoTrailerAssigned: DriversNoTractorAssignedResolver },
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.TractorsRead,
    },
  },
  {
    path: "clientlist",
    component: ClientlistComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.ClientsRead,
    },
  },
  {
    path: "clientinfo/:id",
    component: ClientinfoComponent,
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.ClientsRead,
    },
  },
  {
    path: "lessorlist",
    component: LessorlistComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.LessorRead,
    },
  },
  {
    path: "lessorinfo/:uuid",
    component: LessorinfoComponent,
    resolve: {
      clients: LessorsResolver,
    },
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.LessorRead,
    },
  },
  {
    path: "teams",
    component: TeamslistComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.DriversRead,
    },
  },
  {
    path: "teams/:id",
    component: TeamComponent,
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.DriversRead,
    },
  },
  {
    path: "clientProf",
    component: ClientprofileComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.ClientsRead,
    },
  },
  {
    path: "chargeTypes",
    component: ChargeTypesComponent,
    canActivate: [authenticationService],
    // We don't have a Charge Types read/change permission
    // data: {
    //   permission: Permissions.ProductsRead,
    // },
  },
  {
    path: "products",
    component: ProductsComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.ProductsRead,
    },
  },
  {
    path: "clientproductlist",
    component: ClientProductListComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.ClientProductsRead,
    },
  },
  {
    path: "equipment",
    component: EquipmentComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.EquipmentCodesRead,
    },
  },
  {
    path: "schedule",
    component: ScheduleComponent,
    canActivate: [authenticationService],
  },
  {
    path: "addschedule",
    component: AddscheduleComponent,
    canActivate: [authenticationService],
  },
  {
    path: "scheduleinfo/:uuid",
    component: ScheduleinfoComponent,
    canActivate: [authenticationService],
  },
  {
    path: "createrate/:rateType",
    component: CreateRatesComponent,
    resolve: {
      drivers: DriversSelectFormatResolver,
      billToFullList: BillToResolver,
      clients: ParentsResolver,
      equipmentTypes: TrailersTypesResolver,
      chargeTypes: ChargeTypesIdResolver,
    },
    canActivate: [authenticationService],
    data: {
      permission: Permissions.BusinessRulesChange,
    },
  },
  {
    path: "minimums/:id",
    component: EditDailyMinimumComponent,
    resolve: {
      drivers: DriversSelectFormatResolver,
      billToFullList: BillToResolver,
      clients: ParentsResolver,
      chargeTypes: ChargeTypesIdResolver,
    },
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.BusinessRulesRead,
    },
  },
  {
    path: "rates/:rateType/:id",
    component: EditRatesComponent,
    resolve: {
      drivers: DriversSelectFormatResolver,
      billToFullList: BillToResolver,
      clients: ParentsResolver,
      equipmentTypes: TrailersTypesResolver,
      chargeTypes: ChargeTypesIdResolver,
    },
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.BusinessRulesRead,
    },
  },
  {
    path: "rates",
    component: RatesListComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.BusinessRulesRead,
    },
  },
  {
    path: "newdriver",
    component: AddDriverComponent,
    resolve: { drivers: DriversWithTeamsResolver },
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.DriversChange,
    },
  },
  {
    path: "newtractor",
    component: AddtractorComponent,
    resolve: { driversWithNoTrailerAssigned: DriversNoTractorAssignedResolver },
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.TractorsChange,
    },
  },
  {
    path: "newtrailer",
    component: AddtrailerComponent,
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.TrailersChange,
    },
  },
  {
    path: "newclient",
    component: AddclientComponent,
    resolve: {
      clients: ClientsResolver,
    },
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.ClientsChange,
    },
  },
  {
    path: "peoplenetTest",
    component: PeoplenetTestComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.PeopleNetChange,
    },
  },
  {
    path: "fuel",
    component: FuelComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.FuelReportRead,
    },
  },
  {
    path: "settlements",
    component: SettlementsHomeComponent,
    resolve: {
      columns: ColumnResolver,
    },
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.SettlementsRead,
    },
  },
  {
    path: "settlements/loads/:driverId",
    component: LoadsComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.SettlementsRead,
    },
    resolve: {
      chargeTypes: ChargeTypesResolver,
      globalChargeTypes: GlobalChargeTypesResolver,
    },
  },
  {
    path: "settlements/allloads/:batchId",
    component: AllLoadsComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.SettlementsRead,
    },
    resolve: {
      chargeTypes: ChargeTypesResolver,
    },
  },
  {
    path: "settlements/driverExpenses",
    component: DriverExpensesComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.TChekExpensesChange,
    },
  },
  {
    path: "settlements/companyExpenses",
    component: CompanyExpensesComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.TChekExpressCodesChange,
    },
  },
  {
    path: "settlements/deductions",
    component: DeductionsListComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.SettlementsRead,
    },
  },
  {
    path: "settlements/deductions/:deductionId",
    component: DeductionComponent,
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    resolve: {
      chargeTypes: ChargeTypesResolver,
      drivers: DriversResolver,
    },
    data: {
      permission: Permissions.SettlementsRead,
    },
  },
  {
    path: "locations",
    component: LocationsComponent,
    resolve: {
      clients: ClientsResolver,
    },
    canActivate: [authenticationService],
    data: {
      permission: Permissions.LocationsRead,
    },
  },
  {
    path: "tchek",
    component: TChekComponent,
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.TChekImportChange,
    },
  },
  {
    path: "tchekAdmin",
    component: TchekImportManagerComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.TChekHistoricalChange,
    },
  },
  {
    path: "tchekHistorical",
    component: TchekHistoricalComponent,
    canActivate: [authenticationService],
    data: {
      permission: Permissions.TChekHistoricalChange,
    },
  },
  {
    path: "PcMilerSettings",
    component: PcMilerComponent,
    resolve: {
      PcMilerSettings: PcMilerResolver,
    },
    canActivate: [authenticationService],
    data: {
      permission: Permissions.PCMilerChange,
    },
  },
  {
    path: "configSettings",
    component: ConfigComponent,
    resolve: {
      configSettings: ConfigResolver,
    },
    canActivate: [authenticationService],
    data: {
      permission: Permissions.ConfigChange,
    },
  },
  {
    path: "utilities",
    component: UtilitiesComponent,
    resolve: {
      PcMilerSettings: PcMilerResolver,
      roles: RoleResolver,
      users: UserResolver,
      configSettings: ConfigResolver,
      chargeTypes: ChargeTypesIdResolver,
      globalChargeTypes: GlobalChargeTypesResolver,
    },
    canActivate: [authenticationService],
    canDeactivate: [ConfirmationGuard],
    data: {
      permission: Permissions.PCMilerChange,
    },
  },
  {
    path: "newrole",
    component: AddRoleComponent,
    data: { title: "New Role", permission: Permissions.RolesChange },
    canActivate: [authenticationService],
  },
  {
    path: "testing",
    loadChildren: () =>
      import("./trip-new/shared/testing/trip-testing.module").then((m) => m.ReportsModule),
    canActivate: [authenticationService],
    data: { permission: Permissions.Admin },
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
