<div [style.width.px]="tableWidth">
  <p-table [columns]="cols" [value]="splits">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col style="width: 200px" />
        <col style="width: 150px" />
        <col *ngIf="invalidTotal" style="width: 100px" />
        <col *ngFor="let driver of drivers" [style.width.px]="driverColumnWidth" />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th>Charge Type</th>
        <th style="text-align: right">Total</th>
        <th *ngIf="invalidTotal" style="text-align: right">Sum</th>
        <th *ngFor="let driver of drivers" style="text-align: right">
          <div>{{ driver.name }} {{ driver.lastName }}</div>
          <div>
            <span *ngIf="driver.isPayTo">(Pay To)&nbsp;</span>{{ driver.earningPercentage }}%
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-split>
      <tr [style.backgroundColor]="split.customTotal != split.total ? '#ffd3d2' : null">
        <td class="not-editable">{{ split.chargeType?.name }}</td>
        <td class="not-editable" style="text-align: right">{{ split.total | currency }}</td>
        <td *ngIf="invalidTotal" class="not-editable" style="text-align: right">
          {{ split.customTotal | currency }}
        </td>
        <td
          *ngFor="let driver of drivers"
          style="text-align: right"
          [style.color]="getPayoutAmounts(split, driver.id).customValue != getPayoutAmounts(split, driver.id).originalValue ? 'RED' : null"
          [pEditableColumn]="split"
          [pEditableColumnField]="driver.id"
        >
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input
                pInputText
                type="number"                     
                [disabled]="batchStatus == 'CLOSED'"
                step="0.01"
                (blur)="recalculateTotals()"
                [(ngModel)]="getPayoutAmounts(split, driver.id).customValue"
              />
            </ng-template>
            <ng-template pTemplate="output">
              {{ getPayoutAmounts(split, driver.id).customValue | currency }}
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer" let-columns>
      <tr>
        <td>Net Amount</td>
        <td style="text-align: right">{{ totals.total | currency }}</td>
        <td *ngIf="invalidTotal"></td>
        <td *ngFor="let driver of drivers" style="text-align: right">
          {{ totals[driver.id] | currency }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
