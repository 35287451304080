<div>
  <div class="display-box">
    <div *ngFor="let state of statesToDisplay">
      <div class="state-to-display">
        {{ state }}
      </div>
    </div>
    <button
      pButton
      type="button"
      icon="pi pi-info-circle"
      class="preview-button"
      data-toggle="modal"
      [attr.data-target]="'#addStateModal' + id"
      *ngIf="!isEditing"
    ></button>
    <button
      pButton
      type="button"
      icon="pi pi-plus"
      class="p-button-success preview-button"
      data-toggle="modal"
      [attr.data-target]="'#addStateModal' + id"
      *ngIf="isEditing"
    ></button>
  </div>
</div>

<div
  class="modal fade"
  [id]="'addStateModal' + id"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addStateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" style="min-width: 800px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Select States</h5>
      </div>
      <hr />
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <div class="header-row">
              <h5>USA</h5>
              <p-checkbox
                binary="true"
                [(ngModel)]="USSelected"
                inputId="US"
                (onChange)="toggleGroup('US')"
                [disabled]="!isEditing"
              ></p-checkbox>
            </div>
            <div *ngFor="let state of allStates">
              <div class="p-field-checkbox" *ngIf="state.category == 'US'">
                <p-checkbox
                  [value]="state.name"
                  [(ngModel)]="states"
                  [inputId]="state.id"
                  (onChange)="onStatesChange()"
                  [disabled]="!isEditing"
                ></p-checkbox>
                <label [for]="state.name">{{ state.fullName }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="header-row">
              <h5>Mexico</h5>
              <p-checkbox
                binary="true"
                [(ngModel)]="MXSelected"
                inputId="MX"
                (onChange)="toggleGroup('MX')"
                [disabled]="!isEditing"
              ></p-checkbox>
            </div>
            <div *ngFor="let state of allStates">
              <div class="p-field-checkbox" *ngIf="state.category == 'MX'">
                <p-checkbox
                  [value]="state.name"
                  [(ngModel)]="states"
                  [inputId]="state.id"
                  (onChange)="onStatesChange()"
                  [disabled]="!isEditing"
                ></p-checkbox>
                <label [for]="state.name">{{ state.fullName }}</label>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="header-row">
              <h5>Canada</h5>
              <p-checkbox
                binary="true"
                [(ngModel)]="CASelected"
                inputId="CA"
                (onChange)="toggleGroup('CA')"
                [disabled]="!isEditing"
              ></p-checkbox>
            </div>
            <div *ngFor="let state of allStates">
              <div class="p-field-checkbox" *ngIf="state.category == 'CA'">
                <p-checkbox
                  [value]="state.name"
                  [(ngModel)]="states"
                  [inputId]="state.id"
                  (onChange)="onStatesChange()"
                  [disabled]="!isEditing"
                ></p-checkbox>
                <label [for]="state.name">{{ state.fullName }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          #closebutton
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
