import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { SettlementsService } from "./settlements.service";

@Injectable()
export class ColumnResolver {
  constructor(private _SettlementsService: SettlementsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._SettlementsService.getHomeColumns();
  }
}
