import { Component, OnInit, Input } from "@angular/core";
import { Permissions } from "@models/permissions.enum";
import { ActivatedRoute } from "@angular/router";
import { RatesService } from "@app/rates/shared/rates.service";
import { authenticationService } from "@app/login/auth.service";
import { MaskService } from "../../../shared/services/mask/mask.service";
import { HistoryLog } from "@app/shared/models/rates/HistoryLog.model";

@Component({
  selector: "app-daily-minimum-history",
  templateUrl: "./daily-minimum-history.component.html",
  styleUrls: ["./daily-minimum-history.component.css"],
})
export class DailyMinimumHistoryComponent implements OnInit {
  @Input() rateId: string;

  entries: HistoryLog[];

  filteredEntries: HistoryLog[] = [];
  startDate: Date;
  endDate: Date;

  columns: any = [
    { field: "dateCreatedUtc", header: "Date Changed", width: 350 },
    { field: "name", header: "Name", width: 200 },
    { field: "field", header: "Field", width: 220 },
    { field: "value", header: "Value", width: 220 },
  ];
  dateInputMask: any;

  nameMap = {
    ValidFromUtc: "Valid From",
    ValidToUtc: "Valid To",
    Name: "Name",
    Description: "Description",
    Rate: "Rate",
    GuaranteePeriod: "Guarantee Period",
    GuaranteeType: "Guarantee Type",
    ApplyTo: "Apply To",
    ChargeTypeId: "Charge Type",
  };

  constructor(
    private route: ActivatedRoute,
    private ratesService: RatesService,
    private maskService: MaskService,
    private authService: authenticationService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.rateId = params["id"];
    });
    this.ratesService.getDailyMinimumRateHistory(this.rateId).subscribe((res) => {
      res = res.map((entry) => ({
        ...entry,
        field: this.nameMap[entry.field] ?? entry.field,
      }));
      this.entries = res;
      this.filteredEntries = res;
    });
    this.dateInputMask = this.maskService.getInputDateOnlyMask();
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  filterByDate() {
    this.filteredEntries = this.entries.filter((history) => {
      const createdDate = new Date(history.dateCreatedUtc);
      return createdDate > this.startDate && createdDate < this.endDate;
    });
  }
}
