<div class="container">
  <div class="col-md-12">
    <legend>Trailers</legend>
    <div class="btnRig">
      <button
        id="edit"
        name="edit"
        class="btn btn-primary"
        routerLink="/newtrailer"
        *ngIf="hasPermission('TrailersChange')"
      >
        Add New Trailer
      </button>
    </div>
    <p-table
      #dt
      [columns]="colsTrailers"
      [value]="allRecord"
      sortMode="multiple"
      [scrollable]="true"
      scrollHeight="700px"
      [virtualScroll]="true"
      [virtualScrollItemSize]="46"
      stateStorage="session"
      stateKey="trailerslist-table"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of columns" [style.width.px]="col.width || 150" />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" style="width:20%;">
            {{ col.header }}
            <p-sortIcon *ngIf="col.header.length" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <input
              *ngIf="col.type !== 'boolean'"
              type="text"
              style="width: 100%"
              (input)="$any(dt).filter($event.target.value, col.field, 'contains')"
              [value]="$any(dt).filters[col.field]?.value"
            />
            <p-dropdown aria-label="status"
              *ngIf="col.type === 'boolean'"
              [options]="statusOptions"
              (onChange)="$any(dt).filter($event.value, col.field, 'equals')"
              [ngModel]="$any(dt).filters[col.field]?.value"
              appendTo="body"
              tabbableDropdown
            >
            </p-dropdown>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-trailer>
        <tr class="clickable-row" (dblclick)="viewDetail(trailer)" style="height:42px;">
          <td>{{ trailer.trailerNo }}</td>
          <td>{{ trailer.trailerType }}</td>
          <td>{{ trailer.equipmentCode }}</td>
          <td>{{ trailer.terminalLocation }}</td>
          <td>{{ trailer.active }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
