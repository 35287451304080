import { forkJoin as observableForkJoin, of as observableOf, Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "../http/http-client.service";

@Injectable()
export class NewUploadService {
  re = /(?:\.([^.]+))?$/;
  uploadProgress = new Subject<any>();
  uniqueID = function () {
    function chr4() {
      return Math.random().toString(16).slice(-4);
    }
    return (
      chr4() + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + chr4() + chr4()
    );
  };

  getUniqueName = function (name) {
    return this.uniqueID() + "." + this.re.exec(name)[1];
  };

  constructor(private http: HttpClient) {}

  requestCredentials = function (fileName) {
    return this.http.get("api/aws/" + fileName);
  };

  updateUploadProgress(progress) {
    this.uploadProgress.next(progress);
  }
  getUploadPogress() {
    return this.uploadProgress;
  }

  getCredentials = function (files) {
    const credentialsObservables = [];

    if (files && files.length) {
      for (let x = 0; x <= files.length - 1; x++) {
        const uName = this.getUniqueName(files[x].name);
        credentialsObservables.push(
          this.requestCredentials(uName)
            .map((res) => {
              res = res;
              res.file = files[x];
              res.uName = uName;
              return res;
            })
            .catch((err) => {
              console.log(err);
              observableOf(err);
            })
        );
      }
      return observableForkJoin(credentialsObservables);
    }
  };
}
