import { Component, OnInit } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { CanComponentDeactivate } from "../shared/guards/confirmation/confirmation.guard";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "app-utilities",
  templateUrl: "./utilities.component.html",
  styleUrls: ["./utilities.component.css"],
})
export class UtilitiesComponent implements OnInit, CanComponentDeactivate {
  selectedTab: string;
  isGuardActive: boolean = true;

  constructor(private _AuthService: authenticationService) {}

  ngOnInit() {
    this.selectedTab = "PcMiler";
  }

  selectedNewTab(newTabSelected) {
    this.selectedTab = newTabSelected;
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  confirm() {
    if (this.isGuardActive) {
      return window.confirm("You have unsaved changes. Are you sure you want to leave this page?");
    } else {
      return true;
    }
  }
}
