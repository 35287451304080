import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { RoleService } from "../shared/role.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-listroles",
  templateUrl: "./listroles.component.html",
  styleUrls: ["./listroles.component.css"],
})
export class ListRolesComponent implements OnInit {
  role: any = {};
  allRoles: any;
  errorMsg;
  rolesCols;
  displayEdit: boolean = false;
  displayAdd: boolean = false;
  rolName;
  desc;
  priority;
  originalInfo;
  rolSelected;
  searchRole;
  @Output("isGuardActive") isGuardActive = new EventEmitter();

  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private _roleService: RoleService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.rolesCols = [{ field: "rolName", header: "Role Name" }];

    this.allRoles = this._ActivatedRoute.snapshot.data.roles;
    this.searchRole = this._ActivatedRoute.snapshot.data.users;
    this.rolSelected = {};
  }

  confirmationDelete(role) {
    this.confirmationService.confirm({
      key: "deleteRoleDialog",
      message: "Do you really want to delete this role?.",
      accept: () => {
        this.deleteRole(role);
      },
      reject: () => {},
    });
  }

  deleteRole(role) {
    this._roleService.delete(role).subscribe(
      (result) => {
        this.allRoles = this.allRoles.filter(function (rol) {
          return rol.uuid != role.uuid;
        });
        this.toastr.success("Role deleted", "Success!");
      },
      (error) => {
        console.dir(error);
        this.toastr.error("Error", error.message);
      }
    );
  }

  showEditForm(role) {
    this.displayEdit = true;

    this._roleService.getRoleInfo(role).subscribe((roleInfo) => {
      this.rolSelected = roleInfo;
    });
  }

  showAddForm() {
    this.displayAdd = true;
  }
}
