import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { TcheckService } from "./tcheck.service";
import { ToastrService } from "ngx-toastr";
import { CanComponentDeactivate } from "../shared/guards/confirmation/confirmation.guard";
import { TableReportComponent } from "@app/shared/components/table-report/table-report/table-report.component";
import { TchekTablesService } from "./tchek-tables.service";
import { SettlementsService } from "@app/settlements/shared/settlements.service";
import { BatchGlobal } from "@app/shared/models/BatchGlobal.model";

@Component({
  selector: "app-tcheck",
  templateUrl: "./tcheck.component.html",
  styleUrls: ["./tcheck.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TChekComponent implements OnInit, CanComponentDeactivate {
  storeRecordsId: string = "TChekImportRecords";
  records: any = [];
  successRecords: any = [];
  errorRecords: any = [];
  warningRecords: any = [];
  isGuardActive: boolean = false;
  loadingErrors: boolean = false;
  activeBatch: BatchGlobal;
  tchekScreenTotals: any = [];
  @Input() maxFiles: Number = 5;
  @Output() uploadStatus = new EventEmitter();
  @ViewChild("tableReportSuccess", { static: false })
  tableReportSuccessComponent: TableReportComponent;
  @ViewChild("tableReportWarning", { static: false })
  tableReportWarningComponent: TableReportComponent;
  @ViewChild("tableReportError", { static: false }) tableReportErrorComponent: TableReportComponent;
  constructor(
    private tcheckService: TcheckService,
    private tchekTables: TchekTablesService,
    private toastr: ToastrService,
    private settlementsService: SettlementsService,
    private cdr: ChangeDetectorRef
  ) {}

  confirm() {
    if (this.isGuardActive) {
      return window.confirm(
        "Your changes have been saved so you can come back to them later. If you wish to reset the data, you'll need to press the Clear Records button. Are you sure you want to leave this page?"
      );
    } else {
      return true;
    }
  }

  ngOnInit() {
    this.tcheckService.getScreenRecords().subscribe((storedRecords: any) => {
      if (storedRecords && storedRecords.length) {
        this.setRecords(storedRecords);
        this.refresh();
        this.isGuardActive = true;
        this.cdr.markForCheck();
      }
    });

    this.settlementsService.getActiveBatch().subscribe((batch) => {
      this.activeBatch = batch;
      this.cdr.markForCheck();
    });
  }

  onFileUpload(records: any[]) {
    this.setRecords(records);
    this.isGuardActive = true;

    this.refresh();
  }

  setRecords(records: any[]) {
    this.records = records;
    this.successRecords = this.records.filter((record) => !record.errorMsg && !record.warningMsg);
    this.warningRecords = this.records.filter((record) => record.warningMsg != null);
    this.errorRecords = this.records.filter((record) => record.errorMsg != null);
    this.calculateTotals();
  }

  refresh() {
    this.loadingErrors = true;

    this.tcheckService
      .refreshRecords(
        this.records.map((record) => {
          if (record.errorMsg) {
            delete record.errorMsg;
            delete record.errorInfo;
          }
          if (record.warningMsg) {
            delete record.warningMsg;
            delete record.warningInfo;
          }
          return record;
        })
      )
      .subscribe(
        (records: any) => {
          for (let index = 0; index < records.length; index++) {
            records[index].index = index;
          }
          this.records = records;
          this.successRecords = this.records.filter(
            (record) => !record.errorMsg && !record.warningMsg
          );
          this.warningRecords = this.records.filter((record) => record.warningMsg != null);
          this.errorRecords = this.records.filter((record) => record.errorMsg != null);
          this.cdr.markForCheck();
          this.toastr.success("Processing completed", "Success");
        },
        (error) => {
          this.toastr.error(error.message, "Error");
        },
        () => {
          this.loadingErrors = false;
        }
      );
  }

  getTableRecords() {
    const success = this.successRecords.length ? this.tableReportSuccessComponent.records : [];
    const warning = this.warningRecords.length ? this.tableReportWarningComponent.records : [];
    const error = this.errorRecords.length ? this.tableReportErrorComponent.records : [];

    let total = error
      .map((record) => {
        record.errorMsg += record.errorInfo.card;
        return record;
      })
      .concat(
        warning.map((record) => {
          record.warningMsg += record.warningInfo.card;
          return record;
        })
      )
      .concat(success);
    return total;
  }

  save() {
    const records = this.getTableRecords();
    const recordsToImport = records
      .filter((record) => !record.errorMsg && !record.warningMsg)
      .map((record) => ({
        ...record,
        id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        deletedAt: undefined,
      }));

    this.tcheckService.saveRecords(recordsToImport).subscribe(
      () => {
        const remainingRecords = records.filter((record) => record.errorMsg || record.warningMsg);
        this.setRecords(remainingRecords);
        sessionStorage.removeItem(this.storeRecordsId);
        this.toastr.success("Import completed", "Success");
        this.isGuardActive = false;
        this.cdr.markForCheck();
        this.reloadRecords();
      },
      (error) => {
        this.toastr.error(error, "Error");
      }
    );
  }

  clear() {
    // This code copied to save() above.
    this.setRecords([]);
    sessionStorage.removeItem(this.storeRecordsId);
    this.tcheckService.clearScreenRecords().subscribe((res) => {
      this.toastr.success("Cleared records", "Success");
      this.isGuardActive = false;
      this.cdr.markForCheck();
    });
  }

  getSum(attribute) {
    let records = this.getTableRecords();
    var value = 0;
    records.forEach((record) => {
      value += Number(record[attribute] || 0);
    });
    return value.toFixed(2);
  }

  reloadRecords() {
    this.tcheckService.getScreenRecords().subscribe((storedRecords: any) => {
      if (storedRecords && storedRecords.length) {
        this.setRecords(storedRecords);
        this.refresh();
        this.isGuardActive = true;
        this.cdr.markForCheck();
      }
    });
  }

  calculateTotals() {
    var total = {
      fuelAmount1: this.sum("fuelAmount1"),
      fuelAmount2: this.sum("fuelAmount2"),
      nonHighwayAmount: this.sum("nonHighwayAmount"),
      oilAmount: this.sum("oilAmount"),
      otherAmount1: this.sum("otherAmount1"),
      otherProductAmount2: this.sum("otherProductAmount2"),
      cash: this.sum("cash"),
      dashCash: this.sum("dashCash"),
      discountAmount: this.sum("discountAmount"),
      customerFee: this.sum("customerFee"),
      express: this.sum("express"),
      totalAmount: this.sum("totalAmount"),
      invoiceTotal: this.sum("invoicetotal"),
    };
    this.tchekScreenTotals = [total];
  }

  sum(column) {
    const success = this.tableReportSuccessComponent
      ? this.tableReportSuccessComponent.records
      : this.successRecords;
    const warning = this.tableReportWarningComponent
      ? this.tableReportWarningComponent.records
      : this.warningRecords;
    const error = this.tableReportErrorComponent
      ? this.tableReportErrorComponent.records
      : this.errorRecords;

    const successTotal = success.reduce((sum, r) => sum + Number(r[column]), 0);
    const warningTotal = warning.reduce((sum, r) => sum + Number(r[column]), 0);
    const errorTotal = error.reduce((sum, r) => sum + Number(r[column]), 0);
    const fullTotal = successTotal + warningTotal + errorTotal;
    return Math.round(fullTotal * 100) / 100;
  }

  dowloadCSV() {
    const cols = this.tchekTables.reportCols;
    this.tchekTables.exportToCSV(this.records, cols, "merge");
  }
}
