import { Component, ViewChild, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "locations-component",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.css"],
})
export class LocationsComponent implements OnInit {
  @ViewChild("locationListComponent", { static: true }) locationListComponent;
  @ViewChild("yesterdayLocationsComponent", { static: true })
  yesterdayLocationsComponent;

  selectedTab: String;
  clients: Array<any>;

  constructor(
    private route: ActivatedRoute,
    private _AuthService: authenticationService
  ) {}

  ngOnInit() {
    this.selectedTab = "list";
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  selectedNewTab(selectedTab) {
    this.selectedTab = selectedTab;
  }

  tabSelected = (selectedTab) => {
    this.selectedTab = selectedTab;
  };
}
