import { throwError as observableThrowError, Observable, Subject } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { HttpClient } from "../../shared/services/http/http-client.service";

@Injectable()
export class LocationsService {
  newLocationCreated = new Subject();
  locationUpdated = new Subject();

  constructor(private http: HttpClient) {}

  generateLocationId(name: string, city: string, state: string) {
    if (name && city && state) {
      const locationName = name.slice(0, 4);
      const locationCity = city.slice(0, 2);
      const locationState = state.slice(0, 2);
      return (locationName + locationCity + locationState).toUpperCase();
    }
  }

  checkLocationIdExists(locationId: string) {
    return this.http.post(
      "api/location/checkLocationIdExists",
      {
        locationId,
      },
      null,
      false
    );
  }

  uploadLocations() {
    return this.http.post("api/location/uploadLocations", null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.Message || "Server error");
      })
    );
  }

  fixLocations() {
    return this.http.post("api/location/fixLocations", null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.Message || "Server error");
      })
    );
  }

  createLocation(newLocation: any) {
    return this.http.post("api/location/create", { location: newLocation }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error.Message || "Server error");
      })
    );
  }

  listAllLocationIds(): Observable<any> {
    return this.http.get("api/location/listAllLocationIds").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.Message || "Server error"))
    );
  }

  getLocationsTrip24Hours() {
    return this.http.get("api/location/listTodayAddress").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.Message || "Error getting yesterday locations")
      )
    );
  }

  update(location: any) {
    return this.http
      .patch("api/location/updateLocation", {
        id: location.id,
        name: location.name,
        locId: location.locId,
        zipcode: location.zipcode,
        address: location.address,
        address2: location.address2,
        isBillTo: location.isBillTo,
        isConsignee: location.isConsignee,
        isShipper: location.isShipper,
        billToClient: location.billToClient,
        consigneeClients: location.consigneeClients,
        city: location.city,
        state: location.state,
        latitude: location.latitude,
        longitude: location.longitude,
        country: location.country,
        confidenceLevel: location.confidenceLevel,
        countryAbbreviation: location.countryAbbreviation,
        stateAbbreviation: location.stateAbbreviation,
        numMatches: location.numMatches,
        isUserChosen: location.isUserChosen,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.Message || "Error updating location"))
      );
  }

  updateLocationsCoordinates(locations: any[]) {
    const locationsToUpdate = locations.map((location) => ({
      latitude: location.latitude,
      longitude: location.longitude,
      id: location.id,
    }));
    return this.http.patch("api/location/updateLocationsCoordinates", { locationsToUpdate }).pipe(
      map((res) => res),
      catchError((error: any) =>
        observableThrowError(error.Message || "Error updating coordinates")
      )
    );
  }

  getLocationsByClient(clientId: number) {
    return this.http
      .post("api/location/listByClientDropDown", {
        clientId: clientId,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) =>
          observableThrowError(error.json || "Server list locations by client error")
        )
      );
  }

  listBillTo() {
    return this.http.get("api/location/listBillTo").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.Message || "Server error"))
    );
  }

  listShipper(filter = "", pageSize = 20, page = 0, showLoading = true): Observable<any> {
    return this.http
      .post("api/location/listShipper", { filter, pageSize, page }, null, showLoading)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.Message || "Server error"))
      );
  }

  listConsignee(filter = "", pageSize = 20, page = 0, showLoading = true): Observable<any> {
    return this.http
      .post("api/location/listConsignee", { filter, pageSize, page }, null, showLoading)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.Message || "Server error"))
      );
  }

  listAllLocations(filter = "", size = 20, offset = 0, showLoading = true) {
    return this.http
      .post("api/location/listAllLocations", { filter, size, offset }, null, showLoading)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.Message || "Server error"))
      );
  }

  listAllLocationsFilters(
    filter = {},
    pageSize = 20,
    pageOffset = 0,
    sortField: string,
    sortOrder: number,
    showLoading = true
  ) {
    return this.http
      .post(
        "api/location/listAllLocationsFilters",
        { filter, pageSize, pageOffset, sortField, sortOrder },
        null,
        showLoading
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.Message || "Server error"))
      );
  }

  listYesterdayLocationsFilters(filter = {}, pageSize = 20, pageOffset = 0, showLoading = true) {
    return this.http
      .post(
        "api/location/listYesterdayLocationsFilters",
        { filter, pageSize, pageOffset },
        null,
        showLoading
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.Message || "Server error"))
      );
  }

  updateIsShipper(location) {
    return this.http
      .patch("api/location/updateIsShipper", {
        isShipper: location.isShipper,
        id: location.id,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.Message || "Error updating shipper"))
      );
  }

  updateIsConsignee(location: any) {
    return this.http
      .patch("api/location/updateIsConsignee", {
        isConsignee: location.isConsignee,
        id: location.id,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) =>
          observableThrowError(error.Message || "Error updating consignee")
        )
      );
  }

  delete(location: any) {
    return this.http.delete("api/location/delete/" + location.id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.Message || "Error deleting location"))
    );
  }

  getLocationById(locationId: number) {
    if (!locationId) return observableThrowError("Location Id is null");
    return this.http.get("api/location/getLocationById/" + locationId).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.Message || "Error getting location"))
    );
  }

  getLocationByIdAsync(locationId: number): Promise<any> {
    return this.http.get("api/location/getLocationById/" + locationId).toPromise();
  }
}
