import { Component, Input, OnInit } from "@angular/core";
import { SettlementsService } from "@app/settlements/shared/settlements.service";
import { ToastrService } from "ngx-toastr";
import { DeductionService } from "../../deduction.service";

@Component({
  selector: "app-settlement-teams-tab",
  templateUrl: "./settlement-teams-tab.component.html",
  styleUrls: ["./settlement-teams-tab.component.css"],
})
export class SettlementTeamsTabComponent implements OnInit {
  @Input("batchStatus") batchStatus: string;
  @Input("batchId")
  set batchId(batchId: number) {
    this._batchId = batchId;
    if (batchId) this.loadTeamSplits(batchId);
  }
  deductions: any = [];
  splits: any = [];
  drivers: any = [];
  totals: any = {};
  tableWidth: number = 350;
  driverColumnWidth: number = 200;
  invalidTotal: boolean = false;
  _batchId: number;

  cols = [
    { field: "chargeType", header: "Charge Type" },
    { field: "total", header: "Total" },
  ];

  constructor(private _SettlementsService: SettlementsService, private toastr: ToastrService, private DeductionService: DeductionService) {}

  ngOnInit(): void {}

  recalculateTotals(): void {
    this.calculateCustomTotals();
    this.calculateTeamTotals();
  }

  getPayoutAmounts(split, driverId) {
    if (split.payoutAmounts) {
      return split.payoutAmounts.find((m) => m.driverId == driverId); 
    }
  }

  calculateCustomTotals(): void {
    this.invalidTotal = false;
    for (const split of this.splits) {
      let total = 0;
      for (const driver of this.drivers) {
        const customValue = split.payoutAmounts.find(m => m.driverId == driver.id).customValue;
        total += Math.round(customValue * 100) / 100;
      }
      // round to 2 decimal places
      split.customTotal = Math.round(total * 100) / 100;
      if (split.customTotal != split.total) this.invalidTotal = true;
    }
  }

  calculateTeamTotals(): void {
    this.totals = {};
    for (const split of this.splits) {
      for (const driver of this.drivers) {
        const customValue = split.payoutAmounts.find(m => m.driverId == driver.id).customValue;
        if (this.totals[driver.id]) {
          this.totals[driver.id] += customValue;
        } else {
          this.totals[driver.id] = customValue;
        }
      }
    }
    this.totals.total = this.splits.reduce((acc, curr) => acc + curr.total, 0);
  }

  loadTeamSplits(batchDriverId: number): void {
    this.DeductionService.getDeductionDropdown().subscribe((data) => {
      this.deductions = data;
    });
    this._SettlementsService.getTeamSplitByBatchDriverId(batchDriverId).subscribe(
      (data: any) => {      
        const { splits, drivers, totals } = data;
        this.splits = splits;
        for (var i = 0; i < this.splits.length; i++) {
          if (!this.splits[i].chargeType) continue;
          if (this.splits[i].chargeType.name == "DEFADD") {
            this.splits[i].chargeType.name = "DEFICIT SUBTOTAL";
          }
          if (this.splits[i].chargeType.name == "ESCADD") {
            this.splits[i].chargeType.name = "ESCROW SUBTOTAL";
          }
          if (this.splits[i].chargeType.name == "FUEL" || this.splits[i].chargeType.name == "ESCROW SUBTOTAL" || this.deductions.some((deduction) => deduction.chargeType.id == this.splits[i].chargeType.id)) {
            this.splits[i].total = this.splits[i].total * -1;
            for (var j = 0; j < this.splits[i].payoutAmounts.length; j++) {
              this.splits[i].payoutAmounts[j].customValue = this.splits[i].payoutAmounts[j].customValue * -1;
              this.splits[i].payoutAmounts[j].originalValue = this.splits[i].payoutAmounts[j].originalValue * -1;
            }
          }
        }
        this.drivers = drivers;
        this.totals = totals;
        if (!this.totals) this.totals = { total : 0 }
        this.tableWidth = 350 + this.drivers.length * this.driverColumnWidth;

        this.drivers.sort((a, b) => b.earningPercentage - a.earningPercentage);

        const teamDriverColumns = drivers.map((driver) => {
          const header = `${driver.name} ${driver.lastName}`;
          return { field: driver.id, header: header };
        });

        this.cols = [...this.cols, ...teamDriverColumns];

        this.recalculateTotals();
      },
      (error) => {
        this.toastr.error(error.message, "Error loading team splits");
      }
    );
  }

  validate(): boolean {
    this.recalculateTotals();
    for (const split of this.splits) {
      if (split.total !== split.customTotal) {
        this.toastr.error(`Team Splits are invalid`, "Error");
        return false;
      }
    }
    return true;
  }

  getOverrides(): any {
    const overrides = [];
    for (const split of this.splits) {
      for (const driver of this.drivers) {
        const { customValue, originalValue } = this.getPayoutAmounts(split, driver.id);
        if (customValue !== originalValue) {
          overrides.push({
            BatchId: this._batchId,
            ChargeTypeId: split.chargeType.id,
            DriverId: driver.id,
            customValue: this.getPayoutAmounts(split, driver.id).customValue,
          });
        }
      }
    }
    return overrides;
  }
}
