import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "../../shared/services/http/http-client.service";

@Injectable()
export class NewBillService {
  constructor(private _http: HttpClient) {}

  getBillList() {
    return this._http.post("api/invoice/list", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getTripBillInfo(tripId: number) {
    return this._http.post("api/invoice/findByTrip", { id: tripId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  getBillInfoById(tripId: string) {
    return this._http.get(`api/trip/findInvoiceByTripId/${tripId}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  saveBillingInfo(tripBilling) {
    return this._http.post("api/invoice/createOrUpdate", { BillInfo: tripBilling }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  sendToBillingReview(trip) {
    return this._http.post("api/invoice/sendToBillingReview", { Trip: trip }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }

  sendBill(trip) {
    return this._http.post("api/invoice/sendBill", { Trip: trip }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server trip creation error")
      )
    );
  }
}
