import { Injectable } from "@angular/core";

import { TrailerService } from "./trailer.service";

@Injectable()
export class TrailersResolver {
  constructor(private trailerService: TrailerService) {}

  resolve() {
    return [];
    return this.trailerService.gettrailerList(0, 20, {}, "id");
  }
}
