import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addschedule',
  templateUrl: './addschedule.component.html',
  styleUrls: ['./addschedule.component.css']
})
export class AddscheduleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
