import { Injectable } from "@angular/core";

import { TractorService } from "./tractor.service";

@Injectable()
export class TractorsSelectFormatResolver {
  constructor(private tractorService: TractorService) {}

  resolve() {
    return [];
    return this.tractorService.listSelectFormat();
  }
}
