import { Component, ViewChild, OnInit, Input, ElementRef } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "../../services/http/http-client.service";

@Component({
  selector: "app-mixed-preview-files",
  templateUrl: "./mixed-preview-file.component.html",
  styleUrls: ["./mixed-preview-file.component.css"],
})
export class MixedPreviewFileComponent implements OnInit {
  @Input("files")
  _files: Array<any>;
  src: any;
  currentFile: any;
  index = 0;
  reader = new FileReader();
  @ViewChild("img") img: ElementRef;
  constructor(private httpClient: HttpClient) {}

  set files(fls) {
    if (!this._files.length) {
      this.initComponent(fls);
    } else {
      this._files = fls;
    }
  }
  ngOnInit() {}

  async initComponent(files) {
    this._files = files;
    if (this._files && this._files.length) {
      // if is new
      if (this._files.length && !this._files[0].id) {
        this.reader.onload = (e) => {
          this.src = this.reader.result;
          this.currentFile = this._files[0];
        };
        this.reader.readAsDataURL(this._files[0]);
        // if is already atteched
      } else {
        this.currentFile = this._files[0];
        const src = environment.filesUrl + "download/" + this._files[0].path;
        this.src = this.fileType("pdf")
          ? URL.createObjectURL((await this.httpClient.download(src)).body)
          : src;
      }
    } else {
      this.src = null;
      this.currentFile = null;
    }
  }

  async prev() {
    if (this.index - 1 >= 0) {
      if (this._files.length && !this._files[this.index - 1].id) {
        this.index--;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.src = reader.result;
          this.currentFile = this._files[this.index];
        };
        reader.readAsDataURL(this._files[this.index]);
      } else {
        this.index--;
        this.currentFile = this._files[this.index];
        const src = environment.filesUrl + "download/" + this._files[this.index].path;
        this.src = this.fileType("pdf")
          ? URL.createObjectURL((await this.httpClient.download(src)).body)
          : src;
      }
    }
  }

  async next() {
    if (this._files.length && this.index + 1 < this._files.length) {
      if (!this._files[this.index + 1].id) {
        this.index++;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.src = reader.result;
          this.currentFile = this._files[this.index];
        };
        reader.readAsDataURL(this._files[this.index]);
      } else {
        this.index++;
        this.currentFile = this._files[this.index];
        const src = environment.filesUrl + "download/" + this._files[this.index].path;
        this.src = this.fileType("pdf")
          ? URL.createObjectURL((await this.httpClient.download(src)).body)
          : src;
      }
    }
  }

  async goToLast() {
    if (this._files.length) {
      if (!this._files[this._files.length - 1].id) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.src = reader.result;
          this.currentFile = this._files[this._files.length - 1];
        };
        reader.readAsDataURL(this._files[this._files.length - 1]);
        this.index = this._files.length - 1;
      } else {
        this.currentFile = this._files[this._files.length - 1];
        this.index = this._files.length - 1;
        const src = environment.filesUrl + "download/" + this._files[this._files.length - 1].path;
        this.src = this.fileType("pdf")
          ? URL.createObjectURL((await this.httpClient.download(src)).body)
          : src;
      }
    } else {
      this.src = null;
      this.currentFile = null;
    }
  }

  fileType(type) {
    if (!this.currentFile) return false;
    if (!this.currentFile.type) return false;

    this.currentFile.type = this.currentFile.type.toLowerCase();
    if (type == "pdf" && this.currentFile.type == type) {
      return true;
    } else if (
      (type == "jpg" && this.currentFile.type == type) ||
      (type == "jpg" && this.currentFile.type == "png")
    ) {
      return true;
    }
  }
  open() {
    if (typeof this.currentFile !== "undefined" && this.currentFile != null) {
      window.open(
        this.fileType("pdf") ? this.src : this.img.nativeElement.src,
        "mywindow",
        "menubar=1, resizable=1, width=700, height=700"
      );
    }
  }
}
