import { Component, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng/api";
import { Md5 } from "ts-md5";
import { UserService } from "../shared/user.service";
import { Role } from "@models/role.model";
import { User } from "@models/user.model";
import { authenticationService } from "@app/login/auth.service";

@Component({
  selector: "app-updateuser",
  templateUrl: "./updateuser.component.html",
  styleUrls: ["./updateuser.component.css"],
})
export class UpdateuserComponent implements OnInit {
  @Input() selectedUser: any;

  @Output() userChangedEvent = new EventEmitter<User>();
  @Output() userDeletedEvent = new EventEmitter<User>();
  @Output() userRestoredEvent = new EventEmitter<User>();

  username: string;
  isProcessing = false;
  hasSubmitted = false;
  allRoles: Role[];
  errorMsg: string;

  userForm = new UntypedFormGroup(
    {
      sendReset: new FormControl(false),
      email: new UntypedFormControl("", [Validators.required, Validators.email]),
      name: new UntypedFormControl(""),
      lastName: new UntypedFormControl(""),
      emailSignature: new UntypedFormControl(""),
      roleId: new UntypedFormControl("", Validators.required),
    }
  );

  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _userService: UserService,
    private authService: authenticationService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.allRoles = this._ActivatedRoute.snapshot.data.roles;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedUser = changes.selectedUser;

    if (selectedUser && !selectedUser.firstChange) {
      const user = selectedUser.currentValue;
      this.email.setValue(user.email);
      this.name.setValue(user.name);
      this.lastName.setValue(user.lastName);
      this.emailSignature.setValue(user.emailSignature);
      this.roleId.setValue(user.RoleId);
      this.username = user.username;
    }
  }

  get email() {
    return this.userForm.get("email");
  }
  get name() {
    return this.userForm.get("name");
  }
  get lastName() {
    return this.userForm.get("lastName");
  }
  get emailSignature() {
    return this.userForm.get("emailSignature");
  }
  get roleId() {
    return this.userForm.get("roleId");
  }

  onSubmit() {
    if (this.selectedUser.isDeleted) return;
    this.hasSubmitted = true;

    if (this.userForm.invalid) return;

    const user = {
      ...this.selectedUser,
      email: this.email.value,
      name: this.name.value,
      lastName: this.lastName.value,
      RoleId: this.roleId.value,
      username: this.selectedUser.username,
      emailSignature: this.emailSignature.value,
      uuid: this.selectedUser.uuid,
      id: this.selectedUser.id,
    };

    this.updateUser(user);
  }

  updateUser(user: User) {
    this._userService.updateUser(user).subscribe(
      (res: any) => {
        this.userChangedEvent.emit(user);

        if (this.userForm.value.sendReset) {
          this.authService.startReset(user.username).subscribe(
            () => {
              this.toastr.success("User updated", "Success!");
              this.closeModal();
            },
            (error) => {
              this.toastr.warning(error.message || "Server Error", "Alert!", {
                closeButton: true,
                enableHtml: true
              });
            });
        } else {
          this.toastr.success("User updated", "Success!");
          this.closeModal();
        }
      },
      (error) => {
        this.toastr.warning("error", "Alert!", {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
      }
    );
  }

  deleteUser(userId: string) {
    this._userService.deleteUser(userId).subscribe((result) => {
      this.userDeletedEvent.emit(this.selectedUser);
    });
  }

  confirmationDelete() {
    this.confirmationService.confirm({
      key: "deleteUserDialog",
      message: "Do you really want to delete this user?.",
      accept: () => {
        this.deleteUser(this.selectedUser.id);
        this.closeModal();
      },
      reject: () => {},
    });
  }

  restoreUser(userId: string) {
    this._userService.restoreUser(userId).subscribe(() => {
      this.userRestoredEvent.emit(this.selectedUser);
      this.closeModal();
    });
  }

  closeModal() {
    const closeModal = document.getElementsByClassName("p-dialog-header-close")[0] as HTMLElement;
    closeModal.click();
  }
}
