import { Component, OnInit } from "@angular/core";
import { scheduleService } from "./schedule.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.css"],
})
export class ScheduleComponent implements OnInit {
  errorMsg: any;
  schedulelist: any = [];

  constructor(private _schedulelist: scheduleService, private router: Router) {}

  ngOnInit() {
    this._schedulelist.gettrailerList().subscribe(
      (resScheduleData) => (this.schedulelist = resScheduleData),
      (resScheduleDataError) => (this.errorMsg = resScheduleDataError)
    );
  }

  viewDetail(schedule) {
    this.router.navigate(["/scheduleinfo/", schedule.uuid]);
  }
}
