import { Component, ViewChild, OnInit, Input, ElementRef, NgModule } from "@angular/core";
import { FileUploadService } from "@app/shared/services/files/fileUpload.service";

@Component({
  selector: "app-new-mixed-preview-files",
  templateUrl: "./newmixed-preview-file.component.html",
  styleUrls: ["./newmixed-preview-file.component.css"],
})
export class NewMixedPreviewFileComponent implements OnInit {
  @Input("files")
  _files: Array<any> = [];
  src: any;
  currentFile: any;
  index = 0;
  reader = new FileReader();
  @ViewChild("img") img: ElementRef;
  constructor(private _FileUploadService: FileUploadService) {}

  set files(fls) {
    if (!this._files.length) {
      this.initComponent(fls);
    } else {
      this._files = fls;
    }
  }
  ngOnInit() {}

  initComponent(files: any) {
    this._files = files;
    if (this._files && this._files.length) {
      this.openFile();
    } else {
      this.src = null;
      this.currentFile = null;
    }
  }

  openFile() {
    this.currentFile = this._files[this.index];
    if (!this.currentFile) return;
    if (!this.currentFile.id) {
      // for locally uploaded files
      this.reader.onload = (e) => {
        this.src = this.reader.result;
      };
      this.reader.readAsDataURL(this.currentFile);
    } else {
      // get the file url from the database
      if ((this.currentFile.path && this.currentFile.path.includes("trip")) || (this.currentFile.blobName && this.currentFile.blobName.includes("trip"))) {
        this._FileUploadService.getPictureUrl(this.currentFile.name, "trip").subscribe((promise) => {
          this.src = promise.url;
        });
      }
    }
  }

  prev() {
    if (this.index - 1 >= 0) {
      this.index--;
      this.openFile();
    }
  }

  next() {
    if (this.index + 1 < this._files.length) {
      this.index++;
      this.openFile();
    }
  }

  goToLast() {
    this.index = this._files.length - 1;
    this.openFile();
  }

  goToFile(fileId: number) {
    const index = this._files.findIndex((file) => file.id === fileId || file.tempId === fileId);
    if (index >= 0) {
      this.index = index;
      this.openFile();
    }
  }

  fileType(type: string) {
    if (!this.currentFile) return false;
    if (!this.currentFile.type) return false;

    const currentFileType = this.currentFile.type.toLowerCase();
    if (type.includes("pdf") && currentFileType.includes(type)) {
      return true;
    } else if (
      (type.includes("jpeg") && currentFileType.includes(type)) ||
      (type.includes("jpeg") && currentFileType == "image/png")
    ) {
      return true;
    }
  }

  open() {
    if (this.currentFile) {
      window.open(
        this.fileType("pdf") ? this.src : this.img.nativeElement.src,
        "mywindow",
        "menubar=1, resizable=1, width=700, height=700"
      );
    }
  }
}
