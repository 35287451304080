import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Table } from "primeng/table";
import { LazyLoadEvent } from "primeng/api";
import { cloneDeep } from "lodash";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { authenticationService } from "@app/login/auth.service";
import { LocationsService } from "../shared/locations.service";
import { StatesServices } from "../../shared/services/states/states.service";
import { ToastrService } from "ngx-toastr";
import { PcMilerService } from "../../pcMiler/shared/pcMiler.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "app-yesterday-locations",
  templateUrl: "./yesterday-locations.component.html",
  styleUrls: ["./yesterday-locations.component.css"],
})
export class YesterdayLocationsComponent implements OnInit, OnDestroy {
  @ViewChild("table", { static: true }) table: Table;

  columns = [
    { field: "countryAbbreviation", header: "Country", width: "110px" },
    { field: "name", header: "Name", width: "350px" },
    { field: "address", header: "Address", width: "350px" },
    { field: "city", header: "City", width: "220px" },
    { field: "stateAbbreviation", header: "State", width: "110px" },
    { field: "zipcode", header: "Zip", width: "90px" },
    { field: "latitude", header: "Latitude", width: "120px" },
    { field: "longitude", header: "Longitude", width: "120px" },
    { field: "shipper", header: "Shipper", width: "90px" },
    { field: "consignee", header: "Consignee", width: "110px" },
    { field: "billTo", header: "Bill To", width: "80px" },
    { field: "confidenceLevel", header: "Confidence", width: "200px" },
  ];

  rows = 100;
  locations = [];
  locationSnapshots = {};
  canEditLocations = false;
  isLoading = true;

  locationReference = null;
  exactLocationsData: any = [];

  filtersSubject = new Subject();
  filtersSubscription: Subscription;
  filters = {
    locId: "",
    countryAbbreviation: "",
    name: "",
    address: "",
    city: "",
    stateAbbreviation: "",
    zipcode: "",
    confidenceLevel: "",
  };

  countries = [
    { name: "CANADA", abbreviation: "CA" },
    { name: "MEXICO", abbreviation: "MX" },
    { name: "US", abbreviation: "US" },
  ];

  constructor(
    private _AuthService: authenticationService,
    private _LocationService: LocationsService,
    private _StatesService: StatesServices,
    private _ToastrService: ToastrService,
    private _PCMilerService: PcMilerService
  ) {}

  ngOnInit() {
    this.filtersSubscription = this.filtersSubject.pipe(debounceTime(500)).subscribe(() => {
      this.locations = [];
      this.getLocations(0, this.rows);
    });
  }

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe();
  }

  onLazyLoad(event: LazyLoadEvent) {
    this.getLocations(event.first, event.rows);
  }

  getLocations(offset: number, limit: number) {
    let observable = this._LocationService.listYesterdayLocationsFilters(
      this.filters,
      limit,
      offset,
      false
    );

    this.isLoading = true;
    observable.subscribe((result: any) => {
      if (!this.locations.length) this.locations = Array.from({ length: result.totalRecords });
      this.locations.splice(offset, limit, ...result.Locations);
      this.isLoading = false;
    });
  }

  isAllowed(name: string) {
    return this._AuthService.hasPermission(Permissions[name]);
  }

  isFilterable(field: string) {
    return Object.keys(this.filters).includes(field);
  }

  isEditingLocation(location: any) {
    return !!this.locationSnapshots[location.id];
  }

  toggleLocationEditing() {
    this.canEditLocations = !this.canEditLocations;
    this.forceUpdateFrozenColumns();

    if (!this.canEditLocations) {
      Object.values(this.locationSnapshots).forEach((locationSnapshot: any) => {
        const location = this.locations.find((l) => l.id === locationSnapshot.id);
        if (location) Object.assign(location, locationSnapshot);
        delete this.locationSnapshots[location.id];
      });
    }
  }

  editLocation(location: any) {
    this.locationSnapshots[location.id] = cloneDeep(location);
  }

  cancelEditLocation(location: any) {
    Object.assign(location, this.locationSnapshots[location.id]);
    delete this.locationSnapshots[location.id];
  }

  updateLocation(location: any) {
    const addressFields = [
      "address",
      "address2",
      "city",
      "state",
      "stateAbbreviation",
      "zipcode",
      "country",
      "countryAbbreviation",
    ];

    const locationSnapshot = this.locationSnapshots[location.id];
    const isAddressChanged = addressFields.some((p) => location[p] !== locationSnapshot[p]);

    if (!isAddressChanged) {
      this.updateLocationImmediate(location);
      return;
    }

    if (location.confidenceLevel !== "MANUAL") {
      this.openSelectExactAddressDialog(location);
      return;
    }

    if (
      confirm(
        "This address has a manual lat/long set. " +
          "Do you want to set the lat/long from PC Miler?"
      )
    ) {
      this.openSelectExactAddressDialog(location);
    } else {
      this.updateLocationImmediate(location);
    }
  }

  updateLocationImmediate(location: any) {
    this._LocationService.update(location).subscribe({
      next: () => {
        this._ToastrService.success("Location updated.", "Success");
        delete this.locationSnapshots[location.id];

        this.locationReference = null;
        this.exactLocationsData = [];
      },
      error: (error) => {
        this._ToastrService.error(error.message, "Error");
      },
    });
  }

  deleteLocation(location: any) {
    if (confirm("Are you sure you want to delete this location?")) {
      this._LocationService.delete(location).subscribe(() => {
        this.locations = [];
        this.getLocations(0, this.rows);
      });
    }
  }

  async openSelectExactAddressDialog(location: any) {
    this.locationReference = location;

    try {
      /*
      The dialog will open automatically when `exactLocationsData` is populated then
      later call `finishLocationUpdate` below to finish the update process.
      */
      this.exactLocationsData = await this._PCMilerService.geoCodeLocation(location);
    } catch (error) {
      this._ToastrService.error(error.responseText, "Error");
    }
  }

  finishLocationUpdate(exactLocationData: any) {
    this.locationReference.address = exactLocationData.Address.StreetAddress;
    this.locationReference.city = exactLocationData.Address.City;
    this.locationReference.state = exactLocationData.Address.State;
    this.locationReference.stateAbbreviation = exactLocationData.Address.StateAbbreviation;
    this.locationReference.zipcode = exactLocationData.Address.Zip;
    this.locationReference.country = exactLocationData.Address.Country;
    this.locationReference.countryAbbreviation = exactLocationData.Address.CountryAbbreviation;
    this.locationReference.latitude = exactLocationData.Coords.Lat;
    this.locationReference.longitude = exactLocationData.Coords.Lon;
    this.locationReference.confidenceLevel = exactLocationData.ConfidenceLevel;
    this.locationReference.numMatches = exactLocationData.numMatches;
    this.locationReference.isUserChosen = true;

    this.updateLocationImmediate(this.locationReference);
  }

  /*
  The PrimeNG table component does not support dynamically adding/removing frozen columns;
  it updates the positions of the frozen cells when toggling whether the cells are frozen,
  but it does not update the positions of the frozen cells if they are dynamically added or
  removed. This method can be called to update the positions of the frozen cells manually.
  */
  forceUpdateFrozenColumns() {
    let tableElement = this.table.tableViewChild.nativeElement;
    let rowElements = tableElement.querySelectorAll("tr");

    /*
    The `setTimeout(..., 0);` call allows the DOM to update following the insertion/removal
    of toggled elements before trying to read their widths, otherwise the widths will be 0.
    */
    setTimeout(() => {
      for (let rowElement of rowElements) {
        let frozenCellElements = rowElement.querySelectorAll(".p-frozen-column");

        let left = 0;
        for (let cellElement of frozenCellElements) {
          cellElement.style.left = `${left}px`;
          left += cellElement.offsetWidth;
        }
      }
    }, 0);
  }

  getStatesByCountry(countryAbbreviation) {
    return this._StatesService.getTwoLettersStates(countryAbbreviation);
  }

  setLocationCountry(location: any) {
    const predicate = (c: any) => c.abbreviation === location.countryAbbreviation;
    location.country = this.countries.find(predicate).name;
    location.stateAbbreviation = "";
    location.state = "";
  }

  setLocationState(location: any) {
    const states = this.getStatesByCountry(location.countryAbbreviation);
    const predicate = (s: any) => s.abbreviation === location.stateAbbreviation;
    location.state = states.find(predicate).fullName;
  }
}
