import { Component, OnInit } from "@angular/core";
import { scheduleinfoService } from "./scheduleinfo.service";
import { ActivatedRoute, Params, Router } from "@angular/router";

@Component({
  selector: "app-scheduleinfo",
  templateUrl: "./scheduleinfo.component.html",
  styleUrls: ["./scheduleinfo.component.css"],
})
export class ScheduleinfoComponent implements OnInit {
  uuid: number;
  errorMsg: any;
  schedule: any;

  constructor(
    private _scheduleInfo: ActivatedRoute,
    private scheduleInfoDataService: scheduleinfoService,
    private router: Router
  ) {}

  ngOnInit() {
    this._scheduleInfo.params.subscribe((params) => {
      this.uuid = params["uuid"];
      console.log(this.uuid);
    });

    this.scheduleInfoDataService.getInfo(this.uuid).subscribe((result) => {
      this.schedule = result;
    });
  }
}
