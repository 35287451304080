
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FileUploadService } from '../../../services/files/fileUpload.service';
import { Documentervice } from '../shared/document.service';
import { ToastrService } from 'ngx-toastr';
import { GetPictureService } from '../../../services/files/getPictures.service';

@Component({
  selector: 'createDocument',
  templateUrl: './createDocument.component.html',
  styleUrls: ['./createDocument.component.css']
})
export class CreateDocumentComponent implements OnInit {

  documents: Array<any> = [];
  test: any = [];
  newDocument: any = { files: [] };
  deletedFiles: any = [];
  docType: string = null;
  @Input('disableInputs') disableInputs;
  @ViewChild('fileInput') fileInput;

  constructor(
    private fileUploadService: FileUploadService,
    private documentervice: Documentervice,
    private toastr: ToastrService,
    private getPictureService: GetPictureService
  ) { }

  ngOnInit() {
  }

  getDocuments(docType, id) {
    this.docType = docType;
    if (docType.toLowerCase().indexOf('web') == 0 || docType.toLowerCase().indexOf('app') == 0)
      this.docType = docType.substr(3);
    this.documentervice.findDocuments(docType, id).subscribe((docs: any) => {
      this.documents = [];
      let onInd = null;
      let newDoc = null;
      docs.forEach(doc => {
        let docInd = doc.documentNo;
        let fName = doc.fileName;

        if (onInd !== docInd) {
          if (onInd !== null) {
            this.documents.push(JSON.parse(JSON.stringify(newDoc)));
          }
          newDoc = {files: [{name: fName, id: doc.id}], name: doc.name, inDatabase: true};
          onInd = docInd;
        } else {
          newDoc.files.push({name: fName, id: doc.id});
        }
      });
      if (newDoc !== null)
        this.documents.push(JSON.parse(JSON.stringify(newDoc)));
    });
  }

  addNewDocument(newDocument) {
    if (!newDocument.name) {
      this.toastr.warning('Please name de document first', 'Warning');
      return;
    }
    this.documents.push(newDocument);
    this.newDocument = { files: [] };
  }

  getFilePath(path) {
    return this.getPictureService.getPicturePath(path, this.docType);
  }

  setFilesToDocument(event, documentIndex) {
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      file.documentNo = documentIndex;
      this.documents[documentIndex].files.push(file);
    }
  }

  deleteFile(documentIndex, fileIndex) {
    if (this.documents[documentIndex].inDatabase && this.documents[documentIndex].files[fileIndex].id != null) {
      this.documents[documentIndex].files[fileIndex].deleted = true;
      this.deletedFiles.push(this.documents[documentIndex]);
    } else
      this.documents[documentIndex].files.splice(fileIndex, 1);
  }

  deleteDocument(index) {
    if (this.documents[index].inDatabase) {
      for (let i = 0; i < this.documents[index].files.length; i++) {
        this.documents[index].files[i].deleted = true;
      }
      this.deletedFiles.push(this.documents[index]);
      this.documents[index].deleted = true;
    } else
      this.documents.splice(index, 1);
  }

  deleteDocuments() {
    this.deletedFiles.forEach(doc => {
      for (let i = doc.files.length-1; i >= 0; i--) {
        let file = doc.files[i];
        if (file.deleted) {
          this.documentervice.deleteDocument(file.id).subscribe(res => {
          });
          doc.files.splice(i, 1);
          if (doc.files.length == 0) {
            let found = this.documents.indexOf(doc);
            if (found >= 0)
              this.documents.splice(found, 1);
          }
        }
      }
    });
    this.deletedFiles = [];
  }

  // targetModel explample: {DriverId:2} or {TractorId:1}
  uploadDocuments(targetModel, docType, id) {
    this.deleteDocuments();
    const documentFilesObservables = [];
    documentFilesObservables.push((this.documentervice.findDocTypeByName(('web' + docType).toUpperCase())) as any);
    for (let i = 0; i < this.documents.length; i++) {
      const document = this.documents[i];
      // File has already been updated or deleted
      if (document.inDatabase || document.deleted)
        continue;
      for (let n = 0; n < document.files.length; n++) {
        Object.defineProperty(document.files[n], 'name', {
          writable: true,
          value: id + "_" + i + "_" + n + "_" + document.files[n].name
        });
      }
      const filesUploadsObservable: any = this.fileUploadService.uploadFiles(document.files, docType);
      Object.assign(document, targetModel);
      documentFilesObservables.push(filesUploadsObservable);
    }
    let docTypeId = null;
    return observableForkJoin(documentFilesObservables).pipe(map(filesProcesed => {
      let filesProcessed: any = filesProcesed;
      docTypeId = filesProcessed[0].id;
      let offset = 0;
      for (let i = 0; i < this.documents.length; i++) {
        // File is updated or deleted
        if (this.documents[i].inDatabase || this.documents[i].deleted) {
          offset++;
          continue;
        }
        let fileProcessed = filesProcessed[i+1-offset];
        this.documents[i].files = fileProcessed;
        this.documents[i].files.forEach(file => {
          Object.defineProperty(file, 'parentId', { writable: true, value: id });
          Object.defineProperty(file, 'documentNo', { writable: true, value: i });
        })
        this.documents[i].DocTypeId = docTypeId;
      }

      return this.documentervice.createDocuments(this.documents.filter(doc => !doc.id && doc.files[0])).subscribe(result => {
        result.forEach(doc => {
          this.documents[doc.documentNo].files.find(document => doc.fileName == document.name).id = doc.id;
          this.documents[doc.documentNo].inDatabase = true;
        });
        return result;
      });
    }));
  }
}
