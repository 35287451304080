import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { Table } from "primeng/table";
import { LazyLoadEvent } from "primeng/api";
import { cloneDeep } from "lodash";
import { Subject, Subscription, of, map } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { authenticationService } from "@app/login/auth.service";
import { LocationsService } from "../shared/locations.service";
import { StatesServices } from "../../shared/services/states/states.service";
import { ToastrService } from "ngx-toastr";
import { PcMilerService } from "../../pcMiler/shared/pcMiler.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "app-location-list",
  templateUrl: "./location-list.component.html",
  styleUrls: ["./location-list.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationListComponent implements OnInit, OnDestroy {
  @ViewChild("table", { static: true }) table: Table;

  columns = [
    { field: "countryAbbreviation", header: "Country", width: "110px" },
    { field: "name", header: "Name", width: "350px" },
    { field: "address", header: "Address", width: "350px" },
    { field: "city", header: "City", width: "220px" },
    { field: "stateAbbreviation", header: "State", width: "110px" },
    { field: "zipcode", header: "Zip", width: "90px" },
    { field: "latitude", header: "Latitude", width: "120px" },
    { field: "longitude", header: "Longitude", width: "120px" },
    { field: "shipper", header: "Shipper", width: "90px" },
    { field: "consignee", header: "Consignee", width: "110px" },
    { field: "billTo", header: "Bill To", width: "80px" },
    { field: "confidenceLevel", header: "Confidence", width: "200px" },
    { field: "numMatches", header: "Matches", width: "100px" },
  ];

  rows = 100;
  locations = [];
  locationSnapshots = {};
  canEditLocations = false;
  totalRecords = 0;
  isLoading = true;

  locationForm: any = {};
  locationReference = null;
  exactLocationsData: any = [];

  filtersSubject = new Subject();
  filtersSubscription: Subscription;
  filters = {
    locId: "",
    countryAbbreviation: "",
    name: "",
    address: "",
    city: "",
    stateAbbreviation: "",
    zipcode: "",
    confidenceLevel: "",
    numMatches: "",
  };

  countries = [
    { name: "CANADA", abbreviation: "CA" },
    { name: "MEXICO", abbreviation: "MX" },
    { name: "US", abbreviation: "US" },
  ];

  constructor(
    private _AuthService: authenticationService,
    private _LocationService: LocationsService,
    private _StatesService: StatesServices,
    private _ToastrService: ToastrService,
    private _PCMilerService: PcMilerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.filtersSubscription = this.filtersSubject.pipe(debounceTime(500)).subscribe(() => {
      this.table.first = 0;
      this.table.firstChange.emit(this.table.first);
      this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe();
  }

  onLazyLoad(event: LazyLoadEvent) {
    let observable = this._LocationService.listAllLocationsFilters(
      this.filters,
      event.rows,
      event.first,
      event.sortField,
      event.sortOrder,
      false
    );

    this.isLoading = true;
    observable.subscribe((result: any) => {
      this.locations = result.Locations;
      this.totalRecords = result.totalRecords;
      this.isLoading = false;
      this.cdr.markForCheck();
    });
  }

  hasPermission(name: string) {
    return this._AuthService.hasPermission(Permissions[name]);
  }

  isFilterable(field: string) {
    return Object.keys(this.filters).includes(field);
  }

  isEditingLocation(location: any) {
    return !!this.locationSnapshots[location.id];
  }

  toggleLocationEditing() {
    this.canEditLocations = !this.canEditLocations;
    this.forceUpdateFrozenColumns();

    if (!this.canEditLocations) {
      Object.values(this.locationSnapshots).forEach((locationSnapshot: any) => {
        const location = this.locations.find((l) => l.id === locationSnapshot.id);
        if (location) Object.assign(location, locationSnapshot);
        delete this.locationSnapshots[location.id];
      });
    }
  }

  editLocation(location: any) {
    this.locationSnapshots[location.id] = cloneDeep(location);
  }

  cancelEditLocation(location: any) {
    Object.assign(location, this.locationSnapshots[location.id]);
    delete this.locationSnapshots[location.id];
  }

  createLocation() {
    this.validateNewLocation().subscribe((result: any) => {
      if (result) {
        this.openSelectExactAddressDialog(this.locationForm);
        $("#location-create-modal").modal("hide");
        this.cdr.markForCheck();
      }
    });
  }

  createLocationImmediate(location: any) {
    this._LocationService.createLocation(location).subscribe({
      next: (result: any) => {
        this.locations = [result, ...this.locations];
        this._ToastrService.success("Location created.", "Success", {
          closeButton: true,
          enableHtml: true,
        });

        this.locationReference = null;
        this.exactLocationsData = [];
        this.cdr.markForCheck();
      },
      error: (error) => {
        this._ToastrService.error(error, "Error");
        $("#location-create-modal").modal("show");
        this.exactLocationsData = [];
        this.cdr.markForCheck();
      },
    });
  }

  updateLocation(location: any) {
    const addressFields = [
      "address",
      "city",
      "state",
      "stateAbbreviation",
      "zipcode",
      "country",
      "countryAbbreviation",
    ];

    const locationSnapshot = this.locationSnapshots[location.id];
    const isAddressChanged = addressFields.some((p) => location[p] !== locationSnapshot[p]);

    if (!isAddressChanged) {
      this.updateLocationImmediate(location);
      return;
    }

    if (location.confidenceLevel !== "MANUAL") {
      this.openSelectExactAddressDialog(location);
      return;
    }

    if (
      confirm(
        "This address has a manual lat/long set. " +
          "Do you want to set the lat/long from PC Miler?"
      )
    ) {
      this.openSelectExactAddressDialog(location);
    } else {
      this.updateLocationImmediate(location);
    }
  }

  updateLocationImmediate(location: any) {
    this._LocationService.update(location).subscribe({
      next: () => {
        this._ToastrService.success("Location updated.", "Success");
        delete this.locationSnapshots[location.id];

        this.locationReference = null;
        this.exactLocationsData = [];
        this.cdr.markForCheck();
      },
      error: (error) => {
        this._ToastrService.error(error, "Error");
        this.exactLocationsData = [];
        this.cdr.markForCheck();
      },
    });
  }

  deleteLocation(location: any) {
    if (confirm("Are you sure you want to delete this location?")) {
      this._LocationService.delete(location).subscribe(() => {
        this.table.first = 0;
        this.table.firstChange.emit(this.table.first);
        this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
        this.cdr.markForCheck();
      });
    }
  }

  openLocationCreateModal() {
    this.locationForm = {};
    this.locationForm.countryAbbreviation = "US";
    this.locationForm.country = "US";
    this.locationForm.stateAbbreviation = "";
    this.locationForm.state = "";
    $("#location-create-modal").modal("show");
  }

  async openSelectExactAddressDialog(location: any) {
    this.locationReference = location;

    try {
      /*
      The dialog will open automatically when `exactLocationsData` is populated then
      later call `finishLocationCreateUpdate` below to finish the update process.
      */
      this.exactLocationsData = await this._PCMilerService.geoCodeLocation(location);
      this.cdr.markForCheck();
    } catch (error) {
      if (error.responseText === "Input postal code doesn't match input state") {
        this._ToastrService.error("ZIP and State don't match");
      } else {
        try {
          const errors = JSON.parse(error.responseText);
          setTimeout(() => $("#location-create-modal").modal("show"), 500);

          if (!errors.length) {
            this._ToastrService.error("An error occurred when trying to geocode the location.");
          } else if (errors[0]["Code"] === 25) {
            this._ToastrService.error("You must provide a City or ZIP.");
          } else {
            const message = errors[0]["Description"];
            this._ToastrService.error(message, "Error");
          }
        } catch (err) {
          this._ToastrService.error(error.responseText, "Error");
        }
      }
    }
  }

  finishLocationCreateUpdate(exactLocationData: any) {
    this.locationReference.address = exactLocationData.Address.StreetAddress;
    this.locationReference.city = exactLocationData.Address.City;
    this.locationReference.state = exactLocationData.Address.State;
    this.locationReference.stateAbbreviation = exactLocationData.Address.StateAbbreviation;
    this.locationReference.zipcode = exactLocationData.Address.Zip;
    this.locationReference.country = exactLocationData.Address.Country;
    this.locationReference.countryAbbreviation = exactLocationData.Address.CountryAbbreviation;
    this.locationReference.latitude = exactLocationData.Coords.Lat;
    this.locationReference.longitude = exactLocationData.Coords.Lon;
    this.locationReference.confidenceLevel = exactLocationData.ConfidenceLevel;
    this.locationReference.numMatches = exactLocationData.numMatches;
    this.locationReference.isUserChosen = true;

    const isCreating = this.locationReference === this.locationForm;
    if (isCreating) this.createLocationImmediate(this.locationReference);
    else this.updateLocationImmediate(this.locationReference);
  }

  /*
  The PrimeNG table component does not support dynamically adding/removing frozen columns;
  it updates the positions of the frozen cells when toggling whether the cells are frozen,
  but it does not update the positions of the frozen cells if they are dynamically added or
  removed. This method can be called to update the positions of the frozen cells manually.
  */
  forceUpdateFrozenColumns() {
    let tableElement = this.table.tableViewChild.nativeElement;
    let rowElements = tableElement.querySelectorAll("tr");

    /*
    The `setTimeout(..., 0);` call allows the DOM to update following the insertion/removal
    of toggled elements before trying to read their widths, otherwise the widths will be 0.
    */
    setTimeout(() => {
      for (let rowElement of rowElements) {
        let frozenCellElements = rowElement.querySelectorAll(".p-frozen-column");

        let left = 0;
        for (let cellElement of frozenCellElements) {
          cellElement.style.left = `${left}px`;
          left += cellElement.offsetWidth;
        }
      }
    }, 0);
  }

  getStatesByCountry(countryAbbreviation) {
    return this._StatesService.getTwoLettersStates(countryAbbreviation);
  }

  setLocationCountry(location: any) {
    const predicate = (c: any) => c.abbreviation === location.countryAbbreviation;
    location.country = this.countries.find(predicate).name;
    location.stateAbbreviation = "";
    location.state = "";
  }

  setLocationState(location: any) {
    const states = this.getStatesByCountry(location.countryAbbreviation);
    const predicate = (s: any) => s.abbreviation === location.stateAbbreviation;
    location.state = states.find(predicate).fullName;
  }

  genLocationId() {
    if (!this.locationForm.name) return;
    if (!this.locationForm.city) return;
    if (!this.locationForm.state) return;

    const nameSlice = this.locationForm.name.slice(0, 4);
    const citySlice = this.locationForm.city.slice(0, 2);
    const stateSlice = this.locationForm.state.slice(0, 2);
    this.locationForm.locId = nameSlice + citySlice + stateSlice;
  }

  validateNewLocation() {
    var warnings: string[] = [];
    var orderedWarnings: string[] = [];

    if (!this.locationForm.name) {
      warnings.push("Location Name is required");
    }
    if (!this.locationForm.city && !this.locationForm.zipcode) {
      warnings.push("You must provide a City or ZIP");
    }
    if (!this.locationForm.locId) {
      warnings.push("Location ID is required");

      orderedWarnings = warnings.reverse();

      if (warnings.length > 0) {
        for (const warning of orderedWarnings) {
          this._ToastrService.error(warning);
        }
        return of(false);
      }
      return of(true);
    } else {
      return this._LocationService.checkLocationIdExists(this.locationForm.locId).pipe(
        map((result: any) => {
          if (result) {
            warnings.push("Location ID already exists");
          }

          orderedWarnings = warnings.reverse();

          if (warnings.length > 0) {
            for (const warning of orderedWarnings) {
              this._ToastrService.error(warning);
            }
            return false;
          }
          return true;
        })
      );
    }
  }
}
