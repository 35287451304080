import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TrailerService } from "@app/trailer/shared/trailer.service";
import { ProductService } from "@app/product/shared/products.service";
import { LocationsService } from "@app/locations/shared/locations.service";
import conditionsList from "./conditions-list";
import { CustomChargeCondition } from "@app/shared/models/rates/CustomChargeViewModel.model";
@Component({
  selector: "app-condition-editor",
  templateUrl: "./condition-editor.component.html",
  styleUrls: ["./condition-editor.component.css"],
})
export class ConditionEditorComponent implements OnInit {
  @Input() isEditing: boolean;
  @Input() conditions: CustomChargeCondition[];
  @Output() conditionsChange = new EventEmitter();

  editingCondition: any;

  selectedOptions = [];

  selectedField: any = {
    type: null,
    options: [],
  };

  fields = conditionsList;

  constructor(
    private trailerService: TrailerService,
    private productService: ProductService,
    private locationService: LocationsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  resetInputs() {
    this.selectedOptions = [];
  }

  onDependingFieldChanged() {
    const { requestedOptions } = this.selectedField;

    // Get the options from a service, if necessary
    switch (requestedOptions) {
      case "equipmentCodes":
        this.trailerService.listTypes().subscribe((equipmentCodes: any) => {
          this.selectedField.options = equipmentCodes.map((equipmentCode) => ({
            itemName: equipmentCode.itemName,
          }));
          this.cdr.markForCheck();
        });

        break;
      case "products":
        this.productService.list().subscribe((products: any) => {
          this.selectedField.options = products.map((product: any) => ({
            itemName: product.name,
          }));
          this.cdr.markForCheck();
        });
        break;
      case "locations":
        this.locationService.listAllLocationIds().subscribe((locationIds: string[]) => {
          this.selectedField.options = locationIds.map((locId) => ({ itemName: locId }));
          this.cdr.markForCheck();
        });
        break;
    }

    this.resetInputs();
  }

  addTextValue() {
    this.selectedOptions.push({ itemName: "" });
  }

  removeTextValue(index: number) {
    this.selectedOptions.splice(index, 1);
  }

  createCondition() {
    // convert options to textMultiple
    let textMultiple: string[];
    if (this.selectedOptions.length > 0) {
      textMultiple = this.selectedOptions
        .map((option) => option.itemName)
        .filter((option) => option !== "");
    }

    return {
      ...this.selectedField,
      textMultiple,
    };
  }

  addCondition() {
    const condition = this.createCondition();

    this.conditions.push(condition);
    this.conditionsChange.emit(this.conditions);

    this.resetInputs();
  }

  shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }

  saveCondition() {
    // Find the condition that matches the one we're updating and change it
    const conditionIndex = this.conditions.findIndex((condition) =>
      this.shallowEqual(condition, this.editingCondition)
    );
    if (conditionIndex !== -1) {
      this.conditions[conditionIndex] = this.createCondition();
    }
    this.conditionsChange.emit(this.conditions);

    this.cancelEdit();
  }

  cancelEdit() {
    this.editingCondition = null;
    this.resetInputs();
  }

  // Conditions list
  editCondition(index: number) {
    this.resetInputs();

    let condition = this.conditions[index];

    this.editingCondition = condition;

    // update the selected field and initialize the dropdown
    this.selectedField = this.fields.find(
      (field) => field.field === condition.field && field.table === condition.table
    );
    this.onDependingFieldChanged();

    // convert values to options
    // const textMultiple = condition.textValue;
    // if (textMultiple && textMultiple.length > 0) {
    //   this.selectedOptions = textMultiple.map((text) => ({ itemName: text }));
    // }
  }

  deleteCondition(index: number) {
    this.conditions.splice(index, 1);
    this.conditionsChange.emit(this.conditions);
  }
}
