import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BatchGlobal } from "@app/shared/models/BatchGlobal.model";
import { DeductionService } from "../deduction.service";
import { ActivatedRoute } from "@angular/router";
import { withLatestFrom } from "rxjs/operators";

@Component({
  selector: "app-deficit",
  templateUrl: "./deficit.component.html",
  styleUrls: ["./deficit.component.css"],
})
export class DeficitComponent implements OnInit {
  @Output() deficitChanged = new EventEmitter();

  @Input("batchInfo") batchInfo: any = {};
  cols: any[];
  deficit: any = {};
  _deficitData: any;
  driverId: any;
  deficitHistory: any[];
  constructor(
    private toastr: ToastrService,
    private DeductionService: DeductionService,
    private _ActivatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.cols = [
      { field: "date", header: "Date" },
      { field: "description", header: "Description" },
      { field: "ChargeType", header: "Charge Type" },
      { field: "amount", header: "Amount" },
      { field: "afterBalance", header: "Balance" },
    ];

    this._ActivatedRoute.url
      .pipe(withLatestFrom(this._ActivatedRoute.paramMap, this._ActivatedRoute.queryParamMap))
      .subscribe(([url, params, queryParams]) => {
        this.driverId = params.get("driverId");

        this.DeductionService.getDeficitHistory(this.driverId).subscribe((data) => {
          this.deficitHistory = data;
        });
      });
  }

  depositChanged() {
    this.batchInfo.deficitAdd = Math.abs(this.batchInfo.deficitAdd);
    this.deficitChanged.emit();
  }

  withdrawChanged() {
    this.batchInfo.deficitSubtract = -Math.abs(this.batchInfo.deficitSubtract);
    this.deficitChanged.emit();
  }
}
