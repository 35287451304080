import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DriverRateViewModel } from "@app/shared/models/rates/DriverRateViewModel.model";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";

@Component({
  selector: "app-driver-rates",
  templateUrl: "./driver-rates.component.html",
  styleUrls: ["./driver-rates.component.css"],
})
export class DriverRatesComponent implements OnInit {
  @ViewChild("dtDriverList", { static: true }) table: Table;
  @Input() rates: DriverRateViewModel[];

  statusOptions: SelectItem[] = [
    { label: "ALL", value: null },
    { label: "ACTIVE", value: true },
    { label: "INACTIVE", value: false },
  ];

  // prettier-ignore
  columns: any = [
    { field: "name", header: "Rate Name", width: 300 },
    { field: "description", header: "Description", width: 300 },
    { field: "rateBy", header: "Rate By", width: 200 },
    { field: "priority", header: "Priority", width: 100 },
    { field: "validFromUtc", header: "Start Date", width: 130 },
    { field: "validToUtc", header: "End Date", width: 130 },
    { field: "isValid", header: "Status", width: 100, type: "boolean" },
  ];
  filterElements: any = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    const filter = sessionStorage.getItem("driver-rates-list");
    if (!filter) this.table.filter(this.statusOptions[1].value, "isValid", "equals");
  }

  editRate(rate: DriverRateViewModel) {
    this.router.navigate([`/rates/driver/${rate.id}`]);
  }

  createRate() {
    this.router.navigate([`/createrate/driver`]);
  }
}
