<div class="form-group">
  <label for="rolName">Role Name:</label>
  <input
    type="text"
    class="form-control"
    id="name"
    [(ngModel)]="role.rolName"
  />
</div>
<div class="form-group">
  <label for="desc">Description:</label>
  <input
    type="text"
    class="form-control"
    id="roleDesc"
    [(ngModel)]="role.desc"
  />
</div>
<div class="form-group">
  <label for="priority">Priority:</label>
  <input
    type="number"
    class="form-control"
    id="rolePriority"
    [(ngModel)]="role.priorty"
  />
</div>
<p-footer>
  <div class="footer">
    <button
      type="submit"
      class="btn btn-warning createBtn"
      (click)="createNewRole(role)"
    >
      Save New Role
    </button>
    <button
      type="button"
      pButton
      icon="pi pi-close"
      (click)="closeModalRole()"
      label="Cancel"
      class="ui-button-secondary"
    ></button>
  </div>
</p-footer>
