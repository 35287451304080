import { Component, OnInit } from "@angular/core";
import { clientService } from "../shared/client.service";
import { Router } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { ClientViewModel } from "@app/shared/models/clients/ClientViewModel.model";

@Component({
  selector: "app-clientlist",
  templateUrl: "./clientlist.component.html",
  styleUrls: ["./clientlist.component.css"],
})
export class ClientlistComponent implements OnInit {
  errorMsg: any;
  clientList: any = [];
  colsClients: any = [];
  showDeleted = false;
  filteredClients: any = [];

  constructor(
    private _clientList: clientService,
    private router: Router,
    private _AuthService: authenticationService
  ) {}

  ngOnInit() {
    this.colsClients = [
      { field: "customerNo", header: "Client Id" },
      { field: "companyName", header: "Company Name" },
      { field: "phone", header: "Contact #" },
      { field: "email", header: "Email" },
      { field: "city", header: "City" },
    ];

    this._clientList.getClientList(false, true).subscribe(
      (resClientListData) => {
        this.clientList = resClientListData;
        this.filterClients();
      },
      (resClientListError) => {
        this.errorMsg = resClientListError;
      }
    );
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  viewDetail(client: ClientViewModel) {
    this.router.navigate(["/clientinfo/", client.id]);
  }

  filterClients() {
    if (this.showDeleted) this.filteredClients = this.clientList;
    else this.filteredClients = this.clientList.filter((client) => client.dateDeletedUtc === null);
  }
}
