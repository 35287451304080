import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "../../../shared/services/http/http-client.service";

@Injectable()
export class RoleService {
  constructor(private router: Router, private _http: HttpClient) {}

  createNewRole(role) {
    return this._http.post("api/role/create", { role: role }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error cant create role")
      )
    );
  }

  getAllRoleRules() {
    return this._http.get("api/role/getRules").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  findAndFilter() {
    return this._http.post("api/role/findAndFilter", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getRoleInfo(role) {
    return this._http.get("api/role/find/" + role.id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server role error"))
    );
  }

  delete(roleId) {
    return this._http.delete("api/role/delete/" + roleId.uuid).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Error deleting role"))
    );
  }

  updateRole(role) {
    return this._http.patch("api/role/update", { role }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateRoleRules(rolePermissions) {
    return this._http.patch("api/role/updateRules", rolePermissions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
