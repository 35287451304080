import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import {RoleService} from '../shared/role.service';
import {ToastrService} from 'ngx-toastr';
import {ListRolesComponent} from '../listroles/listroles.component';


@Component({
  selector: 'app-addrole',
  templateUrl: './addrole.component.html',
  styleUrls: ['./addrole.component.css']
})
export class AddRoleComponent implements OnInit {

  @ViewChild(ListRolesComponent) roleModal: ListRolesComponent;
  role: any = {}
  isProcessing = false;

  constructor(private confirmationService: ConfirmationService, private _addRoleService: RoleService, private toastr: ToastrService ) { }

  ngOnInit() {

  }

  sendNewRole(role){
    return this._addRoleService.createNewRole(role);
  }

  createNewRole(newRole) {
      this.sendNewRole(newRole).subscribe(roleCreated => {
        this.closeModalRole();
      }, error => {
        this.toastr.warning(error, 'Alert!', {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
        this.isProcessing = false;
      });
    };

  closeModalRole(){
    const closeModal = document.getElementsByClassName('pi-times')[0] as HTMLElement;
    closeModal.click();
  }

  }
