<div class="container">
  <div class="col-md-12">
    <legend>Teams</legend>
    <div class="btnRig">
      <button
        id="edit"
        name="edit"
        class="btn createBtn"
        routerLink="/teams/new"
        *ngIf="hasPermission('DriversChange')"
      >
        Create Team
      </button>
    </div>
    <div style="display: inline-block">
      <p-table #teamsTable [columns]="cols" [value]="teams" [autoLayout]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns">
              <input
                type="text"
                style="width: 100%"
                (input)="$any(teamsTable).filter($event.target.value, col.field, 'contains')"
                [value]="$any(teamsTable).filters[col.field]?.value"
              />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-team>
          <tr class="clickable-row" (dblclick)="viewDetail(team)">
            <td>{{ team.name }}</td>
            <td>{{ team.driversList }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
