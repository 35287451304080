import { throwError as observableThrowError } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { HttpClient } from "../../shared/services/http/http-client.service";
import { Lessor } from "@app/shared/models/Lessor.model";

@Injectable()
export class LessorService {

  constructor(private router: Router, private _http: HttpClient) {
  }

  getLessorList(validOnly = true) {
    return this._http
      .post("api/lessor/list", { validOnly: validOnly})
      .pipe(
        map((res) => {
          let client = res as any;
          client.map((client) => (client.itemName = client.companyName));
          return client;
        }),
        catchError((error: any) =>
          observableThrowError(error.message || "Server error")
        )
      );
  }

  getActiveLessorList(active: boolean) {
    return this._http
      .post("api/lessor/listActive", {active})
      .pipe(
        map((res) => {
          let client = res as any;
          client.map((client) => (client.itemName = client.companyName));
          return client;
        }),
        catchError((error: any) =>
          observableThrowError(error.message || "Server error")
        )
      );
  }

  getLessorInfo(uuid: string) {
    return this._http.get("api/lessor/find/" + uuid).pipe(
      map((res: Lessor) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error")
      )
    );
  }

  getLessorByName(name: string) {
    return this._http.post("api/lessor/findByName", { name }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error")
      )
    );
  }

  getLessorByLessorId(id: string) {
    return this._http.post("api/lessor/findByLessorId", { id }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error")
      )
    );
  }

  createAddLessor(newLessor: Lessor) {
    newLessor.id = null;
    return this._http
      .post("api/lessor/create", { lessor: newLessor })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) =>
          observableThrowError(error.message || "Server error")
        )
      );
  }

  updateLessor(lessor: Lessor) {
    return this._http.patch("api/lessor/update", { lessor }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error")
      )
    );
  }

  lessorListDropdown() {
    return this._http.get("api/lessor/listDropDown").pipe(
      map((res: any) => {
        let lessors = res.map((lessor: any) => (lessor.itemName = lessor.name));
        return lessors;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error")
      )
    );
  }
}
