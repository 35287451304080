import { throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "../../shared/services/http/http-client.service";

@Injectable()
export class DriverHireDateService {
  url: String;
  constructor(private _http: HttpClient) {}

  createHireDates(driverId: String, driverHireDates: any[]) {
    return this._http.post("api/driverHireDate/create", { driverId, driverHireDates }).pipe(
      map((res) => res),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error driver hire dates cant be created")
      )
    );
  }
}
