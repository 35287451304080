import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { HttpClient } from "../shared/services/http/http-client.service";

@Injectable()
export class scheduleService {
  constructor(private router: Router, private _http: HttpClient) {}

  gettrailerList() {
    return this._http.get("api/schedule/list").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server schedule error"))
    );
  }
}
