import { Component, OnInit, ViewChild } from "@angular/core";
import { PeoplenetTestService } from "./shared/peoplenet-test.service";
import { Table } from "primeng/table";
@Component({
  selector: "app-peoplenet-test",
  templateUrl: "./peoplenet-test.component.html",
  styleUrls: ["./peoplenet-test.component.css"],
})
export class PeoplenetTestComponent implements OnInit {
  constructor(private peoplenetTestService: PeoplenetTestService) {}
  cols: any[];
  truckList: any = [];
  truckListFilter: any = [];
  @ViewChild("dtPeopleNet", { static: true }) dataTable: Table;

  ngOnInit() {
    this.dataTable.filterDelay = 0;
    this.dataTable.filterTimeout = 0;
    this.cols = [
      { field: "tractorNumber", header: "Truck Number", width: 100 },
      { field: "dateTime", header: "Date", width: 185 },
      { field: "speed", header: "Truck Speed", width: 80 },
      { field: "heading", header: "Truck Heading", width: 150 },
      { field: "latitude", header: "Latitude", width: 150 },
      { field: "longitude", header: "Longitude", width: 150 },
      { field: "location", header: "Location", width: 545 },
      { field: "driver1Name", header: "Driver Name", width: 225 },
      { field: "dutyStatus1", header: "Duty Status", width: 225 },
    ];
    this.listTrucksLocations();
  }
  listTrucksLocations() {
    this.peoplenetTestService.listTractorsLocations().subscribe((res: any) => {
      const parsedRes = res.map((truck) => ({
        ...truck,
        dateTime: truck.dateTime ? new Date(truck.dateTime).toLocaleString() : null,
      }));

      this.truckList = parsedRes;
      this.truckListFilter = parsedRes;
    });
  }
}
