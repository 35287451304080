<button
  pButton
  pRipple
  label="Adjust All by %"
  icon="pi pi-pencil"
  class="p-button-secondary"
  data-toggle="modal"
  data-target="#adjustByPercentModal"
></button>

<div
  class="modal fade"
  id="adjustByPercentModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="adjustByPercentModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Adjust all prices by</h5>
        <p-inputNumber [(ngModel)]="percentage" mode="decimal" suffix="%" [minFractionDigits]="2" [maxFractionDigits]="2" (onFocus)="$event.target.select()"></p-inputNumber>
      </div>
      <hr />
      <div class="modal-body">
        <div class="radiobutton-container">
          <div class="p-field-radiobutton">
            <p-radioButton
              name="roundingOption"
              value="Round Up"
              [(ngModel)]="roundingOption"
              inputId="roundingOption1"
            ></p-radioButton>
            <label for="roundingOption1">Round up to nearest dollar</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="roundingOption"
              value="Round"
              [(ngModel)]="roundingOption"
              inputId="roundingOption2"
            ></p-radioButton>
            <label for="roundingOption2">Round to nearest dollar</label>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              name="roundingOption"
              value="Round To Penny"
              [(ngModel)]="roundingOption"
              inputId="roundingOption3"
            ></p-radioButton>
            <label for="roundingOption3">Round to nearest penny</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          #closebutton
        >
          Cancel
        </button>
        <button class="btn btn-primary" (click)="adjustByPercent()">OK</button>
      </div>
    </div>
  </div>
</div>
