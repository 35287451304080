import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { authenticationService } from "@app/login/auth.service";
import { StatesServices } from "@app/shared/services/states/states.service";
import { ToastrService } from "ngx-toastr";
import { Permissions } from "@models/permissions.enum";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { FuelService } from "@app/fuel/fuel.service";
import { GasRegion } from "@app/shared/models/fuel/GasRegion.model";
import { BoundedTableComponent } from "../../bounded-table/bounded-table.component";
import { FuelSurchargeViewModel } from "@app/shared/models/rates/FuelSurchargeViewModel.model";

@Component({
  selector: "app-edit-fuel-surcharges",
  templateUrl: "./edit-fuel-surcharges.component.html",
  styleUrls: ["./edit-fuel-surcharges.component.scss"],
})
export class EditFuelSurchargesComponent implements OnInit {
  @ViewChild("clientRatesTable", { static: true }) clientRatesTable: BoundedTableComponent;
  @ViewChild("driverRatesTable", { static: true }) driverRatesTable: BoundedTableComponent;

  @Input() rate: FuelSurchargeViewModel;
  @Input() rateId: number;
  @Input() isEditing: boolean;
  @Input() isCreating: boolean;
  // regions
  @Input() fuelRegions: GasRegion[] = [];
  @Output() fuelRegionsChange = new EventEmitter();
  @Input() selectedRegions: GasRegion[] = [];
  @Output() selectedRegionsChange = new EventEmitter();

  // dropdown settings
  originSettings: any;
  destSettings: any;

  // table columns
  columns: any[];

  // rate by
  rateBy: string = "PRICE";
  ratingType: string = "WEIGHT";

  clientRates: any[] = [];
  driverRates: any[] = [];

  decimals: number = 2;
  clientPriceIncrement: number | string;
  clientPriceInterval: number | string;
  driverPriceIncrement: number | string;
  driverPriceInterval: number | string;

  constructor(
    private toastr: ToastrService,
    private fuelService: FuelService,
    private authService: authenticationService
  ) {}

  ngOnInit(): void {
    this.columns = [
      {
        field: "fuelSurchargePerMile",
        name: "FUEL SURCHARGE PER MILE ($)",
        type: "dollar",
      },
    ];

    this.fuelService.getRegions().subscribe((regions) => {
      // this.fuelRegions = regions;
      // filter out all of the candian cities for now
      const canadaNational = regions.find((region) => region.name === "CA - NATIONAL");
      this.fuelRegions = regions.filter((region) => region.gasRegionId !== canadaNational.id);
      this.fuelRegionsChange.emit(this.fuelRegions);
      if (!this.isCreating) {
        this.loadRates(this.rate);
      }
    });
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  rateByChanged(type: string): void {
    // update column header
    this.columns = [
      {
        field: "fuelSurchargePerMile",
        name: "Fuel Surcharge Per Mile " + (type === "PRICE" ? "($)" : "(0.02 = 2%)"),
        type: type === "PRICE" ? "dollar" : "percentage",
      },
    ];
  }

  validate = () => {
    const warnings = [];
    if (!this.rate.name) warnings.push("Rate Name is required");
    if (!this.rate.chargeTypeId) warnings.push("Charge Type is required");
    if (this.rateBy === "PERCENTAGE") {
      for (const rate of this.clientRates) {
        if (rate.values.fuelSurchargePerMile >= 1) {
          warnings.push("Percentages must be in the form 0.02 = 2%");
        }
      }
    }
    if (!this.clientRates.length) warnings.push("Rates are required");
    if (!this.clientRatesTable.validateRanges())
      warnings.push(`Price Ranges are invalid - each row must differ by 0.001`);
    if (!this.rate.isStraightPassThrough) {
      if (!this.driverRates.length) warnings.push("Driver Rates are required");
      if (!this.driverRatesTable.validateRanges())
        warnings.push(`Driver Price Ranges are invalid - each row must differ by 0.001`);
    }

    if (warnings.length > 0) {
      for (const warning of warnings) {
        this.toastr.warning(warning);
      }
      return false;
    }
    return true;
  };

  filterObject(object: any, names: string[], defaultValue: any) {
    const oldValues = object;
    let newValues = {};
    for (let name of names) {
      newValues[name] = oldValues[name] || defaultValue;
    }
    return newValues;
  }

  roundToDecimals(value: any) {
    return Number(value).toFixed(this.decimals);
  }

  mapRatesToTable(rates: any[]) {
    let rows = [];
    let bounds = {};

    // create the row objects
    for (let item of rates) {
      const { floor, roof, kind, price } = item;
      const boundsKey = `${floor}-${roof}`;

      if (!bounds[boundsKey]) {
        bounds[boundsKey] = { floor, roof, values: {} };
      }

      bounds[boundsKey].values["fuelSurchargePerMile"] = price;
    }

    rows = Object.values(bounds);
    rows = rows.sort((row1, row2) => row1.floor - row2.floor);

    return rows;
  }

  loadRates(surcharge: FuelSurchargeViewModel) {
    this.rateBy = surcharge.rateBy;
    this.rateByChanged(this.rateBy);
    this.ratingType = surcharge.ratingType;

    this.decimals = surcharge.decimals;
    this.clientPriceIncrement = surcharge.clientPriceIncrement;
    this.clientPriceInterval = surcharge.clientPriceInterval;
    this.driverPriceIncrement = surcharge.driverPriceIncrement;
    this.driverPriceInterval = surcharge.driverPriceInterval;

    if (surcharge.gasRegions) {
      const gasRegionIds = surcharge.gasRegions.map((region) => region.id);

      this.selectedRegions = this.fuelRegions.filter((region) => gasRegionIds.includes(region.id));
      this.selectedRegionsChange.emit(this.selectedRegions);
    }

    // decimals need to be initialized before doing this mapping
    this.clientRates = this.mapRatesToTable(
      surcharge.fuelSurchargeRates.filter((item) => item.kind === "CLIENT")
    );
    this.driverRates = this.mapRatesToTable(
      surcharge.fuelSurchargeRates.filter((item) => item.kind === "DRIVER")
    );
  }

  //For parsing this page's data into a request object
  buildRatesObject = () => {
    const buildRatesFromRows = (rows: any[], type: any) => {
      // map from the rates table to individual rates items
      let rates = [];
      const kind = type;
      for (const row of rows) {
        const { floor, roof, values } = row;
        for (const [name, price] of Object.entries(values)) {
          const rate = { floor, roof, price, kind };
          rates.push(rate);
        }
      }
      return rates;
    };

    const clientFuelSurchargeRates = buildRatesFromRows(this.clientRates, "CLIENT");
    const driverFuelSurchargeRates = buildRatesFromRows(this.driverRates, "DRIVER");
    const fuelSurchargeRates = clientFuelSurchargeRates.concat(driverFuelSurchargeRates);

    const rateObject = {
      ...this.rate,
      clients: this.rate.clients.map((client) => ({ id: client.id })),
      chargeTypeId: this.rate.chargeTypeId,

      rateBy: this.rateBy.toUpperCase(),
      ratingType: this.ratingType.toUpperCase(),

      clientPriceIncrement: this.clientPriceIncrement,
      clientPriceInterval: this.clientPriceInterval,
      driverPriceIncrement: this.driverPriceIncrement,
      driverPriceInterval: this.driverPriceInterval,
      decimals: this.decimals,

      gasRegions: this.selectedRegions.map((region) => ({ id: region.id })),

      fuelSurchargeRates,
    };

    return rateObject;
  };

  exportExcel(rows: any[], cols: any[], title: string) {
    if (rows === undefined || cols === undefined) {
      this.toastr.warning("Table is empty.", "Unable to Export", {
        closeButton: true,
        enableHtml: true,
      });
      return;
    }

    //Get readable column headers
    const exportRows = [];
    rows.forEach((row) => {
      let thisExportRow = {} as any;
      if (row.floor != undefined && row.roof != undefined) {
        thisExportRow.PRICE = row.floor + "-" + row.roof;
      }

      if (row.values != undefined) {
        cols.forEach((col) => {
          thisExportRow[col.name.toUpperCase()] = row.values[col.field];
        });
      } else {
        thisExportRow = row;
      }

      exportRows.push(thisExportRow);
    });

    if (exportRows.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(exportRows);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveAsExcelFile(excelBuffer, title);
      });
    } else {
      this.toastr.warning("Table is empty.", "Unable to Export", {
        closeButton: true,
        enableHtml: true,
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
  }

  importExcel(event: any, buildFunction, cols: any[], table: any, fileUpload: any) {
    let reader = new FileReader();
    reader.readAsArrayBuffer(event.files[0]);
    reader.onload = (e) => {
      let data = new Uint8Array(reader.result as ArrayBuffer);
      let workbook = XLSX.read(data, { type: "array" });
      let sheet = workbook.Sheets[workbook.SheetNames[0]];
      let result = XLSX.utils.sheet_to_json(sheet);

      let exit = false;

      //Check that columns match
      cols.forEach((col) => {
        if (exit) {
          return;
        }
        result.forEach((resultRow: any[]) => {
          if (exit) {
            return;
          }
          if (resultRow[col.name] === undefined) {
            this.toastr.warning("Columns do not match this table.", "Unable to Import", {
              closeButton: true,
              enableHtml: true,
            });
            exit = true;
            return;
          }
        });
      });
      if (exit) {
        fileUpload.clear();
        return;
      }

      //Add to table
      try {
        let formattedResult = buildFunction(result);
        table.rows = this.mapRatesToTable(formattedResult);
        table.updatedRows();

        this.toastr.success("Columns successfully imported.", "Success", {
          closeButton: true,
          enableHtml: true,
        });
      } catch (error) {
        this.toastr.warning("Columns do not match this table.", "Unable to Import", {
          closeButton: true,
          enableHtml: true,
        });
      }

      fileUpload.clear();
    };
  }

  loadBoundedExcelData(data: any[]) {
    let rates = [];
    for (const row of data) {
      const price = row["PRICE"].split("-");
      const [floor, roof] = price;
      delete row["PRICE"];

      for (const [kind, price] of Object.entries(row)) {
        const rate = { floor, roof, kind, price };
        rates.push(rate);
      }
    }

    return rates;
  }
}
