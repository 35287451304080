import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { RatesService } from "@app/rates/shared/rates.service";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: "app-rates-header",
  templateUrl: "./rates-header.component.html",
  styleUrls: ["./rates-header.component.css"],
})
export class RatesHeaderComponent implements OnInit {
  @Input() rateType: string;
  @Input() rateId: number;
  @Input() title: string;
  @Input() isEditing: boolean;
  @Output() isEditingChange = new EventEmitter();
  @Input() isCreating: boolean;
  @Input() canChange: boolean;
  @Input() buildRatesObject: () => any;
  @Input() validate: () => any = () => true;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private ratesService: RatesService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {}

  toggleEditing() {
    this.isEditing = !this.isEditing;
    this.isEditingChange.emit(this.isEditing);
  }

  createNewRate() {
    if (!this.validate()) return;
    const ratesObject = this.buildRatesObject();
    this.ratesService.createRule(ratesObject, this.rateType).subscribe(
      (res: any) => {
        this.toastr.success("Rate Created", "Success!");
        this.router.navigate([
          `/rates/${this.rateType.toLowerCase()}/${res.id}`,
        ]);
      },
      (error) => {
        this.toastr.warning("error", "Alert!", {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
      }
    );
  }

  updateRate() {
    if (!this.validate()) return;
    const ratesObject = this.buildRatesObject();

    this.ratesService
      .updateRule(this.rateId, ratesObject, this.rateType)
      .subscribe(
        (res) => {
          this.toastr.success("Rate Updated", "Success!");
          this.toggleEditing();
          setTimeout(() => {
            this.router.navigate(["/rates"]);
          }, 200);
        },
        (error) => {
          this.toastr.warning("error", "Alert!", {
            closeButton: true,
            enableHtml: true,
          });
          console.dir(error);
        }
      );
  }

  copyRate() {
    this.confirmationService.confirm({
      message: "Are you sure you want to copy this rate?",
      accept: () => {
        const ratesObject = this.buildRatesObject();
        ratesObject.name = "Copy of " + ratesObject.name;
        ratesObject.billTos = [];
        delete ratesObject.id;
        this.sendCreatedRateToService(ratesObject);
      },
      reject: () => {},
    });
  }

  sendCreatedRateToService(ratesObject: any) {
    this.ratesService.createRule(ratesObject, this.rateType).subscribe(
      (res: any) => {
        this.toggleEditing();
        this.toastr.success("Rate Created", "Success!");
        this.router.navigate([
          `/rates/${this.rateType.toLowerCase()}/${res.id}`,
        ]);
      },
      (error) => {
        this.toastr.warning("error", "Alert!", {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
      }
    );
  }

  deleteRate() {
    this.ratesService.deleteRule(this.rateId, this.rateType).subscribe(
      (res) => {
        this.toggleEditing();
        this.toastr.success("Rate Deleted", "Success!");
        setTimeout(() => {
          this.router.navigate(["/rates"]);
        }, 200);
      },
      (error) => {
        this.toastr.warning("error", "Alert!", {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
      }
    );
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: "Do you really want to delete this rate?",
      accept: () => {
        this.deleteRate();
      },
      reject: () => {},
    });
  }
}
