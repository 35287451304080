<div class="container">
  <div class="col-md-12">
    <legend>Lessor List</legend>
    <div class="btnRig">
      <button
        id="edit"
        name="edit"
        class="btn createBtn"
        routerLink="/lessorinfo/new"
        *ngIf="hasPermission('LessorChange')"
      >
        Add New Lessor
      </button>
    </div>
    <div style="display: inline-block">
      <p-table #dt [columns]="cols" [value]="lessorList" [autoLayout]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
              <input
                *ngIf="col.type !== 'boolean'"
                type="text"
                style="width: 100%"
                (input)="$any(dt).filter($event.target.value, col.field, 'contains')"
                [value]="$any(dt).filters[col.field]?.value"
              />
              <p-dropdown aria-label="status"
                *ngIf="col.type === 'boolean'"
                [options]="statusOptions"
                (onChange)="$any(dt).filter($event.value, col.field, 'equals')"
                [ngModel]="$any(dt).filters[col.field]?.value"
                appendTo="body"
                tabbableDropdown
              >
              </p-dropdown>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-lessor>
          <tr class="clickable-row" (dblclick)="viewDetail(lessor)">
            <td>{{ lessor.lessorId }}</td>
            <td>{{ lessor.name }}</td>
            <td>{{ lessor.phone }}</td>
            <td>{{ lessor.email }}</td>
            <td>{{ lessor.active ? "Active" : "Inactive" }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
