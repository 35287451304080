<div>
  <button
    pButton
    type="button"
    icon="pi pi-plus"
    class="p-button-success"
    label="Add Driver"
    *ngIf="isEditing"
    (click)="addDriver()"
    style="margin-bottom: 0.5rem"
  ></button>
</div>
<div *ngIf="isEditing">
  <div *ngFor="let driver of drivers" class="flex">
    <p-dropdown
      [style]="{ width: '200px' }"
      [options]="allDrivers"
      [(ngModel)]="driver.id"
      [autoDisplayFirst]="false"
      class="mr-2"
      tabbableDropdown
    ></p-dropdown>
    <label class="mr-2">Valid From</label>
    <input
      id="validFrom"
      name="validFrom"
      type="text"
      [inputMask]="dateInputMask"
      placeholder="mm/dd/yyyy"
      autocomplete="off"
      class="mr-2"
      [(ngModel)]="driver.validFromUtc"
    />
    <label class="mr-2">To</label>
    <input
      id="validTo"
      name="validTo"
      type="text"
      [inputMask]="dateInputMask"
      placeholder="mm/dd/yyyy"
      autocomplete="off"
      class="mr-2"
      [(ngModel)]="driver.validToUtc"
    />
    <button
      pButton
      type="button"
      icon="pi pi-trash"
      class="p-button-danger"
      (click)="removeDriver(driver)"
    ></button>
  </div>
</div>

<div *ngIf="!isEditing">
  <div *ngFor="let driver of drivers" class="mr-2">
    <label>{{ driver.name }} </label>
    &nbsp;-
    <label>Valid From {{ driver.validFromUtc | date : "MM/dd/yyyy" }}</label>
    <label *ngIf="driver.validTo"> - {{ driver.validToUtc | date : "MM/dd/yyyy" }}</label>
  </div>
</div>
