<legend style="display: flex; margin-bottom: 0">
  {{ title }}
  <div style="margin-left: auto; margin-bottom: 10px">
    <button
      type="button"
      class="btn"
      style="margin-right: 1rem"
      routerLink="/rates"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="canChange && !isCreating && !isEditing"
      (click)="toggleEditing()"
    >
      Edit
    </button>
    <button
      type="button"
      class="btn btn-danger"
      style="margin-right: 1rem"
      *ngIf="isEditing && !isCreating"
      (click)="confirmDelete()"
    >
      Delete
    </button>
    <button
      type="button"
      class="btn btn-primary"
      style="margin-right: 1rem"
      (click)="copyRate()"
      *ngIf="canChange && isEditing && !isCreating"
    >
      <i class="fa fa-copy"></i>
      Copy Rate
    </button>
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="isEditing && !isCreating"
      (click)="updateRate()"
    >
      Update
    </button>
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="isCreating && isEditing"
      (click)="createNewRate()"
    >
      Create
    </button>
  </div>
</legend>
<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '50vw' }"
></p-confirmDialog>
