import { Component, OnInit, HostListener } from "@angular/core";
import { authenticationService } from "./login/auth.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "app";
  isLogin: boolean;

  userActivity;
  userInactive: Subject<any> = new Subject();
  userInactiveWarning: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private authService: authenticationService,
    private toastr: ToastrService
  ) {
    this.setTimeout();
    this.userInactiveWarning.subscribe(
      () => (this.userActivity = setTimeout(() => this.displayWarn()))
    );
    this.userInactive.subscribe(() => this.logout());
  }

  displayWarn() {
    if (this.router.url != "/login") {
      this.toastr.warning("You will soon be logged out for inactivity.", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
      this.userActivity = setTimeout(
        () => this.userInactive.next(undefined),
        1 * 60 * 1000 // 1 minute
      );
    }
  }

  logout() {
    this.toastr.warning("Logged out due to inactivity.", "Alert!", {
      closeButton: true,
      enableHtml: true,
    });
    this.authService.logout();
  }

  setTimeout() {
    if (this.router.url != "/login") {
      this.userActivity = setTimeout(
        () => this.userInactiveWarning.next(undefined),
        5 * 1000 * 60 * 60 // 5 hours
      );
    }
  }

  @HostListener("window:mousemove") refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
