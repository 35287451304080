import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { HttpClient } from "../../shared/services/http/http-client.service";
import { ChargeType } from "@app/shared/models/ChargeType.model";

@Injectable({
  providedIn: "root",
})
export class ChargetypesService {
  constructor(private _http: HttpClient) {}

  getChargeTypes(): Observable<any> {
    return this._http.get("api/chargeType").pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  listDropDown() {
    return this._http.get("api/chargeType/listDropdown").pipe(
      map((res: any) => {
        return res.map((chargeType) => ({
          label: chargeType.name,
          value: chargeType,
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  listDropDownJustId() {
    return this._http.get("api/chargeType/listDropdown").pipe(
      map((res: any) => {
        return res.map((chargeType) => ({
          label: chargeType.name,
          value: chargeType.id,
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  listAccessorialsDropDown() {
    return this._http.get("api/chargeType/listAccessorialsDropdown").pipe(
      map((res: any) => {
        return res.map((chargeType) => ({
          label: chargeType.name,
          value: chargeType,
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  addChargeType(chargeType: ChargeType): Observable<any> {
    return this._http.post("api/chargeType", chargeType).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  editChargeType(id: string | number, chargeType: ChargeType): Observable<any> {
    return this._http.patch("api/chargeType/update/" + id, chargeType).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  restoreChargeType(id: string | number, chargeType: ChargeType): Observable<any> {
    return this._http.patch("api/chargeType/restore/" + id, chargeType).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteChargeType(id: string | number): Observable<any> {
    return this._http.delete("api/chargeType/" + id).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getGlobalChargeTypes(): Observable<any> {
    return this._http.get("api/chargeType/getGlobalChargeTypes").pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateGlobalChargeType(type: string, chargeTypeId: string): Observable<any> {
    return this._http.post("api/chargeType/updateGlobalChargeType/" + type, { chargeTypeId }).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
