<div class="col-md-12 no-padding">
  <legend>Locations</legend>

  <div class="d-flex btnRig">
    @if (hasPermission('LocationsChange')) {
    <button (click)="openLocationCreateModal()" class="btn btn-primary">Add New Location</button>
    <button (click)="toggleLocationEditing()" class="btn">
      @if (!canEditLocations) { Enable Editing } @else { Disable Editing }
    </button>
    }
  </div>

  <div class="table-wrapper">
    <p-table
      #table
      [columns]="columns"
      [value]="locations"
      [totalRecords]="totalRecords"
      [rows]="rows"
      [paginator]="true"
      [loading]="isLoading"
      [scrollable]="true"
      scrollHeight="600px"
      [lazy]="true"
      (onLazyLoad)="onLazyLoad($event)"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th pFrozenColumn style="width: 170px" [hidden]="!canEditLocations"></th>
          <th pFrozenColumn style="width: 150px; text-align: left">ID</th>
          @for (column of columns; track column.field) {
          <th
            [pSortableColumn]="column.field"
            pFrozenColumn
            [frozen]="column.frozen"
            [style.width]="column.width"
          >
            {{ column.header }}
          </th>
          }
        </tr>
        <tr>
          <th pFrozenColumn [hidden]="!canEditLocations"></th>
          <th pFrozenColumn>
            <input type="text" [(ngModel)]="filters.locId" (input)="filtersSubject.next($event)" />
          </th>
          @for (column of columns; track column.field) {
          <th>
            @if (isFilterable(column.field)) {
            <input
              type="text"
              [(ngModel)]="filters[column.field]"
              (input)="filtersSubject.next($event)"
            />
            }
          </th>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-location>
        <tr>
          <td pFrozenColumn [hidden]="!canEditLocations">
            @if (!isEditingLocation(location)) {
            <button
              (click)="editLocation(location)"
              title="Edit"
              type="button"
              [attr.aria-label]="location.locId"
              class="btn"
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
            } @if (isEditingLocation(location)) {
            <button (click)="cancelEditLocation(location)" title="Cancel" type="button" class="btn">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
            } @if (isEditingLocation(location)) {
            <button
              (click)="deleteLocation(location)"
              title="Delete"
              type="button"
              class="btn btn-danger"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
            } @if (isEditingLocation(location)) {
            <button
              (click)="updateLocation(location)"
              title="Save"
              type="button"
              class="btn btn-primary"
            >
              <i class="fa fa-save" aria-hidden="true"></i>
            </button>
            }
          </td>
          <td pFrozenColumn>
            @if (!isEditingLocation(location)) {
            {{ location.locId }}
            } @else {
            <input name="locId" type="text" [(ngModel)]="location.locId" />
            }
          </td>
          <td style="text-align: center">
            @if (!isEditingLocation(location)) {
            {{ location.countryAbbreviation }}
            } @else {
            <select
              name="countryAbbreviation"
              [(ngModel)]="location.countryAbbreviation"
              (ngModelChange)="setLocationCountry(location)"
              required
            >
              @for (country of countries; track country.abbreviation) {
              <option [ngValue]="country.abbreviation">
                {{ country.name }}
              </option>
              }
            </select>
            }
          </td>
          <td>
            @if (!isEditingLocation(location)) {
            {{ location.name }}
            } @else {
            <input name="name" type="text" [(ngModel)]="location.name" />
            }
          </td>
          <td>
            @if (!isEditingLocation(location)) {
            {{ location.address }}
            } @else {
            <input name="address" type="text" [(ngModel)]="location.address" />
            }
          </td>
          <td>
            @if (!isEditingLocation(location)) {
            {{ location.city }}
            } @else {
            <input name="city" type="text" [(ngModel)]="location.city" />
            }
          </td>
          <td style="text-align: center">
            @if (!isEditingLocation(location)) {
            {{ location.stateAbbreviation }}
            } @else {
            <select
              name="state"
              [(ngModel)]="location.stateAbbreviation"
              (ngModelChange)="setLocationState(location)"
              required
            >
              @for (state of getStatesByCountry(location.countryAbbreviation); track
              state.abbreviation) {
              <option [ngValue]="state.abbreviation">
                {{ state.abbreviation }} - {{ state.fullName }}
              </option>
              }
            </select>
            }
          </td>
          <td style="text-align: center">
            @if (!isEditingLocation(location)) {
            {{ location.zipcode }}
            } @else {
            <input name="zipcode" type="text" [(ngModel)]="location.zipcode" />
            }
          </td>
          <td>
            @if (!isEditingLocation(location)) {
            {{ location.latitude }}
            } @else {
            <input
              name="latitude"
              type="number"
              step="0.000001"
              [(ngModel)]="location.latitude"
              (change)="location.confidenceLevel = 'MANUAL'"
            />
            }
          </td>
          <td>
            @if (!isEditingLocation(location)) {
            {{ location.longitude }}
            } @else {
            <input
              name="longitude"
              type="number"
              step="0.000001"
              [(ngModel)]="location.longitude"
              (change)="location.confidenceLevel = 'MANUAL'"
            />
            }
          </td>
          <td style="text-align: center">
            <p-checkbox
              binary="true"
              [(ngModel)]="location.isShipper"
              disabled="{{ !isEditingLocation(location) }}"
            ></p-checkbox>
          </td>
          <td style="text-align: center">
            <p-checkbox
              binary="true"
              [(ngModel)]="location.isConsignee"
              disabled="{{ !isEditingLocation(location) }}"
            ></p-checkbox>
          </td>
          <td style="text-align: center">
            <p-checkbox
              binary="true"
              [(ngModel)]="location.isBillTo"
              disabled="{{ !isEditingLocation(location) }}"
            ></p-checkbox>
          </td>
          <td style="text-align: center">
            {{ location.confidenceLevel }}
          </td>
          <td style="text-align: center">
            {{ location.numMatches }}
            {{
              location.isUserChosen || !location.numMatches || location.numMatches == 1 ? "" : "*"
            }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div
  class="modal fade"
  id="location-create-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="location-create-modal-label"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="location-create-modal-label" class="modal-title">Add New Location</h5>
        <button type="button" class="close" aria-label="Close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="form-group">
            <label for="name">Name</label>
            <input
              id="name"
              name="name"
              type="text"
              [(ngModel)]="locationForm.name"
              (change)="genLocationId()"
              placeholder="Insert new name"
              class="form-control inputForm"
              required
            />
          </div>
          <div class="form-group">
            <label for="countryAbbreviation">Country</label>
            <select
              id="countryAbbreviation"
              name="countryAbbreviation"
              [(ngModel)]="locationForm.countryAbbreviation"
              (ngModelChange)="setLocationCountry(locationForm)"
              (change)="genLocationId()"
              class="form-control inputForm"
              required
            >
              @for (country of countries; track country.abbreviation) {
              <option [ngValue]="country.abbreviation">
                {{ country.name }}
              </option>
              }
            </select>
          </div>
          <div class="form-group">
            <label for="address">Address</label>
            <input
              id="address"
              name="address"
              type="text"
              [(ngModel)]="locationForm.address"
              placeholder="Insert new address"
              class="form-control inputForm"
              required
            />
          </div>
          <div class="form-group">
            <label for="city">City</label>
            <input
              id="city"
              name="city"
              type="text"
              [(ngModel)]="locationForm.city"
              (change)="genLocationId()"
              placeholder="Insert origin city"
              class="form-control inputForm"
              required
            />
          </div>
          <div class="form-group">
            <label for="stateAbbreviation">State</label>
            <select
              id="stateAbbreviation"
              name="stateAbbreviation"
              [(ngModel)]="locationForm.stateAbbreviation"
              (ngModelChange)="setLocationState(locationForm)"
              (change)="genLocationId()"
              class="form-control inputForm"
              required
            >
              <option [ngValue]="''" disabled>Select one</option>
              @for (state of getStatesByCountry(locationForm.countryAbbreviation); track
              state.abbreviation) {
              <option [ngValue]="state.abbreviation">
                {{ state.abbreviation }} - {{ state.fullName }}
              </option>
              }
            </select>
          </div>
          <div class="form-group">
            <label for="zipcode">Zip</label>
            <input
              id="zipcode"
              name="zipcode"
              type="text"
              [(ngModel)]="locationForm.zipcode"
              placeholder="Insert ZIP code"
              class="form-control inputForm"
              required
            />
          </div>
          <div class="form-group">
            <label for="locationId">Location Id</label>
            <input
              id="locationId"
              name="locationId"
              type="text"
              [(ngModel)]="locationForm.locId"
              placeholder="ID generated"
              class="form-control inputForm"
              required
            />
          </div>
          <div class="row">
            <div class="form-group">
              <label class="col-md-6" for="isShipper">Shipper</label>
              <div class="col-md-6">
                <p-checkbox
                  id="isShipper"
                  name="isShipper"
                  binary="true"
                  [(ngModel)]="locationForm.isShipper"
                ></p-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label class="col-md-6" for="isConsignee">Consignee</label>
              <div class="col-md-6">
                <p-checkbox
                  id="isConsignee"
                  name="isConsignee"
                  binary="true"
                  [(ngModel)]="locationForm.isConsignee"
                ></p-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="modalElem btn btn-secondary">
          Close
        </button>
        <button type="button" (click)="createLocation()" class="btn btn-primary">
          Save New Location
        </button>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Select Near Location"
  [(visible)]="exactLocationsData.length"
  [style]="{ 'max-width': '1600px' }"
>
  <h4>Please select one of the available options.</h4>
  <app-pc-miller-near-locations
    [locations]="exactLocationsData"
    (locationSelected)="finishLocationCreateUpdate($event)"
  ></app-pc-miller-near-locations>
</p-dialog>
