<div class="row form-group">
  <label class="col-md-2">Drivers</label>
  <div class="col-md-4">
    <app-drivers-selector
      [isEditing]="isEditing"
      [drivers]="drivers"
      [(isDefaultDriver)]="isDefaultDriver"
      [(selectedDrivers)]="selectedDrivers"
    ></app-drivers-selector>
  </div>
</div>
<hr />

<h5>DRIVER CHARGE</h5>
<div class="row form-group">
  <div class="col-md-12">
    <div class="row form-group">
      <label class="col-md-2">CHARGE PER</label>
      <p-dropdown
        *ngIf="isEditing; else chargePerPreview"
        class="col-md-4"
        [style]="{ minWidth: '100%' }"
        [options]="chargePerOptions"
        [(ngModel)]="rate.chargePer"
        placeholder="Select Charge Per"
        tabbableDropdown
      ></p-dropdown>
      <ng-template #chargePerPreview>
        <div class="col-md-4">{{ rate.chargePer }}</div>
      </ng-template>
    </div>
    <div class="row form-group" *ngIf="rate.chargePer === 'MILE'">
      <div class="col-md-6">
        <label>Seniority Years</label>
        <p-toolbar styleClass="toolbar-header">
          <div class="ui-toolbar-group-left">
            <button
              pButton
              type="button"
              icon="fa fa-file-excel-o"
              class="p-button-secondary mr-2"
              title="Export to Excel"
              label="Export"
              (click)="exportExcel(rates, columns, 'seniority_years')"
            ></button>
            <span *ngIf="isEditing">
              <p-fileUpload
                #fileUpload
                pRipple
                class="p-button-secondary p-button mr-2 no-padding upload-button"
                mode="basic"
                [auto]="true"
                customUpload="true"
                (uploadHandler)="
                  importExcel($event, loadBoundedExcelData, columns, seniorityTable, fileUpload)
                "
                chooseLabel="Import"
                name="myfile[]"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                maxFileSize="1000000"
              >
              </p-fileUpload>
            </span>
          </div>
        </p-toolbar>
        <app-bounded-table
          #seniorityTable
          [(rows)]="rates"
          firstColumn="DAYS"
          [columns]="columns"
          [isEditing]="isEditing"
        >
        </app-bounded-table>
      </div>
    </div>
    <div class="row form-group" *ngIf="rate.chargePer === 'TRIP'">
      <label class="col-md-2">VALUE ($)</label>
      <div class="col-md-4">
        <input
          *ngIf="isEditing; else valuePreview"
          type="number"
          min="0"
          class="form-control input-md"
          [(ngModel)]="rate.value"
        />
        <ng-template #valuePreview>
          <div>{{ rate.value | currency }}</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
