import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { HttpClient } from "../../shared/services/http/http-client.service";
import { TripCharge } from "@app/shared/models/trips/TripCharge.model";
import { BatchGlobal } from "@app/shared/models/BatchGlobal.model";
import { DatePipe } from "@angular/common";

@Injectable()
export class SettlementsService {
  constructor(private _http: HttpClient, private datePipe: DatePipe) {}

  getHomeColumns() {
    return this._http.get("/api/batch/columns").pipe(
      map((res) => {
        let cols = res as any;
        let columns = [
          {
            name: "Driver ID",
            prop: "driverNo",
            width: "140px",
            widthSize: 140,
            searchWidth: "90%",
            frozenLeft: true,
            isAction: false,
            temp: "TEXT",
            isEditable: false,
            type: "INFO",
          },
          {
            name: "Driver",
            prop: "driverName",
            width: "230px",
            widthSize: 230,
            searchWidth: "90%",
            frozenLeft: true,
            isAction: false,
            temp: "TEXT",
            isEditable: false,
            type: "INFO",
          },
          {
            name: "Truck",
            prop: "unitNumber",
            searchWidth: "90%",
            width: "100px",
            widthSize: 70,
            frozenLeft: true,
            isAction: false,
            temp: "TEXT",
            isEditable: false,
            type: "INFO",
          },
          {
            name: "Driver Type",
            prop: "driverType",
            width: "200px",
            widthSize: 200,
            searchWidth: "90%",
            frozenLeft: true,
            isAction: false,
            temp: "TEXT",
            isEditable: false,
            type: "INFO",
          },
          {
            name: "Lessor",
            prop: "lessorInd",
            width: "100px",
            widthSize: 70,
            searchWidth: "90%",
            frozenLeft: true,
            isAction: false,
            temp: "TEXT",
            isEditable: false,
            type: "INFO",
          },
          {
            name: "Projected Earnings",
            prop: "earn",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            frozenLeft: true,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "TOTAL",
          },
          {
            name: "Fuel",
            prop: "fuel",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "TOTAL",
          },
          {
            name: "Non Fuel",
            prop: "nonFuel",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "TOTAL",
          },
          {
            name: "Reimbursement",
            prop: "reimbursement",
            searchWidth: "90%",
            width: "170px",
            widthSize: 170,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "TOTAL",
          },
          {
            name: "Total Deductions",
            prop: "deductions",
            searchWidth: "90%",
            width: "140px",
            widthSize: 140,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "TOTAL",
          },
          {
            name: "Net",
            prop: "total",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "SUMMARY",
          },
          {
            name: "ESCBB",
            prop: "escrowTotal",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "TOTAL",
          },
          {
            name: "ESCADD",
            prop: "escrowDepositValue",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: true,
            type: "ESCROW",
          },
          {
            name: "ESCSUB",
            prop: "escrowWithdrawValue",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: true,
            type: "ESCROW",
          },
          {
            name: "ESCINT",
            prop: "escrowInterestValue",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: true,
            type: "ESCROW",
          },
          {
            name: "ESCNET",
            prop: "escrowBatch",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "SUMMARY",
          },
          {
            name: "ESCEB",
            prop: "escrowTotalNew",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "TOTAL",
          },
          {
            name: "DEFBB",
            prop: "deficitTotal",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "TOTAL",
          },
          {
            name: "DEFADD",
            prop: "deficitDepositValue",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: true,
            type: "DEFICIT",
          },
          {
            name: "DEFSUB",
            prop: "deficitWithdrawValue",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: true,
            type: "DEFICIT",
          },
          {
            name: "DEFNET",
            prop: "deficitBatch",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "SUMMARY",
          },
          {
            name: "DEFEB",
            prop: "deficitTotalNew",
            searchWidth: "90%",
            width: "130px",
            widthSize: 130,
            temp: "MONEY",
            isAction: false,
            isEditable: false,
            type: "TOTAL",
          },
        ];
        for (let i = 0; i < cols.length; i++) {
          cols[i].width = "140px";
          cols[i].widthSize = 140;
          cols[i].searchWidth = "90%";
          cols[i].temp = "MONEY";
          cols[i].type = "DEDUCTION";
          cols[i].isAction = false;
          cols[i].isEditable = true;
          columns.push(cols[i]);
        }
        columns.push({
          name: "Total Deductions",
          prop: "deductions",
          searchWidth: "90%",
          width: "140px",
          widthSize: 140,
          temp: "MONEY",
          isAction: false,
          isEditable: false,
          type: "TOTAL",
        });
        columns.push({
          name: "ESCEB",
          prop: "escrowTotalNew",
          searchWidth: "90%",
          width: "130px",
          widthSize: 130,
          temp: "MONEY",
          isAction: false,
          isEditable: false,
          type: "TOTAL",
        });
        columns.push({
          name: "DEFEB",
          prop: "deficitTotalNew",
          searchWidth: "90%",
          width: "130px",
          widthSize: 130,
          temp: "MONEY",
          isAction: false,
          isEditable: false,
          type: "TOTAL",
        });
        columns.push({
          name: "Total",
          prop: "total",
          searchWidth: "90%",
          width: "135px",
          widthSize: 135,
          temp: "MONEY",
          isAction: false,
          isEditable: false,
          type: "TOTAL",
        });

        return columns;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  sendDriverSettlementEmails(data) {
    return this._http.post("/api/batch/driverSettlementEmails", data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getEscrowByDriverId(id: number) {
    return this._http.get(`/api/ecsrow/drivers/${id}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getDeficitByDriverId(id: number) {
    return this._http.get(`/api/deficit/drivers/${id}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getHomeData(batchNo) {
    return this._http.post("/api/batch/home", { batchNo }).pipe(
      map((res: any) => {
        for (const batchDriver of res) {
          for (const deduction of batchDriver.driverDeductions) {
            batchDriver[deduction.deductionId] = deduction.customValue;
          }
        }
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  selectAllEarnings(batchNo, driverIds) {
    return this._http.post("/api/batch/selectAllEarnings", { batchNo, driverIds }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getGlobalBatchList(beginDate = null, endDate = null) {
    return this._http
      .post("/api/batch/getGlobalBatchList", {
        beginDate: beginDate,
        endDate: endDate,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.message || "Server error"))
      );
  }

  getGlobalBatchListDropDown(activeStatus: "Active" | "Inactive" | "All" = "All") {
    return this._http.post("/api/batch/getGlobalBatchList", {}).pipe(
      map((res: any) => {
        return res
          .filter((batch: BatchGlobal) => {
            if (activeStatus === "All") {
              return true;
            } else if (activeStatus === "Active") {
              return batch.status === "ACTIVE";
            } else if (activeStatus === "Inactive") {
              return batch.status !== "ACTIVE";
            }
          })
          .map((batch: BatchGlobal) => ({
            id: batch.id,
            itemName: `Batch No: ${batch.batchNo} ${this.datePipe.transform(
              batch.startDate,
              "MM/dd/yyyy",
              "GMT-00:00"
            )} - ${
              batch.endDate != null
                ? this.datePipe.transform(batch.endDate, "MM/dd/yyyy", "GMT-00:00") + " "
                : " "
            }${batch.status}`,
            batchNo: batch.batchNo,
          }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getGlobalBatchMonthList() {
    return this._http.get("/api/batch/getGlobalBatchMonthList").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getBatch(batchId: string) {
    return this._http.get(`/api/batch/getBatch/${batchId}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getBatchDetailsById(id: string) {
    return this._http.post("/api/batch/getBatchDetailsByID", { id }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getTeamSplitByBatchDriverId(batchDriverId: number) {
    return this._http.post("/api/batch/getTeamSplitByBatchDriverId", { batchDriverId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getBatchDeficitByBatchId(batchId: number) {
    return this._http.get(`/api/deficit/batches/${batchId}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getEscrowByBatchId(batchId: number) {
    return this._http.get(`/api/escrow/batches/${batchId}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getNonBatchAssignedTrips(driverId: number) {
    return this._http.post("/api/batch/getNonBatchAssignedTrips", { driverId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  saveBatch(batch) {
    return this._http.post("/api/batch/saveBatchChanges", { batch }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  saveBatchDriverCharges(DriverCharges: TripCharge[]) {
    return this._http.post("/api/batch/saveBatchDriverCharges", { DriverCharges }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  setLoadInclude(loads, include = true) {
    return this._http
      .post("/api/batch/setLoadInclude", {
        loads: loads,
        include: include,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.message || "Server error"))
      );
  }

  closeBatchList(BatchIds: number[]) {
    return this._http.post("/api/batch/closeBatchList", { BatchIds }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  closeBatchForAll(batchNo: number) {
    return this._http.post("/api/batch/closeBatchForAll", { batchNo }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getBatchListByDriverId(driverId: number) {
    return this._http.post("/api/batch/getAllDriverBatch", { driverId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getFuelListByCardNo(tCheckCardNo: string) {
    return this._http.post("/api/batch/getFuelListByCardNo", { tCheckCardNo }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  createBatchForDriverList(driverIds: number[], startDate: Date, endDate: Date) {
    return this._http
      .post("/api/batch/createBatchForDriverList", {
        driverIds,
        startDate,
        endDate,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.message || "Server error"))
      );
  }

  listUnassignedDrivers() {
    return this._http.get("/api/batch/listUnassignedDrivers").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getDriversByBatch(batchNo: string | number) {
    return this._http.post("/api/batch/getDriversByBatch", { batchNo }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getDriversInBatch(batchNo: string | number) {
    return this._http.post("/api/batch/getDriversInBatch", { batchNo }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  changeDriversBatch(newDrivers, removeDrivers, batchNo: number) {
    return this._http
      .post("/api/batch/changeDriversBatch", {
        newDrivers,
        removeDrivers,
        batchNo,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.message || "Server error"))
      );
  }

  printInvoice(driverId: number, batchId: string) {
    return this._http.post<BlobPart>("/api/batch/printInvoice", { driverId, batchId }, "blob").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  saveBatchDataChange(batchData) {
    return this._http.post("/api/batch/saveBatchDataChange", { batchData }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  getActiveBatch() {
    return this._http.get("/api/batch/getActiveBatch").pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server trip listing error"))
    );
  }

  parseDeductions(batchNo: number, csvString: string) {
    return this._http.post("/api/batch/parseDeductions", { batchNo, csvString }).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error))
    );
  }

  importDeductions(batchNo: number, rows: any[], deductionsOrder: string[]) {
    return this._http.post("/api/batch/importDeductions", { batchNo, rows, deductionsOrder }).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteDeductions(batchNo: number) {
    return this._http.post("/api/batch/deleteDeductions", { batchNo }).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteFuel(batchNo: number) {
    return this._http.post("/api/batch/deleteFuel", { batchNo }).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getAllLoadsForBatch(batchId: string) {
    return this._http.post("/api/batch/getAllLoads", { batchId }).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateAllLoads(batchId: string, trips: any) {
    return this._http.post("/api/batch/updateAllLoads", { batchId, trips }).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
