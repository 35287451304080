<div class="btn-group">
	<button type="button" class="btn btn-secondary dropdown-toggle" (click)="toggleSelect()">
		<span class="pull-left" [innerHtml]="header"></span>
		<span class="caret pull-right"></span>
	</button>
	<ul class="dropdown-menu multi-select-popup" [ngStyle]="{display:isOpen ? 'block' : 'none'}" style="display:block;">
		<button class="selectBtn" (click)="selectionAll(true)">Select all</button>
		<button class="selectBtn" (click)="selectionAll(false)">Deselect all</button>
		<li *ngIf="enableFilter" class="filter-container">
			<div class="form-group has-feedback filter">
				<input class="form-control" type="text" [value]="filterText" [placeholder]="filterPlaceholder" [formControl]="filterInput"
				/>
				<span class="clear-filter fa fa-times-circle-o form-control-feedback" (click)="clearFilter()"></span>
			</div>
		</li>
		<li *ngFor="let item of itemsList | filter:{value:filterText}">
			<a (click)="selectItem(item)" class="dropdown-item">
				<i class="fa fa-fw" [ngClass]="{'fa-check': item.checked, 'glyphicon-none': !item.checked}"></i>
				<span [innerHtml]="item.value"></span>
			</a>
		</li>
	</ul>
</div>