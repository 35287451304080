<div style="width: fit-content; min-width: 100%">
  <p-table [value]="rows" [autoLayout]="true">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns">
          {{ col.name }}
        </th>
        <th style="width: 50px" *ngIf="isEditing">
          <div>
            <button
              pButton
              type="button"
              icon="pi pi-plus"
              class="p-button-success"
              (click)="addRow()"
              aria-label="addRow"
            ></button>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr>
        <td *ngFor="let col of columns" class="bounds-column">
          <ng-container *ngIf="isEditing">
            <div class="bounds-holder">
              <input
                *ngIf="col.type === 'dollar'"
                type="number"
                min="0"
                class="form-control bounds-input"
                [(ngModel)]="row.values[col.field]"
                (keydown.Tab)="onTab(col.field, i)"
              />
            </div>
            <div class="bounds-holder">
              <span *ngIf="col.type === 'readonly'">{{
                row.values[col.field]
              }}</span>
              <input
                *ngIf="col.type === 'number'"
                type="number"
                min="0"
                step="1"
                class="form-control bounds-input"
                [(ngModel)]="row.values[col.field]"
                (keydown.Tab)="onTab(col.field, i)"
              />
              <span *ngIf="showPlusIcon(i, col)">+</span>
            </div>
          </ng-container>
          <ng-container *ngIf="!isEditing">
            <span *ngIf="col.type === 'dollar' || !col.type">$</span
            >{{ row.values[col.field] }}
            <span *ngIf="showPlusIcon(i, col)">+</span>
          </ng-container>
        </td>
        <td style="text-align: center" *ngIf="isEditing">
          <button
            pButton
            pRipple
            type="button"
            class="p-button-danger"
            icon="pi pi-trash"
            (click)="deleteRow(i)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
