import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { EquipmentService } from "../shared/equipment.service";
import { ProductService } from "../../product/shared/products.service";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "app-equipment",
  templateUrl: "./equipment.component.html",
  styleUrls: ["./equipment.component.css"],
})
export class EquipmentComponent implements OnInit {
  @ViewChild("productDropDown", { static: true }) productDropDown: ElementRef;
  constructor(
    private equipmentService: EquipmentService,
    private authService: authenticationService,
    private productsService: ProductService
  ) {}
  errorMsg: any;
  modalError: any;
  btn: any;
  equipmentList: any;
  selEquipment: any;
  availableProducts = [];
  newProductId: any;

  ngOnInit() {
    this.viewDetail(null);

    this.availableProducts = [];
    this.productsService.list().subscribe((result: any) => {
      for (let i = 0; i < result.length; i++) {
        let product = result[i];
        product.label = product.name + ": " + product.un;
        product.value = product.id;
        this.availableProducts.push(product);
      }
    });

    this.refreshEquipment();
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  refreshEquipment() {
    this.equipmentService.getEquipmentList().subscribe(
      (resEquipmentData) => {
        this.equipmentList = resEquipmentData;
      },
      (resEquipmentError) => {
        this.errorMsg = resEquipmentError;
      }
    );
  }

  viewDetail(sel) {
    this.modalError = "";
    if (sel == null) {
      this.selEquipment = { name: "" };
      this.btn = "Create Equipment Code";
    } else {
      this.newProductId = "";
      this.selEquipment = JSON.parse(JSON.stringify(sel));
      this.selEquipment.origName = sel.name;
      this.btn = "Update";
    }
  }

  validateEquipmentCodeName(name) {
    let equipment = this.equipmentList.find((item) => item.name == name);
    if (equipment) {
      this.modalError = "Equipment Code " + name + " already exists.";

      return false;
    }
    return true;
  }

  editOn() {
    if (!this.validateEquipmentCodeName(this.selEquipment.name.toUpperCase())) return;
    this.modalError = "";

    if (this.btn == "Update") {
      this.selEquipment.name = this.selEquipment.name.toUpperCase();
      let equipment = this.selEquipment;
      this.equipmentService.update(equipment).subscribe((data) => {
        let updated = this.equipmentList.find((equipment) => equipment.id === this.selEquipment.id);
        updated.name = equipment.name;
      });
    } else {
      if (!this.validateEquipmentCodeName(this.selEquipment.name.toUpperCase())) return;

      let equipment = JSON.parse(JSON.stringify(this.selEquipment));
      equipment.name = equipment.name.toUpperCase();
      this.equipmentService.create(equipment).subscribe((data) => {
        this.equipmentList.push(data);
        this.equipmentList.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });
      });
    }
  }

  addProduct() {
    let product = this.availableProducts.find((item) => item.value === this.newProductId);
    let equipment = this.equipmentList.find((item) => item.id === this.selEquipment.id);
    if (!equipment.Equipment) equipment.Equipment = [];

    for (let i = 0; i < equipment.Equipment.length; i++) {
      let equipProduct = equipment.Equipment[i].Product;
      if (equipProduct && equipProduct.id == product.id) {
        this.modalError =
          "Product " +
          product.name +
          ": " +
          product.un +
          " is already associated with equipment code " +
          this.selEquipment.name;
        return;
      }
    }
    this.modalError = "";

    this.equipmentService
      .addProduct(this.selEquipment.id, this.newProductId)
      .subscribe((data: any) => {
        let newEquipment = data;
        newEquipment.Product = product;
        equipment.Equipment.push(newEquipment);
      });
  }

  delete(equipment) {
    const r = confirm(
      'Are you sure you want to delete equipment code "' +
        equipment.name +
        '" and all its associations?'
    );
    if (r === true) {
      this.equipmentService.delete(equipment).subscribe((data) => {
        this.equipmentList = this.equipmentList.filter((item) => item.id !== equipment.id);
      });
    }
  }

  deleteProduct(equipment, equip) {
    this.equipmentService.deleteProduct(equip.id).subscribe((data) => {
      for (let i = 0; i < equipment.Equipment.length; i++) {
        if (equipment.Equipment[i] === equip) {
          equipment.Equipment.splice(i, 1);
          break;
        }
      }
    });
  }
}
