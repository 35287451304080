import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "../../../shared/services/http/http-client.service";

@Injectable()
export class ClientWeeklyReportService {
  constructor(private router: Router, private _http: HttpClient) {}

  createReportDetails(clientWeeklyReportDetails) {
    return this._http
      .post("api/clientWeeklyReportDetails/create", { clientWeeklyReportDetails })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.message || "Server error"))
      );
  }
  createReport(clientId) {
    return this._http
      .post("api/clientWeeklyReport/create", { clientWeeklyReport: { ClientId: clientId } })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => observableThrowError(error.message || "Server error"))
      );
  }
  listByClient(clientId) {
    return this._http.post("api/clientWeeklyReport/listByClient", { clientId }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  list(offset = 0, limit = 20, filter = {}, order = "createdAt") {
    return this._http.post("api/clientWeeklyReport/list", { offset, limit, filter, order }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  listReportDetails(reportId) {
    return this._http.get("api/clientWeeklyReport/find/" + reportId).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  getLastByClient(clientId) {
    return this._http.get("api/clientWeeklyReport/lastByClient/" + clientId).pipe(
      map((res) => {
        if (res["_body"].length) {
          return res;
        } else {
          return [];
        }
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
