import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MaskService } from "../../shared/services/mask/mask.service";
import { clientService } from "../shared/client.service";

@Component({
  selector: "app-bill-to",
  templateUrl: "./bill-to.component.html",
  styleUrls: ["./bill-to.component.css"],
})
export class BillToComponent implements OnInit {
  @Input("clientId") ClientId;
  @Input("states") states;
  @Input("isBillTo") isBillTo;
  @Input("isShipper") isShipper;
  @Input("isConsignee") isConsignee;
  @Input("billTo") billTo;
  @Input("companyName") companyName;
  @Input("disableInputs") disableInputs;
  @Input("index") index: string;
  @Output("locIdGenerated") locIdGenerated = new EventEmitter<any>();
  @Output("billToEdited") billToEdited = new EventEmitter<any>();

  newBillTo: any;
  usPhoneMask: any;
  dateInputMask: any;
  constructor(private maskService: MaskService, private clientService: clientService) {}

  ngOnInit() {
    this.newBillTo = this.billTo || {
      isActive: true,
    };
    this.usPhoneMask = this.maskService.getUsPhoneMask();

    this.dateInputMask = this.maskService.getInputDateOnlyMask();
  }
  validateBillTo() {
    return this.clientService.validateBillTo(this.newBillTo);
  }

  generateBillingId() {
    const cn = this.companyName || "";
    const bs = this.newBillTo.state || "";
    const bc = this.newBillTo.city || "";
    const bcn = this.newBillTo.contactName || "";
    const bcz = this.newBillTo.zip || "";
    this.newBillTo.locId =
      cn.substring(0, 2) +
      bs.substring(0, 2) +
      bc.substring(0, 2) +
      bcn.substring(0, 2) +
      bcz.substring(0, 4);
    if (this.newBillTo.locId && this.newBillTo.locId.length >= 4 && this.newBillTo.zip) {
      this.locIdGenerated.emit(true);
    } else {
      this.locIdGenerated.emit(false);
    }
  }

  useTheClientAsContact(newClient) {
    delete this.newBillTo.locId;
    this.companyName = newClient.companyName || "";
    this.newBillTo.validFrom = newClient.validFrom || null;
    this.newBillTo.validTo = newClient.validTo || null;
    this.newBillTo.address = newClient.address || "";
    this.newBillTo.address2 = newClient.address2 || "";
    this.newBillTo.companyName = newClient.companyName || "";
    this.newBillTo.state = newClient.state || "";
    this.newBillTo.city = newClient.city || "";
    this.newBillTo.zip = newClient.zip || "";
    this.newBillTo.phone = newClient.phone || "";
    this.newBillTo.email = newClient.email || "";
    this.newBillTo.contactName = newClient.contactName || "";
    //this.generateBillingId();
  }

  getBillTo() {
    return this.newBillTo;
  }

  resetBillTo() {
    this.newBillTo = {};
  }

  infoEdited(billToClient) {
    this.billToEdited.emit(billToClient);
  }

  dateChanged(event: KeyboardEvent) {
    const attributes = {
      justDate: true,
      yearDigits: 2,
    };
    this.maskService.newMaskDate(event, attributes);
  }

  removeBillToSpaces() {
    this.newBillTo.customerNo = this.newBillTo.customerNo.replace(/\s/g, "");
  }
}
