import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { HttpClient } from "../../../shared/services/http/http-client.service";

@Injectable()
export class ConfigService {
  currentSettup: any;
  constructor(private router: Router, private _http: HttpClient) {}

  findLastVersion() {
    return this._http.get("api/config/findLastVersion").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  findByVersion(version) {
    return this._http.get("api/config/findByVersion/" + version).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  listVersions() {
    return this._http.get("api/config/listVersions").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  update(newData: any) {
    return this._http.patch("api/config", newData).pipe(
      map(async (res) => {
        await this.getCurrentSetup(true);
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getCurrentSetup(forceFetch = false) {
    return new Promise((resolve, reject) => {
      if (!forceFetch && this.currentSettup) {
        resolve(this.currentSettup);
      } else {
        return this.findLastVersion()
          .toPromise()
          .then((currentSettings) => {
            return this.listVersions()
              .toPromise()
              .then((versions) => {
                this.currentSettup = { currentSettings, versions };
                resolve(this.currentSettup);
              });
          });
      }
    });
  }
}
