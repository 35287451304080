import { Injectable } from "@angular/core";

import { clientService } from "./client.service";

@Injectable()
export class ParentsResolver {
  constructor(private clientService: clientService) {}

  resolve() {
    return this.clientService.parentClientListDropdown();
  }
}
