<div style="width: fit-content; min-width: 100%">
  <p-table [value]="rows" [autoLayout]="true">
    <ng-template pTemplate="header">
      <tr>
        <th class="bounds-column">{{ firstColumn }}</th>
        <th style="min-width: 110px" *ngFor="let col of columns">
          {{ col.name }}
        </th>
        <th style="width: 50px" *ngIf="isEditing">
          <div>
            <button
              pButton
              type="button"
              icon="pi pi-plus"
              class="p-button-success"
              (click)="addRow()"
              aria-label="addRow"
            ></button>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr>
        <td class="bounds-column">
          <div [ngClass]="{ 'bounds-holder': isEditing }">
            <ng-container *ngIf="!(noUpperBound && i == rows.length - 1)">
              <!-- Number -->
              <ng-container *ngIf="isEditing && firstColumnType !== 'dollar'">
                <!-- Number Floor -->
                <input
                  type="number"
                  min="0"
                  [step]="increment"
                  class="form-control p-inputtext bounds-input"
                  (change)="updateRowFloor(i)"
                  [(ngModel)]="row[floor]"
                  aria-label="rowFloor"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
                <span>-</span>
                <!-- Number Roof -->
                <input
                  type="number"
                  min="0"
                  [step]="increment"
                  class="form-control p-inputtext bounds-input"
                  (change)="updateRowCeiling(i)"
                  [(ngModel)]="row[roof]"
                  aria-label="rowRoof"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </ng-container>
              <!-- Dollars -->
              <ng-container *ngIf="isEditing && firstColumnType === 'dollar'">
                <!-- Dollars Floor -->
                <input
                  type="number"
                  min="0"
                  [step]="increment"
                  class="form-control p-inputtext bounds-input"
                  (change)="updateRowFloor(i)"
                  [(ngModel)]="row[floor]"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46)"
                />
                <span>-</span>
                <!-- Doolars Roof -->
                <input
                  type="number"
                  min="0"
                  [step]="increment"
                  class="form-control p-inputtext bounds-input"
                  (change)="updateRowCeiling(i)"
                  [(ngModel)]="row[roof]"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode === 46)"
                />
              </ng-container>
              <ng-container *ngIf="!isEditing">
                <span *ngIf="firstColumnType === 'dollar'">$</span>{{ row[floor] }} -
                <span *ngIf="firstColumnType === 'dollar'">$</span>{{ row[roof] }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="noUpperBound && i == rows.length - 1">
              {{ row[floor] }}+
            </ng-container>
          </div>
        </td>
        <td *ngFor="let col of columns">
          <div class="bounds-holder">
            <!-- Edit mode -->
            <ng-container *ngIf="isEditing">
              <span *ngIf="(col.type === 'dollar' && firstColumnType === 'dollar') || !col.type"
                >$</span
              >
              <input
                *ngIf="col.type === 'number' || col.type === 'dollar' || col.type === 'percentage'"
                type="number"
                min="0"
                step="any"
                class="form-control p-inputtext"
                [(ngModel)]="row.values[col.field]"
                (change)="onRowValueUpdated(i)"
                aria-label="bandRate"
                (keydown.Tab)="onTab(col.field, i)"
              />
              <span *ngIf="col.type === 'readonly'">${{ row.values[col.field] }}</span>
            </ng-container>
          </div>
          <!-- Preview mode -->
          <ng-container *ngIf="!isEditing">
            <span *ngIf="col.type === 'dollar' || !col.type">${{ row.values[col.field] }}</span>
            <span *ngIf="col.type === 'percentage'"
              >{{ row.values[col.field] * 100 | number }}%</span
            >
            <span *ngIf="col.type === 'number'">{{ row.values[col.field] | number }}</span>
            <span *ngIf="col.type === 'readonly'">{{ row.values[col.field] | currency }}</span>
          </ng-container>
        </td>
        <td style="text-align: center" *ngIf="isEditing">
          <button
            *ngIf="!(noUpperBound && i == rows.length - 1)"
            pButton
            pRipple
            type="button"
            class="p-button-danger"
            icon="pi pi-trash"
            (click)="deleteRow(i)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
