import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { HttpClient } from "../../shared/services/http/http-client.service";

import { ClientRateViewModel } from "@app/shared/models/rates/ClientRateViewModel.model";
import { DriverRateViewModel } from "@app/shared/models/rates/DriverRateViewModel.model";
import { FuelSurchargeViewModel } from "@app/shared/models/rates/FuelSurchargeViewModel.model";
import { InsuranceSurchargeViewModel } from "@app/shared/models/rates/InsuranceSurchargeViewModel.model";
import { LongevityViewModel } from "@app/shared/models/rates/LongevityViewModel.model";
import { CustomChargeViewModel } from "@app/shared/models/rates/CustomChargeViewModel.model";

@Injectable()
export class RatesService {
  constructor(private _http: HttpClient) {}

  createRule(rule: any, type: string) {
    switch (type.toLowerCase()) {
      case "client":
        return this.createClientRates(rule);
      case "driver":
        return this.createDriverRates(rule);
      case "fuel":
        return this.createFuelSurcharges(rule);
      case "longevity":
        return this.createLongevity(rule);
      case "insurance":
        return this.createInsuranceSurcharges(rule);
      case "custom":
        return this.createCustomCharge(rule);
    }
  }

  updateRule(id: number, rule: any, type: string) {
    switch (type.toLowerCase()) {
      case "client":
        return this.updateClientRates(id, rule);
      case "driver":
        return this.updateDriverRates(id, rule);
      case "fuel":
        return this.updateFuelSurcharges(id, rule);
      case "longevity":
        return this.updateLongevity(id, rule);
      case "insurance":
        return this.updateInsuranceSurcharges(id, rule);
      case "custom":
        return this.updateCustomCharge(id, rule);
    }
  }

  deleteRule(id: number, type: string) {
    switch (type.toLowerCase()) {
      case "client":
        return this.deleteClientRate(id);
      case "driver":
        return this.deleteDriverRate(id);
      case "fuel":
        return this.deleteFuelSurcharge(id);
      case "longevity":
        return this.deleteLongevity(id);
      case "insurance":
        return this.deleteInsuranceSurcharge(id);
      case "custom":
        return this.deleteCustomCharge(id);
    }
  }

  getRatesByType(type: string): Observable<any> {
    return this._http.get(`api/rates/rateRules/${type}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  // #region Daily Minimum Rates
  getDailyMinimumRates(): Observable<any> {
    return this._http.get(`api/rates/minimums`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  createDailyMinimumRate(rate: any) {
    return this._http.post("api/rates/minimums", rate).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateDailyMinimumRate(rate: any) {
    return this._http.patch(`api/rates/minimums/${rate.id}`, rate).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteDailyMinimumRate(id: string) {
    return this._http.delete(`api/rates/minimums/${id}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getDailyMinimumRateById(id: string) {
    return this._http.get(`api/rates/minimums/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getDailyMinimumRateHistory(id: string) {
    return this._http.get(`api/rates/minimums/history/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  // #endregion

  getDriverGuaranteedRate(id: string, date: Date) {
    return this._http.post(`api/rates/minimums/getDriverGuarantee`, { driverId: id, date }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  // #endregion

  // #region Client Rates
  createClientRates(clientRates: any) {
    return this._http.post("api/rates/createClientRates", clientRates).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateClientRates(id: number, clientRates: any) {
    return this._http.patch(`api/rates/clientRates/${id}`, clientRates).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteClientRate(id: number) {
    return this._http.delete(`api/rates/clientRates/${id}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getClientRates() {
    return this._http.get("api/rates/clientRates").pipe(
      map((res: ClientRateViewModel[]) => {
        return res.map((rate) => ({
          ...rate,
          rateBy: `${rate.weightOrDistance} - ${rate.equipmentOrProduct}`,
          validFromUtc: rate.validFromUtc ? new Date(rate.validFromUtc).toLocaleDateString() : null,
          validToUtc: rate.validToUtc ? new Date(rate.validToUtc).toLocaleDateString() : null,
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getClientRatesById(id: string | number) {
    return this._http.get(`api/rates/clientRates/${id}`).pipe(
      map((res: ClientRateViewModel) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  // #endregion

  // #region Driver Rates
  createDriverRates(DriverRates: any) {
    return this._http.post("api/rates/createDriverRates", DriverRates).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateDriverRates(id: number, DriverRates: any) {
    return this._http.patch(`api/rates/driverRates/${id}`, DriverRates).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteDriverRate(id: number) {
    return this._http.delete(`api/rates/driverRates/${id}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getDriverRates() {
    return this._http.get("api/rates/driverRates").pipe(
      map((res: DriverRateViewModel[]) => {
        return res.map((rate) => ({
          ...rate,
          rateBy: `${rate.weightOrDistance} - ${rate.equipmentOrProduct}`,
          validFromUtc: rate.validFromUtc ? new Date(rate.validFromUtc).toLocaleDateString() : null,
          validToUtc: rate.validToUtc ? new Date(rate.validToUtc).toLocaleDateString() : null,
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getDriverRatesById(id: string | number) {
    return this._http.get(`api/rates/driverRates/${id}`).pipe(
      map((res: DriverRateViewModel) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  // #endregion

  // #region Fuel Surcharges
  getFuelSurcharges(): Observable<any> {
    return this._http.get(`api/rates/fuelSurcharges`).pipe(
      map((res: any) => {
        return res.map((rate) => ({
          ...rate,
          validFromUtc: rate.validFromUtc ? new Date(rate.validFromUtc).toLocaleDateString() : null,
          validToUtc: rate.validToUtc ? new Date(rate.validToUtc).toLocaleDateString() : null,
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  createFuelSurcharges(fuelSurcharges: any) {
    return this._http.post("api/rates/createFuelSurcharges", fuelSurcharges).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateFuelSurcharges(id: number, fuelSurcharges: any) {
    return this._http.patch(`api/rates/fuelSurcharges/${id}`, fuelSurcharges).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteFuelSurcharge(id: number) {
    return this._http.delete(`api/rates/fuelSurcharges/${id}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getFuelSurchargesById(id: string | number) {
    return this._http.get(`api/rates/fuelSurcharges/${id}`).pipe(
      map((res: FuelSurchargeViewModel) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  // #endregion

  // #region Insurance Surcharges
  getInsuranceSurcharges(): Observable<any> {
    return this._http.get(`api/rates/insuranceSurcharges`).pipe(
      map((res: any) => {
        return res.map((rate) => ({
          ...rate,
          validFromUtc: rate.validFromUtc ? new Date(rate.validFromUtc).toLocaleDateString() : null,
          validToUtc: rate.validToUtc ? new Date(rate.validToUtc).toLocaleDateString() : null,
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  createInsuranceSurcharges(insuranceSurcharges: any) {
    return this._http.post("api/rates/createInsuranceSurcharges", insuranceSurcharges).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateInsuranceSurcharges(id: number, insuranceSurcharges: any) {
    return this._http.patch(`api/rates/insuranceSurcharges/${id}`, insuranceSurcharges).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteInsuranceSurcharge(id: number) {
    return this._http.delete(`api/rates/insuranceSurcharges/${id}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getInsuranceSurchargeById(id: string | number) {
    return this._http.get(`api/rates/insuranceSurcharges/${id}`).pipe(
      map((res: InsuranceSurchargeViewModel) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  // #endregion

  // #region Longevity
  getLongevityList(): Observable<any> {
    return this._http.get(`api/rates/longevity`).pipe(
      map((res: any) => {
        return res.map((rate) => ({
          ...rate,
          validFromUtc: rate.validFromUtc ? new Date(rate.validFromUtc).toLocaleDateString() : null,
          validToUtc: rate.validToUtc ? new Date(rate.validToUtc).toLocaleDateString() : null,
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  createLongevity(longevity: any) {
    return this._http.post("api/rates/createLongevity", longevity).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateLongevity(id: number, longevity: any) {
    return this._http.patch(`api/rates/longevity/${id}`, longevity).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteLongevity(id: number) {
    return this._http.delete(`api/rates/longevity/${id}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getLongevityById(id: string | number) {
    return this._http.get(`api/rates/longevity/${id}`).pipe(
      map((res: LongevityViewModel) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  // #endregion

  // #region Custom Charges
  getCustomCharges(): Observable<any> {
    return this._http.get(`api/rates/customCharges`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  // combined guarantees and custom charges into one tab, with one list, despite being
  // different types of charges
  getCustomChargesAndGuarantees(): Observable<any> {
    return this._http.get(`api/rates/customChargesAndGuarantees`).pipe(
      map((res: any) => {
        return res.map((rate) => ({
          ...rate,
          validFromUtc: rate.validFromUtc ? new Date(rate.validFromUtc).toLocaleDateString() : null,
          validToUtc: rate.validToUtc ? new Date(rate.validToUtc).toLocaleDateString() : null,
        }));
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getCustomChargeTypes(): Observable<any> {
    return this._http.get(`api/rates/customChargeTypes`).pipe(
      map((res) => res),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  createCustomCharge(longevity: any) {
    return this._http.post("api/rates/createCustomCharge", longevity).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateCustomCharge(id: number, customCharge: any) {
    return this._http.patch(`api/rates/customCharges/${id}`, customCharge).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteCustomCharge(id: number) {
    return this._http.delete(`api/rates/customCharges/${id}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getCustomChargeById(id: string | number) {
    return this._http.get(`api/rates/customCharges/${id}`).pipe(
      map((res: CustomChargeViewModel) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
  // #endregion
}
