import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "../../../shared/services/http/http-client.service";
import { User } from "@models/user.model";

@Injectable()
export class UserService {
  constructor(private router: Router, private _http: HttpClient) {}

  createNewUser(user: User) {
    return this._http.post("/api/user/create", user).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error cant create user")
      )
    );
  }

  findAndFilter() {
    return this._http.post("/api/user/findAndFilter", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateUser(user: User) {
    return this._http.patch("/api/user/update", user).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteUser(userId: string) {
    return this._http.delete("/api/user/delete/" + userId).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Error deleting user"))
    );
  }

  restoreUser(userId: string) {
    return this._http.patch("/api/user/restore/" + userId, null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
