import { Injectable } from "@angular/core";
import { HttpClient } from "../shared/services/http/http-client.service";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class authenticationService {
  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService) {}

  private usernameSubject = new BehaviorSubject<string>(this.getUsername());

  async login(user) {
    try {
      const credentials = { user: user.email, password: user.password };
      const response = (await this.http.post("/api/auth/login", credentials).toPromise()) as any;

      this.setUsername(response.username);
      localStorage.setItem("permissions", JSON.stringify(response.permissions));
      this.router.navigate(["/home"]);
      return user;
    } catch (error) {
      throw new Error(error.message || "Server Auth Error");
    }
  }

  logout() {
    this.http.post("/api/auth/logout", {}).subscribe(() => {
      this.router.navigate(["/login"]);
    });
  }

  startReset(username) {
    return this.http.post("/api/auth/reset", { username });
  }

  async reset(form) {
    try {
      (await this.http.patch("/api/auth/reset", form).toPromise()) as any;
      this.toastr.success("Login with your new password.", "Success!", {
        closeButton: true,
        enableHtml: true,
      });
      this.logout();
    } catch (error) {
      this.toastr.warning(error.message || "Server Error", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
    }
  }

  getUsername() {
    return localStorage.getItem("username");
  }

  setUsername(username: string): void {
    localStorage.setItem("username", username);
    this.usernameSubject.next(username);
  }

  getUsernameObservable(): Observable<string> {
    return this.usernameSubject.asObservable();
  }

  hasPermission(permission: string): boolean {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    return permissions ? permissions.includes(permission) : false;
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if (!localStorage.getItem("username") || !localStorage.getItem("permissions")) {
      this.toastr.warning("Login is required", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });

      this.router.navigate(["/login"]);
      return false;
    } else if (route.data.permission && !this.hasPermission(route.data.permission)) {
      this.toastr.warning("Insufficient permissions", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });

      this.router.navigate(["/home"]);
      return false;
    } else {
      return true;
    }
  }
}
