<p-table *ngIf="rates && rates.length > 0" [value]="rates" [autoLayout]="true">
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Status</th>
      <th *ngIf="isEditing"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rate>
    <tr>
      <td>
        <a *ngIf="rate.rateType != 'minimums'" [routerLink]="['/rates', rate.rateType, rate.id]">
          {{ rate.name }}</a
        >
        <a *ngIf="rate.rateType == 'minimums'" [routerLink]="['/', rate.rateType, rate.id]">
          {{ rate.name }}</a
        >
      </td>
      <td>{{ rate.rateType }}</td>
      <td>{{ rate.isValid ? "ACTIVE" : "INACTIVE" }}</td>
      <td *ngIf="isEditing && rate.rateType != 'minimums'" style="width: 40px">
        <button
          pButton
          pRipple
          type="button"
          class="p-button-danger"
          icon="pi pi-trash"
          (click)="removeRate(rate)"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
<div *ngIf="isEditing" style="float: right; margin-top: 4px">
  <button
    pButton
    type="button"
    icon="pi pi-plus"
    class="p-button-success"
    data-toggle="modal"
    [attr.data-target]="'#addBillToModal' + index"
  ></button>
</div>

<div
  class="modal fade"
  [id]="'addBillToModal' + index"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addBillToModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" style="min-width: 800px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Rate</h5>
      </div>
      <hr />
      <form [formGroup]="ratesForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-sm-4 control-label">Rate Type</label>
            <div class="col-sm-8">
              <p-dropdown
                placeholder="Select the type of rate"
                [options]="types"
                formControlName="rateType"
                optionLabel="name"
                [style]="{ minWidth: '100%' }"
                (onChange)="onRateTypeSelected()"
                tabbableDropdown
              ></p-dropdown>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 control-label">Rate</label>
            <div class="col-sm-8">
              <p-multiSelect
                placeholder="Select a Rate"
                [options]="ratesList"
                formControlName="selectedRate"
                optionLabel="name"
                [style]="{ minWidth: '100%' }"
                required
                tabbableMultiselect
              >
              </p-multiSelect>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" #closebutton>
            Cancel
          </button>
          <button type="submit" class="btn btn-primary" [disabled]="ratesForm.invalid">OK</button>
        </div>
      </form>
    </div>
  </div>
</div>
