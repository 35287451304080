import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { ShipperService } from "./shipper.service";

@Injectable()
export class ShipperResolver {
  constructor(private shipperService: ShipperService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return [];
    // return this.shipperService.list();
  }
}
