import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { RatesService } from "@app/rates/shared/rates.service";
import { ToastrService } from "ngx-toastr";
import { SelectItem } from "primeng/api";
import { Permissions } from "@models/permissions.enum";
import { MinimumRateViewModel } from "@app/shared/models/rates/MinimumRateViewModel.model";

@Component({
  selector: "app-edit-daily-minimum",
  templateUrl: "./edit-daily-minimum.component.html",
  styleUrls: ["./edit-daily-minimum.component.css"],
})
export class EditDailyMinimumComponent implements OnInit {
  rate: MinimumRateViewModel = {
    name: "",
    description: "",
    validFromUtc: new Date(),
    guaranteePeriod: "DAILY",
    applyTo: "DRIVERS",
    drivers: [],
    clients: [],
    excludedChargeTypes: [],
  };

  isEditing: boolean = false;
  isCreating: boolean = false;
  canChange: boolean;

  chargeTypes: SelectItem[] = [];
  excludedChargeTypes: SelectItem[] = [];
  drivers: SelectItem[] = [];
  allBillTos: any[] = [];
  selectedDrivers = [];
  excludedChargeTypesPreview = [];

  guaranteePeriods: SelectItem[] = [{ label: "DAILY", value: "DAILY" }];
  guaranteeTypes: SelectItem[] = [
    { label: "PAY", value: "PAY" },
    { label: "MILES", value: "MILES" },
  ];
  applyToOptions: SelectItem[] = [
    { label: "DRIVERS", value: "DRIVERS" },
    { label: "CLIENTS", value: "CLIENTS" },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ratesService: RatesService,
    private authService: authenticationService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const rateId = params["id"];
      this.isCreating = params["id"] === "create";

      if (this.isCreating) {
        this.isEditing = true;
      } else {
        this.getRate(rateId);
      }
    });

    this.chargeTypes = this.route.snapshot.data["chargeTypes"];
    this.excludedChargeTypes = this.route.snapshot.data["chargeTypes"];
    this.drivers = this.route.snapshot.data["drivers"].map((driver) => ({
      label: driver.itemName,
      value: driver.id,
      disabled: !driver.isActive,
    }));
    this.allBillTos = this.route.snapshot.data["billToFullList"];

    this.canChange = this.hasPermission("BusinessRulesChange");
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  confirm() {
    if (this.isEditing) {
      return window.confirm("Leave this page without saving changes?");
    } else {
      return true;
    }
  }

  toggleEditing() {
    this.isEditing = !this.isEditing;
  }

  applyToChanged() {
    if (this.rate.applyTo === "DRIVERS") {
      this.rate.clients = [];
    } else {
      this.rate.drivers = [];
    }
  }

  getRate(rateId: string) {
    this.ratesService.getDailyMinimumRateById(rateId).subscribe((res) => {
      this.rate = res;
      this.excludedChargeTypesPreview = res.excludedChargeTypes;
      this.rate.validFromUtc = new Date(this.rate.validFromUtc);
      this.rate.validToUtc = this.rate.validToUtc ? new Date(this.rate.validToUtc) : null;
      this.rate.excludedChargeTypes = this.rate.excludedChargeTypes.map(
        (chargeType) => chargeType.id
      );
      this.rate.drivers = this.rate.drivers.map((driver) => ({
        ...driver,
        name: this.drivers.find((d) => d.value === driver.id).label,
        validFromUtc: new Date(driver.validFromUtc),
        validToUtc: driver.validToUtc ? new Date(driver.validToUtc) : null,
      }));
      this.rate.clients = this.rate.clients.map((client) => ({
        ...client,
        companyName: this.allBillTos.find((c) => c.id === client.id).companyName,
        validFromUtc: new Date(client.validFromUtc),
        validToUtc: client.validToUtc ? new Date(client.validToUtc) : null,
      }));
    });
  }

  createNewRate() {
    if (!this.validate()) return;
    const ratesObject = this.buildRatesObject();
    this.ratesService.createDailyMinimumRate(ratesObject).subscribe(
      (res: any) => {
        this.isEditing = false;
        this.toastr.success("Rate Created", "Success!");
        this.router.navigate([`/minimums/${res.id}`]);
      },
      (error) => {
        this.toastr.warning("error", "Alert!", {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
      }
    );
  }

  updateRate() {
    if (!this.validate()) return;
    const ratesObject = this.buildRatesObject();

    this.ratesService.updateDailyMinimumRate(ratesObject).subscribe(
      (res) => {
        this.toastr.success("Rate Updated", "Success!");
        this.toggleEditing();
        this.router.navigate(["/rates"]);
      },
      (error) => {
        this.toastr.warning("error", "Alert!", {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
      }
    );
  }

  copyRate() {
    if (confirm("Are you sure you want to copy this rate?")) {
      const ratesObject = this.buildRatesObject();
      ratesObject.name = "Copy of " + ratesObject.name;
      ratesObject.drivers = [];
      ratesObject.clients = [];
      delete ratesObject.id;
      this.sendCreatedRateToService(ratesObject);
    }
  }

  sendCreatedRateToService(ratesObject: MinimumRateViewModel) {
    this.ratesService.createDailyMinimumRate(ratesObject).subscribe(
      (res: any) => {
        this.toggleEditing();
        this.toastr.success("Rate Created", "Success!");
        this.router.navigate([`/minimums/${res.id}`]);
      },
      (error) => {
        this.toastr.warning("error", "Alert!", {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
      }
    );
  }

  buildRatesObject = () => {
    const ratesObject = JSON.parse(JSON.stringify(this.rate));
    ratesObject.excludedChargeTypes = this.rate.excludedChargeTypes.map((chargeTypeId) => ({
      id: chargeTypeId,
    }));
    return ratesObject;
  };

  deleteRate() {
    this.ratesService.deleteDailyMinimumRate(this.rate.id).subscribe(
      (res) => {
        this.toggleEditing();
        this.toastr.success("Rate Deleted", "Success!");
        this.router.navigate(["/rates"]);
      },
      (error) => {
        this.toastr.warning("error", "Alert!", {
          closeButton: true,
          enableHtml: true,
        });
        console.dir(error);
      }
    );
  }

  confirmDelete() {
    if (confirm("Are you sure you want to delete this rate?")) {
      this.deleteRate();
    }
  }

  validate = () => {
    if (!this.rate.name) {
      this.toastr.warning("Rate Name is required");
      return false;
    }
    if (!this.rate.chargeTypeId) {
      this.toastr.warning("Charge type is required");
      return false;
    }
    if (!this.rate.validFromUtc) {
      this.toastr.warning("Valid From is required");
      return false;
    }
    if (!this.rate.guaranteePeriod) {
      this.toastr.warning("Gurantee Period is required");
      return false;
    }
    if (!this.rate.guaranteeType) {
      this.toastr.warning("Pay or Miles is required");
      return false;
    }
    if (!this.rate.rate) {
      this.toastr.warning("Rate is required");
      return false;
    }
    const missingDriverValidFrom = this.rate.drivers.some((driver) => !driver.validFromUtc);
    if (missingDriverValidFrom) {
      this.toastr.warning("All Drivers must have a valid from date");
      return false;
    }
    const missingClientValidFrom = this.rate.clients.some((client) => !client.validFromUtc);
    if (missingClientValidFrom) {
      this.toastr.warning("All Clients must have a valid from date");
      return false;
    }
    return true;
  };
}
