<div class="col-md-12 text-center" class="btnSelectFile" align="center">
  <a href="javascript:void(0)" (click)="file.click()">
    <button type="button" class="btn btn-default" style="font-size: 12px">
      Select files to upload
    </button>
  </a>
  <br />
  <input type="file" #file [multiple]="maxFiles > 1" style="display: none" />
</div>
<div class="row error" *ngIf="errors.length > 0">
  <ul>
    <li *ngFor="let err of errors">{{ err }}</li>
  </ul>
</div>
