import { throwError as observableThrowError, forkJoin as observableForkJoin } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { Router } from "@angular/router";
import { HttpClient } from "../../../services/http/http-client.service";

@Injectable()
export class Documentervice {
  constructor(private router: Router, private _http: HttpClient) {}

  createDocuments(documents) {
    let observableArray = [];
    let responses = [];
    for (let index = 0; index < documents.length; index++) {
      let document = documents[index];
      for (let n = 0; n < document.files.length; n++) {
        if (document.files[n].deleted || document.files[n].id) continue;
        let doc = JSON.parse(JSON.stringify(document));
        doc.files = [document.files[n]];
        doc.fileName = doc.files[0].name;
        doc.parentId = doc.files[0].parentId;
        doc.documentNo = doc.files[0].documentNo;
        observableArray.push(
          this._http.post("/api/document/create", { document: doc })
        );
      }
    }
    return observableForkJoin(observableArray).pipe(
      map((results: Array<Response>) => {
        results.map((res) => {
          responses.push(res);
        });
        return responses;
      })
    );
  }

  deleteDocument(id) {
    return this._http.delete("/api/document/deleteDoc/" + id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server document deletion error")
      )
    );
  }

  updateDocument(document) {
    return this._http.patch("/api/document/update", { document: document });
  }

  findDocTypeByName(name) {
    return this._http.get("/api/docType/findByName/" + name);
  }

  findDocuments(docType, id) {
    return this._http.patch("/api/document/findDocs", { docType: docType, id: id });
  }
}
