
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '../../shared/services/http/http-client.service'


@Injectable()
export class ClientProductService {

    constructor(private router: Router, private _http: HttpClient) {
    }

    getClientProductList() {
        return this._http.post('api/clientProduct/list', {}).pipe(
            map((res: any) => {
                let client = res;
				client.map((client: any) =>
					client.itemName = client.companyName
				);
                return client;
            }),
            catchError((error: any) => observableThrowError(error.message|| 'Server error')),);

    }

    updateClientProduct(clientid,newClientProductsList=[],newClientProductsFilePassList={}) {
        return this._http.patch('api/clientProduct/update', { clientid,newClientProductsList,newClientProductsFilePassList }).pipe(
            map(res => {
                return res;
            }),
            catchError((error: any) => observableThrowError(error.message || 'Server error')),);
    }

    deleteClientProduct(clientProductId) {
        return this._http.patch('api/clientProduct/delete', { clientProductId }).pipe(
            map(res => {
                return res;
            }),
            catchError((error: any) => observableThrowError(error.message || 'Server error')),);
    }

    createClientProduct(clientId, productId, docName) {
        return this._http.patch('api/clientProduct/create', { clientId, productId, docName }).pipe(
            map(res => {
                return res;
            }),
            catchError((error: any) => observableThrowError(error.message || 'Server error')),);
    }

}
