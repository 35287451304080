import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ClientProductService } from "../shared/clientProduct.service";
import { GetPictureService } from "../../../app/shared/services/files/getPictures.service";
import { FileUploadService } from "../../../app/shared/services/files/fileUpload.service";
import { ProductService } from "../../product/shared/products.service";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "app-clientproductlist",
  templateUrl: "./clientproductlist.component.html",
  styleUrls: ["./clientproductlist.component.css"],
})
export class ClientProductListComponent implements OnInit {
  errorMsg: any;
  clientList: any = [];
  colsClients: any = [];
  filterElements: any = [];
  allRecord: any;
  availableProducts = [];
  docType: string;
  selClient: any;
  newProductFile: any;
  newProductId: any;
  error: string;

  @ViewChild("inputFile", { static: true }) inputFile: ElementRef;
  constructor(
    private _clientProductList: ClientProductService,
    private getPictureService: GetPictureService,
    private fileUploadService: FileUploadService,
    private productsService: ProductService,
    private _AuthService: authenticationService
  ) {}

  ngOnInit() {
    this.error = "";
    this.docType = "clientproduct";

    this.colsClients = [
      { field: "customerNo", header: "Client Id" },
      { field: "companyName", header: "Company Name" },
      { field: "products", header: "Products" },
    ];

    this.availableProducts = [];
    this.productsService.list().subscribe((result: any) => {
      for (let i = 0; i < result.length; i++) {
        let product = result[i];
        this.availableProducts.push({
          label: product.name + ": " + product.un,
          value: product.id,
        });
      }
    });

    this.refreshProducts();
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  refreshProducts() {
    this._clientProductList.getClientProductList().subscribe(
      (resClientListData) => {
        this.clientList = resClientListData;
      },
      (resClientListError) => {
        this.errorMsg = resClientListError;
      }
    );
  }

  viewDetail(client) {
    this.selClient = client;
    this.clearModalFields();
  }

  deleteClientProduct(client, clientProduct) {
    this._clientProductList.deleteClientProduct(clientProduct.id).subscribe((data) => {
      for (let i = 0; i < client.ClientProducts.length; i++) {
        if (client.ClientProducts[i] == clientProduct) {
          delete client.ClientProducts[i];
        }
      }
    });
  }

  clearModalFields() {
    this.onChange(null);
    this.newProductId = "";
    this.error = "";
  }

  onChange(event) {
    this.inputFile.nativeElement.value = "";
    this.newProductFile = null;
  }

  addClientProduct() {
    let client = this.clientList.find((client) => client.id == this.selClient.id);
    let clientProduct = client.ClientProducts.find((ClientProduct) => ClientProduct.Product && ClientProduct.Product.id === this.newProductId);
    if (clientProduct) {
      this.error =
        "Product " +
        clientProduct.Product.name +
        ": " +
        clientProduct.Product.un +
        " is already associated with this client.";
      return;
    }

    let newName = null;
    if (this.newProductFile && this.newProductFile.name.length) {
      newName = (
        client.id +
        "_" +
        this.newProductId +
        "_" +
        this.newProductFile.name
      ).toUpperCase();
      this.fileUploadService
        .uploadSingleFileRename([this.newProductFile], this.docType, newName)
        .subscribe((result: any) => {
          newName = result.name;

          this._clientProductList
            .createClientProduct(client.id, this.newProductId, newName)
            .subscribe((data) => {
              this.refreshProducts();
            });
          this.error = "";
          this.clearModalFields();
        });
    } else {
      this._clientProductList
        .createClientProduct(client.id, this.newProductId, newName)
        .subscribe((data) => {
          this.refreshProducts();
        });
      this.error = "";
      this.clearModalFields();
    }
  }

  getTemporalImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.newProductFile = event.target.files[0];
    }
  }

  getFilePath(path) {
    return this.getPictureService.getPicturePath(path, this.docType);
  }
}
