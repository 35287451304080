<div class="container">
  <div class="col-md-12">
    <legend>Equipment Code List</legend>
    <div class="btnRig">
      <a (click)="viewDetail(null)">
        <button
          id="edit"
          name="edit"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          *ngIf="hasPermission('EquipmentCodesChange')"
        >
          Add New Equipment Code
        </button>
      </a>
    </div>
    <div style="display: inline-block">
      <p-table [value]="equipmentList" [autoLayout]="true">
        <ng-template pTemplate="header">
          <tr>
            <th>Equipment Code</th>
            <th>Products</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-equipment>
          <tr>
            <td>{{ equipment.name }}</td>
            <td>
              <div style="display: table-cell; vertical-align: middle">
                <div *ngFor="let equip of equipment.Equipment">
                  <div *ngIf="equip.Product">
                    {{ equip.Product.name }}: {{ equip.Product.un }}
                    <a
                      (click)="deleteProduct(equipment, equip)"
                      *ngIf="hasPermission('EquipmentCodesChange')"
                    >
                      <i
                        class="pi pi-trash"
                        styleClass="btn"
                        style="cursor: pointer"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div style="display: table-cell; vertical-align: middle">
                <span style="display: block">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#associateModal"
                    (click)="viewDetail(equipment)"
                    class="btn btnDetails"
                    *ngIf="hasPermission('EquipmentCodesChange')"
                  >
                    Associate Product
                  </button>
                  <a (click)="viewDetail(equipment)">
                    <i
                      class="pi pi-pencil"
                      styleClass="btn"
                      style="cursor: pointer"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      *ngIf="hasPermission('EquipmentCodesChange')"
                    ></i>
                  </a>
                  <a (click)="delete(equipment)">
                    <i
                      class="pi pi-trash"
                      styleClass="btn"
                      style="cursor: pointer"
                      *ngIf="hasPermission('EquipmentCodesChange')"
                    ></i>
                  </a>
                </span>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<!-- Equipment Code Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{
            btn == "Update"
              ? "Edit Equipment Code: " + selEquipment.origName
              : "Add Equipment Code"
          }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="modalError.length" class="alert alert-block alert-danger">
          {{ modalError }}
        </div>
        <div>
          <div class="form-group">
            <label class="control-label">Equipment Code</label>
            <input
              id="name"
              name="name"
              type="text"
              [(ngModel)]="selEquipment.name"
              placeholder="Insert Equipment Code"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="modalElem btn btn-primary"
          (click)="editOn()"
          [attr.data-dismiss]="!modalError.length ? 'modal' : ''"
        >
          {{ btn }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Associate Modal -->
<div
  class="modal fade"
  id="associateModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="associateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="associateModalLabel">Associate Product</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="modalError.length" class="alert alert-block alert-danger">
          {{ modalError }}
        </div>
        <h5>{{ selEquipment.name }}</h5>
        <div>
          <p-dropdown
            #productDropDown
            [showTransitionOptions]="'0ms'"
            [hideTransitionOptions]="'0ms'"
            [style]="{ width: '83%' }"
            [options]="availableProducts"
            [(ngModel)]="newProductId"
            id="productSelect"
            placeholder="Select a Product"
            tabbableDropdown
          ></p-dropdown>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button
          [disabled]="!newProductId"
          type="button"
          class="modalElem btn btn-primary"
          (click)="addProduct()"
          [attr.data-dismiss]="!modalError.length ? 'modal' : ''"
        >
          Add product
        </button>
      </div>
    </div>
  </div>
</div>
<script-hack
  >$('.modal-content').draggable({{ "{" }}'handle':'.modal-header'{{
    "}"
  }});</script-hack
>
