import { Injectable } from '@angular/core';


@Injectable()
export class SelectsService {

    keysPressed = {};

    constructor () {
        window.onkeyup = (e)=> { 
            this.keysPressed[e.keyCode] = false; 
        }
        window.onkeydown = (e)=> { 
            this.keysPressed[e.keyCode] = true; 
        }
    }
    handleSelection(selectId){
        const isCtrlPressed = this.keysPressed['17']
        if(!isCtrlPressed){
            document.getElementById(selectId).click();
        }
    }

}
