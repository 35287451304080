import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { SettlementsService } from "@app/settlements/shared/settlements.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-import-deductions",
  templateUrl: "./import-deductions.component.html",
  styleUrls: ["./import-deductions.component.css"],
})
export class ImportDeductionsComponent implements OnInit {
  @ViewChild("closebutton") closebutton: any;

  dragAreaClass: String = "dragarea";
  importedFile: File;
  parsedResults: any;
  cols: any[] = [];
  errCols: any[] = [];
  rows: any[] = [];
  errors: any[] = [];
  warnings: any[] = [];
  errorRows: any[] = [];
  deductionsOrder: string[] = [];

  @Input() batch: any;
  @Output() onImported = new EventEmitter();

  constructor(
    private settlementsService: SettlementsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  onFileChange(event: Event & { target: HTMLInputElement }) {
    const files = event.target.files;

    if (!this.validateExtension(files)) return;

    this.setImportedFile(files[0]);
    event.target.value = "";
  }

  setImportedFile(file: File) {
    this.importedFile = file;
    this.uploadFiles();
  }

  validateExtension(files: FileList) {
    const file = files[0];
    if (!file) return false;
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension != "csv") {
      this.toastr.warning("Only .csv files are allowed", "Warning");
      return false;
    }
    return true;
  }

  closeModal() {
    this.closebutton.nativeElement.click();
  }

  deleteFile() {
    this.importedFile = null;
  }

  @HostListener("dragover", ["$event"]) onDragOver(event) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }

  @HostListener("dragenter", ["$event"]) onDragEnter(event) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }

  @HostListener("dragend", ["$event"]) onDragEnd(event) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }

  @HostListener("dragleave", ["$event"]) onDragLeave(event) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    var files = event.dataTransfer.files;
    if (!this.validateExtension(files)) return;
    this.setImportedFile(files[0]);
  }

  async uploadFiles() {
    // read this.importedFile as a string
    const text = await this.importedFile.text();
    const lines = text.split("\n");

    const header = lines[0].split(",").map((column) => column.trim().toUpperCase());
    // merge lines into a single string
    const newLines = [header.join(","), ...lines.slice(1)];
    const parsedText = newLines.join("\n");

    const columns = [
      { header: "Errors", field: "errors", width: 380 },
      { header: "Warnings", field: "warnings", width: 380 },
      { header: "ID", field: "driverId", width: 200 },
      { header: "Total", field: "TOTAL", width: 100 },
      { header: "ESCBB", field: "ESCBB", width: 120 },
      { header: "ESCADD", field: "ESCADD", width: 100 },
      { header: "ESCSUB", field: "ESCSUB", width: 100 },
      { header: "ESCINT", field: "ESCINT", width: 120 },
      { header: "ESCNET", field: "ESCNET", width: 120 },
      { header: "ESCEB", field: "ESCEB", width: 120 },
      { header: "DEFBB", field: "DEFBB", width: 120 },
      { header: "DEFADD", field: "DEFADD", width: 100 },
      { header: "DEFSUB", field: "DEFSUB", width: 100 },
      { header: "DEFNET", field: "DEFNET", width: 100 },
      { header: "DEFEB", field: "DEFEB", width: 100 },
    ];

    this.settlementsService.parseDeductions(this.batch.batchNo, parsedText).subscribe({
      next: (res: any) => {
        const { errors, warnings, rows } = res;
        this.parsedResults = res;
        this.errors = errors;
        this.warnings = warnings;

        const importedColumns = Object.keys(rows[0]);
        // reorder the deduction columns to match the order they were imported in
        const orderedDeductionColumns = header
          .filter((column) => importedColumns.includes(column))
          .filter((column) => !columns.some((col) => col.field === column))
          .map((column) => ({ header: column, field: column }));

        this.deductionsOrder = orderedDeductionColumns.map((col) => col.header);
        this.errCols = [...columns, ...orderedDeductionColumns];
        this.cols = [...this.errCols].slice(2);
        this.rows = rows.filter((row) => row.errors.length === 0 && row.warnings.length === 0);
        this.errorRows = rows.filter((row) => row.errors.length > 0 || row.warnings.length > 0);

        this.cdr.markForCheck();
      },
      error: (error) => {
        console.error(error);
        this.toastr.error(error);
      },
    });
  }

  cancel() {
    this.parsedResults = null;
    this.cols = [];
    this.rows = [];
  }

  import() {
    // import all rows, and error rows that just have a warning (but no error)
    const rowsToImport = [...this.rows, ...this.errorRows.filter((row) => row.errors.length === 0)];
    this.settlementsService
      .importDeductions(this.batch.batchNo, rowsToImport, this.deductionsOrder)
      .subscribe({
        next: (res: any) => {
          this.toastr.success("Imported deductions");
          this.parsedResults = null;
          this.cols = [];
          this.rows = [];
          this.importedFile = null;
          this.closeModal();
          this.onImported.emit();

          this.cdr.markForCheck();
        },
        error: (error) => {
          console.error(error);
          this.toastr.error(error);
        },
      });
  }
}
