import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { HttpClient } from "../../shared/services/http/http-client.service";

@Injectable()
export class TrailerService {
  constructor(private router: Router, private _http: HttpClient) {}

  gettrailerList(offset, limit, filter, order) {
    return this._http.post("/api/trailer/list", { offset, limit, filter, order }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  findAndFilter() {
    return this._http.post("/api/trailer/findAndFilter", {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  listTypes() {
    return this._http.get("/api/trailer/listTypes").pipe(
      map((res: any) => {
        let types = res;
        for (let i = 0; i < types.length; i++) {
          types[i].itemName = types[i].equipmentCode;
        }
        return types;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  updateTrailer(trailer) {
    return this._http.patch("/api/trailer/update", { trailer }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  listSelectFormat() {
    return this._http.post("/api/trailer/list", {}).pipe(
      map((res) => {
        let trailers = res;
        return trailers;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  gettrailerinfo(uuid) {
    return this._http.get("/api/trailer/find/" + uuid).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  createNewTrailer(trailer) {
    return this._http.post("/api/trailer/create", { trailer: trailer }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server error cant create trailer")
      )
    );
  }
  getProductsByTrailer(trailerNumber, trailers, products) {
    const trailer = trailers.find((trailer) => trailer.trailerNo === trailerNumber);
    if (!trailer) {
      return products;
    }
    const trailerType = trailer.equipmentCode;
    switch (trailerType) {
      case "CO2":
        return products.filter((product) => product.name === "CO2");
      case "ALUMINUM":
        return products.filter(
          (product) => product.name === "NITROGEN" || product.name === "ARGON"
        );
      case "STEEL":
        return products.filter(
          (product) =>
            product.name === "ARGON" || product.name === "NITROGEN" || product.name === "OXYGEN"
        );

      case "SHIPPER":
        return products;
      default:
        return products;
    }
  }

  delete(trailer) {
    return this._http.delete("/api/trailer/delete/" + trailer.uuid).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Error deleting trailer"))
    );
  }
}
