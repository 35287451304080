<div class="container">
  <div class="row">
    <div class="col-sm-9">
      <div class="tripHeaderTitle">
        <p class="TripHeader">Trailer/ Container/ Chassis</p>
        <hr />
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="company">Trailer Number</label>
        <div class="col-md-3">
          <input
            id="manufactureSerialNo"
            name="manufactureSerialNo"
            type="text"
            placeholder="Insert trailer No"
            class="form-control input-md"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.trailerNo"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="homeLocation">Terminal Location</label>
          <div class="col-md-3">
            <select
              class="selectState"
              id="terminalLocation"
              name="terminalLocation"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.terminalLocation"
            >
              <option *ngFor="let terminal of terminals" [ngValue]="terminal">
                {{ terminal }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="trailerType">Trailer Type</label>
        <div class="col-md-3 inputSpace">
          <select
            class="selectEquipment"
            type="text"
            id="trailerType"
            name="trailerType"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.trailerType"
          >
            <option value="CHASIS" selected>Chasis</option>
            <option value="TRAILER">Trailer</option>
            <option value="CONTAINER">Container</option>
          </select>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="active">Active</label>
          <div class="col-md-3 inputSpace">
            <p-checkbox
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.active"
              binary="true"
            ></p-checkbox>
          </div>
        </div>
      </div>
      <hr />
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="company">Trailer Year</label>
        <div class="col-md-3 inputSpace">
          <input
            id="year"
            name="year"
            type="text"
            placeholder="Insert trailer year"
            class="form-control input-md"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.year"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="company"> Date Purchased </label>
          <div class="col-md-3 inputSpace">
            <input
              id="purchasedDateInput"
              name="puchaseDate"
              type="text"
              [inputMask]="dateInputMask"
              autocomplete="off"
              placeholder="mm/dd/yyyy"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.purchasedDate"
            />
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="make">Trailer Make</label>
        <div class="col-md-3 inputSpace">
          <input
            id="make"
            name="make"
            type="text"
            placeholder="Insert trailer manufacturer"
            class="form-control input-md"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.make"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="company">Trailer Price</label>
          <div class="col-md-3 inputSpace">
            <input
              id="price"
              name="price"
              type="text"
              placeholder="Insert trailer price"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.price"
            />
          </div>
        </div>
      </div>

      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="company">Model</label>
        <div class="col-md-3 inputSpace">
          <input
            id="model"
            name="model"
            type="text"
            placeholder="Insert trailer model"
            class="form-control input-md"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.model"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Length</label>
          <div class="col-md-3 inputSpace">
            <input
              id="length"
              name="length"
              type="text"
              placeholder="Insert trailer length"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.length"
            />
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Height</label>
          <div class="col-md-3 inputSpace">
            <input
              id="height"
              name="height"
              type="text"
              placeholder="Insert trailer height"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.height"
            />
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="radios">State of registration</label>
        <div class="col-md-3">
          <select
            class="selectState"
            id="stateOfRegistration"
            name="stateOfRegistration"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.stateOfRegistration"
          >
            <option *ngFor="let state of states" [ngValue]="state.abbreviation">
              {{ state.fullName }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Weight</label>
          <div class="col-md-3 inputSpace">
            <input
              id="weight"
              name="weight"
              type="text"
              placeholder="Insert trailer weight"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.weight"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-3 control-label" for="radios">License Number</label>
        <div class="col-md-3">
          <input
            id="licenseNumber"
            name="licenseNumber"
            type="text"
            placeholder="Insert License #"
            class="form-control input-md"
            [(ngModel)]="trailerInfo.licenseNumber"
            [disabled]="is_edit"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="axles">Axles</label>
          <div class="col-md-3 inputSpace">
            <input
              id="axles"
              name="axles"
              type="text"
              [disabled]="is_edit"
              placeholder="Insert Trailer Axles"
              class="form-control input-md"
              [(ngModel)]="trailerInfo.axles"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-3 control-label" for="radios">License Expiration</label>
        <div class="col-md-3">
          <input
            id="licenseExpirationDate"
            name="licenseExpirationDate"
            aria-label="licenseExpirationDate"
            type="text"
            [inputMask]="dateInputMask"
            autocomplete="off"
            placeholder="mm/dd/yyyy"
            class="form-control input-md"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.licenseExpirationDate"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Tire Size</label>
          <div class="col-md-3 inputSpace">
            <input
              id="tireSize"
              name="tireSize"
              type="text"
              placeholder="Insert tire size"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.tireSize"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-3 control-label" for="company">Equipment Code</label>
        <div class="col-md-3 inputSpace">
          <select
            class="selectEquipment"
            type="text"
            id="equipmentCode"
            name="equipmentCode"
            [disabled]="is_edit"
            [(ngModel)]="equipmentCodeSelected"
            (change)="equipmentCodeSelectedChanged()"
          >
            <option [ngValue]="null" disabled>--Select one--</option>
            <option *ngFor="let equ of equipmentCodes" [ngValue]="equ">
              {{ equ.equipmentCode }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Engine Hours</label>
          <div class="col-md-3 inputSpace">
            <input
              id="engineHours"
              name="engineHours"
              type="text"
              placeholder="Insert engine hours"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.engineHours"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-group">
          <label class="col-md-3 control-label" for="vinNo">Vin Number</label>
          <div class="col-md-3 inputSpace">
            <input
              id="vinNo"
              name="vinNo"
              type="text"
              placeholder="Insert vin number"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.vinNo"
            />
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <div class="form-group" class="insuranceInput">
          <label class="col-md-3 control-label" for="radios">Insurance Policy Number</label>
          <div class="col-md-3 inputSpace">
            <input
              id="insurancePolicyNumber"
              [disabled]="is_edit"
              name="insurancePolicyNumber"
              type="text"
              placeholder="Insert insurance policy number"
              class="form-control input-md"
              [(ngModel)]="trailerInfo.insurancePolicyNumber"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Insurance Name</label>
          <div class="col-md-3 inputSpace">
            <input
              id="insuranceName"
              name="insuranceName"
              type="text"
              placeholder="Insert insurance name"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.insuranceName"
            />
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="currentLocationState">Current Loc State</label>
        <div class="col-md-3">
          <select
            class="selectState"
            id="currentLocationState"
            name="currentLocationState"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.currentLocationState"
          >
            <option *ngFor="let state of states" [ngValue]="state.abbreviation">
              {{ state.fullName }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="currentLocationCity">Current Loc City</label>
          <div class="col-md-3">
            <input
              id="currentLocationCity"
              name="currentLocationCity"
              type="text"
              placeholder="Insert current location city"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.currentLocationCity"
            />
          </div>
        </div>
      </div>
      <!-- Text input
  <div class="form-group">
    <label class="col-md-3 control-label" for="currentLocationAddress">current Location Address</label>
    <div class="col-md-3 inputSpace">
      <input id="currentLocationAddress" name="currentLocationAddress" type="text" placeholder="Insert current location address"
        class="form-control input-md" [(ngModel)]="trailerInfo.currentLocationAddress">
    </div>
    <div class="form-group">
      <label class="col-md-3 control-label" for="currentLocationZip">Current Location Zip</label>
      <div class="col-md-3 inputSpace">
        <input id="currentLocationZip" name="currentLocationZip" type="text" placeholder="Insert current location zip" class="form-control input-md"
          [(ngModel)]="trailerInfo.currentLocationZip">
      </div>
    </div>
  </div>
  <Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="serial">Trailer Serial</label>
        <div class="col-md-3 inputSpace">
          <input
            id="serial"
            name="serial"
            type="text"
            placeholder="Insert serial"
            class="form-control input-md"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.serial"
          />
        </div>
        <div class="form-group">
          <label class="col-md-3 control-label" for="radios">Trailer Owner</label>
          <div class="col-md-3 inputSpace">
            <input
              id="owner"
              name="owner"
              type="text"
              placeholder="Insert "
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.owner"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div *ngIf="trailerInfo.equipmentCode == 'STEEL'" class="selectType">
          <label class="col-md-3 control-label" for="equipmentCodeSteelType">Steel type</label>
          <div class="col-md-3 inputSpace">
            <select
              class="selectEquipment"
              type="text"
              id="equipmentCodeSteelType"
              name="equipmentCodeSteelType"
              [disabled]="is_edit"
              [(ngModel)]="trailerInfo.equipmentCodeSteelType"
            >
              <option value="LOX" selected>LOX</option>
              <option value="LIN">LIN</option>
              <option value="LAR">LAR</option>
            </select>
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label">UN numbers:</label>
        <ul class="col-md-3">
          <li *ngFor="let unNumber of equipmentCodeSelected.un; let i = index">
            {{ equipmentCodeSelected.name[i] }}: {{ unNumber }}
          </li>
        </ul>
      </div>
      <br />
      <hr />
      <div class="form-group">
        <label class="col-md-3 control-label">Mobile device type</label>
        <div class="col-md-3 inputSpace">
          <select
            class="selectEquipment"
            type="text"
            id="type"
            name="type"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.mobileDeviceType"
          >
            <option value="PHONE" selected>Phone</option>
            <option value="TABLET">Tablet</option>
            <option value="DSN">DSN</option>
          </select>
        </div>
      </div>
      <br />
      <!-- Text input-->
      <div class="form-group">
        <label class="col-md-3 control-label" for="radios">Mobile Device Number</label>
        <div class="col-md-3 inputSpace">
          <input
            id="mobileDeviceNumber"
            name="mobileDeviceNumber"
            type="text"
            placeholder="mobile device number"
            class="form-control input-md"
            [disabled]="is_edit"
            [(ngModel)]="trailerInfo.mobileDeviceNumber"
          />
        </div>
      </div>
    </div>
    <div class="col-sm-3" align="center">
      <div>
        <img
          *ngIf="trailerPicture"
          class="img-responsive"
          style="margin-top: 89px"
          [src]="trailerPicture"
          [appGetFile]="trailerPicture"
          [appPreviewFile]="true"
        />
        <div class="form-group">
          <input
            [disabled]="is_edit"
            type="file"
            #trailerPictureComponent
            (change)="getTemporalImage($event)"
            placeholder="Upload file..."
          />
          <br />
          <label class="col-md-3 control-label" for="company">upload Docs</label>
          <div class="col-md-3">
            <createDocument #trailerDocuments [disableInputs]="is_edit"></createDocument>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
  <div class="row" class="bottom-btns">
    <div class="col-sm-6" align="right">
      <button
        *ngIf="hasPermission('TrailersChange')"
        type="button"
        [disabled]="isProcessing"
        class="btn btn-warning createBtn"
        (click)="editOn(trailerInfo)"
      >
        {{ btnProfile }}
      </button>
    </div>
    <div class="col-sm-6" align="left">
      <button *ngIf="!is_edit" type="button" class="btn btn-secondary" (click)="cancelEdit()">
        Cancel
      </button>
    </div>
    <br />
  </div>
</div>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '50vw' }"
></p-confirmDialog>
