import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { TcheckService } from "../../../tcheck/tcheck.service";
import { ToastrService } from "ngx-toastr";
import { TchekTablesService } from "@app/tcheck/tchek-tables.service";

@Component({
  selector: "app-table-driver-expenses",
  templateUrl: "./table-driver-expenses.component.html",
  styleUrls: ["./table-driver-expenses.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDriverExpensesComponent implements OnInit, OnChanges {
  @Input() records!: any;
  @Output() onRecordsSaved = new EventEmitter<any>();

  cols: any[];
  totals: any[];

  constructor(
    private toastr: ToastrService,
    private tcheckService: TcheckService,
    private tchekTables: TchekTablesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.cols = this.tchekTables.driverExpensesCols;
  }

  ngOnChanges() {
    this.cols = this.tchekTables.driverExpensesCols;
    this.updateTotals();
  }

  updateTotals() {
    this.totals = this.tchekTables.calculateTotals(this.cols, this.records);
  }

  onRecordChanged(newValue: any, record: any, field: string) {
    const oldValue = record[field];
    // if this is the first time the record has been changed, log its original value
    const key = `${field}OriginalValue`;
    if (record[key] === undefined) record[key] = oldValue;
    // mark if the record has been changed
    record[`${field}Changed`] = record[key] !== newValue;
    // mark the record as one we want to update when we save
    record.softUpdate = true;

    // set the new value
    record[field] = newValue;

    this.updateTotals();
  }

  updateRecords() {
    const updatedRecords = this.records.filter((record) => record.softUpdate);

    for (const record of updatedRecords) {
      if (!record.authorizationCode.startsWith("A")) record.card = "0";
    }

    this.tcheckService.updateExpenses(updatedRecords).subscribe(
      () => {
        this.toastr.success("Expenses updated", "Success");
        this.records = this.records.filter((record) => !record.isDriverTransaction);
        this.onRecordsSaved.emit();
        this.cdr.markForCheck();
      },
      (error: string) => this.toastr.error(error)
    );
  }

  deleteRecord(record: any) {
    if (confirm("Are you sure you want to delete this record?")) {
      this.tcheckService.deleteRecord(record.id).subscribe(
        () => {
          this.toastr.success("Expense deleted", "Success");
          this.records = this.records.filter((r) => r.id != record.id);

          this.updateTotals();
          this.cdr.markForCheck();
        },
        () => this.toastr.error("Error deleting expense")
      );
    }
  }

  openDriverSettlement(driverID: string, batchNo: number) {
    const url = `/settlements/loads/${driverID}?batchNo=${batchNo}&tab=Fuel`;
    window.open(url, "_blank");
  }
}
