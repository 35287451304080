import { throwError as observableThrowError } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "../../shared/services/http/http-client.service";
import { ClientViewModel } from "../../shared/models/clients/ClientViewModel.model";
import { BillToViewModel } from "@app/shared/models/clients/BillToViewModel.model";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class clientService {
  constructor(private _http: HttpClient, private toastr: ToastrService) {}

  getClientList(validOnly = true, includeDeleted = false) {
    return this._http.post("api/client/list", { validOnly, includeDeleted }).pipe(
      map((res: any) => {
        let client = res;
        client.map((client) => (client.itemName = client.companyName));
        return client;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getClientInfo(id: string) {
    return this._http.get("api/client/find/" + id).pipe(
      map((res: ClientViewModel) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  createAddClient(newClient: any, billToList = null) {
    return this._http.post("api/client/create", { client: newClient, billToList }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || error.Message || "Server error")
      )
    );
  }

  updateClient(client: any, newBillToList = [], editedBillToList = [], removedBillToList = []) {
    return this._http
      .patch("api/client/update", {
        client,
        newBillToList,
        editedBillToList,
        removedBillToList,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) =>
          observableThrowError(error.message || error.Message || "Server error")
        )
      );
  }

  clientListDropdown() {
    return this._http.get("api/client/listDropDown").pipe(
      map((res: any) => {
        let clients = res;
        clients.map(
          (client: any) => (client.itemName = client.customerNo + "  " + (client.companyName || ""))
        );
        return clients;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  parentClientListDropdown(validOnly = true, includeDeleted = false) {
    return this._http.post("api/client/list", { validOnly, includeDeleted }).pipe(
      map((res: any) => {
        let clients = res;
        clients.map(
          (client: any) => (client.itemName = client.customerNo + "  " + (client.companyName || ""))
        );
        return clients;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  clientListChildren(parentId) {
    return this._http.get("api/client/listChildren/" + parentId).pipe(
      map((res: any) => {
        let clients = res;
        clients.map(
          (client) => (client.itemName = client.customerNo + "  " + (client.companyName || ""))
        );
        return clients;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  listAllBillTo() {
    return this._http.get("api/client/listAllBillTo/").pipe(
      map((res: any) => {
        let clients = res;
        clients.map(
          (client) => (client.itemName = client.customerNo + "  " + (client.companyName || ""))
        );
        return clients;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  deleteClient(clientId: string | number) {
    return this._http.delete("api/client/delete/" + clientId).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Error deleting client"))
    );
  }

  restoreClient(clientId: string | number) {
    return this._http.get("api/client/restore/" + clientId).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  validateBillTo(billTo: BillToViewModel) {
    const requiredFields = [
      "companyName",
      "validFrom",
      "address",
      "city",
      "state",
      "zip",
      "invoiceContent",
      "invoiceEmail",
      "customerNo",
    ];

    const displayNames = {
      companyName: "Name",
      validFrom: "Valid From",
      address: "Address",
      city: "City",
      state: "State",
      zip: "ZIP",
      invoiceContent: "Invoice Content",
      invoiceEmail: "Invoice Email",
      customerNo: "Bill To ID",
    };

    const warnings = [];
    for (const field of requiredFields) {
      if (!billTo[field]) {
        warnings.push(`Bill To ${billTo?.companyName ?? ""} ${displayNames[field]} required`);
      }
    }

    if (billTo.customerNo && billTo.customerNo.trim().length < 4) {
      warnings.push(`Bill To ${billTo?.companyName ?? ""} ID must be at least 4 characters`);
    }

    if (billTo.validFrom && billTo.validTo) {
      if (billTo.validFrom >= billTo.validTo) {
        warnings.push(`${billTo?.companyName ?? ""} Valid To date must be after Valid From`);
      }
    }

    if (warnings.length > 0) {
      const orderedWarnings = warnings.reverse();
      for (const warning of orderedWarnings) {
        this.toastr.warning(warning);
      }
      return false;
    }
    return true;
  }
}
