import { throwError as observableThrowError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient as _HttpClient, HttpHeaders } from "@angular/common/http";
import { SpinnerService } from "../../../shared/services/spinner/spinner.service";
import { ToastrService } from "ngx-toastr";
import { CapitalLatterService } from "../capital-latter/capital-latter.service";
import { ConfirmationGuard } from "@app/shared/guards/confirmation/confirmation.guard";

@Injectable()
export class HttpClient {
  public token: String;
  loader: any;

  isLoadingOpen: Boolean = false;
  constructor(
    private router: Router,
    private httpClient: _HttpClient,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _capitalService: CapitalLatterService,
    private _confirmationGuard: ConfirmationGuard
  ) {}

  showLoading() {
    if (!this.isLoadingOpen) {
      this.isLoadingOpen = true;
      this.loader.present();
    }
  }

  hideLoading() {
    this.loader.dismiss();
    this.isLoadingOpen = false;
  }

  castCapitalLatter(data) {
    return this._capitalService.castCapitarLatter(data);
  }

  handleError(error) {
    if (error.status === 401) {
      this.toastr.warning("Login is required", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });

      this._confirmationGuard.skipConfirmation = true;
      this.router.navigate(["/login"]);
      this.spinner.forceHide();
    } else if (error.status === 403) {
      this.toastr.warning("Insufficient permissions", "Alert!", {
        closeButton: true,
        enableHtml: true,
      });
    }
  }

  get<T>(url: string, showSpinner = true) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    if (showSpinner) {
      this.spinner.show();
    }
    return this.httpClient.get(url).pipe(
      map((result: T) => {
        if (showSpinner) this.spinner.hide();
        return result;
      }),
      catchError((e) => {
        this.spinner.hide();
        this.handleError(e);
        return observableThrowError(e.message || "Server error");
      })
    );
  }

  post<T>(url: string, data: any, responseType = null, showSpinner = true) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    if (showSpinner) {
      this.spinner.show();
    }
    let options: any = {};
    options.headers = headers;
    if (responseType !== null) {
      options.responseType = responseType;
    }
    return this.httpClient.post<any>(url, data, options).pipe(
      map((result: any) => {
        if (showSpinner) this.spinner.hide();
        return result as T;
      }),
      catchError((e) => {
        this.spinner.hide();
        this.handleError(e);
        return observableThrowError(e.error || "Server error");
      })
    );
  }

  patch<T>(url: string, data: any, showSpinner = true) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    if (showSpinner) {
      this.spinner.show();
    }
    return this.httpClient
      .patch(url, data, {
        headers: headers,
      })
      .pipe(
        map((result) => {
          if (showSpinner) {
            this.spinner.hide();
          }
          return result as T;
        }),
        catchError((e) => {
          this.spinner.hide();
          this.handleError(e);
          return observableThrowError(e.error || "Server error");
        })
      );
  }

  put<T>(url: string, data: any, isUpload = false, showSpinner = true) {
    let headers = new HttpHeaders();
    if (!isUpload) {
      headers = headers.append("Content-Type", "application/json");
    }
    if (showSpinner) {
      this.spinner.show();
    }
    return this.httpClient
      .put(url, data, {
        headers: headers,
      })
      .pipe(
        map((result) => {
          if (showSpinner) {
            this.spinner.hide();
          }
          return result as T;
        }),
        catchError((e) => {
          this.spinner.hide();
          this.handleError(e);
          return observableThrowError(e.error || "Server error");
        })
      );
  }

  delete<T>(url: string, showSpinner = true) {
    let headers = new HttpHeaders();
    if (showSpinner) {
      this.spinner.show();
    }
    headers = headers.append("Content-Type", "application/json");
    return this.httpClient
      .delete(url, {
        headers: headers,
      })
      .pipe(
        map((result) => {
          if (showSpinner) {
            this.spinner.hide();
          }
          return result as T;
        }),
        catchError((e) => {
          this.spinner.hide();
          this.handleError(e);
          return observableThrowError(e.message || "Server error");
        })
      );
  }

  upload(url, data) {
    return this.httpClient.post(url, data).pipe(
      map((result) => {
        return result;
      }),
      catchError((e) => {
        this.handleError(e);
        return observableThrowError(e.message || "Server error");
      })
    );
  }

  async download(downloadUrl) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

    const response = await this.httpClient
      .get(downloadUrl, {
        responseType: "blob",
        observe: "response",
        headers: headers,
      })
      .pipe(
        catchError((e) => {
          this.handleError(e);
          return observableThrowError(e.message || "Server error");
        })
      )
      .toPromise();

    return response;
  }
}
