import { Pipe, PipeTransform } from "@angular/core";
import conditionsList from "./conditions-list";

@Pipe({
  name: "conditionDisplayName",
})
export class ConditionDisplayNamePipe implements PipeTransform {
  fields = conditionsList;

  transform(field: any): string {
    const fieldData = this.getFieldData("field", field);
    if (fieldData) return `${fieldData.table} - ${fieldData.displayName}`;
    return null;
  }

  getFieldData(property: string, value: string) {
    return this.fields.find((field) => field[property] === value);
  }
}
