<div class="container">
  <div class="row">
    <div class="col-md" align="left">
      <input type="text" placeholder="Document name"  [(ngModel)]="newDocument.name"  [disabled]="disableInputs" >
    <div class="col" align="left">
        <button type="button" class="btn btn-secondary" (click)="addNewDocument(newDocument)" [disabled]="disableInputs" >Create document</button>
      </div>
    </div>
    </div>
    <div class="row">
      <div class="col-md">
        <div *ngFor="let document of documents; let i = index" align="left">
          <div *ngIf="document && document.deleted === undefined">
            <input type="button" value="X" (click)="deleteDocument(i)" [disabled]="disableInputs">
            Document name: {{document.name}} <input type="file" style="color:transparent" (change)='setFilesToDocument($event,i)'  multiple placeholder="Upload file" /> 
            <div *ngFor="let file of document.files; let j = index"><div *ngIf="file.deleted === undefined" style="margin: 2px; margin-left: 10px;">
                File: {{file.name}}
                <a *ngIf="docType !== null && file.id !== undefined" target="_blank" [appGetFile]="getFilePath(file.name)" [appOpenFile]="true">
                  <i class="glyphicon glyphicon-zoom-in" styleClass="btn" style="cursor: pointer;"></i>
                </a>
                    <input type="button" value="X" (click)="deleteFile(i, j);">
            </div></div>
          </div>
        </div>
      </div>
    </div>
</div>
  