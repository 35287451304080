<div class="container" style="max-width: 100%">
  <div class="text-center">
    <i class="pi pi-exclamation-triangle" style="font-size: 4rem"></i>
  </div>

  <div class="text-center">
    WARNING: Multiple rate sets found for this trip. Please review rates and make corrections to
    priority or other settings
  </div>

  <hr />

  <div class="row mx-3">
    <div class="col-md-6">Start Date</div>
    <div class="col-md-6">
      {{ tripOptions.startDate | date : "MM/dd/yyyy" }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">End Date</div>
    <div class="col-md-6">
      {{ tripOptions.endDate | date : "MM/dd/yyyy" }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">Origin State</div>
    <div class="col-md-6">
      {{ tripOptions.origin }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">Destination State</div>
    <div class="col-md-6">
      {{ tripOptions.destination }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">Bill To</div>
    <div class="col-md-6">
      {{ tripOptions.tripData.billTo }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">Driver</div>
    <div class="col-md-6">
      {{ tripOptions.tripData.driverName }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">Equipment</div>
    <div class="col-md-6">
      {{ tripOptions.trailerType }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">Product</div>
    <div class="col-md-6">
      {{ tripOptions.productName }}
    </div>
  </div>

  <hr />
  <div>Conflicting Rates Found</div>

  <div class="d-flex" *ngFor="let rate of rates" style="margin-bottom: 6px; align-items: center">
    <button class="btn btn-primary mr-2" (click)="selectRate(rate)">Use</button>
    <a [routerLink]="rate.rateLink" target="_blank">{{ rate.name }}</a>
  </div>
</div>
