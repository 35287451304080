import { forkJoin as observableForkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Component, OnInit, Input } from "@angular/core";
import { NewUploadService } from "../../services/files/new-upload.service";
@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.css"],
})
export class FileUploadComponent {
  errors: Array<string> = [];

  @Input() fileExt: String = "JPG, PDF, TIFF";
  @Input() maxFiles: number = 1000;
  @Input() maxSize: number = 1024; // 5MB

  constructor() {}

  isValidFileExtension(files) {
    // Make array of file extensions
    const extensions = this.fileExt.split(",").map(function (x) {
      return x.toLocaleUpperCase().trim();
    });

    for (let i = 0; i < files.length; i++) {
      //Set the orignal name for future use
      files[i].originalName = files[i].name;
      // Get file extension
      const ext = files[i].name.toUpperCase().split(".").pop() || files[i].name;
      // Check the extension exists
      const exists = extensions.includes(ext);
      if (!exists) {
        this.errors.push("Error (Extension): " + files[i].name);
        return false;
      }
    }
    return true;
  }
}
