import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PcMilerService } from "./pcMiler.service";

@Injectable()
export class PcMilerResolver {
  constructor(private PcMilerService: PcMilerService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.PcMilerService.getCurrentSetup();
  }
}
