<div class="billToList" *ngIf="isEditing">
  <div>
    <label for="binary" class="checkbox-label">Is Default</label>
    <p-checkbox
      [(ngModel)]="isDefaultBillTo"
      binary="true"
      inputId="binary"
      (onChange)="isDefaultBillToChange.emit(isDefaultBillTo)"
    ></p-checkbox>
  </div>
  <div *ngIf="!isDefaultBillTo" style="margin-top: 1rem">
    <div>
      <button
        pButton
        type="button"
        icon="pi pi-plus"
        class="p-button-success"
        label="Add Bill To"
        data-toggle="modal"
        data-target="#addBillToModal"
        *ngIf="isEditing"
      ></button>
    </div>

    <div class="billToRow" *ngFor="let billTo of billTos">
      <div>{{ billTo.companyName }}</div>
      <a *ngIf="isEditing" class="delete-button ui-button-danger" (click)="removeBillTo(billTo)">
        <span class="pi pi-times"></span>
      </a>
    </div>
  </div>
</div>

<div *ngIf="!isEditing">
  <div *ngIf="isDefaultBillTo">Is Default</div>
  <div *ngIf="!isDefaultBillTo">
    <div *ngFor="let billTo of billTos">
      {{ billTo.companyName }}
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addBillToModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addBillToModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" style="min-width: 800px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Bill To</h5>
      </div>
      <hr />
      <form [formGroup]="billToForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-sm-4 control-label">Add Client</label>
            <div class="col-sm-8">
              <p-dropdown
                placeholder="Select a Client"
                [options]="clients"
                formControlName="selectedClient"
                optionLabel="itemName"
                filterBy="itemName"
                [style]="{ minWidth: '100%' }"
                (onChange)="onClientSelected()"
                tabbableDropdown
              ></p-dropdown>
            </div>
          </div>
          <hr />
          <div class="form-group row">
            <label class="col-sm-4 control-label">Add Bill To</label>
            <div class="col-sm-8">
              <p-multiSelect
                placeholder="Select a Bill To"
                [options]="clientBillTos"
                formControlName="billTo"
                optionLabel="itemName"
                filterBy="itemName"
                [style]="{ minWidth: '100%' }"
                required
                tabbableMultiselect
              >
              </p-multiSelect>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" #closebutton>
            Cancel
          </button>
          <button type="submit" class="btn btn-primary">OK</button>
        </div>
      </form>
    </div>
  </div>
</div>
