import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SelectItemGroup } from "primeng/api";
import { MaskService } from "../../../shared/services/mask/mask.service";
import { ClientMinimumRateViewModel } from "@app/shared/models/rates/MinimumRateViewModel.model";
import { BillToViewModel } from "@app/shared/models/clients/BillToViewModel.model";

@Component({
  selector: "app-add-minimum-client",
  templateUrl: "./add-minimum-client.component.html",
  styleUrls: ["./add-minimum-client.component.css"],
})
export class AddMinimumClientComponent {
  @Input() isEditing!: boolean;
  @Input() clients!: ClientMinimumRateViewModel[];
  @Output() clientsChange = new EventEmitter();

  allClients: any[];
  allBillTos: any[];
  groupedBillTos: SelectItemGroup[];
  dateInputMask: any;
  constructor(private route: ActivatedRoute, private maskService: MaskService) {}

  ngOnInit() {
    this.dateInputMask = this.maskService.getInputDateOnlyMask();

    this.allClients = this.route.snapshot.data["clients"];
    this.allBillTos = this.route.snapshot.data["billToFullList"];

    this.groupedBillTos = this.allClients.map((client) => ({
      label: client.companyName,
      items: this.allBillTos
        .filter((billTo: BillToViewModel) => billTo.clientId === client.id)
        .map((billTo) => ({
          label: billTo.companyName,
          value: billTo.id,
          disabled: !billTo.isActive,
        })),
    }));
  }

  addClient() {
    this.clients.push({
      validFromUtc: new Date(),
    });
  }

  removeClient(client: any) {
    this.clients = this.clients.filter((c) => c !== client);
    this.clientsChange.emit(this.clients);
  }
}
