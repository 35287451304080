import { distinctUntilChanged } from "rxjs/operators";
import { Component, Input, Output, OnInit } from "@angular/core";
import { Pipe, PipeTransform, EventEmitter } from "@angular/core";

import { UntypedFormControl } from "@angular/forms";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(items: any, filter: any): any {
    if (filter && Array.isArray(items)) {
      const filterKeys = Object.keys(filter);
      return items.filter((item) =>
        filterKeys.reduce(
          (memo, keyName) =>
            (memo && new RegExp(filter[keyName], "gi").test(item[keyName])) ||
            filter[keyName] === "",
          true
        )
      );
    } else {
      return items;
    }
  }
}

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.css"],
})
export class FilterComponent implements OnInit {
  public isOpen = false;
  public enableFilter: boolean;
  public filterText: string;
  public filterPlaceholder: string;
  public filterInput = new UntypedFormControl();
  itemsList;

  /**
   * Declare like this:
   * [items]="{items: array, filter: 'Filter string'}"
   * @memberof FilterComponent
   */
  @Input()
  set items(item) {
    this.itemsList = this.getUniqueValuesOfKeyForFilter(item.items, item.filter, item.current);
  }
  get items() {
    return this.itemsList;
  }
  @Input() header;
  @Output() select: EventEmitter<any>;
  @Output() selectAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() deselectSelectAll: EventEmitter<any> = new EventEmitter<any>();
  constructor() {
    this.select = new EventEmitter();
  }

  selectItem(itemSelected: any) {
    itemSelected.checked = !itemSelected.checked;
    this.select.emit(this.itemsList.filter((item) => item.checked));
  }

  toggleSelect() {
    this.isOpen = !this.isOpen;
  }
  leave() {
    this.isOpen = !this.isOpen;
  }
  clearFilter() {
    this.filterText = "";
  }

  ngOnInit() {
    this.enableFilter = true;
    this.filterText = "";
    this.filterPlaceholder = "Filter..";

    this.filterInput.valueChanges.pipe(distinctUntilChanged()).subscribe((term) => {
      this.filterText = term;
      // console.log(term);
    });
  }

  getUniqueValuesOfKeyForFilter(array, key, current) {
    // console.log(current);
    const unique = {};
    const distinct = [];
    if (key.indexOf(".") >= 0) {
      const keys = key.split(".");
      // console.log(keys);
      // distinct.push({value: '', checked: true});
      for (const i of Object.keys(array)) {
        if (array[i][keys[0]]) {
          if (typeof unique[array[i][keys[0]][keys[1]]] === "undefined") {
            if (!array[i][keys[0]][keys[1]]) {
              distinct.push({ value: null, checked: true });
            } else {
              distinct.push({ value: array[i][keys[0]][keys[1]], checked: true });
            }
          }
          unique[array[i][keys[0]][keys[1]]] = 0;
        } else {
          distinct.push({ value: null, checked: true });
          array[i][keys[0]] = {};
          unique[array[i][keys[0]][keys[1]]] = 0;
        }
      }
      if (
        current &&
        current[keys[0]] &&
        current[keys[0]][keys[1]] &&
        current[keys[0]][keys[1]].length > 0
      ) {
        distinct.forEach((item: any) => {
          let exist = false;
          current[keys[0]][keys[1]].forEach((element) => {
            if (item.value === element.value) {
              exist = true;
            }
          });
          if (!exist) {
            item.checked = false;
          }
        });
      }
    } else {
      for (const i of Object.keys(array)) {
        if (typeof unique[array[i][key]] === "undefined") {
          if (!array[i][key]) {
            distinct.push({ value: null, checked: true });
          } else {
            distinct.push({ value: array[i][key], checked: true });
          }
        }
        unique[array[i][key]] = 0;
      }
      if (current && current[key] && current[key].length > 0) {
        distinct.forEach((item: any) => {
          let exist = false;
          current[key].forEach((element) => {
            if (item.value === element.value) {
              exist = true;
            }
          });
          if (!exist) {
            item.checked = false;
          }
        });
      }
    }
    return distinct;
  }

  selectionAll(select) {
    let newItemList: any;
    if (this.filterText.trim() !== "") {
      newItemList = this.itemsList.filter((singleItem) =>
        singleItem["value"].toUpperCase().includes(this.filterText.toUpperCase())
      );
      this.itemsList.forEach((item) => {
        newItemList.forEach((newListItem) => {
          if (item.value === newListItem.value) {
            item.checked = select;
          }
        });
      });
    } else {
      this.itemsList.forEach((item) => {
        item.checked = select;
      });
    }
    if (select) {
      this.selectAll.emit(this.itemsList);
    } else {
      this.deselectSelectAll.emit(this.itemsList);
    }
  }
}
