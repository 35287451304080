<div class="container">
  <div class="row">
    <div class="col-sm-8">
      <div class="tripHeaderTitle">
        <p class="TripHeader">Tractor Info</p>
        <hr />
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-md-6 control-label" for="unit">Unit Number</label>
          <div class="col-md-6">
            <input
              id="unitnumberid"
              name="unit"
              type="text"
              tabindex="1"
              [(ngModel)]="tractorInfo.unitNumber"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="notes">Notes</label>
          <div class="col-md-6">
            <textarea
              id="notes"
              name="notes"
              tabindex="1"
              type="text"
              placeholder="Insert notes"
              [(ngModel)]="tractorInfo.notes"
              maxlength="255"
              rows="3"
              style="text-transform: uppercase"
              [disabled]="is_edit"
            ></textarea>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="active">Active</label>
          <div class="col-md-6">
            <p-checkbox
              [(ngModel)]="tractorInfo.isActive"
              id="active"
              tabindex="3"
              binary="true"
              [disabled]="is_edit"
            ></p-checkbox>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-md-6 control-label" for="terminalLocation">Terminal Location</label>
          <div class="col-md-6">
            <select
              class="selectState"
              id="terminalLocation"
              tabindex="2"
              name="terminalLocation"
              [(ngModel)]="tractorInfo.terminalLocation"
              [disabled]="is_edit"
            >
              <option *ngFor="let terminal of terminals" [ngValue]="terminal">
                {{ terminal }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="dateSold">Date Sold</label>
          <div class="col-md-6">
            <input id="dateSoldInput"
                   name="dateSoldInput"
                   type="text"
                   [inputMask]="dateInputMask"
                   autocomplete="off"
                   placeholder="mm/dd/yyyy"
                   appendTo="body"
                   class="form-control input-md"
                   [tabIndex]="4"
                   [disabled]="is_edit"
                   [(ngModel)]="tractorInfo.dateSold" />
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-md-6 control-label" for="year">Year</label>
          <div class="col-md-6">
            <input
              id="name"
              name="year"
              type="text"
              data-toggle="tooltip"
              tabindex="5"
              data-placement="top"
              title="{{ tractorInfo.year }}"
              [(ngModel)]="tractorInfo.year"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="maker">Make</label>
          <div class="col-md-6">
            <input
              id="name"
              name="maker"
              type="text"
              data-toggle="tooltip"
              tabindex="7"
              data-placement="top"
              title="{{ tractorInfo.make }}"
              [(ngModel)]="tractorInfo.make"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="tractorModel">Tractor Model</label>
          <div class="col-md-6">
            <input
              id="tractorModel"
              name="tractorModel"
              type="text"
              tabindex="9"
              placeholder="Insert tractor model"
              title="{{ tractorInfo.model }}"
              [(ngModel)]="tractorInfo.model"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="fuel">Fuel Type</label>
          <div class="col-md-6">
            <select
              class="selectFuel"
              id="fuelType"
              name="fuelType"
              tabindex="13"
              [disabled]="is_edit"
              [(ngModel)]="tractorInfo.fuelType"
            >
              <option value="DIESEL" selected>Diesel</option>
              <option value="CNG">CNG</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="radios">Fuel Card Number</label>
          <div class="col-md-6 inputSpace">
            <input
              id="FuelCard"
              name="FuelCard"
              type="text"
              tabindex="15"
              placeholder="Insert fuel Card Number"
              [disabled]="is_edit"
              [(ngModel)]="tractorInfo.TcheckCardNo"
              class="form-control input-md"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="radios">State of registration</label>
          <div class="col-md-6">
            <select
              class="selectState"
              id="stateOfRegistration"
              tabindex="17"
              name="stateOfRegistration"
              [disabled]="is_edit"
              [(ngModel)]="tractorInfo.stateOfRegistration"
            >
              <option *ngFor="let state of states" [ngValue]="state.abbreviation">
                {{ state.fullName }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="license">License Plate</label>
          <div class="col-md-6">
            <input
              id="name"
              name="license"
              type="text"
              tabindex="19"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ tractorInfo.licenseNumber }}"
              [(ngModel)]="tractorInfo.licenseNumber"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="licenseExpirationDate"
            >License Expiration Date</label
          >
          <div class="col-md-6">
            <input id="licenseExpirationDate"
                   name="licenseExpirationDate"
                   type="text"
                   [inputMask]="dateInputMask"
                   autocomplete="off"
                   placeholder="mm/dd/yyyy"
                   appendTo="body"
                   class="form-control input-md"
                   [tabIndex]="21"
                   [disabled]="is_edit"
                   [(ngModel)]="tractorInfo.licenseExpirationDate" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="engineManufacturer">Engine Manufacturer</label>
          <div class="col-md-6">
            <input
              id="engineManufacturer"
              name="engineManufacturer"
              type="text"
              tabindex="23"
              placeholder="Insert engine manufacture"
              title="{{ tractorInfo.engineManufacturer }}"
              [(ngModel)]="tractorInfo.engineManufacturer"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="engineModel">Engine Model</label>
          <div class="col-md-6">
            <input
              id="engineModel"
              name="engineModel"
              type="text"
              tabindex="25"
              placeholder="Insert Engine Model"
              title="{{ tractorInfo.engineModel }}"
              [(ngModel)]="tractorInfo.engineModel"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="engineSerialNumber">Engine Serial #</label>
          <div class="col-md-6">
            <input
              id="engineSerialNumber"
              name="engineSerialNumber"
              type="text"
              tabindex="27"
              placeholder="Engine Serial Number"
              title="{{ tractorInfo.engineSerialNumber }}"
              [(ngModel)]="tractorInfo.engineSerialNumber"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="tractor">Tractor Driver</label>
          <div class="col-md-6">
            <select
              class="selectState"
              id="truckInfoDriverSelect"
              tabindex="29"
              name="truckInfoDriverSelect"
              [(ngModel)]="tractorInfo.driverId"
              [disabled]="is_edit"
            >
              <option [ngValue]="null">--No Driver Selected--</option>
              <option
                *ngFor="let driverAvailable of driversWithNoTrailerAssigned"
                [ngValue]="driverAvailable.id"
              >
                {{ driverAvailable.lastName + " " + driverAvailable.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="insuran">Insurance Name</label>
          <div class="col-md-6">
            <input
              id="name"
              name="insuran"
              type="text"
              data-toggle="tooltip"
              tabindex="31"
              data-placement="top"
              title="{{ tractorInfo.insuranceName }}"
              [(ngModel)]="tractorInfo.insuranceName"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="baseManuFactureWarranty"
            >Base MFR Warranty</label
          >
          <div class="col-md-6">
            <input
              id="baseManuFactureWarranty"
              name="baseManuFactureWarranty"
              tabindex="33"
              type="text"
              title="{{ tractorInfo.baseManuFactureWarranty }}"
              placeholder="Insert Base Manufacture Warranty"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="tractorInfo.baseManuFactureWarranty"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="engineMilesWarranty"
            >Engine Miles Warranty</label
          >
          <div class="col-md-6">
            <input
              id="engineMilesWarranty"
              name="engineMilesWarranty"
              tabindex="35"
              type="text"
              placeholder="Insert warranty miles"
              title="{{ tractorInfo.engineMilesWarranty }}"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="tractorInfo.engineMilesWarranty"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="afterTreatmentMilesWarranty"
            >After Treatment Miles</label
          >
          <div class="col-md-6">
            <input
              id="afterTreatmentMilesWarranty"
              name="afterTreatmentMilesWarranty"
              tabindex="37"
              type="text"
              placeholder="Insert After Treatment Miles Warranty"
              title="{{ tractorInfo.afterTreatmentMilesWarranty }}"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="tractorInfo.afterTreatmentMilesWarranty"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="afterTreatmentYearWarranty"
            >Treatment Year</label
          >
          <div class="col-md-6">
            <input
              id="afterTreatmentYearWarranty"
              tabindex="39"
              title="{{ tractorInfo.afterTreatmentYearWarranty }}"
              [disabled]="is_edit"
              class="form-control input-md inp"
              name="afterTreatmentYearWarranty"
              type="text"
              placeholder="Insert warranty miles"
              class="form-control input-md"
              [(ngModel)]="tractorInfo.afterTreatmentYearWarranty"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="tracOw">Tractor Owner</label>
          <div class="col-md-6">
            <input
              id="name"
              name="tracOw"
              tabindex="41"
              type="text"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ tractorInfo.owner }}"
              [(ngModel)]="tractorInfo.owner"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-md-6 control-label" for="date">Date Purchased</label>
          <div class="col-md-6">
            <input id="datePurchasedInput"
                   name="datePurchasedInput"
                   type="text"
                   [inputMask]="dateInputMask"
                   autocomplete="off"
                   placeholder="mm/dd/yyyy"
                   appendTo="body"
                   class="form-control input-md"
                   [tabIndex]="6"
                   [disabled]="is_edit"
                   [(ngModel)]="tractorInfo.datePurchased" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="purcha">Purchase Price</label>
          <div class="col-md-6">
            <input
              id="name"
              name="purcha"
              type="number"
              tabindex="8"
              data-toggle="tooltip"
              data-placement="top"
              title="{{ tractorInfo.purchasePrice }}"
              [(ngModel)]="tractorInfo.purchasePrice"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="length">Tractor Length</label>
          <div class="col-md-6">
            <input
              id="length"
              name="length"
              type="text"
              data-toggle="tooltip"
              tabindex="10"
              data-placement="top"
              title="{{ tractorInfo.length }}"
              [(ngModel)]="tractorInfo.length"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="heigh">Height</label>
          <div class="col-md-6">
            <input
              id="name"
              name="heigh"
              type="text"
              data-toggle="tooltip"
              tabindex="12"
              data-placement="top"
              title="{{ tractorInfo.height }}"
              [(ngModel)]="tractorInfo.height"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="laden">Tractor Width</label>
          <div class="col-md-6">
            <input
              id="name"
              name="laden"
              type="text"
              data-toggle="tooltip"
              tabindex="14"
              data-placement="top"
              title="{{ tractorInfo.width }}"
              [(ngModel)]="tractorInfo.width"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="laden">Laden Weight</label>
          <div class="col-md-6">
            <input
              id="name"
              name="laden"
              type="text"
              data-toggle="tooltip"
              tabindex="16"
              data-placement="top"
              title="{{ tractorInfo.ladenWeight }}"
              [(ngModel)]="tractorInfo.ladenWeight"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="unladen">Unladen Weight</label>
          <div class="col-md-6">
            <input
              id="name"
              name="unladen"
              type="text"
              data-toggle="tooltip"
              tabindex="18"
              data-placement="top"
              title="{{ tractorInfo.unladenWeight }}"
              [(ngModel)]="tractorInfo.unladenWeight"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="axles">Axles</label>
          <div class="col-md-6">
            <input
              id="name"
              name="axles"
              type="text"
              data-toggle="tooltip"
              tabindex="20"
              data-placement="top"
              title="{{ tractorInfo.axles }}"
              [(ngModel)]="tractorInfo.axles"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="steer">Steer Tire Size</label>
          <div class="col-md-6">
            <input
              id="name"
              name="steer"
              type="text"
              data-toggle="tooltip"
              tabindex="22"
              data-placement="top"
              title="{{ tractorInfo.steerTireSize }}"
              [(ngModel)]="tractorInfo.steerTireSize"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="drive">Drive Tire Size</label>
          <div class="col-md-6">
            <input
              id="name"
              name="drive"
              type="text"
              data-toggle="tooltip"
              tabindex="24"
              data-placement="top"
              title="{{ tractorInfo.driveTireSize }}"
              [(ngModel)]="tractorInfo.driveTireSize"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="accumulated">Accumulated Miles</label>
          <div class="col-md-6">
            <input
              id="name"
              name="accumulated"
              type="text"
              data-toggle="tooltip"
              tabindex="26"
              data-placement="top"
              title="{{ tractorInfo.accumulatedMiles }}"
              [(ngModel)]="tractorInfo.accumulatedMiles"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="policy">Insurance Policy Number</label>
          <div class="col-md-6">
            <input
              id="name"
              name="policy"
              type="text"
              data-toggle="tooltip"
              tabindex="28"
              data-placement="top"
              title="{{ tractorInfo.insurancePolicyNumber }}"
              [(ngModel)]="tractorInfo.insurancePolicyNumber"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="afterTreatmentManufactureWarranty"
            >After Treatment</label
          >
          <div class="col-md-6">
            <input
              id="afterTreatmentManufactureWarranty"
              tabindex="30"
              name="afterTreatmentManufactureWarranty"
              type="text"
              placeholder="Insert AfterTreatment Manufacture Warranty"
              title="{{ tractorInfo.afterTreatmentManufactureWarranty }}"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="tractorInfo.afterTreatmentManufactureWarranty"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="baseWarrantyMiles">Base Warranty Miles</label>
          <div class="col-md-6">
            <input
              id="baseWarrantyMiles"
              name="baseWarrantyMiles"
              tabindex="32"
              type="text"
              title="{{ tractorInfo.baseWarrantyMiles }}"
              placeholder="Insert Base Warranty Miles"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="tractorInfo.baseWarrantyMiles"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="engineYearWarranty"
            >Engine Year Warranty</label
          >
          <div class="col-md-6">
            <input
              id="engineYearWarranty"
              name="engineYearWarranty"
              tabindex="34"
              type="text"
              placeholder="Insert Wngine miles Warranty"
              title="{{ tractorInfo.engineYearWarranty }}"
              class="form-control input-md"
              [disabled]="is_edit"
              [(ngModel)]="tractorInfo.engineYearWarranty"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="equip">Equipment Code</label>
          <div class="col-md-6">
            <input
              id="name"
              name="equip"
              type="text"
              data-toggle="tooltip"
              tabindex="36"
              data-placement="top"
              title="{{ tractorInfo.equipmentCode }}"
              [(ngModel)]="tractorInfo.equipmentCode"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="vinNo">Vin Number</label>
          <div class="col-md-6">
            <input
              id="vinNo"
              name="vinNo"
              type="text"
              data-toggle="tooltip"
              tabindex="36"
              data-placement="top"
              title="{{ tractorInfo.vinNo }}"
              [(ngModel)]="tractorInfo.vinNo"
              [disabled]="is_edit"
              class="form-control input-md inp"
            />
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-md-6 control-label">Mobile device type</label>
          <div class="col-md-6">
            <select
              class="selectEquipment"
              tabindex="43"
              type="text"
              id="type"
              name="type"
              [disabled]="is_edit"
              [(ngModel)]="tractorInfo.mobileDeviceType"
            >
              <option value="PHONE" selected>PHONE</option>
              <option value="TABLET">TABLET</option>
              <option value="NONE">NONE</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-6 control-label" for="mobileDeviceNumber"
            >Mobile Device Number</label
          >
          <div class="col-md-6">
            <input
              id="mobileDeviceNumber"
              tabindex="45"
              name="mobileDeviceNumber"
              [disabled]="is_edit"
              type="text"
              placeholder="mobile device number"
              class="form-control input-md"
              [(ngModel)]="tractorInfo.mobileDeviceNumber"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4" align="center">
      <div>
        <img
          class="img-responsive"
          style="margin-top: 64px"
          [src]="profilePicture"
          [appGetFile]="profilePicture"
          [appPreviewFile]="true"
        />
        <input
          [disabled]="is_edit"
          class="form-control-file inpFile"
          type="file"
          (change)="getTemporalImage($event)"
          placeholder="Upload file..."
        />
        <div class="form-group">
          <br />
          <label class="col-md-3 control-label" for="company">upload Docs</label>
          <div class="col-md-3">
            <createDocument #tractorDocument [disableInputs]="is_edit"></createDocument>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" class="bottom-btns">
  <div class="col-sm-6" align="right">
    <button
      *ngIf="hasPermission('TractorsChange')"
      type="button"
      class="btn btn-warning createBtn"
      [disabled]="isProcessing"
      (click)="editOn(tractorInfo)"
    >
      {{ btnProfile }}
    </button>
  </div>
  <div class="col-sm-6" align="left">
    <button *ngIf="!is_edit" type="button" class="btn btn-secondary" (click)="cancelEdit()">
      Cancel
    </button>
  </div>
  <br />
</div>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '50vw' }"
></p-confirmDialog>
