<div class="container">
  <div class="col-md-12">
    <legend>T-Chek Admin (delete/edit imported records)</legend>
    <span>Import Date:</span>
    <select (change)="dataChangedYear()" [(ngModel)]="yearSelected" style="margin-left: 1rem">
      <option [ngValue]="0" [selected]="yearSelected == 0">Select Year</option>
      <option *ngFor="let select of selectYears" [ngValue]="select.dateyear">
        {{ select.dateyear }}
      </option>
    </select>
    <select
      (change)="dataChangedSelectedBatch()"
      [(ngModel)]="batchSelected"
      style="margin-left: 1rem"
    >
      <option [ngValue]="0" [selected]="batchSelected.uuid == 0">Select Option</option>
      <option *ngFor="let select of selectRecords" [ngValue]="select">
        {{ select.createdAt | date : "MM/dd/yyyy" }}
      </option>
    </select>
    <br />
    <button
      *ngIf="batchSelected"
      pButton
      pRipple
      type="button"
      class="p-button-danger"
      icon="pi pi-trash"
      label="Delete Imported Batch"
      style="margin-top: 1rem"
      (click)="deleteBatch()"
    ></button>
    <app-table-report [record]="records" [isEdit]="editOff"></app-table-report>
  </div>
</div>

<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>
