import { Injectable } from "@angular/core";

import { TractorService } from "./tractor.service";

@Injectable()
export class TractorsResolver {
  constructor(private tractorService: TractorService) {}

  resolve() {
    return [];
    return this.tractorService.getTractorList(
      0,
      20,
      {
        make: [],
        year: [],
        unitNumber: [],
        licenseNumber: [],
        active: true,
        name: [],
      },
      "id"
    );
  }
}
