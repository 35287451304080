import { Component, OnInit, ViewChild } from "@angular/core";
import { TrailerService } from "../shared/trailer.service";
import { Router } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { SelectItem } from "primeng/api";

@Component({
  selector: "app-trailerlist",
  templateUrl: "./trailerlist.component.html",
  styleUrls: ["./trailerlist.component.css"],
})
export class TrailerlistComponent implements OnInit {
  colsTrailers: any = [];
  errorMsg: any;
  allRecord: any = [];

  statusOptions: SelectItem[] = [
    { label: "ALL", value: null },
    { label: "ACTIVE", value: "Active" },
    { label: "INACTIVE", value: "Inactive" },
  ];

  constructor(
    private _trailerlist: TrailerService,
    private router: Router,
    private _AuthService: authenticationService
  ) {}

  ngOnInit() {
    this.colsTrailers = [
      { field: "trailerNo", header: "Trailer Number", width: 130 },
      { field: "trailerType", header: "Trailer Type", width: 120 },
      { field: "equipmentCode", header: "Equipment Code", width: 135 },
      { field: "terminalLocation", header: "Terminal Location", width: 200 },
      { field: "active", header: "Status", width: 100, type: "boolean" },
    ];
    this._trailerlist.findAndFilter().subscribe(
      (resTrailerData) => {
        this.allRecord = resTrailerData;
      },
      (resTrailerDataError) => {
        this.errorMsg = resTrailerDataError;
      }
    );
  }

  viewDetail(trailer: any) {
    this.router.navigate(["/trailerinfo/", trailer.uuid]);
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }
}
