import { of as observableOf, Observable, Observer } from "rxjs";
import { Component, ViewChild, OnInit } from "@angular/core";
import { TractorService } from "../shared/tractor.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FileUploadService } from "../../../app/shared/services/files/fileUpload.service";

import { MaskService } from "../../../app/shared/services/mask/mask.service";
import { GetPictureService } from "../../../app/shared/services/files/getPictures.service";
import { StatesServices } from "../../shared/services/states/states.service";
import { TerminalLocationsServices } from "../../shared/services/terminals/terminals.service";
import { ToastrService } from "ngx-toastr";
import { CanComponentDeactivate } from "../../shared/guards/confirmation/confirmation.guard";
import { TractorViewModel } from "@app/shared/models/tractors/TractorViewModel.model";


@Component({
  selector: "app-addtractor",
  templateUrl: "./addtractor.component.html",
  styleUrls: ["./addtractor.component.css"],
})
export class AddtractorComponent implements OnInit, CanComponentDeactivate {
  errorMsg: any;
  newVariable: any = {};
  uuid: any;
  tractor: TractorViewModel = new TractorViewModel();
  usPhoneMask: any;
  drivers = [];
  docType: string;
  previewProfilePicture: any;
  dateMask: (string | RegExp)[];
  states: { abbreviation: string; fullName: string }[];
  terminals: Array<string>;
  isProcessing = false;
  letters = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
  numbers = /^\d*\.?\d+$/;
  isGuardActive = true;
  @ViewChild("tractorDocument", { static: true }) tractorDocument;
  @ViewChild("profilePicture", { static: true }) profilePicture;
  dateInputMask: any;
  constructor(
    private _addTractorService: TractorService,
    private router: Router,
    private fileUploadService: FileUploadService,
    private getPictureService: GetPictureService,
    private maskService: MaskService,
    private route: ActivatedRoute,
    private statesServices: StatesServices,
    private toastr: ToastrService,
    private terminalLocationsServices: TerminalLocationsServices
  ) {
    this.docType = "tractor";
  }

  ngOnInit() {
    this.usPhoneMask = this.maskService.getUsPhoneMask();
    this.dateMask = this.maskService.getDate();
    this.dateInputMask = this.maskService.getInputDateOnlyMask();
    this.drivers = this.route.snapshot.data.driversWithNoTrailerAssigned;
    this.states = this.statesServices.getTwoLettersStates("US");
    this.terminals = this.terminalLocationsServices.getTerminalLocations();
    this.tractor.terminalLocation = this.terminals[0];
    this.tractor.driverId = null;
    this.tractor.mobileDeviceType = "NONE";
    this.tractor.isActive = true;
    //this.startForm();
    //we are inserting the css using JS because the compoenent doesnt allow us to change the css DO NOT DELETE/EDIT/MOVE this script
    let styleDatatable = document.createElement("style");
    styleDatatable.type = "text/css";
    styleDatatable.innerHTML = ".owl-datetime-main-input{ font-size:13px !important; }";
    document.getElementsByTagName("head")[0].appendChild(styleDatatable);
  }

  confirm() {
    if (this.isGuardActive) {
      return window.confirm("Leave this page without creating the tractor?");
    } else {
      return true;
    }
  }

  dateChanged(event, param, justDate, yearDigits) {
    if (event.code === "Delete" || event.code === "Backspace") {
      return;
    }
    this.maskService.maskDate(event, param, justDate, yearDigits);
  }

  confirmationModal() {
    document.getElementById("openModal").click();
  }

    //startForm(){
    //  this.tractor.unitNumber = "123456789";
    //  this.tractor.owner = "LGT";
    //  this.tractor.height = "12.5";
    //  this.tractor.ladenWeight = "2500";
    //  this.tractor.length = "30.5";
    //  this.tractor.width = "15.8";
    //  this.tractor.purchasePrice = "250000";
    //  this.tractor.datePurchased = new Date(1,1,2023);
    //  this.tractor.terminalLocation = "NE";
    //  this.tractor.unladenWeight = "15000";
    //  this.tractor.make = "MAK";
    //  this.tractor.model = "Model";
    //  this.tractor.axles = "8";
    //  this.tractor.year = "2015";
    //  this.tractor.steerTireSize = "7";
    //  this.tractor.fuelType = "diesel";
    //  this.tractor.driveTireSize = "9";
    //  this.tractor.stateOfRegistration = "NE";
    //  this.tractor.insuranceName = "GEICO";
    //  this.tractor.licenseNumber = "12548";
    //  this.tractor.insurancePolicyNumber = "123456789";
    //  this.tractor.licenseExpirationDate = new Date(1, 1, 2023);
    //  this.tractor.accumulatedMiles = "3000";
    //  this.tractor.currentLocationState = "NE";
    //  this.tractor.currentLocationAddress = "2124 Y ST";
    //  this.tractor.currentLocationZip = "68066";
    //  this.tractor.engineManufacturer = "VOLVO";
    //  this.tractor.engineSerialNumber = "1238780494";
    //  this.tractor.tractorModel = "9787";
    //  this.tractor.engineModel = "25212";
    //  this.tractor.baseManuFactureWarranty = "120000";
    //  this.tractor.baseWarrantyMiles = "25000";
    //  this.tractor.baseWarrantyYear = "2014";
    //  this.tractor.engineManufactureWarranty = "20000";
    //  this.tractor.engineMilesWarranty = "450000";
    //  this.tractor.engineYearWarranty = "2017";
    //  this.tractor.afterTreatmentManufactureWarranty = "489000";
    //  this.tractor.afterTreatmentMilesWarranty = "150000";
    //  this.tractor.mobileDeviceType = "PHONE";
    //  this.tractor.mobileDeviceNumber = "4029199333";
    //  this.tractor.afterTreatmentYearWarranty = "3977";
    //}

  createTractor() {
    console.log("----------------------");
    console.dir(this.tractorDocument.documents);
    console.log("----------------------");
  }

  getTemporalImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.previewProfilePicture = this.getPictureService.getPreviewImage(event.target.files[0]);
    }
  }

  getFilePath(path) {
    return this.getPictureService.getPicturePath(path, "tractor");
  }

  uploadProfilePicture(files) {
    const fname: string = this.profilePicture.nativeElement.files[0].name;
    return this.fileUploadService.uploadSingleFileRename(
      this.profilePicture.nativeElement.files,
      this.docType,
      this.tractor.unitNumber + "_" + fname
    );
  }
  uploadDocuments(tractorId) {
    return this.tractorDocument.uploadDocuments(
      { TractorId: tractorId },
      this.docType + "_files",
      tractorId
    );
  }

  sendNewTractor(newTractor) {
    return this._addTractorService.createNewTractor(newTractor);
  }

  finishTractorCreation() {
    document.getElementById("closeModal").click();
    this.isGuardActive = false;
    this.toastr.success("Tractor created.", "Success");
    this.isProcessing = false;
    this.tractorDocument.files = [];
    this.router.navigate(["/tractorlist/"]);
  }

  driverSelected(driverId) {
    if (driverId === -1) {
      this.tractor.owner = "";
    }
    this.drivers.forEach((driver) => {
      if (driver.id === driverId) {
        this.tractor.owner = (driver.lastName.slice(0, 4) + driver.name.slice(0, 4)).toUpperCase();
        return;
      }
    });
  }
  createNewTractor(newTractor) {
    // if (this.validateMandatoryFields()) {
    //  if (this.validateTractorFields()) {
    this.isProcessing = true;

    let profilePictureOservable;

    if (this.profilePicture.nativeElement.files && this.profilePicture.nativeElement.files[0]) {
      profilePictureOservable = this.uploadProfilePicture(this.profilePicture.nativeElement.files);
    } else {
      profilePictureOservable = observableOf(undefined);
    }

    profilePictureOservable.subscribe((result) => {
      if (result) {
        newTractor.picture = this.profilePicture.nativeElement.files[0].name;
      }

      this.sendNewTractor(newTractor).subscribe(
        (TractorCreated: any) => {
          if (this.tractorDocument.documents.length) {
            this.uploadDocuments(TractorCreated.id).subscribe(
              (documentsCreated) => {
                this.finishTractorCreation();
                return;
              },
              (error) => {
                this.isProcessing = false;
              }
            );
          } else {
            this.finishTractorCreation();
            return;
          }
        },
        (errors) => {
          for (const error of errors) {
            this.toastr.warning(error, "Alert!", {
              closeButton: true,
              enableHtml: true,
            });
            console.dir(error);
          }
          this.isProcessing = false;
        }
      );
    });
    //   }
    // }
  }

  /*
  validateMandatoryFields(): boolean {
    try {
      this.isValidUnitNumber();
      this.isValidTerminalLocation();
      this.isValidYear();
      this.isValidDatepurchased();
      this.isValidMake();
      this.isValidPurchasePrice();
      this.isValidModel();
      this.isValidLenght();
      this.isValidHeight();
      this.isValidFuelType();
      this.isValidwidth();
      this.isValidTcheckCardNo();
      this.isValidLadenWeight();
      this.isValidStateOfRegistration();
      this.isValidUnladenWeight();
      this.isValidLicenseNumber();
      this.isValidAxles();
      this.isValidLicenseExpirationDate();
      this.isValidSteerTireSize();
      this.isValidEngineManufacturer();
      this.isValidDriveTireSize();
      this.isValidEngineModel();
      this.isValidAccumulatedMiles();
      this.isValidEngineSerialNumber();
      this.isValidinsurancePolicyNumber();
      this.isValidDriverId();
      this.isValidAfterTreatmentYearWarranty();
      this.isValidInsuranceName();
      this.isValidBaseWarrantyMiles();
      this.isValidBaseManuFactureWarranty();
      this.isValidEngineManufactureWarranty();
      this.isValidBaseWarrantyYear();
      this.isValidEngineYearWarranty();
      this.isValidEngineMilesWarranty();
      this.isValidAfterTreatmentMilesWarranty();
      this.isValidAfterTreatmentManufactureWarranty();
      this.isValidEquipmentCode();
      this.isValidOwner();
      this.isValidMobileDeviceType();
      this.isValidMobileDeviceNumber();
      return true;
    } catch (error) {
      switch (error.type) {
        case 'warning':
          this.toastr.warning(error.msg, 'Alert!', {
            closeButton: true,
            enableHtml: true,
          });
          console.log("Validation error")
          console.dir(error)
          break;
        default:
          this.toastr.info(error.msg, 'Info!', {
            closeButton: true,
            enableHtml: true,
          });
          console.log("Validation error")
          console.dir(error)
          break;
      }
    }
  }





  isValidUnitNumber(): boolean {
    if (typeof this.tractor.unitNumber === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Unit Number <b>Required</b>' };
    }
    return true;
  }

  isValidTerminalLocation(): boolean {
    if (typeof this.tractor.terminalLocation === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Terminal Location <b>Required</b>' };
    }
    return true;
  }

  isValidYear(): boolean {
    if (typeof this.tractor.year === 'undefined') {
      throw { type: 'warning', msg: 'Tractor year <b>Required</b>' };
    }
    return true;
  }

  isValidDatepurchased(): boolean {
    if (typeof this.tractor.datePurchased === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Date Purchased <b>Required</b>' };
    }
    return true;
  }

  isValidMake(): boolean {
    if (typeof this.tractor.make === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Maker <b>Required</b>' };
    }
    return true;
  }

  isValidPurchasePrice(): boolean {
    if (typeof this.tractor.purchasePrice === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Purchase Price <b>Required</b>' };
    }
    return true;
  }

  isValidModel(): boolean {
    if (typeof this.tractor.model === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Model <b>Required</b>' };
    }
    return true;
  }

  isValidLenght(): boolean {
    if (typeof this.tractor.length === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Length <b>Required</b>' };
    }
    return true;
  }

  isValidHeight(): boolean {
    if (typeof this.tractor.height === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Height <b>Required</b>' };
    }
    return true;
  }

  isValidFuelType(): boolean {
    if (typeof this.tractor.fuelType === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Fuel Type <b>Required</b>' };
    }
    return true;
  }

  isValidwidth(): boolean {
    if (typeof this.tractor.fuelType === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Width <b>Required</b>' };
    }
    return true;
  }

  isValidTcheckCardNo(): boolean {
    if (typeof this.tractor.TcheckCardNo === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Tcheck Card No <b>Required</b>' };
    }
    return true;
  }

  isValidLadenWeight(): boolean {
    if (typeof this.tractor.TcheckCardNo === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Laden Weight <b>Required</b>' };
    }
    return true;
  }

  isValidStateOfRegistration(): boolean {
    if (typeof this.tractor.stateOfRegistration === 'undefined') {
      throw { type: 'warning', msg: 'Tractor State Of Registration <b>Required</b>' };
    }
    return true;
  }

  isValidUnladenWeight(): boolean {
    if (typeof this.tractor.unladenWeight === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Unladen Weight <b>Required</b>' };
    }
    return true;
  }

  isValidLicenseNumber(): boolean {
    if (typeof this.tractor.licenseNumber === 'undefined') {
      throw { type: 'warning', msg: 'Tractor License Number <b>Required</b>' };
    }
    return true;
  }

  isValidAxles(): boolean {
    if (typeof this.tractor.axles === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Axles <b>Required</b>' };
    }
    return true;
  }

  isValidLicenseExpirationDate(): boolean {
    if (typeof this.tractor.licenseExpirationDate === 'undefined') {
      throw { type: 'warning', msg: 'Tractor License Expiration Date<b>Required</b>' };
    }
    return true;
  }

  isValidSteerTireSize(): boolean {
    if (typeof this.tractor.steerTireSize === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Steer Tire Size <b>Required</b>' };
    }
    return true;
  }

  isValidEngineManufacturer(): boolean {
    if (typeof this.tractor.engineManufacturer === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Engine Manufacturer <b>Required</b>' };
    }
    return true;
  }

  isValidDriveTireSize(): boolean {
    if (typeof this.tractor.driveTireSize === 'undefined') {
      throw { type: 'warning', msg: 'Tractor drive Tire Size <b>Required</b>' };
    }
    return true;
  }

  isValidEngineModel(): boolean {
    if (typeof this.tractor.engineModel === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Engine Model <b>Required</b>' };
    }
    return true;
  }

  isValidAccumulatedMiles(): boolean {
    if (typeof this.tractor.accumulatedMiles === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Accumulated Miles <b>Required</b>' };
    }
    return true;
  }

  isValidEngineSerialNumber(): boolean {
    if (typeof this.tractor.engineSerialNumber === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Engine Serial Number <b>Required</b>' };
    }
    return true;
  }

  isValidinsurancePolicyNumber(): boolean {
    if (typeof this.tractor.insurancePolicyNumber === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Insurance Policy Number <b>Required</b>' };
    }
    return true;
  }

  isValidDriverId(): boolean {
    if (typeof this.tractor.DriverId === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Driver Id <b>Required</b>' };
    }
    return true;
  }

  isValidAfterTreatmentYearWarranty(): boolean {
    if (typeof this.tractor.afterTreatmentYearWarranty === 'undefined') {
      throw { type: 'warning', msg: 'Tractor After Treatmen Year Warranty <b>Required</b>' };
    }
    return true;
  }

  isValidInsuranceName(): boolean {
    if (typeof this.tractor.insuranceName === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Insurance Name <b>Required</b>' };
    }
    return true;
  }

  isValidBaseWarrantyMiles(): boolean {
    if (typeof this.tractor.baseWarrantyMiles === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Base Warranty Miles <b>Required</b>' };
    }
    return true;
  }

  isValidBaseManuFactureWarranty(): boolean {
    if (typeof this.tractor.baseWarrantyMiles === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Manufacture Warranty Miles <b>Required</b>' };
    }
    return true;
  }

  isValidEngineManufactureWarranty(): boolean {
    if (typeof this.tractor.engineManufactureWarranty === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Engine Manufacture Warranty <b>Required</b>' };
    }
    return true;
  }

  isValidBaseWarrantyYear(): boolean {
    if (typeof this.tractor.baseWarrantyYear === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Base Warranty Year <b>Required</b>' };
    }
    return true;
  }

  isValidEngineYearWarranty(): boolean {
    if (typeof this.tractor.engineYearWarranty === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Engine Year Warranty Year <b>Required</b>' };
    }
    return true;
  }

  isValidEngineMilesWarranty(): boolean {
    if (typeof this.tractor.engineMilesWarranty === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Engine Miles Warranty <b>Required</b>' };
    }
    return true;
  }

  isValidAfterTreatmentMilesWarranty(): boolean {
    if (typeof this.tractor.afterTreatmentMilesWarranty === 'undefined') {
      throw { type: 'warning', msg: 'Tractor After Treatment Miles <b>Required</b>' };
    }
    return true;
  }

  isValidAfterTreatmentManufactureWarranty(): boolean {
    if (typeof this.tractor.afterTreatmentManufactureWarranty === 'undefined') {
      throw { type: 'warning', msg: 'Tractor After Treatment Manu Facture Warranty <b>Required</b>' };
    }
    return true;
  }

  isValidEquipmentCode(): boolean {
    if (typeof this.tractor.equipmentCode === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Equipment Code <b>Required</b>' };
    }
    return true;
  }

  isValidOwner(): boolean {
    if (typeof this.tractor.owner === 'undefined') {
      throw { type: 'warning', msg: 'Tractor owner <b>Required</b>' };
    }
    return true;
  }

  isValidMobileDeviceType(): boolean {
    if (typeof this.tractor.owner === 'undefined') {
      throw { type: 'warning', msg: 'Tractor owner <b>Required</b>' };
    }
    return true;
  }

  isValidMobileDeviceNumber(): boolean {
    if (typeof this.tractor.mobileDeviceType === 'undefined') {
      throw { type: 'warning', msg: 'Tractor Mobile Device Number <b>Required</b>' };
    }
    return true;
  }


  //

  validateTractorFields(): boolean {
    try {
      this.isContentUnitNumber();
      this.isContentYear();
      this.isContentMake();
      this.isContentpurchasePrice();
      this.isContentLength();
      this.isContentHeight();
      this.isContentWidth();
      this.isContentTcheckCardNo();
      this.isContentLadenWeight();
      this.isContentUnladenWeight();
      this.isContentLicenseNumber();
      this.isContentAxles();
      this.isContentSteerTireSize();
      this.isContentEngineManufacturer();
      this.isContentDriveTireSize();
      this.isContentEngineModel();
      this.isContentAccumulatedMiles();
      this.isContentengineSerialNumber();
      this.isContentinsurancePolicyNumber();
      this.isContentAfterTreatmentYearWarranty();
      this.isContentInsuranceName();
      this.isContentBaseWarrantyMiles();
      this.isContentMobileDeviceNumber();
      return true;
    } catch (error) {
      switch (error.type) {
        case 'warning':
          this.toastr.warning(error.msg, 'Alert!', {
            closeButton: true,
            enableHtml: true,
          });
          throw error;
        default:
          this.toastr.info(error.msg, 'Info!', {
            closeButton: true,
            enableHtml: true,
          });
          throw error;
      }
    }
  }



  isContentUnitNumber(): boolean {
    if (this.tractor.unitNumber && isNaN(Number(this.tractor.unitNumber))) {
      throw { type: 'warning', msg: 'Unit Number can only be numbers.' };
    }
    return true;
  }

  isContentYear(): boolean {
    if (this.tractor.year && isNaN(Number(this.tractor.year))) {
      throw { type: 'warning', msg: 'Year can only be numbers.' };
    } else if (this.tractor.year && this.tractor.year.length > 4) {
      throw { type: 'warning', msg: 'YEAR format is not correct (YYYY)' };
    }
    return true;
  }

  isContentMake(): boolean {
    if (this.tractor.make && !this.tractor.make.match(this.letters)) {
      throw { type: 'warning', msg: 'Tractor Make can only be letters.' };
    }
    return true;
  }

  isContentpurchasePrice(): boolean {
    if (this.tractor.purchasePrice && isNaN(Number(this.tractor.purchasePrice))) {
      throw { type: 'warning', msg: 'Purchase Price can only be numbers.' };
    }
    return true;
  }

  isContentLength(): boolean {
    if (this.tractor.length && isNaN(Number(this.tractor.length))) {
      throw { type: 'warning', msg: 'Tractor length can only be numbers.' };
    }
    return true;
  }

  isContentHeight(): boolean {
    if (this.tractor.height && isNaN(Number(this.tractor.height))) {
      throw { type: 'warning', msg: 'Tractor height can only be numbers.' };
    }
    return true;
  }

  isContentWidth(): boolean {
    if (this.tractor.width && isNaN(Number(this.tractor.width))) {
      throw { type: 'warning', msg: 'Tractor width can only be numbers.' };
    }
    return true;
  }

  isContentTcheckCardNo(): boolean {
    if (this.tractor.TcheckCardNo && isNaN(Number(this.tractor.TcheckCardNo))) {
      throw { type: 'warning', msg: 'Tractor Tcheck Card No can only be numbers.' };
    }
    return true;
  }

  isContentLadenWeight(): boolean {
    if (this.tractor.ladenWeight && isNaN(Number(this.tractor.ladenWeight))) {
      throw { type: 'warning', msg: 'Tractor Laden Weight can only be numbers.' };
    }
    return true;
  }

  isContentUnladenWeight(): boolean {
    if (this.tractor.unladenWeight  && isNaN(Number(this.tractor.unladenWeight))) {
      throw { type: 'warning', msg: 'Tractor Unladen Weight can only be numbers.' };
    }
    return true;
  }

  isContentLicenseNumber(): boolean {
    if (this.tractor.licenseNumber  && isNaN(Number(this.tractor.licenseNumber))) {
      throw { type: 'warning', msg: 'Tractor License Number can only be numbers.' };
    }
    return true;
  }

  isContentAxles(): boolean {
    if (this.tractor.axles && isNaN(Number(this.tractor.axles))) {
      throw { type: 'warning', msg: 'Tractor Axles can only be numbers.' };
    }
    return true;
  }

  isContentSteerTireSize(): boolean {
    if (this.tractor.steerTireSize && isNaN(Number(this.tractor.steerTireSize))) {
      throw { type: 'warning', msg: 'Tractor Steer Tire Size can only be numbers.' };
    }
    return true;
  }

  isContentEngineManufacturer(): boolean {
    if (this.tractor.engineManufacturer && !this.tractor.engineManufacturer.match(this.letters)) {
      throw { type: 'warning', msg: 'Tractor Engine Manufacturer can only be numbers.' };
    }
    return true;
  }

  isContentDriveTireSize(): boolean {
    if (this.tractor.driveTireSize && isNaN(Number(this.tractor.driveTireSize))) {
      throw { type: 'warning', msg: 'Tractor Drive Tire Size can only be numbers.' };
    }
    return true;
  }

  isContentEngineModel(): boolean {
    if (this.tractor.engineModel && isNaN(Number(this.tractor.engineModel))) {
      throw { type: 'warning', msg: 'Tractor Engine Model can only be numbers.' };
    }
    return true;
  }

  isContentAccumulatedMiles(): boolean {
    if (this.tractor.accumulatedMiles && isNaN(Number(this.tractor.accumulatedMiles))) {
      throw { type: 'warning', msg: 'Tractor Accumulated Miles can only be numbers.' };
    }
    return true;
  }

  isContentengineSerialNumber(): boolean {
    if (this.tractor.engineSerialNumber && isNaN(Number(this.tractor.engineSerialNumber))) {
      throw { type: 'warning', msg: 'Tractor engine Serial Number can only be numbers.' };
    }
    return true;
  }

  isContentinsurancePolicyNumber(): boolean {
    if (this.tractor.insurancePolicyNumber && isNaN(Number(this.tractor.insurancePolicyNumber))) {
      throw { type: 'warning', msg: 'Tractor insurance Policy Number can only be numbers.' };
    }
    return true;
  }

  isContentAfterTreatmentYearWarranty(): boolean {
    if (this.tractor.afterTreatmentYearWarranty && isNaN(Number(this.tractor.afterTreatmentYearWarranty))) {
      throw { type: 'warning', msg: 'Year can only be numbers.' };
    } else if (this.tractor.afterTreatmentYearWarranty && this.tractor.afterTreatmentYearWarranty.length > 4) {
      throw { type: 'warning', msg: 'YEAR format is not correct (YYYY)' };
    }
    return true;
  }

  isContentInsuranceName(): boolean {
    if (this.tractor.insuranceName && !this.tractor.insuranceName.match(this.letters)) {
      throw { type: 'warning', msg: 'Tractor Insurance Name can only be letters.' };
    }
    return true;
  }

  isContentBaseWarrantyMiles(): boolean {
    if (this.tractor.baseWarrantyMiles && isNaN(Number(this.tractor.baseWarrantyMiles))) {
      throw { type: 'warning', msg: 'Tractor Base Warranty Miles can only be numbers.' };
    } else if (this.tractor.baseWarrantyMiles && this.tractor.baseWarrantyMiles.length > 4) {
      throw { type: 'warning', msg: 'Tracto Base Warranty Miles format is not correct (YYYY)' };
    }
    return true;
  }

  isContentEngineYearWarranty(): boolean {
    if (this.tractor.engineYearWarranty && isNaN(Number(this.tractor.engineYearWarranty))) {
      throw { type: 'warning', msg: 'Tractor Engine Year Warranty can only be numbers.' };
    } else if (this.tractor.engineYearWarranty && this.tractor.engineYearWarranty.length > 4) {
      throw { type: 'warning', msg: 'Tracto Base Engine Year Warranty format is not correct (YYYY)' };
    }
    return true;
  }

  isContentMobileDeviceNumber(): boolean {
    if (this.tractor.mobileDeviceNumber && isNaN(Number(this.tractor.mobileDeviceNumber))) {
      throw { type: 'warning', msg: 'Tractor mobile Device Number can only be numbers.' };
    }
    return true;
  }
  */
}
