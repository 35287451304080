import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class SpinnerService {
  numberOfOpenRequests = 0;
  constructor(private spinner: NgxSpinnerService) {}

  show() {
    this.numberOfOpenRequests++;
    this.spinner.show();
  }
  
  hide() {
    this.numberOfOpenRequests--;
    if (this.numberOfOpenRequests <= 0) {
      this.spinner.hide();
    }
  }
  
  forceHide() {
    this.numberOfOpenRequests = 0;
    this.spinner.hide();
  }
}
