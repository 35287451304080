import { Injectable } from "@angular/core";

import { EquipmentService } from "./equipment.service";

@Injectable()
export class EquipmentResolver {
  constructor(private EquipmentService: EquipmentService) {}

  resolve() {
    return this.EquipmentService.getEquipmentList();
  }
}
