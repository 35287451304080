<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label for="userName">Username:</label>
    <input style="text-transform: none !important"
           type="text"
           class="form-control"
           formControlName="username"
           required
           [ngClass]="{ 'is-invalid': hasSubmitted && username.invalid }"
           id="username" />
  </div>
  <div class="form-group">
    <label for="email">Email:</label>
    <input type="email"
           class="form-control"
           id="userEmail"
           formControlName="email"
           required
           [ngClass]="{ 'is-invalid': hasSubmitted && email.invalid }" />
  </div>
  <div class="form-group">
    <label for="userName1">Name:</label>
    <input type="textr" class="form-control" id="userName1" formControlName="name" />
  </div>
  <div class="form-group">
    <label for="userLastName">lastName:</label>
    <input type="text" class="form-control" id="userEmailSignature" aria-label="lastName" formControlName="lastName" />
  </div>
  <div class="form-group">
    <label for="userEmailSignature">Email Signature:</label>
    <textarea rows="6" style="text-transform: none !important" class="form-control" id="userEmailSignature" formControlName="emailSignature"></textarea>
  </div>
  <div class="form-group">
    <label for="roleId">Role:</label>
    <select id="roleId"
            name="roleId"
            formControlName="roleId"
            class="form-control"
            aria-label="selectRole"
            required
            [ngClass]="{ 'is-invalid': hasSubmitted && roleId.invalid }">
      <option *ngFor="let role of allRoles" [ngValue]="role.id">
        {{ role.rolName }}
      </option>
    </select>
  </div>
  <p-footer>
    <div class="footer">
      <button type="button" class="btn btn-secondary mr-3" (click)="closeModalNewUser()">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary createBtn">Save New User</button>
    </div>
  </p-footer>
</form>
