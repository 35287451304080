import { Component, OnInit } from "@angular/core";
import { LessorService } from "../shared/lessor.service";
import { Router } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { Lessor } from "@app/shared/models/Lessor.model";
import { SelectItem } from "primeng/api";

@Component({
  selector: "app-lessorlist",
  templateUrl: "./lessorlist.component.html",
  styleUrls: ["./lessorlist.component.css"],
})
export class LessorlistComponent implements OnInit {
  errorMsg: any;
  lessorList: any = [];
  cols: any = [];
  filterElements: any = [];

  statusOptions: SelectItem[] = [
    { label: "ALL", value: null },
    { label: "ACTIVE", value: true },
    { label: "INACTIVE", value: false },
  ];

  constructor(
    private lessorService: LessorService,
    private router: Router,
    private _AuthService: authenticationService
  ) {}

  ngOnInit() {
    this.cols = [
      { field: "lessorId", header: "ID" },
      { field: "name", header: "Name" },
      { field: "phone", header: "Phone Number" },
      { field: "email", header: "Email" },
      { field: "active", header: "Status", type: "boolean" },
    ];

    this.lessorService.getLessorList(false).subscribe(
      (lessors: any) => {
        this.lessorList = lessors;
      },
      (resLessorListError) => {
        this.errorMsg = resLessorListError;
      }
    );
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  viewDetail(lessor: Lessor) {
    this.router.navigate(["/lessorinfo/", lessor.uuid]);
  }
}
