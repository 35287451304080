<div class="row">
  <div class="col-sm-6" *ngIf="deficit">
    <div>
      <div class="row form-group">
        <div class="col-sm-6">
          <label>Beginning Balance</label>
        </div>
        <div class="col-sm-6">
          <input
            type="number"
            class="form-control"
            [(ngModel)]="batchInfo.deficitBeforeBalance"
            disabled
          />
        </div>
      </div>
      <div class="row marginLabel form-group">
        <div class="col-sm-6">
          <label for="deposit">Deposit</label>
        </div>
        <div class="col-sm-6">
          <input
            type="number"
            class="form-control"
            id="deposit"
            [(ngModel)]="batchInfo.deficitAdd"
            [disabled]="!batchInfo.isOpen"
            (change)="depositChanged()"
          />
        </div>
      </div>
      <div class="row marginLabel form-group">
        <div class="col-sm-6">
          <label for="depositDescription">Deposit Description</label>
        </div>
        <div class="col-sm-6">
          <textarea
            id="depositDescription"
            class="form-control"
            [(ngModel)]="batchInfo.deficitDepositDescription"
            [disabled]="!batchInfo.isOpen"
            (change)="deficitChanged.emit()"
          ></textarea>
        </div>
      </div>
      <div class="row marginLabel form-group">
        <div class="col-sm-6">
          <label for="withdraw">Withdraw</label>
        </div>
        <div class="col-sm-6">
          <input
            id="withdraw"
            type="number"
            class="form-control"
            [(ngModel)]="batchInfo.deficitSubtract"
            [disabled]="!batchInfo.isOpen"
            (change)="withdrawChanged()"
          />
        </div>
      </div>
      <div class="row marginLabel form-group">
        <div class="col-sm-6">
          <label for="withdrawDescription">Withdraw Description</label>
        </div>
        <div class="col-sm-6">
          <textarea
            id="withdrawDescription"
            class="form-control"
            [disabled]="!batchInfo.isOpen"
            [(ngModel)]="batchInfo.deficitWithdrawDescription"
            (change)="deficitChanged.emit()"
          ></textarea>
        </div>
      </div>
      <div class="row marginLabel form-group">
        <div class="col-sm-6">
          <label for="Include">Deficit Subtotal</label>
        </div>
        <div class="col-sm-6">
          <input
            type="number"
            class="form-control"
            id="Include"
            [ngModel]="batchInfo.deficitAdd + batchInfo.deficitSubtract"
            disabled
          />
        </div>
      </div>
      <div class="row marginLabel form-group">
        <div class="col-sm-6">
          <label for="Include">Ending Balance</label>
        </div>
        <div class="col-sm-6">
          <input
            type="number"
            class="form-control"
            id="Include"
            [ngModel]="
              batchInfo.deficitBeforeBalance + batchInfo.deficitAdd + batchInfo.deficitSubtract
            "
            disabled
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div>
      <p-table [value]="deficitHistory" [paginator]="false" [autoLayout]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Batch</th>
            <th>Beginning</th>
            <th>Deposit</th>
            <th>Withdraw</th>
            <th>End</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-batch>
          <tr>
            <td>{{ batch.batchNo }}</td>
            <td>{{ batch.beforeBalance | currency }}</td>
            <td>{{ batch.add | currency }}</td>
            <td>{{ batch.subtract | currency }}</td>
            <td>{{ batch.afterBalance | currency }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
