import { Component, EventEmitter, HostListener, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TcheckService } from "../tcheck.service";
import { FileUploadService } from "@app/shared/services/files/fileUpload.service";

@Component({
  selector: "app-tcheck-import-modal",
  templateUrl: "./tcheck-import-modal.component.html",
  styleUrls: ["./tcheck-import-modal.component.css"],
})
export class TcheckImportModalComponent implements OnInit {
  dragAreaClass: String = "dragarea";
  maxFiles: number = 5;
  globalFiles: any = [];
  displayImportModal: boolean = false;
  validExtensions: string[] = [".dat", ".aspx", ".xls", ".xlsx"];

  @Output() onFileUpload = new EventEmitter<any>();

  constructor(
    private tcheckService: TcheckService,
    private fileUploadService: FileUploadService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.displayImportModal = false;
    this.globalFiles = [];
  }

  onFileChange(event: Event & { target: HTMLInputElement }) {
    const files = event.target.files;
    const parsedFiles = this.deleteDuplicatedFiles(files);

    if (!this.validateFilesExtension(parsedFiles)) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      this.globalFiles.push(files[i]);
    }

    // remove files from input
    event.target.value = "";
  }

  deleteDuplicatedFiles(incomingFiles: FileList) {
    let currentFilesNames = this.globalFiles.map((globalFile) => globalFile.name);
    let incomingFilesWithNewName = [];
    for (let i = 0; i < incomingFiles.length; i++) {
      let incomingFile = incomingFiles[i];
      if (!currentFilesNames.includes(incomingFile.name)) {
        incomingFilesWithNewName.push(incomingFile);
      } else {
        this.toastr.warning(incomingFile.name + " already added", "Warning");
      }
    }
    return incomingFilesWithNewName;
  }

  validateFilesExtension(files: any[]) {
    let aspxFilesAmount = 0;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = file.name.split(".").pop().toLowerCase();
      if (!this.validExtensions.includes("." + extension)) {
        this.toastr.warning("Only DAT/ASPX and Excel files are allowed", "Warning");
        return false;
      }
      if (extension === "aspx") {
        aspxFilesAmount++;
        if (aspxFilesAmount > 1) {
          this.toastr.warning("Only one ASPX file is allowed", "Warning");
          return false;
        }
      }
    }
    return true;
  }

  @HostListener("dragover", ["$event"]) onDragOver(event) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }

  @HostListener("dragenter", ["$event"]) onDragEnter(event) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }

  @HostListener("dragend", ["$event"]) onDragEnd(event) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }

  @HostListener("dragleave", ["$event"]) onDragLeave(event) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    var files = event.dataTransfer.files;
    files = this.deleteDuplicatedFiles(files);
    if (!this.validateFilesExtension(files)) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      this.globalFiles.push(files[i]);
    }
  }

  deleteFile(index: number) {
    this.globalFiles.splice(index, 1);
  }

  uploadFiles() {
    this.fileUploadService.uploadFiles(this.globalFiles, "others").subscribe((result) => {
      const aspxPath = result.find((file) => file.type.toUpperCase() === "ASPX")?.path;
      const datPaths = result
        .filter((file) => file.type.toUpperCase() === "DAT")
        .map((file) => file.path);
      const xlsPaths = result
        .filter((file) => file.type.toUpperCase() === "XLS" || file.type.toUpperCase() === "XLSX")
        .map((file) => file.path);

      if (datPaths.length > 0 && !aspxPath) {
        this.toastr.error("ASPX file not found", "Error");
        return;
      }

      this.tcheckService.uploadBatch(aspxPath, datPaths, xlsPaths).subscribe(
        (records: any) => {
          this.tcheckService
            .saveScreenRecords(
              records.map((record) => {
                delete record.id;
                delete record.createdAt;
                delete record.updatedAt;
                delete record.deletedAt;
                return record;
              })
            )
            .subscribe((records: any) => {
              for (let index = 0; index < records.length; index++) {
                records[index].index = index;
              }
              // emit records to parent component
              this.onFileUpload.emit(records);
              this.closeModal();
            });
        },
        (error) => {
          this.toastr.error(error.message, "Error");
        }
      );
    });
  }
}
