import { Component, Input, OnInit } from "@angular/core";
import { authenticationService } from "@app/login/auth.service";
import { ToastrService } from "ngx-toastr";
import { Permissions } from "@models/permissions.enum";
import { SelectItem } from "primeng/api";
import { ChargetypesService } from "@app/chargeType/shared/chargetypes.service";
import { InsuranceSurchargeViewModel } from "@app/shared/models/rates/InsuranceSurchargeViewModel.model";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-edit-insurance-surcharges",
  templateUrl: "./edit-insurance-surcharges.component.html",
  styleUrls: ["./edit-insurance-surcharges.component.scss"],
})
export class EditInsuranceSurchargesComponent implements OnInit {
  @Input() rate: InsuranceSurchargeViewModel;
  @Input() rateId: number;
  @Input() rateType: string;
  @Input() isEditing: boolean;
  @Input() isCreating: boolean;

  includedChargesList: SelectItem[] = [];
  includedCharges: string[];
  includedChargeNames: string[];
  displayCharges: string;
  amount: number;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authService: authenticationService
  ) {}

  ngOnInit(): void {
    if (!this.isCreating) {
      this.loadRates(this.rate);
    }

    this.includedChargesList = this.route.snapshot.data["chargeTypes"];
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  validate = () => {
    if (!this.rate.name) {
      this.toastr.warning("Rate Name is required");
      return false;
    }
    if (!this.rate.chargeTypeId) {
      this.toastr.warning("Charge type is required");
      return false;
    }
    return true;
  };

  loadRates(surcharge: InsuranceSurchargeViewModel) {
    this.amount = surcharge.amount;
    this.includedCharges = surcharge.includedChargeTypes.map((chargeType) => chargeType.id);
    this.includedChargeNames = surcharge.includedChargeTypes.map((chargeType) => chargeType.name);
    this.displayCharges = this.includedCharges.map((item) => item["name"]).join(", ");
  }

  // Build the object to send to the server
  buildRatesObject = () => {
    const rateObject = {
      ...this.rate,
      clients: this.rate.clients.map((client) => ({ id: client.id })),
      amount: this.amount,
      includedChargeTypes: this.includedCharges.map((charge) => ({ id: charge })),
    };

    return rateObject;
  };
}
