import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { ProductService } from "@app/product/shared/products.service";
import { StatesServices } from "@app/shared/services/states/states.service";
import { ToastrService } from "ngx-toastr";
import { Permissions } from "@models/permissions.enum";
import { SelectItem } from "primeng/api";
import { saveAs } from "file-saver";
import { BoundedTableComponent } from "../../bounded-table/bounded-table.component";
import { DriverViewModel } from "@app/shared/models/drivers/DriverViewModel.model";
import { DriverRateViewModel } from "@app/shared/models/rates/DriverRateViewModel.model";
import {
  ClientDriverRateViewModel,
  LoadingChargeViewModel,
} from "@app/shared/models/rates/ClientDriverRateViewModel.model";

@Component({
  selector: "app-edit-client-rates",
  templateUrl: "./edit-client-rates.component.html",
  styleUrls: ["./edit-client-rates.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditClientRatesComponent implements OnInit {
  @ViewChild("mileRatesTable") mileRatesTable: BoundedTableComponent;
  @ViewChild("loadChargesTable") loadChargesTable: BoundedTableComponent;
  @ViewChild("unloadChargesTable") unloadChargesTable: BoundedTableComponent;
  @ViewChild("delayedChargesTable") delayedChargesTable: BoundedTableComponent;

  @Input() rate: ClientDriverRateViewModel;
  @Input() rateId: number;
  @Input() rateType: string;
  @Input() isEditing: boolean;
  @Input() isCreating: boolean;

  // dropdown data
  drivers: DriverViewModel[] = [];
  equipmentTypes = [];
  chargeTypes: SelectItem[] = [];
  typesOfProducts: any[] = [];
  // productTypes is either equipmentTypes or typesOfProducts based on what
  // the current productType is
  productTypes = [];
  allRatingUnits: any[];

  // table columns
  loadColsSingleFreeTime: any[] = [];
  loadColsMultipleFreeTime: any[] = [];
  loadCols: any[] = [];
  unloadCols: any[] = [];
  delayCols: any[] = [];
  stopChargeCols: any[] = [];

  // rate by
  selectedProducts: any[] = [];
  selectedProductNames: string[] = [];
  selectedProductCols: { name: string; type: string }[] = [];

  selectedDrivers: any[] = [];

  // rates
  mileRates: any[] = [];
  singleExcessMilesRates: any = {};

  loadCharges: any[] = [];
  unloadCharges: any[] = [];
  delayedCharges: any[] = [];

  payOrCharges: string;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private productService: ProductService,
    private statesService: StatesServices,
    private authService: authenticationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // initialize dropdowns
    this.allRatingUnits = ["US POUNDS", "US TONS", "US GALLONS", "STANDARD CUBIC FEET"];

    this.loadColsSingleFreeTime = [
      { field: "freeTime", name: "Free Time (Hours)", type: "number" },
      { field: "pricePerHour", name: "Price/Hour", type: "dollar" },
      {
        field: "timeInterval",
        name: "Interval (Minutes)",
        type: "number",
      },
      { field: "intervalPrice", name: "Interval Price", type: "readonly" },
      { field: "stop1", name: "Stop 1", type: "number" },
      { field: "stop2", name: "Stop 2", type: "number" },
      { field: "stop3", name: "Stop 3", type: "number" },
      { field: "stop4", name: "Stop 4+", type: "number" },
    ];

    this.loadColsMultipleFreeTime = [
      { field: "pricePerHour", name: "Price/Hour", type: "dollar" },
      {
        field: "timeInterval",
        name: "Interval (Minutes)",
        type: "number",
      },
      { field: "intervalPrice", name: "Interval Price", type: "readonly" },
      { field: "stop1", name: "Stop 1", type: "number" },
      { field: "stop1FreeTime", name: "Stop 1 Free Time", type: "number" },
      { field: "stop2", name: "Stop 2", type: "number" },
      { field: "stop2FreeTime", name: "Stop 2 Free Time", type: "number" },
      { field: "stop3", name: "Stop 3", type: "number" },
      { field: "stop3FreeTime", name: "Stop 3 Free Time", type: "number" },
      { field: "stop4", name: "Stop 4+", type: "number" },
      { field: "stop4FreeTime", name: "Stop 4+ Free Time", type: "number" },
    ];

    this.loadCols = this.loadColsSingleFreeTime;
    this.unloadCols = this.loadColsSingleFreeTime;
    this.delayCols = this.loadColsSingleFreeTime;

    if (this.isCreating) {
      // Initialize the rate object
      this.rate.weightOrDistance = "WEIGHT";
      this.rate.equipmentOrProduct = "EQUIPMENT";
      this.rate.rateByUnits = "US TONS";
      this.rate.decimals = 2;
    }

    // load in data for dropdowns
    this.drivers = this.route.snapshot.data["drivers"].filter((driver) => driver.isActive);
    this.equipmentTypes = this.route.snapshot.data["equipmentTypes"];
    this.chargeTypes = this.route.snapshot.data["chargeTypes"];
    this.productService.listDropDown().subscribe((types: any[]) => {
      this.typesOfProducts = types;
      this.updateProductTypes();

      // we need to do this after we've gotten the product types
      if (!this.isCreating) {
        if (this.rateType === "CLIENT") {
          this.loadRates();
          this.payOrCharges = "Charges";
        } else if (this.rateType === "DRIVER") {
          this.rate = this.rate as DriverRateViewModel;
          this.loadRates();
          this.payOrCharges = "Pay";
        }
      }

      this.cdr.markForCheck();
    });
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this.authService.hasPermission(permission);
  }

  updateProductTypes() {
    switch (this.rate.equipmentOrProduct) {
      case "EQUIPMENT":
        this.productTypes = this.equipmentTypes.sort((a, b) =>
          a.itemName.localeCompare(b.itemName)
        );
        break;
      case "PRODUCT":
        this.productTypes = this.typesOfProducts.sort((a, b) =>
          a.itemName.localeCompare(b.itemName)
        );
        break;
    }
  }

  filterObject(object: any, names: string[], defaultValue: any) {
    const oldValues = object;
    let newValues = {};
    for (let name of names) {
      newValues[name] = oldValues[name] || defaultValue;
    }
    return newValues;
  }

  onSelectedProductsChange() {
    this.selectedProductNames = this.selectedProducts.map((product) => product.itemName);
    this.selectedProductCols = this.selectedProducts
      .map((product) => ({
        name: product.itemName.toUpperCase(),
        field: product.itemName.toUpperCase(),
        type: "dollar",
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const names = this.selectedProductNames;

    // update the values in all the tables that just had a column added/removed
    this.mileRates = this.mileRates.map((rate) => {
      const newValues = this.filterObject(rate.values, names, 0);
      return {
        ...rate,
        values: newValues,
      };
    });

    this.singleExcessMilesRates = this.filterObject(this.singleExcessMilesRates, names, 0);

    this.cdr.markForCheck();
  }

  onUndelayedMaxChange(max: number) {
    // change the floor of delayed charges to be max+1
    this.delayedCharges;
    if (this.delayedCharges && this.delayedCharges[0]) {
      this.delayedCharges[0].floor = max + 1;
    }
  }

  ratingTypeChanged() {
    // clear the current selected products, and update the options
    this.selectedProducts = [];
    this.onSelectedProductsChange();
    this.updateProductTypes();
  }

  /**
   * For parsing the client rate object into this page's values
   */
  getDropdownValue(options: any[], items: any[], optionsLabel: string, itemLabel: string) {
    return items.map((item) => {
      return options.find((option) => option[optionsLabel] == item[itemLabel]);
    });
  }

  mapRatesToTable(rates: any[]) {
    let rows = [];
    let bounds = {};

    for (let item of rates) {
      const { floor, roof, kind, rate } = item;
      const boundsKey = `${floor}-${roof}`;

      if (!bounds[boundsKey]) {
        bounds[boundsKey] = { floor, roof, values: {} };
      }

      bounds[boundsKey].values[kind] = rate;
    }

    rows = Object.values(bounds);
    rows = rows.sort((row1, row2) => row1.floor - row2.floor);

    return rows;
  }

  mapExcessRatesToRow(rates: any[], type: string) {
    const row = {};
    row["Type"] = type;
    const filtered = rates.filter((rate) => rate.type === type);
    for (let rateObject of filtered) {
      const { kind, rate } = rateObject;
      row[kind] = rate;
    }
    return row;
  }

  mapChargesToRow(charges: LoadingChargeViewModel[], type: string) {
    if (!charges) return [];

    charges = charges.filter((charge) => charge.loadingChargeType === type);

    let rows = [];
    let bounds = {};

    // create the row objects
    for (let [i, item] of charges.entries()) {
      const { milesFloor, milesRoof } = item;
      let boundsKey: number | string;

      if (milesFloor === undefined || milesRoof === undefined) {
        boundsKey = i;
        if (!bounds[boundsKey]) {
          bounds[boundsKey] = { values: {} };
        }
      } else {
        boundsKey = `${milesFloor}-${milesRoof}`;
        if (!bounds[boundsKey]) {
          bounds[boundsKey] = {
            floor: milesFloor,
            roof: milesRoof,
            values: {},
          };
        }
        delete item.milesFloor;
        delete item.milesRoof;
        delete item.id;
      }

      for (let [field, value] of Object.entries(item)) {
        bounds[boundsKey].values[field] = Number(value);
      }
    }

    rows = Object.values(bounds);
    rows = rows.sort((row1, row2) => row1.milesFloor - row2.milesFloor);

    return rows;
  }

  updateLoadsTable(table: any[], setFreeTimePerStop: boolean) {
    this.updateLoadTableColumns();

    if (setFreeTimePerStop) {
      // add the free time column to the table
      table = table.map((row) => ({
        ...row,
        freeTime: 0,
      }));
    } else {
      // remove the free time column from the table
      // add the per stop free time columns
      table = table.map((row) => ({
        ...row,
        freeTime: 0,
        stop1FreeTime: 0,
        stop2FreeTime: 0,
        stop3FreeTime: 0,
        stop4FreeTime: 0,
      }));
    }
  }

  updateLoadTableColumns() {
    if (this.rate.loadSetFreeTimePerStop) this.loadCols = this.loadColsMultipleFreeTime;
    else this.loadCols = this.loadColsSingleFreeTime;
    if (this.rate.unloadSetFreeTimePerStop) this.unloadCols = this.loadColsMultipleFreeTime;
    else this.unloadCols = this.loadColsSingleFreeTime;
    if (this.rate.delaySetFreeTimePerStop) this.delayCols = this.loadColsMultipleFreeTime;
    else this.delayCols = this.loadColsSingleFreeTime;
  }

  loadRates() {
    this.updateLoadTableColumns();
    this.updateProductTypes();

    if (this.rate.equipmentOrProduct === "PRODUCT")
      this.selectedProducts = this.getDropdownValue(
        this.productTypes,
        this.rate.products,
        "itemName",
        "name"
      );
    else if (this.rate.equipmentOrProduct === "EQUIPMENT")
      this.selectedProducts = this.getDropdownValue(
        this.equipmentTypes,
        this.rate.equipmentCodes,
        "itemName",
        "name"
      );
    this.onSelectedProductsChange();

    this.rate.validFromUtc = new Date(this.rate.validFromUtc);
    this.rate.validToUtc = this.rate.validToUtc ? new Date(this.rate.validToUtc) : null;

    this.mileRates = this.mapRatesToTable(this.rate.rateItems);
    this.singleExcessMilesRates = this.mapExcessRatesToRow(this.rate.excessRateItems, "SINGLE");

    this.loadCharges = this.mapChargesToRow(this.rate.loadingCharges, "LOADING");
    this.unloadCharges = this.mapChargesToRow(this.rate.loadingCharges, "UNLOADING");
    this.delayedCharges = this.mapChargesToRow(this.rate.loadingCharges, "DELAYED");

    this.selectedDrivers = this.getDropdownValue(this.drivers, this.rate.drivers, "id", "id");
  }

  // Build the object to send to the server
  // this function has to use arrow notation so that it will bind 'this'
  // when passed to the child
  buildRatesObject = () => {
    const buildRatesFromRows = (rows: any[]) => {
      // map from the rates table to individual rates items
      let rates = [];
      for (const row of rows) {
        const { floor, roof, values } = row;
        for (const [kind, rate] of Object.entries(values)) {
          const rateObject = { floor, roof, rate, kind };
          rates.push(rateObject);
        }
      }
      return rates;
    };

    const buildExcessMileRates = (row: any, type: string) => {
      const excessRates = [];
      for (const [kind, rate] of Object.entries(row)) {
        const excessRate = {
          type,
          kind,
          rate,
        };
        if (kind !== "Type") {
          excessRates.push(excessRate);
        }
      }
      return excessRates;
    };

    const buildLoadingCharges = (rows: any[], type: string): LoadingChargeViewModel[] => {
      return rows.map((row) => ({
        ...row.values,
        milesFloor: row.floor,
        milesRoof: row.roof,
        loadingChargeType: type,
      }));
    };

    const rateToSend = JSON.parse(JSON.stringify(this.rate));

    rateToSend.rateItems = buildRatesFromRows(this.mileRates);
    rateToSend.excessRateItems = [].concat(
      buildExcessMileRates(this.singleExcessMilesRates, "SINGLE")
    );

    rateToSend.origins = this.statesService.mapDropdownToLocations(this.rate.origins);
    rateToSend.destinations = this.statesService.mapDropdownToLocations(this.rate.destinations);

    const equipmentOrProduct = this.rate.equipmentOrProduct;
    const productIds = this.selectedProducts.map((product) => ({ id: product.id }));
    rateToSend.products = equipmentOrProduct === "PRODUCT" ? productIds : [];
    rateToSend.equipmentCodes = equipmentOrProduct === "EQUIPMENT" ? productIds : [];

    rateToSend.drivers = this.selectedDrivers.map((driver) => ({ id: driver.id }));

    const load = buildLoadingCharges(this.loadCharges, "LOADING");
    const unload = buildLoadingCharges(this.unloadCharges, "UNLOADING");
    const delayed = buildLoadingCharges(this.delayedCharges, "DELAYED");

    rateToSend.loadingCharges = load.concat(unload).concat(delayed);

    return rateToSend;
  };

  validateDelayCharges = () => {
    if (!this.delayedCharges) return true;
    if (!this.delayedCharges[0]) return true;

    const warnings = [];

    let loadRoof = -1;
    let unloadRoof = -1;
    const delayedFloor = this.delayedCharges[0].floor;

    if (this.loadCharges && this.loadCharges[0])
      loadRoof = this.loadCharges[this.loadCharges.length - 1].roof;
    if (this.unloadCharges && this.unloadCharges[0])
      unloadRoof = this.unloadCharges[this.unloadCharges.length - 1].roof;

    if (delayedFloor != loadRoof + 1)
      warnings.push("Invalid Delay Charges floor/Load Charges roof");
    if (delayedFloor != unloadRoof + 1)
      warnings.push("Invalid Delay Charges floor/Unload Charges roof");

    if (!this.delayedChargesTable.validateRanges())
      warnings.push("Invalid Delay Charges ranges. Each row must be 1 greater than the prior.");

    if (warnings.length > 0) {
      for (const warning of warnings) {
        this.toastr.warning(warning);
      }
      return false;
    }

    return true;
  };

  validate = () => {
    if (!this.validateDelayCharges()) return false;

    const warnings = [];

    if (!this.selectedProducts) {
      warnings.push("You must select at least one equipment/product");
    }

    if (!this.rate.name) warnings.push("Rate Name is required");
    if (this.selectedProducts.length === 0)
      warnings.push("You must select at least one equipment/product");
    else {
      if (this.mileRatesTable && !this.mileRatesTable.validateRanges())
        warnings.push(`Invalid Miles Rates ranges. Each row must be 1 greater than the prior.`);
    }

    if (this.rate.weightOrDistance === "DISTANCE") {
      if (!this.rate.teamChargeTypeId) warnings.push("Team Miles Charge Type is required");
    }

    if (!this.rate.singleChargeTypeId) warnings.push("Single Miles Charge type is required");
    if (!this.rate.excessMilesChargeTypeId) warnings.push("Excess Miles Charge Type is required");
    if (!this.rate.stopChargeTypeId) warnings.push("Stop Charge Type is required");

    if (!this.rate.loadChargeTypeId) warnings.push("Load Charge Type is required");
    if (!this.rate.unloadChargeTypeId) warnings.push("Unload Charge Type is required");
    if (!this.rate.delayChargeTypeId) warnings.push("Delay Charge Type is required");
    if (this.unloadChargesTable && !this.unloadChargesTable.validateRanges())
      warnings.push("Invalid Unload Charges ranges. Each row must be 1 greater than the prior.");

    if (warnings.length > 0) {
      for (const warning of warnings) {
        this.toastr.warning(warning);
      }
      return false;
    }
    return true;
  };

  exportExcel(rows: any[], cols: any[], title: string) {
    if (rows === undefined || cols === undefined) {
      this.toastr.warning("Table is empty.", "Unable to Export", {
        closeButton: true,
        enableHtml: true,
      });
      return;
    }

    //Get readable column headers
    const exportRows = [];
    rows.forEach((row) => {
      let thisExportRow = {} as any;
      if (row.floor != undefined && row.roof != undefined) {
        thisExportRow.Miles = row.floor + "-" + row.roof;
      }

      if (row.values != undefined) {
        cols.forEach((col) => {
          thisExportRow[col.name.toUpperCase()] = row.values[col.field];
        });
      } else {
        thisExportRow = row;
      }

      exportRows.push(thisExportRow);
    });

    if (exportRows.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(exportRows);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.saveAsExcelFile(excelBuffer, title);
      });
    } else {
      this.toastr.warning("Table is empty.", "Unable to Export", {
        closeButton: true,
        enableHtml: true,
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
  }

  importExcel(event: any, buildFunction, cols: any[], table: any, fileUpload: any) {
    let reader = new FileReader();
    reader.readAsArrayBuffer(event.files[0]);
    reader.onload = (e) => {
      import("xlsx").then((xlsx) => {
        let data = new Uint8Array(reader.result as ArrayBuffer);
        let workbook = xlsx.read(data, { type: "array" });
        let sheet = workbook.Sheets[workbook.SheetNames[0]];
        let result = xlsx.utils.sheet_to_json(sheet);

        let exit = false;
        //Check that columns match
        cols.forEach((col) => {
          if (exit) {
            return;
          }
          result.forEach((resultRow: any[]) => {
            if (exit) {
              return;
            }
            if (resultRow[col.name.toUpperCase()] === undefined) {
              this.toastr.warning("Columns do not match this table.", "Unable to Import", {
                closeButton: true,
                enableHtml: true,
              });
              exit = true;
              return;
            }
          });
        });
        if (exit) {
          fileUpload.clear();
          return;
        }

        //Add to table
        try {
          let formattedResult = buildFunction(result);
          table.rows = this.mapRatesToTable(formattedResult);
          table.updatedRows();

          this.toastr.success("Columns successfully imported.", "Success", {
            closeButton: true,
            enableHtml: true,
          });
        } catch (error) {
          this.toastr.warning("Columns do not match this table.", "Unable to Import", {
            closeButton: true,
            enableHtml: true,
          });
        }

        fileUpload.clear();
      });
    };
  }

  loadBoundedExcelData(data: any[]) {
    let rates = [];
    for (const row of data) {
      const miles = row["Miles"].split("-");
      const [floor, roof] = miles;
      delete row["Miles"];

      for (const [kind, price] of Object.entries(row)) {
        const rate = { floor, roof, kind, rate: price };
        rates.push(rate);
      }
    }

    return rates;
  }
}
