<div class="  filterable tripTable container">
	<div class="textType">
	  <div align="left">
	  <h2>Schedules</h2>
	  </div>
	  <div class="btnRig">
		  <button id="edit" name="edit" class="btn btn-default" routerLink="/addschedule">Schedule Tractor</button>
	  </div>
	</div>
	<table class="table">
	  <thead>
		<tr class="filters filtersBar">
		  <th><input type="text" class="form-control" placeholder="ID"></th>
		  <th><input type="text" class="form-control" placeholder="Filter By Name"></th>
		  <th><input type="text" class="form-control" placeholder="Filter By Trailer"></th>
		  <th><input type="text" class="form-control" placeholder="Filter By Product"></th>
		  <th><input type="text" class="form-control" placeholder="Filter By Status"></th>
		  <th><input type="text" class="form-control" placeholder="Filter By Client"></th>
		  <th><input type="text" class="form-control" placeholder="Filter By Actual Location"></th>
		  <th></th>
		  <th></th>
		  <th></th>
		  
		</tr>
	  </thead>
	  <tbody>
		<tr class="titleTable">
		  <td class="topTable"> Schedule Number</td>
		  <td class="topTable">Start Date</td>
		  <td class="topTable">End Date</td>
		  <td class="topTable">Client</td>
		  <td class="topTable">Tractor Number</td>
		  <td class="topTable">Trailer Number</td>
		  <td class="topTable">Drivers / Team</td>
		  <td class="topTable">Product</td>
		  <td class="topTable">Status</td>
		  <td class="topTable"></td>
		<!--  <td class="topTable"></td>-->
		
	   
		</tr>
		<tr *ngFor="let schedule of schedulelist">
		  <td>{{schedule.scheduleNo}}</td>
		  <td>{{schedule.startDate}}</td>
		  <td>{{schedule.endDate}}</td>
		  <td>{{schedule.ClientId}}</td>
		  <td>{{schedule.TractorId}}</td>
		  <td>{{schedule.TrailerId}}</td>
		  <td>{{schedule.DriverId}} / {{schedule.TeamId}}</td>
		  <td>{{schedule.status}} </td>
		  <td> </td>
		  <td><a (click)="viewDetail(schedule)"><button type="button" class="btn btn-secondary">Details</button></a></td>
		<!--  <td></td>-->
		
		</tr>
	  </tbody>
  
	</table>
  </div>