export default [
  {
    field: "tractorNo",
    table: "Trip",
    displayName: "Tractor No",
    type: "string", // string | number | boolean | date?
    // requestedOptions: dropdown options from request
    // options: [], // dropdown options
  },
  {
    field: "trailerNo",
    table: "Trip",
    displayName: "Trailer No",
    type: "string",
  },
  {
    field: "trailerType",
    table: "Trip",
    displayName: "Trailer Type",
    type: "string",
    requestedOptions: "equipmentCodes",
  },
  {
    field: "productName",
    table: "Trip",
    displayName: "Product",
    type: "string",
    requestedOptions: "products",
  },
  {
    field: "originLocId",
    table: "Trip",
    displayName: "Origin Location Id",
    type: "string",
    requestedOptions: "locations",
  },
  {
    field: "locId",
    table: "TripStop",
    displayName: "Location Id",
    type: "string",
    requestedOptions: "locations",
  },
];
