import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment'
import { DomSanitizer } from '@angular/platform-browser';


@Injectable()
export class GetPictureService{

    constructor (    private sanitizer: DomSanitizer){
    }

  getPicturePath(path: string, docType: string) {
        return '/api/download/' + docType.toLowerCase() + '/' + path;
  }
    
    getPreviewImage(file){
        if(!file){
            return  'assets/img/lgt.png'
        }
        return this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file)));
    }
}

