import { Injectable } from "@angular/core";
import { HttpClient } from "../../shared/services/http/http-client.service";
import { throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";


@Injectable()
export class PeoplenetTestService {
  constructor(private _http: HttpClient) {}

  listTractorsLocations() {
    return this._http.get("api/PeopleNet/listCurrentTractorsLocations").pipe(
      map((res) => {
        return res
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
