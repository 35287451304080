import { Injectable } from "@angular/core";
import { MaskAttributes } from "@app/shared/models/MaskAttributes.model";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { createMask } from "@ngneat/input-mask";

@Injectable()
export class MaskService {
  constructor() { }
  containsSpecialCharacters(str) {
    var regex = /[~`!#@$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
    return regex.test(str);
  }
  getUsPhoneMask() {
    return createMask({ inputFormat: "(XXX) XXX-XXXX", mask: '(999) 999-9999', keepStatic: true });
  }
  getUSPhoneWithCountryCode() {
    return createMask({ inputFormat:"+1 (XXX) XXX-XXXX", mask: '+1 (999) 999-9999', keepStatic: true });
  }

  getInputDateOnlyMask() {
    return createMask<Date>({
      alias: "datetime",
      inputFormat: "mm/dd/yyyy",
      parser: (value: string) => {
        const values = value.split("/");
        const year = +values[2];
        const date = +values[1];
        const month = +values[0] - 1;

        return new Date(year, month, date);
      },
    });
  }

  getInputDateTimeMask() {
    return createMask<Date>({
      alias: "datetime",
      inputFormat: "mm/dd/yy HH:MM",
      parser: (value: string) => {
        const values = value.split("/");
        var yearTime = values[2].split(" ");
        var time = yearTime[1].split(":");

        var hours = +time[0];
        var minutes = +time[1];
        const year = 2000 + +yearTime[0];
        const date = +values[1];
        const month = +values[0] - 1;
        if (Number.isNaN(hours)) {
          hours = 0;
        }
        if (Number.isNaN(minutes)) {
          minutes = 0;
        }
        return new Date(year, month, date, hours, minutes);
      },
      formatter: (value: Date) => {
        if (!(value instanceof Date)) {
          value = new Date(value);
        }
        return value.toLocaleDateString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hourCycle: "h23",
        });
      },
    });
  }
  getDate() {
    return [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
  }
  getZipCode() {
    return [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
  }
  getSocialSecurityMask() {
    return [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      "-",
      /[0-9]/,
      /[0-9]/,
      "-",
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ];
  }
  isNumberEvent(event: KeyboardEvent) {
    const { key } = event;
    return (
      key == "1" ||
      key == "2" ||
      key == "3" ||
      key == "4" ||
      key == "5" ||
      key == "6" ||
      key == "7" ||
      key == "8" ||
      key == "9" ||
      key == "0"
    );
  }
  maskDate(event: KeyboardEvent, inputId: string, justDate: boolean, yearDigits: number) {
    if (!this.isNumberEvent(event)) return false;
    const element = <HTMLInputElement>document.getElementById(inputId);
    let value = element.value;
    if (element.selectionStart !== value.length) return false;
    let slashAmount: any = value.match(/\//g);
    let colonAmount: any = value.match(/\:/g);
    slashAmount ? (slashAmount = slashAmount.length) : (slashAmount = 0);
    colonAmount ? (colonAmount = colonAmount.length) : (colonAmount = 0);
    if (value.length === 2 && slashAmount < 1) {
      value = value + "/";
      element.value = value;
    }
    if (value.length === 5 && slashAmount && slashAmount < 2) {
      value = value + "/";
      element.value = value;
    }
    if (value.length === 8 && !justDate) {
      value = value + " ";
      element.value = value;
    }
    if (value.length === 11 && !colonAmount && !justDate) {
      value = value + ":";
      element.value = value;
    }
    if (justDate && value.length == 8 && yearDigits == 2) {
      value = value.slice(0, -1);
      element.value = value;
    }
    if (justDate && value.length == 10 && yearDigits == 4) {
      value = value.slice(0, -1);
      element.value = value;
    }
    return true;
  }

  defaultAttributes: MaskAttributes = {
    justDate: false,
    yearDigits: 2,
  };

  newMaskDate(event: KeyboardEvent, attributes?: MaskAttributes) {
    if (!attributes) attributes = this.defaultAttributes;
    const { justDate, yearDigits } = attributes;

    if (!this.isNumberEvent(event)) return false;
    const element = event.target as HTMLInputElement;
    let value = element.value;
    if (element.selectionStart !== value.length) return false;
    let slashAmount: any = value.match(/\//g);
    let colonAmount: any = value.match(/\:/g);
    slashAmount ? (slashAmount = slashAmount.length) : (slashAmount = 0);
    colonAmount ? (colonAmount = colonAmount.length) : (colonAmount = 0);
    if (value.length === 2 && slashAmount < 1) {
      value = value + "/";
      element.value = value;
    }
    if (value.length === 5 && slashAmount && slashAmount < 2) {
      value = value + "/";
      element.value = value;
    }
    if (value.length === 8 && !justDate) {
      value = value + " ";
      element.value = value;
    }
    if (value.length === 11 && !colonAmount && !justDate) {
      value = value + ":";
      element.value = value;
    }
    if (justDate && value.length == 8 && yearDigits == 2) {
      value = value.slice(0, -1);
      element.value = value;
    }
    if (justDate && value.length == 10 && yearDigits == 4) {
      value = value.slice(0, -1);
      element.value = value;
    }
    return true;
  }

  maskReferenceNumbers(event, inputId, mask) {
    let maskArray = mask.split("");

    var isFixed = function (maskArray, positionToEdit) {
      if (maskArray[positionToEdit] !== "X") {
        return true;
      } else {
        return false;
      }
    };

    var isDeletable = function (currentValueAsArray, positionToEdit) {
      if (isFixed(maskArray, positionToEdit)) {
        return false;
      } else {
        if (currentValueAsArray[positionToEdit] === "_") {
          return false;
        } else {
          return true;
        }
      }
    };

    var setCaretPosition = function (elem, caretPos) {
      elem.setSelectionRange(caretPos, caretPos);
      elem.focus();
    };

    const currentCursorPosition = event.target.selectionStart;
    let currentValueAsArray = (<HTMLInputElement>document.getElementById(inputId)).value.split("");

    if (event.target.selectionEnd != currentCursorPosition) {
      event.preventDefault();
      return;
    }
    if (this.containsSpecialCharacters(event.key)) {
      event.preventDefault();
      return;
    }

    if (event.code === "Delete") {
      if (!isDeletable(currentValueAsArray, currentCursorPosition)) {
        setCaretPosition(
          <HTMLInputElement>document.getElementById(inputId),
          currentCursorPosition + 1
        );
        event.preventDefault();
        return;
      } else {
        currentValueAsArray[currentCursorPosition] = "_";
        (<HTMLInputElement>document.getElementById(inputId)).value = currentValueAsArray.join("");
        setCaretPosition(
          <HTMLInputElement>document.getElementById(inputId),
          currentCursorPosition + 1
        );
        event.preventDefault();
        return (<HTMLInputElement>document.getElementById(inputId)).value;
      }
    }
    if (event.code === "Backspace") {
      if (!isDeletable(currentValueAsArray, currentCursorPosition - 1)) {
        setCaretPosition(
          <HTMLInputElement>document.getElementById(inputId),
          currentCursorPosition - 1
        );
        event.preventDefault();
        return;
      } else {
        currentValueAsArray[currentCursorPosition - 1] = "_";
        (<HTMLInputElement>document.getElementById(inputId)).value = currentValueAsArray.join("");
        setCaretPosition(
          <HTMLInputElement>document.getElementById(inputId),
          currentCursorPosition - 1
        );
        event.preventDefault();
        return (<HTMLInputElement>document.getElementById(inputId)).value;
      }
    }
    if (event.key.length > 1) {
      return;
    }
    if (isFixed(maskArray, currentCursorPosition)) {
      setCaretPosition(
        <HTMLInputElement>document.getElementById(inputId),
        currentCursorPosition + 1
      );
      event.preventDefault();
      return;
    } else {
      currentValueAsArray[currentCursorPosition] = event.key;
      (<HTMLInputElement>document.getElementById(inputId)).value = currentValueAsArray.join("");
      setCaretPosition(
        <HTMLInputElement>document.getElementById(inputId),
        currentCursorPosition + 1
      );
      event.preventDefault();
      return (<HTMLInputElement>document.getElementById(inputId)).value;
    }
  }

  getDecimalLimitMask(decimalLimit, allowTrailing = false) {
    const numberMask = createNumberMask({
      prefix: "",
      suffix: "",
      includeThousandsSeparator: false,
      allowDecimal: true,
      decimalSymbol: ".",
      decimalLimit: decimalLimit,
      integerLimit: null,
      requireDecimal: false,
      allowNegative: true,
      allowLeadingZeroes: false,
    });
    if (!allowTrailing) return numberMask;

    return (rawValue) => {
      const mask = numberMask(rawValue);
      if (mask.indexOf(".") < 0) mask.push(".");
      let decimals = mask.slice(mask.indexOf(".") + 1, mask.length);
      let decimalsNeeded = decimalLimit;
      for (let i = 0; i < decimals.length; i++) {
        if ("0".match(decimals[i])) {
          decimalsNeeded -= 1;
        }
      }
      for (let i = 0; i < decimalsNeeded; i++) {
        mask.push("0");
      }
      return mask;
    };
  }
}
