import { Injectable } from '@angular/core';

@Injectable()
export class CapitalLatterService { 
  constructor() { }

  person:{
    stel:"te",
    tos:"tex",
    pass:"e",
    text:"xe"
    ignore:{
      pass:"",
      text:""
    }
  }

  castCapitarLatter(param) {
    if (typeof param !== "undefined" && param != null) { 
      if (Object.prototype.toString.call(param) === "[object Object]") {
        return this.treeCapitalLatter(param);
      } else if (Object.prototype.toString.call(param) === "[object String]") {
        return this.uppercaseObjets(param);
      }else if(Object.prototype.toString.call(param) === "[object Array]"){
        return this.arrayCapitalLatter(param);
      }else if(Object.prototype.toString.call(param) === "[object Number]"){
        return param;
      }
    }
  }

  treeCapitalLatter(obj) {
    Object.keys(obj).forEach(function (key) {
      if (typeof obj[key] !== "undefined" && obj[key] != null) {
        if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
          let objChild = obj[key];
          this.objectCapitalLatter(objChild);
        } else if (Object.prototype.toString.call(obj[key]) === "[object String]") {
          obj[key] = obj[key].trim().toUpperCase();
        } else if (Object.prototype.toString.call(obj[key]) === "[object Array]") {
          if (typeof obj[key] !== "undefined" && obj[key] != null) {
            let objChild = obj[key];
            this.arrayCapitalLatter(objChild);
          }
        }
      }
    }.bind(this));

    return obj;
  }

  objectCapitalLatter(objChild) {
    Object.keys(objChild).forEach(function (key) {
      if (Object.prototype.toString.call(objChild) === "[object Object]") {
        this.treeCapitalLatter(objChild);
      }else if (Object.prototype.toString.call(objChild) === "[object String]") { 
        objChild[key] = objChild[key].toString().toUpperCase();
      }else if (Object.prototype.toString.call(objChild) === "[object Array]") {
        this.treeCapitalLatter(objChild);
      }

    }.bind(this));
    return objChild;
  }
  
  arrayCapitalLatter(objChild) {
    Object.keys(objChild).forEach(function (key) {
      let objChildArray = objChild[key];
      Object.keys(objChildArray).forEach(function (key) {
        if (Object.prototype.toString.call(objChildArray) === "[object Object]") {
          this.treeCapitalLatter(objChildArray);
        }else if (Object.prototype.toString.call(objChildArray) === "[object String]") { 
          objChildArray[key] = objChildArray[key].toString().toUpperCase();
        }else if (Object.prototype.toString.call(objChildArray) === "[object Array]") {
          this.treeCapitalLatter(objChildArray);
        }
      }.bind(this))
    }.bind(this));
    return objChild
  } 

  uppercaseObjets(objKey) {
    return objKey.trim().toUpperCase();
  }
 
}
