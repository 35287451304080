<div class="d-flex" style="margin-bottom: 1rem; align-items: center">
  <button type="button" class="btn btn-primary mr-3" (click)="updateRecords()">SAVE</button>
  <button type="button" class="btn btn-secondary mr-3" (click)="doingBulkUpdate = !doingBulkUpdate">
    Toggle bulk update
  </button>
  <div class="text-danger" *ngIf="numMissingChargeTypes > 0">
    {{ numMissingChargeTypes }} records are missing charge types.
  </div>
</div>
<div>
  <p-table
    #dt
    [columns]="cols"
    [value]="records"
    [scrollable]="true"
    scrollHeight="800px"
    [virtualScroll]="true"
    [virtualScrollItemSize]="58"
  >
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngIf="doingBulkUpdate" [style.width.px]="200" />
        <col *ngFor="let col of columns" [style.width.px]="col.width || 115" />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngIf="doingBulkUpdate">Bulk Update</th>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
        </th>
      </tr>
      <tr>
        <th *ngIf="doingBulkUpdate">
          <p-dropdown
            [style]="{ minWidth: '100%', maxWidth: '100%' }"
            [options]="chargeTypes"
            [(ngModel)]="bulkUpdateChargeTypeId"
            placeholder="none"
            appendTo="body"
            tabbableDropdown
          ></p-dropdown>
          <div>
            <button
              pButton
              type="button"
              style="margin-top: 0.5rem; width: 100%"
              class="p-button-secondary"
              (click)="bulkUpdateChargeTypes()"
              label="Bulk Update"
            ></button>
          </div>
        </th>
        <th *ngFor="let col of columns">
          <input
            *ngIf="col.filter != 'hide'"
            type="text"
            style="width: 100%"
            (input)="$any(dt).filter($event.target.value, col.field, 'contains')"
            [value]="$any(dt).filters[col.field]?.value"
          />
          <input
            *ngIf="col.field == 'ChargeTypeId'"
            type="text"
            style="width: 100%"
            (input)="$any(dt).filter($event.target.value, 'filterChargeType', 'contains')"
            [value]="$any(dt).filters['filterChargeType']?.value"
          />
          <input
            *ngIf="col.field == 'batchDriverId'"
            type="text"
            style="width: 100%"
            (input)="$any(dt).filter($event.target.value, 'filterDriverName', 'contains')"
            [value]="$any(dt).filters['filterDriverName']?.value"
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record>
      <tr>
        <td *ngIf="doingBulkUpdate" class="text-center">
          <p-checkbox
            *ngIf="record.invoicetotal != 0"
            [(ngModel)]="record.shouldBulkUpdate"
            binary="true"
          ></p-checkbox>
        </td>
        <ng-template ngFor let-col [ngForOf]="cols">
          <td *ngIf="col.type === 'driverLink'" style="text-overflow: clip">
            <img
              *ngIf="record.driverID && record.batchNo"
              style="cursor: pointer"
              src="assets/img/details.svg"
              (click)="openDriverSettlement(record.driverID, record.batchNo)"
            />
          </td>
          <td *ngIf="col.type === 'code'">
            {{ record[col.field] }}
          </td>
          <td *ngIf="col.type === 'date'">
            {{ record[col.field] | date : "MM/dd/yyyy" }}
          </td>
          <td *ngIf="col.type === 'driver'" style="text-overflow: clip">
            <p-dropdown
              [options]="drivers"
              [style]="{ minWidth: '100%', maxWidth: '100%' }"
              [filter]="true"
              [(ngModel)]="record.batchDriverId"
              optionLabel="label"
              optionValue="value"
              placeholder="Select a Driver"
              (ngModelChange)="onRecordChanged($event, record, col.field)"
              appendTo="body"
              tabbableDropdown
            ></p-dropdown>
          </td>
          <td *ngIf="col.type === 'delete'">
            <button
              pButton
              type="button"
              icon="pi pi-trash"
              class="p-button-danger"
              (click)="deleteRecord(record)"
              title="Delete Record"
            ></button>
          </td>
          <td *ngIf="col.type === 'chargeType'">
            <p-dropdown
              *ngIf="record.invoicetotal != 0"
              [style]="{ minWidth: '100%', maxWidth: '100%' }"
              [options]="chargeTypes"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="record.chargeTypeId"
              (ngModelChange)="onRecordChanged($event, record, col.field)"
              placeholder="none"
              appendTo="body"
              tabbableDropdown
            ></p-dropdown>
          </td>
          <td
            *ngIf="col.type === 'string' || col.type === 'currency' || col.type === 'number'"
            [ngClass]="{ 'align-right': col.type === 'currency' || col.type === 'number' }"
            class="editable-cell"
            [pEditableColumn]="record"
            [pEditableColumnField]="record[col.field]"
          >
            <p-cellEditor class="elements-table">
              <ng-template pTemplate="input">
                <input
                  *ngIf="col.type === 'string'"
                  style="width: 100px"
                  type="text"
                  [ngModel]="record[col.field]"
                  (ngModelChange)="onRecordChanged($event, record, col.field)"
                  [title]="record[col.field]"
                />
                <input
                  *ngIf="col.type === 'number' || col.type === 'currency'"
                  style="width: 100px; text-align: right"
                  type="number"
                  min="0"
                  [ngModel]="record[col.field]"
                  (ngModelChange)="onRecordChanged($event, record, col.field)"
                  [title]="record[col.field]"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <span
                  *ngIf="col.type === 'string'"
                  [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
                  [title]="record[col.field]"
                >
                  {{ record[col.field] }}
                </span>
                <span
                  *ngIf="col.type === 'number'"
                  [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
                  [title]="record[col.field]"
                >
                  {{ record[col.field] | number }}
                </span>
                <span
                  *ngIf="col.type === 'currency'"
                  [style.color]="record[col.field + 'Changed'] ? 'red' : ''"
                  [title]="record[col.field]"
                >
                  {{ record[col.field] | currency }}
                </span>
              </ng-template>
            </p-cellEditor>
          </td>
        </ng-template>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer" let-columns>
      <tr>
        <ng-template ngFor let-total [ngForOf]="totals">
          <td *ngIf="total != null">
            {{ total | currency }}
          </td>
          <td *ngIf="total == null"></td>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
</div>
