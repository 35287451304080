<p-accordion>
  <p-accordionTab header="History">
    <div style="display: inline-block">
      <p-table
        #teamHistory
        [columns]="columns"
        [value]="filteredHistories"
        sortField="createdAt"
        sortOrder="-1"
        sortMode="single"
        [scrollable]="true"
        scrollHeight="700px"
        [virtualScroll]="true"
      >
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width.px]="col.width" />
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" style="text-align: center" [ngSwitch]="col.field">
              <input
                *ngSwitchDefault
                pInputText
                type="text"
                (input)="teamHistory.filter($event.target.value, col.field, 'contains')"
                [style.width]="col.width"
              />
              <div class="d-flex" *ngSwitchCase="'createdAt'">
                <input id="startDate"
                       name="startDate"
                       type="text"
                       [inputMask]="dateInputMask"
                       autocomplete="off"
                       placeholder="mm/dd/yyyy"
                       appendTo="body"
                       [(ngModel)]="startDate" />
                -
                <input id="endDate"
                       name="endDate"
                       type="text"
                       [inputMask]="dateInputMask"
                       autocomplete="off"
                       placeholder="mm/dd/yyyy"
                       appendTo="body"
                       [(ngModel)]="endDate" />
                <p-button label="Filter"
                          (onClick)="filterByDate()"
                          [disabled]="!startDate || !endDate"
                          [style]="{ 'margin-left': '1rem' }"></p-button>
              </div>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-history>
          <tr>
            <td>{{ history.createdAt | date: "short" }}</td>
            <td *ngIf="history.Team.name">{{ history.Team.name }}</td>
            <td *ngIf="history.field">{{ history.field }}</td>
            <td
              *ngIf="
                history.value &&
                  history.field != 'REMOVED PERCENT' &&
                  history.field != 'ADDED PERCENT';
                else percent
              "
            >
              {{ history.value }}
            </td>
            <ng-template #percent>
              <td>
                {{
                  history.Driver?.name +
                    " " +
                    history.Driver?.lastName +
                    " - " +
                    history.value +
                    "%"
                }}
              </td>
            </ng-template>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-accordionTab>
</p-accordion>
