import { Component, OnInit, NgZone, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataSharingService } from "../../shared/services/data/dataSharing.service";
import { SettlementsService } from "../shared/settlements.service";
import { DriverService } from "../../driver/shared/driver.service";
import { ToastrService } from "ngx-toastr";
import { Table } from "primeng/table";
import { TcheckService } from "../../tcheck/tcheck.service";
import { Location } from "@angular/common";
import { SelectItem } from "primeng/api";
import { PaymentTrip } from "@app/shared/models/trips/PaymentTrip.model";
import { TripCharge } from "@app/shared/models/trips/TripCharge.model";
import { RateCalculatorService } from "@app/trip-new/shared/rate-calculator.service";
import { MaskService } from "@app/shared/services/mask/mask.service";
import { RatesService } from "@app/rates/shared/rates.service";
import { ChargeType } from "@app/shared/models/ChargeType.model";
import { DriverViewModel } from "@app/shared/models/drivers/DriverViewModel.model";
import { SettlementTeamsTabComponent } from "./settlement-teams-tab/settlement-teams-tab.component";
import { withLatestFrom } from "rxjs/operators";
import { MinimumRateViewModel } from "@app/shared/models/rates/MinimumRateViewModel.model";
import { DeductionService } from "../deduction.service";
@Component({
  selector: "app-loads",
  templateUrl: "./loads.component.html",
  styleUrls: ["./loads.component.css"],
})
export class LoadsComponent implements OnInit {
  @ViewChild("dt1") td1: Table;
  @ViewChild("dt3", { static: true }) td3: Table;
  cellSelected: string;

  @ViewChild("teamSplitComponent") teamSplitComponent: SettlementTeamsTabComponent;

  selectedTab = "Deficit";
  errorMsg: any;

  accessorials: SelectItem[] = [];

  driverId: any;
  driverInfo: any = {};
  colsFuel: any[];
  colsCharge: { field: string; header: string }[];

  batchsList: any = [];
  batchSelected: any;
  batchInfo: any = {};
  settlement: any = {}; // the batch info without any changes
  DriverChargeChanges: any = [];
  driverChargeTypes: SelectItem[] = [];

  batchTrips: PaymentTrip[] = [];
  batchDrivers: DriverViewModel[] = [];

  advances: any = [];
  advanceTotals: any = {
    gallons: 0,
    ppg: 0,
    cost: 0,
    others: 0,
    dashCash: 0,
    discount: 0,
    fee: 0,
    oil: 0,
    express: 0,
    driverTotal: 0,
    lgtTotal: 0,
    net: 0,
    otherAmount1: 0,
    iNVOICETOTAL: 0,
  };
  reimbursements: TripCharge[] = [];
  deductions: any = [];
  allDeductions: any = [];
  escrow: any = {};
  deficit: any = {};
  charge: any = {};
  attachedFiles = [];
  totalEarnings = 0;
  summaryEarnings: any = [];
  summaryDeductions: any = {
    deduction: 0,
    pendingValue: 0,
    targetAmount: 0,
    customValue: 0,
  };
  colsDeduction: { field: string; header: string }[];
  colsGuarantees: { field: string; header: string }[];
  colsReimbursements: { field: string; header: string }[];
  tabSelected: string;
  maxTempId: any = 0;
  margins: any = { top: 70, bottom: 40, left: 30, width: 550 };
  rateTypes: SelectItem[] = [{ value: "FLAT" }, { value: "VARIABLE" }];
  unitsOrMiles: SelectItem[] = [
    { label: "UNITS", value: true },
    { label: "MILES", value: false },
  ];

  loadPDF = false;

  // For calculating guarantees
  excludedChargeTypes: ChargeType[] = [];
  guaranteedDailyRate: number;
  dateInputMask: any;
  constructor(
    private _SettlementsService: SettlementsService,
    private _DataSharingService: DataSharingService,
    private _DriverService: DriverService,
    private DeductionService: DeductionService,
    private _ActivatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private _NgZone: NgZone,
    private router: Router,
    private tcheckService: TcheckService,
    private rateCalculaterService: RateCalculatorService,
    private ratesService: RatesService,
    private location: Location,
    private maskService: MaskService
  ) {}

  ngOnInit() {
    this.dateInputMask = this.maskService.getInputDateTimeMask();

    this.colsCharge = [
      { field: "chargeType?.description", header: "Charge Type" },
      { field: "description", header: "Description" },
      { field: "charge.rateType", header: "Type" },
      { field: "charge.useUnits", header: "Units/Miles" },
      { field: "units", header: "Units" },
      { field: "miles", header: "Miles" },
      { field: "chargeRate", header: "Rate" },
      { field: "price", header: "Total" },
    ];

    this.colsGuarantees = [
      { field: "tripNo", header: "Trip Number" },
      { field: "startDate", header: "Trip Start" },
      { field: "endDate", header: "Trip End" },
      { field: "totalTime", header: "Total Time" },
      { field: "miles", header: "Miles" },
      { field: "gross", header: "Gross" },
      // excluded meaning the charges that are ignored when calculating guaranteed pay
      { field: "excludedPayCharges", header: "Excluded Charges" },
      { field: "net", header: "Net" },
      { field: "guaranteedAmount", header: "Guaranteed Amount" },
      { field: "ratePay", header: "Additional Day Rate Pay" },
    ];

    this.colsDeduction = [
      { field: "description", header: "Description" },
      { field: "customValue", header: "Current Deduction Amount" },
    ];

    this.colsReimbursements = [
      { field: "rateType", header: "Rate Type" },
      { field: "chargeType", header: "Charge Type" },
      { field: "units", header: "Units" },
      { field: "chargeRate", header: "Rate" },
      { field: "price", header: "Total Value" },
      { field: "tripNo", header: "Trip No" },
    ];

    const chargeTypes = this._ActivatedRoute.snapshot.data.chargeTypes;
    this.accessorials = chargeTypes.filter(
      (item: SelectItem) => item.value.isAccessorial && item.value.expenseGL
    );
    this.driverChargeTypes = chargeTypes.filter(
      (item: SelectItem) => item.value.expenseGL && !item.value.isAccessorial
    );
    //Gets a list of all deductions to be used in the dropdown
    this.DeductionService.getDeductionDropdown().subscribe((data) => {
      this.allDeductions = data;
    });
    this.NoBatchDefaultData();

    // get both the params and query params
    this._ActivatedRoute.url
      .pipe(withLatestFrom(this._ActivatedRoute.paramMap, this._ActivatedRoute.queryParamMap))
      .subscribe(([url, params, queryParams]) => {
        this.loadPDF = false;
        const savedTab = sessionStorage.getItem(this.router.url + "Tab");
        this.driverId = params.get("driverId");
        const queryParamsTab = queryParams.get("tab");
        const queryBatchNo = Number(queryParams.get("batchNo"));

        this._DriverService.getDriverInfoById(this.driverId).subscribe((driverInfo) => {
          this.driverInfo = driverInfo;
          this.getBatchList(queryBatchNo);
        });
        this.getGuaranteeForDriver(this.driverId);

        // set default tab
        if (queryParamsTab) {
          this.tabDestination(queryParamsTab);
        } else if (savedTab) {
          this.tabDestination(savedTab);
        } else {
          this.tabDestination("Loads");
        }
      });
  }

  createTrip() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/new/newtrip"], { queryParams: { driverid: this.driverId } })
    );
    window.open(url, "_blank");
  }

  getBatchList(defaultBatchNo?: number) {
    this._SettlementsService.getBatchListByDriverId(this.driverId).subscribe((batchesResult) => {
      this.batchsList = batchesResult;
      this._DataSharingService.currentBatchNo.subscribe((batchNo) => {
        if (defaultBatchNo) batchNo = defaultBatchNo;
        this.batchSelected = {};
        if (this.batchsList.length > 0) {
          for (let i = 0; i < this.batchsList.length; i++) {
            if (this.batchsList[i].batchNo == batchNo) {
              this.batchSelected = this.batchsList[i];
              this.getAllBatchInfo();
              return;
            }
          }
          this.batchSelected = this.batchsList[0];
          this.getAllBatchInfo();
        }
      });
    });
  }

  tabDestination(tab: string) {
    if (tab == "Invoice") {
      this.loadPDF = true;
    }

    this.tabSelected = tab;
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    if (tab == "Loads") {
      document.getElementById("one").classList.add("active");
    } else if (tab == "Fuel") {
      document.getElementById("two").classList.add("active");
      document.getElementById("Invoice").style.display = "none";
    } else if (tab == "Reimbursements") {
      document.getElementById("three").classList.add("active");
      document.getElementById("Invoice").style.display = "none";
    } else if (tab == "Deduction") {
      document.getElementById("four").classList.add("active");
      document.getElementById("Invoice").style.display = "none";
    } else if (tab == "Escrow") {
      document.getElementById("five").classList.add("active");
      document.getElementById("Invoice").style.display = "none";
    } else if (tab == "Deficit") {
      document.getElementById("six").classList.add("active");
      document.getElementById("Invoice").style.display = "none";
    } else if (tab == "Teams") {
      document.getElementById("eight").classList.add("active");
      document.getElementById("Teams").style.display = "none";
    } else if (tab == "Invoice") {
      document.getElementById("nine").classList.add("active");
      this.totalCalculation();
    }

    document.getElementById(tab).style.display = "block";
    sessionStorage.setItem(this.location.path() + "Tab", tab);
  }

  NoBatchDefaultData() {
    this.driverInfo = {};
    this.batchsList = [];
    this.batchSelected = {};
    this.batchInfo = {};
    this.batchTrips = [];
    this.advances = [];
    this.advanceTotals.gallons = 0;
    this.advanceTotals.ppg = 0;
    this.advanceTotals.cost = 0;
    this.advanceTotals.others = 0;
    this.advanceTotals.dashCash = 0;
    this.advanceTotals.discount = 0;
    this.advanceTotals.fee = 0;
    this.advanceTotals.oil = 0;
    this.advanceTotals.express = 0;
    this.advanceTotals.driverTotal = 0;
    this.advanceTotals.lgtTotal = 0;
    this.advanceTotals.net = 0;
    this.reimbursements = [];
    this.deductions = [];
    this.escrow = {};
    this.deficit = {};
  }

  getAllBatchInfo() {
    if (!this.batchSelected.id) {
      return this.NoBatchDefaultData();
    }

    this._SettlementsService
      .getBatchDetailsById(this.batchSelected.id)
      .subscribe((batchInfoResult: any) => {
        this.batchInfo = batchInfoResult;
        this.settlement = batchInfoResult;
        this.batchTrips = this.batchInfo.loads;
        this.calculateTripTotals();
        this.summaryEarnings = this.batchInfo.summary;
        this.calculateTotal();
        this.advances = this.batchInfo.tCheks;
        //Appends any deductions that would be missing from the list due to being inactive
        const missingDeductions = this.batchInfo.deductions.filter(
          (deduction) => !this.allDeductions.find((d) => d.deductionId == deduction.deductionId)
        );
        this.allDeductions = this.allDeductions.concat(missingDeductions);
        this.deductions = this.batchInfo.deductions;
        this.deductionTotalTab();
        this.attachedFiles = this.batchInfo.attachedFiles;
        this.reimbursements = this.batchInfo.reimbursements;
        this.markReimbursementsOnBatch();
        this.deductionDataFormat();
        this.FuelDataFormat();
        if (this.batchInfo.status != "CLOSED") {
          this.getNonBatchAssignedTrips();
        }
        this.totalCalculation();
        this.getDriversInBatch(this.batchSelected.batchNo);
      });
  }

  getDriversInBatch(batchNo: string | number) {
    this._SettlementsService.getDriversInBatch(batchNo).subscribe((drivers: any) => {
      this.batchDrivers = drivers;
    });
  }

  calculateTripTotals() {
    for (let tripPayment of this.batchTrips) {
      const { driverCharges } = tripPayment;
      // Recalculate net non trip expense
      tripPayment.netNonTripExpense = driverCharges
        //.filter((charge) => !charge.ratingType.includes("GUARANTEED"))
        .reduce((a, b) => {
          return a + Number(b.price);
        }, 0);
      // Calculate the charges that aren't part of the guaranteed pay
      tripPayment.guaranteedExtraPay = Number(
        driverCharges.find(
          (charge) =>
            charge.ratingType == "GURANTEED PAY" || charge.ratingType == "GUARANTEED MILES"
        )?.price ?? 0
      );
      tripPayment.excludedPayCharges = driverCharges
        .filter((charge) =>
          this.excludedChargeTypes.some((chargeType) => chargeType.id === charge.chargeType.id)
        )
        .reduce((a, b) => {
          return a + Number(b.price);
        }, 0);
      this.recalculateTripTime(tripPayment);
    }
    this.calculateTotal();
  }

  calculateTotal() {
    this.totalEarnings = 0;
    this.batchTrips.forEach((trip) => {
      if (trip.include) {
        this.totalEarnings += trip.netNonTripExpense;
      }
    });
  }

  selectAllEarnings() {
    this.batchTrips.forEach((trip) => (trip.include = true));
    this.calculateTotal();
  }

  getNonBatchAssignedTrips() {
    this._SettlementsService.getNonBatchAssignedTrips(this.driverId).subscribe(
      (nonassigned: any) => {
        this.batchTrips = this.batchTrips.concat(nonassigned);
        this.calculateTripTotals();
      },
      (batchError) => {
        this.errorMsg = batchError;
      }
    );
  }

  getGuaranteeForDriver(driverId: string) {
    this.ratesService
      .getDriverGuaranteedRate(driverId, new Date())
      .subscribe((rate: MinimumRateViewModel) => {
        if (rate && (rate.guaranteeType === "PAY" || rate.guaranteeType === "MILES")) {
          this.excludedChargeTypes = rate.excludedChargeTypes;
          this.guaranteedDailyRate = rate.rate;
        }
      });
  }

  tripTimeChanged(trip: PaymentTrip) {
    trip.softUpdate = true;
    this.recalculateTripTime(trip);
  }

  recalculateTripTime(trip: PaymentTrip) {
    const { startDate, endDate } = trip;
    const diffInMS = new Date(endDate).valueOf() - new Date(startDate).valueOf();
    const diffInMinutes = Math.round(Math.round(diffInMS / 1000 / 60) / 15) * 15;
    trip.tripTimeMinutes = diffInMinutes;

    const guarantee = trip.driverCharges.find((charge) => charge.ratingType.includes("GUARANTEED"));
    if (guarantee) {
      trip.guaranteedExtraPay = +guarantee.price;
      trip.calculatedGuaranteedExtraPay = +guarantee.calculatedPrice;
    }

    // recalculate the guaranteed amount for this trip
    if (this.guaranteedDailyRate) {
      const diffInHours = diffInMinutes / 60;
      const guaranteedAmount = (diffInHours / 24) * this.guaranteedDailyRate;
      trip.guaranteedAmount = guaranteedAmount > 0 ? guaranteedAmount : 0;
    }
  }

  FuelDataFormat() {
    this.advanceTotals.gallons = 0;
    this.advanceTotals.ppg = 0;
    this.advanceTotals.cost = 0;
    this.advanceTotals.others = 0;
    this.advanceTotals.dashCash = 0;
    this.advanceTotals.discount = 0;
    this.advanceTotals.fee = 0;
    this.advanceTotals.oil = 0;
    this.advanceTotals.express = 0;
    this.advanceTotals.lgtTotal = 0;
    this.advanceTotals.net = 0;
    this.advanceTotals.driverTotal = 0;

    this.advanceTotals.otherAmount1 = 0;
    this.advanceTotals.iNVOICETOTAL = 0;
    for (let advance of this.advances) {
      this.advanceTotals.gallons += advance.fuelQuantity1;
      this.advanceTotals.ppg += advance.fuelPPU1;
      this.advanceTotals.cost += advance.fuelAmount1;
      this.advanceTotals.others += advance.consolidatedOthers;
      this.advanceTotals.dashCash += advance.dashCash;
      this.advanceTotals.discount += advance.discountAmount;
      this.advanceTotals.fee += advance.customerFee;
      this.advanceTotals.oil += advance.oilAmount;
      this.advanceTotals.express += advance.express;
      this.advanceTotals.driverTotal += advance.calculatedDriverAmount;
      this.advanceTotals.lgtTotal += advance.calculatedLGTAmount;
      this.advanceTotals.net += advance.calculatedTotalAmount;
      this.advanceTotals.otherAmount1 += advance.otherAmount1;
      this.advanceTotals.iNVOICETOTAL += advance.invoicetotal;
      this.advances.softUpdate = false;
      this.advances.softDelete = false;
    }
  }

  deductionDataFormat() {
    for (let i = 0; i < this.deductions.length; i++) {
      this.deductions[i].softUpdate = false;
      this.deductions[i].softDelete = false;
    }
  }

  // Mark each reimbursement as part of the batch or not
  markReimbursementsOnBatch() {
    for (const driverCharge of this.reimbursements) {
      const { paymentTripId } = driverCharge;
      const paymentTrip = this.batchTrips.find((trip) => trip.id == paymentTripId);
      driverCharge.include = paymentTrip.include;
    }
  }

  changesOnReinbursement(charge: TripCharge) {
    if (charge) {
      if (charge.chargeTypeId)
        charge.chargeType = this.accessorials.find(
          (item) => item.value.id == charge.chargeTypeId
        ).value;

      this.rateCalculaterService.calculateChargePrice(charge);
      charge.softUpdate = true;
      // assign the charge to a payment trip based on the selected trip
      if (charge.tripId) {
        charge.paymentTrip = this.batchTrips.find((tripPayment) => tripPayment.id == charge.tripId);
        charge.tripNo = charge.paymentTrip.tripNo;
      }
    }
  }

  addReinbursement() {
    const newReinbursement = {
      tempId: this.maxTempId + 1,
      miles: 0,
      flatOrVariable: "VARIABLE",
      chargeRate: 0,
      units: 0,
      price: 0,
      usesUnits: true,
      description: "",
      rateName: "",
      ratingType: "CUSTOM",
      isAccessorial: true,
      chargeTypeId: "",
      chargeType: null,
    };
    this.maxTempId += 1;
    this.reimbursements.push(newReinbursement);
  }

  deleteReinbursement(charge: TripCharge) {
    if (charge.id != null) {
      charge.delete = !charge.delete;
    } else {
      this.reimbursements = this.reimbursements.filter(
        (reimbursement) => reimbursement.tempId != charge.tempId
      );
    }
  }

  validateReimbursements() {
    for (const reimubrsement of this.reimbursements) {
      if (!reimubrsement.chargeType) {
        this.toastr.error("All reimbursements require a charge type");
        return false;
      }
      if (!reimubrsement.price || reimubrsement.price === 0) {
        this.toastr.error("Reimbursement price must be greater than 0");
        return false;
      }
      if (!reimubrsement.tripId) {
        this.toastr.error("Reimbursements must be assigned to a trip");
        return false;
      }
      reimubrsement.calculatedDescription = reimubrsement.chargeType.description;
      reimubrsement.description = reimubrsement.chargeType.description;
      reimubrsement.ratingType = "CUSTOM";
    }
    return true;
  }

  // When a load is added or removed from the batch
  LoadIncludeChange(tripPayment: PaymentTrip) {
    if (tripPayment.include) {
      // if the load is being added
      this.markReimbursementsOnBatch();
    }
    this.calculateTotal();
  }

  chargeChange(charge) {
    charge.softUpdate = true;
    this.deductionTotalTab();
  }

  onKeyBoard(event, x, y) {
    var keyPress = event.key;
    var evt = new Event("click");
    if (keyPress === "Enter") {
      const cellId = "celda" + String(x) + String(y + 1) + "scrollable";
      this.cellSelected = cellId;
      var button = document.getElementById(cellId);
      if (button != null) {
        setTimeout(function () {
          button.dispatchEvent(evt);
        }, 200);
      }
    }
    if (keyPress === "ArrowRight") {
      const cellId = "celda" + String(x + 1) + String(y) + "scrollable";
      this.cellSelected = cellId;
      var button = document.getElementById(cellId);
      if (button != null) {
        button.dispatchEvent(evt);
      }
    }
    if (keyPress === "ArrowLeft") {
      const cellId = "celda" + String(x - 1) + String(y) + "scrollable";
      this.cellSelected = cellId;
      var button = document.getElementById(cellId);
      if (button != null) {
        button.dispatchEvent(evt);
      }
    }
    if (keyPress === "ArrowUp") {
      const cellId = "celda" + String(x) + String(y - 1) + "scrollable";
      this.cellSelected = cellId;
      var button = document.getElementById(cellId);

      if (button != null) {
        button.dispatchEvent(evt);
      } else {
        event.preventDefault(button);
      }
    }
    if (keyPress === "ArrowDown") {
      const cellId = "celda" + String(x) + String(y + 1) + "scrollable";
      this.cellSelected = cellId;
      var button = document.getElementById(cellId);
      if (button != null) {
        button.dispatchEvent(evt);
      } else {
        event.preventDefault(button);
      }
    }
  }

  editCharge(charge) {
    //making a clone of the charge type that doesn't reference the original object
    const chargeType = JSON.parse(
      JSON.stringify(
        this.driverChargeTypes.find((driver) => driver.value.id == charge.chargeType.id)
      )
    );
    charge.chargeType = chargeType.value;
    charge.description = chargeType.value.description;
    charge.ratingType = charge.ratingType;
    charge.flatOrVariable = charge.rateType;
    charge.price = this.rateCalculaterService.calculateChargePrice(charge);
    this.calculateTripTotals();

    if (this.DriverChargeChanges.indexOf(charge) < 0) this.DriverChargeChanges.push(charge);
    charge.changed = true;
  }

  addCharge(tripPayment) {
    tripPayment.driverCharges.push({
      tripId: tripPayment.id,
      chargeType: { id: null, name: "Select a Charge Type" },
      usesUnits: true,
      rateType: "FLAT",
      ratingType: "CUSTOM",
      units: 0,
      miles: 0,
      chargeRate: 0,
      price: 0,
      delete: false,
      changed: true,
    });
  }

  removeCharge(driverCharges: TripCharge[], charge: TripCharge) {
    if (charge.id == null) driverCharges.splice(driverCharges.indexOf(charge), 1);
    else {
      charge.delete = true;
    }

    if (this.DriverChargeChanges.indexOf(charge) < 0) this.DriverChargeChanges.push(charge);
    this.calculateTripTotals();
  }

  previewInvoiceTrips() {
    return this.batchTrips.filter((tripPayment) => tripPayment.include == true);
  }

  deductionTotalTab() {
    this.summaryDeductions.customValue = this.deductions.reduce((a, b) => a + b.customValue, 0);
  }

  totalCalculation() {
    if (typeof this.batchSelected.status !== "undefined" && this.batchSelected.status != "CLOSED") {
      this.batchInfo.totalSettlement = 0;
      this.earningsTotal();
      this.fuelTotal();
      this.deductionTotal();
      this.reimbursementTotal();
      this.batchInfo.totalSettlement =
        this.batchInfo.totalEarnings +
        this.batchInfo.totalAdvance +
        this.batchInfo.totalDeductions +
        this.batchInfo.totalReinbursements;
    }
  }

  earningsTotal() {
    this.batchInfo.totalEarnings = 0;
    let includedTrips = this.previewInvoiceTrips();
    for (let i = 0; i < includedTrips.length; i++) {
      this.batchInfo.totalEarnings += includedTrips[i].netNonTripExpense;
    }
  }

  fuelTotal() {
    this.batchInfo.fuelAdvances = 0;
    this.batchInfo.nonFuelAdvances = 0;
    this.batchInfo.totalAdvance = 0;
    this.batchInfo.cash = 0;
    this.batchInfo.express = 0;
    for (let fuel of this.advances) {
      this.batchInfo.cash += fuel.dashCash;
      this.batchInfo.express += fuel.express;
      if (fuel.authorizationCode.substring(0, 1) == "A") {
        this.batchInfo.fuelAdvances -= fuel.calculatedDriverAmount;
      } else {
        this.batchInfo.nonFuelAdvances -= fuel.calculatedDriverAmount;
      }
      this.batchInfo.totalAdvance -= fuel.calculatedDriverAmount;
    }
  }

  deductionTotal() {
    this.batchInfo.totalDeductions = -this.summaryDeductions.customValue;

    const { escrowAdd, escrowSubtract, escrowInterest } = this.batchInfo;
    const { deficitAdd, deficitSubtract } = this.batchInfo;
    const totalEscrow = escrowAdd + escrowSubtract + escrowInterest;
    const totalDeficit = deficitAdd + deficitSubtract;
    this.batchInfo.totalDeductions -= totalEscrow || 0;
    this.batchInfo.totalDeductions -= totalDeficit || 0;
  }

  reimbursementTotal() {
    this.batchInfo.totalReinbursements = this.reimbursements
      .filter((charge) => !charge.delete)
      .reduce((a, b) => a + Number(b.price), 0);
    this.batchInfo.overUnderAdvance =
      this.batchInfo.totalReinbursements - this.batchInfo.totalAdvance;
  }

  saveBatch() {
    this.saveChanges(this.batchInfo.status);
  }

  preCloseBatch() {
    this.saveChanges("PRECLOSED");
  }

  closeBatch() {
    this.tcheckService.getScreenRecords().subscribe((records) => {
      this.tcheckService.refreshRecords(records).subscribe((records: any) => {
        let errors = records.filter((record) => record.errorMsg != null);
        if (errors.length == 0) this.saveChanges("CLOSED");
        else {
          this.toastr.error(
            "There are errors on the T-Chek Import screen. Please clear them before you can close the batch.",
            "Warning!"
          );
        }
      });
    });
  }

  saveChanges(status) {
    if (!this.validateReimbursements()) return;
    if (!this.validateLoads()) return;
    if (!this.validateDeductions()) return;
    //if (!this.teamSplitComponent.validate()) return;
    if (this.batchSelected.status && this.batchSelected.status != "CLOSED") {
      this.totalCalculation();
      const includedTrips = this.batchTrips.filter((trip) => trip.include);
      const nonIncludedTrips = this.batchTrips.filter((trip) => !trip.include);

      let batch = {
        BatchInfo: {
          id: this.batchSelected.batchId,
          batchDriverId: this.batchSelected.id,
          uuid: this.batchInfo.uuid,
          batchNo: this.batchInfo.batchNo,
          startDate: this.batchInfo.startDate,
          endDate: this.batchInfo.endDate,
          noRevenue: this.batchInfo.noRevenue,
          printDate: this.batchInfo.printDate,
          totalEarnings: this.batchInfo.totalEarnings || 0,
          fuelAdvances: this.batchInfo.fuelAdvances,
          nonFuelAdvances: this.batchInfo.nonFuelAdvances,
          totalAdvance: this.batchInfo.totalAdvance,
          totalReinbursements: this.batchInfo.totalReinbursements,
          overUnderAdvance: this.batchInfo.overUnderAdvance,
          totalDeductions: this.batchInfo.totalDeductions,
          totalSettlement: this.batchInfo.totalSettlement || 0,
          errorMessage: this.batchInfo.errorMessage,
          driverId: this.driverId,
          status: status,
        },
        batchDriver: {
          id: this.batchInfo.id,
          escrowBeforeBalance: this.batchInfo.escrowBeforeBalance,
          escrowAfterBalance: this.batchInfo.escrowAfterBalance,
          escrowAdd: this.batchInfo.escrowAdd,
          escrowSubtract: this.batchInfo.escrowSubtract,
          escrowInterest: this.batchInfo.escrowInterest,
          escrowDepositDescription: this.batchInfo.escrowDepositDescription,
          escrowWithdrawDescription: this.batchInfo.escrowWithdrawDescription,
          deficitBeforeBalance: this.batchInfo.deficitBeforeBalance,
          deficitAfterBalance: this.batchInfo.deficitAfterBalance,
          deficitAdd: this.batchInfo.deficitAdd,
          deficitSubtract: this.batchInfo.deficitSubtract,
          deficitDepositDescription: this.batchInfo.deficitDepositDescription,
          deficitWithdrawDescription: this.batchInfo.deficitWithdrawDescription,
        },
        includedTrips: includedTrips,
        nonIncludedTrips: nonIncludedTrips,
        BatchCharges: [],
        tcheks: this.advances,
        reimbursements: this.reimbursements,
        deductions: this.deductions,
        teamSplitOverrides: [],
      };

      // this.setBatchCharges(batch, this.advances);
      this.setBatchCharges(batch, [this.escrow]);
      this.setBatchCharges(batch, [this.deficit]);
      this.DriverChargeChanges = [];

      batch.teamSplitOverrides = this.teamSplitComponent.getOverrides();

      if (status != "CLOSED") {
        this._SettlementsService.saveBatch(batch).subscribe(
          () => {
            this.toastr.success("All Changes Saved Successfully", "Success!");
            location.reload();
          },
          (batchError) => {
            this.toastr.error("Error saving batch", "Error!");
            this.errorMsg = batchError;
          }
        );
      } else {
        batch.BatchInfo.status = "PRECLOSE";
        this._SettlementsService.saveBatch(batch).subscribe(
          () => {
            this._SettlementsService.closeBatchList([batch.BatchInfo.id]).subscribe(
              () => {
                this.toastr.success("Batch Closed Successfully", "Success!");
                this.getBatchList();
              },
              (batchError) => {
                this.toastr.error("Error closing batch", "Error!");
                this.errorMsg = batchError;
              }
            );
          },
          (batchError) => {
            this.toastr.error("Error saving batch", "Error!");
            this.errorMsg = batchError;
          }
        );
      }
    }
  }

  setBatchCharges(batch: any, chargeList: any[]) {
    for (const charge of chargeList) {
      if (charge.softUpdate || charge.softDelete || charge.id) {
        const batchCharge = {
          ...charge,
          BatchId: this.batchSelected.id,
        };
        batch.BatchCharges.push(batchCharge);
      }
    }
  }

  onChangeTabSettlement(optionValue) {
    this._NgZone.run(() =>
      this.router.navigate(["/settlements/" + optionValue + "/", this.driverId])
    );
  }

  previousDriver() {
    const driverIndex = this.batchDrivers.findIndex((driver) => driver.id == this.driverId);
    if (driverIndex > 0) {
      const prevDriverId = this.batchDrivers[driverIndex - 1].id;
      this.router.navigate(["/settlements/loads/", prevDriverId]);
    } else if (driverIndex === 0) {
      const prevDriverId = this.batchDrivers[this.batchDrivers.length - 1].id;
      this.router.navigate(["/settlements/loads/", prevDriverId]);
    }
  }

  nextDriver() {
    const driverIndex = this.batchDrivers.findIndex((driver) => driver.id == this.driverId);
    if (driverIndex >= 0 && driverIndex < this.batchDrivers.length - 1) {
      const nextDriverId = this.batchDrivers[driverIndex + 1].id;
      this.router.navigate(["/settlements/loads/", nextDriverId]);
    } else {
      const nextDriverId = this.batchDrivers[0].id;
      this.router.navigate(["/settlements/loads/", nextDriverId]);
    }
  }

  printInvoice() {
    this._SettlementsService.printInvoice(this.driverId, this.batchSelected.id).subscribe(
      (response) => {
        const x = new Blob([response], { type: "application/pdf" });
        const fileURL = (URL as any).createObjectURL(x, { oneTimeOnly: true });
        const anchor = document.createElement("a");
        anchor.href = fileURL;
        anchor.target = "_blank";
        anchor.click();
      },
      (error) => {
        this.toastr.error("Error printing invoice", "Error!");
      }
    );
  }

  dateChanged(event: KeyboardEvent) {
    this.maskService.newMaskDate(event);
  }

  addDeduction() {
    const newDeduction = {
      id: null,
      tempId: this.deductions.length + 1,
      customValue: 0,
      value: 0,
      deductionId: null,
      description: "",
      chargeType: null,
    };
    this.deductions.push(newDeduction);
  }
  deleteDeduction(deduction: any) {
    if (deduction.id != null) {
      const index = this.deductions.findIndex((d) => d.id == deduction.id);
      this.deductions[index].delete = !this.deductions[index].delete;
    } else this.deductions = this.deductions.filter((d) => d.tempId != deduction.tempId);
    const test = this.deductions;
  }
  validateDeductions() {
    for (let i = 0; i < this.deductions.length; i++) {
      const containsDuplicateDeduction = this.deductions
        .filter((d) => !d.delete)
        .some((d, i, arr) => arr.findIndex((ded) => ded.deductionId === d.deductionId) !== i);
      if (containsDuplicateDeduction) {
        this.toastr.error("Deductions must be unique");
        return false;
      }
    }
    return true;
  }
  validateLoads() {
    for (let i = 0; i < this.batchTrips.length; i++) {
      if (this.batchTrips[i].guaranteedExtraPay == null) this.batchTrips[i].guaranteedExtraPay = 0;
      for (let j = 0; j < this.batchTrips[i].driverCharges.length; j++) {
        if (this.batchTrips[i].driverCharges[j].chargeRate == null)
          this.batchTrips[i].driverCharges[j].chargeRate = 0;
        if (this.batchTrips[i].driverCharges[j].miles == null)
          this.batchTrips[i].driverCharges[j].miles = 0;
        if (this.batchTrips[i].driverCharges[j].units == null)
          this.batchTrips[i].driverCharges[j].units = 0;
        if (!this.batchTrips[i].driverCharges[j].chargeType) {
          this.toastr.error("Please select a charge type");
          return false;
        }
      }
    }
    return true;
  }
}
