<p-table [columns]="cols"
         [value]="locationList"
         selectionMode="single"
         [(selection)]="selectedLocation"
         (onRowSelect)="save()">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th></th>
      <th style="width: 100px">Country</th>
      <th style="width: 150px">State</th>
      <th style="width: 150px">City</th>
      <th style="width: 150px">County</th>
      <th>Street Address</th>
      <th style="width: 100px">Zip</th>
      <th style="width: 200px">Confidence Level</th>
      <th style="width: 110px">Latitude</th>
      <th style="width: 120px">Longitude</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-location let-columns="columns">
    <tr style="height:30px;" [pSelectableRowDblClick]="location">
      <td>
        <div #loadTrigger></div>
        <p-radioButton [value]="location"
                       [inputId]="location.number"
                       tabindex="-1"
                       (keydown.enter)="checkRow(location.number)"
                       (keydown.space)="checkRow(location.number)"
                       (keydown.tab)="checkRow(location.number)"
                       [(ngModel)]="selectedLocation"></p-radioButton>
      </td>
      <td *ngFor="let col of columns" [title]="location.Address[col.field]">
        {{ location.Address[col.field] }}
      </td>
      <td>{{ location.ConfidenceLevel }}</td>
      <td>{{ location.Coords.Lat }}</td>
      <td>{{ location.Coords.Lon }}</td>
    </tr>
  </ng-template>
</p-table>

<button type="button"
        id="save"
        class="btn btn-lg active"
        style="margin-top:10px;"
        (click)="save()"
        (keydown.space)="save()"
        (keydown.tab)="goToFirstRow()"
        *ngIf="this.selectedLocation != null">
  Save
</button>
