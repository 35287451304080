import { Component, OnInit, ViewChild } from "@angular/core";
import { DriverService } from "../shared/driver.service";
import { Router } from "@angular/router";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { ToastrService } from "ngx-toastr";
import { SelectItem } from "primeng/api";
import { Table } from "primeng/table";

@Component({
  selector: "app-drivers-list",
  templateUrl: "./drivers-list.component.html",
  styleUrls: ["./drivers-list.component.css"],
})
export class DriversListComponent implements OnInit {
  @ViewChild("dt", { static: true }) dt: Table;

  colsDrivers: any = [];
  allDrivers: any;
  changedDrivers = new Map();
  isEditingIds: boolean = false;
  showDeleted: boolean = false;

  statusOptions: SelectItem[] = [
    { label: "ALL", value: null },
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "INACTIVE", value: "INACTIVE" },
  ];

  constructor(
    private _driverList: DriverService,
    private router: Router,
    private _AuthService: authenticationService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.colsDrivers = [
      { field: "driverNo", header: "Id", width: 120 },
      { field: "lastName", header: "Last Name" },
      { field: "name", header: "Name" },
      { field: "cellphone", header: "Phone Number", width: 160 },
      { field: "peopleNetId", header: "PeopleNet ID", width: 100 },
      { field: "unitNumber", header: "Tractor", width: 100 },
      { field: "email", header: "Email", width: 300 },
      { field: "isActive", header: "Status", width: 100, type: "boolean" },
    ];

    if (!sessionStorage.getItem("driverslist")) {
      this.dt.filters["isActive"] = { value: "ACTIVE" };
    }

    this._driverList.findAndFilter().subscribe(
      (resDriverListData: any) => {
        resDriverListData = resDriverListData.map((driver) => ({
          ...driver,
          isActive: driver.isActive ? "ACTIVE" : "INACTIVE",
          unitNumber: driver.tractors?.map((tractor) => tractor.unitNumber).join(", "),
        }));
        this.allDrivers = JSON.parse(JSON.stringify(resDriverListData));
      },
      () => this.toastr.error("Error fetching drivers")
    );
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  viewDetail(driver: any) {
    this.router.navigate(["/driverinfo/", driver.id]);
  }

  onDriverChanged(driver: any) {
    this.changedDrivers.set(driver.id, driver);
  }

  saveDriverChanges() {
    this._driverList.getDriverInfoById;
    this.isEditingIds = false;
    for (const driver of this.changedDrivers.values()) {
      const updateDriver = {
        id: driver.id,
        idSystem: driver.driverNo,
      };
      this._driverList.updateDriverNo(updateDriver).subscribe((res) => {
        this.toastr.success("Updated Drivers");
      });
    }
  }
}
