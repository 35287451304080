import { throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { HttpClient } from "../../shared/services/http/http-client.service";

interface ProductDefinition {
  POUNDS: number;
  TONS: number;
  KILOS: number;
  GALLONS: number;
  LITERS: number;
  SCF: number;
  NM3: number;
}

@Injectable()
export class ProductService {
  productsList: any[]; // cached result

  constructor(private _http: HttpClient) {}

  setValues(product) {
    product.hazardDocument = product.un + ".pdf";
    return product;
  }

  create(product) {
    product = this.setValues(product);
    return this._http.post("/api/product/create", { product }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server product creation error")
      )
    );
  }

  list(showSpinner: boolean = true) {
    return this._http.get("/api/product/list", showSpinner).pipe(
      map((res: any) => res),
      catchError((error: any) =>
        observableThrowError(error.message || "Server product creation error")
      )
    );
  }

  listSelectFormat() {
    return this._http.get("/api/product/list").pipe(
      map((res: any) => {
        let products = res;
        products.map((product) => (product.itemName = product.name));
        return products;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server product creation error")
      )
    );
  }

  listDropDown() {
    return this._http.get("/api/product/list").pipe(
      map((res: any) => {
        return res.map((product) => ({ ...product, itemName: product.name }));
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server product creation error")
      )
    );
  }

  update(product) {
    product = this.setValues(product);

    return this._http.patch("/api/product/update", { product }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server product creation error")
      )
    );
  }

  delete(product) {
    return this._http.delete("/api/product/delete/" + product.uuid).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) =>
        observableThrowError(error.message || "Server product creation error")
      )
    );
  }

  translateUnits(unit: string) {
    const translationTable = {
      POUNDS: "POUNDS",
      "US POUNDS": "POUNDS",
      TONS: "TONS",
      "US TONS": "TONS",
      KILOS: "KILOS",
      GALLONS: "GALLONS",
      "US GALLONS": "GALLONS",
      LITERS: "LITERS",
      SCF: "SCF",
      "STANDARD CUBIC FEET": "SCF",
    };
    return translationTable[unit];
  }

  getRate(amount: number, productDefinition: ProductDefinition, incomingUnits: string) {
    return {
      POUNDS: (amount * productDefinition.POUNDS) / productDefinition[incomingUnits],
      KILOS: (amount * productDefinition.KILOS) / productDefinition[incomingUnits],
      SCF: (amount * productDefinition.SCF) / productDefinition[incomingUnits],
      GALLONS: (amount * productDefinition.GALLONS) / productDefinition[incomingUnits],
      LITERS: (amount * productDefinition.LITERS) / productDefinition[incomingUnits],
      TONS: (amount * productDefinition.TONS) / productDefinition[incomingUnits],
    };
  }

  async precacheProducts() {
    this.productsList = await this.list().toPromise();
  }

  async getProductTypes() {
    const litersPerGallon = 3.78541;
    const poundsPerTon = 2000;
    const kilosPerTon = 907.2;
    const nm3PerSCF = 0.02833;

    if (!this.productsList) this.productsList = await this.list().toPromise();

    let productTypes: { [key: string]: ProductDefinition } = {};

    for (let product of this.productsList) {
      const { name, scfPerPound, gallonsPerPound } = product;

      const definitionInTons: ProductDefinition = {
        POUNDS: poundsPerTon,
        TONS: 1,
        KILOS: kilosPerTon,
        SCF: scfPerPound * poundsPerTon,
        NM3: scfPerPound * poundsPerTon * nm3PerSCF,
        GALLONS: gallonsPerPound * poundsPerTon,
        LITERS: gallonsPerPound * poundsPerTon * litersPerGallon,
      };

      productTypes[name.toUpperCase()] = definitionInTons;
    }

    return productTypes;
  }

  async convertUnits(product: string, amount: number, incomingUnits: string) {
    const productTypes = await this.getProductTypes();
    const definitionInTons = productTypes[product];

    return this.getRate(amount, definitionInTons, incomingUnits);
  }

  async convertTo(product: string, amount: number, incomingUnits: string, outgoingUnits: string) {
    const conversionTable = await this.convertUnits(product, amount, incomingUnits);
    outgoingUnits = this.translateUnits(outgoingUnits);
    return conversionTable[outgoingUnits];
  }

  getproductinfo(uuid) {
    return this._http.get("api/product/find/" + uuid).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
