import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { clientService } from "../shared/client.service";
import { GetPictureService } from "../../../app/shared/services/files/getPictures.service";
import { ActivatedRoute, Router } from "@angular/router";
import { StatesServices } from "../../shared/services/states/states.service";
import { ToastrService } from "ngx-toastr";
import { MaskService } from "../../shared/services/mask/mask.service";
import { ConfirmationService } from "primeng/api";
import { CanComponentDeactivate } from "../../shared/guards/confirmation/confirmation.guard";
import { authenticationService } from "@app/login/auth.service";
import { Permissions } from "@models/permissions.enum";
import { ClientViewModel } from "../../shared/models/clients/ClientViewModel.model";
import { Client } from "../../shared/models/clients/Client.model";
import { BillToComponent } from "../bill-to/bill-to.component";

@Component({
  selector: "app-clientinfo",
  templateUrl: "./clientinfo.component.html",
  styleUrls: ["./clientinfo.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientinfoComponent implements OnInit, CanComponentDeactivate {
  id: string;
  errorMsg: any;
  clientInfo: ClientViewModel | any = {};
  newBillAddress: any = {};
  btnEdit: string;
  isEditing: boolean;
  states: Array<any>;
  defaultBillAddress: any;
  billToLocations: Array<any>;
  showclientInfoComponent = false;
  billLocationSelected: any = {};
  showNewBillToForm: boolean;
  newBillToList: Array<any>;
  deletedBillToList: Array<any>;
  billToEditedList: any;
  originalInfo: any;
  letters = /^[A-Za-z]+$/;
  numbers = /^\d*\.?\d+$/;
  usPhoneMask: any;
  showInactive: boolean;
  @ViewChild("billTo") billToComponent: BillToComponent;
  dateInputMask: any;

  constructor(
    private statesServices: StatesServices,
    private _clientinfo: ActivatedRoute,
    private clientService: clientService,
    private getPictureService: GetPictureService,
    private toastr: ToastrService,
    private router: Router,
    private maskService: MaskService,
    private _AuthService: authenticationService,
    private _ConfirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.billToEditedList = {};
    this.newBillToList = [];
    this.deletedBillToList = [];
    this.showNewBillToForm = false;
    this.states = this.statesServices.getTwoLettersStates("US");
    this.usPhoneMask = this.maskService.getUsPhoneMask();
    this.btnEdit = "Edit Profile";
    this.isEditing = false;
    this.showInactive = false;
    this._clientinfo.params.subscribe((params) => {
      this.id = params["id"];
      this.cdr.markForCheck();
    });
    this.dateInputMask = this.maskService.getInputDateOnlyMask();

    this.clientService.getClientInfo(this.id).subscribe((result) => {
      this.clientInfo = result;

      if (this.clientInfo.validFrom)
        this.clientInfo.validFrom = new Date(this.clientInfo.validFrom);
      if (this.clientInfo.validTo) this.clientInfo.validTo = new Date(this.clientInfo.validTo);
      for (const client of this.clientInfo.children) {
        if (client.validFrom) client.validFrom = new Date(client.validFrom);
        if (client.validTo) client.validTo = new Date(client.validTo);
      }

      this.cdr.markForCheck();
    });
  }

  hasPermission(permissionName: string) {
    const permission = Permissions[permissionName];
    return this._AuthService.hasPermission(permission);
  }

  confirm() {
    if (this.isEditing) {
      return window.confirm("Are you sure you want to leave this page?");
    } else {
      return true;
    }
  }

  finishClientEdition() {
    this.toastr.success("Client updated", "Success!");
  }

  defaultAddressChanged(addresSelectedId, addresses) {
    addresses
      .filter((address) => address.id !== addresSelectedId)
      .map((addressNotSelected) => {
        addressNotSelected.isDefault = false;
      });
  }

  isValidCustomerNo(): boolean {
    if (typeof this.clientInfo.customerNo === "undefined") {
      throw { type: "warning", msg: "Client Id No Required" };
    }
    if (this.clientInfo.customerNo.length > 30) {
      throw {
        type: "warning",
        msg: "Client Id must be less than 30 characters",
      };
    }
    if (this.clientInfo.customerNo.length < 2) {
      throw { type: "warning", msg: "Client Id must be more than 1 character" };
    }
    return true;
  }

  isValidCompanyName(): boolean {
    if (!this.clientInfo.companyName) {
      throw { type: "warning", msg: "Company Name required" };
    }
    return true;
  }

  isValidRange(): boolean {
    if (!this.clientInfo.validFrom) throw { type: "warning", msg: "Client Valid From required" };
    if (this.clientInfo.validFrom && this.clientInfo.validTo) {
      if (this.clientInfo.validFrom >= this.clientInfo.validTo) {
        throw {
          type: "warning",
          msg: "Client Valid From date must be prior to Valid To date.",
        };
      }
    }
    return true;
  }

  isValidAddress(): boolean {
    if (typeof this.clientInfo.address === "undefined") {
      throw { type: "warning", msg: "Company Address required" };
    }
    return true;
  }

  isValidCity(): boolean {
    if (typeof this.clientInfo.city === "undefined") {
      throw { type: "warning", msg: "Company city required" };
    }
    return true;
  }

  isValidState(): boolean {
    if (typeof this.clientInfo.state === "undefined") {
      throw { type: "warning", msg: "Company state required" };
    }
    return true;
  }

  isValidZip(): boolean {
    if (typeof this.clientInfo.zip === "undefined") {
      throw { type: "warning", msg: "Company zip required" };
    }
    return true;
  }

  isValidBillingRange(): boolean {
    if (typeof this.clientInfo.billingRange === "undefined") {
      throw { type: "warning", msg: "Client Billing Range required" };
    }
    return true;
  }

  validateClientFields(): boolean {
    try {
      this.isValidCompanyName();
      this.isValidRange();
      this.isValidAddress();
      this.isValidCity();
      this.isValidState();
      this.isValidZip();
      this.isValidCustomerNo();
      this.isValidBillingRange();
      return true;
    } catch (error) {
      switch (error.type) {
        case "warning":
          this.toastr.warning(error.msg, "Alert!", {
            closeButton: true,
            enableHtml: true,
          });
          break;
        default:
          this.toastr.info(error.msg, "Info!", {
            closeButton: true,
            enableHtml: true,
          });
          break;
      }
    }
  }

  editOn() {
    if (this.btnEdit === "Edit Profile") {
      this.btnEdit = "Save Changes";
      this.isEditing = true;
      this.originalInfo = JSON.stringify(this.clientInfo);
    } else {
      if (!this.validateClientFields()) return;
      if (this.showNewBillToForm && !this.addBillTo()) return;

      const editedBillToList = [];
      Object.keys(this.billToEditedList).forEach((k) => {
        editedBillToList.push(this.billToEditedList[k]);
      });
      if (!this.validateRange()) return;
      if (!this.checkBillToUnique()) return;
      for (const billTo of this.clientInfo.children) {
        if (!this.clientService.validateBillTo(billTo)) return;
      }
      for (const billTo of this.newBillToList) {
        if (!this.clientService.validateBillTo(billTo)) return;
      }

      this.clientService
        .updateClient(this.clientInfo, this.newBillToList, editedBillToList, this.deletedBillToList)
        .subscribe({
          next: (res) => {
            this.btnEdit = "Edit Profile";
            this.isEditing = false;
            this.finishClientEdition();
            this.router.navigate(["/clientlist/"]);
            this.cdr.markForCheck();
          },
          error: (resError) => {
            this.toastr.warning(resError);
          },
        });
    }
  }
  getPicturePath(name) {
    return this.getPictureService.getPicturePath(name, "client");
  }

  validateRange(): boolean {
    // Validate client and all new/edited bill-tos.
    let checkList = [this.clientInfo] as Client[];
    checkList = checkList.concat(this.clientInfo.children);
    checkList = checkList.concat(this.newBillToList);
    checkList = checkList.concat(this.billToEditedList);
    // Don't validate deleted bill-tos
    this.deletedBillToList.forEach((billTo) => {
      let ind = checkList.indexOf(billTo);
      if (ind >= 0) checkList.splice(ind, 1);
    });

    for (const client of checkList) {
      if (client.validFrom && client.validTo && client.validFrom >= client.validTo) {
        this.toastr.warning(
          `${client.companyName}: Valid From date should be prior to Valid To date.`,
          "warning"
        );
        return false;
      }
    }

    return true;
  }

  dateChanged(event: KeyboardEvent) {
    const attributes = {
      justDate: true,
      yearDigits: 2,
    };
    this.maskService.newMaskDate(event, attributes);
  }

  addBillTo() {
    const newBillTo = this.billToComponent.getBillTo();
    const isValid = this.billToComponent.validateBillTo();
    const isUnique = this.checkBillToUnique(newBillTo.customerNo);
    if (!isValid || !isUnique) return false;

    this.newBillToList.push(newBillTo);
    this.billToComponent.resetBillTo();
    this.showNewBillToForm = false;
    return true;
  }

  deleteBillTo(index) {
    this.deletedBillToList.push({ id: this.clientInfo.children[index].id });
    this.clientInfo.children.splice(index, 1);
  }

  deleteJustAddedBillTo(index) {
    this.newBillToList.splice(index, 1);
  }

  billToEdited(billToClient) {
    if (billToClient.validFrom && billToClient.validTo) {
      if (billToClient.validFrom >= billToClient.validTo) {
        this.toastr.warning(
          `${billToClient.companyName}: Valid From date should be prior to Valid To date.`,
          "warning"
        );
        return;
      }
    }
    if (billToClient.id) {
      // is an old billTo no a new one
      this.billToEditedList[billToClient.id] = billToClient;
    }
  }

  cancelEdit() {
    this.isEditing = false;
    this.btnEdit = "Edit Profile";
    this.newBillToList = [];
    this.clientInfo = JSON.parse(this.originalInfo);
  }

  useTheClientAsContact() {
    this.billToComponent.useTheClientAsContact(this.clientInfo);
  }

  checkBillToUnique(newId = null) {
    const existingBillTo = [];
    let isUnique = true;
    if (newId) {
      existingBillTo.push(newId);
    }
    this.clientInfo.children.forEach((billTo) => {
      if (!existingBillTo.includes(billTo.customerNo)) {
        existingBillTo.push(billTo.customerNo);
      } else {
        this.toastr.warning(
          `${billTo.companyName}: Bill to Id ${billTo.customerNo} already exists`,
          "warning"
        );
        isUnique = false;
      }
    });
    this.newBillToList.forEach((billTo) => {
      if (!existingBillTo.includes(billTo.customerNo)) {
        existingBillTo.push(billTo.customerNo);
      } else {
        this.toastr.warning(
          `${billTo.companyName}: Bill to Id ${billTo.customerNo} already exists`,
          "warning"
        );
        isUnique = false;
      }
    });

    return isUnique;
  }

  deleteClient() {
    this.clientService.deleteClient(this.id).subscribe((result) => {
      this.ngOnInit();
    });
  }

  confirmationDelete() {
    this._ConfirmationService.confirm({
      key: "deleteClientDialog",
      message: "Do you really want to delete this client?",
      accept: () => {
        this.deleteClient();
      },
      reject: () => {},
    });
  }

  restoreClient() {
    this.clientService.restoreClient(this.id).subscribe(() => {
      this.ngOnInit();
    });
  }

  removeClientSpaces() {
    this.clientInfo.customerNo = this.clientInfo.customerNo.replace(/\s/g, "");
  }

  billToTrackBy(index, item) {
    return item.customerNo;
  }
}
